
import { defineComponent, computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import NewsList from "@/shared/components/List/NewsList.vue";
import { usePoolNews } from "@/shared/composables/usePoolNews";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { usePoolDetails } from "@/shared/composables/usePoolDetails";

export default defineComponent({
  components: { FormGroup, LayoutMain, InputTextArea, NewsList },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const poolHash = computed(
      () => router?.currentRoute?.value?.params?.pool_hash as string
    );

    const { poolData, poolDataLoading } = usePoolDetails(poolHash.value);

    const { createPoolNews, createPoolNewsLoading } = usePoolNews(
      poolHash.value
    );

    const formState = reactive({
      news: "",
    });

    const handleSubmit = async () => {
      try {
        await createPoolNews({
          poolHash: poolHash.value,
          body: formState.news,
        });
        formState.news = "";
      } catch (error) {
        console.log("create pool news error", error);
      }
    };

    const backToMyPools = () => {
      router.push({
        name: routeNames.profile,
      });
    };

    return {
      t,
      poolData,
      poolDataLoading,
      poolHash,
      backToMyPools,
      formState,
      handleSubmit,
      createPoolNewsLoading,
    };
  },
  methods: { makeRequiredRule },
});
