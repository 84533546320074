import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createBlock(_component_CustomTable, {
    columns: _ctx.columns,
    "data-source": _ctx.votes
  }, {
    txHash: _withCtx(({ text }) => [
      _createVNode(_component_a_button, {
        type: "link",
        size: "small"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(text), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["columns", "data-source"]))
}