import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_link = _resolveComponent("a-typography-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, {
    class: "my-pools-intro",
    "back-text": _ctx.t('Back to My pools'),
    title: 
      _ctx.poolDataLoading
        ? ''
        : _ctx.t('{poolName} introduction', {
            poolName: _ctx.poolData.poolDetail?.name,
          })
    ,
    onOnBack: _ctx.backToMyPools
  }, {
    default: _withCtx(() => [
      (_ctx.poolDataLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_a_typography_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Edit introduction text for your pool. Add links, descriptions.")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              keypath: "For posting updates about your pool missing progress, please use {newsPage}",
              tag: "div",
              class: "ant-typography mb-8"
            }, {
              newsPage: _withCtx(() => [
                _createVNode(_component_a_typography_link, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.manageNewsRedirect(_ctx.poolData.hash)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("news page")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, {
              gutter: 32,
              class: "flex-col-reverse md:flex-row"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  md: 14,
                  xs: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormGroup, {
                      model: _ctx.formState,
                      loading: _ctx.updatePoolLoading,
                      onFinish: _ctx.handleSubmit
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputTextArea, {
                          value: _ctx.formState.introduction,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.introduction) = $event)),
                          name: "introduction",
                          rows: 16,
                          disabled: _ctx.updatePoolLoading,
                          rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                        }, null, 8, ["value", "disabled", "rules"]),
                        _createVNode(_component_a_button, {
                          "html-type": "submit",
                          class: "md:mt-6 px-16",
                          loading: _ctx.updatePoolLoading
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Save")), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ]),
                      _: 1
                    }, 8, ["model", "loading", "onFinish"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  md: 10,
                  xs: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography, { class: "lighter" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Some recommendations for intro")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
    ]),
    _: 1
  }, 8, ["back-text", "title", "onOnBack"]))
}