
import { defineComponent, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";
import { usePoolDetails } from "@/shared/composables/usePoolDetails";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { message } from "ant-design-vue";

export default defineComponent({
  components: { FormGroup, LayoutMain, InputTextArea },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const poolHash = router?.currentRoute?.value?.params?.pool_hash as string;
    const { poolData, poolDataLoading, updatePool, updatePoolLoading } =
      usePoolDetails(poolHash);

    const formState = reactive({
      introduction: "",
    });

    watch(
      () => poolData?.value?.poolDetail?.longDescription,
      (description) => {
        formState.introduction = description ?? "";
      }
    );

    const handleSubmit = async () => {
      try {
        await updatePool({
          poolHash: poolHash,
          longDescription: formState.introduction,
        });
        message.success(t("Introduction text successfully updated"));
      } catch (error) {
        console.log("MyPoolsIntro.vue:handleSubmit", error);
      }
    };

    const manageNewsRedirect = (pool_hash) => {
      router.push({
        name: routeNames.profileNews,
        params: {
          pool_hash,
        },
      });
    };

    const backToMyPools = () => {
      router.push({
        name: routeNames.profile,
      });
    };

    return {
      t,
      poolData,
      poolDataLoading,
      updatePoolLoading,
      manageNewsRedirect,
      formState,
      handleSubmit,
      backToMyPools,
    };
  },
  methods: { makeRequiredRule },
});
