import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form = _resolveComponent("a-form")!
  const _directive_form_loading = _resolveDirective("form-loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_a_form, _mergeProps({
    ref: "formGroup",
    hideRequiredMark: "",
    colon: false,
    validateTrigger: _ctx.validateTrigger
  }, _ctx.$attrs, {
    onSubmit: _ctx.onSubmit,
    autocomplete: "disabled",
    autocorrect: "disabled",
    autocapitalize: "disabled",
    spellcheck: "false",
    scrollToFirstError: _ctx.scrollToFirstError,
    layout: _ctx.layout
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["validateTrigger", "onSubmit", "scrollToFirstError", "layout"])), [
    [_directive_form_loading, _ctx.loading || _ctx.disabled]
  ])
}