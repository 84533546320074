import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTabs = _resolveComponent("CustomTabs")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, { class: "profile" }, {
    default: _withCtx(() => [
      _createVNode(_component_CustomTabs, {
        class: "profile-tabs",
        tabs: _ctx.tabList
      }, null, 8, ["tabs"])
    ]),
    _: 1
  }))
}