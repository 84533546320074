import gql from "graphql-tag";

export const myPoolsGql = gql`
  query MyPools($input: PaginationInput!) {
    myPools(input: $input) {
      __typename
      ... on MyPoolItemResults {
        results {
          id
          voteId
          hash
          status
          created
          modified
          poolDetail {
            name
            ticker
            url
            logo
            longDescription
            shortDescription
          }
          isVoted
          isOwned
          pledgeAmount
          roaPercentage
          lifetimePercentage
          luckPercentage
          epoch {
            id
            created
            number
          }
          poolEpochDetail {
            blocksCount
            delegatorsCount
            activeStakeAmount
            poolRewardsAmount
            delegatorRewardsAmount
            poolHash
            epochNo
          }
          poolVoteDetail {
            voteCount
            voteStrike
            voteCountDifference
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        totalCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
