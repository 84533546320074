import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-9" }
const _hoisted_2 = { class: "profile-internal-id__generate-new-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "profile-internal-id" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        md: 12,
        xs: 24,
        class: "md:mt-12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_a_typography_text, { class: "font-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.internalId ? _ctx.t("ID Poolboost ") : _ctx.t("No Poolboost ID found")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_typography_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.internalId?.secretKey ?? _ctx.t("Generate now")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_button, {
              loading: _ctx.generateSecretKeyLoading,
              onClick: _ctx.generateSecretKey
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.internalId ? _ctx.t("Generate New") : _ctx.t("Generate")), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}