import gql from "graphql-tag";

export const userSecretKeysGql = gql`
  query UserSecretKeys {
    userSecretKeys {
      ... on UserSecretKeyResults {
        results {
          id
          created
          modified
          user {
            id
            username
            name
            avatarUrl
          }
          secretKey
          status
          poolVotes {
            id
            created
            status
            delegation {
              id
              fromStakingKey {
                id
                created
                modified
                stakingKey
              }
              startTx {
                id
                amount
                txid
                stakingKey
                type
              }
              endTx {
                id
                amount
                txid
                stakingKey
                type
              }
            }
            epoch {
              id
              created
              number
            }
            pool {
              id
              address
              status
            }
            secretKey {
              id
              created
              modified
              user {
                id
                username
                name
                avatarUrl
              }
              secretKey
              status
              poolVotes {
                id
                created
                status
              }
            }
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
