import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my-pools" }
const _hoisted_2 = { class: "my-pools__header flex" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "my-pools__header-vote-details flex" }
const _hoisted_5 = { class: "flex my-pools__header-vote-details-item" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex my-pools__header-vote-details-item" }
const _hoisted_8 = { class: "flex items-center my-pools__vote-stake" }
const _hoisted_9 = { class: "my-pools__footer flex" }
const _hoisted_10 = { class: "my-pools__footer-right flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LikeOutlined = _resolveComponent("LikeOutlined")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_MyPoolsDetails = _resolveComponent("MyPoolsDetails")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_StockOutlined = _resolveComponent("StockOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.myPoolsListLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.myPoolsList, (pool) => {
          return (_openBlock(), _createBlock(_component_CustomCard, {
            key: pool?.id ?? '',
            class: "mb-4"
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (pool?.poolDetail?.logo)
                    ? (_openBlock(), _createBlock(_component_a_image, {
                        key: 0,
                        class: "my-pools__header-icon",
                        src: pool?.poolDetail?.logo,
                        fallback: _ctx.productFallbackIcon,
                        alt: pool?.poolDetail?.name
                      }, null, 8, ["src", "fallback", "alt"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_typography_text, { class: "font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(pool?.poolDetail?.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_LikeOutlined, { class: "my-pools__vote-icon mr-0.5" }),
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(pool?.poolVoteDetail?.voteCount), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createVNode(_component_a_typography_text, {
                      class: "small",
                      type: "secondary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("votes")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_AppIcon, {
                        name: "voteStake",
                        class: "my-pools__vote-stake-icon"
                      }),
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(
                      pool?.poolEpochDetail?.activeStakeAmount ?? 0
                    )), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createVNode(_component_a_typography_text, {
                      class: "small",
                      type: "secondary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("vote stake")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_a_button, {
                  type: "link",
                  class: "my-pools__button p-0",
                  onClick: _ctx.goToVotesRewards
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_StockOutlined, { class: "mr-1" })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.t("Votes and rewards")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_a_button, {
                    type: "link",
                    class: "my-pools__button p-0",
                    onClick: ($event: any) => (_ctx.changeIntro(pool?.hash))
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_AppIcon, {
                        name: "editPencil",
                        class: "my-pools__footer-icon -ml-3 mr-1 mb-1"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.t("Change intro")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_a_button, {
                    type: "link",
                    class: "my-pools__button p-0",
                    onClick: ($event: any) => (_ctx.manageNews(pool?.hash))
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_AppIcon, {
                        name: "news",
                        class: "my-pools__footer-icon mr-1 mb-1"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.t("Manage news")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    md: 12,
                    xs: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MyPoolsDetails, {
                        class: "my-pools__details",
                        "my-pool-item": pool
                      }, null, 8, ["my-pool-item"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, {
                    md: 12,
                    xs: 24
                  })
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
  ]))
}