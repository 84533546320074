import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_NewsList = _resolveComponent("NewsList")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, {
    class: "my-pools-news",
    "back-text": _ctx.t('Back to My pools'),
    title: 
      _ctx.poolDataLoading
        ? ''
        : _ctx.t('{poolName} news page', {
            poolName: _ctx.poolData.poolDetail?.name,
          })
    ,
    onOnBack: _ctx.backToMyPools
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_a_typography, { class: "mb-8" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(
          "Post updates about your pool mission, how you spent rewards and your future plans."
        )), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            md: 16,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormGroup, {
                model: _ctx.formState,
                loading: _ctx.createPoolNewsLoading,
                onFinish: _ctx.handleSubmit
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputTextArea, {
                    value: _ctx.formState.news,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.news) = $event)),
                    name: "news",
                    placeholder: "What’s new with your pool?",
                    rows: 4,
                    disabled: _ctx.createPoolNewsLoading,
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                  }, null, 8, ["value", "disabled", "rules"]),
                  _createVNode(_component_a_button, {
                    "html-type": "submit",
                    class: "md:mt-4 px-16",
                    loading: _ctx.createPoolNewsLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Post")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              }, 8, ["model", "loading", "onFinish"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            class: "news-list",
            md: 16,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NewsList, {
                "pool-hash": _ctx.poolHash,
                class: "mt-16"
              }, null, 8, ["pool-hash"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.poolDataLoading)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createVNode(_component_a_skeleton, {
              active: "",
              paragraph: false,
              class: "my-pools-news__title-loading"
            })
          ])
        }
      : undefined
  ]), 1032, ["back-text", "title", "onOnBack"]))
}