
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import MyPools from "@/web/views/Profile/ProfileTabs/MyPools/MyPools.vue";
import MyVotes from "@/web/views/Profile/ProfileTabs/MyVotes.vue";
import InternalID from "@/web/views/Profile/ProfileTabs/InternalID.vue";
import CustomTabs from "@/shared/components/custom-tabs.vue";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";

export default defineComponent({
  components: { CustomTabs, LayoutMain },
  setup() {
    const { t } = useI18n();
    const activeTabKey = ref();

    const tabList = [
      {
        name: "myPools",
        title: t("My Pools"),
        component: MyPools,
      },
      {
        name: "myVotes",
        title: t("My Votes"),
        component: MyVotes,
      },
      {
        name: "internalId",
        title: t("Internal ID"),
        component: InternalID,
      },
    ];

    return { t, activeTabKey, tabList };
  },
});
