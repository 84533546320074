
import { defineComponent, PropType } from "vue";
import { FormContextProps } from "ant-design-vue/lib/form/context";

export default defineComponent({
  props: {
    label: { type: String, required: false },
    rules: { type: Object as PropType<FormContextProps["rules"]> },
  },
});
