import { computed } from "vue";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import { generateSecretKeyGql } from "@/api/user/generateSecretKey";
import { userSecretKeysGql } from "@/api/user/userSecretKeys";
import {
  GenerateSecretKey,
  GenerateSecretKey_generateSecretKey_UserSecretKey,
} from "@/api/user/__generated__/GenerateSecretKey";
import {
  UserSecretKeys,
  UserSecretKeys_userSecretKeys_UserSecretKeyResults,
} from "@/api/user/__generated__/UserSecretKeys";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";

export const useInternalID = () => {
  const { data: internalIds, loading: internalIdsLoading } = useQuerySingleNode<
    UserSecretKeys_userSecretKeys_UserSecretKeyResults,
    UserSecretKeys
  >(userSecretKeysGql, "UserSecretKeyResults", {}, {});

  const { mutate: generateSecretKeyMutate, loading: generateSecretKeyLoading } =
    useCustomMutation<GenerateSecretKey>(generateSecretKeyGql, {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: userSecretKeysGql }],
    });

  const generateSecretKey = async () => {
    const generateSecretKeyResponse = await generateSecretKeyMutate();
    const parsedResponse =
      parseGqlResponse<GenerateSecretKey_generateSecretKey_UserSecretKey>(
        "UserSecretKey",
        generateSecretKeyResponse
      );

    console.log("generateSecretKey:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !generateSecretKeyResponse) {
      throw new Error("Failed to GenerateSecretKey");
    }

    return parsedResponse.data;
  };

  return {
    internalId: computed(() => first(internalIds.value?.results)),
    internalIds: computed(() => internalIds.value?.results),
    internalIdsLoading,
    generateSecretKey,
    generateSecretKeyLoading,
  };
};
