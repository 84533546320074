import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_text, {
      class: "small title",
      type: "secondary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "value" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.value), 1)
      ]),
      _: 1
    })
  ]))
}