
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { i18nTranslate } from "@/plugins/i18n";
import { map } from "lodash";
import {
  adaCurrencyFormatter,
  localeFormatterPrettyShort,
} from "@/shared/utils/adaFormatters";
import TitleValue from "@/shared/components/TitleValue.vue";
import { MyPools_myPools_MyPoolItemResults_results } from "@/api/user/__generated__/MyPools";
import { decimalToPercentage } from "@/shared/utils/numbers";

const poolsLabels = {
  roa_lifetime: i18nTranslate("ROA / Lifetime"),
  total_stake: i18nTranslate("Stake"),
  luck: i18nTranslate("Luck"),
  pledge: i18nTranslate("Pledge"),
};

const poolsDetailsToShow = ["roa_lifetime", "total_stake", "luck", "pledge"];

export default defineComponent({
  components: { TitleValue },
  props: {
    myPoolItem: {
      type: Object as PropType<MyPools_myPools_MyPoolItemResults_results | null>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    /**
     * Mock values
     *
     * TODO: we might need a parser for all of these values
     */
    const mockDetailsToShow = {
      roa_lifetime: `${decimalToPercentage(
        props?.myPoolItem?.lifetimePercentage
      )}%`,
      total_stake: adaCurrencyFormatter(1000),
      luck: `${decimalToPercentage(props?.myPoolItem?.luckPercentage)}%`,
      pledge: `${localeFormatterPrettyShort(
        Number(props?.myPoolItem?.pledgeAmount)
      )}`,
    };

    /**
     * These details is mock only
     */
    const summary = computed(() => {
      return {
        list: map(poolsDetailsToShow, (key) => ({
          key,
          title: poolsLabels[key],
          value: mockDetailsToShow[key] || props?.myPoolItem?.[key],
        })),
      };
    });

    return { t, summary };
  },
});
