
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { mockVotes } from "@/web/mock/votes";
import CustomTable from "@/shared/components/CustomTable.vue";
import { maskTXHash } from "@/shared/utils/stringHelper";
import { useBreakpoints } from "@/shared/utils/breakpoints";

export default defineComponent({
  components: { CustomTable },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    //const router = useRouter();
    const { isMobileDown } = useBreakpoints();

    const columns = computed(() => {
      return [
        {
          title: t("Epoch"),
          dataIndex: "epoch",
          key: "epoch",
        },
        {
          title: t("Pool"),
          dataIndex: "poolName",
          key: "poolName",
        },
        ...(isMobileDown.value
          ? []
          : [
              {
                title: t("Vote Stake"),
                dataIndex: "voteStake",
                key: "voteStake",
              },
              {
                title: t("Amount"),
                dataIndex: "amount",
                key: "amount",
              },
              {
                title: t("Internal ID"),
                dataIndex: "internalID",
                key: "internalID",
              },
              {
                title: t("Transaction hash"),
                dataIndex: "txHash",
                key: "txHash",
                slots: { customRender: "txHash" },
              },
            ]),
      ];
    });

    return {
      t,
      columns,
      votes: mockVotes.map((vote) => ({
        ...vote,
        txHash: maskTXHash(vote.txHash),
      })),
    };
  },
});
