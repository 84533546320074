
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    layout: {
      default: "vertical",
    },
  },
  setup(props, { emit }) {
    const formGroup = ref();
    const validateTrigger = ref("submit");

    return {
      formGroup,
      validateTrigger,
      onSubmit(e) {
        /**
         * change validation trigger to change on initial submit
         * so it will validate when value changes
         */
        validateTrigger.value = "change";

        /**
         * emit original submit on submit with event
         */
        emit("submit", e);
      },
      scrollToFirstError: {
        behavior: (actions) => {
          actions.forEach(({ el, top }) => {
            /**
             * Add header height + 40 on top el to scroll
             */
            const headerHeight =
              document.querySelector("header")?.clientHeight || 50;
            const extraOffset = 40;

            el.scrollTop = top - (headerHeight + extraOffset);
          });
        },
      },
    };
  },
});
