import { myPoolsGql } from "@/api/user/myPools";
import {
  MyPools,
  MyPoolsVariables,
  MyPools_myPools_MyPoolItemResults,
} from "@/api/user/__generated__/MyPools";
import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import { computed } from "vue";

export const useMyPools = () => {
  const { data: myPools, loading: myPoolsListLoading } = useQuerySingleNode<
    MyPools_myPools_MyPoolItemResults,
    MyPools,
    MyPoolsVariables
  >(
    myPoolsGql,
    "MyPoolItemResults",
    { input: { after: null, pageSize: 100 } }, // TODO: Add way to paginate pages
    {}
  );

  const myPoolsList = computed(() => myPools?.value?.results);

  return { myPoolsList, myPoolsListLoading };
};
