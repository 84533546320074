import random from "lodash/random";
import sampleSize from "lodash/sampleSize";
import { v4 as uuiv4 } from "uuid";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import { mockPools } from "@/web/mock/pools";

const randomPools = sampleSize(mockPools, random(1, 5));

export const mockVotes = randomPools.map((pool) => {
  const txHash = "stake" + uuiv4().replaceAll("-", "");
  return {
    poolName: pool.name,
    id: txHash,
    epoch: random(330, 350),
    voteStake: `${random(50, 100)}%`,
    amount: `${adaCurrencyFormatter(random(1000, 1000000))}`,
    internalID: "1a4rc56w820k2",
    txHash,
  };
});
