
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import MyPoolsDetails from "@/web/views/Profile/ProfileTabs/MyPools/MyPoolsDetails.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import productFallbackIcon from "@/assets/icons/product_fallback.svg";
import LikeOutlined from "@ant-design/icons-vue/LikeOutlined";
import StockOutlined from "@ant-design/icons-vue/StockOutlined";
import CustomCard from "@/shared/components/CustomCard.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { useMyPools } from "@/shared/composables/useMyPools";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";

export default defineComponent({
  components: {
    MyPoolsDetails,
    LikeOutlined,
    StockOutlined,
    AppIcon,
    CustomCard,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const { myPoolsList, myPoolsListLoading } = useMyPools();

    const goToVotesRewards = () => {
      console.log("Votes and Rewards");
    };

    const changeIntro = (pool_hash) => {
      if (pool_hash) {
        router.push({
          name: routeNames.profileIntro,
          params: {
            pool_hash,
          },
        });
      }
    };

    const manageNews = (pool_hash) => {
      if (pool_hash) {
        router.push({
          name: routeNames.profileNews,
          params: {
            pool_hash,
          },
        });
      }
    };

    return {
      t,
      myPoolsList,
      myPoolsListLoading,
      productFallbackIcon,
      goToVotesRewards,
      changeIntro,
      manageNews,
    };
  },
  methods: { adaCurrencyFormatter },
});
