import take from "lodash/take";

const snapshotPoolList = [
  {
    name: "CARDANIANS.io #2",
    icon: "https://cdn.adapools.org/pool_logo/e5b20e8f9661506710a159e561d40ceb54449fdea1d1130b3bdb36bb.png",
    pool_id: "e5b20e8f9661506710a159e561d40ceb54449fdea1d1130b3bdb36bb",
    db_ticker: "CRDN1",
    db_name: "CARDANIANS.io #2",
    db_url: "https://cardanians.io",
    roa: "4.644",
    roa_lifetime: "4.605",
    total_stake: "41994461835466",
    stake_x_deleg: "11727020897.924",
    saturated: "0.61088118766956",
    luck_lifetime: "98",
    blocks_epoch: "17",
    blocks_lifetime: "3616",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: "https://twitter.com/Cardanians_io",
      tg: "https://t.me/CardanoCafe",
      fb: null,
      yt: null,
      tc: null,
      di: "https://discord.com/",
      gh: null,
    },
  },
  {
    name: "#2 Nordic Pool",
    icon: "https://cdn.adapools.org/pool_logo/f0e505f8f0d7beccffe83c287d0d2ff6a46ec5d38345e8bd35359427.png",
    pool_id: "f0e505f8f0d7beccffe83c287d0d2ff6a46ec5d38345e8bd35359427",
    db_ticker: "NORTH",
    db_name: "#2 Nordic Pool",
    db_url: "https://nordicpool.org",
    roa: "4.358",
    roa_lifetime: "4.459",
    total_stake: "56357784419779",
    stake_x_deleg: "20553531881.757",
    saturated: "0.81982025191008",
    luck_lifetime: "98",
    blocks_epoch: "40",
    blocks_lifetime: "4572",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TITANstaking",
    icon: "https://cdn.adapools.org/pool_logo/000000f66e28b0f18aef20555f4c4954234e3270dfbbdcc13f54e799.png",
    pool_id: "000000f66e28b0f18aef20555f4c4954234e3270dfbbdcc13f54e799",
    db_ticker: "TITAN",
    db_name: "TITANstaking",
    db_url: "https://www.titanstaking.io",
    roa: "3.454",
    roa_lifetime: "4.717",
    total_stake: "56990205688483",
    stake_x_deleg: "12826965043.548",
    saturated: "0.8290198996457",
    luck_lifetime: "101",
    blocks_epoch: "46",
    blocks_lifetime: "5532",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ECO Pool",
    icon: "https://cdn.adapools.org/pool_logo/72de14d78a359617a7dd0b33c5554ed4edbafd768ece87c4bf672ee0.png",
    pool_id: "72de14d78a359617a7dd0b33c5554ed4edbafd768ece87c4bf672ee0",
    db_ticker: "ECO",
    db_name: "ECO Pool",
    db_url: "https://ecopool.io",
    roa: "4.3",
    roa_lifetime: "4.234",
    total_stake: "55355514089786",
    stake_x_deleg: "24901265897.34",
    saturated: "0.80524051775487",
    luck_lifetime: "102",
    blocks_epoch: "33",
    blocks_lifetime: "1621",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "120000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEDSCAVE.IO",
    icon: "https://cdn.adapools.org/pool_logo/024dcb42f0aa6d81a7e26ccdd525a2ed3e9665d126b38ba0f8b77b50.png",
    pool_id: "024dcb42f0aa6d81a7e26ccdd525a2ed3e9665d126b38ba0f8b77b50",
    db_ticker: "NEDS1",
    db_name: "NEDSCAVE.IO",
    db_url: "https://nedscave.io",
    roa: "3.85",
    roa_lifetime: "4.628",
    total_stake: "54298540312424",
    stake_x_deleg: "11309839681.821",
    saturated: "0.7898650285063",
    luck_lifetime: "98",
    blocks_epoch: "39",
    blocks_lifetime: "5040",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakit.io Pool by TOBG",
    icon: "https://cdn.adapools.org/pool_logo/9f1bd8718fffe41cbd70761991819fbbd9552b4c44c30e1a6fc96766.png",
    pool_id: "9f1bd8718fffe41cbd70761991819fbbd9552b4c44c30e1a6fc96766",
    db_ticker: "STI",
    db_name: "stakit.io Pool by TOBG",
    db_url: "https://stakit.io",
    roa: "4.122",
    roa_lifetime: "4.636",
    total_stake: "53258540952536",
    stake_x_deleg: "9908565758.6113",
    saturated: "0.77473646115775",
    luck_lifetime: "98",
    blocks_epoch: "25",
    blocks_lifetime: "5619",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bravo pool",
    icon: "https://cdn.adapools.org/pool_logo/50a251d84927d932b9393cb462f300d215faedd5e8a5e791e869f32c.png",
    pool_id: "50a251d84927d932b9393cb462f300d215faedd5e8a5e791e869f32c",
    db_ticker: "BRAVO",
    db_name: "Bravo pool",
    db_url: "https://bravostakepool.nl",
    roa: "3.917",
    roa_lifetime: "4.612",
    total_stake: "58691435718365",
    stake_x_deleg: "6982088474.7044",
    saturated: "0.85376719668751",
    luck_lifetime: "103",
    blocks_epoch: "42",
    blocks_lifetime: "4608",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "310000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garden Pool",
    icon: "https://cdn.adapools.org/pool_logo/d5b90a198d2411b5c82fbdfee5f94b86de49a8589bfa7720b9de358f.png",
    pool_id: "d5b90a198d2411b5c82fbdfee5f94b86de49a8589bfa7720b9de358f",
    db_ticker: "EDEN",
    db_name: "Garden Pool",
    db_url: "https://garden-pool.com",
    roa: "3.826",
    roa_lifetime: "4.688",
    total_stake: "52542312480324",
    stake_x_deleg: "11096581305.243",
    saturated: "0.764317694477",
    luck_lifetime: "98",
    blocks_epoch: "40",
    blocks_lifetime: "5746",
    tax_ratio: "0.038",
    tax_fix: "340000000",
    pledge: "10000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A CardanoLand pool ACL",
    icon: "https://cdn.adapools.org/pool_logo/0c2e0c5a062389a3fd6fafddae26344d490866d7a34cad981b20f96b.png",
    pool_id: "0c2e0c5a062389a3fd6fafddae26344d490866d7a34cad981b20f96b",
    db_ticker: "ACL",
    db_name: "A CardanoLand pool ACL",
    db_url: "https://cardanoland.com",
    roa: "3.659",
    roa_lifetime: "4.827",
    total_stake: "58774768549532",
    stake_x_deleg: "15516042383.72",
    saturated: "0.85497941507656",
    luck_lifetime: "99",
    blocks_epoch: "41",
    blocks_lifetime: "6592",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoCafe",
    icon: "https://cdn.adapools.org/pool_logo/5b60373e718a3647ec57f610b9942f91286748ddeaa0ad226acb927b.png",
    pool_id: "5b60373e718a3647ec57f610b9942f91286748ddeaa0ad226acb927b",
    db_ticker: "CAFE",
    db_name: "CardanoCafe",
    db_url: "https://cardanocafe.org/",
    roa: "4.317",
    roa_lifetime: "4.74",
    total_stake: "59086680148282",
    stake_x_deleg: "9999438170.2965",
    saturated: "0.85951670212739",
    luck_lifetime: "101",
    blocks_epoch: "36",
    blocks_lifetime: "3955",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THREE SecureStaking.io",
    icon: "https://cdn.adapools.org/pool_logo/34ae2462f0fab1866281947a022d85bddf29f45a8b9699baabbbfcb9.png",
    pool_id: "34ae2462f0fab1866281947a022d85bddf29f45a8b9699baabbbfcb9",
    db_ticker: "SECUR",
    db_name: "THREE SecureStaking.io",
    db_url: "https://cardano.securestaking.io",
    roa: "3.943",
    roa_lifetime: "4.566",
    total_stake: "52534663454918",
    stake_x_deleg: "20650418024.732",
    saturated: "0.76420642633543",
    luck_lifetime: "99",
    blocks_epoch: "41",
    blocks_lifetime: "4249",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Steak.and.ADA",
    icon: "https://cdn.adapools.org/pool_logo/f76e3a1104a9d816a67d5826a155c9e2979a839d0d944346d47e33ab.png",
    pool_id: "f76e3a1104a9d816a67d5826a155c9e2979a839d0d944346d47e33ab",
    db_ticker: "ST3AK",
    db_name: "Steak.and.ADA",
    db_url: "https://steakandbake.github.io/ada",
    roa: "4.144",
    roa_lifetime: "4.613",
    total_stake: "47892422441188",
    stake_x_deleg: "5240444517.0356",
    saturated: "0.6966771003251",
    luck_lifetime: "100",
    blocks_epoch: "31",
    blocks_lifetime: "4326",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1612388000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/10bc63e4689bd58b8a7d248a99360a45a80d047b0b4752a7b00b754b.png",
    pool_id: "10bc63e4689bd58b8a7d248a99360a45a80d047b0b4752a7b00b754b",
    db_ticker: "CRYPT",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "3.543",
    roa_lifetime: "4.721",
    total_stake: "53698174262317",
    stake_x_deleg: "37368249312.677",
    saturated: "0.78113167868597",
    luck_lifetime: "100",
    blocks_epoch: "39",
    blocks_lifetime: "4243",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/1b268f4cba3faa7e36d8a0cc4adca2096fb856119412ee7330f692b5.png",
    pool_id: "1b268f4cba3faa7e36d8a0cc4adca2096fb856119412ee7330f692b5",
    db_ticker: "DIGI",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "3.764",
    roa_lifetime: "4.84",
    total_stake: "47982729292452",
    stake_x_deleg: "18314018813.913",
    saturated: "0.69799076775036",
    luck_lifetime: "99",
    blocks_epoch: "35",
    blocks_lifetime: "7259",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garden Pool Two",
    icon: "https://cdn.adapools.org/pool_logo/7c6b4a731eec6bef00e16ec4c990e7a817f61597dab1033006563ad7.png",
    pool_id: "7c6b4a731eec6bef00e16ec4c990e7a817f61597dab1033006563ad7",
    db_ticker: "EDEN",
    db_name: "Garden Pool Two",
    db_url: "https://garden-pool.com",
    roa: "3.786",
    roa_lifetime: "4.681",
    total_stake: "50899419587126",
    stake_x_deleg: "24494427135.287",
    saturated: "0.74041901074716",
    luck_lifetime: "100",
    blocks_epoch: "47",
    blocks_lifetime: "4682",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COOL2",
    icon: "https://cdn.adapools.org/pool_logo/d918835729aaab9d4dca82c60079a6c643d65141f909730ecfeb3aef.png",
    pool_id: "d918835729aaab9d4dca82c60079a6c643d65141f909730ecfeb3aef",
    db_ticker: "COOL2",
    db_name: "COOL2",
    db_url: "https://www.stakecool.io",
    roa: "3.531",
    roa_lifetime: "4.979",
    total_stake: "52909142941173",
    stake_x_deleg: "47580164515.443",
    saturated: "0.76965387019642",
    luck_lifetime: "101",
    blocks_epoch: "36",
    blocks_lifetime: "5836",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AtlasStakePool",
    icon: "https://cdn.adapools.org/pool_logo/e6ac159ee8063742eadcb48be45f29d9a67b4158439070032f42d10c.png",
    pool_id: "e6ac159ee8063742eadcb48be45f29d9a67b4158439070032f42d10c",
    db_ticker: "ATLAS",
    db_name: "AtlasStakePool",
    db_url: "https://atlasstakepool.com",
    roa: "3.885",
    roa_lifetime: "4.482",
    total_stake: "49838482640790",
    stake_x_deleg: "17021339699.723",
    saturated: "0.72498587043548",
    luck_lifetime: "101",
    blocks_epoch: "38",
    blocks_lifetime: "3417",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANIANS.io",
    icon: "https://cdn.adapools.org/pool_logo/618ab17ec8ea7239b4ac0c826b667c599489e25524ce74841a29d510.png",
    pool_id: "618ab17ec8ea7239b4ac0c826b667c599489e25524ce74841a29d510",
    db_ticker: "CRDNS",
    db_name: "CARDANIANS.io",
    db_url: "https://cardanians.io",
    roa: "3.893",
    roa_lifetime: "4.744",
    total_stake: "59664105371244",
    stake_x_deleg: "7098644303.5388",
    saturated: "0.86791633842646",
    luck_lifetime: "97",
    blocks_epoch: "54",
    blocks_lifetime: "7166",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "#3 Nordic Pool",
    icon: "https://cdn.adapools.org/pool_logo/fa58244068bef886049b3e8fbba8aa39f227ce78168efdf7629111c5.png",
    pool_id: "fa58244068bef886049b3e8fbba8aa39f227ce78168efdf7629111c5",
    db_ticker: "NORTH",
    db_name: "#3 Nordic Pool",
    db_url: "https://nordicpool.org",
    roa: "4.422",
    roa_lifetime: "4.191",
    total_stake: "49866534061134",
    stake_x_deleg: "35216478856.733",
    saturated: "0.72539392626538",
    luck_lifetime: "99",
    blocks_epoch: "38",
    blocks_lifetime: "2330",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONE SecureStaking.io",
    icon: "https://cdn.adapools.org/pool_logo/a10865dae2d543ee9f13e98bff70ea81565bb6e4343b15b765f78174.png",
    pool_id: "a10865dae2d543ee9f13e98bff70ea81565bb6e4343b15b765f78174",
    db_ticker: "SECUR",
    db_name: "ONE SecureStaking.io",
    db_url: "https://cardano.securestaking.io",
    roa: "4.391",
    roa_lifetime: "4.736",
    total_stake: "44446407322578",
    stake_x_deleg: "14241078924.248",
    saturated: "0.64654892350427",
    luck_lifetime: "95",
    blocks_epoch: "37",
    blocks_lifetime: "5381",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "#1 Nordic Pool",
    icon: "https://cdn.adapools.org/pool_logo/52e22df52e90370f639c99f5c760f0cd67d7f871cd0d0764fae47cd9.png",
    pool_id: "52e22df52e90370f639c99f5c760f0cd67d7f871cd0d0764fae47cd9",
    db_ticker: "NORTH",
    db_name: "#1 Nordic Pool",
    db_url: "https://nordicpool.org",
    roa: "3.736",
    roa_lifetime: "4.462",
    total_stake: "59179599702884",
    stake_x_deleg: "16420532658.958",
    saturated: "0.86086837578606",
    luck_lifetime: "96",
    blocks_epoch: "42",
    blocks_lifetime: "5093",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "110000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ATADA-2 Stakepool in Austria",
    icon: "https://cdn.adapools.org/pool_logo/2ba96bc27be2bdde3ec11b9f696cf21fad39e49097be9b0193e6b572.png",
    pool_id: "2ba96bc27be2bdde3ec11b9f696cf21fad39e49097be9b0193e6b572",
    db_ticker: "ATAD2",
    db_name: "ATADA-2 Stakepool in Austria",
    db_url: "https://stakepool.at",
    roa: "4.023",
    roa_lifetime: "4.588",
    total_stake: "45319218076048",
    stake_x_deleg: "12838305403.98",
    saturated: "0.65924544695966",
    luck_lifetime: "98",
    blocks_epoch: "42",
    blocks_lifetime: "4980",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "900000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Spire Staking |  Top 50 Operator - Pool A",
    icon: "https://cdn.adapools.org/pool_logo/d7513627b3d50819c1c84687bfd15f02f00cd11c37d7935a67540089.png",
    pool_id: "d7513627b3d50819c1c84687bfd15f02f00cd11c37d7935a67540089",
    db_ticker: "SPIRE",
    db_name: "Spire Staking |  Top 50 Operator - Pool A",
    db_url: "https://www.spirestaking.com",
    roa: "3.567",
    roa_lifetime: "4.674",
    total_stake: "59139147202942",
    stake_x_deleg: "8426780735.6714",
    saturated: "0.8602799250683",
    luck_lifetime: "100",
    blocks_epoch: "48",
    blocks_lifetime: "6057",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AHLNET ",
    icon: "https://cdn.adapools.org/pool_logo/0001a003afb844ce6d9409fc49e049db654a78dc77f1151cb6cd548f.png",
    pool_id: "0001a003afb844ce6d9409fc49e049db654a78dc77f1151cb6cd548f",
    db_ticker: "AHL",
    db_name: "AHLNET ",
    db_url: "https://ahlnet.nu",
    roa: "3.507",
    roa_lifetime: "4.535",
    total_stake: "47547133867465",
    stake_x_deleg: "27294565939.991",
    saturated: "0.6916542881545",
    luck_lifetime: "98",
    blocks_epoch: "32",
    blocks_lifetime: "3656",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANIANS.io #3",
    icon: "https://cdn.adapools.org/pool_logo/397f04eb0ae0497d8c4d691844c8c6b026bc942e3f406f26b8e24f48.png",
    pool_id: "397f04eb0ae0497d8c4d691844c8c6b026bc942e3f406f26b8e24f48",
    db_ticker: "CRDN2",
    db_name: "CARDANIANS.io #3",
    db_url: "https://cardanians.io",
    roa: "4.333",
    roa_lifetime: "4.624",
    total_stake: "39715386140854",
    stake_x_deleg: "21386853064.542",
    saturated: "0.5777281382849",
    luck_lifetime: "102",
    blocks_epoch: "27",
    blocks_lifetime: "3540",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KOPI 咖啡 Singapore",
    icon: "https://cdn.adapools.org/pool_logo/fb4b7b76ea6d857f442cb9569d186f328fc846bb83222c3150ec3346.png",
    pool_id: "fb4b7b76ea6d857f442cb9569d186f328fc846bb83222c3150ec3346",
    db_ticker: "KOPI",
    db_name: "KOPI 咖啡 Singapore",
    db_url: "https://kopipool.com",
    roa: "3.756",
    roa_lifetime: "4.585",
    total_stake: "44846275681605",
    stake_x_deleg: "21124011154.783",
    saturated: "0.65236569189223",
    luck_lifetime: "99",
    blocks_epoch: "37",
    blocks_lifetime: "4267",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KIWI by Kiwipool Staking",
    icon: "https://cdn.adapools.org/pool_logo/60397646d7d1ad6fe2ddccfe7efc9cba61f6d3d94d29e8f41de73240.png",
    pool_id: "60397646d7d1ad6fe2ddccfe7efc9cba61f6d3d94d29e8f41de73240",
    db_ticker: "KIWI",
    db_name: "KIWI by Kiwipool Staking",
    db_url: "https://www.kiwipool.org",
    roa: "4.187",
    roa_lifetime: "4.664",
    total_stake: "40458386134206",
    stake_x_deleg: "5729838002.2951",
    saturated: "0.58853634247515",
    luck_lifetime: "99",
    blocks_epoch: "32",
    blocks_lifetime: "5335",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "501369000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAFFLE",
    icon: "https://cdn.adapools.org/pool_logo/5ed675dd3d0640ca94bf8c3d6a5bfaac0f59930c99a94ffe3686cb70.png",
    pool_id: "5ed675dd3d0640ca94bf8c3d6a5bfaac0f59930c99a94ffe3686cb70",
    db_ticker: "WFFL",
    db_name: "WAFFLE",
    db_url: "https://wafflepool.org",
    roa: "3.473",
    roa_lifetime: "4.304",
    total_stake: "52843976071553",
    stake_x_deleg: "45831722525.198",
    saturated: "0.76870590675148",
    luck_lifetime: "97",
    blocks_epoch: "46",
    blocks_lifetime: "3594",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "850000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BLADE Pool - bladepool.com",
    icon: "https://cdn.adapools.org/pool_logo/d765dee553a7e041d525b1782a10eb0a5e52e96ed55ed173d8bb82a7.png",
    pool_id: "d765dee553a7e041d525b1782a10eb0a5e52e96ed55ed173d8bb82a7",
    db_ticker: "BLADE",
    db_name: "BLADE Pool - bladepool.com",
    db_url: "https://bladepool.com",
    roa: "3.975",
    roa_lifetime: "4.119",
    total_stake: "58975629448845",
    stake_x_deleg: "13651766076.122",
    saturated: "0.85790127999317",
    luck_lifetime: "97",
    blocks_epoch: "56",
    blocks_lifetime: "2612",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "230000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fair Pool #01",
    icon: "https://cdn.adapools.org/pool_logo/6b51a5aae5b4b5f07f38acf12816b4f8a558ee2840d0dba69040aeb2.png",
    pool_id: "6b51a5aae5b4b5f07f38acf12816b4f8a558ee2840d0dba69040aeb2",
    db_ticker: "FAIR",
    db_name: "Fair Pool #01",
    db_url: "https://fairpool.eu",
    roa: "3.796",
    roa_lifetime: "4.455",
    total_stake: "44268620443217",
    stake_x_deleg: "18749945126.31",
    saturated: "0.64396270962584",
    luck_lifetime: "97",
    blocks_epoch: "45",
    blocks_lifetime: "4937",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "90000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OctasPool",
    icon: "https://cdn.adapools.org/pool_logo/153806dbcd134ddee69a8c5204e38ac80448f62342f8c23cfe4b7edf.png",
    pool_id: "153806dbcd134ddee69a8c5204e38ac80448f62342f8c23cfe4b7edf",
    db_ticker: "OCTAS",
    db_name: "OctasPool",
    db_url: "https://octaluso.dyndns.org",
    roa: "3.775",
    roa_lifetime: "4.733",
    total_stake: "38179609423415",
    stake_x_deleg: "11396898335.348",
    saturated: "0.55538764232093",
    luck_lifetime: "99",
    blocks_epoch: "27",
    blocks_lifetime: "5232",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANGEL stake pool",
    icon: "https://cdn.adapools.org/pool_logo/00004614332ac81201d8302d4cb8262502af229256e143dc2156156e.png",
    pool_id: "00004614332ac81201d8302d4cb8262502af229256e143dc2156156e",
    db_ticker: "ANGEL",
    db_name: "ANGEL stake pool",
    db_url: "https://www.angelstakepool.net",
    roa: "3.819",
    roa_lifetime: "4.907",
    total_stake: "42518669220912",
    stake_x_deleg: "10104246487.859",
    saturated: "0.6185066796085",
    luck_lifetime: "102",
    blocks_epoch: "26",
    blocks_lifetime: "5602",
    tax_ratio: "0.0177",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Australia",
    icon: "https://cdn.adapools.org/pool_logo/6658713e2cbfa4e347691a0435953f5acbe9f03d330e94caa3a0cfb4.png",
    pool_id: "6658713e2cbfa4e347691a0435953f5acbe9f03d330e94caa3a0cfb4",
    db_ticker: "ADAOZ",
    db_name: "ADA Australia",
    db_url: "https://cardanode.com.au",
    roa: "3.771",
    roa_lifetime: "3.827",
    total_stake: "59036557880963",
    stake_x_deleg: "11989552778.425",
    saturated: "0.85878758812401",
    luck_lifetime: "93",
    blocks_epoch: "47",
    blocks_lifetime: "1662",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEDSCAVE.IO",
    icon: "https://cdn.adapools.org/pool_logo/c4ded02c3f7275e4b6157dd164bf941eea0af4596501c5ed16752ed2.png",
    pool_id: "c4ded02c3f7275e4b6157dd164bf941eea0af4596501c5ed16752ed2",
    db_ticker: "NEDS2",
    db_name: "NEDSCAVE.IO",
    db_url: "https://nedscave.io",
    roa: "3.628",
    roa_lifetime: "4.481",
    total_stake: "40991938175592",
    stake_x_deleg: "31339402274.917",
    saturated: "0.59629776839847",
    luck_lifetime: "99",
    blocks_epoch: "24",
    blocks_lifetime: "2542",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Berry",
    icon: "https://cdn.adapools.org/pool_logo/2a748e3885f6f73320ad16a8331247b81fe01b8d39f57eec9caa5091.png",
    pool_id: "2a748e3885f6f73320ad16a8331247b81fe01b8d39f57eec9caa5091",
    db_ticker: "BERRY",
    db_name: "Berry",
    db_url: "https://pipool.online",
    roa: "4.541",
    roa_lifetime: "4.235",
    total_stake: "35244877258668",
    stake_x_deleg: "1673625398.1038",
    saturated: "0.51269694950251",
    luck_lifetime: "100",
    blocks_epoch: "20",
    blocks_lifetime: "1308",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/08453273401b3ee2f380ec570f8c926d5f3bd3522337d03b566a6b61.png",
    pool_id: "08453273401b3ee2f380ec570f8c926d5f3bd3522337d03b566a6b61",
    db_ticker: "DIGI3",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "3.344",
    roa_lifetime: "4.635",
    total_stake: "37162322356040",
    stake_x_deleg: "32230982095.438",
    saturated: "0.54058946406701",
    luck_lifetime: "100",
    blocks_epoch: "29",
    blocks_lifetime: "5119",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POWER Stake Pool 1",
    icon: "https://cdn.adapools.org/pool_logo/e594951faeec31cb8efc70c3e36887f18f8bc62c718f25ec64807dfa.png",
    pool_id: "e594951faeec31cb8efc70c3e36887f18f8bc62c718f25ec64807dfa",
    db_ticker: "POWER",
    db_name: "POWER Stake Pool 1",
    db_url: "https://powerstakepool.com",
    roa: "4.247",
    roa_lifetime: "4.633",
    total_stake: "24246682576154",
    stake_x_deleg: "3475728580.2973",
    saturated: "0.3527094193325",
    luck_lifetime: "99",
    blocks_epoch: "18",
    blocks_lifetime: "3779",
    tax_ratio: "0.0097",
    tax_fix: "340000000",
    pledge: "135000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking4ADA.org",
    icon: "https://cdn.adapools.org/pool_logo/a631b46315b1567ef0ce2b2ad4cb63faac09f32ceba6716a7e331e5e.png",
    pool_id: "a631b46315b1567ef0ce2b2ad4cb63faac09f32ceba6716a7e331e5e",
    db_ticker: "4ADA",
    db_name: "Staking4ADA.org",
    db_url: "https://staking4ADA.org",
    roa: "3.873",
    roa_lifetime: "4.742",
    total_stake: "36249304811716",
    stake_x_deleg: "34888647556.993",
    saturated: "0.5273080641523",
    luck_lifetime: "98",
    blocks_epoch: "19",
    blocks_lifetime: "3966",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/fb21d3e5ed7684742936778239a7bed2bcee1292964ddeb716d3a903.png",
    pool_id: "fb21d3e5ed7684742936778239a7bed2bcee1292964ddeb716d3a903",
    db_ticker: "DIGI2",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "3.879",
    roa_lifetime: "4.859",
    total_stake: "34453471721837",
    stake_x_deleg: "32472640642.636",
    saturated: "0.50118460398986",
    luck_lifetime: "100",
    blocks_epoch: "31",
    blocks_lifetime: "6490",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swimming Pool",
    icon: "https://cdn.adapools.org/pool_logo/31af0509d4376a8a55b40e952aea7f009c13cadf0338c57512ff42e9.png",
    pool_id: "31af0509d4376a8a55b40e952aea7f009c13cadf0338c57512ff42e9",
    db_ticker: "SWIM",
    db_name: "The Swimming Pool",
    db_url: "https://theswim.net",
    roa: "3.962",
    roa_lifetime: "4.842",
    total_stake: "28660139367558",
    stake_x_deleg: "8264169367.808",
    saturated: "0.41691068799084",
    luck_lifetime: "97",
    blocks_epoch: "15",
    blocks_lifetime: "6613",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "425000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RAMEN by Kiwipool Staking",
    icon: "https://cdn.adapools.org/pool_logo/2523d71a1bd6376804e0bace048289a63455eb6340db81e616a11f9c.png",
    pool_id: "2523d71a1bd6376804e0bace048289a63455eb6340db81e616a11f9c",
    db_ticker: "RAMEN",
    db_name: "RAMEN by Kiwipool Staking",
    db_url: "https://www.kiwipool.org",
    roa: "3.353",
    roa_lifetime: "4.508",
    total_stake: "36482360606506",
    stake_x_deleg: "28546448048.909",
    saturated: "0.53069825882302",
    luck_lifetime: "100",
    blocks_epoch: "24",
    blocks_lifetime: "3562",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "222369000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "QueenAda",
    icon: "https://cdn.adapools.org/pool_logo/b40683f4baad755ff60f26dc73c3e371ac4c5e422feef2fc1f5f29bf.png",
    pool_id: "b40683f4baad755ff60f26dc73c3e371ac4c5e422feef2fc1f5f29bf",
    db_ticker: "QUEEN",
    db_name: "QueenAda",
    db_url: "https://queenada.com",
    roa: "4.127",
    roa_lifetime: "4.638",
    total_stake: "31839914980036",
    stake_x_deleg: "14316508534.189",
    saturated: "0.46316595637084",
    luck_lifetime: "97",
    blocks_epoch: "15",
    blocks_lifetime: "3625",
    tax_ratio: "0.0069",
    tax_fix: "420000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADASTACK",
    icon: "https://cdn.adapools.org/pool_logo/94864d27407c1dd0569e4acb9c7fa6ec2cc19a6193d9171f2d811232.png",
    pool_id: "94864d27407c1dd0569e4acb9c7fa6ec2cc19a6193d9171f2d811232",
    db_ticker: "STACK",
    db_name: "ADASTACK",
    db_url: "https://adastack.net",
    roa: "3.92",
    roa_lifetime: "4.934",
    total_stake: "27553572819391",
    stake_x_deleg: "20215387248.269",
    saturated: "0.40081378717024",
    luck_lifetime: "101",
    blocks_epoch: "21",
    blocks_lifetime: "5042",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1010000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THOR - The Viking Pool",
    icon: "https://cdn.adapools.org/pool_logo/40d04c1367c25e6da2521f906f71bbb07858dc1d2209025364369173.png",
    pool_id: "40d04c1367c25e6da2521f906f71bbb07858dc1d2209025364369173",
    db_ticker: "THOR",
    db_name: "THOR - The Viking Pool",
    db_url: "https://thevikingpool.com",
    roa: "4.073",
    roa_lifetime: "4.693",
    total_stake: "22225096998795",
    stake_x_deleg: "5045424971.3496",
    saturated: "0.3233020035806",
    luck_lifetime: "104",
    blocks_epoch: "22",
    blocks_lifetime: "2930",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aichi Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/970e9a7ae4677b152c27a0eba3db996b372de094d24fc2974768f3da.png",
    pool_id: "970e9a7ae4677b152c27a0eba3db996b372de094d24fc2974768f3da",
    db_ticker: "AICHI",
    db_name: "Aichi Stake Pool",
    db_url: "https://aichi-stakepool.com/",
    roa: "3.832",
    roa_lifetime: "4.458",
    total_stake: "57465955547219",
    stake_x_deleg: "120726797368.11",
    saturated: "0.83594049407734",
    luck_lifetime: "98",
    blocks_epoch: "49",
    blocks_lifetime: "3693",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AzureADA2",
    icon: "https://cdn.adapools.org/pool_logo/dd7330d29709deb4e8dff5b49d50b174c9c884678992cca4a68d96d7.png",
    pool_id: "dd7330d29709deb4e8dff5b49d50b174c9c884678992cca4a68d96d7",
    db_ticker: "AZUR2",
    db_name: "AzureADA2",
    db_url: "https://azureada.com",
    roa: "4.044",
    roa_lifetime: "4.201",
    total_stake: "32836809083328",
    stake_x_deleg: "7468002975.5124",
    saturated: "0.47766748412433",
    luck_lifetime: "96",
    blocks_epoch: "23",
    blocks_lifetime: "2391",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking OutOfBits",
    icon: "https://cdn.adapools.org/pool_logo/cdae4a1a08974113e77ea332cb1da97d9e3fca5cf797f9394739214b.png",
    pool_id: "cdae4a1a08974113e77ea332cb1da97d9e3fca5cf797f9394739214b",
    db_ticker: "SOBIT",
    db_name: "Staking OutOfBits",
    db_url: "https://staking.outofbits.com/",
    roa: "3.721",
    roa_lifetime: "4.88",
    total_stake: "24398983741039",
    stake_x_deleg: "11030281980.578",
    saturated: "0.35492489995594",
    luck_lifetime: "99",
    blocks_epoch: "22",
    blocks_lifetime: "4815",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "210000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rocky Mountain Pool",
    icon: "https://cdn.adapools.org/pool_logo/f4762ca1dce3c32c0d7a7e6d9f8a54bf40338e99ae5f3ad0172c9a2f.png",
    pool_id: "f4762ca1dce3c32c0d7a7e6d9f8a54bf40338e99ae5f3ad0172c9a2f",
    db_ticker: "ROCKY",
    db_name: "Rocky Mountain Pool",
    db_url: "https://stakecardano.net",
    roa: "3.513",
    roa_lifetime: "4.559",
    total_stake: "26846082750767",
    stake_x_deleg: "3619533874.9854",
    saturated: "0.39052213549773",
    luck_lifetime: "100",
    blocks_epoch: "18",
    blocks_lifetime: "3632",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "151000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/90a9f94272aa45bb1eb27bb0e2fda43eb4d9bfd31010ab2c9c249896.png",
    pool_id: "90a9f94272aa45bb1eb27bb0e2fda43eb4d9bfd31010ab2c9c249896",
    db_ticker: "DIGI4",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "3.771",
    roa_lifetime: "4.535",
    total_stake: "31219520365446",
    stake_x_deleg: "22476256562.596",
    saturated: "0.45414125686476",
    luck_lifetime: "98",
    blocks_epoch: "26",
    blocks_lifetime: "3919",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/7f6c103302f96390d478a170fe80938b76fccd8a23490e3b6ddebcf7.png",
    pool_id: "7f6c103302f96390d478a170fe80938b76fccd8a23490e3b6ddebcf7",
    db_ticker: "GOAT",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "3.82",
    roa_lifetime: "4.654",
    total_stake: "60253671098598",
    stake_x_deleg: "4383998188.1983",
    saturated: "0.87649257910187",
    luck_lifetime: "101",
    blocks_epoch: "48",
    blocks_lifetime: "5507",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kaizen Crypto Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/9679eaa0fa242a9cdae4b030e714b66c0119fc9b3f7564b8f03a5316.png",
    pool_id: "9679eaa0fa242a9cdae4b030e714b66c0119fc9b3f7564b8f03a5316",
    db_ticker: "KAIZN",
    db_name: "Kaizen Crypto Stake Pool",
    db_url: "https://www.kaizencrypto.com",
    roa: "3.165",
    roa_lifetime: "4.748",
    total_stake: "28059872169523",
    stake_x_deleg: "9447768407.2468",
    saturated: "0.40817877614276",
    luck_lifetime: "98",
    blocks_epoch: "20",
    blocks_lifetime: "5568",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Brothership Pool",
    icon: "https://cdn.adapools.org/pool_logo/89eba2781e5cd11ccda6be56503702a39e9941e522f04cd5bba22957.png",
    pool_id: "89eba2781e5cd11ccda6be56503702a39e9941e522f04cd5bba22957",
    db_ticker: "BSP",
    db_name: "The Brothership Pool",
    db_url: "https://brothershipool.org",
    roa: "3.756",
    roa_lifetime: "4.384",
    total_stake: "45612988282832",
    stake_x_deleg: "82632225150.058",
    saturated: "0.66351883647291",
    luck_lifetime: "96",
    blocks_epoch: "34",
    blocks_lifetime: "2617",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "440000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "banderini",
    icon: "https://cdn.adapools.org/pool_logo/4ee9e4c2ec6fc68850330dbc75ccd7ebf7951f20e1085d324f36cf44.png",
    pool_id: "4ee9e4c2ec6fc68850330dbc75ccd7ebf7951f20e1085d324f36cf44",
    db_ticker: "BANDA",
    db_name: "banderini",
    db_url: "https://banderini.net",
    roa: "4.133",
    roa_lifetime: "4.324",
    total_stake: "29766759402510",
    stake_x_deleg: "11835689623.264",
    saturated: "0.43300836686809",
    luck_lifetime: "96",
    blocks_epoch: "25",
    blocks_lifetime: "2323",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Two",
    icon: "https://cdn.adapools.org/pool_logo/c2c52eab0d713209b337d8ff8fe28e1ccae2f70eb7ed1ee27e8c866a.png",
    pool_id: "c2c52eab0d713209b337d8ff8fe28e1ccae2f70eb7ed1ee27e8c866a",
    db_ticker: "OCEA2",
    db_name: "ADA Ocean Two",
    db_url: "https://adaocean.com/",
    roa: "3.997",
    roa_lifetime: "4.945",
    total_stake: "56701802055116",
    stake_x_deleg: "67502145303.71",
    saturated: "0.82482457611066",
    luck_lifetime: "101",
    blocks_epoch: "37",
    blocks_lifetime: "5808",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HOPE",
    icon: "0",
    pool_id: "65ea43eab7c0143d6bb68f9d56c276bce09377bdc41e1a42ac9535ed",
    db_ticker: "HOPE",
    db_name: "HOPE",
    db_url: "https://cardano.ipclub29.com/",
    roa: "3.606",
    roa_lifetime: "4.853",
    total_stake: "24495033575859",
    stake_x_deleg: "14800624517.135",
    saturated: "0.35632210888792",
    luck_lifetime: "98",
    blocks_epoch: "16",
    blocks_lifetime: "4329",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ad Astra Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/032f086ac6dafeaf4c75e21840f49762ebdab05ff9c25c41a8530e0f.png",
    pool_id: "032f086ac6dafeaf4c75e21840f49762ebdab05ff9c25c41a8530e0f",
    db_ticker: "ASTRA",
    db_name: "Ad Astra Stake Pool",
    db_url: "https://www.astra-pool.com/",
    roa: "3.72",
    roa_lifetime: "4.679",
    total_stake: "32554435878061",
    stake_x_deleg: "30538870429.701",
    saturated: "0.47355988346795",
    luck_lifetime: "101",
    blocks_epoch: "22",
    blocks_lifetime: "2349",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Moon Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/059b4217a24a8c67a363968ff1db13a17ed96e611362450c115b2415.png",
    pool_id: "059b4217a24a8c67a363968ff1db13a17ed96e611362450c115b2415",
    db_ticker: "AMOON",
    db_name: "ADA Moon Stake Pool",
    db_url: "https://adamoon.net",
    roa: "4.242",
    roa_lifetime: "4.042",
    total_stake: "39432859260091",
    stake_x_deleg: "48622514500.729",
    saturated: "0.57361830215591",
    luck_lifetime: "102",
    blocks_epoch: "34",
    blocks_lifetime: "747",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANIANS.io #4",
    icon: "https://cdn.adapools.org/pool_logo/b6e038fb50202dfe5ccc61ecae9b8803bdc9cb7fb3ae9348cb97b0ef.png",
    pool_id: "b6e038fb50202dfe5ccc61ecae9b8803bdc9cb7fb3ae9348cb97b0ef",
    db_ticker: "CRDN3",
    db_name: "CARDANIANS.io #4",
    db_url: "https://cardanians.io",
    roa: "3.59",
    roa_lifetime: "4.504",
    total_stake: "19832423175434",
    stake_x_deleg: "15127706464.862",
    saturated: "0.28849647535053",
    luck_lifetime: "100",
    blocks_epoch: "13",
    blocks_lifetime: "2537",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AzureADA3",
    icon: "https://cdn.adapools.org/pool_logo/bdadf198bdc79d4f37c5dcd9ed0cdf402bdc2299c3ae4c87d688a6fe.png",
    pool_id: "bdadf198bdc79d4f37c5dcd9ed0cdf402bdc2299c3ae4c87d688a6fe",
    db_ticker: "AZUR3",
    db_name: "AzureADA3",
    db_url: "https://azureada.com",
    roa: "3.815",
    roa_lifetime: "4.057",
    total_stake: "29976837222420",
    stake_x_deleg: "10100012541.247",
    saturated: "0.43606430764029",
    luck_lifetime: "98",
    blocks_epoch: "23",
    blocks_lifetime: "1254",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakit.io Pool 2 by TOBG",
    icon: "https://cdn.adapools.org/pool_logo/afb33d38e92e03f9ce86f0175cc33e487b8def0ee25235f1e40183d1.png",
    pool_id: "afb33d38e92e03f9ce86f0175cc33e487b8def0ee25235f1e40183d1",
    db_ticker: "STI2",
    db_name: "stakit.io Pool 2 by TOBG",
    db_url: "https://stakit.io",
    roa: "4.34",
    roa_lifetime: "4.359",
    total_stake: "27522143318877",
    stake_x_deleg: "9914316757.5205",
    saturated: "0.4003565913934",
    luck_lifetime: "99",
    blocks_epoch: "25",
    blocks_lifetime: "2004",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Node Networks",
    icon: "https://cdn.adapools.org/pool_logo/de950fe87de4b25c5a63b5ddf89578adf92e36cdf2035f1da11797d4.png",
    pool_id: "de950fe87de4b25c5a63b5ddf89578adf92e36cdf2035f1da11797d4",
    db_ticker: "NODE",
    db_name: "Node Networks",
    db_url: "https://nodenetworks.org",
    roa: "4.068",
    roa_lifetime: "4.934",
    total_stake: "41426967498974",
    stake_x_deleg: "8762048963.404",
    saturated: "0.60262601307939",
    luck_lifetime: "101",
    blocks_epoch: "36",
    blocks_lifetime: "5111",
    tax_ratio: "0.0089",
    tax_fix: "340000000",
    pledge: "135000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COOL",
    icon: "https://cdn.adapools.org/pool_logo/6198d2070b6e70cc2a5e370a43dd49699960c88a084ffe390abe9a6a.png",
    pool_id: "6198d2070b6e70cc2a5e370a43dd49699960c88a084ffe390abe9a6a",
    db_ticker: "COOL",
    db_name: "COOL",
    db_url: "https://www.stakecool.io",
    roa: "3.746",
    roa_lifetime: "4.962",
    total_stake: "21074398107979",
    stake_x_deleg: "17905181060.305",
    saturated: "0.3065631224437",
    luck_lifetime: "99",
    blocks_epoch: "15",
    blocks_lifetime: "5932",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ALPS - The Alpine Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/16d216d074625d2dbb75c20948e4d9a1efe0fe79c4c915d4b5391de1.png",
    pool_id: "16d216d074625d2dbb75c20948e4d9a1efe0fe79c4c915d4b5391de1",
    db_ticker: "ALPS",
    db_name: "ALPS - The Alpine Stake Pool",
    db_url: "https://alps.kalu.solutions",
    roa: "3.875",
    roa_lifetime: "4.607",
    total_stake: "21966808683154",
    stake_x_deleg: "3357812394.2455",
    saturated: "0.3195447587887",
    luck_lifetime: "100",
    blocks_epoch: "14",
    blocks_lifetime: "3651",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Elite Stake Pool 1",
    icon: "https://cdn.adapools.org/pool_logo/03ba735795ea8294bccd63efd305168ac8367e9d6fd5f0a6225e12b0.png",
    pool_id: "03ba735795ea8294bccd63efd305168ac8367e9d6fd5f0a6225e12b0",
    db_ticker: "ELITE",
    db_name: "Elite Stake Pool 1",
    db_url: "https://elitestakepool.com",
    roa: "4.123",
    roa_lifetime: "4.739",
    total_stake: "20818342208935",
    stake_x_deleg: "9475804373.6618",
    saturated: "0.3028383519649",
    luck_lifetime: "98",
    blocks_epoch: "16",
    blocks_lifetime: "4619",
    tax_ratio: "0.007",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Canucks Pool",
    icon: "https://cdn.adapools.org/pool_logo/4deb0ba979fe7af5a5a4896ef68cdae029ad116542555cb422f46348.png",
    pool_id: "4deb0ba979fe7af5a5a4896ef68cdae029ad116542555cb422f46348",
    db_ticker: "CANUK",
    db_name: "Cardano Canucks Pool",
    db_url: "https://cardanocanucks.com",
    roa: "3.824",
    roa_lifetime: "4.618",
    total_stake: "25251747876842",
    stake_x_deleg: "23822403657.398",
    saturated: "0.36732981111119",
    luck_lifetime: "98",
    blocks_epoch: "25",
    blocks_lifetime: "3678",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "550000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CoffeePool️",
    icon: "https://cdn.adapools.org/pool_logo/1d2972246d8adda98836626a34e337525f5206e552715d28379b5fdb.png",
    pool_id: "1d2972246d8adda98836626a34e337525f5206e552715d28379b5fdb",
    db_ticker: "COFFE",
    db_name: "CoffeePool️",
    db_url: "https://coffeepool.jp",
    roa: "3.951",
    roa_lifetime: "4.6",
    total_stake: "31843600164635",
    stake_x_deleg: "52202623220.713",
    saturated: "0.46321956367633",
    luck_lifetime: "104",
    blocks_epoch: "33",
    blocks_lifetime: "2520",
    tax_ratio: "0.011",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A CardanoLand pool ACL",
    icon: "https://cdn.adapools.org/pool_logo/bdd750e173f3d606c1af13b8661da7c0b5fa43121741c593cceec822.png",
    pool_id: "bdd750e173f3d606c1af13b8661da7c0b5fa43121741c593cceec822",
    db_ticker: "ACL",
    db_name: "A CardanoLand pool ACL",
    db_url: "https://cardanoland.com",
    roa: "3.717",
    roa_lifetime: "4.807",
    total_stake: "21002705007492",
    stake_x_deleg: "17444107149.08",
    saturated: "0.30552022382186",
    luck_lifetime: "99",
    blocks_epoch: "19",
    blocks_lifetime: "4784",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JOY",
    icon: "0",
    pool_id: "fd832d27b5fbe0489409613f50e2139b54a21e613c8a7609dcff556e",
    db_ticker: "JOY",
    db_name: "JOY",
    db_url: "https://cardano.ipclub29.com/",
    roa: "4.406",
    roa_lifetime: "4.949",
    total_stake: "23238629227794",
    stake_x_deleg: "34581293493.741",
    saturated: "0.33804556129585",
    luck_lifetime: "99",
    blocks_epoch: "22",
    blocks_lifetime: "5192",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano With Paul ️",
    icon: "https://cdn.adapools.org/pool_logo/86a19d5418cd07294af7d71386cf14a84e0478fbfde5b89fe4d98a58.png",
    pool_id: "86a19d5418cd07294af7d71386cf14a84e0478fbfde5b89fe4d98a58",
    db_ticker: "PAUL1",
    db_name: "Cardano With Paul ️",
    db_url: "https://www.cardanowithpaul.com",
    roa: "3.807",
    roa_lifetime: "4.259",
    total_stake: "44828458342194",
    stake_x_deleg: "5221110918.0287",
    saturated: "0.65210650825265",
    luck_lifetime: "97",
    blocks_epoch: "31",
    blocks_lifetime: "3932",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/fc88e862f18afc8ea5eaee720b003cb71f3405df2a76faafdca43f43.png",
    pool_id: "fc88e862f18afc8ea5eaee720b003cb71f3405df2a76faafdca43f43",
    db_ticker: "NGINE",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "4.382",
    roa_lifetime: "4.887",
    total_stake: "18515527984170",
    stake_x_deleg: "17467479230.349",
    saturated: "0.269339985106",
    luck_lifetime: "101",
    blocks_epoch: "13",
    blocks_lifetime: "3168",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAvault Stake Pool 3",
    icon: "https://cdn.adapools.org/pool_logo/edfa208d441511f9595ba80e8f3a7b07b6a80cbc9dda9d8e9d1dc039.png",
    pool_id: "edfa208d441511f9595ba80e8f3a7b07b6a80cbc9dda9d8e9d1dc039",
    db_ticker: "ADV3",
    db_name: "ADAvault Stake Pool 3",
    db_url: "https://adavault.com",
    roa: "3.906",
    roa_lifetime: "4.527",
    total_stake: "21518014795317",
    stake_x_deleg: "14840010203.667",
    saturated: "0.3130162850034",
    luck_lifetime: "103",
    blocks_epoch: "17",
    blocks_lifetime: "1282",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VINCE pool",
    icon: "https://cdn.adapools.org/pool_logo/f8024d90482a3f839c7ee0c2be31ed3ad4be1564f1970bb0db4078f9.png",
    pool_id: "f8024d90482a3f839c7ee0c2be31ed3ad4be1564f1970bb0db4078f9",
    db_ticker: "VINCE",
    db_name: "VINCE pool",
    db_url: "https://www.vincepool.com",
    roa: "3.026",
    roa_lifetime: "4.802",
    total_stake: "16485532008953",
    stake_x_deleg: "6715084321.3658",
    saturated: "0.23981022574955",
    luck_lifetime: "99",
    blocks_epoch: "10",
    blocks_lifetime: "3680",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lovelacepool",
    icon: "https://cdn.adapools.org/pool_logo/4aea697c45e39e37e5d842ec8cf6c01a714e88db33a1ba0565ab59af.png",
    pool_id: "4aea697c45e39e37e5d842ec8cf6c01a714e88db33a1ba0565ab59af",
    db_ticker: "1LOVE",
    db_name: "Lovelacepool",
    db_url: "https://lovelacepool.com",
    roa: "3.952",
    roa_lifetime: "4.06",
    total_stake: "53286066659756",
    stake_x_deleg: "46538049484.503",
    saturated: "0.77513686959218",
    luck_lifetime: "97",
    blocks_epoch: "43",
    blocks_lifetime: "1691",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "712000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONYX Stake Pool #1",
    icon: "0",
    pool_id: "f1b265f1e545da9c6e003c5d703fb5a23283c646cd40b2d619671e7f",
    db_ticker: "ONYX",
    db_name: "ONYX Stake Pool #1",
    db_url: "https://onyxstakepool.com",
    roa: "3.435",
    roa_lifetime: "4.758",
    total_stake: "56440932094425",
    stake_x_deleg: "28914411933.619",
    saturated: "0.82102977688122",
    luck_lifetime: "99",
    blocks_epoch: "45",
    blocks_lifetime: "4429",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BITA",
    icon: "https://cdn.adapools.org/pool_logo/3d1e6611b34537690ace03b54d689eceaf01a3b6c4487f487300ec37.png",
    pool_id: "3d1e6611b34537690ace03b54d689eceaf01a3b6c4487f487300ec37",
    db_ticker: "BITA",
    db_name: "BITA",
    db_url: "https://bitapool.wixsite.com/bita",
    roa: "3.918",
    roa_lifetime: "4.548",
    total_stake: "21255490470014",
    stake_x_deleg: "20859166310.122",
    saturated: "0.30919742021447",
    luck_lifetime: "101",
    blocks_epoch: "22",
    blocks_lifetime: "1332",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "560000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sunshine Stake Pool #2",
    icon: "https://cdn.adapools.org/pool_logo/09d372205e474b745eeedd87aa86295cfdef9b85991ad640101756bc.png",
    pool_id: "09d372205e474b745eeedd87aa86295cfdef9b85991ad640101756bc",
    db_ticker: "SUNNY",
    db_name: "Sunshine Stake Pool #2",
    db_url: "https://sunnyada.io",
    roa: "3.833",
    roa_lifetime: "4.674",
    total_stake: "22042765290905",
    stake_x_deleg: "19558797951.114",
    saturated: "0.32064967740716",
    luck_lifetime: "100",
    blocks_epoch: "18",
    blocks_lifetime: "3376",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean",
    icon: "https://cdn.adapools.org/pool_logo/eaa778d97077ff7725fe4cbb70b514d840407a45a3c244ac05f6a83d.png",
    pool_id: "eaa778d97077ff7725fe4cbb70b514d840407a45a3c244ac05f6a83d",
    db_ticker: "OCEAN",
    db_name: "ADA Ocean",
    db_url: "https://adaocean.com/",
    roa: "3.376",
    roa_lifetime: "4.853",
    total_stake: "36923227316741",
    stake_x_deleg: "14617271305.123",
    saturated: "0.53711141826789",
    luck_lifetime: "97",
    blocks_epoch: "30",
    blocks_lifetime: "6908",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "#5 Nordic Pool",
    icon: "https://cdn.adapools.org/pool_logo/988b7a8fa476f5be2987ffaebe740cdcdfafc7790f3a624c22fbbf28.png",
    pool_id: "988b7a8fa476f5be2987ffaebe740cdcdfafc7790f3a624c22fbbf28",
    db_ticker: "NORTH",
    db_name: "#5 Nordic Pool",
    db_url: "https://nordicpool.org",
    roa: "3.682",
    roa_lifetime: "3.798",
    total_stake: "27459864898247",
    stake_x_deleg: "16887985792.28",
    saturated: "0.39945064537343",
    luck_lifetime: "93",
    blocks_epoch: "28",
    blocks_lifetime: "714",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "110000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hyperlink Pool",
    icon: "https://cdn.adapools.org/pool_logo/263498e010c7a49bbfd7c4e1aab29809fca7ed993f9e14192a75871e.png",
    pool_id: "263498e010c7a49bbfd7c4e1aab29809fca7ed993f9e14192a75871e",
    db_ticker: "HYPER",
    db_name: "Hyperlink Pool",
    db_url: "https://hyperlinkpool.kr",
    roa: "4.264",
    roa_lifetime: "4.719",
    total_stake: "14660762289358",
    stake_x_deleg: "5008801602.1039",
    saturated: "0.21326583287467",
    luck_lifetime: "98",
    blocks_epoch: "13",
    blocks_lifetime: "4195",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAGE",
    icon: "https://cdn.adapools.org/pool_logo/83b259c187f8b88eda564b1f56ce2786360a9dc224f737fcdd8703c1.png",
    pool_id: "83b259c187f8b88eda564b1f56ce2786360a9dc224f737fcdd8703c1",
    db_ticker: "ADAGE",
    db_name: "ADAGE",
    db_url: "https://adage.app",
    roa: "3.982",
    roa_lifetime: "4.93",
    total_stake: "14450314126576",
    stake_x_deleg: "10816103388.156",
    saturated: "0.21020450483273",
    luck_lifetime: "101",
    blocks_epoch: "9",
    blocks_lifetime: "3837",
    tax_ratio: "0.0084",
    tax_fix: "380000000",
    pledge: "105000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEDSCAVE.IO",
    icon: "https://cdn.adapools.org/pool_logo/47605a7c1fc5e8195335fb91e3a0df07adb2993c1a5b4a78d55a1d6a.png",
    pool_id: "47605a7c1fc5e8195335fb91e3a0df07adb2993c1a5b4a78d55a1d6a",
    db_ticker: "NEDS3",
    db_name: "NEDSCAVE.IO",
    db_url: "https://nedscave.io",
    roa: "3.438",
    roa_lifetime: "4.099",
    total_stake: "27994136861018",
    stake_x_deleg: "36882920765.505",
    saturated: "0.40722254378315",
    luck_lifetime: "100",
    blocks_epoch: "30",
    blocks_lifetime: "1284",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AAA",
    icon: "https://cdn.adapools.org/pool_logo/fcbfb4a3c18f890de7a51f68603b18e654f8b432abdda17c53a0d586.png",
    pool_id: "fcbfb4a3c18f890de7a51f68603b18e654f8b432abdda17c53a0d586",
    db_ticker: "AAA",
    db_name: "AAA",
    db_url: "https://stakelovelace.io",
    roa: "4.489",
    roa_lifetime: "4.808",
    total_stake: "23253375803285",
    stake_x_deleg: "12200092236.771",
    saturated: "0.33826007542834",
    luck_lifetime: "98",
    blocks_epoch: "22",
    blocks_lifetime: "5050",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "the Token Square",
    icon: "https://cdn.adapools.org/pool_logo/5790d62ab1ba703e861fe800f9cefaaf1485c3ca42c6ba9ce74690a1.png",
    pool_id: "5790d62ab1ba703e861fe800f9cefaaf1485c3ca42c6ba9ce74690a1",
    db_ticker: "TTS17",
    db_name: "the Token Square",
    db_url: "https://tts17.com",
    roa: "4.337",
    roa_lifetime: "4.289",
    total_stake: "26448443415405",
    stake_x_deleg: "24904372330.89",
    saturated: "0.38473779206688",
    luck_lifetime: "108",
    blocks_epoch: "16",
    blocks_lifetime: "475",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "54000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEAD Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ec6cdcad62633e065be5d96eecea28464144a1827adc0cba98d29a23.png",
    pool_id: "ec6cdcad62633e065be5d96eecea28464144a1827adc0cba98d29a23",
    db_ticker: "LEAD",
    db_name: "LEAD Stake Pool",
    db_url: "https://www.leadstakepool.com",
    roa: "3.675",
    roa_lifetime: "4.581",
    total_stake: "17416942258576",
    stake_x_deleg: "13989511854.278",
    saturated: "0.25335917898358",
    luck_lifetime: "100",
    blocks_epoch: "15",
    blocks_lifetime: "1710",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "401000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pilot Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/7d50b9c6ec097e2a7f4ecb2f2914f9ad21e1a7bda884cb143937a73f.png",
    pool_id: "7d50b9c6ec097e2a7f4ecb2f2914f9ad21e1a7bda884cb143937a73f",
    db_ticker: "PILOT",
    db_name: "Pilot Pool 2",
    db_url: "https://www.adapilot.com",
    roa: "4.586",
    roa_lifetime: "4.439",
    total_stake: "10837901305590",
    stake_x_deleg: "6497542749.1547",
    saturated: "0.15765578916915",
    luck_lifetime: "106",
    blocks_epoch: "6",
    blocks_lifetime: "624",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "325000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake with Pride - Ardana + Hosky ISPO",
    icon: "https://cdn.adapools.org/pool_logo/93ca5c2f1eb63054f7c768a807408a43e65d7c68fe56e330db20af51.png",
    pool_id: "93ca5c2f1eb63054f7c768a807408a43e65d7c68fe56e330db20af51",
    db_ticker: "PRIDE",
    db_name: "Stake with Pride - Ardana + Hosky ISPO",
    db_url: "https://www.StakeWithPride.com",
    roa: "3.584",
    roa_lifetime: "3.971",
    total_stake: "28543244152274",
    stake_x_deleg: "23628513371.088",
    saturated: "0.41521024738928",
    luck_lifetime: "99",
    blocks_epoch: "28",
    blocks_lifetime: "792",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " PLANETSTAKE 1",
    icon: "https://cdn.adapools.org/pool_logo/0019cb5ac91c786a809f4d87622a29d2c7f57f4697f8c8e8457f4de4.png",
    pool_id: "0019cb5ac91c786a809f4d87622a29d2c7f57f4697f8c8e8457f4de4",
    db_ticker: "STAKE",
    db_name: " PLANETSTAKE 1",
    db_url: "https://planetstake.com",
    roa: "3.661",
    roa_lifetime: "4.715",
    total_stake: "46083596782217",
    stake_x_deleg: "42669997020.571",
    saturated: "0.67036464104966",
    luck_lifetime: "97",
    blocks_epoch: "28",
    blocks_lifetime: "4928",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MANTIS 1PERCENT",
    icon: "https://cdn.adapools.org/pool_logo/54f5f2618a3da4bb0b384895d8f3922189acbb8e9637201ebb26bc18.png",
    pool_id: "54f5f2618a3da4bb0b384895d8f3922189acbb8e9637201ebb26bc18",
    db_ticker: "MANT",
    db_name: "MANTIS 1PERCENT",
    db_url: "https://mantispool.net",
    roa: "3.544",
    roa_lifetime: "4.718",
    total_stake: "20927669695429",
    stake_x_deleg: "36522983761.656",
    saturated: "0.30442870702306",
    luck_lifetime: "97",
    blocks_epoch: "17",
    blocks_lifetime: "4366",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Three",
    icon: "https://cdn.adapools.org/pool_logo/660f20fb9d419173ac623786ad3e6dc11a23951981e0e83e98233d29.png",
    pool_id: "660f20fb9d419173ac623786ad3e6dc11a23951981e0e83e98233d29",
    db_ticker: "OCEA3",
    db_name: "ADA Ocean Three",
    db_url: "https://adaocean.com/",
    roa: "3.973",
    roa_lifetime: "4.878",
    total_stake: "48811362470344",
    stake_x_deleg: "75559384629.015",
    saturated: "0.71004465289923",
    luck_lifetime: "99",
    blocks_epoch: "41",
    blocks_lifetime: "5750",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hodl₳da",
    icon: "0",
    pool_id: "2f606921baedcad68bbc765b9ab82b2b201d42bd35262517ce5d7d96",
    db_ticker: "HODL₳",
    db_name: "Hodl₳da",
    db_url: "https://hodlada.today",
    roa: "3.859",
    roa_lifetime: "4.46",
    total_stake: "53678049696890",
    stake_x_deleg: "16874583369.032",
    saturated: "0.78083893250249",
    luck_lifetime: "96",
    blocks_epoch: "44",
    blocks_lifetime: "4523",
    tax_ratio: "0.02",
    tax_fix: "500000000",
    pledge: "6445000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool247 | Ardana Stake Pool Alliance member",
    icon: "https://cdn.adapools.org/pool_logo/76e80e1b3f622c7051f222453497b0667e12892f5d94ee565d17dc52.png",
    pool_id: "76e80e1b3f622c7051f222453497b0667e12892f5d94ee565d17dc52",
    db_ticker: "EU01",
    db_name: "StakePool247 | Ardana Stake Pool Alliance member",
    db_url: "https://www.stakepool247.eu/",
    roa: "4.174",
    roa_lifetime: "4.337",
    total_stake: "18589436407262",
    stake_x_deleg: "41494277694.781",
    saturated: "0.27041510937962",
    luck_lifetime: "110",
    blocks_epoch: "19",
    blocks_lifetime: "364",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FOUR SecureStaking.io",
    icon: "https://cdn.adapools.org/pool_logo/881b67128439e781d00190ee7ba1692d5af13aaee5d1914911bb3e42.png",
    pool_id: "881b67128439e781d00190ee7ba1692d5af13aaee5d1914911bb3e42",
    db_ticker: "SECUR",
    db_name: "FOUR SecureStaking.io",
    db_url: "https://cardano.securestaking.io",
    roa: "3.735",
    roa_lifetime: "4.364",
    total_stake: "23542441805744",
    stake_x_deleg: "38094566028.712",
    saturated: "0.34246503425336",
    luck_lifetime: "99",
    blocks_epoch: "25",
    blocks_lifetime: "1932",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAPOP",
    icon: "https://cdn.adapools.org/pool_logo/699c814b5a0f3bf2d35b9b0adcfed9fa0fe891881a9ad3c15e2edf73.png",
    pool_id: "699c814b5a0f3bf2d35b9b0adcfed9fa0fe891881a9ad3c15e2edf73",
    db_ticker: "APOP",
    db_name: "ADAPOP",
    db_url: "https://adapop.org/",
    roa: "4.542",
    roa_lifetime: "4.795",
    total_stake: "34429774288961",
    stake_x_deleg: "15288532099.894",
    saturated: "0.50083988434572",
    luck_lifetime: "101",
    blocks_epoch: "33",
    blocks_lifetime: "4098",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Black Star Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/68f866aa0448d8066426be9e913c171f7f8cc07c0def4eaf92e5df2b.png",
    pool_id: "68f866aa0448d8066426be9e913c171f7f8cc07c0def4eaf92e5df2b",
    db_ticker: "BSSP",
    db_name: "Black Star Stake Pool",
    db_url: "https://blackstarpool.com",
    roa: "3.412",
    roa_lifetime: "4.906",
    total_stake: "10833415836486",
    stake_x_deleg: "9180860878.378",
    saturated: "0.15759054035839",
    luck_lifetime: "102",
    blocks_epoch: "9",
    blocks_lifetime: "2276",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garden Pool Five",
    icon: "https://cdn.adapools.org/pool_logo/aa8ce95c3862c3e5f6e1dccebfcf36f4379484ae9eb42d7a476483b9.png",
    pool_id: "aa8ce95c3862c3e5f6e1dccebfcf36f4379484ae9eb42d7a476483b9",
    db_ticker: "EDEN",
    db_name: "Garden Pool Five",
    db_url: "https://garden-pool.com",
    roa: "4.043",
    roa_lifetime: "4.419",
    total_stake: "20396540625997",
    stake_x_deleg: "29560203805.793",
    saturated: "0.29670252736604",
    luck_lifetime: "98",
    blocks_epoch: "12",
    blocks_lifetime: "1628",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "skylightpool.com",
    icon: "0",
    pool_id: "675304517e38e66880714b3c4816f47866c302edafceb96945fd056f",
    db_ticker: "SKY2",
    db_name: "skylightpool.com",
    db_url: "https://skylightpool.com",
    roa: "3.96",
    roa_lifetime: "4.384",
    total_stake: "50372986957095",
    stake_x_deleg: "55354930722.082",
    saturated: "0.73276114882428",
    luck_lifetime: "96",
    blocks_epoch: "31",
    blocks_lifetime: "3547",
    tax_ratio: "0.0216",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cryptobounty1",
    icon: "https://cdn.adapools.org/pool_logo/6ec027701fa6c9f4a593f7e7108cb2f5af54361080f7bed1c835349d.png",
    pool_id: "6ec027701fa6c9f4a593f7e7108cb2f5af54361080f7bed1c835349d",
    db_ticker: "BNTY1",
    db_name: "Cryptobounty1",
    db_url: "https://cryptobounty.org",
    roa: "4.013",
    roa_lifetime: "4.664",
    total_stake: "25147218567710",
    stake_x_deleg: "60889149074.358",
    saturated: "0.36580925373962",
    luck_lifetime: "100",
    blocks_epoch: "14",
    blocks_lifetime: "1689",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Oasis Pool ",
    icon: "https://cdn.adapools.org/pool_logo/30f9a4c529f89d60ad1bd1c33c0e13c20b347fec7cac794c557efcee.png",
    pool_id: "30f9a4c529f89d60ad1bd1c33c0e13c20b347fec7cac794c557efcee",
    db_ticker: "OASIS",
    db_name: "Oasis Pool ",
    db_url: "https://OasisPool.io",
    roa: "4.335",
    roa_lifetime: "4.662",
    total_stake: "14309732985133",
    stake_x_deleg: "15621979241.412",
    saturated: "0.20815951197188",
    luck_lifetime: "97",
    blocks_epoch: "13",
    blocks_lifetime: "1936",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2800000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Fans",
    icon: "https://cdn.adapools.org/pool_logo/6c518b4861bb88b1395ceb116342cecbcfb8736282655f9a61c4c368.png",
    pool_id: "6c518b4861bb88b1395ceb116342cecbcfb8736282655f9a61c4c368",
    db_ticker: "CRFA",
    db_name: "Cardano Fans",
    db_url: "https://cardano.fans",
    roa: "3.769",
    roa_lifetime: "3.925",
    total_stake: "48094918399351",
    stake_x_deleg: "40112525770.935",
    saturated: "0.69962274996589",
    luck_lifetime: "94",
    blocks_epoch: "43",
    blocks_lifetime: "1957",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef8710427e328a29555283c74b202b40bec9a62630a9f03b1e18.png",
    pool_id: "00beef8710427e328a29555283c74b202b40bec9a62630a9f03b1e18",
    db_ticker: "BCSH0",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.753",
    roa_lifetime: "4.687",
    total_stake: "31713931171990",
    stake_x_deleg: "38912798984.037",
    saturated: "0.46133330666127",
    luck_lifetime: "98",
    blocks_epoch: "18",
    blocks_lifetime: "3952",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "325000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Point Pool",
    icon: "https://cdn.adapools.org/pool_logo/1cb5d0e006c33ace982a527d4140bc086f99f7d35c27c5ab29600a74.png",
    pool_id: "1cb5d0e006c33ace982a527d4140bc086f99f7d35c27c5ab29600a74",
    db_ticker: "APP",
    db_name: "ADA Point Pool",
    db_url: "https://adapointpool.com",
    roa: "3.637",
    roa_lifetime: "4.705",
    total_stake: "19695265562756",
    stake_x_deleg: "41904820346.289",
    saturated: "0.28650128356408",
    luck_lifetime: "99",
    blocks_epoch: "25",
    blocks_lifetime: "2561",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "竜SM₳UG",
    icon: "https://cdn.adapools.org/pool_logo/abacadaba9f12a8b5382fc370e4e7e69421fb59831bb4ecca3a11d9b.png",
    pool_id: "abacadaba9f12a8b5382fc370e4e7e69421fb59831bb4ecca3a11d9b",
    db_ticker: "SMAUG",
    db_name: "竜SM₳UG",
    db_url: "https://smaug.pool.pm",
    roa: "4.681",
    roa_lifetime: "4.332",
    total_stake: "15937945383218",
    stake_x_deleg: "9893200113.7294",
    saturated: "0.23184464282122",
    luck_lifetime: "94",
    blocks_epoch: "12",
    blocks_lifetime: "1808",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "1100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TITANstaking #2",
    icon: "https://cdn.adapools.org/pool_logo/28489654d3fcce6affdde39f37c2d68dda5f42fc9f9feb8dd46d614a.png",
    pool_id: "28489654d3fcce6affdde39f37c2d68dda5f42fc9f9feb8dd46d614a",
    db_ticker: "TITAN",
    db_name: "TITANstaking #2",
    db_url: "https://www.titanstaking.io",
    roa: "4.59",
    roa_lifetime: "4.514",
    total_stake: "13085177957929",
    stake_x_deleg: "5481850841.1935",
    saturated: "0.19034626716079",
    luck_lifetime: "100",
    blocks_epoch: "10",
    blocks_lifetime: "1705",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 3",
    icon: "https://cdn.adapools.org/pool_logo/30432a0b85b6f80b3e9cd356d8b76b9793f5530c91050c1688da4922.png",
    pool_id: "30432a0b85b6f80b3e9cd356d8b76b9793f5530c91050c1688da4922",
    db_ticker: "MS3",
    db_name: "Moonstake 3",
    db_url: "https://moonstake.io",
    roa: "3.601",
    roa_lifetime: "4.457",
    total_stake: "55322747268463",
    stake_x_deleg: "92204578780.772",
    saturated: "0.80476386836228",
    luck_lifetime: "98",
    blocks_epoch: "45",
    blocks_lifetime: "6738",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAFR",
    icon: "0",
    pool_id: "42c3740db23c3fc36cb9f63aa8070ec01417b4d22893463c8c8a21d4",
    db_ticker: "ADAFR",
    db_name: "ADAFR",
    db_url: "https://ada-staking.fr",
    roa: "4.22",
    roa_lifetime: "4.806",
    total_stake: "30923913409973",
    stake_x_deleg: "6799453256.3705",
    saturated: "0.44984114870406",
    luck_lifetime: "99",
    blocks_epoch: "26",
    blocks_lifetime: "5599",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "3030000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crowdano Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/087cb9a9dab66751853893b0de0628aee88c36f82fd0c1ee3b998548.png",
    pool_id: "087cb9a9dab66751853893b0de0628aee88c36f82fd0c1ee3b998548",
    db_ticker: "CROW",
    db_name: "Crowdano Stakepool",
    db_url: "https://crowdano.com",
    roa: "3.525",
    roa_lifetime: "4.55",
    total_stake: "19611004326710",
    stake_x_deleg: "17667571465.505",
    saturated: "0.28527555994005",
    luck_lifetime: "96",
    blocks_epoch: "17",
    blocks_lifetime: "4144",
    tax_ratio: "0.0399",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Krishna Stake Pool by Kaizen Crypto",
    icon: "https://cdn.adapools.org/pool_logo/163cc36624d9332f6ae7770e8df8b8bad7e71711eb6c2ed7971c99d4.png",
    pool_id: "163cc36624d9332f6ae7770e8df8b8bad7e71711eb6c2ed7971c99d4",
    db_ticker: "KRSNA",
    db_name: "Krishna Stake Pool by Kaizen Crypto",
    db_url: "https://www.kaizencrypto.com",
    roa: "4.471",
    roa_lifetime: "4.793",
    total_stake: "15397412837310",
    stake_x_deleg: "17497060042.398",
    saturated: "0.22398167353465",
    luck_lifetime: "102",
    blocks_epoch: "20",
    blocks_lifetime: "3350",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/fc7c663438fd4b61c5d0c064128467a550fd4e721fe6d5f237f5b416.png",
    pool_id: "fc7c663438fd4b61c5d0c064128467a550fd4e721fe6d5f237f5b416",
    db_ticker: "GOAT2",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "3.977",
    roa_lifetime: "4.621",
    total_stake: "55693007770373",
    stake_x_deleg: "197492935355.93",
    saturated: "0.81014993988857",
    luck_lifetime: "101",
    blocks_epoch: "44",
    blocks_lifetime: "5695",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Plush Pool",
    icon: "https://cdn.adapools.org/pool_logo/14cb7963696b6f80abb2298dc60bc2abc7d813abb23ac19c6420bca0.png",
    pool_id: "14cb7963696b6f80abb2298dc60bc2abc7d813abb23ac19c6420bca0",
    db_ticker: "PLUSH",
    db_name: "Plush Pool",
    db_url: "https://plushpool.com/",
    roa: "4.165",
    roa_lifetime: "4.405",
    total_stake: "35051737543733",
    stake_x_deleg: "11402647216.569",
    saturated: "0.50988740240299",
    luck_lifetime: "98",
    blocks_epoch: "23",
    blocks_lifetime: "2871",
    tax_ratio: "0.004",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DISCOVERY",
    icon: "https://cdn.adapools.org/pool_logo/654d96593e499e70889eeae16a15ad8682f8349fd1b77726bbbf8a6d.png",
    pool_id: "654d96593e499e70889eeae16a15ad8682f8349fd1b77726bbbf8a6d",
    db_ticker: "DIVY",
    db_name: "DISCOVERY",
    db_url: "https://discovery-pool.com/",
    roa: "3.99",
    roa_lifetime: "4.891",
    total_stake: "10658408401676",
    stake_x_deleg: "16891296991.563",
    saturated: "0.15504475824915",
    luck_lifetime: "100",
    blocks_epoch: "9",
    blocks_lifetime: "3697",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1129000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dew Staking Solutions",
    icon: "https://cdn.adapools.org/pool_logo/18652168f6def2b2184bd8e0b1b9023fcc9d31edf3008714e3a18e3c.png",
    pool_id: "18652168f6def2b2184bd8e0b1b9023fcc9d31edf3008714e3a18e3c",
    db_ticker: "DEW",
    db_name: "Dew Staking Solutions",
    db_url: "https://www.dew-stake.com",
    roa: "3.84",
    roa_lifetime: "4.783",
    total_stake: "9635460220986",
    stake_x_deleg: "6102254731.4668",
    saturated: "0.14016422943103",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "2034",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "105000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CLIO1",
    icon: "0",
    pool_id: "c1cadab46b74defa9f79b59b617fe2a50bdbce6b367e472b6109a7bc",
    db_ticker: "CLIO1",
    db_name: "CLIO1",
    db_url: "https://clio.one",
    roa: "3.853",
    roa_lifetime: "4.657",
    total_stake: "48275518407231",
    stake_x_deleg: "70993409422.399",
    saturated: "0.70224988560437",
    luck_lifetime: "97",
    blocks_epoch: "30",
    blocks_lifetime: "4853",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ektropy",
    icon: "0",
    pool_id: "71a659c3e71b0ddd0cf9a67bdb3f7fd656934c4a995801ee0ea4c482",
    db_ticker: "EKTRP",
    db_name: "Ektropy",
    db_url: "https://ektrp.com",
    roa: "3.483",
    roa_lifetime: "4.862",
    total_stake: "35145364927193",
    stake_x_deleg: "37151548548.83",
    saturated: "0.51124937264161",
    luck_lifetime: "100",
    blocks_epoch: "27",
    blocks_lifetime: "4815",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONE Pool",
    icon: "https://cdn.adapools.org/pool_logo/9a881d6856d6e31820f653ef066ec55bbc6ce60e1370935e662df659.png",
    pool_id: "9a881d6856d6e31820f653ef066ec55bbc6ce60e1370935e662df659",
    db_ticker: "ONE1",
    db_name: "ONE Pool",
    db_url: "https://adapool.one",
    roa: "2.951",
    roa_lifetime: "4.318",
    total_stake: "15298037610837",
    stake_x_deleg: "14391380631.079",
    saturated: "0.22253609110021",
    luck_lifetime: "97",
    blocks_epoch: "18",
    blocks_lifetime: "1227",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Congee Pool",
    icon: "https://cdn.adapools.org/pool_logo/49b7d9e2116ef05fbe1ee784391e31aecb360bbba2e979f389ae3d89.png",
    pool_id: "49b7d9e2116ef05fbe1ee784391e31aecb360bbba2e979f389ae3d89",
    db_ticker: "CONGE",
    db_name: "Congee Pool",
    db_url: "https://congeepool.com",
    roa: "3.101",
    roa_lifetime: "4.962",
    total_stake: "6760323791102",
    stake_x_deleg: "5541249009.1",
    saturated: "0.098340458385192",
    luck_lifetime: "103",
    blocks_epoch: "8",
    blocks_lifetime: "3177",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CheapStaking #1",
    icon: "https://cdn.adapools.org/pool_logo/cd29aa2b5003b8833a7bcf36b2fef2a4b96b7d07632249dbd432cac8.png",
    pool_id: "cd29aa2b5003b8833a7bcf36b2fef2a4b96b7d07632249dbd432cac8",
    db_ticker: "CHEAP",
    db_name: "CheapStaking #1",
    db_url: "https://cheapstaking.com",
    roa: "3.142",
    roa_lifetime: "4.713",
    total_stake: "17580725943292",
    stake_x_deleg: "47903885404.065",
    saturated: "0.25574169247387",
    luck_lifetime: "96",
    blocks_epoch: "22",
    blocks_lifetime: "3266",
    tax_ratio: "0.009",
    tax_fix: "500000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean One",
    icon: "https://cdn.adapools.org/pool_logo/15b5c3202b12c66377626bf881af87a9907dc89b6a8d86b0c0e8823e.png",
    pool_id: "15b5c3202b12c66377626bf881af87a9907dc89b6a8d86b0c0e8823e",
    db_ticker: "OCEA1",
    db_name: "ADA Ocean One",
    db_url: "https://adaocean.com/",
    roa: "3.92",
    roa_lifetime: "4.821",
    total_stake: "37042642663126",
    stake_x_deleg: "47858711451.067",
    saturated: "0.53884851848152",
    luck_lifetime: "102",
    blocks_epoch: "32",
    blocks_lifetime: "4594",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swimming Pool",
    icon: "https://cdn.adapools.org/pool_logo/fbc3b43604dedb35f09d9d61391ce03cf65e9b3b7653ca1376e0150d.png",
    pool_id: "fbc3b43604dedb35f09d9d61391ce03cf65e9b3b7653ca1376e0150d",
    db_ticker: "SWIM2",
    db_name: "The Swimming Pool",
    db_url: "https://theswim.net",
    roa: "4.766",
    roa_lifetime: "5.052",
    total_stake: "6648713398906",
    stake_x_deleg: "12785987305.588",
    saturated: "0.096716894563656",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "1447",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "skylightpool.com",
    icon: "0",
    pool_id: "4511bcca3896bcb6d43b66fb89fe079f00f44bed505c9ca232704dc9",
    db_ticker: "SKY",
    db_name: "skylightpool.com",
    db_url: "https://skylightpool.com",
    roa: "3.672",
    roa_lifetime: "4.444",
    total_stake: "32631567945717",
    stake_x_deleg: "15871385187.606",
    saturated: "0.4746819011588",
    luck_lifetime: "96",
    blocks_epoch: "27",
    blocks_lifetime: "4059",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HOTEL GINEBRA BARCELONA",
    icon: "https://cdn.adapools.org/pool_logo/f059ed9ef469a045d29c0f48799fb6cab08c0abbc0fd1f736a5fced5.png",
    pool_id: "f059ed9ef469a045d29c0f48799fb6cab08c0abbc0fd1f736a5fced5",
    db_ticker: "HOTEL",
    db_name: "HOTEL GINEBRA BARCELONA",
    db_url: "https://www.hotelginebra.com.es",
    roa: "3.515",
    roa_lifetime: "4.787",
    total_stake: "7656873754879",
    stake_x_deleg: "12697966426.002",
    saturated: "0.11138230920883",
    luck_lifetime: "100",
    blocks_epoch: "7",
    blocks_lifetime: "2514",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "285000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom Two",
    icon: "https://cdn.adapools.org/pool_logo/bcd7cf751b59f949170a7e6599f9ac03e49b32c19f3f1d8dad3ac210.png",
    pool_id: "bcd7cf751b59f949170a7e6599f9ac03e49b32c19f3f1d8dad3ac210",
    db_ticker: "BLOOM",
    db_name: "Bloom Two",
    db_url: "https://bloompool.io/",
    roa: "4.11",
    roa_lifetime: "4.655",
    total_stake: "32900211964484",
    stake_x_deleg: "10027495265.006",
    saturated: "0.47858978734359",
    luck_lifetime: "96",
    blocks_epoch: "28",
    blocks_lifetime: "5423",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PSILOBYTE",
    icon: "https://cdn.adapools.org/pool_logo/f2fa0dfea7f4caba8cbdd042c4940c783ee57f9516062725b04e19a3.png",
    pool_id: "f2fa0dfea7f4caba8cbdd042c4940c783ee57f9516062725b04e19a3",
    db_ticker: "PSB",
    db_name: "PSILOBYTE",
    db_url: "https://psilobyte.io",
    roa: "3.572",
    roa_lifetime: "3.958",
    total_stake: "8308063673895",
    stake_x_deleg: "6308324733.4055",
    saturated: "0.12085497902624",
    luck_lifetime: "100",
    blocks_epoch: "8",
    blocks_lifetime: "412",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/8857ee40b59bbc752e8cab4e5808e204cf7c4ce338fd7b86f4db0cc6.png",
    pool_id: "8857ee40b59bbc752e8cab4e5808e204cf7c4ce338fd7b86f4db0cc6",
    db_ticker: "DIGI6",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "4.81",
    roa_lifetime: "4.017",
    total_stake: "14494227916847",
    stake_x_deleg: "13034377623.064",
    saturated: "0.21084330593133",
    luck_lifetime: "98",
    blocks_epoch: "8",
    blocks_lifetime: "965",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wave-7",
    icon: "0",
    pool_id: "e14a650c7a58d229bbb663cb42fffb36d68c2a6cecf0fd7b9c47e399",
    db_ticker: "WAV7",
    db_name: "Wave-7",
    db_url: "https://wavegp.com",
    roa: "3.666",
    roa_lifetime: "4.452",
    total_stake: "44048508626775",
    stake_x_deleg: "8051271911.3096",
    saturated: "0.64076080723273",
    luck_lifetime: "98",
    blocks_epoch: "41",
    blocks_lifetime: "4475",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Friends4ADA",
    icon: "https://cdn.adapools.org/pool_logo/7e53c234d29f6037c5e5f82dfdc8a6edf816cc406d0a3a3412cdeab7.png",
    pool_id: "7e53c234d29f6037c5e5f82dfdc8a6edf816cc406d0a3a3412cdeab7",
    db_ticker: "F4ADA",
    db_name: "Friends4ADA",
    db_url: "https://friends.staking4ADA.org",
    roa: "3.61",
    roa_lifetime: "4.914",
    total_stake: "6787178195416",
    stake_x_deleg: "20080408862.178",
    saturated: "0.098731101572043",
    luck_lifetime: "102",
    blocks_epoch: "9",
    blocks_lifetime: "1973",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano GURU Pool",
    icon: "https://cdn.adapools.org/pool_logo/96840eba5a4d0ff0d42f7ad5ac83343a0c6a87b1c71bf106cc544855.png",
    pool_id: "96840eba5a4d0ff0d42f7ad5ac83343a0c6a87b1c71bf106cc544855",
    db_ticker: "GURU",
    db_name: "Cardano GURU Pool",
    db_url: "https://twitter.com/CardanoGuru",
    roa: "3.072",
    roa_lifetime: "4.709",
    total_stake: "35683194665672",
    stake_x_deleg: "47704805702.77",
    saturated: "0.51907302497684",
    luck_lifetime: "101",
    blocks_epoch: "34",
    blocks_lifetime: "4194",
    tax_ratio: "0.021",
    tax_fix: "420000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garden Pool Four",
    icon: "https://cdn.adapools.org/pool_logo/c6fbd4e484a28211695206e1b53f1409e7cef8c62d3f6b24bab2f600.png",
    pool_id: "c6fbd4e484a28211695206e1b53f1409e7cef8c62d3f6b24bab2f600",
    db_ticker: "EDEN",
    db_name: "Garden Pool Four",
    db_url: "https://garden-pool.com",
    roa: "3.069",
    roa_lifetime: "4.578",
    total_stake: "9930991861963",
    stake_x_deleg: "21129769919.07",
    saturated: "0.14446324201372",
    luck_lifetime: "102",
    blocks_epoch: "14",
    blocks_lifetime: "1044",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef0a9be2f6d897ed24a613cf547bb20cd282a04edfc53d477114.png",
    pool_id: "00beef0a9be2f6d897ed24a613cf547bb20cd282a04edfc53d477114",
    db_ticker: "BCSH",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "4.473",
    roa_lifetime: "4.481",
    total_stake: "23028262566270",
    stake_x_deleg: "20524298187.406",
    saturated: "0.33498541882894",
    luck_lifetime: "94",
    blocks_epoch: "17",
    blocks_lifetime: "3713",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "325000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano With Paul 2 ️",
    icon: "https://cdn.adapools.org/pool_logo/a89b86373838360143aba3911eaae54bf9420868965d48d447e4510a.png",
    pool_id: "a89b86373838360143aba3911eaae54bf9420868965d48d447e4510a",
    db_ticker: "PAUL",
    db_name: "Cardano With Paul 2 ️",
    db_url: "https://www.cardanowithpaul.com",
    roa: "3.62",
    roa_lifetime: "3.978",
    total_stake: "33520290899553",
    stake_x_deleg: "4870719398.3657",
    saturated: "0.48760989475176",
    luck_lifetime: "94",
    blocks_epoch: "27",
    blocks_lifetime: "2340",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CALM",
    icon: "https://cdn.adapools.org/pool_logo/0ec6869e13bb1f39c8be3611e7cc00ba1ec2a22d7e4b197cec9aa2e3.png",
    pool_id: "0ec6869e13bb1f39c8be3611e7cc00ba1ec2a22d7e4b197cec9aa2e3",
    db_ticker: "CALM",
    db_name: "CALM",
    db_url: "https://www.stakecool.io",
    roa: "3.679",
    roa_lifetime: "4.877",
    total_stake: "7830127177269",
    stake_x_deleg: "26633085636.969",
    saturated: "0.11390257621099",
    luck_lifetime: "98",
    blocks_epoch: "7",
    blocks_lifetime: "3424",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sandstone",
    icon: "https://cdn.adapools.org/pool_logo/40183423c226189d508db4b21bf94b790cf4d096134a9afbc2bd5318.png",
    pool_id: "40183423c226189d508db4b21bf94b790cf4d096134a9afbc2bd5318",
    db_ticker: "SAND",
    db_name: "Sandstone",
    db_url: "https://www.sandstone.io",
    roa: "3.253",
    roa_lifetime: "4.387",
    total_stake: "12889412910969",
    stake_x_deleg: "37910037973.438",
    saturated: "0.18749853012204",
    luck_lifetime: "97",
    blocks_epoch: "8",
    blocks_lifetime: "945",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "810000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CROWN Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/0014cda8c1c48b64d542ae26e8b65d9e1953c0d5562ee620d6900f4c.png",
    pool_id: "0014cda8c1c48b64d542ae26e8b65d9e1953c0d5562ee620d6900f4c",
    db_ticker: "CROWN",
    db_name: "CROWN Stake Pool",
    db_url: "https://www.crownstakepool.com/",
    roa: "3.321",
    roa_lifetime: "4.652",
    total_stake: "5835009367890",
    stake_x_deleg: "10785599570.961",
    saturated: "0.084880179359968",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "1796",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A CardanoLand pool ACL",
    icon: "https://cdn.adapools.org/pool_logo/0e773f93f9dab25a991cbb62922e4579ea7b0595ea183d0d7732961a.png",
    pool_id: "0e773f93f9dab25a991cbb62922e4579ea7b0595ea183d0d7732961a",
    db_ticker: "ACL",
    db_name: "A CardanoLand pool ACL",
    db_url: "https://cardanoland.com",
    roa: "3.439",
    roa_lifetime: "4.742",
    total_stake: "14014278791789",
    stake_x_deleg: "50230389934.728",
    saturated: "0.20386162599732",
    luck_lifetime: "99",
    blocks_epoch: "11",
    blocks_lifetime: "3131",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom Six",
    icon: "https://cdn.adapools.org/pool_logo/8e06d57e463f8a73f37652b23d126eeb52e6838168700d0478d32851.png",
    pool_id: "8e06d57e463f8a73f37652b23d126eeb52e6838168700d0478d32851",
    db_ticker: "BLOOM",
    db_name: "Bloom Six",
    db_url: "https://bloompool.io/",
    roa: "3.418",
    roa_lifetime: "4.583",
    total_stake: "31298031176608",
    stake_x_deleg: "10513278863.489",
    saturated: "0.45528333073525",
    luck_lifetime: "100",
    blocks_epoch: "21",
    blocks_lifetime: "3399",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Fortress",
    icon: "https://cdn.adapools.org/pool_logo/903e554b27f6c66a2032bedc3d74fdf6b58b2f09a05e13b9af71807f.png",
    pool_id: "903e554b27f6c66a2032bedc3d74fdf6b58b2f09a05e13b9af71807f",
    db_ticker: "DIGI5",
    db_name: "Digital Fortress",
    db_url: "https://digitalfortress.online",
    roa: "4.69",
    roa_lifetime: "4.168",
    total_stake: "9270976564930",
    stake_x_deleg: "6703526077.3174",
    saturated: "0.13486219199642",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "938",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom Three",
    icon: "https://cdn.adapools.org/pool_logo/f5b2ef0d7db63c8d00446cd7d9ce9cdb9e73023ffaa5e806decceb66.png",
    pool_id: "f5b2ef0d7db63c8d00446cd7d9ce9cdb9e73023ffaa5e806decceb66",
    db_ticker: "BLOOM",
    db_name: "Bloom Three",
    db_url: "https://bloompool.io/",
    roa: "3.749",
    roa_lifetime: "4.488",
    total_stake: "32356985639940",
    stake_x_deleg: "13675818106.484",
    saturated: "0.47068763244491",
    luck_lifetime: "98",
    blocks_epoch: "34",
    blocks_lifetime: "3270",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Squid Pool",
    icon: "0",
    pool_id: "cb11e3e5013a120ee55e8d99bdb7395366bd3164d3da1737300a2b7e",
    db_ticker: "SQUID",
    db_name: "Squid Pool",
    db_url: "https://squidpool.com",
    roa: "4.072",
    roa_lifetime: "4.52",
    total_stake: "36410254041212",
    stake_x_deleg: "50994753559.12",
    saturated: "0.52964934564923",
    luck_lifetime: "99",
    blocks_epoch: "38",
    blocks_lifetime: "2512",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "202000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef373356ff27a77bd510f4cd35d309715afb6f52f49d9186a16e.png",
    pool_id: "00beef373356ff27a77bd510f4cd35d309715afb6f52f49d9186a16e",
    db_ticker: "BCSH4",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.619",
    roa_lifetime: "4.432",
    total_stake: "23438361548078",
    stake_x_deleg: "27190674649.742",
    saturated: "0.3409510091025",
    luck_lifetime: "100",
    blocks_epoch: "18",
    blocks_lifetime: "1889",
    tax_ratio: "0.025",
    tax_fix: "500000000",
    pledge: "3000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AtlasStakePool #2",
    icon: "https://cdn.adapools.org/pool_logo/3d2294baebe5bbeaa920af59e8461068582bb6c17522bbc7308e8567.png",
    pool_id: "3d2294baebe5bbeaa920af59e8461068582bb6c17522bbc7308e8567",
    db_ticker: "ATLAS",
    db_name: "AtlasStakePool #2",
    db_url: "https://atlasstakepool.com",
    roa: "4.003",
    roa_lifetime: "4.014",
    total_stake: "10062458324847",
    stake_x_deleg: "5915613359.6984",
    saturated: "0.14637564630407",
    luck_lifetime: "98",
    blocks_epoch: "13",
    blocks_lifetime: "822",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "125000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking Rocks!",
    icon: "https://cdn.adapools.org/pool_logo/0cc4e441026e975f1cf048fcd3489c86424014dd34c244e268cb8095.png",
    pool_id: "0cc4e441026e975f1cf048fcd3489c86424014dd34c244e268cb8095",
    db_ticker: "PHRCK",
    db_name: "Staking Rocks!",
    db_url: "https://staking.rocks",
    roa: "3.94",
    roa_lifetime: "4.303",
    total_stake: "6345349596019",
    stake_x_deleg: "2599487749.2909",
    saturated: "0.092303949806091",
    luck_lifetime: "97",
    blocks_epoch: "3",
    blocks_lifetime: "897",
    tax_ratio: "0.0095",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chunky Monkey ",
    icon: "https://cdn.adapools.org/pool_logo/39fca98bb733eea4fa54384c39d072cd9733cfa70d7bb63ce651a470.png",
    pool_id: "39fca98bb733eea4fa54384c39d072cd9733cfa70d7bb63ce651a470",
    db_ticker: "MNKY",
    db_name: "Chunky Monkey ",
    db_url: "https://www.chunkymonkey.us",
    roa: "2.919",
    roa_lifetime: "4.411",
    total_stake: "10453617782924",
    stake_x_deleg: "25250284499.816",
    saturated: "0.15206572885006",
    luck_lifetime: "103",
    blocks_epoch: "11",
    blocks_lifetime: "490",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "208000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COOL4",
    icon: "https://cdn.adapools.org/pool_logo/7782b3a9574a1d179a767064213e03a061995a006e1aeafdfb8463f8.png",
    pool_id: "7782b3a9574a1d179a767064213e03a061995a006e1aeafdfb8463f8",
    db_ticker: "COOL4",
    db_name: "COOL4",
    db_url: "https://www.stakecool.io",
    roa: "3.42",
    roa_lifetime: "4.79",
    total_stake: "11809705505275",
    stake_x_deleg: "48599611132.819",
    saturated: "0.17179234141292",
    luck_lifetime: "99",
    blocks_epoch: "11",
    blocks_lifetime: "2583",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Poly 0PCT Pool",
    icon: "https://cdn.adapools.org/pool_logo/b3f9883d3de29971c43e05fdb985281ffee737be0e5669094810ba6b.png",
    pool_id: "b3f9883d3de29971c43e05fdb985281ffee737be0e5669094810ba6b",
    db_ticker: "POLY",
    db_name: "Poly 0PCT Pool",
    db_url: "https://polypool.io",
    roa: "2.8",
    roa_lifetime: "4.446",
    total_stake: "7398357799859",
    stake_x_deleg: "15067938492.585",
    saturated: "0.10762175301328",
    luck_lifetime: "96",
    blocks_epoch: "3",
    blocks_lifetime: "878",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "日本壱プール",
    icon: "https://cdn.adapools.org/pool_logo/54a7cf8a1e91ff0b72a3577c0d2f39d2d7ad1fca63ae0b9e4662053d.png",
    pool_id: "54a7cf8a1e91ff0b72a3577c0d2f39d2d7ad1fca63ae0b9e4662053d",
    db_ticker: "1JPN",
    db_name: "日本壱プール",
    db_url: "https://twitter.com/sakakibara1JPN",
    roa: "3.735",
    roa_lifetime: "4.638",
    total_stake: "25833952476257",
    stake_x_deleg: "79489084542.329",
    saturated: "0.37579897160551",
    luck_lifetime: "99",
    blocks_epoch: "19",
    blocks_lifetime: "3092",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KIWI1 by Kiwipool Staking",
    icon: "https://cdn.adapools.org/pool_logo/921dc3fc11681ad9ccde08c9bc23041808f4b7e8ba231be557db8ab5.png",
    pool_id: "921dc3fc11681ad9ccde08c9bc23041808f4b7e8ba231be557db8ab5",
    db_ticker: "KIWI",
    db_name: "KIWI1 by Kiwipool Staking",
    db_url: "https://www.kiwipool.org",
    roa: "4.819",
    roa_lifetime: "4.225",
    total_stake: "8233570955627",
    stake_x_deleg: "9697963434.1896",
    saturated: "0.11977135518111",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "1342",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "222369000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Koala Pool stake, earn, &amp; save Koalas コアラを救え",
    icon: "https://cdn.adapools.org/pool_logo/9138b6f09e8e69c29fdee4e11b147b7ad351aab23fc73a9976907f10.png",
    pool_id: "9138b6f09e8e69c29fdee4e11b147b7ad351aab23fc73a9976907f10",
    db_ticker: "KOALA",
    db_name: "Koala Pool stake, earn, &amp; save Koalas コアラを救え",
    db_url: "https://koalapool.org",
    roa: "3.584",
    roa_lifetime: "4.254",
    total_stake: "9979930974130",
    stake_x_deleg: "20492671404.784",
    saturated: "0.14517514500419",
    luck_lifetime: "97",
    blocks_epoch: "11",
    blocks_lifetime: "790",
    tax_ratio: "0.019022",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom Four",
    icon: "https://cdn.adapools.org/pool_logo/e118432cb62a1f13de9af18f7eff32a98fe97b751d444117f7655b86.png",
    pool_id: "e118432cb62a1f13de9af18f7eff32a98fe97b751d444117f7655b86",
    db_ticker: "BLOOM",
    db_name: "Bloom Four",
    db_url: "https://bloompool.io/",
    roa: "4.132",
    roa_lifetime: "4.568",
    total_stake: "29356562616825",
    stake_x_deleg: "11612564326.276",
    saturated: "0.42704135387006",
    luck_lifetime: "100",
    blocks_epoch: "25",
    blocks_lifetime: "3223",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "#4 Nordic Pool",
    icon: "https://cdn.adapools.org/pool_logo/b6a310df0c12af2bcab4cb1ceef2aea7b52080f1ee114b9eae5465de.png",
    pool_id: "b6a310df0c12af2bcab4cb1ceef2aea7b52080f1ee114b9eae5465de",
    db_ticker: "NORTH",
    db_name: "#4 Nordic Pool",
    db_url: "https://nordicpool.org",
    roa: "3.613",
    roa_lifetime: "3.982",
    total_stake: "10232572268166",
    stake_x_deleg: "11146592884.712",
    saturated: "0.14885024421989",
    luck_lifetime: "95",
    blocks_epoch: "13",
    blocks_lifetime: "1082",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "110000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "P2P Validator",
    icon: "0",
    pool_id: "33c1bf1a04ba85d8fd6119a08ee7f4ad659add075e2208b28862a2a1",
    db_ticker: "P2P",
    db_name: "P2P Validator",
    db_url: "https://p2p.org/",
    roa: "3.36",
    roa_lifetime: "4.72",
    total_stake: "36856049983531",
    stake_x_deleg: "8647595021.9453",
    saturated: "0.5361342091955",
    luck_lifetime: "96",
    blocks_epoch: "31",
    blocks_lifetime: "6540",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Black Star Stake Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/41b59eb92c9df16cb37f15d222ca3c148aa929c8739fb6b549e51b51.png",
    pool_id: "41b59eb92c9df16cb37f15d222ca3c148aa929c8739fb6b549e51b51",
    db_ticker: "BSSP2",
    db_name: "Black Star Stake Pool 2",
    db_url: "https://blackstarpool.com",
    roa: "3.395",
    roa_lifetime: "4.697",
    total_stake: "7846243161891",
    stake_x_deleg: "31766166647.332",
    saturated: "0.11413701073869",
    luck_lifetime: "103",
    blocks_epoch: "12",
    blocks_lifetime: "1891",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Viper Stake Pool #2",
    icon: "https://cdn.adapools.org/pool_logo/0319357f4578b716282f4c58dd6be4d613053a40346a208306a05d03.png",
    pool_id: "0319357f4578b716282f4c58dd6be4d613053a40346a208306a05d03",
    db_ticker: "VIPER",
    db_name: "Viper Stake Pool #2",
    db_url: "https://viperstaking.com/ada-pools/adder-pool/",
    roa: "3.872",
    roa_lifetime: "3.792",
    total_stake: "12958271106733",
    stake_x_deleg: "18511815866.761",
    saturated: "0.18850019021175",
    luck_lifetime: "93",
    blocks_epoch: "17",
    blocks_lifetime: "666",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "401000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SITION POOL",
    icon: "0",
    pool_id: "76bfa1b2e6593740c3388ed881cbe851dbab8d8741652c9bad16cc94",
    db_ticker: "SIPO",
    db_name: "SITION POOL",
    db_url: "https://sipo.tokyo",
    roa: "3.519",
    roa_lifetime: "4.585",
    total_stake: "46969552970213",
    stake_x_deleg: "35609971925.863",
    saturated: "0.68325238730694",
    luck_lifetime: "98",
    blocks_epoch: "45",
    blocks_lifetime: "5993",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Army of Spies",
    icon: "https://cdn.adapools.org/pool_logo/eaa28db3bf0b7bb815b552fc9e0cecdda62933ce6c67067183c79acd.png",
    pool_id: "eaa28db3bf0b7bb815b552fc9e0cecdda62933ce6c67067183c79acd",
    db_ticker: "AOS",
    db_name: "Army of Spies",
    db_url: "armyofspies.io",
    roa: "3.812",
    roa_lifetime: "3.617",
    total_stake: "16327236797490",
    stake_x_deleg: "26765961963.098",
    saturated: "0.23750755147883",
    luck_lifetime: "96",
    blocks_epoch: "10",
    blocks_lifetime: "152",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Four",
    icon: "https://cdn.adapools.org/pool_logo/cfff13497008842ab170eae18ecf43be7eb4fa51bdd503821ddaaf19.png",
    pool_id: "cfff13497008842ab170eae18ecf43be7eb4fa51bdd503821ddaaf19",
    db_ticker: "OCEA4",
    db_name: "ADA Ocean Four",
    db_url: "https://adaocean.com/",
    roa: "3.745",
    roa_lifetime: "4.856",
    total_stake: "33473264963030",
    stake_x_deleg: "64620202631.332",
    saturated: "0.48692582216935",
    luck_lifetime: "98",
    blocks_epoch: "19",
    blocks_lifetime: "5123",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Education Thailand",
    icon: "https://cdn.adapools.org/pool_logo/96aa57f4af0652c800cde0d25288bf6d1e35aefd11770d60e6bf8f0a.png",
    pool_id: "96aa57f4af0652c800cde0d25288bf6d1e35aefd11770d60e6bf8f0a",
    db_ticker: "CETH",
    db_name: "Cardano Education Thailand",
    db_url: "https://www.cardano-education.com/",
    roa: "3.632",
    roa_lifetime: "4.126",
    total_stake: "6228671397529",
    stake_x_deleg: "2067265648.0348",
    saturated: "0.090606666084538",
    luck_lifetime: "100",
    blocks_epoch: "2",
    blocks_lifetime: "554",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Loops",
    icon: "https://cdn.adapools.org/pool_logo/c9bc139d73045ba6c5b002ff88ea2a5854b3e783c468adb9250df917.png",
    pool_id: "c9bc139d73045ba6c5b002ff88ea2a5854b3e783c468adb9250df917",
    db_ticker: "LOOPS",
    db_name: "Crypto Loops",
    db_url: "https://crypto-loops.com/loops/",
    roa: "3.42",
    roa_lifetime: "3.778",
    total_stake: "17568443050375",
    stake_x_deleg: "27710477997.437",
    saturated: "0.25556301681319",
    luck_lifetime: "98",
    blocks_epoch: "13",
    blocks_lifetime: "358",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom Five",
    icon: "https://cdn.adapools.org/pool_logo/28cf6c6c01ecbfbd5e807fd879132f07b2c6f0712b930442a229d380.png",
    pool_id: "28cf6c6c01ecbfbd5e807fd879132f07b2c6f0712b930442a229d380",
    db_ticker: "BLOOM",
    db_name: "Bloom Five",
    db_url: "https://bloompool.io/",
    roa: "3.47",
    roa_lifetime: "4.383",
    total_stake: "32274608583355",
    stake_x_deleg: "22808910659.615",
    saturated: "0.46948931742993",
    luck_lifetime: "97",
    blocks_epoch: "27",
    blocks_lifetime: "4084",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef9385526062d41cd7293746048c6a9a13ab8b591920cf40c706.png",
    pool_id: "00beef9385526062d41cd7293746048c6a9a13ab8b591920cf40c706",
    db_ticker: "BCSH1",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.202",
    roa_lifetime: "4.771",
    total_stake: "13671545870054",
    stake_x_deleg: "14653318188.697",
    saturated: "0.19887599015078",
    luck_lifetime: "98",
    blocks_epoch: "12",
    blocks_lifetime: "2168",
    tax_ratio: "0.025",
    tax_fix: "400000000",
    pledge: "500001950000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Woof Pool",
    icon: "https://cdn.adapools.org/pool_logo/c22942e1b855136643d1e6e5a75266fb891d87727a8cbf06acd17208.png",
    pool_id: "c22942e1b855136643d1e6e5a75266fb891d87727a8cbf06acd17208",
    db_ticker: "WOOF",
    db_name: "Woof Pool",
    db_url: "https://woofpool.github.io/",
    roa: "4.138",
    roa_lifetime: "3.898",
    total_stake: "17050159051297",
    stake_x_deleg: "42947503907.549",
    saturated: "0.24802369064806",
    luck_lifetime: "102",
    blocks_epoch: "12",
    blocks_lifetime: "239",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "105000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Masterstake",
    icon: "0",
    pool_id: "00000aedaa1a5abf500cbc4c5c23761a2dff570bc4a299983c893f8e",
    db_ticker: "MASTR",
    db_name: "Masterstake",
    db_url: "https://masterstake.com",
    roa: "3.978",
    roa_lifetime: "4.628",
    total_stake: "23392407735900",
    stake_x_deleg: "15626190872.345",
    saturated: "0.34028253240023",
    luck_lifetime: "100",
    blocks_epoch: "26",
    blocks_lifetime: "3455",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Into the Cryptoverse #2",
    icon: "https://cdn.adapools.org/pool_logo/0c2cfc283e0bbb85b9ef9d852c99ae7ee13f20f36b70fd648c6c3448.png",
    pool_id: "0c2cfc283e0bbb85b9ef9d852c99ae7ee13f20f36b70fd648c6c3448",
    db_ticker: "ITC2",
    db_name: "Into the Cryptoverse #2",
    db_url: "https://www.youtube.com/channel/UCRvqjQPSeaWn-uEx-w0XOIg",
    roa: "3.912",
    roa_lifetime: "3.928",
    total_stake: "32177265236340",
    stake_x_deleg: "5469533441.4992",
    saturated: "0.46807329215333",
    luck_lifetime: "96",
    blocks_epoch: "18",
    blocks_lifetime: "2187",
    tax_ratio: "0.0399",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GROW TEAM",
    icon: "https://cdn.adapools.org/pool_logo/95954fa839905a201d8d97cc31be8bbd4ac2ad0df1d201accce3a23e.png",
    pool_id: "95954fa839905a201d8d97cc31be8bbd4ac2ad0df1d201accce3a23e",
    db_ticker: "GROW",
    db_name: "GROW TEAM",
    db_url: "https://growpools.io",
    roa: "3.546",
    roa_lifetime: "4.241",
    total_stake: "59906091289877",
    stake_x_deleg: "17359052822.335",
    saturated: "0.87143643700406",
    luck_lifetime: "97",
    blocks_epoch: "46",
    blocks_lifetime: "3361",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "160000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RADAR Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/fd2a060be85493dfdb03eecb6343a76636823d223d680a202b900f65.png",
    pool_id: "fd2a060be85493dfdb03eecb6343a76636823d223d680a202b900f65",
    db_ticker: "RADAR",
    db_name: "RADAR Stake Pool",
    db_url: "https://radarstakepool.org",
    roa: "3.952",
    roa_lifetime: "4.321",
    total_stake: "8832561527834",
    stake_x_deleg: "39082130654.133",
    saturated: "0.12848469632562",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "418",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JORGE",
    icon: "https://cdn.adapools.org/pool_logo/7ed358aeebfc6d9ea28fd8fec077c7359c8f5fa3df2aea10438dbd26.png",
    pool_id: "7ed358aeebfc6d9ea28fd8fec077c7359c8f5fa3df2aea10438dbd26",
    db_ticker: "JORGE",
    db_name: "JORGE",
    db_url: "https://www.jorgepool.com",
    roa: "3.374",
    roa_lifetime: "4.083",
    total_stake: "6594423664243",
    stake_x_deleg: "13738382633.84",
    saturated: "0.095927157628363",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "548",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Salt Pool",
    icon: "https://cdn.adapools.org/pool_logo/ea90b56d9c4d04c583ec728c8b415948b310d4653d12bd281aaff9df.png",
    pool_id: "ea90b56d9c4d04c583ec728c8b415948b310d4653d12bd281aaff9df",
    db_ticker: "SALT",
    db_name: "Salt Pool",
    db_url: "https://saltnet.io",
    roa: "4.739",
    roa_lifetime: "3.817",
    total_stake: "17001663116415",
    stake_x_deleg: "70840262985.062",
    saturated: "0.24731823442828",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "341",
    tax_ratio: "0.0111",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef284975ef87856c1343f6bf50172253177fdebc756524d43fc1.png",
    pool_id: "00beef284975ef87856c1343f6bf50172253177fdebc756524d43fc1",
    db_ticker: "BCSH2",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.26",
    roa_lifetime: "4.744",
    total_stake: "11937436598718",
    stake_x_deleg: "12230980121.637",
    saturated: "0.17365040837352",
    luck_lifetime: "96",
    blocks_epoch: "12",
    blocks_lifetime: "2876",
    tax_ratio: "0.025",
    tax_fix: "400000000",
    pledge: "3020000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bloom",
    icon: "https://cdn.adapools.org/pool_logo/53215c471b7ac752e3ddf8f2c4c1e6ed111857bfaa675d5e31ce8bce.png",
    pool_id: "53215c471b7ac752e3ddf8f2c4c1e6ed111857bfaa675d5e31ce8bce",
    db_ticker: "BLOOM",
    db_name: "Bloom",
    db_url: "https://bloompool.io/",
    roa: "3.402",
    roa_lifetime: "4.648",
    total_stake: "39438112083735",
    stake_x_deleg: "10130519415.293",
    saturated: "0.57369471344935",
    luck_lifetime: "97",
    blocks_epoch: "26",
    blocks_lifetime: "6974",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Into the Cryptoverse #3",
    icon: "https://cdn.adapools.org/pool_logo/d4a3b639d0f0b050cbfc25dad467af58355f31edbc4959db1cc59051.png",
    pool_id: "d4a3b639d0f0b050cbfc25dad467af58355f31edbc4959db1cc59051",
    db_ticker: "ITC3",
    db_name: "Into the Cryptoverse #3",
    db_url: "https://www.youtube.com/channel/UCRvqjQPSeaWn-uEx-w0XOIg",
    roa: "3.692",
    roa_lifetime: "3.842",
    total_stake: "32285593788070",
    stake_x_deleg: "6723363970.8601",
    saturated: "0.46964911599883",
    luck_lifetime: "96",
    blocks_epoch: "23",
    blocks_lifetime: "1562",
    tax_ratio: "0.0399",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef534478b59ba0b2b646348c8d1f81f60bf40a2195c6378dd5db.png",
    pool_id: "00beef534478b59ba0b2b646348c8d1f81f60bf40a2195c6378dd5db",
    db_ticker: "BCSH5",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.928",
    roa_lifetime: "4.318",
    total_stake: "17475821706302",
    stake_x_deleg: "20656999652.839",
    saturated: "0.25421568113611",
    luck_lifetime: "101",
    blocks_epoch: "13",
    blocks_lifetime: "1455",
    tax_ratio: "0.025",
    tax_fix: "400000000",
    pledge: "3020000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Five",
    icon: "https://cdn.adapools.org/pool_logo/83f09b0af923a095a1c17905e802a35d1044f974b25d2a755a2d8266.png",
    pool_id: "83f09b0af923a095a1c17905e802a35d1044f974b25d2a755a2d8266",
    db_ticker: "OCEA5",
    db_name: "ADA Ocean Five",
    db_url: "https://adaocean.com/",
    roa: "4.101",
    roa_lifetime: "5.013",
    total_stake: "19336258993706",
    stake_x_deleg: "23783836400.622",
    saturated: "0.28127891971664",
    luck_lifetime: "101",
    blocks_epoch: "24",
    blocks_lifetime: "6189",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cero Stake Pool",
    icon: "0",
    pool_id: "fc2d8488ef021561a83af7b56f973b94cfcfd8100433ee6fd0b0aa45",
    db_ticker: "CERO",
    db_name: "Cero Stake Pool",
    db_url: "https://cerostakepool.com",
    roa: "3.77",
    roa_lifetime: "4.561",
    total_stake: "35882808404557",
    stake_x_deleg: "13913458086.296",
    saturated: "0.52197674781446",
    luck_lifetime: "102",
    blocks_epoch: "30",
    blocks_lifetime: "3134",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "320000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEDSCAVE.IO",
    icon: "https://cdn.adapools.org/pool_logo/636fab1b0059766eb08c3bd089e71a949db856bb845e3bff31fad5ad.png",
    pool_id: "636fab1b0059766eb08c3bd089e71a949db856bb845e3bff31fad5ad",
    db_ticker: "NEDS4",
    db_name: "NEDSCAVE.IO",
    db_url: "https://nedscave.io",
    roa: "4.552",
    roa_lifetime: "3.637",
    total_stake: "10161535626109",
    stake_x_deleg: "18081024245.746",
    saturated: "0.14781689490736",
    luck_lifetime: "89",
    blocks_epoch: "4",
    blocks_lifetime: "731",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VoltPool",
    icon: "0",
    pool_id: "7e82a949dc775005761ec3446a9358261416cbdd8de2c6530cb3270b",
    db_ticker: "VOLT",
    db_name: "VoltPool",
    db_url: "https://Voltairera.com",
    roa: "4.066",
    roa_lifetime: "4.823",
    total_stake: "16417933218389",
    stake_x_deleg: "9075695532.5533",
    saturated: "0.23882688585995",
    luck_lifetime: "99",
    blocks_epoch: "10",
    blocks_lifetime: "3697",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoAtStake",
    icon: "https://cdn.adapools.org/pool_logo/df6d366e0d659557f7ce38f1f6015fe5022b15fac72e5c3bd28bc5ed.png",
    pool_id: "df6d366e0d659557f7ce38f1f6015fe5022b15fac72e5c3bd28bc5ed",
    db_ticker: "HEL",
    db_name: "CardanoAtStake",
    db_url: "https://cardanoatstake.com",
    roa: "3.146",
    roa_lifetime: "4.758",
    total_stake: "6177759697248",
    stake_x_deleg: "52801364933.744",
    saturated: "0.089866068430119",
    luck_lifetime: "101",
    blocks_epoch: "6",
    blocks_lifetime: "1289",
    tax_ratio: "0.0225",
    tax_fix: "340000000",
    pledge: "600000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TRUE Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/6d9ce533e4874ff84f89a0f1d2f8a26c0838898ba16fcf66aa790c9e.png",
    pool_id: "6d9ce533e4874ff84f89a0f1d2f8a26c0838898ba16fcf66aa790c9e",
    db_ticker: "TRUE",
    db_name: "TRUE Staking Pool",
    db_url: "https://7ru3.com",
    roa: "3.643",
    roa_lifetime: "4.728",
    total_stake: "6228181122311",
    stake_x_deleg: "38445562483.401",
    saturated: "0.090599534193942",
    luck_lifetime: "103",
    blocks_epoch: "9",
    blocks_lifetime: "1102",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "(Crypto Capital Venture) #Pool5",
    icon: "https://cdn.adapools.org/pool_logo/2313599837d144bdbd6897a3cdecc0ad2c995f38275aad6626c29ef5.png",
    pool_id: "2313599837d144bdbd6897a3cdecc0ad2c995f38275aad6626c29ef5",
    db_ticker: "CCV4",
    db_name: "(Crypto Capital Venture) #Pool5",
    db_url: "https://www.youtube.com/cryptocapitalventure",
    roa: "3.441",
    roa_lifetime: "3.84",
    total_stake: "28438457428982",
    stake_x_deleg: "8568381268.1476",
    saturated: "0.41368594548901",
    luck_lifetime: "95",
    blocks_epoch: "30",
    blocks_lifetime: "1274",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 5",
    icon: "https://cdn.adapools.org/pool_logo/2fc479904f9482d1b573977a3af26bb5577545014521eee513f54ef1.png",
    pool_id: "2fc479904f9482d1b573977a3af26bb5577545014521eee513f54ef1",
    db_ticker: "MS5",
    db_name: "Moonstake 5",
    db_url: "https://moonstake.io",
    roa: "3.544",
    roa_lifetime: "4.479",
    total_stake: "59465172382524",
    stake_x_deleg: "79392753514.718",
    saturated: "0.86502251826294",
    luck_lifetime: "98",
    blocks_epoch: "41",
    blocks_lifetime: "6214",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Charity pool",
    icon: "https://cdn.adapools.org/pool_logo/00333b89543da962cc92e7ffa45848f42a98f7276780670728ce5256.png",
    pool_id: "00333b89543da962cc92e7ffa45848f42a98f7276780670728ce5256",
    db_ticker: "CHRTY",
    db_name: "Charity pool",
    db_url: "https://charity-pool.ro",
    roa: "3.842",
    roa_lifetime: "4.386",
    total_stake: "6941820697729",
    stake_x_deleg: "30852536434.351",
    saturated: "0.10098064094815",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "1224",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "55000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aspen Pool by Woodland Pools",
    icon: "https://cdn.adapools.org/pool_logo/05b8a25ec4f41956a6496555c233bcbe6fc06596553ae2d14df73e20.png",
    pool_id: "05b8a25ec4f41956a6496555c233bcbe6fc06596553ae2d14df73e20",
    db_ticker: "ASPEN",
    db_name: "Aspen Pool by Woodland Pools",
    db_url: "https://www.woodlandpools.net/",
    roa: "3.558",
    roa_lifetime: "3.69",
    total_stake: "5849418976475",
    stake_x_deleg: "7883313984.4677",
    saturated: "0.08508979173316",
    luck_lifetime: "100",
    blocks_epoch: "3",
    blocks_lifetime: "220",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEAF pool",
    icon: "https://cdn.adapools.org/pool_logo/b0c53bbb91bd03c5417c87c7488a83de68f5ea27aefb886c9fc4ff27.png",
    pool_id: "b0c53bbb91bd03c5417c87c7488a83de68f5ea27aefb886c9fc4ff27",
    db_ticker: "LEAF",
    db_name: "LEAF pool",
    db_url: "https://leaf-pool.com/",
    roa: "4.534",
    roa_lifetime: "4.752",
    total_stake: "31078499607470",
    stake_x_deleg: "73471630277.707",
    saturated: "0.45208986903043",
    luck_lifetime: "99",
    blocks_epoch: "17",
    blocks_lifetime: "3233",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #2 (v1)",
    icon: "0",
    pool_id: "17a6a1f2fb36d9ef275805db17409af7ab753b9d27bdd41b89510bb9",
    db_ticker: "1PCT2",
    db_name: "1 Percent Pool #2 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.044",
    roa_lifetime: "4.958",
    total_stake: "38936750453719",
    stake_x_deleg: "46911747534.601",
    saturated: "0.56640155205116",
    luck_lifetime: "100",
    blocks_epoch: "34",
    blocks_lifetime: "6322",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nielsen-Nielsen",
    icon: "https://cdn.adapools.org/pool_logo/1e1be043e289a619d8bcd2f0eca0941aa9eaf1278701c5542b46a3e9.png",
    pool_id: "1e1be043e289a619d8bcd2f0eca0941aa9eaf1278701c5542b46a3e9",
    db_ticker: "NINI",
    db_name: "Nielsen-Nielsen",
    db_url: "https://tinyurl.com/NINIstake",
    roa: "3.805",
    roa_lifetime: "4.708",
    total_stake: "8899639464842",
    stake_x_deleg: "70075901297.969",
    saturated: "0.12946045951045",
    luck_lifetime: "99",
    blocks_epoch: "9",
    blocks_lifetime: "1507",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "London pool",
    icon: "https://cdn.adapools.org/pool_logo/3b0a33f53dfde8ffd7f05e16aeb9b888a019b383c43cbad26c517985.png",
    pool_id: "3b0a33f53dfde8ffd7f05e16aeb9b888a019b383c43cbad26c517985",
    db_ticker: "LONDN",
    db_name: "London pool",
    db_url: "https://londonpool.co.uk",
    roa: "3.506",
    roa_lifetime: "4.813",
    total_stake: "14779997237146",
    stake_x_deleg: "13943393619.949",
    saturated: "0.21500030888253",
    luck_lifetime: "98",
    blocks_epoch: "19",
    blocks_lifetime: "3286",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "301000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanistas StakePool",
    icon: "https://cdn.adapools.org/pool_logo/92fcdd76d1d9d13910cbe1930765543984e6f4ef9b7efed3cc6536a1.png",
    pool_id: "92fcdd76d1d9d13910cbe1930765543984e6f4ef9b7efed3cc6536a1",
    db_ticker: "CARDs",
    db_name: "Cardanistas StakePool",
    db_url: "https://www.cardanistas.io",
    roa: "4.036",
    roa_lifetime: "4.097",
    total_stake: "19926774101808",
    stake_x_deleg: "1935013993.1839",
    saturated: "0.28986897075688",
    luck_lifetime: "96",
    blocks_epoch: "22",
    blocks_lifetime: "1535",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SWAG",
    icon: "0",
    pool_id: "7e3a1ed8f2085923debacc6866aefaba941edd638ff0886061ebf521",
    db_ticker: "SWAG",
    db_name: "SWAG",
    db_url: "https://www.ada-swag.com",
    roa: "4.492",
    roa_lifetime: "4.646",
    total_stake: "37245663565550",
    stake_x_deleg: "41384070628.389",
    saturated: "0.54180180433337",
    luck_lifetime: "98",
    blocks_epoch: "31",
    blocks_lifetime: "2526",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PoolData.Live",
    icon: "https://cdn.adapools.org/pool_logo/eb6e1949ef2e1f2cdb9b041d2a4b86d4d837798ab3ddfe53cc4cafe4.png",
    pool_id: "eb6e1949ef2e1f2cdb9b041d2a4b86d4d837798ab3ddfe53cc4cafe4",
    db_ticker: "ADATA",
    db_name: "PoolData.Live",
    db_url: "https://www.pooldata.live",
    roa: "4.191",
    roa_lifetime: "4.006",
    total_stake: "16683313628615",
    stake_x_deleg: "88741029939.441",
    saturated: "0.24268729728321",
    luck_lifetime: "100",
    blocks_epoch: "9",
    blocks_lifetime: "595",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "880000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TANTUM",
    icon: "0",
    pool_id: "ff069b3ecf738d4adfe83f7c20c64b82f94f7285ee41eedb2bfd297b",
    db_ticker: "TNTM",
    db_name: "TANTUM",
    db_url: "https://ada-stake.com",
    roa: "3.789",
    roa_lifetime: "4.714",
    total_stake: "26489946358535",
    stake_x_deleg: "9307781573.6244",
    saturated: "0.3853415232753",
    luck_lifetime: "98",
    blocks_epoch: "18",
    blocks_lifetime: "5174",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAfrog 2 ",
    icon: "https://cdn.adapools.org/pool_logo/000001b844f4e4c900ae0dfdc84a8845f71090b82fb473e6c70a31ee.png",
    pool_id: "000001b844f4e4c900ae0dfdc84a8845f71090b82fb473e6c70a31ee",
    db_ticker: "FROG2",
    db_name: "ADAfrog 2 ",
    db_url: "https://adafrog.io",
    roa: "3.265",
    roa_lifetime: "4.647",
    total_stake: "16835032867487",
    stake_x_deleg: "9932172783.1782",
    saturated: "0.24489431279867",
    luck_lifetime: "101",
    blocks_epoch: "14",
    blocks_lifetime: "3047",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Beaver Pool!",
    icon: "https://cdn.adapools.org/pool_logo/593dfbc66c5b8dfc040851a2f4d87191490d4c2312c65dd4418f7b23.png",
    pool_id: "593dfbc66c5b8dfc040851a2f4d87191490d4c2312c65dd4418f7b23",
    db_ticker: "BEAVR",
    db_name: "Beaver Pool!",
    db_url: "https://www.beaver-stake-pool.net/",
    roa: "3.482",
    roa_lifetime: "4.475",
    total_stake: "18714968327865",
    stake_x_deleg: "33241506799.05",
    saturated: "0.27224118561436",
    luck_lifetime: "94",
    blocks_epoch: "10",
    blocks_lifetime: "1904",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "130000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AZUL - STAKE FOR FUTURE Pool",
    icon: "https://cdn.adapools.org/pool_logo/19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866.png",
    pool_id: "19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866",
    db_ticker: "AZUL",
    db_name: "AZUL - STAKE FOR FUTURE Pool",
    db_url: "https://stakeforfuture.org/",
    roa: "3.731",
    roa_lifetime: "3.633",
    total_stake: "9010369357228",
    stake_x_deleg: "34522487958.728",
    saturated: "0.13107121495807",
    luck_lifetime: "92",
    blocks_epoch: "10",
    blocks_lifetime: "402",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blue Cheese Stakehouse",
    icon: "https://cdn.adapools.org/pool_logo/00beef68425d90f50c8e2102476a0f42ec850836e9affd601ecf7804.png",
    pool_id: "00beef68425d90f50c8e2102476a0f42ec850836e9affd601ecf7804",
    db_ticker: "BCSH6",
    db_name: "Blue Cheese Stakehouse",
    db_url: "https://bluecheesestakehouse.com",
    roa: "3.653",
    roa_lifetime: "4.161",
    total_stake: "9244644201580",
    stake_x_deleg: "16717258954.033",
    saturated: "0.13447914278721",
    luck_lifetime: "97",
    blocks_epoch: "5",
    blocks_lifetime: "1221",
    tax_ratio: "0.025",
    tax_fix: "400000000",
    pledge: "500001950000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #7 (v1)",
    icon: "0",
    pool_id: "71efddeabf5dd8211853a5ed30548391ccb54fa3b55e5828db9afee6",
    db_ticker: "1PCT7",
    db_name: "1 Percent Pool #7 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.061",
    roa_lifetime: "4.753",
    total_stake: "55000965417624",
    stake_x_deleg: "130954679565.77",
    saturated: "0.80008300163321",
    luck_lifetime: "99",
    blocks_epoch: "52",
    blocks_lifetime: "6677",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking 24/7",
    icon: "https://cdn.adapools.org/pool_logo/4f04025065314e75684cd38357ac6af703fcef705d9b4f638e3caf46.png",
    pool_id: "4f04025065314e75684cd38357ac6af703fcef705d9b4f638e3caf46",
    db_ticker: "247",
    db_name: "Staking 24/7",
    db_url: "https://staking247.com",
    roa: "3.974",
    roa_lifetime: "4.532",
    total_stake: "19414468303805",
    stake_x_deleg: "45149926287.919",
    saturated: "0.2824166077391",
    luck_lifetime: "100",
    blocks_epoch: "16",
    blocks_lifetime: "1002",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COOL3",
    icon: "https://cdn.adapools.org/pool_logo/0840d05089b5bff1e24fe57c1ed905cd6fda68e1147a5b5863d39562.png",
    pool_id: "0840d05089b5bff1e24fe57c1ed905cd6fda68e1147a5b5863d39562",
    db_ticker: "COOL3",
    db_name: "COOL3",
    db_url: "https://www.stakecool.io",
    roa: "3.907",
    roa_lifetime: "4.715",
    total_stake: "42590706895003",
    stake_x_deleg: "243375467971.45",
    saturated: "0.61955459064206",
    luck_lifetime: "101",
    blocks_epoch: "25",
    blocks_lifetime: "4857",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TapTap Vienna",
    icon: "0",
    pool_id: "d2f12c2f3094ed0784f048fe93a62d8b061525b356e043be084470d9",
    db_ticker: "TAPSY",
    db_name: "TapTap Vienna",
    db_url: "https://tap-ada.at",
    roa: "3.835",
    roa_lifetime: "4.837",
    total_stake: "12380265115581",
    stake_x_deleg: "16036612844.017",
    saturated: "0.1800921056472",
    luck_lifetime: "99",
    blocks_epoch: "13",
    blocks_lifetime: "5017",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "21ADA",
    icon: "https://cdn.adapools.org/pool_logo/f3efb842c77895dde51e95e54d9c309da599631ef31216bcfea11083.png",
    pool_id: "f3efb842c77895dde51e95e54d9c309da599631ef31216bcfea11083",
    db_ticker: "21ADA",
    db_name: "21ADA",
    db_url: "https://www.21ada.ca",
    roa: "3.626",
    roa_lifetime: "3.962",
    total_stake: "18129523512114",
    stake_x_deleg: "16391974242.418",
    saturated: "0.26372489063808",
    luck_lifetime: "101",
    blocks_epoch: "12",
    blocks_lifetime: "555",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Plutus Staking #1 ",
    icon: "https://cdn.adapools.org/pool_logo/e00ff546b17eb7ed726411edc74eccdad9d176d0d338c21a3d8daf34.png",
    pool_id: "e00ff546b17eb7ed726411edc74eccdad9d176d0d338c21a3d8daf34",
    db_ticker: "PLTUS",
    db_name: " Plutus Staking #1 ",
    db_url: "https://bit.ly/3fQLy8y",
    roa: "3.976",
    roa_lifetime: "4.798",
    total_stake: "7612607516806",
    stake_x_deleg: "7888712452.6487",
    saturated: "0.11073838115485",
    luck_lifetime: "100",
    blocks_epoch: "8",
    blocks_lifetime: "1993",
    tax_ratio: "0.01",
    tax_fix: "500000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Dan's Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/9dff03e4b074c90bfda895a76e56611e7a67fa9373feb1db63e8ef57.png",
    pool_id: "9dff03e4b074c90bfda895a76e56611e7a67fa9373feb1db63e8ef57",
    db_ticker: "DAN",
    db_name: "Cardano Dan's Stake Pool",
    db_url: "https://twitter.com/cardanodan",
    roa: "3.94",
    roa_lifetime: "4.83",
    total_stake: "8341145729005",
    stake_x_deleg: "13303262725.686",
    saturated: "0.12133621403278",
    luck_lifetime: "98",
    blocks_epoch: "6",
    blocks_lifetime: "3186",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sugar Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/5c8751066db31d6745bc8a58ba1534de67a95f9962fc309b4673dd8c.png",
    pool_id: "5c8751066db31d6745bc8a58ba1534de67a95f9962fc309b4673dd8c",
    db_ticker: "SUGAR",
    db_name: "Sugar Stake Pool",
    db_url: "https://sugarstakepool.jp",
    roa: "3.469",
    roa_lifetime: "4.544",
    total_stake: "6982858784839",
    stake_x_deleg: "79350668009.534",
    saturated: "0.10157761003163",
    luck_lifetime: "102",
    blocks_epoch: "4",
    blocks_lifetime: "772",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arcadia's TESLA Pool",
    icon: "0",
    pool_id: "472314c0412e6cf715366ad460cc3560a6c6314cbd9787b481e9d23a",
    db_ticker: "TESLA",
    db_name: "Arcadia's TESLA Pool",
    db_url: "https://arcadiastakepool.com",
    roa: "4.692",
    roa_lifetime: "4.893",
    total_stake: "6488919593172",
    stake_x_deleg: "34515529750.915",
    saturated: "0.094392420679183",
    luck_lifetime: "109",
    blocks_epoch: "7",
    blocks_lifetime: "913",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200001000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Into the Cryptoverse",
    icon: "https://cdn.adapools.org/pool_logo/89f7ebe5936075aae36b1f7f2e1e9e5d2decd5230cfb962d01e12c84.png",
    pool_id: "89f7ebe5936075aae36b1f7f2e1e9e5d2decd5230cfb962d01e12c84",
    db_ticker: "ITC",
    db_name: "Into the Cryptoverse",
    db_url: "https://www.youtube.com/channel/UCRvqjQPSeaWn-uEx-w0XOIg",
    roa: "3.679",
    roa_lifetime: "4.033",
    total_stake: "41642137100261",
    stake_x_deleg: "4750414909.9089",
    saturated: "0.60575602251015",
    luck_lifetime: "95",
    blocks_epoch: "34",
    blocks_lifetime: "3452",
    tax_ratio: "0.0399",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "kBlocks",
    icon: "0",
    pool_id: "c63dab6d780a74cbae2a27696c9723f55b3092b2bd001256df03827f",
    db_ticker: "BLOCK",
    db_name: "kBlocks",
    db_url: "https://kblocks.net",
    roa: "4.029",
    roa_lifetime: "4.423",
    total_stake: "23709490227405",
    stake_x_deleg: "12788290306.044",
    saturated: "0.34489503891975",
    luck_lifetime: "98",
    blocks_epoch: "16",
    blocks_lifetime: "3444",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAfrog ",
    icon: "https://cdn.adapools.org/pool_logo/00000110093effbf3ce788aebd3e7506b80322bd3995ad432e61fad5.png",
    pool_id: "00000110093effbf3ce788aebd3e7506b80322bd3995ad432e61fad5",
    db_ticker: "FROG",
    db_name: "ADAfrog ",
    db_url: "https://adafrog.io",
    roa: "3.327",
    roa_lifetime: "4.226",
    total_stake: "13513778271052",
    stake_x_deleg: "5804887573.4759",
    saturated: "0.19658099090465",
    luck_lifetime: "96",
    blocks_epoch: "14",
    blocks_lifetime: "1480",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "275000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fresco Pool",
    icon: "https://cdn.adapools.org/pool_logo/19cb138eab81d3559e70094df2b6cb1742bf275e920300d5c3972253.png",
    pool_id: "19cb138eab81d3559e70094df2b6cb1742bf275e920300d5c3972253",
    db_ticker: "VENUS",
    db_name: "Fresco Pool",
    db_url: "https://www.cpoolsunited.com/pool?id=VENUS",
    roa: "2.503",
    roa_lifetime: "3.977",
    total_stake: "6504665617156",
    stake_x_deleg: "39905924031.632",
    saturated: "0.094621473497388",
    luck_lifetime: "95",
    blocks_epoch: "7",
    blocks_lifetime: "629",
    tax_ratio: "0.0085",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAAUS",
    icon: "0",
    pool_id: "78373839385285b7a61841de8b3802d539274ac0de16c3a0bf2fe877",
    db_ticker: "WAAUS",
    db_name: "WAAUS",
    db_url: "https://www.wastaking.xyz",
    roa: "2.204",
    roa_lifetime: "4.856",
    total_stake: "6922800243927",
    stake_x_deleg: "13061887252.692",
    saturated: "0.10070395595444",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "2112",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MANA",
    icon: "https://cdn.adapools.org/pool_logo/58494275c20e5786646ccc1f7d19a4c190382f374b0762208d596580.png",
    pool_id: "58494275c20e5786646ccc1f7d19a4c190382f374b0762208d596580",
    db_ticker: "MANA",
    db_name: "MANA",
    db_url: "https://mine-ada.com",
    roa: "4.723",
    roa_lifetime: "4.162",
    total_stake: "11882916543351",
    stake_x_deleg: "17977180852.271",
    saturated: "0.17285732103013",
    luck_lifetime: "99",
    blocks_epoch: "14",
    blocks_lifetime: "610",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1610000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeLove",
    icon: "0",
    pool_id: "95c4956f7a137f7fe9c72f2e831e6038744b6307d00143b2447e6443",
    db_ticker: "LOVE",
    db_name: "StakeLove",
    db_url: "https://pooltool.io/about",
    roa: "3.399",
    roa_lifetime: "4.814",
    total_stake: "22486148264775",
    stake_x_deleg: "31361434121.025",
    saturated: "0.3270994402052",
    luck_lifetime: "99",
    blocks_epoch: "25",
    blocks_lifetime: "4952",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "600000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 4",
    icon: "https://cdn.adapools.org/pool_logo/707725a41873178814530f39fb14a4b2842d05377e2b62a546fe2014.png",
    pool_id: "707725a41873178814530f39fb14a4b2842d05377e2b62a546fe2014",
    db_ticker: "MS4",
    db_name: "Moonstake 4",
    db_url: "https://moonstake.io",
    roa: "3.88",
    roa_lifetime: "4.447",
    total_stake: "50183006246584",
    stake_x_deleg: "195264615745.46",
    saturated: "0.72999755484073",
    luck_lifetime: "95",
    blocks_epoch: "35",
    blocks_lifetime: "6018",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SITION POOL3",
    icon: "0",
    pool_id: "0d25da9f80c78329f00c773d846e01618b1380334d440dbccab3e68b",
    db_ticker: "SIPO3",
    db_name: "SITION POOL3",
    db_url: "https://sipo.tokyo",
    roa: "3.542",
    roa_lifetime: "4.009",
    total_stake: "40650780906468",
    stake_x_deleg: "66206483561.023",
    saturated: "0.59133505311089",
    luck_lifetime: "98",
    blocks_epoch: "46",
    blocks_lifetime: "2622",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NKR ADA POOL",
    icon: "https://cdn.adapools.org/pool_logo/793bab987086c97fd34f4009dfdc06e36c84c9bfc3f35b0dced39d92.png",
    pool_id: "793bab987086c97fd34f4009dfdc06e36c84c9bfc3f35b0dced39d92",
    db_ticker: "NKR",
    db_name: "NKR ADA POOL",
    db_url: "https://kazunaka.com/",
    roa: "2.859",
    roa_lifetime: "4.276",
    total_stake: "31927093852295",
    stake_x_deleg: "103323928324.58",
    saturated: "0.46443412199787",
    luck_lifetime: "98",
    blocks_epoch: "20",
    blocks_lifetime: "1748",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SITION POOL2",
    icon: "0",
    pool_id: "38ee2f8d878f7c444bd80d38c3b4cff02d3a3477c9e77d262065d9ad",
    db_ticker: "SIPO2",
    db_name: "SITION POOL2",
    db_url: "https://sipo.tokyo",
    roa: "3.546",
    roa_lifetime: "4.255",
    total_stake: "42110528900325",
    stake_x_deleg: "85764824644.246",
    saturated: "0.61256958140844",
    luck_lifetime: "95",
    blocks_epoch: "37",
    blocks_lifetime: "4047",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake Army",
    icon: "https://cdn.adapools.org/pool_logo/08ac812c4721d68695614f3dcde425f1bdb7e298b3da19a9805a54f5.png",
    pool_id: "08ac812c4721d68695614f3dcde425f1bdb7e298b3da19a9805a54f5",
    db_ticker: "AKA",
    db_name: "Stake Army",
    db_url: "https://stake.army",
    roa: "3.259",
    roa_lifetime: "4.76",
    total_stake: "6007505780117",
    stake_x_deleg: "105394838247.67",
    saturated: "0.087389434356087",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "1585",
    tax_ratio: "0.0325",
    tax_fix: "340000000",
    pledge: "52400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CLAY",
    icon: "0",
    pool_id: "0236ec1a0769c1b1bd8b6400d2f0e8e206cccd77a3700e5b7cf172a8",
    db_ticker: "CLAY",
    db_name: "CLAY",
    db_url: "https://www.claynation.io/stake",
    roa: "3.501",
    roa_lifetime: "4.29",
    total_stake: "18084257192538",
    stake_x_deleg: "3996520926.5277",
    saturated: "0.26306641469018",
    luck_lifetime: "96",
    blocks_epoch: "10",
    blocks_lifetime: "871",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BRADA POOL",
    icon: "0",
    pool_id: "7095ee942c6aa5d594f08fa9f377b2f00c4abe96dd58209e2e9d18b7",
    db_ticker: "BRADA",
    db_name: "BRADA POOL",
    db_url: "https://twitter.com/BradaPool",
    roa: "3.585",
    roa_lifetime: "4.59",
    total_stake: "18013226071135",
    stake_x_deleg: "7945842995.6484",
    saturated: "0.26203314568499",
    luck_lifetime: "99",
    blocks_epoch: "8",
    blocks_lifetime: "2826",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #0 (v2)",
    icon: "0",
    pool_id: "41c8a40ab0b78d727655d165f946722728dfb01df2fcc2e148b3c98d",
    db_ticker: "1PCT0",
    db_name: "1 Percent Pool #0 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.941",
    roa_lifetime: "4.672",
    total_stake: "27145644422365",
    stake_x_deleg: "54291288844.73",
    saturated: "0.39487977176041",
    luck_lifetime: "99",
    blocks_epoch: "25",
    blocks_lifetime: "4062",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "307Pool",
    icon: "0",
    pool_id: "2bfa5943dfb17ecd8e681e7ce568e22ac45340a417bdde358e3f5159",
    db_ticker: "307",
    db_name: "307Pool",
    db_url: "https://307pool.com",
    roa: "3.009",
    roa_lifetime: "4.66",
    total_stake: "7114950796403",
    stake_x_deleg: "45318157938.873",
    saturated: "0.10349911399618",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "1547",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LiverPool",
    icon: "0",
    pool_id: "6f9ecffc4bc41068db87bd193e5842577df08a464cb7c001865c8b60",
    db_ticker: "LIV",
    db_name: "LiverPool",
    db_url: "https://babbageblockchain.com",
    roa: "3.237",
    roa_lifetime: "4.242",
    total_stake: "19156712621998",
    stake_x_deleg: "82929491870.121",
    saturated: "0.27866711101623",
    luck_lifetime: "99",
    blocks_epoch: "12",
    blocks_lifetime: "618",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "230000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PEACEpool Cardano ecosystem developers",
    icon: "https://cdn.adapools.org/pool_logo/bd24b3372791f401cc029455c44ea77f3c8750ce3b804a354af0ff16.png",
    pool_id: "bd24b3372791f401cc029455c44ea77f3c8750ce3b804a354af0ff16",
    db_ticker: "PEACE",
    db_name: "PEACEpool Cardano ecosystem developers",
    db_url: "https://twitter.com/repsistance",
    roa: "3.662",
    roa_lifetime: "4.226",
    total_stake: "17640534147688",
    stake_x_deleg: "156110921660.96",
    saturated: "0.25661170497878",
    luck_lifetime: "103",
    blocks_epoch: "13",
    blocks_lifetime: "681",
    tax_ratio: "0.024",
    tax_fix: "340000000",
    pledge: "51000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CArdano Pools EXplained",
    icon: "0",
    pool_id: "4042098128e6161d238f01ff474ae4cbe6451c82498b1bd8713d7098",
    db_ticker: "CAPEX",
    db_name: "CArdano Pools EXplained",
    db_url: "https://www.cardano-pool.eu/",
    roa: "4.372",
    roa_lifetime: "4.931",
    total_stake: "8994149970649",
    stake_x_deleg: "6415228224.4287",
    saturated: "0.13083527627226",
    luck_lifetime: "99",
    blocks_epoch: "6",
    blocks_lifetime: "3541",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MONAD",
    icon: "0",
    pool_id: "3626a235b9bee93c48cf0aaacb238bbd46bef84c8a32bc0969f9a11b",
    db_ticker: "MONAD",
    db_name: "MONAD",
    db_url: "https://www.monadpool.com",
    roa: "3.273",
    roa_lifetime: "4.045",
    total_stake: "16467753734172",
    stake_x_deleg: "68330928357.56",
    saturated: "0.23955161037175",
    luck_lifetime: "100",
    blocks_epoch: "14",
    blocks_lifetime: "468",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "400161000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BEAST Pool",
    icon: "https://cdn.adapools.org/pool_logo/bc7b9907c9fcfd5dbba3e8253d51998ca827007e759e1ec30b3f9c1b.png",
    pool_id: "bc7b9907c9fcfd5dbba3e8253d51998ca827007e759e1ec30b3f9c1b",
    db_ticker: "BEAST",
    db_name: "BEAST Pool",
    db_url: "https://twitter.com/Beastlyorion",
    roa: "3.595",
    roa_lifetime: "4.624",
    total_stake: "11831288025700",
    stake_x_deleg: "73945550160.625",
    saturated: "0.1721062960425",
    luck_lifetime: "103",
    blocks_epoch: "8",
    blocks_lifetime: "1396",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #4 (v2)",
    icon: "0",
    pool_id: "f018942907ac50ed7f831f14755ad93698188492d09d229655e6ade1",
    db_ticker: "1PCT4",
    db_name: "1 Percent Pool #4 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.274",
    roa_lifetime: "4.781",
    total_stake: "18115634611365",
    stake_x_deleg: "37662442019.47",
    saturated: "0.26352285284991",
    luck_lifetime: "101",
    blocks_epoch: "18",
    blocks_lifetime: "4772",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #4 (v1)",
    icon: "0",
    pool_id: "64e09628015d1065e4a11f032c90f677ba1f2f583ff0f55a158450b0",
    db_ticker: "1PCT4",
    db_name: "1 Percent Pool #4 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.395",
    roa_lifetime: "4.938",
    total_stake: "14284818032015",
    stake_x_deleg: "28569636064.03",
    saturated: "0.20779708141589",
    luck_lifetime: "99",
    blocks_epoch: "18",
    blocks_lifetime: "5766",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #1 (v1)",
    icon: "0",
    pool_id: "844540411ea37a35689620b02570eec2a0e403c1207c92c1b7a4dfa0",
    db_ticker: "1PCT1",
    db_name: "1 Percent Pool #1 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.552",
    roa_lifetime: "4.937",
    total_stake: "16465268792080",
    stake_x_deleg: "38380579934.918",
    saturated: "0.23951546264393",
    luck_lifetime: "98",
    blocks_epoch: "11",
    blocks_lifetime: "3514",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool (v1)",
    icon: "0",
    pool_id: "87021d7881cff6817040033a3c6b0246d11421c198e69195e60a7000",
    db_ticker: "1PCT",
    db_name: "1 Percent Pool (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.786",
    roa_lifetime: "4.946",
    total_stake: "10667101019880",
    stake_x_deleg: "15618010278.009",
    saturated: "0.15517120723076",
    luck_lifetime: "98",
    blocks_epoch: "11",
    blocks_lifetime: "4665",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Grow Your Stake",
    icon: "https://cdn.adapools.org/pool_logo/59db640a870601fedb539d3f4954007332e76e463d1265c096b69c59.png",
    pool_id: "59db640a870601fedb539d3f4954007332e76e463d1265c096b69c59",
    db_ticker: "GYS",
    db_name: "Grow Your Stake",
    db_url: "https://www.growyourstake.com",
    roa: "2.076",
    roa_lifetime: "3.011",
    total_stake: "3988515651898",
    stake_x_deleg: "47482329189.262",
    saturated: "0.058019773845807",
    luck_lifetime: "78",
    blocks_epoch: "7",
    blocks_lifetime: "144",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #3 (v1)",
    icon: "0",
    pool_id: "56f78c5478524496790461eefd4d9380f71dfb231358736902648dfe",
    db_ticker: "1PCT3",
    db_name: "1 Percent Pool #3 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.138",
    roa_lifetime: "4.8",
    total_stake: "22822772421722",
    stake_x_deleg: "63220976237.457",
    saturated: "0.33199621363213",
    luck_lifetime: "99",
    blocks_epoch: "19",
    blocks_lifetime: "5253",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #8 (v1)",
    icon: "0",
    pool_id: "6b5180a258275c671690c94c704f074190e90ea900ed565b4c29abe8",
    db_ticker: "1PCT8",
    db_name: "1 Percent Pool #8 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.296",
    roa_lifetime: "4.921",
    total_stake: "10580024308280",
    stake_x_deleg: "15767547404.292",
    saturated: "0.15390452770505",
    luck_lifetime: "97",
    blocks_epoch: "11",
    blocks_lifetime: "5256",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DigitalAssetNews",
    icon: "https://cdn.adapools.org/pool_logo/727ceb44ef548cbb6f36d7023dfbd8400d48aea0c84b675beff1dd12.png",
    pool_id: "727ceb44ef548cbb6f36d7023dfbd8400d48aea0c84b675beff1dd12",
    db_ticker: "DNEWS",
    db_name: "DigitalAssetNews",
    db_url: "https://danteachescrypto.com/cardano-staking-pool/",
    roa: "3.157",
    roa_lifetime: "4.32",
    total_stake: "24134140858035",
    stake_x_deleg: "8333612174.7358",
    saturated: "0.3510723077844",
    luck_lifetime: "98",
    blocks_epoch: "21",
    blocks_lifetime: "2673",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "75000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Husky pool",
    icon: "0",
    pool_id: "a3a542bf3a44361dad63349afcef16797afb957d1354e77bc0d37846",
    db_ticker: "HUSKY",
    db_name: "Husky pool",
    db_url: "https://huskypool.com",
    roa: "4.56",
    roa_lifetime: "4.698",
    total_stake: "7261380549173",
    stake_x_deleg: "5971530056.8857",
    saturated: "0.10562918490012",
    luck_lifetime: "103",
    blocks_epoch: "6",
    blocks_lifetime: "1882",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #5 (v1)",
    icon: "0",
    pool_id: "1d8988c2057d6efd6a094e468840a51942ab03b5b69b07a2bca71b53",
    db_ticker: "1PCT5",
    db_name: "1 Percent Pool #5 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.087",
    roa_lifetime: "4.968",
    total_stake: "10586852245427",
    stake_x_deleg: "20088903691.512",
    saturated: "0.1540038517152",
    luck_lifetime: "98",
    blocks_epoch: "9",
    blocks_lifetime: "5513",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #9 (v1)",
    icon: "0",
    pool_id: "b59366e330a6db8260ad9aae8446ba8c908931d1b9cb3cca5d1d84aa",
    db_ticker: "1PCT9",
    db_name: "1 Percent Pool #9 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.157",
    roa_lifetime: "4.856",
    total_stake: "16323184527014",
    stake_x_deleg: "43297571689.692",
    saturated: "0.23744860428216",
    luck_lifetime: "98",
    blocks_epoch: "14",
    blocks_lifetime: "4803",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Loot Pool",
    icon: "0",
    pool_id: "868dab7d64c04c03aae405fe0ffc128829f56ceea94a862f9530d2c5",
    db_ticker: "LOOT",
    db_name: "Loot Pool",
    db_url: "https://lootpool.io",
    roa: "3.63",
    roa_lifetime: "4.89",
    total_stake: "6562262986595",
    stake_x_deleg: "3022691380.2833",
    saturated: "0.095459325631019",
    luck_lifetime: "103",
    blocks_epoch: "7",
    blocks_lifetime: "2345",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AZDFM hosted by AzureADA Services",
    icon: "0",
    pool_id: "bce696401137ff3d305e6a8dbb6ac0cd9b41570bda42deb4e83d86f2",
    db_ticker: "AZDFM",
    db_name: "AZDFM hosted by AzureADA Services",
    db_url: "https://azureada.com/azdfm",
    roa: "3.312",
    roa_lifetime: "3.806",
    total_stake: "21754991370958",
    stake_x_deleg: "36440521559.394",
    saturated: "0.31646351412958",
    luck_lifetime: "91",
    blocks_epoch: "10",
    blocks_lifetime: "1087",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BeKind",
    icon: "https://cdn.adapools.org/pool_logo/d9e2d181dfe21ab2ca7e95847b9a219e691ee1366d22569712390655.png",
    pool_id: "d9e2d181dfe21ab2ca7e95847b9a219e691ee1366d22569712390655",
    db_ticker: "BKIND",
    db_name: "BeKind",
    db_url: "https://bekindstakepools.online",
    roa: "4.229",
    roa_lifetime: "4.66",
    total_stake: "14575555116467",
    stake_x_deleg: "112988799352.46",
    saturated: "0.21202634898326",
    luck_lifetime: "102",
    blocks_epoch: "18",
    blocks_lifetime: "2125",
    tax_ratio: "0.04",
    tax_fix: "400000000",
    pledge: "125000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KIND, The Charitable Pool",
    icon: "0",
    pool_id: "969b78926481d5e944e5f680f04611c16e1120d57da58056be55f5bf",
    db_ticker: "KIND",
    db_name: "KIND, The Charitable Pool",
    db_url: "https://www.kindstakepool.com/",
    roa: "3.146",
    roa_lifetime: "4.541",
    total_stake: "9644378221253",
    stake_x_deleg: "16236326971.806",
    saturated: "0.14029395697977",
    luck_lifetime: "101",
    blocks_epoch: "13",
    blocks_lifetime: "1806",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #6 (v1)",
    icon: "0",
    pool_id: "e75ff353a1a2e454d3fe59249f87dad63b4270b16caf4b819bae5f69",
    db_ticker: "1PCT6",
    db_name: "1 Percent Pool #6 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.076",
    roa_lifetime: "5.012",
    total_stake: "6417215885879",
    stake_x_deleg: "13770849540.513",
    saturated: "0.093349367763228",
    luck_lifetime: "99",
    blocks_epoch: "8",
    blocks_lifetime: "4278",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #3 (v2)",
    icon: "0",
    pool_id: "43e608ee299b1aaad2d54f5986717379598cbc90ba291a27476a8dda",
    db_ticker: "1PCT3",
    db_name: "1 Percent Pool #3 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "2.984",
    roa_lifetime: "4.803",
    total_stake: "12008247786907",
    stake_x_deleg: "34907697054.962",
    saturated: "0.1746804780744",
    luck_lifetime: "101",
    blocks_epoch: "11",
    blocks_lifetime: "2686",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA.ZONE",
    icon: "0",
    pool_id: "b6ad3e6e873a7654e68bdfe5922c86d506cd5e9299fbc3aaab3e84bd",
    db_ticker: "ZONE",
    db_name: "ADA.ZONE",
    db_url: "https://ada.zone",
    roa: "4.428",
    roa_lifetime: "4.675",
    total_stake: "6679674733407",
    stake_x_deleg: "21340813844.751",
    saturated: "0.097167280066056",
    luck_lifetime: "97",
    blocks_epoch: "3",
    blocks_lifetime: "1668",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Masterstake",
    icon: "0",
    pool_id: "9479b6e386c65d8308a776e30350c0720c67b3c28fb4624920ac9199",
    db_ticker: "MASTR",
    db_name: "Masterstake",
    db_url: "https://masterstake.com",
    roa: "3.883",
    roa_lifetime: "4.221",
    total_stake: "13017914884414",
    stake_x_deleg: "19087851736.677",
    saturated: "0.18936781084919",
    luck_lifetime: "97",
    blocks_epoch: "11",
    blocks_lifetime: "1824",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tokai Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c27db049e669c55902e3f4d34d5366e3321b1f3c6517acdf7c766a5b.png",
    pool_id: "c27db049e669c55902e3f4d34d5366e3321b1f3c6517acdf7c766a5b",
    db_ticker: "TOKAI",
    db_name: "Tokai Stake Pool",
    db_url: "https://aichi-stakepool.com",
    roa: "3.742",
    roa_lifetime: "4.283",
    total_stake: "21315676755694",
    stake_x_deleg: "219749244904.06",
    saturated: "0.31007293255755",
    luck_lifetime: "98",
    blocks_epoch: "27",
    blocks_lifetime: "2189",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #6 (v2)",
    icon: "0",
    pool_id: "3ef28c9dcc109acb9f0636a789808cee3f5f9b96754b4c1fc6d88e0c",
    db_ticker: "1PCT6",
    db_name: "1 Percent Pool #6 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.383",
    roa_lifetime: "4.611",
    total_stake: "7048100983153",
    stake_x_deleg: "17402718476.921",
    saturated: "0.10252666926112",
    luck_lifetime: "101",
    blocks_epoch: "3",
    blocks_lifetime: "3179",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cosmic Asset Stake Pool",
    icon: "0",
    pool_id: "c473846af5dfa3d56d2bdb765d8a2835be6dcc5c70be5cc4ff136a45",
    db_ticker: "COSMC",
    db_name: "Cosmic Asset Stake Pool",
    db_url: "https://www.cosmicassetpool.net/",
    roa: "3.592",
    roa_lifetime: "4.469",
    total_stake: "22353414470295",
    stake_x_deleg: "154161479105.48",
    saturated: "0.32516860041177",
    luck_lifetime: "97",
    blocks_epoch: "15",
    blocks_lifetime: "2211",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC Cardano Stake World",
    icon: "0",
    pool_id: "a0a6af80153d4bc92d7c5dfa4c07bb99067ee4b65066a4d87f488503",
    db_ticker: "THC",
    db_name: "THC Cardano Stake World",
    db_url: "https://thc-room335.com",
    roa: "3.03",
    roa_lifetime: "4.372",
    total_stake: "6608331380272",
    stake_x_deleg: "6542902356.705",
    saturated: "0.096129469116932",
    luck_lifetime: "102",
    blocks_epoch: "2",
    blocks_lifetime: "1466",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arrakis",
    icon: "0",
    pool_id: "c65ca06828caa8fc9b0bb015af93ef71685544c6ed2abbb7c59b0e62",
    db_ticker: "ARRA",
    db_name: "Arrakis",
    db_url: "https://arrapool.io",
    roa: "3.419",
    roa_lifetime: "4.149",
    total_stake: "3204142480894",
    stake_x_deleg: "47119742366.088",
    saturated: "0.046609726107693",
    luck_lifetime: "102",
    blocks_epoch: "12",
    blocks_lifetime: "500",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 6",
    icon: "https://cdn.adapools.org/pool_logo/48794370608c8d41cf4ad665833da210ad4fed6c6fd40b48bfad9ca8.png",
    pool_id: "48794370608c8d41cf4ad665833da210ad4fed6c6fd40b48bfad9ca8",
    db_ticker: "MS6",
    db_name: "Moonstake 6",
    db_url: "https://moonstake.io",
    roa: "3.984",
    roa_lifetime: "4.43",
    total_stake: "56079303776388",
    stake_x_deleg: "296715892996.76",
    saturated: "0.81576927521595",
    luck_lifetime: "99",
    blocks_epoch: "43",
    blocks_lifetime: "6199",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VEGASPool",
    icon: "0",
    pool_id: "6a4a39566f5c45f0f6de34ef5e674b892e517ddeebd4eafcfd8583ef",
    db_ticker: "VEGAS",
    db_name: "VEGASPool",
    db_url: "https://www.ada.vegas",
    roa: "3.23",
    roa_lifetime: "4.16",
    total_stake: "6649390964938",
    stake_x_deleg: "7479629881.82",
    saturated: "0.096726750919096",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "469",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 7",
    icon: "https://cdn.adapools.org/pool_logo/b62772f5982c919ff658977c4d3a0c937bcfd8127aba2c405266e217.png",
    pool_id: "b62772f5982c919ff658977c4d3a0c937bcfd8127aba2c405266e217",
    db_ticker: "MS7",
    db_name: "Moonstake 7",
    db_url: "https://moonstake.io",
    roa: "3.81",
    roa_lifetime: "4.497",
    total_stake: "55266015790858",
    stake_x_deleg: "307033421060.32",
    saturated: "0.8039386121046",
    luck_lifetime: "98",
    blocks_epoch: "50",
    blocks_lifetime: "5614",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #2 (v3)",
    icon: "0",
    pool_id: "550e404e1946236f0fa0c36c6e54e58f41931bf24ff682a0ba226fd5",
    db_ticker: "1PCT2",
    db_name: "1 Percent Pool #2 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.031",
    roa_lifetime: "4.656",
    total_stake: "5210114400165",
    stake_x_deleg: "27861574332.433",
    saturated: "0.075790014529466",
    luck_lifetime: "106",
    blocks_epoch: "21",
    blocks_lifetime: "1159",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Endeavor Two",
    icon: "https://cdn.adapools.org/pool_logo/1bdb8db050fb6cfd13a8e34ddec812e0aec77c589f71fc396cd9d950.png",
    pool_id: "1bdb8db050fb6cfd13a8e34ddec812e0aec77c589f71fc396cd9d950",
    db_ticker: "ENDVR",
    db_name: "Endeavor Two",
    db_url: "https://endeavorstakepools.com",
    roa: "3.77",
    roa_lifetime: "4.395",
    total_stake: "49403388744947",
    stake_x_deleg: "374268096552.63",
    saturated: "0.71865668643779",
    luck_lifetime: "98",
    blocks_epoch: "40",
    blocks_lifetime: "2344",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Philippines",
    icon: "0",
    pool_id: "cba1419077fd3a23e5036727a3994e6dd0c5dcd259bdb89df6863431",
    db_ticker: "ADAPH",
    db_name: "ADA Philippines",
    db_url: "https://pool.adaph.io",
    roa: "3.815",
    roa_lifetime: "4.146",
    total_stake: "16042465634343",
    stake_x_deleg: "67689728414.949",
    saturated: "0.2333650684286",
    luck_lifetime: "98",
    blocks_epoch: "8",
    blocks_lifetime: "459",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ouros",
    icon: "0",
    pool_id: "7862852ab4afc9ecf783ff074760ad2f3bf52669713ddd67d6794512",
    db_ticker: "OUROS",
    db_name: "Ouros",
    db_url: "https://ourostech.com.br",
    roa: "3.572",
    roa_lifetime: "4.4",
    total_stake: "5890289261595",
    stake_x_deleg: "37045844412.547",
    saturated: "0.085684319850041",
    luck_lifetime: "100",
    blocks_epoch: "5",
    blocks_lifetime: "651",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "380000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NAP-Nekota ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/56b76df4f3653a6faf62f6f6838045cf9f3dde69a1eee353de526ee4.png",
    pool_id: "56b76df4f3653a6faf62f6f6838045cf9f3dde69a1eee353de526ee4",
    db_ticker: "NAP",
    db_name: "NAP-Nekota ADA Pool",
    db_url: "https://www.publickey.co.jp",
    roa: "3.5",
    roa_lifetime: "4.626",
    total_stake: "40760602969910",
    stake_x_deleg: "253171447018.07",
    saturated: "0.59293260263565",
    luck_lifetime: "97",
    blocks_epoch: "25",
    blocks_lifetime: "5110",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #1 (v2)",
    icon: "0",
    pool_id: "e0bfd78fd14b0336186ff6ca79a45f3619102b5bb484f7e0124dfdc0",
    db_ticker: "1PCT1",
    db_name: "1 Percent Pool #1 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.709",
    roa_lifetime: "4.539",
    total_stake: "8803036381546",
    stake_x_deleg: "49178974198.581",
    saturated: "0.12805520263426",
    luck_lifetime: "100",
    blocks_epoch: "9",
    blocks_lifetime: "4999",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Japanese Stake Pool",
    icon: "0",
    pool_id: "6dd356ae25a25f87f253863a26098ef4493121cc5a696a90b82bfd77",
    db_ticker: "JSP",
    db_name: "Japanese Stake Pool",
    db_url: "https://cardano-ada-staking-pool.com",
    roa: "3.992",
    roa_lifetime: "4.387",
    total_stake: "6427616858992",
    stake_x_deleg: "34557079887.054",
    saturated: "0.09350066768542",
    luck_lifetime: "102",
    blocks_epoch: "7",
    blocks_lifetime: "785",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ouroboros-暗号通貨革命",
    icon: "0",
    pool_id: "072a7705cee8f76cbaff72325340fe821674366002b5398f2a66b07e",
    db_ticker: "OBS",
    db_name: "Ouroboros-暗号通貨革命",
    db_url: "https://ouroboros.mobi",
    roa: "4.01",
    roa_lifetime: "4.407",
    total_stake: "13109240597237",
    stake_x_deleg: "73235980990.151",
    saturated: "0.19069629935638",
    luck_lifetime: "100",
    blocks_epoch: "10",
    blocks_lifetime: "1065",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "95000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #3 (v3)",
    icon: "0",
    pool_id: "6b9da81e0b66fcdfaa67f55f08b28399b70e031f3cb13e6aa8f61b0a",
    db_ticker: "1PCT3",
    db_name: "1 Percent Pool #3 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.794",
    roa_lifetime: "4.464",
    total_stake: "3545472709907",
    stake_x_deleg: "29545605915.892",
    saturated: "0.051574957392331",
    luck_lifetime: "99",
    blocks_epoch: "7",
    blocks_lifetime: "4606",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #5 (v3)",
    icon: "0",
    pool_id: "a19199571e9c14f2502bf37146034491fd08521f5253cc6ef497b5a9",
    db_ticker: "1PCT5",
    db_name: "1 Percent Pool #5 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.376",
    roa_lifetime: "4.497",
    total_stake: "3208223976601",
    stake_x_deleg: "29983401650.477",
    saturated: "0.04666909843528",
    luck_lifetime: "101",
    blocks_epoch: "25",
    blocks_lifetime: "760",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #9 (v2)",
    icon: "0",
    pool_id: "27fdacbd3703ed8491520415b5eaff29b241cf30cdc01786b03975f7",
    db_ticker: "1PCT9",
    db_name: "1 Percent Pool #9 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.519",
    roa_lifetime: "4.505",
    total_stake: "6135919756880",
    stake_x_deleg: "44143307603.453",
    saturated: "0.089257435021167",
    luck_lifetime: "96",
    blocks_epoch: "6",
    blocks_lifetime: "970",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #6 (v3)",
    icon: "0",
    pool_id: "99127328c3fed20710b0b807f32b10ccd731125b30de38b9ff973a01",
    db_ticker: "1PCT6",
    db_name: "1 Percent Pool #6 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.208",
    roa_lifetime: "4.252",
    total_stake: "6366075497269",
    stake_x_deleg: "46467704359.628",
    saturated: "0.092605443446389",
    luck_lifetime: "98",
    blocks_epoch: "17",
    blocks_lifetime: "3202",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OSAKA-Pool",
    icon: "https://cdn.adapools.org/pool_logo/fdd8140cffbc13abbe2c28806dfaa1eea23698d527196025d55b0a9e.png",
    pool_id: "fdd8140cffbc13abbe2c28806dfaa1eea23698d527196025d55b0a9e",
    db_ticker: "OSAKA",
    db_name: "OSAKA-Pool",
    db_url: "https://osaka-pool.com",
    roa: "3.445",
    roa_lifetime: "4.711",
    total_stake: "13864414917106",
    stake_x_deleg: "165052558536.98",
    saturated: "0.20168159992355",
    luck_lifetime: "102",
    blocks_epoch: "17",
    blocks_lifetime: "1987",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Easy Staking Pool",
    icon: "0",
    pool_id: "20df8645abddf09403ba2656cda7da2cd163973a5e439c6e43dcbea9",
    db_ticker: "EASY1",
    db_name: "Easy Staking Pool",
    db_url: "http://easystaking.org/",
    roa: "4.141",
    roa_lifetime: "3.843",
    total_stake: "16529906670298",
    stake_x_deleg: "87925035480.309",
    saturated: "0.24045573100524",
    luck_lifetime: "97",
    blocks_epoch: "14",
    blocks_lifetime: "595",
    tax_ratio: "0.022",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Suisse",
    icon: "0",
    pool_id: "ae0154e2fbd824443a4ea8b26d800dd77d8e982b94d90c939a77672e",
    db_ticker: "CH1",
    db_name: "Cardano Suisse",
    db_url: "http://cardanosuisse.com",
    roa: "3.544",
    roa_lifetime: "3.767",
    total_stake: "8749419193096",
    stake_x_deleg: "57943173464.212",
    saturated: "0.12727524903256",
    luck_lifetime: "95",
    blocks_epoch: "6",
    blocks_lifetime: "323",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Falcon Cardano Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/50de79c63d672319e20be59c620eb57ed1de8b7cc7fa489d8f22e353.png",
    pool_id: "50de79c63d672319e20be59c620eb57ed1de8b7cc7fa489d8f22e353",
    db_ticker: "FALCO",
    db_name: "Falcon Cardano Stakepool",
    db_url: "https://falconstakepool.com",
    roa: "3.339",
    roa_lifetime: "3.762",
    total_stake: "15175382903972",
    stake_x_deleg: "229930043999.58",
    saturated: "0.22075186885452",
    luck_lifetime: "95",
    blocks_epoch: "12",
    blocks_lifetime: "223",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "42000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Medusa Development Support",
    icon: "0",
    pool_id: "660f2b816e90e4e826daebf33357d76ab89414f88d57dd7d9f7898fe",
    db_ticker: "MDS",
    db_name: "Medusa Development Support",
    db_url: "https://adawallet.io",
    roa: "3.924",
    roa_lifetime: "3.925",
    total_stake: "16217006707316",
    stake_x_deleg: "154447682926.82",
    saturated: "0.23590406650821",
    luck_lifetime: "92",
    blocks_epoch: "13",
    blocks_lifetime: "630",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pudim's pool",
    icon: "0",
    pool_id: "e4b1c8ec89415ce6349755a1aa44b4affbb5f1248ff29943d190c715",
    db_ticker: "PUDIM",
    db_name: "Pudim's pool",
    db_url: "https://pudim.od.gy",
    roa: "4.119",
    roa_lifetime: "4.28",
    total_stake: "12496963282310",
    stake_x_deleg: "140415317778.76",
    saturated: "0.1817896798409",
    luck_lifetime: "101",
    blocks_epoch: "9",
    blocks_lifetime: "939",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #0 (v1)",
    icon: "0",
    pool_id: "463e481957f266d93e4decbfb73003380ab17223e0e569f71c7a525a",
    db_ticker: "1PCT0",
    db_name: "1 Percent Pool #0 (v1)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.753",
    roa_lifetime: "4.979",
    total_stake: "61150351713549",
    stake_x_deleg: "34029132840.038",
    saturated: "0.88953633047003",
    luck_lifetime: "100",
    blocks_epoch: "52",
    blocks_lifetime: "7910",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Asahi Stake Pool",
    icon: "0",
    pool_id: "5016cf588331d0f6d0e57f7f6d36d8d4f53224c77c09380580ea6430",
    db_ticker: "ASAHI",
    db_name: "Asahi Stake Pool",
    db_url: "https://twitter.com/PoolAsahi",
    roa: "3.248",
    roa_lifetime: "4.428",
    total_stake: "6978396051491",
    stake_x_deleg: "89466616044.756",
    saturated: "0.10151269195128",
    luck_lifetime: "104",
    blocks_epoch: "9",
    blocks_lifetime: "655",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #4 (v3)",
    icon: "0",
    pool_id: "a75f5263ca05569b3baecf0fafbfdaaf07076727c44460e4641b415b",
    db_ticker: "1PCT4",
    db_name: "1 Percent Pool #4 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.497",
    roa_lifetime: "4.229",
    total_stake: "5207764589545",
    stake_x_deleg: "77727829694.701",
    saturated: "0.075755832519754",
    luck_lifetime: "96",
    blocks_epoch: "6",
    blocks_lifetime: "3446",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DigitalAssetNews Two",
    icon: "https://cdn.adapools.org/pool_logo/9b97ad981c8456110e17b1297a58961147209b7dc0d8e78b44ed8bcb.png",
    pool_id: "9b97ad981c8456110e17b1297a58961147209b7dc0d8e78b44ed8bcb",
    db_ticker: "DNEWS",
    db_name: "DigitalAssetNews Two",
    db_url: "https://danteachescrypto.com/cardano-staking-pool/",
    roa: "2.973",
    roa_lifetime: "4.293",
    total_stake: "18949969500035",
    stake_x_deleg: "6811635334.3045",
    saturated: "0.27565967912242",
    luck_lifetime: "99",
    blocks_epoch: "17",
    blocks_lifetime: "2205",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zetetic",
    icon: "https://cdn.adapools.org/pool_logo/a087620de93bee6faeb0c33ca5e647f9c4e1e037d8f28e95dc583179.png",
    pool_id: "a087620de93bee6faeb0c33ca5e647f9c4e1e037d8f28e95dc583179",
    db_ticker: "ZETE",
    db_name: "Zetetic",
    db_url: "https://zetetic.tech",
    roa: "4.09",
    roa_lifetime: "4.824",
    total_stake: "62322543390961",
    stake_x_deleg: "7612378574.6868",
    saturated: "0.90658786090468",
    luck_lifetime: "103",
    blocks_epoch: "51",
    blocks_lifetime: "6004",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "400000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaStat Pool",
    icon: "https://cdn.adapools.org/pool_logo/77b0a93c26ac65be36e9a9f220f9a43cbc57d705fc5d8f1de5fdeea1.png",
    pool_id: "77b0a93c26ac65be36e9a9f220f9a43cbc57d705fc5d8f1de5fdeea1",
    db_ticker: "STAT",
    db_name: "AdaStat Pool",
    db_url: "https://adastat.net/",
    roa: "4.088",
    roa_lifetime: "4.804",
    total_stake: "61792556677391",
    stake_x_deleg: "11075919820.289",
    saturated: "0.89887829876519",
    luck_lifetime: "101",
    blocks_epoch: "46",
    blocks_lifetime: "5205",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "220000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ATM Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/003da6afe37eca8d1bec9a040a51f23eb085c3e1b924948a214067e7.png",
    pool_id: "003da6afe37eca8d1bec9a040a51f23eb085c3e1b924948a214067e7",
    db_ticker: "ATM",
    db_name: "ATM Stake Pool",
    db_url: "https://atmstakepool.com/",
    roa: "4.224",
    roa_lifetime: "4.499",
    total_stake: "15432747156281",
    stake_x_deleg: "314954023597.57",
    saturated: "0.22449567156665",
    luck_lifetime: "98",
    blocks_epoch: "9",
    blocks_lifetime: "432",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RDLRT",
    icon: "0",
    pool_id: "5271fc86fd9c25613c138c4aef6f8593b2952c95897b079facebbc9e",
    db_ticker: "RDLRT",
    db_name: "RDLRT",
    db_url: "https://github.com/rdlrt/rdlrt-pool",
    roa: "3.935",
    roa_lifetime: "4.602",
    total_stake: "17330081519774",
    stake_x_deleg: "192556461330.82",
    saturated: "0.25209564115116",
    luck_lifetime: "95",
    blocks_epoch: "10",
    blocks_lifetime: "2149",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOMO ADA POOL",
    icon: "https://cdn.adapools.org/pool_logo/c6da03f57821cdefa6f77958de6d99e5de4740cf568bc1fbd59b0562.png",
    pool_id: "c6da03f57821cdefa6f77958de6d99e5de4740cf568bc1fbd59b0562",
    db_ticker: "MOMO",
    db_name: "MOMO ADA POOL",
    db_url: "https://twitter.com/momoadapool",
    roa: "3.872",
    roa_lifetime: "4.01",
    total_stake: "19855868035801",
    stake_x_deleg: "239227325732.54",
    saturated: "0.28883752089606",
    luck_lifetime: "101",
    blocks_epoch: "9",
    blocks_lifetime: "411",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NAP2-Nekota ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/254b9f3ded998ff0b053d92081394f8ee40d7fdcd9e96ed490d19e2c.png",
    pool_id: "254b9f3ded998ff0b053d92081394f8ee40d7fdcd9e96ed490d19e2c",
    db_ticker: "NAP2",
    db_name: "NAP2-Nekota ADA Pool",
    db_url: "https://www.publickey.co.jp",
    roa: "3.396",
    roa_lifetime: "4.285",
    total_stake: "7160508470478",
    stake_x_deleg: "204585956299.37",
    saturated: "0.10416182819301",
    luck_lifetime: "99",
    blocks_epoch: "4",
    blocks_lifetime: "912",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaStrong",
    icon: "0",
    pool_id: "e2eed9f58a161573050ab9324252b4fcccbf9fe06e1a034b6f91d748",
    db_ticker: "ADAST",
    db_name: "AdaStrong",
    db_url: "https://www.adastrong.com/",
    roa: "3.68",
    roa_lifetime: "4.766",
    total_stake: "37189761910347",
    stake_x_deleg: "110683815209.37",
    saturated: "0.54098861926013",
    luck_lifetime: "101",
    blocks_epoch: "27",
    blocks_lifetime: "3130",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "3000010000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hash Valley",
    icon: "0",
    pool_id: "b49a54a4bca1160af57d6314d4c1110b0c071db47eaaa08b619c4fa1",
    db_ticker: "HASH",
    db_name: "Hash Valley",
    db_url: "https://hashvalley.com/",
    roa: "4.289",
    roa_lifetime: "4.447",
    total_stake: "8432941955791",
    stake_x_deleg: "153326217378.02",
    saturated: "0.12267154696935",
    luck_lifetime: "96",
    blocks_epoch: "9",
    blocks_lifetime: "1326",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "202000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZZZ5",
    icon: "0",
    pool_id: "91ab082852ea331c39eebae35a3e2a390ea26423d82a91c72a02fb85",
    db_ticker: "ZZZ5",
    db_name: "ZZZ5",
    db_url: "https://coinzzz.jp",
    roa: "4.219",
    roa_lifetime: "4.778",
    total_stake: "37254659112637",
    stake_x_deleg: "115697699107.57",
    saturated: "0.54193265993308",
    luck_lifetime: "100",
    blocks_epoch: "31",
    blocks_lifetime: "6655",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardanofun1",
    icon: "0",
    pool_id: "09612bc8cf646ded39886839e962d050fb53b81fbe3487eed973554b",
    db_ticker: "FUN1",
    db_name: "cardanofun1",
    db_url: "https://staking.cardanofun.com/",
    roa: "3.209",
    roa_lifetime: "4.509",
    total_stake: "29304514634108",
    stake_x_deleg: "111001949371.62",
    saturated: "0.42628422704647",
    luck_lifetime: "98",
    blocks_epoch: "25",
    blocks_lifetime: "4651",
    tax_ratio: "0.048935",
    tax_fix: "340000000",
    pledge: "158900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCJ3",
    icon: "0",
    pool_id: "4a2aa209e00f2926bd0d8a286592bbdea4d35a593c05d41efe29e642",
    db_ticker: "CCJ3",
    db_name: "CCJ3",
    db_url: "http://ccj123.html.xdomain.jp",
    roa: "3.67",
    roa_lifetime: "4.695",
    total_stake: "31491369432502",
    stake_x_deleg: "208552115447.03",
    saturated: "0.45809576595219",
    luck_lifetime: "99",
    blocks_epoch: "29",
    blocks_lifetime: "4537",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCJ2",
    icon: "0",
    pool_id: "8871f659c6ce6a29afc0c52963cc872898db31235bead5b4a514b746",
    db_ticker: "CCJ2",
    db_name: "CCJ2",
    db_url: "http://ccj123.html.xdomain.jp",
    roa: "3.337",
    roa_lifetime: "4.544",
    total_stake: "43333343875442",
    stake_x_deleg: "254902022796.72",
    saturated: "0.63035751418934",
    luck_lifetime: "99",
    blocks_epoch: "48",
    blocks_lifetime: "5581",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Earn Stakepool",
    icon: "0",
    pool_id: "7ca6f74bf72922e511003d78affd9f999228ff1543e1007f610c5d12",
    db_ticker: "EARN",
    db_name: "Earn Stakepool",
    db_url: "https://earningcardano.com",
    roa: "2.674",
    roa_lifetime: "4.544",
    total_stake: "7615761211907",
    stake_x_deleg: "87537485194.333",
    saturated: "0.11078425703764",
    luck_lifetime: "103",
    blocks_epoch: "5",
    blocks_lifetime: "986",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "150750000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Maladex - Research Driven Cardano DEX",
    icon: "https://cdn.adapools.org/pool_logo/261789e4d39000aa333495d4dbe850708a7fe7a996011a6be98060db.png",
    pool_id: "261789e4d39000aa333495d4dbe850708a7fe7a996011a6be98060db",
    db_ticker: "MAL",
    db_name: "Maladex - Research Driven Cardano DEX",
    db_url: "https://maladex.com",
    roa: "5.978",
    roa_lifetime: "4.02",
    total_stake: "5774768489777",
    stake_x_deleg: "5008472237.4475",
    saturated: "0.084003872876696",
    luck_lifetime: "98",
    blocks_epoch: "9",
    blocks_lifetime: "1159",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "YOYOGI POOL",
    icon: "0",
    pool_id: "f84aed8cedb63195f68ec94a59b3e9b979b7eb0dd41085ae945473d7",
    db_ticker: "YYG",
    db_name: "YOYOGI POOL",
    db_url: "https://yoyogi-pool.site",
    roa: "4.431",
    roa_lifetime: "4.326",
    total_stake: "6629802152936",
    stake_x_deleg: "228613867342.62",
    saturated: "0.09644179818443",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "598",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1700000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA4Good",
    icon: "https://cdn.adapools.org/pool_logo/cb87bf12be816ecd5cd6e167442670e4024d85e0005c8623651b371f.png",
    pool_id: "cb87bf12be816ecd5cd6e167442670e4024d85e0005c8623651b371f",
    db_ticker: "A4G",
    db_name: "ADA4Good",
    db_url: "https://www.ada4good.com",
    roa: "4.356",
    roa_lifetime: "4.219",
    total_stake: "62900246322480",
    stake_x_deleg: "24832312010.454",
    saturated: "0.91499153694913",
    luck_lifetime: "102",
    blocks_epoch: "57",
    blocks_lifetime: "1585",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "90000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZZZ3",
    icon: "0",
    pool_id: "86c8a19e4d6227da12d7c9c63c5fd7a83bf227a92089778f7d9ec0f9",
    db_ticker: "ZZZ3",
    db_name: "ZZZ3",
    db_url: "https://coinzzz.jp",
    roa: "3.836",
    roa_lifetime: "4.734",
    total_stake: "39520541280741",
    stake_x_deleg: "187301143510.62",
    saturated: "0.57489378693583",
    luck_lifetime: "101",
    blocks_epoch: "33",
    blocks_lifetime: "6399",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZZZ",
    icon: "0",
    pool_id: "6184f6e7229530a2d1f9f746112406100e2696dd7439ff8c52750700",
    db_ticker: "ZZZ",
    db_name: "ZZZ",
    db_url: "https://coinzzz.jp",
    roa: "3.744",
    roa_lifetime: "4.593",
    total_stake: "35085542560034",
    stake_x_deleg: "163188570046.67",
    saturated: "0.51037915411511",
    luck_lifetime: "95",
    blocks_epoch: "18",
    blocks_lifetime: "6853",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MetaDEX",
    icon: "0",
    pool_id: "a076d1ea6e8a35bdafc3ca3290abe334672edde80ba1306fc5ae2ae8",
    db_ticker: "METAX",
    db_name: "MetaDEX",
    db_url: "https://www.metadex.fi/",
    roa: "4.535",
    roa_lifetime: "3.473",
    total_stake: "6899184002586",
    stake_x_deleg: "4719004105.736",
    saturated: "0.10036041737987",
    luck_lifetime: "81",
    blocks_epoch: "2",
    blocks_lifetime: "802",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Blue Orange",
    icon: "0",
    pool_id: "16712d380315b12dfdac18ca550571c6ab040ef6be4c682e2a837f09",
    db_ticker: "TBO",
    db_name: "The Blue Orange",
    db_url: "https://theblueorange.io",
    roa: "2.734",
    roa_lifetime: "4.094",
    total_stake: "15755052377376",
    stake_x_deleg: "271638834092.69",
    saturated: "0.22918415161019",
    luck_lifetime: "91",
    blocks_epoch: "15",
    blocks_lifetime: "750",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADATree.io",
    icon: "0",
    pool_id: "b2d1c479ce496d5be21cb68e95dae7342937c631488c47161d408f39",
    db_ticker: "TREE",
    db_name: "ADATree.io",
    db_url: "https://adatree.io",
    roa: "4.068",
    roa_lifetime: "4.141",
    total_stake: "5522831148605",
    stake_x_deleg: "30345226091.236",
    saturated: "0.080339013857989",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "399",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "122000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POP2",
    icon: "0",
    pool_id: "cad3d1c69faad3a6777143009a367d07bf960a2a621d50bdf215bc0f",
    db_ticker: "POP2",
    db_name: "POP2",
    db_url: "https://popool.net",
    roa: "3.82",
    roa_lifetime: "4.621",
    total_stake: "39149048461885",
    stake_x_deleg: "213929226567.68",
    saturated: "0.56948978925436",
    luck_lifetime: "99",
    blocks_epoch: "32",
    blocks_lifetime: "5247",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "[SHARP] CardanoSHARP Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/557fb2fa1a4d61d5ffc613f9f1211a5b22f5c2c3244589adc3168f3d.png",
    pool_id: "557fb2fa1a4d61d5ffc613f9f1211a5b22f5c2c3244589adc3168f3d",
    db_ticker: "SHARP",
    db_name: "[SHARP] CardanoSHARP Stake Pool",
    db_url: "https://www.sharpstakepool.com/",
    roa: "3.638",
    roa_lifetime: "4.096",
    total_stake: "15815070696249",
    stake_x_deleg: "451859162749.97",
    saturated: "0.23005722058911",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "660",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Diamond Pool",
    icon: "0",
    pool_id: "065761de502a7ff8cd77e1ada105f08766988e02451d739bc5da35e4",
    db_ticker: "CARAT",
    db_name: "Diamond Pool",
    db_url: "https://diamondpool.io",
    roa: "2.937",
    roa_lifetime: "4.159",
    total_stake: "9259976824432",
    stake_x_deleg: "264570766412.34",
    saturated: "0.13470218197973",
    luck_lifetime: "100",
    blocks_epoch: "7",
    blocks_lifetime: "753",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONYX Stake Pool #2",
    icon: "0",
    pool_id: "4690a6bcbfc9eedd4d8595c9d665cd9b8ebe3e6d82ebf0120d7bc7c5",
    db_ticker: "ONYX",
    db_name: "ONYX Stake Pool #2",
    db_url: "https://onyxstakepool.com",
    roa: "3.856",
    roa_lifetime: "4.301",
    total_stake: "61511758825274",
    stake_x_deleg: "154941457998.17",
    saturated: "0.8947936143116",
    luck_lifetime: "98",
    blocks_epoch: "38",
    blocks_lifetime: "4608",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ada Booster",
    icon: "https://cdn.adapools.org/pool_logo/6b5179aee4db62de5bec35029e4c9b02145366acfec872f1594924db.png",
    pool_id: "6b5179aee4db62de5bec35029e4c9b02145366acfec872f1594924db",
    db_ticker: "BOOST",
    db_name: "Ada Booster",
    db_url: "https://dbooster.io",
    roa: "2.673",
    roa_lifetime: "4.104",
    total_stake: "15322547287582",
    stake_x_deleg: "464319614775.21",
    saturated: "0.22289262621901",
    luck_lifetime: "99",
    blocks_epoch: "14",
    blocks_lifetime: "297",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "61000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAZZ",
    icon: "0",
    pool_id: "bbe8aaed7d10e53102f4cd78fd3e504d8ff0c0009243da208893b970",
    db_ticker: "JAZZ",
    db_name: "JAZZ",
    db_url: "https://jazz.neeup.com",
    roa: "3.801",
    roa_lifetime: "4.666",
    total_stake: "19474097885057",
    stake_x_deleg: "172337149425.28",
    saturated: "0.28328402186524",
    luck_lifetime: "98",
    blocks_epoch: "21",
    blocks_lifetime: "4281",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VICE  CardanoWall.com",
    icon: "https://cdn.adapools.org/pool_logo/0880d4856cfd12765d47e8a3e9750a7593b008a63b759e27e723c450.png",
    pool_id: "0880d4856cfd12765d47e8a3e9750a7593b008a63b759e27e723c450",
    db_ticker: "VICE",
    db_name: "VICE  CardanoWall.com",
    db_url: "https://vicepool.com",
    roa: "3.056",
    roa_lifetime: "4.156",
    total_stake: "15843986146233",
    stake_x_deleg: "480120792310.09",
    saturated: "0.23047784520617",
    luck_lifetime: "99",
    blocks_epoch: "12",
    blocks_lifetime: "707",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAZZ2",
    icon: "0",
    pool_id: "43a7cf27c04747c306e744e3c51c01bb01c64e5a8e7e46c46f9cdbdc",
    db_ticker: "JAZZ2",
    db_name: "JAZZ2",
    db_url: "https://jazz2.neeup.com",
    roa: "3.794",
    roa_lifetime: "4.696",
    total_stake: "24355397572593",
    stake_x_deleg: "196414496553.17",
    saturated: "0.35429086467646",
    luck_lifetime: "99",
    blocks_epoch: "13",
    blocks_lifetime: "4687",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZZZ4",
    icon: "0",
    pool_id: "80790f58f82501881d46bbb4f6b9d6536ac1a60fdd18350a0b7d50b0",
    db_ticker: "ZZZ4",
    db_name: "ZZZ4",
    db_url: "https://coinzzz.jp",
    roa: "4.478",
    roa_lifetime: "4.676",
    total_stake: "27109256168110",
    stake_x_deleg: "208532739754.69",
    saturated: "0.39435044243923",
    luck_lifetime: "99",
    blocks_epoch: "22",
    blocks_lifetime: "5647",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool Under",
    icon: "0",
    pool_id: "abcdef77d812a5a397fb324f1d00325e2243c476ea8ecd2030719ae5",
    db_ticker: "UNDR",
    db_name: "Pool Under",
    db_url: "https://www.poolunder.com",
    roa: "4.294",
    roa_lifetime: "4.569",
    total_stake: "31701064641150",
    stake_x_deleg: "511307494212.1",
    saturated: "0.46114614098996",
    luck_lifetime: "99",
    blocks_epoch: "22",
    blocks_lifetime: "2924",
    tax_ratio: "0.0299",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAPAN",
    icon: "0",
    pool_id: "2f09336a66c36d0df40dc42f2454fe4f4311c23d312e3683be434081",
    db_ticker: "JAPAN",
    db_name: "JAPAN",
    db_url: "https://tokyostaker.com",
    roa: "3.719",
    roa_lifetime: "4.793",
    total_stake: "18870718405452",
    stake_x_deleg: "192558351076.04",
    saturated: "0.27450683656493",
    luck_lifetime: "100",
    blocks_epoch: "14",
    blocks_lifetime: "4219",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAZZ4",
    icon: "0",
    pool_id: "4ef0c287cc67a951bb0f0eb8d56d1bf69939bd007a2681dbc3055ef6",
    db_ticker: "JAZZ4",
    db_name: "JAZZ4",
    db_url: "https://jazz4.neeup.com",
    roa: "3.759",
    roa_lifetime: "4.567",
    total_stake: "16002774437804",
    stake_x_deleg: "172072843417.25",
    saturated: "0.23278769216939",
    luck_lifetime: "100",
    blocks_epoch: "15",
    blocks_lifetime: "3342",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UKADA",
    icon: "0",
    pool_id: "f322b5ad43b8decce9e8279e42840b5c5fca0397c5ca76b9504331ee",
    db_ticker: "UKADA",
    db_name: "UKADA",
    db_url: "https://ukada.godaddysites.com/",
    roa: "3.447",
    roa_lifetime: "3.821",
    total_stake: "4935510781732",
    stake_x_deleg: "189827337758.92",
    saturated: "0.071795435786585",
    luck_lifetime: "94",
    blocks_epoch: "4",
    blocks_lifetime: "393",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAPAN2",
    icon: "0",
    pool_id: "b3cc3005f1dccbc88230eb66d6e99c2f1bfa32273044473e17d9e10b",
    db_ticker: "JP2",
    db_name: "JAPAN2",
    db_url: "https://tokyostaker.com",
    roa: "3.797",
    roa_lifetime: "4.711",
    total_stake: "45765039168014",
    stake_x_deleg: "315620959779.41",
    saturated: "0.66573067635051",
    luck_lifetime: "102",
    blocks_epoch: "34",
    blocks_lifetime: "5722",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardanofun",
    icon: "0",
    pool_id: "75edaff5db48aa20512e3891747c2a8d68a44f65122142cc1d4dc393",
    db_ticker: "FUN",
    db_name: "cardanofun",
    db_url: "https://staking.cardanofun.com/",
    roa: "4.034",
    roa_lifetime: "4.558",
    total_stake: "38700051020424",
    stake_x_deleg: "319835132400.2",
    saturated: "0.56295835443386",
    luck_lifetime: "98",
    blocks_epoch: "29",
    blocks_lifetime: "5519",
    tax_ratio: "0.048935",
    tax_fix: "340000000",
    pledge: "158900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAPAN5",
    icon: "0",
    pool_id: "ff46164e9121389ea4e9b9cb318c281f01801f6830d6d306843fb008",
    db_ticker: "JP5",
    db_name: "JAPAN5",
    db_url: "https://tokyostaker.com",
    roa: "3.863",
    roa_lifetime: "4.683",
    total_stake: "13101549155667",
    stake_x_deleg: "189877523995.17",
    saturated: "0.19058441419925",
    luck_lifetime: "100",
    blocks_epoch: "14",
    blocks_lifetime: "3251",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bliss Staking",
    icon: "0",
    pool_id: "62c337b473a2e2548ed3cb76002a56bc1305bad95c26c6efd89085af",
    db_ticker: "BLISS",
    db_name: "Bliss Staking",
    db_url: "https://www.bliss-pool.com",
    roa: "3.388",
    roa_lifetime: "4.477",
    total_stake: "7547691747715",
    stake_x_deleg: "29951157729.028",
    saturated: "0.10979407039606",
    luck_lifetime: "129",
    blocks_epoch: "6",
    blocks_lifetime: "117",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SMAN8",
    icon: "https://cdn.adapools.org/pool_logo/ec736597797c68044b8fccd4e895929c0a842f2e9e0a9e221b0a3026.png",
    pool_id: "ec736597797c68044b8fccd4e895929c0a842f2e9e0a9e221b0a3026",
    db_ticker: "SA8",
    db_name: "SMAN8",
    db_url: "https://twitter.com/SMAN8_TickerSA8",
    roa: "4.163",
    roa_lifetime: "4.474",
    total_stake: "16371385862387",
    stake_x_deleg: "564530546978.86",
    saturated: "0.2381497750488",
    luck_lifetime: "100",
    blocks_epoch: "16",
    blocks_lifetime: "1430",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "168000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #7 (v2)",
    icon: "0",
    pool_id: "38a7e77635a708b921b02dc819c65c44c405a775c4c9eece16b63687",
    db_ticker: "1PCT7",
    db_name: "1 Percent Pool #7 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.509",
    roa_lifetime: "4.125",
    total_stake: "5257724540635",
    stake_x_deleg: "28116174014.091",
    saturated: "0.076482585356291",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "1998",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAPAN3",
    icon: "0",
    pool_id: "30c6319d1f680470c8d2d48f8d44fd2848fa9b8cd6ac944d4dfc0c54",
    db_ticker: "JP3",
    db_name: "JAPAN3",
    db_url: "https://tokyostaker.com",
    roa: "3.572",
    roa_lifetime: "4.658",
    total_stake: "28647116490577",
    stake_x_deleg: "292317515209.97",
    saturated: "0.41672124799781",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "4260",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POP",
    icon: "0",
    pool_id: "2e65f9aa580a28d0df07dd2773c09da9604c40127b158919da9db85d",
    db_ticker: "POP",
    db_name: "POP",
    db_url: "https://popool.net",
    roa: "4.015",
    roa_lifetime: "4.64",
    total_stake: "21107068956603",
    stake_x_deleg: "270603448161.58",
    saturated: "0.30703837574943",
    luck_lifetime: "99",
    blocks_epoch: "18",
    blocks_lifetime: "4663",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAPAN4",
    icon: "0",
    pool_id: "ffcd73fdf73a0a8b8a3318afe9b599654d58b276f2a70cec5bf168c3",
    db_ticker: "JP4",
    db_name: "JAPAN4",
    db_url: "https://tokyostaker.com",
    roa: "4.206",
    roa_lifetime: "4.663",
    total_stake: "21020255453016",
    stake_x_deleg: "276582308592.32",
    saturated: "0.30577552503391",
    luck_lifetime: "99",
    blocks_epoch: "14",
    blocks_lifetime: "4348",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZZZ2",
    icon: "0",
    pool_id: "aef742cd720083790f58b8dced44b868e2496335061c2c5256b44e45",
    db_ticker: "ZZZ2",
    db_name: "ZZZ2",
    db_url: "https://coinzzz.jp",
    roa: "3.708",
    roa_lifetime: "4.758",
    total_stake: "35196813777415",
    stake_x_deleg: "345066801739.36",
    saturated: "0.51199778405954",
    luck_lifetime: "100",
    blocks_epoch: "30",
    blocks_lifetime: "6296",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POP4",
    icon: "0",
    pool_id: "57e0985d50391676b06b2ecf640d03d59735cd0c9a99d6e9e6f805eb",
    db_ticker: "POP4",
    db_name: "POP4",
    db_url: "https://popool.net",
    roa: "4.18",
    roa_lifetime: "4.512",
    total_stake: "17050367358354",
    stake_x_deleg: "258338899369",
    saturated: "0.24802672083006",
    luck_lifetime: "97",
    blocks_epoch: "8",
    blocks_lifetime: "3633",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JAZZ3",
    icon: "0",
    pool_id: "bc00a90f9f448be5cfaa4e76dbc13353b83b59cb3f2c5e321555ca28",
    db_ticker: "JAZZ3",
    db_name: "JAZZ3",
    db_url: "https://jazz3.neeup.com",
    roa: "3.606",
    roa_lifetime: "4.454",
    total_stake: "35959425657222",
    stake_x_deleg: "356033917398.24",
    saturated: "0.5230912766418",
    luck_lifetime: "96",
    blocks_epoch: "28",
    blocks_lifetime: "4567",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA's Digital GrandParent",
    icon: "https://cdn.adapools.org/pool_logo/4044aa415dec3609dcab41faa19b643719b9521ef16eb35945a64bd9.png",
    pool_id: "4044aa415dec3609dcab41faa19b643719b9521ef16eb35945a64bd9",
    db_ticker: "MUGGY",
    db_name: "ADA's Digital GrandParent",
    db_url: "https://twitter.com/Muggy_Pool",
    roa: "4.301",
    roa_lifetime: "4.691",
    total_stake: "5077004742446",
    stake_x_deleg: "38172967988.316",
    saturated: "0.073853707163122",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "931",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ozzy pool",
    icon: "https://cdn.adapools.org/pool_logo/0e3f803915a37af7b6a121409192363e9de658524276c20c26f511ee.png",
    pool_id: "0e3f803915a37af7b6a121409192363e9de658524276c20c26f511ee",
    db_ticker: "OZZY",
    db_name: "Ozzy pool",
    db_url: "http://ozzy-pool.com/",
    roa: "3.891",
    roa_lifetime: "3.72",
    total_stake: "51526550254246",
    stake_x_deleg: "38654576334.768",
    saturated: "0.74954169764466",
    luck_lifetime: "92",
    blocks_epoch: "43",
    blocks_lifetime: "1111",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NASEC pool",
    icon: "0",
    pool_id: "aa8b94010cd3c7199be1d619079c184a59e6f1ad930926bc2bd38579",
    db_ticker: "NASEC",
    db_name: "NASEC pool",
    db_url: "https://nasec.co.uk/cardano",
    roa: "4.776",
    roa_lifetime: "4.139",
    total_stake: "5164420029013",
    stake_x_deleg: "22851416057.58",
    saturated: "0.075125311840133",
    luck_lifetime: "102",
    blocks_epoch: "9",
    blocks_lifetime: "494",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AzureADA",
    icon: "https://cdn.adapools.org/pool_logo/79c4d4fe108f480fee98f307adac12571df2959651f833b595141cd1.png",
    pool_id: "79c4d4fe108f480fee98f307adac12571df2959651f833b595141cd1",
    db_ticker: "AZUR",
    db_name: "AzureADA",
    db_url: "https://azureada.com",
    roa: "3.641",
    roa_lifetime: "4.5",
    total_stake: "64412179354570",
    stake_x_deleg: "9531248794.6981",
    saturated: "0.93698518577689",
    luck_lifetime: "97",
    blocks_epoch: "42",
    blocks_lifetime: "5569",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KEI",
    icon: "0",
    pool_id: "e8167261090f590718d0f6fd338c55eac1fecc38da7d14028f1ec67e",
    db_ticker: "KEI",
    db_name: "KEI",
    db_url: "https://keipool.com",
    roa: "5.168",
    roa_lifetime: "4.673",
    total_stake: "6036374837436",
    stake_x_deleg: "262451079888.52",
    saturated: "0.087809384112581",
    luck_lifetime: "102",
    blocks_epoch: "5",
    blocks_lifetime: "3130",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTN4",
    icon: "0",
    pool_id: "32ae538b59a19834504e8b22dff0f703572d7ee1d0e2ea233e1b300c",
    db_ticker: "KTN4",
    db_name: "KTN4",
    db_url: "https://katanapool.com",
    roa: "3.489",
    roa_lifetime: "4.553",
    total_stake: "24617057898750",
    stake_x_deleg: "362015557334.56",
    saturated: "0.35809716112182",
    luck_lifetime: "96",
    blocks_epoch: "18",
    blocks_lifetime: "3838",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTN",
    icon: "0",
    pool_id: "9ffd5ceefaf338459f086690ebca63c2cc81841f1b4429cabf8fef49",
    db_ticker: "KTN",
    db_name: "KTN",
    db_url: "https://katanapool.com",
    roa: "4.273",
    roa_lifetime: "4.756",
    total_stake: "7631398307319",
    stake_x_deleg: "305255932292.76",
    saturated: "0.11101172530368",
    luck_lifetime: "99",
    blocks_epoch: "8",
    blocks_lifetime: "4079",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hermes Stakepool Germany #1",
    icon: "https://cdn.adapools.org/pool_logo/d248ded3c18e0e80d07a46f00a2d808075b989ccb1a0e40a76e5cee1.png",
    pool_id: "d248ded3c18e0e80d07a46f00a2d808075b989ccb1a0e40a76e5cee1",
    db_ticker: "HRMS",
    db_name: "Hermes Stakepool Germany #1",
    db_url: "https://www.hermes-stakepool.com",
    roa: "4.033",
    roa_lifetime: "4.728",
    total_stake: "64817196582011",
    stake_x_deleg: "12158543722.005",
    saturated: "0.94287685325188",
    luck_lifetime: "98",
    blocks_epoch: "57",
    blocks_lifetime: "6304",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTN2",
    icon: "0",
    pool_id: "06b4d54e25e33f3adfa22098749005f7b610721e3c24d547450267ec",
    db_ticker: "KTN2",
    db_name: "KTN2",
    db_url: "https://katanapool.com",
    roa: "3.962",
    roa_lifetime: "4.674",
    total_stake: "24146105287463",
    stake_x_deleg: "395837791597.75",
    saturated: "0.35124635084959",
    luck_lifetime: "98",
    blocks_epoch: "21",
    blocks_lifetime: "4501",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Spectrum Pool",
    icon: "https://cdn.adapools.org/pool_logo/079c374160b0ae34a5a20b8e95a5b5c8766239b2984f13d7ab962791.png",
    pool_id: "079c374160b0ae34a5a20b8e95a5b5c8766239b2984f13d7ab962791",
    db_ticker: "SPEC",
    db_name: "Spectrum Pool",
    db_url: "https://spectrum-pool.kind.software",
    roa: "3.55",
    roa_lifetime: "4.555",
    total_stake: "27602052067312",
    stake_x_deleg: "73021301765.376",
    saturated: "0.40151900064966",
    luck_lifetime: "105",
    blocks_epoch: "26",
    blocks_lifetime: "779",
    tax_ratio: "0.007",
    tax_fix: "340000000",
    pledge: "35001000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Summer Night SKY pool",
    icon: "0",
    pool_id: "075578defd7ee97cbeaa2937e5819099cb3835ac9f9c8b1a2c3a3578",
    db_ticker: "SNSKY",
    db_name: "Summer Night SKY pool",
    db_url: "https://sanskys.github.io/",
    roa: "3.057",
    roa_lifetime: "3.872",
    total_stake: "15195350563145",
    stake_x_deleg: "607814022525.8",
    saturated: "0.22104233256848",
    luck_lifetime: "98",
    blocks_epoch: "6",
    blocks_lifetime: "653",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #5 (v2)",
    icon: "0",
    pool_id: "e9132dcc6ca311a5a5bcc30909c74f1e7404bb8c50ef8a86fa95a311",
    db_ticker: "1PCT5",
    db_name: "1 Percent Pool #5 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.085",
    roa_lifetime: "4.776",
    total_stake: "3097164996676",
    stake_x_deleg: "11061303559.557",
    saturated: "0.045053555847218",
    luck_lifetime: "97",
    blocks_epoch: "3",
    blocks_lifetime: "1504",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - 1",
    icon: "0",
    pool_id: "d9812f8d30b5db4b03e5b76cfd242db9cd2763da4671ed062be808a0",
    db_ticker: "IOG1",
    db_name: "Input Output Global (IOHK) - 1",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "52910709696613",
    stake_x_deleg: "61811576748.38",
    saturated: "0.76967666133083",
    luck_lifetime: "233",
    blocks_epoch: "36",
    blocks_lifetime: "7906",
    tax_ratio: "0.07",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sunshine Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/2d10d9ee5a86c5cedb75cacd033a2945cfd80315eb978858244cec1c.png",
    pool_id: "2d10d9ee5a86c5cedb75cacd033a2945cfd80315eb978858244cec1c",
    db_ticker: "SUNNY",
    db_name: "Sunshine Stake Pool",
    db_url: "https://sunnyada.io",
    roa: "4.231",
    roa_lifetime: "4.776",
    total_stake: "65667538956314",
    stake_x_deleg: "7824084231.659",
    saturated: "0.95524653574895",
    luck_lifetime: "100",
    blocks_epoch: "47",
    blocks_lifetime: "6121",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "520000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bio Pool",
    icon: "https://cdn.adapools.org/pool_logo/603749d1b47c79cdf3072c284abb9810dd98afe827d6ae2b92f115ed.png",
    pool_id: "603749d1b47c79cdf3072c284abb9810dd98afe827d6ae2b92f115ed",
    db_ticker: "BIO",
    db_name: "Bio Pool",
    db_url: "https://Pool.Bio",
    roa: "2.972",
    roa_lifetime: "4.09",
    total_stake: "4842282295882",
    stake_x_deleg: "5049303749.6163",
    saturated: "0.070439268195157",
    luck_lifetime: "101",
    blocks_epoch: "4",
    blocks_lifetime: "1097",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FORTUNE Pool",
    icon: "0",
    pool_id: "7fe4d9c44e85fcc958c9d2d547d551fd004a9fc63c60bf6cfda46461",
    db_ticker: "FORT",
    db_name: "FORTUNE Pool",
    db_url: "http://fortunepool.net",
    roa: "3.301",
    roa_lifetime: "4.391",
    total_stake: "4926285086549",
    stake_x_deleg: "57956295135.871",
    saturated: "0.071661232289644",
    luck_lifetime: "99",
    blocks_epoch: "6",
    blocks_lifetime: "580",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "1200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BEAST Pool",
    icon: "https://cdn.adapools.org/pool_logo/c01546efeba17d531d60447356fc4c19b29c632aeac3377d21f75b4e.png",
    pool_id: "c01546efeba17d531d60447356fc4c19b29c632aeac3377d21f75b4e",
    db_ticker: "BEAST",
    db_name: "BEAST Pool",
    db_url: "https://twitter.com/Beastlyorion",
    roa: "3.371",
    roa_lifetime: "4.345",
    total_stake: "47651303269227",
    stake_x_deleg: "934339279788.76",
    saturated: "0.69316961005853",
    luck_lifetime: "98",
    blocks_epoch: "36",
    blocks_lifetime: "3703",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #8 (v2)",
    icon: "0",
    pool_id: "cd5721fe2ccf9b803bbe787266c3493fff67acc67951b88ebbaf9599",
    db_ticker: "1PCT8",
    db_name: "1 Percent Pool #8 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "4.233",
    roa_lifetime: "4.804",
    total_stake: "5051987745299",
    stake_x_deleg: "17541624115.622",
    saturated: "0.073489792202408",
    luck_lifetime: "98",
    blocks_epoch: "11",
    blocks_lifetime: "3225",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanesia",
    icon: "https://cdn.adapools.org/pool_logo/0a423fa44f23b13cba59474427d11746136672af66b2d80b4499dd1d.png",
    pool_id: "0a423fa44f23b13cba59474427d11746136672af66b2d80b4499dd1d",
    db_ticker: "ADI",
    db_name: "Cardanesia",
    db_url: "https://cardanesia.com/",
    roa: "4.104",
    roa_lifetime: "4.341",
    total_stake: "65768766171862",
    stake_x_deleg: "45799976442.801",
    saturated: "0.95671905852828",
    luck_lifetime: "100",
    blocks_epoch: "40",
    blocks_lifetime: "1642",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "80000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanode: 0.00% Fee &amp; 100% Rewards Guaranteed",
    icon: "https://cdn.adapools.org/pool_logo/971212db48d9390662faa0fa725950f3b9ac1a898227f5fcf1096399.png",
    pool_id: "971212db48d9390662faa0fa725950f3b9ac1a898227f5fcf1096399",
    db_ticker: "FUND",
    db_name: "Cardanode: 0.00% Fee &amp; 100% Rewards Guaranteed",
    db_url: "https://www.cardanode.io",
    roa: "3.877",
    roa_lifetime: "4.031",
    total_stake: "29013475339711",
    stake_x_deleg: "42855945848.908",
    saturated: "0.42205056331918",
    luck_lifetime: "138",
    blocks_epoch: "22",
    blocks_lifetime: "1849",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #2 (v2)",
    icon: "0",
    pool_id: "757bbac0aeaa2cb38deaad2dbd298b9cd85bdc1a968f930aea093a63",
    db_ticker: "1PCT2",
    db_name: "1 Percent Pool #2 (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.187",
    roa_lifetime: "4.68",
    total_stake: "5038020745427",
    stake_x_deleg: "26941287408.701",
    saturated: "0.073286618329066",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "1341",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "(Crypto Capital Venture)",
    icon: "https://cdn.adapools.org/pool_logo/849cdde779a0a0faaa4276dac342f762bd6c84d6cbf637a92a20bb88.png",
    pool_id: "849cdde779a0a0faaa4276dac342f762bd6c84d6cbf637a92a20bb88",
    db_ticker: "CCV",
    db_name: "(Crypto Capital Venture)",
    db_url: "https://www.youtube.com/cryptocapitalventure",
    roa: "4.054",
    roa_lifetime: "4.278",
    total_stake: "37187031423324",
    stake_x_deleg: "6975620225.722",
    saturated: "0.54094889966182",
    luck_lifetime: "98",
    blocks_epoch: "21",
    blocks_lifetime: "3558",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HodlAda",
    icon: "0",
    pool_id: "ed40b0a319f639a70b1e2a4de00f112c4f7b7d4849f0abd25c4336a4",
    db_ticker: "HODLA",
    db_name: "HodlAda",
    db_url: "https://hodlada.today",
    roa: "3.671",
    roa_lifetime: "4.399",
    total_stake: "65090497026726",
    stake_x_deleg: "18839507098.908",
    saturated: "0.94685247510679",
    luck_lifetime: "96",
    blocks_epoch: "61",
    blocks_lifetime: "4366",
    tax_ratio: "0.015",
    tax_fix: "500000000",
    pledge: "6450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "(Crypto Capital Venture) #Pool4",
    icon: "https://cdn.adapools.org/pool_logo/903c38e5905f1587d5ecf8c875a181725fb4b12b3eb06ce1d20bb1c6.png",
    pool_id: "903c38e5905f1587d5ecf8c875a181725fb4b12b3eb06ce1d20bb1c6",
    db_ticker: "CCV3",
    db_name: "(Crypto Capital Venture) #Pool4",
    db_url: "https://www.youtube.com/cryptocapitalventure",
    roa: "3.474",
    roa_lifetime: "3.965",
    total_stake: "37189593505047",
    stake_x_deleg: "8128872897.278",
    saturated: "0.54098616951735",
    luck_lifetime: "95",
    blocks_epoch: "23",
    blocks_lifetime: "2339",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VOGUE-Pool",
    icon: "https://cdn.adapools.org/pool_logo/f1c96d35a89c44f6bebbdb70bbd62fb8435090cfd4899fb2fa336d34.png",
    pool_id: "f1c96d35a89c44f6bebbdb70bbd62fb8435090cfd4899fb2fa336d34",
    db_ticker: "VOGUE",
    db_name: "VOGUE-Pool",
    db_url: "https://vogue-pool.com",
    roa: "4.005",
    roa_lifetime: "4.047",
    total_stake: "7624928668417",
    stake_x_deleg: "762492866841.7",
    saturated: "0.11091761335359",
    luck_lifetime: "96",
    blocks_epoch: "4",
    blocks_lifetime: "610",
    tax_ratio: "0.0398",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "(Crypto Capital Venture) #Pool3",
    icon: "https://cdn.adapools.org/pool_logo/ddeb342bed35ab00242117343ada661db593fb25c55c818f2d3d784f.png",
    pool_id: "ddeb342bed35ab00242117343ada661db593fb25c55c818f2d3d784f",
    db_ticker: "CCV2",
    db_name: "(Crypto Capital Venture) #Pool3",
    db_url: "https://www.youtube.com/cryptocapitalventure",
    roa: "3.983",
    roa_lifetime: "4.163",
    total_stake: "34438494888980",
    stake_x_deleg: "7344528660.4777",
    saturated: "0.50096674037063",
    luck_lifetime: "99",
    blocks_epoch: "19",
    blocks_lifetime: "2495",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Your ADA Moon",
    icon: "https://cdn.adapools.org/pool_logo/f981975f99456e2275bed68576ba12cae91a9dce3ec599274908cdd7.png",
    pool_id: "f981975f99456e2275bed68576ba12cae91a9dce3ec599274908cdd7",
    db_ticker: "YOON",
    db_name: "Your ADA Moon",
    db_url: "https://youradamoonpool.us",
    roa: "3.31",
    roa_lifetime: "3.731",
    total_stake: "8312983129909",
    stake_x_deleg: "159865060190.56",
    saturated: "0.120926540918",
    luck_lifetime: "96",
    blocks_epoch: "8",
    blocks_lifetime: "312",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "(Crypto Capital Venture) #Pool2",
    icon: "https://cdn.adapools.org/pool_logo/e0d0ce6856aa952a99cb376b4f882dd376aa6e095dc36c886faaa19c.png",
    pool_id: "e0d0ce6856aa952a99cb376b4f882dd376aa6e095dc36c886faaa19c",
    db_ticker: "CCV1",
    db_name: "(Crypto Capital Venture) #Pool2",
    db_url: "https://www.youtube.com/cryptocapitalventure",
    roa: "3.861",
    roa_lifetime: "4.033",
    total_stake: "32852910127920",
    stake_x_deleg: "6750135633.4333",
    saturated: "0.47790170132377",
    luck_lifetime: "95",
    blocks_epoch: "28",
    blocks_lifetime: "2486",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Endeavor One",
    icon: "https://cdn.adapools.org/pool_logo/ea595c6f726db925b6832af51795fd8a46e700874c735d204f7c5841.png",
    pool_id: "ea595c6f726db925b6832af51795fd8a46e700874c735d204f7c5841",
    db_ticker: "ENDVR",
    db_name: "Endeavor One",
    db_url: "https://endeavorstakepools.com",
    roa: "3.923",
    roa_lifetime: "4.417",
    total_stake: "65776021246325",
    stake_x_deleg: "108720696274.92",
    saturated: "0.95682459598038",
    luck_lifetime: "99",
    blocks_epoch: "54",
    blocks_lifetime: "4609",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TWO SecureStaking.io",
    icon: "https://cdn.adapools.org/pool_logo/fdc55442d4c89bfa42bb62ac640ed710afd6eb79531e6dbb8459eb46.png",
    pool_id: "fdc55442d4c89bfa42bb62ac640ed710afd6eb79531e6dbb8459eb46",
    db_ticker: "SECUR",
    db_name: "TWO SecureStaking.io",
    db_url: "https://cardano.securestaking.io",
    roa: "4.432",
    roa_lifetime: "4.848",
    total_stake: "66520877176625",
    stake_x_deleg: "24277692400.228",
    saturated: "0.96765979794409",
    luck_lifetime: "104",
    blocks_epoch: "43",
    blocks_lifetime: "4351",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KentuckyBourbon",
    icon: "0",
    pool_id: "c91e83649984043842f8d0a5f17763f09e953545cc78b78428acaa83",
    db_ticker: "KYBBN",
    db_name: "KentuckyBourbon",
    db_url: "https://www.bourbonx.io",
    roa: "3.259",
    roa_lifetime: "4.084",
    total_stake: "4909028718424",
    stake_x_deleg: "31069802015.342",
    saturated: "0.071410208935746",
    luck_lifetime: "95",
    blocks_epoch: "6",
    blocks_lifetime: "452",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1140000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ATADA Stakepool in Austria",
    icon: "https://cdn.adapools.org/pool_logo/00000036d515e12e18cd3c88c74f09a67984c2c279a5296aa96efe89.png",
    pool_id: "00000036d515e12e18cd3c88c74f09a67984c2c279a5296aa96efe89",
    db_ticker: "ATADA",
    db_name: "ATADA Stakepool in Austria",
    db_url: "https://stakepool.at",
    roa: "3.852",
    roa_lifetime: "4.746",
    total_stake: "66618615509650",
    stake_x_deleg: "24950792325.712",
    saturated: "0.96908156896697",
    luck_lifetime: "97",
    blocks_epoch: "58",
    blocks_lifetime: "7706",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Vietnam",
    icon: "0",
    pool_id: "89af15a1c2b8e379aa8ca6d4d9b0134e373122f84f6f45fac2e26c47",
    db_ticker: "ADAVN",
    db_name: "ADA Vietnam",
    db_url: "https://adavn.stakings.club",
    roa: "3.076",
    roa_lifetime: "4.388",
    total_stake: "4907144063159",
    stake_x_deleg: "19243702208.467",
    saturated: "0.071382793405309",
    luck_lifetime: "93",
    blocks_epoch: "10",
    blocks_lifetime: "1269",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BloxBean Pool",
    icon: "https://cdn.adapools.org/pool_logo/8ebb7e21acee66c3e5af6c765ea6ad31962e7b9160520aecd6f001a3.png",
    pool_id: "8ebb7e21acee66c3e5af6c765ea6ad31962e7b9160520aecd6f001a3",
    db_ticker: "BLOXB",
    db_name: "BloxBean Pool",
    db_url: "https://www.bloxbean.com/",
    roa: "3.174",
    roa_lifetime: "4.065",
    total_stake: "15783482471550",
    stake_x_deleg: "986467654471.88",
    saturated: "0.22959771589784",
    luck_lifetime: "98",
    blocks_epoch: "12",
    blocks_lifetime: "610",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moksh Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6539a70bb6e61540444133eab3a24288d1cd3ddc3598adde93475513.png",
    pool_id: "6539a70bb6e61540444133eab3a24288d1cd3ddc3598adde93475513",
    db_ticker: "MOKSH",
    db_name: "Moksh Stake Pool",
    db_url: "https://mokshpool.org",
    roa: "4.319",
    roa_lifetime: "4.274",
    total_stake: "66245644602855",
    stake_x_deleg: "116016890723.04",
    saturated: "0.96365606997137",
    luck_lifetime: "106",
    blocks_epoch: "48",
    blocks_lifetime: "754",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "[DEAD]POOL",
    icon: "https://cdn.adapools.org/pool_logo/59d12b7a426724961607014aacea1e584f3ebc1196948f42a10893bc.png",
    pool_id: "59d12b7a426724961607014aacea1e584f3ebc1196948f42a10893bc",
    db_ticker: "DEAD",
    db_name: "[DEAD]POOL",
    db_url: "https://www.deadpoolcrypto.com",
    roa: "2.388",
    roa_lifetime: "4.535",
    total_stake: "4719656857819",
    stake_x_deleg: "10677956691.898",
    saturated: "0.068655471714185",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "1124",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "340000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dynamic Strategies",
    icon: "https://cdn.adapools.org/pool_logo/6ae0fb9fc19ad1b82521d6e4b9f6e9bad4d150529673c95c5b5cf4e4.png",
    pool_id: "6ae0fb9fc19ad1b82521d6e4b9f6e9bad4d150529673c95c5b5cf4e4",
    db_ticker: "DSIO",
    db_name: "Dynamic Strategies",
    db_url: "https://dynamicstrategies.io",
    roa: "4.161",
    roa_lifetime: "4.484",
    total_stake: "15294272344092",
    stake_x_deleg: "1092448024578",
    saturated: "0.2224813188631",
    luck_lifetime: "102",
    blocks_epoch: "15",
    blocks_lifetime: "382",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "235000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "David Likes Crypto ADA Stakepool",
    icon: "0",
    pool_id: "8e9c37a1516b8adee6dc20168b475a611db86b79cc0280ee376a3860",
    db_ticker: "DAVID",
    db_name: "David Likes Crypto ADA Stakepool",
    db_url: "http://www.davidlikescrypto.com",
    roa: "3.862",
    roa_lifetime: "4.727",
    total_stake: "7782934492131",
    stake_x_deleg: "6545781742.7511",
    saturated: "0.11321607798512",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "2296",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LegaSystems",
    icon: "https://cdn.adapools.org/pool_logo/22e020d0ea6ecbca727ade58b2e048c4f80ba7ee48cbf257960e418e.png",
    pool_id: "22e020d0ea6ecbca727ade58b2e048c4f80ba7ee48cbf257960e418e",
    db_ticker: "LEGA",
    db_name: "LegaSystems",
    db_url: "https://StakeMyADA.net",
    roa: "2.772",
    roa_lifetime: "3.599",
    total_stake: "4661464080072",
    stake_x_deleg: "63855672329.753",
    saturated: "0.067808958349562",
    luck_lifetime: "92",
    blocks_epoch: "2",
    blocks_lifetime: "340",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Responsible Staking",
    icon: "https://cdn.adapools.org/pool_logo/075a870caa0a0cf20a3c2cf514be05e2064ef1ba71b2381e8f16a09e.png",
    pool_id: "075a870caa0a0cf20a3c2cf514be05e2064ef1ba71b2381e8f16a09e",
    db_ticker: "VITAL",
    db_name: "Responsible Staking",
    db_url: "https://responsible-staking.at",
    roa: "2.657",
    roa_lifetime: "3.785",
    total_stake: "4695004661699",
    stake_x_deleg: "12933897139.667",
    saturated: "0.068296863407608",
    luck_lifetime: "95",
    blocks_epoch: "5",
    blocks_lifetime: "405",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WeQanhet",
    icon: "https://cdn.adapools.org/pool_logo/a24a0b7f7dec890db8c59c47646290522234a522a98f00cf2d589a80.png",
    pool_id: "a24a0b7f7dec890db8c59c47646290522234a522a98f00cf2d589a80",
    db_ticker: "WEQNT",
    db_name: "WeQanhet",
    db_url: "https://weqntstakepool.nl",
    roa: "4.942",
    roa_lifetime: "4.547",
    total_stake: "4623265987706",
    stake_x_deleg: "14138428096.96",
    saturated: "0.067253301841266",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "868",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakesStone",
    icon: "https://cdn.adapools.org/pool_logo/2eac123f4a385bfdbc01cb6139cf0b1f2643470c5bf88efc72af55b3.png",
    pool_id: "2eac123f4a385bfdbc01cb6139cf0b1f2643470c5bf88efc72af55b3",
    db_ticker: "SAS1",
    db_name: "StakesStone",
    db_url: "https://www.stakesandstone.com",
    roa: "2.864",
    roa_lifetime: "4.466",
    total_stake: "4600224573132",
    stake_x_deleg: "255568031840.67",
    saturated: "0.066918125104017",
    luck_lifetime: "103",
    blocks_epoch: "4",
    blocks_lifetime: "597",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakit.io Pool 3 by TOBG",
    icon: "https://cdn.adapools.org/pool_logo/74b26596160232737854ec18cde45feff530cac762bade758bd27372.png",
    pool_id: "74b26596160232737854ec18cde45feff530cac762bade758bd27372",
    db_ticker: "STI3",
    db_name: "stakit.io Pool 3 by TOBG",
    db_url: "https://stakit.io",
    roa: "2.107",
    roa_lifetime: "3.918",
    total_stake: "4527647540400",
    stake_x_deleg: "5589688321.4815",
    saturated: "0.065862368177626",
    luck_lifetime: "96",
    blocks_epoch: "7",
    blocks_lifetime: "775",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "genePool One",
    icon: "https://cdn.adapools.org/pool_logo/2f73a35c508608125faf8404c2bcce942ea7dcd9c1a41c009385d3d7.png",
    pool_id: "2f73a35c508608125faf8404c2bcce942ea7dcd9c1a41c009385d3d7",
    db_ticker: "GENE",
    db_name: "genePool One",
    db_url: "https://genepool.one",
    roa: "5.821",
    roa_lifetime: "4.438",
    total_stake: "4624685242622",
    stake_x_deleg: "55719099308.699",
    saturated: "0.067273947328571",
    luck_lifetime: "99",
    blocks_epoch: "6",
    blocks_lifetime: "630",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Shamrock",
    icon: "https://cdn.adapools.org/pool_logo/be7e2461a584b6532c972edca711fa466d7d0e8a86b6629fc0784ff6.png",
    pool_id: "be7e2461a584b6532c972edca711fa466d7d0e8a86b6629fc0784ff6",
    db_ticker: "SHA☘",
    db_name: "Shamrock",
    db_url: "https://shamrock-pool.com/",
    roa: "3.906",
    roa_lifetime: "4.164",
    total_stake: "25226648102544",
    stake_x_deleg: "63066620256.36",
    saturated: "0.3669646920154",
    luck_lifetime: "97",
    blocks_epoch: "14",
    blocks_lifetime: "1497",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "31500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 1",
    icon: "https://cdn.adapools.org/pool_logo/ee98a72dfb3ba43e9de2e39ef825a69d5d45627d2e918ea3e260f2dd.png",
    pool_id: "ee98a72dfb3ba43e9de2e39ef825a69d5d45627d2e918ea3e260f2dd",
    db_ticker: "MS1",
    db_name: "Moonstake 1",
    db_url: "https://moonstake.io",
    roa: "3.632",
    roa_lifetime: "4.414",
    total_stake: "54598976250863",
    stake_x_deleg: "515084681611.92",
    saturated: "0.79423538247372",
    luck_lifetime: "96",
    blocks_epoch: "33",
    blocks_lifetime: "6848",
    tax_ratio: "0.085",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vampyre Fund",
    icon: "0",
    pool_id: "643472a2db02c7f92f787355a2be6206d44201517594211a887b1e88",
    db_ticker: "VAMP",
    db_name: "Vampyre Fund",
    db_url: "https://www.vampyre.fund",
    roa: "3.815",
    roa_lifetime: "4.108",
    total_stake: "13213005221655",
    stake_x_deleg: "880867014777",
    saturated: "0.19220573308245",
    luck_lifetime: "99",
    blocks_epoch: "8",
    blocks_lifetime: "778",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "38000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA NORTH POOL",
    icon: "https://cdn.adapools.org/pool_logo/0000fc522cea692e3e714b392d90cec75e4b87542c5f9638bf9a363a.png",
    pool_id: "0000fc522cea692e3e714b392d90cec75e4b87542c5f9638bf9a363a",
    db_ticker: "ANP",
    db_name: "ADA NORTH POOL",
    db_url: "https://www.adanorthpool.com",
    roa: "5.282",
    roa_lifetime: "4.243",
    total_stake: "4573903930459",
    stake_x_deleg: "20419213975.263",
    saturated: "0.066535246392074",
    luck_lifetime: "93",
    blocks_epoch: "3",
    blocks_lifetime: "735",
    tax_ratio: "0.001",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 5",
    icon: "https://cdn.adapools.org/pool_logo/936f24e391afc0738c816ae1f1388957b977de3d0e065dc9ba38af8d.png",
    pool_id: "936f24e391afc0738c816ae1f1388957b977de3d0e065dc9ba38af8d",
    db_ticker: "NUFI5",
    db_name: "Nu.Fi &amp; AdaLite 5",
    db_url: "https://nu.fi",
    roa: "4.311",
    roa_lifetime: "4.878",
    total_stake: "38345296756675",
    stake_x_deleg: "12486257491.591",
    saturated: "0.55779784763134",
    luck_lifetime: "100",
    blocks_epoch: "37",
    blocks_lifetime: "3330",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 0",
    icon: "https://cdn.adapools.org/pool_logo/a0fc643d831b144e1dd289f5acf4274aedc331b6a0e4257ef5376520.png",
    pool_id: "a0fc643d831b144e1dd289f5acf4274aedc331b6a0e4257ef5376520",
    db_ticker: "NUFI0",
    db_name: "Nu.Fi &amp; AdaLite 0",
    db_url: "https://nu.fi",
    roa: "4.01",
    roa_lifetime: "4.57",
    total_stake: "57974007599904",
    stake_x_deleg: "69848201927.595",
    saturated: "0.84333097910268",
    luck_lifetime: "98",
    blocks_epoch: "56",
    blocks_lifetime: "4620",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POP3",
    icon: "0",
    pool_id: "ee9ef63e4d50a24ecba269de1bfe71c9b36e6f7e4534d2919f1df4c3",
    db_ticker: "POP3",
    db_name: "POP3",
    db_url: "https://popool.net",
    roa: "5.266",
    roa_lifetime: "4.87",
    total_stake: "4820762573172",
    stake_x_deleg: "70893567252.529",
    saturated: "0.070126227065617",
    luck_lifetime: "100",
    blocks_epoch: "4",
    blocks_lifetime: "3472",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 1",
    icon: "https://cdn.adapools.org/pool_logo/c779f67a0f3a1f2985626e345013015f71cf245dbcc8ac8457f42e56.png",
    pool_id: "c779f67a0f3a1f2985626e345013015f71cf245dbcc8ac8457f42e56",
    db_ticker: "NUFI1",
    db_name: "Nu.Fi &amp; AdaLite 1",
    db_url: "https://nu.fi",
    roa: "3.818",
    roa_lifetime: "4.919",
    total_stake: "35032429593269",
    stake_x_deleg: "10954480798.396",
    saturated: "0.5096065352792",
    luck_lifetime: "99",
    blocks_epoch: "26",
    blocks_lifetime: "3829",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 9",
    icon: "https://cdn.adapools.org/pool_logo/481d305a21bbbaa85177413676ba350b1e2d7b7456b8008e72b96386.png",
    pool_id: "481d305a21bbbaa85177413676ba350b1e2d7b7456b8008e72b96386",
    db_ticker: "NUFI9",
    db_name: "Nu.Fi &amp; AdaLite 9",
    db_url: "https://nu.fi",
    roa: "4.149",
    roa_lifetime: "4.793",
    total_stake: "35128939193380",
    stake_x_deleg: "10025382189.892",
    saturated: "0.51101043228277",
    luck_lifetime: "100",
    blocks_epoch: "24",
    blocks_lifetime: "3129",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 7",
    icon: "https://cdn.adapools.org/pool_logo/d5e0f919debe82e4447cf0f0089188ae212f7efbc065a3d819daa17a.png",
    pool_id: "d5e0f919debe82e4447cf0f0089188ae212f7efbc065a3d819daa17a",
    db_ticker: "NUFI7",
    db_name: "Nu.Fi &amp; AdaLite 7",
    db_url: "https://nu.fi",
    roa: "3.49",
    roa_lifetime: "4.732",
    total_stake: "31245204584630",
    stake_x_deleg: "5697520894.3527",
    saturated: "0.45451487770984",
    luck_lifetime: "98",
    blocks_epoch: "23",
    blocks_lifetime: "3468",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Centimani Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/5977a2ca8bbb156c964b2def041bfd456a2f64615415cb6023e3aee0.png",
    pool_id: "5977a2ca8bbb156c964b2def041bfd456a2f64615415cb6023e3aee0",
    db_ticker: "CEMA1",
    db_name: "Centimani Stake Pool",
    db_url: "https://centimani.io",
    roa: "1.858",
    roa_lifetime: "4.266",
    total_stake: "4437344170555",
    stake_x_deleg: "90558044297.041",
    saturated: "0.064548751395546",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "596",
    tax_ratio: "0.0187",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite",
    icon: "https://cdn.adapools.org/pool_logo/04c60c78417132a195cbb74975346462410f72612952a7c4ade7e438.png",
    pool_id: "04c60c78417132a195cbb74975346462410f72612952a7c4ade7e438",
    db_ticker: "NUFI",
    db_name: "Nu.Fi &amp; AdaLite",
    db_url: "https://nu.fi",
    roa: "4.094",
    roa_lifetime: "4.918",
    total_stake: "23103624734443",
    stake_x_deleg: "8958365542.6301",
    saturated: "0.33608169030824",
    luck_lifetime: "97",
    blocks_epoch: "14",
    blocks_lifetime: "4811",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 2",
    icon: "https://cdn.adapools.org/pool_logo/ce19882fd62e79faa113fcaef93950a4f0a5913b20a0689911b6f62d.png",
    pool_id: "ce19882fd62e79faa113fcaef93950a4f0a5913b20a0689911b6f62d",
    db_ticker: "NUFI2",
    db_name: "Nu.Fi &amp; AdaLite 2",
    db_url: "https://nu.fi",
    roa: "3.405",
    roa_lifetime: "4.924",
    total_stake: "21659049335211",
    stake_x_deleg: "8027816655.0078",
    saturated: "0.31506787331927",
    luck_lifetime: "97",
    blocks_epoch: "19",
    blocks_lifetime: "5578",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ikinari Stake Pool",
    icon: "0",
    pool_id: "bd8286b7f327621459728c0cd1f7a731eaf7b2fd0df1c6b09b2932a5",
    db_ticker: "ISP",
    db_name: "Ikinari Stake Pool",
    db_url: "https://ikinaristakepool.amebaownd.com/",
    roa: "3.471",
    roa_lifetime: "4.442",
    total_stake: "28227243638708",
    stake_x_deleg: "186935388335.81",
    saturated: "0.41061348008725",
    luck_lifetime: "101",
    blocks_epoch: "23",
    blocks_lifetime: "4009",
    tax_ratio: "0.1",
    tax_fix: "350000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 6",
    icon: "https://cdn.adapools.org/pool_logo/cf69a3eca039d537acd46d5864a54dd8953f0c14be957350905834aa.png",
    pool_id: "cf69a3eca039d537acd46d5864a54dd8953f0c14be957350905834aa",
    db_ticker: "NUFI6",
    db_name: "Nu.Fi &amp; AdaLite 6",
    db_url: "https://nu.fi",
    roa: "3.631",
    roa_lifetime: "4.92",
    total_stake: "21332898163364",
    stake_x_deleg: "8007844655.9174",
    saturated: "0.3103234473519",
    luck_lifetime: "102",
    blocks_epoch: "22",
    blocks_lifetime: "3323",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 3",
    icon: "https://cdn.adapools.org/pool_logo/92229dcf782ce8a82050fdeecb9334cc4d906c6eb66cdbdcea86fb5f.png",
    pool_id: "92229dcf782ce8a82050fdeecb9334cc4d906c6eb66cdbdcea86fb5f",
    db_ticker: "NUFI3",
    db_name: "Nu.Fi &amp; AdaLite 3",
    db_url: "https://nu.fi",
    roa: "3.546",
    roa_lifetime: "4.581",
    total_stake: "23970803943019",
    stake_x_deleg: "10050651548.436",
    saturated: "0.34869629332263",
    luck_lifetime: "101",
    blocks_epoch: "19",
    blocks_lifetime: "5223",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite A",
    icon: "https://cdn.adapools.org/pool_logo/e5c947f0a4f6a6dd0946d8971dafaf263c88a1b086527fdf24223c21.png",
    pool_id: "e5c947f0a4f6a6dd0946d8971dafaf263c88a1b086527fdf24223c21",
    db_ticker: "NUFIA",
    db_name: "Nu.Fi &amp; AdaLite A",
    db_url: "https://nu.fi",
    roa: "3.551",
    roa_lifetime: "4.77",
    total_stake: "20074473260919",
    stake_x_deleg: "9688452346.0034",
    saturated: "0.29201750734461",
    luck_lifetime: "101",
    blocks_epoch: "16",
    blocks_lifetime: "3421",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADOPTion Stake Pool",
    icon: "0",
    pool_id: "fd2ce4f1b300eee8dabf70b010f23a9f2650f18c1f8d3ecf823f5423",
    db_ticker: "ADOPT",
    db_name: "ADOPTion Stake Pool",
    db_url: "https://adoptstakepool.com",
    roa: "1.929",
    roa_lifetime: "4.144",
    total_stake: "4565702806408",
    stake_x_deleg: "17360086716.38",
    saturated: "0.066415947032551",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "548",
    tax_ratio: "0.042",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TOASTY POOL",
    icon: "0",
    pool_id: "b43a281762c70838ed2363aa76e28eb2212c644bed89bdb3d209bf9f",
    db_ticker: "TOAST",
    db_name: "TOASTY POOL",
    db_url: "https://toast-pool.us",
    roa: "3.061",
    roa_lifetime: "4.756",
    total_stake: "4449525206775",
    stake_x_deleg: "6171324836.0264",
    saturated: "0.064725945376559",
    luck_lifetime: "97",
    blocks_epoch: "8",
    blocks_lifetime: "2064",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 8",
    icon: "https://cdn.adapools.org/pool_logo/03fbee96b84daa1bf91c44b51d2e1cbdda53fd504c98aeb6fd4a55b6.png",
    pool_id: "03fbee96b84daa1bf91c44b51d2e1cbdda53fd504c98aeb6fd4a55b6",
    db_ticker: "NUFI8",
    db_name: "Nu.Fi &amp; AdaLite 8",
    db_url: "https://nu.fi",
    roa: "3.716",
    roa_lifetime: "4.683",
    total_stake: "55131414289591",
    stake_x_deleg: "165064114639.49",
    saturated: "0.80198060332529",
    luck_lifetime: "96",
    blocks_epoch: "42",
    blocks_lifetime: "5162",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite B",
    icon: "https://cdn.adapools.org/pool_logo/7d7ac07a2f2a25b7a4db868a40720621c4939cf6aefbb9a11464f1a6.png",
    pool_id: "7d7ac07a2f2a25b7a4db868a40720621c4939cf6aefbb9a11464f1a6",
    db_ticker: "NUFIB",
    db_name: "Nu.Fi &amp; AdaLite B",
    db_url: "https://nu.fi",
    roa: "3.38",
    roa_lifetime: "4.492",
    total_stake: "35677976752879",
    stake_x_deleg: "6307987403.2671",
    saturated: "0.51899712152137",
    luck_lifetime: "99",
    blocks_epoch: "24",
    blocks_lifetime: "2497",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "world economic pool",
    icon: "0",
    pool_id: "39580d748f45b855d81852e334cad199cfbdaa281ed3d81e8a90f8ed",
    db_ticker: "WEP",
    db_name: "world economic pool",
    db_url: "https://world-economic-pool.amebaownd.com/",
    roa: "3.52",
    roa_lifetime: "4.078",
    total_stake: "22354547960948",
    stake_x_deleg: "1117727398047.4",
    saturated: "0.32518508896969",
    luck_lifetime: "100",
    blocks_epoch: "21",
    blocks_lifetime: "1161",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kawanua",
    icon: "https://cdn.adapools.org/pool_logo/40c5a505a4276c94e49b848c1b0aa62c4ea244574f4e2e28ebb29210.png",
    pool_id: "40c5a505a4276c94e49b848c1b0aa62c4ea244574f4e2e28ebb29210",
    db_ticker: "KAWAN",
    db_name: "Kawanua",
    db_url: "https://kawancardano.com/",
    roa: "3.174",
    roa_lifetime: "3.924",
    total_stake: "10829197329113",
    stake_x_deleg: "9694894654.5327",
    saturated: "0.15752917496206",
    luck_lifetime: "101",
    blocks_epoch: "8",
    blocks_lifetime: "252",
    tax_ratio: "0.0095",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "animation.com Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/b57475ec29303269b889893d98c088f90c2df00846e5b8e31541230b.png",
    pool_id: "b57475ec29303269b889893d98c088f90c2df00846e5b8e31541230b",
    db_ticker: "ANIM",
    db_name: "animation.com Cardano Pool",
    db_url: "https://animation.com/cardanostakepool",
    roa: "4.242",
    roa_lifetime: "3.828",
    total_stake: "4288841625094",
    stake_x_deleg: "61269166072.771",
    saturated: "0.062388528180909",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "233",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jack's Cardano Friends Pool",
    icon: "https://cdn.adapools.org/pool_logo/bd50ebea27a9e846bcd873c361ecb1a4d9baf738040d232882109fcd.png",
    pool_id: "bd50ebea27a9e846bcd873c361ecb1a4d9baf738040d232882109fcd",
    db_ticker: "JACK",
    db_name: "Jack's Cardano Friends Pool",
    db_url: "https://www.youtube.com/channel/UCO_-AuhWHBUYq_eF63FS7bg",
    roa: "3.889",
    roa_lifetime: "3.853",
    total_stake: "7530334830006",
    stake_x_deleg: "6486076511.6331",
    saturated: "0.10954158437669",
    luck_lifetime: "96",
    blocks_epoch: "3",
    blocks_lifetime: "461",
    tax_ratio: "0.0169",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "4GOOD Stake Pool",
    icon: "0",
    pool_id: "2dd6fd6af3442499b23b4c307e152817eaea0303ddcffd0821706fa8",
    db_ticker: "4GOOD",
    db_name: "4GOOD Stake Pool",
    db_url: "https://4goodstakepool.com",
    roa: "1.987",
    roa_lifetime: "4.354",
    total_stake: "4398217020818",
    stake_x_deleg: "40724231674.241",
    saturated: "0.063979580160656",
    luck_lifetime: "100",
    blocks_epoch: "2",
    blocks_lifetime: "557",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo #2",
    icon: "0",
    pool_id: "04357793d81097a7d2c15ec6cd6067a58cdd2fb21aaf07e56c306ecf",
    db_ticker: "EMUR2",
    db_name: "Emurgo #2",
    db_url: "https://emurgo.io/",
    roa: "3.782",
    roa_lifetime: "4.623",
    total_stake: "49366456763572",
    stake_x_deleg: "22439298528.896",
    saturated: "0.71811944767679",
    luck_lifetime: "98",
    blocks_epoch: "37",
    blocks_lifetime: "6656",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Yoroi",
    icon: "0",
    pool_id: "df1750df9b2df285fcfb50f4740657a18ee3af42727d410c37b86207",
    db_ticker: "YOROI",
    db_name: "Yoroi",
    db_url: "https://yoroi-wallet.com/",
    roa: "3.15",
    roa_lifetime: "4.661",
    total_stake: "41905316938074",
    stake_x_deleg: "5900495203.8966",
    saturated: "0.60958442284836",
    luck_lifetime: "98",
    blocks_epoch: "24",
    blocks_lifetime: "3998",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Meshnet",
    icon: "0",
    pool_id: "23fe0d0e58b18df247484c5cd81852120bed80b82bf93ec58901c3cf",
    db_ticker: "MESH",
    db_name: "Meshnet",
    db_url:
      "https://www.facebook.com/groups/adastakepool/?utm_source=adapools.org",
    roa: "2.515",
    roa_lifetime: "4.676",
    total_stake: "4409369572796",
    stake_x_deleg: "11393719826.346",
    saturated: "0.064141813081381",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "1523",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "201993538576",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Apolo Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c6074d596a4d1629304bfd92715ec1677aeb378e4932723616d575f1.png",
    pool_id: "c6074d596a4d1629304bfd92715ec1677aeb378e4932723616d575f1",
    db_ticker: "APOLO",
    db_name: "Apolo Stake Pool",
    db_url: "https://apolostakepool.com",
    roa: "3.112",
    roa_lifetime: "4.541",
    total_stake: "3613024986154",
    stake_x_deleg: "8727113493.1256",
    saturated: "0.05255762065172",
    luck_lifetime: "106",
    blocks_epoch: "6",
    blocks_lifetime: "748",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "275000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LambdaHoneypot",
    icon: "https://cdn.adapools.org/pool_logo/5be57ce6d1225697f4ad4090355f0a72d6e1e2446d1d768f36aa118c.png",
    pool_id: "5be57ce6d1225697f4ad4090355f0a72d6e1e2446d1d768f36aa118c",
    db_ticker: "HONEY",
    db_name: "LambdaHoneypot",
    db_url: "https://www.lambda-honeypot.com",
    roa: "4.351",
    roa_lifetime: "3.811",
    total_stake: "15332813474878",
    stake_x_deleg: "393149063458.41",
    saturated: "0.22304196545124",
    luck_lifetime: "101",
    blocks_epoch: "16",
    blocks_lifetime: "234",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "32000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FIKA Pool",
    icon: "https://cdn.adapools.org/pool_logo/f423b19715cca49029ed13ff02a110b63de7d96ad7a0536dc5887a41.png",
    pool_id: "f423b19715cca49029ed13ff02a110b63de7d96ad7a0536dc5887a41",
    db_ticker: "FIKA",
    db_name: "FIKA Pool",
    db_url: "https://fikapool.com",
    roa: "2.879",
    roa_lifetime: "3.442",
    total_stake: "4213577792681",
    stake_x_deleg: "9061457618.6688",
    saturated: "0.061293687163225",
    luck_lifetime: "90",
    blocks_epoch: "2",
    blocks_lifetime: "279",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sprout Pool",
    icon: "https://cdn.adapools.org/pool_logo/a50d1fd3cda9b9452cb149474a601d24332a870a6fe32b60add3a98b.png",
    pool_id: "a50d1fd3cda9b9452cb149474a601d24332a870a6fe32b60add3a98b",
    db_ticker: "SPRO",
    db_name: "Sprout Pool",
    db_url: "https://sproutpool.ca",
    roa: "4.483",
    roa_lifetime: "3.704",
    total_stake: "4124689379212",
    stake_x_deleg: "22295618266.011",
    saturated: "0.060000653338843",
    luck_lifetime: "102",
    blocks_epoch: "2",
    blocks_lifetime: "208",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo #1",
    icon: "0",
    pool_id: "af22f95915a19cd57adb14c558dcc4a175f60c6193dc23b8bd2d8beb",
    db_ticker: "EMUR1",
    db_name: "Emurgo #1",
    db_url: "https://emurgo.io/",
    roa: "2.645",
    roa_lifetime: "4.889",
    total_stake: "11189978365158",
    stake_x_deleg: "7841610627.3006",
    saturated: "0.16277735146332",
    luck_lifetime: "99",
    blocks_epoch: "9",
    blocks_lifetime: "4314",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo #4",
    icon: "0",
    pool_id: "c5293f2ba88ac474787358b9c2f4fae7b3c4408f79cdf89a12c9ece4",
    db_ticker: "EMUR4",
    db_name: "Emurgo #4",
    db_url: "https://emurgo.io/",
    roa: "3.48",
    roa_lifetime: "4.432",
    total_stake: "46699614652276",
    stake_x_deleg: "115593105574.94",
    saturated: "0.67932567332963",
    luck_lifetime: "96",
    blocks_epoch: "37",
    blocks_lifetime: "6327",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cyberspace Station",
    icon: "0",
    pool_id: "fdd12df69fcca7aa647ef993001baffa30c28cae1cd70c95d2d9e350",
    db_ticker: "CSS",
    db_name: "Cyberspace Station",
    db_url: "https://cyberspacestation.tech/pool",
    roa: "4.685",
    roa_lifetime: "4.61",
    total_stake: "4107202086527",
    stake_x_deleg: "2892395835.5824",
    saturated: "0.059746270792725",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "1191",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "439000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MILKY Pool",
    icon: "https://cdn.adapools.org/pool_logo/b42eae6ad659bd5e2187975a36816b712422bfd5b64ea32f52781096.png",
    pool_id: "b42eae6ad659bd5e2187975a36816b712422bfd5b64ea32f52781096",
    db_ticker: "MILKY",
    db_name: "MILKY Pool",
    db_url: "https://milky-pool.com/",
    roa: "5.491",
    roa_lifetime: "5.02",
    total_stake: "5872828682307",
    stake_x_deleg: "23212761590.146",
    saturated: "0.085430325895919",
    luck_lifetime: "102",
    blocks_epoch: "2",
    blocks_lifetime: "1153",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "744000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoCafe Two",
    icon: "https://cdn.adapools.org/pool_logo/e24313f4a277d8c18b04374cd1b3aca3e6df7454f9382bd56fbc4015.png",
    pool_id: "e24313f4a277d8c18b04374cd1b3aca3e6df7454f9382bd56fbc4015",
    db_ticker: "CAFE2",
    db_name: "CardanoCafe Two",
    db_url: "https://cardanocafe.org/",
    roa: "3.135",
    roa_lifetime: "3.962",
    total_stake: "4135115045400",
    stake_x_deleg: "20675575227",
    saturated: "0.060152312463994",
    luck_lifetime: "94",
    blocks_epoch: "6",
    blocks_lifetime: "419",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kronos Stake Pool by Kaizen Crypto",
    icon: "https://cdn.adapools.org/pool_logo/ca4d7a55367b654e7159ae78f3dbe6fd0908c3a1bb236ce231b3a266.png",
    pool_id: "ca4d7a55367b654e7159ae78f3dbe6fd0908c3a1bb236ce231b3a266",
    db_ticker: "KRONO",
    db_name: "Kronos Stake Pool by Kaizen Crypto",
    db_url: "https://www.kaizencrypto.com",
    roa: "4.492",
    roa_lifetime: "4.562",
    total_stake: "4103335857532",
    stake_x_deleg: "8391279872.2536",
    saturated: "0.05969002988721",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "969",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The pool of Vietnamese",
    icon: "0",
    pool_id: "9495762e111912925166df9cfeee225fa1e866ed49c637273096c7e8",
    db_ticker: "FIDA",
    db_name: "The pool of Vietnamese",
    db_url: "t.me/StakingADA",
    roa: "2.505",
    roa_lifetime: "4.776",
    total_stake: "4201341740599",
    stake_x_deleg: "12731338607.876",
    saturated: "0.061115692882515",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "993",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XSEC Cardano ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/ac4c79de638c8bb08997e939dccde43fe347a392d11a7ddf77bf3816.png",
    pool_id: "ac4c79de638c8bb08997e939dccde43fe347a392d11a7ddf77bf3816",
    db_ticker: "XSEC",
    db_name: "XSEC Cardano ADA Pool",
    db_url: "https://ada.xsec.es",
    roa: "1.631",
    roa_lifetime: "3.801",
    total_stake: "4304800249303",
    stake_x_deleg: "130448492403.12",
    saturated: "0.062620673632577",
    luck_lifetime: "132",
    blocks_epoch: "5",
    blocks_lifetime: "59",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "55000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The fortepool.io",
    icon: "https://cdn.adapools.org/pool_logo/401045f4b0b72381c849f96d2f58d0426cb29c853933c796c652850c.png",
    pool_id: "401045f4b0b72381c849f96d2f58d0426cb29c853933c796c652850c",
    db_ticker: "FORTE",
    db_name: "The fortepool.io",
    db_url: "https://fortepool.io",
    roa: "3.592",
    roa_lifetime: "3.976",
    total_stake: "4163165107757",
    stake_x_deleg: "37846955525.064",
    saturated: "0.060560348539655",
    luck_lifetime: "117",
    blocks_epoch: "2",
    blocks_lifetime: "85",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Capital Stake Pool - live long and prosper.",
    icon: "https://cdn.adapools.org/pool_logo/693cb17b244a81dfaa589c2a076ea604e35462a3915a28d6c98685c9.png",
    pool_id: "693cb17b244a81dfaa589c2a076ea604e35462a3915a28d6c98685c9",
    db_ticker: "CSP",
    db_name: "Capital Stake Pool - live long and prosper.",
    db_url: "https://capitalstakepool.info",
    roa: "1.684",
    roa_lifetime: "4.27",
    total_stake: "4014660919905",
    stake_x_deleg: "19583711804.415",
    saturated: "0.058400101433635",
    luck_lifetime: "96",
    blocks_epoch: "6",
    blocks_lifetime: "523",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VASIL",
    icon: "0",
    pool_id: "9c3bcf9d42f117cf4405d394e448ee1f46dd8c52b705decec29bcdc0",
    db_ticker: "VASIL",
    db_name: "VASIL",
    db_url: "https://www.vasilstdabov.org/staking-pool/",
    roa: "0.892",
    roa_lifetime: "5.022",
    total_stake: "4254191663296",
    stake_x_deleg: "163622756280.62",
    saturated: "0.061884485293092",
    luck_lifetime: "156",
    blocks_epoch: "1",
    blocks_lifetime: "4",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "105000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OKExPool",
    icon: "0",
    pool_id: "2c73900f65624d4279bee7bdad0a16c0df083c332048784e2fdffa35",
    db_ticker: "OKEx",
    db_name: "OKExPool",
    db_url: "https://www.okex.win/",
    roa: "3.255",
    roa_lifetime: "3.766",
    total_stake: "8002620280588",
    stake_x_deleg: "1600524056117.6",
    saturated: "0.11641178307339",
    luck_lifetime: "90",
    blocks_epoch: "7",
    blocks_lifetime: "545",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 2",
    icon: "https://cdn.adapools.org/pool_logo/237795878bfa5352cca325012e073b344ce337a1dd752cd3d5ea4cdc.png",
    pool_id: "237795878bfa5352cca325012e073b344ce337a1dd752cd3d5ea4cdc",
    db_ticker: "MS2",
    db_name: "Moonstake 2",
    db_url: "https://moonstake.io",
    roa: "3.214",
    roa_lifetime: "4.347",
    total_stake: "52731699120811",
    stake_x_deleg: "1054633982416.2",
    saturated: "0.76707264669719",
    luck_lifetime: "96",
    blocks_epoch: "36",
    blocks_lifetime: "6106",
    tax_ratio: "0.085",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 8",
    icon: "https://cdn.adapools.org/pool_logo/b573268bf369e3f3a4e6dd16fdd78ca01eb164c1daa29d611ef885d2.png",
    pool_id: "b573268bf369e3f3a4e6dd16fdd78ca01eb164c1daa29d611ef885d2",
    db_ticker: "MS8",
    db_name: "Moonstake 8",
    db_url: "https://moonstake.io",
    roa: "3.862",
    roa_lifetime: "4.389",
    total_stake: "58065626430751",
    stake_x_deleg: "439891109323.87",
    saturated: "0.84466373151227",
    luck_lifetime: "96",
    blocks_epoch: "44",
    blocks_lifetime: "5346",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTN3",
    icon: "0",
    pool_id: "0b38a13726b9dc3aa11be08af93003e775d9d62091765e0e1e3d027b",
    db_ticker: "KTN3",
    db_name: "KTN3",
    db_url: "https://katanapool.com",
    roa: "4.115",
    roa_lifetime: "4.603",
    total_stake: "45252857602171",
    stake_x_deleg: "1616173485791.8",
    saturated: "0.65828012050173",
    luck_lifetime: "99",
    blocks_epoch: "46",
    blocks_lifetime: "6119",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite 4",
    icon: "https://cdn.adapools.org/pool_logo/d785ff6a030ae9d521770c00f264a2aa423e928c85fc620b13d46eda.png",
    pool_id: "d785ff6a030ae9d521770c00f264a2aa423e928c85fc620b13d46eda",
    db_ticker: "NUFI4",
    db_name: "Nu.Fi &amp; AdaLite 4",
    db_url: "https://nu.fi",
    roa: "3.733",
    roa_lifetime: "3.949",
    total_stake: "61730628385588",
    stake_x_deleg: "264938319251.45",
    saturated: "0.89797744596714",
    luck_lifetime: "132",
    blocks_epoch: "60",
    blocks_lifetime: "7323",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 10",
    icon: "https://cdn.adapools.org/pool_logo/3c68076a8b81226f6d7da352dda415db00299701624b6cf71d7cc086.png",
    pool_id: "3c68076a8b81226f6d7da352dda415db00299701624b6cf71d7cc086",
    db_ticker: "MS10",
    db_name: "Moonstake 10",
    db_url: "https://moonstake.io",
    roa: "3.606",
    roa_lifetime: "4.565",
    total_stake: "58713588188311",
    stake_x_deleg: "462311718018.2",
    saturated: "0.85408944220654",
    luck_lifetime: "100",
    blocks_epoch: "43",
    blocks_lifetime: "5474",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC Cardano Stake World",
    icon: "0",
    pool_id: "d8d8fd61ce6ca61d085a21dc0f643d6532475bbea0d5651804a1f22d",
    db_ticker: "THC2",
    db_name: "THC Cardano Stake World",
    db_url: "https://thc-room335.com",
    roa: "2.499",
    roa_lifetime: "3.422",
    total_stake: "6370045979173",
    stake_x_deleg: "235927628858.26",
    saturated: "0.092663200888564",
    luck_lifetime: "159",
    blocks_epoch: "6",
    blocks_lifetime: "129",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FUNGI Stake Pool | Ardana ASPA Member",
    icon: "https://cdn.adapools.org/pool_logo/9dcb2a0398835a6b0a71dd5bbaa0d6bb961276aedd72f2655c7879d0.png",
    pool_id: "9dcb2a0398835a6b0a71dd5bbaa0d6bb961276aedd72f2655c7879d0",
    db_ticker: "FUNGI",
    db_name: "FUNGI Stake Pool | Ardana ASPA Member",
    db_url: "https://fungi-pool.com",
    roa: "3.649",
    roa_lifetime: "2.972",
    total_stake: "3917229064871",
    stake_x_deleg: "12928148728.947",
    saturated: "0.056982788656698",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "164",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "240000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "We are Grabbers",
    icon: "0",
    pool_id: "4ed1c8475cc3e497bbf00a5827266faec51ad9c3cce1b02122b33cba",
    db_ticker: "GRAB",
    db_name: "We are Grabbers",
    db_url: "",
    roa: "4.478",
    roa_lifetime: "4.695",
    total_stake: "3994721003752",
    stake_x_deleg: "37686047205.208",
    saturated: "0.058110041289293",
    luck_lifetime: "109",
    blocks_epoch: "4",
    blocks_lifetime: "521",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RINA User Group",
    icon: "0",
    pool_id: "cc06dfc772a853fc79e150cd458c4553d876d7c5d6f575100e082253",
    db_ticker: "RUG",
    db_name: "RINA User Group",
    db_url: "http://rinauser.group/",
    roa: "3.516",
    roa_lifetime: "3.729",
    total_stake: "3986056696345",
    stake_x_deleg: "11011206343.494",
    saturated: "0.057984004136588",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "516",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "46555000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adapolis",
    icon: "https://cdn.adapools.org/pool_logo/90454dd6894d47a5cc1e9d16ed4536b876e8e08c3c274d15caa98ff9.png",
    pool_id: "90454dd6894d47a5cc1e9d16ed4536b876e8e08c3c274d15caa98ff9",
    db_ticker: "ADP",
    db_name: "Adapolis",
    db_url: "https://www.adapolis.io",
    roa: "4.432",
    roa_lifetime: "4.739",
    total_stake: "3740361248285",
    stake_x_deleg: "10626026273.537",
    saturated: "0.054409944116389",
    luck_lifetime: "102",
    blocks_epoch: "1",
    blocks_lifetime: "770",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moonstake 9",
    icon: "https://cdn.adapools.org/pool_logo/83ea16a7f183288173077c54a1e2b3cbcd0f2336e1bc85e2cc6397e0.png",
    pool_id: "83ea16a7f183288173077c54a1e2b3cbcd0f2336e1bc85e2cc6397e0",
    db_ticker: "MS9",
    db_name: "Moonstake 9",
    db_url: "https://moonstake.io",
    roa: "3.887",
    roa_lifetime: "4.555",
    total_stake: "57088022955707",
    stake_x_deleg: "634311366174.52",
    saturated: "0.83044281890135",
    luck_lifetime: "100",
    blocks_epoch: "52",
    blocks_lifetime: "5456",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool (v2)",
    icon: "0",
    pool_id: "03646a1b39e69473a57bbeda37e35be8adcefb6ff608dd8fc726d371",
    db_ticker: "1PCT",
    db_name: "1 Percent Pool (v2)",
    db_url: "https://www.1percentpool.eu",
    roa: "2.837",
    roa_lifetime: "4.726",
    total_stake: "3689261879466",
    stake_x_deleg: "23498483308.701",
    saturated: "0.053666616502486",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "993",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Don’t underestimate the Force",
    icon: "0",
    pool_id: "b81c65f4e17c8e94d89fcd369d11077af9800ae70177013a1a79b349",
    db_ticker: "LUKE",
    db_name: "Don’t underestimate the Force",
    db_url:
      "https://pooltool.io/pool/b81c65f4e17c8e94d89fcd369d11077af9800ae70177013a1a79b349",
    roa: "2.393",
    roa_lifetime: "4.249",
    total_stake: "3887898071822",
    stake_x_deleg: "40081423420.845",
    saturated: "0.056556119255873",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "483",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1585241000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Infinia Stake Pool Fukuoka",
    icon: "0",
    pool_id: "c58e3004c1f48f620b8bb1e9a4cd88d9cd006f972a94eb67288ab369",
    db_ticker: "ISPF",
    db_name: "Infinia Stake Pool Fukuoka",
    db_url: "http://infiniastakepool.com/",
    roa: "2.405",
    roa_lifetime: "2.153",
    total_stake: "8859165937517",
    stake_x_deleg: "402689360796.23",
    saturated: "0.12887170291349",
    luck_lifetime: "72",
    blocks_epoch: "8",
    blocks_lifetime: "14",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Maluiin",
    icon: "https://cdn.adapools.org/pool_logo/38927599cc3ff6b081501e0ee1dc4f0cb8ba012b3de8b49a785bb05a.png",
    pool_id: "38927599cc3ff6b081501e0ee1dc4f0cb8ba012b3de8b49a785bb05a",
    db_ticker: "Malu",
    db_name: "Maluiin",
    db_url: "https://maluiin.com",
    roa: "4.124",
    roa_lifetime: "4.111",
    total_stake: "47391084503204",
    stake_x_deleg: "21100215718.256",
    saturated: "0.68938428356799",
    luck_lifetime: "102",
    blocks_epoch: "48",
    blocks_lifetime: "1182",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VIVI Pool",
    icon: "0",
    pool_id: "ee89064ccaa9a12ba93acdd1b3789c282b44b7df858e762a0e35b36f",
    db_ticker: "VIVI",
    db_name: "VIVI Pool",
    db_url: "https://vivi-stakepool.com/",
    roa: "3.535",
    roa_lifetime: "3.995",
    total_stake: "12336091160723",
    stake_x_deleg: "257001899181.73",
    saturated: "0.17944952001023",
    luck_lifetime: "93",
    blocks_epoch: "12",
    blocks_lifetime: "1129",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "28000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tygar",
    icon: "https://cdn.adapools.org/pool_logo/f8e64037e3917cd7cb94969a5374e11b9e6c939b4b69118a8eb4509f.png",
    pool_id: "f8e64037e3917cd7cb94969a5374e11b9e6c939b4b69118a8eb4509f",
    db_ticker: "TYGAR",
    db_name: "Tygar",
    db_url: "https://tygar-pool.com",
    roa: "3.744",
    roa_lifetime: "3.954",
    total_stake: "3610500255798",
    stake_x_deleg: "5676887194.6509",
    saturated: "0.052520894135627",
    luck_lifetime: "104",
    blocks_epoch: "3",
    blocks_lifetime: "280",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Coinz",
    icon: "https://cdn.adapools.org/pool_logo/91991d9238f3f270a46fc237acc3abcdf2456ff163c1639951d75d15.png",
    pool_id: "91991d9238f3f270a46fc237acc3abcdf2456ff163c1639951d75d15",
    db_ticker: "COINZ",
    db_name: "ADA Coinz",
    db_url: "https://adacoinz.com",
    roa: "4.401",
    roa_lifetime: "3.644",
    total_stake: "4122262207649",
    stake_x_deleg: "13045133568.509",
    saturated: "0.059965345982056",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "138",
    tax_ratio: "0.0187",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EXPO Pool",
    icon: "https://cdn.adapools.org/pool_logo/b352298733374ae760475bb3cd86b4b52fe3dcf7fd61f678e969030e.png",
    pool_id: "b352298733374ae760475bb3cd86b4b52fe3dcf7fd61f678e969030e",
    db_ticker: "EXPO",
    db_name: "EXPO Pool",
    db_url: "https://expo-dow.com",
    roa: "3.624",
    roa_lifetime: "4.019",
    total_stake: "16616232243633",
    stake_x_deleg: "593436865844.04",
    saturated: "0.24171148394171",
    luck_lifetime: "96",
    blocks_epoch: "10",
    blocks_lifetime: "958",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SargatxetPool",
    icon: "https://cdn.adapools.org/pool_logo/e811a4b2f8ef3ec84143e3026d706564bc1cc98dc199a305e0fbb8e3.png",
    pool_id: "e811a4b2f8ef3ec84143e3026d706564bc1cc98dc199a305e0fbb8e3",
    db_ticker: "SARGA",
    db_name: "SargatxetPool",
    db_url: "https://cardano.sargatxet.cloud",
    roa: "4.054",
    roa_lifetime: "4.552",
    total_stake: "22312943901509",
    stake_x_deleg: "5692077525.8952",
    saturated: "0.32457988685181",
    luck_lifetime: "98",
    blocks_epoch: "16",
    blocks_lifetime: "2775",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GAGA Pool",
    icon: "0",
    pool_id: "f8328a36320b8719b07da68f3b28f019feb5907ac26a42420d3680b3",
    db_ticker: "GAGA",
    db_name: "GAGA Pool",
    db_url: "https://staking-fm.site",
    roa: "3.508",
    roa_lifetime: "4.587",
    total_stake: "55225274629439",
    stake_x_deleg: "1972331236765.7",
    saturated: "0.80334596231254",
    luck_lifetime: "98",
    blocks_epoch: "42",
    blocks_lifetime: "6539",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FEEL",
    icon: "https://cdn.adapools.org/pool_logo/455778840915ea2ec843bfee7269c1e0963b08ce5d1cdf029e39cb59.png",
    pool_id: "455778840915ea2ec843bfee7269c1e0963b08ce5d1cdf029e39cb59",
    db_ticker: "FEEL",
    db_name: "FEEL",
    db_url: "https://www.feelstakepool.com",
    roa: "3.927",
    roa_lifetime: "4.117",
    total_stake: "34063118055366",
    stake_x_deleg: "64882129629.269",
    saturated: "0.49550624305934",
    luck_lifetime: "100",
    blocks_epoch: "34",
    blocks_lifetime: "501",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WYAM-StakePool",
    icon: "https://cdn.adapools.org/pool_logo/940d6893606290dc6b7705a8aa56a857793a8ae0a3906d4e2afd2119.png",
    pool_id: "940d6893606290dc6b7705a8aa56a857793a8ae0a3906d4e2afd2119",
    db_ticker: "WYAM",
    db_name: "WYAM-StakePool",
    db_url: "https://wyam-stakepool.com",
    roa: "4.45",
    roa_lifetime: "4.308",
    total_stake: "4595736013179",
    stake_x_deleg: "117839384953.31",
    saturated: "0.066852831331573",
    luck_lifetime: "112",
    blocks_epoch: "2",
    blocks_lifetime: "319",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCJ",
    icon: "0",
    pool_id: "39654d8b4eebd091f6ce64d4be8fc05c5e50187b821b9a334076ce55",
    db_ticker: "CCJ",
    db_name: "CCJ",
    db_url: "http://ccj123.html.xdomain.jp",
    roa: "5.417",
    roa_lifetime: "4.779",
    total_stake: "3883739806166",
    stake_x_deleg: "92469995384.905",
    saturated: "0.056495630178229",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "3259",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BonafideADA",
    icon: "https://cdn.adapools.org/pool_logo/4f345190f6280c907325bcd11f9b00f222f092c889f6bedfb739abb1.png",
    pool_id: "4f345190f6280c907325bcd11f9b00f222f092c889f6bedfb739abb1",
    db_ticker: "BFADA",
    db_name: "BonafideADA",
    db_url: "https://bonafideada.com/",
    roa: "4.002",
    roa_lifetime: "3.765",
    total_stake: "10371296805219",
    stake_x_deleg: "19135233957.969",
    saturated: "0.1508682295982",
    luck_lifetime: "97",
    blocks_epoch: "5",
    blocks_lifetime: "377",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADABreathes",
    icon: "https://cdn.adapools.org/pool_logo/80cb48c2953a78d25f69dbcea305fbcce0c520c25d401cd75a259cf1.png",
    pool_id: "80cb48c2953a78d25f69dbcea305fbcce0c520c25d401cd75a259cf1",
    db_ticker: "AIR",
    db_name: "ADABreathes",
    db_url: "https://www.adabreathes.com",
    roa: "4.375",
    roa_lifetime: "4.011",
    total_stake: "39566432345982",
    stake_x_deleg: "58271623484.51",
    saturated: "0.5755613508767",
    luck_lifetime: "95",
    blocks_epoch: "32",
    blocks_lifetime: "893",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SMILES ON AFRICA",
    icon: "https://cdn.adapools.org/pool_logo/cf635c0a032bc98d453b65512aaf2b0f9b0f62b2b865a6011523aaf7.png",
    pool_id: "cf635c0a032bc98d453b65512aaf2b0f9b0f62b2b865a6011523aaf7",
    db_ticker: "SMILE",
    db_name: "SMILES ON AFRICA",
    db_url: "https://smilespool.com/",
    roa: "3.863",
    roa_lifetime: "3.788",
    total_stake: "8297571692592",
    stake_x_deleg: "20795919029.053",
    saturated: "0.12070235523445",
    luck_lifetime: "100",
    blocks_epoch: "6",
    blocks_lifetime: "247",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MaximumMint",
    icon: "https://cdn.adapools.org/pool_logo/ba67e271c925f7b066a00f4c9cbb9f27b13cef1ab02cca4c245c8acc.png",
    pool_id: "ba67e271c925f7b066a00f4c9cbb9f27b13cef1ab02cca4c245c8acc",
    db_ticker: "AMINT",
    db_name: "MaximumMint",
    db_url: "https://cardanomint.com",
    roa: "3.83",
    roa_lifetime: "4.847",
    total_stake: "3555365500168",
    stake_x_deleg: "18232643590.605",
    saturated: "0.051718864926797",
    luck_lifetime: "108",
    blocks_epoch: "5",
    blocks_lifetime: "495",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "United Stakes of Cardano",
    icon: "https://cdn.adapools.org/pool_logo/3dc5218de6bf5a29ad6203d9da31b7128c414872e53b8f09767764d4.png",
    pool_id: "3dc5218de6bf5a29ad6203d9da31b7128c414872e53b8f09767764d4",
    db_ticker: "USA01",
    db_name: "United Stakes of Cardano",
    db_url: "https://unitedstakesofcardano.io",
    roa: "3.43",
    roa_lifetime: "4.27",
    total_stake: "3588349017260",
    stake_x_deleg: "23002237290.128",
    saturated: "0.052198666529533",
    luck_lifetime: "94",
    blocks_epoch: "3",
    blocks_lifetime: "624",
    tax_ratio: "0.0239",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nerd Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/e3f78291c937e8a2f5662765c1442804dc89db4ef7f3296e4852b986.png",
    pool_id: "e3f78291c937e8a2f5662765c1442804dc89db4ef7f3296e4852b986",
    db_ticker: "NERD",
    db_name: "Nerd Stake Pool",
    db_url: "https://www.stakewithnerd.com/",
    roa: "4.155",
    roa_lifetime: "4.142",
    total_stake: "32721399072512",
    stake_x_deleg: "14110133278.358",
    saturated: "0.47598864835897",
    luck_lifetime: "99",
    blocks_epoch: "22",
    blocks_lifetime: "2277",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano24",
    icon: "https://cdn.adapools.org/pool_logo/be6d07b366b26dd577682e0e499c0d81988d8b3f49b52b853691eeba.png",
    pool_id: "be6d07b366b26dd577682e0e499c0d81988d8b3f49b52b853691eeba",
    db_ticker: "ADA",
    db_name: "Cardano24",
    db_url: "https://Cardano24.com",
    roa: "3.655",
    roa_lifetime: "3.914",
    total_stake: "17278131388136",
    stake_x_deleg: "112928963321.15",
    saturated: "0.25133993773868",
    luck_lifetime: "97",
    blocks_epoch: "13",
    blocks_lifetime: "485",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ape Pool",
    icon: "https://cdn.adapools.org/pool_logo/3c947f2150c633fcd157b9b0fc42ca45d28bba6d06ca4edda09de568.png",
    pool_id: "3c947f2150c633fcd157b9b0fc42ca45d28bba6d06ca4edda09de568",
    db_ticker: "APE",
    db_name: "ADA Ape Pool",
    db_url: "https://www.adaape.com",
    roa: "3.595",
    roa_lifetime: "4.071",
    total_stake: "10518653706189",
    stake_x_deleg: "6911073394.3423",
    saturated: "0.15301178745658",
    luck_lifetime: "102",
    blocks_epoch: "13",
    blocks_lifetime: "784",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TeqWyse Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/398964318c3664acd3a17d6d0f29a74b6d9a8200cde2f875629906b9.png",
    pool_id: "398964318c3664acd3a17d6d0f29a74b6d9a8200cde2f875629906b9",
    db_ticker: "TQWS",
    db_name: "TeqWyse Stake Pool",
    db_url: "https://teqwyse.com",
    roa: "2.51",
    roa_lifetime: "4.174",
    total_stake: "3562520074084",
    stake_x_deleg: "67217359888.377",
    saturated: "0.051822940426757",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "410",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1015000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wish Pool",
    icon: "https://cdn.adapools.org/pool_logo/4dbdfb68de96a563719f7df2b3cf7c98fb547a3e7edca2a547d35852.png",
    pool_id: "4dbdfb68de96a563719f7df2b3cf7c98fb547a3e7edca2a547d35852",
    db_ticker: "WISH",
    db_name: "Wish Pool",
    db_url: "http://blockchainlens.org",
    roa: "3.926",
    roa_lifetime: "3.831",
    total_stake: "4599990564751",
    stake_x_deleg: "52272620053.989",
    saturated: "0.066914721052353",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "423",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "31000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAwolf ",
    icon: "https://cdn.adapools.org/pool_logo/00000368a25a58d3c46bea611ddabf77679b8d1fd854c75a41ea399c.png",
    pool_id: "00000368a25a58d3c46bea611ddabf77679b8d1fd854c75a41ea399c",
    db_ticker: "WOLF",
    db_name: "ADAwolf ",
    db_url: "https://adafrog.io",
    roa: "3.524",
    roa_lifetime: "4.207",
    total_stake: "5964112696061",
    stake_x_deleg: "3751014274.2522",
    saturated: "0.086758207818915",
    luck_lifetime: "103",
    blocks_epoch: "4",
    blocks_lifetime: "520",
    tax_ratio: "0.0249",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BreakingADA",
    icon: "https://cdn.adapools.org/pool_logo/c0d70a23601c6a880e7a730774c0a4005e49f0eb52ea38d1e609df0d.png",
    pool_id: "c0d70a23601c6a880e7a730774c0a4005e49f0eb52ea38d1e609df0d",
    db_ticker: "BREAK",
    db_name: "BreakingADA",
    db_url: "https://twitter.com/BreakingADA",
    roa: "3.616",
    roa_lifetime: "3.489",
    total_stake: "16842793813399",
    stake_x_deleg: "19359533118.849",
    saturated: "0.2450072090152",
    luck_lifetime: "88",
    blocks_epoch: "21",
    blocks_lifetime: "462",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SAGE Cardano Staking",
    icon: "https://cdn.adapools.org/pool_logo/bca52a1ea803c93025fa43e3aed7af82c3b2bfb01b530b07b0048c9a.png",
    pool_id: "bca52a1ea803c93025fa43e3aed7af82c3b2bfb01b530b07b0048c9a",
    db_ticker: "SAGE",
    db_name: "SAGE Cardano Staking",
    db_url: "https://www.ianesthesia.org/sage.html",
    roa: "2.855",
    roa_lifetime: "4.452",
    total_stake: "3441385053604",
    stake_x_deleg: "12745870568.904",
    saturated: "0.050060824615651",
    luck_lifetime: "96",
    blocks_epoch: "3",
    blocks_lifetime: "864",
    tax_ratio: "0.01999",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Seed Pools",
    icon: "0",
    pool_id: "a4dcead63ac83d18e481683cc9fff0980837256eac6a6b303b56f0ab",
    db_ticker: "SEED",
    db_name: "Seed Pools",
    db_url: "https://seedpools.org",
    roa: "1.929",
    roa_lifetime: "3.854",
    total_stake: "3461029144877",
    stake_x_deleg: "4205381707.0194",
    saturated: "0.050346581481748",
    luck_lifetime: "87",
    blocks_epoch: "2",
    blocks_lifetime: "691",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RATS Pool",
    icon: "0",
    pool_id: "5e376d756f6eec459900a4abcf2510316c45b13cf204a57c703dc251",
    db_ticker: "RATS",
    db_name: "RATS Pool",
    db_url: "https://rats.fail",
    roa: "4.192",
    roa_lifetime: "3.917",
    total_stake: "4678630238956",
    stake_x_deleg: "13720323281.396",
    saturated: "0.068058669455942",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "588",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THREE Pool",
    icon: "https://cdn.adapools.org/pool_logo/bba38410de5b0d75242daeac580103e72814cc4baccdc2314ef4ffbe.png",
    pool_id: "bba38410de5b0d75242daeac580103e72814cc4baccdc2314ef4ffbe",
    db_ticker: "THREE",
    db_name: "THREE Pool",
    db_url: "https://threepool.info",
    roa: "4.603",
    roa_lifetime: "3.937",
    total_stake: "4272126865734",
    stake_x_deleg: "46946449074",
    saturated: "0.062145383451743",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "468",
    tax_ratio: "0.03333",
    tax_fix: "343000000",
    pledge: "33333333333",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CTEC Stake Pool",
    icon: "0",
    pool_id: "a56e07cb5cd11a2d2b6bff3c6ecbc7ddc79205e2831560792f06e795",
    db_ticker: "CTEC",
    db_name: "CTEC Stake Pool",
    db_url: "https://www.crown-tech.co.jp/ctecstakepool/",
    roa: "4.469",
    roa_lifetime: "4.493",
    total_stake: "44195848435038",
    stake_x_deleg: "901956090510.98",
    saturated: "0.64290411644849",
    luck_lifetime: "99",
    blocks_epoch: "46",
    blocks_lifetime: "3243",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Dan's Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c8ae7865c04089e56bc4611f28404707bbbc663e345b7da7ea548ff7.png",
    pool_id: "c8ae7865c04089e56bc4611f28404707bbbc663e345b7da7ea548ff7",
    db_ticker: "DAN2",
    db_name: "Cardano Dan's Stake Pool",
    db_url: "https://twitter.com/cardanodan",
    roa: "2.295",
    roa_lifetime: "4.669",
    total_stake: "3529665285038",
    stake_x_deleg: "6536417194.5148",
    saturated: "0.05134501139336",
    luck_lifetime: "102",
    blocks_epoch: "1",
    blocks_lifetime: "1197",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Six",
    icon: "https://cdn.adapools.org/pool_logo/4a463cd2a7ba953d1ff3514c1bc41910fa8888fd6bec83905cd642d8.png",
    pool_id: "4a463cd2a7ba953d1ff3514c1bc41910fa8888fd6bec83905cd642d8",
    db_ticker: "OCEA6",
    db_name: "ADA Ocean Six",
    db_url: "https://adaocean.com/",
    roa: "4.19",
    roa_lifetime: "4.592",
    total_stake: "3390212094071",
    stake_x_deleg: "28251767450.592",
    saturated: "0.049316426499095",
    luck_lifetime: "100",
    blocks_epoch: "4",
    blocks_lifetime: "605",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ginger's Pool",
    icon: "0",
    pool_id: "1dc1e011d522a077a20b2659bd0ee257e0d80f105f5dc998d4cfda7c",
    db_ticker: "GINGR",
    db_name: "Ginger's Pool",
    db_url: "https://evaginger.com",
    roa: "4.851",
    roa_lifetime: "3.821",
    total_stake: "15527813201419",
    stake_x_deleg: "127277157388.68",
    saturated: "0.22587856959707",
    luck_lifetime: "101",
    blocks_epoch: "11",
    blocks_lifetime: "280",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chile_Stake_po",
    icon: "0",
    pool_id: "7e7eb329d995759d2b9fdb85b8d0c9e3fa2e57c460641c8fc875e780",
    db_ticker: "CHIL",
    db_name: "Chile_Stake_po",
    db_url: "https://www.chilestakepo.cl",
    roa: "2.867",
    roa_lifetime: "4.013",
    total_stake: "3803744253741",
    stake_x_deleg: "3999731076.489",
    saturated: "0.055331958209646",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "596",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Codanar",
    icon: "https://cdn.adapools.org/pool_logo/d65467e9d63f124ed903101f82868bda6ffd39f83bc7d30de65a288f.png",
    pool_id: "d65467e9d63f124ed903101f82868bda6ffd39f83bc7d30de65a288f",
    db_ticker: "CDN",
    db_name: "Codanar",
    db_url: "https://codanar.com",
    roa: "3.704",
    roa_lifetime: "3.38",
    total_stake: "3300844005946",
    stake_x_deleg: "6472243148.9137",
    saturated: "0.0480164149874",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "138",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "577777000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo #3",
    icon: "0",
    pool_id: "c34a7f59c556633dc88ec25c9743c5ebca3705e179a54db5638941cb",
    db_ticker: "EMUR3",
    db_name: "Emurgo #3",
    db_url: "https://emurgo.io/",
    roa: "4.411",
    roa_lifetime: "4.83",
    total_stake: "3270140055914",
    stake_x_deleg: "4698477091.8305",
    saturated: "0.047569773581798",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "3050",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SAKE",
    icon: "https://cdn.adapools.org/pool_logo/37d2b23f574680a88198e99cc884b6cf9292ce3500467584cb5093f2.png",
    pool_id: "37d2b23f574680a88198e99cc884b6cf9292ce3500467584cb5093f2",
    db_ticker: "SAKE",
    db_name: "SAKE",
    db_url: "https://sakepool.com",
    roa: "3.802",
    roa_lifetime: "3.494",
    total_stake: "21515239149614",
    stake_x_deleg: "316400575729.62",
    saturated: "0.31297590849494",
    luck_lifetime: "91",
    blocks_epoch: "22",
    blocks_lifetime: "276",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Haskell CI Support Pool",
    icon: "0",
    pool_id: "e2c17915148f698723cb234f3cd89e9325f40b89af9fd6e1f9d1701a",
    db_ticker: "ZW3RK",
    db_name: "Haskell CI Support Pool",
    db_url: "https://pools.zw3rk.com",
    roa: "4.434",
    roa_lifetime: "4.044",
    total_stake: "16412452015773",
    stake_x_deleg: "256444562746.45",
    saturated: "0.23874715240422",
    luck_lifetime: "98",
    blocks_epoch: "13",
    blocks_lifetime: "825",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AAAA pool",
    icon: "https://cdn.adapools.org/pool_logo/2c799c3eb2fb59dbf874500fa77df18a2acaa8cfec1793c928294a50.png",
    pool_id: "2c799c3eb2fb59dbf874500fa77df18a2acaa8cfec1793c928294a50",
    db_ticker: "0AAAA",
    db_name: "AAAA pool",
    db_url: "https://0AAAA.org",
    roa: "3.537",
    roa_lifetime: "3.598",
    total_stake: "15231337289324",
    stake_x_deleg: "331116028028.78",
    saturated: "0.22156582097785",
    luck_lifetime: "92",
    blocks_epoch: "7",
    blocks_lifetime: "131",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " PLANETSTAKE 2",
    icon: "https://cdn.adapools.org/pool_logo/f8e96e3c3195c966a63d55b037ac83ec5e06086bfbd4d1eb84614e33.png",
    pool_id: "f8e96e3c3195c966a63d55b037ac83ec5e06086bfbd4d1eb84614e33",
    db_ticker: "STAKE",
    db_name: " PLANETSTAKE 2",
    db_url: "https://planetstake.com",
    roa: "3.958",
    roa_lifetime: "4.891",
    total_stake: "3345936956360",
    stake_x_deleg: "7604402173.5455",
    saturated: "0.048672368984676",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "1276",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kevmate",
    icon: "0",
    pool_id: "47d0952dee0eacb0a7dd1b059fdd3c0580172d6d81a497e8f51dd1e6",
    db_ticker: "KMADA",
    db_name: "Kevmate",
    db_url: "https://kevmate.com",
    roa: "4.478",
    roa_lifetime: "3.604",
    total_stake: "3561024970707",
    stake_x_deleg: "296752080892.25",
    saturated: "0.051801191594014",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "176",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "199000821299",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Farm",
    icon: "https://cdn.adapools.org/pool_logo/6879ab43e837d11c606e37dbf3e743f13f303c9dff53be602f263d6d.png",
    pool_id: "6879ab43e837d11c606e37dbf3e743f13f303c9dff53be602f263d6d",
    db_ticker: "FARM",
    db_name: "ADA Farm",
    db_url: "https://adafarm.io",
    roa: "4.733",
    roa_lifetime: "4.038",
    total_stake: "9865210095024",
    stake_x_deleg: "26880681457.831",
    saturated: "0.14350633383682",
    luck_lifetime: "98",
    blocks_epoch: "7",
    blocks_lifetime: "470",
    tax_ratio: "0.0095",
    tax_fix: "340000000",
    pledge: "21888000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Latino_Staking_Pool",
    icon: "https://cdn.adapools.org/pool_logo/e8e00995c7f3820fedf01e75a443c56e20f96ddcf9168b662ce2fdcd.png",
    pool_id: "e8e00995c7f3820fedf01e75a443c56e20f96ddcf9168b662ce2fdcd",
    db_ticker: "LATIN",
    db_name: "Latino_Staking_Pool",
    db_url: "https://cardanolatino.com/cardanoenglish/Latino-Staking-Pool-Eng/",
    roa: "1.726",
    roa_lifetime: "4.521",
    total_stake: "3006222566863",
    stake_x_deleg: "6958848534.4051",
    saturated: "0.04373064284618",
    luck_lifetime: "104",
    blocks_epoch: "3",
    blocks_lifetime: "567",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Australia",
    icon: "https://cdn.adapools.org/pool_logo/5b9a5633289c52003d359bce18f77f571a701fd3942a52bde3262558.png",
    pool_id: "5b9a5633289c52003d359bce18f77f571a701fd3942a52bde3262558",
    db_ticker: "AOAUS",
    db_name: "ADA Ocean Australia",
    db_url: "https://adaocean.com.au",
    roa: "3.963",
    roa_lifetime: "4.287",
    total_stake: "3136687810682",
    stake_x_deleg: "11704058995.082",
    saturated: "0.045628482694825",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "581",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MUGEN-Pool",
    icon: "https://cdn.adapools.org/pool_logo/13913da4d22cec06a4c6069a64e747b827edcbea4594bb52ece17860.png",
    pool_id: "13913da4d22cec06a4c6069a64e747b827edcbea4594bb52ece17860",
    db_ticker: "MUGEN",
    db_name: "MUGEN-Pool",
    db_url: "https://mugen-pool.com",
    roa: "2.515",
    roa_lifetime: "4.186",
    total_stake: "3408461203230",
    stake_x_deleg: "227230746882",
    saturated: "0.049581890967258",
    luck_lifetime: "95",
    blocks_epoch: "2",
    blocks_lifetime: "734",
    tax_ratio: "0.0398",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BUTT",
    icon: "https://cdn.adapools.org/pool_logo/d19f0911de1f06bb763d0f095d6d8da441b8e744085715d7bfb7c2fa.png",
    pool_id: "d19f0911de1f06bb763d0f095d6d8da441b8e744085715d7bfb7c2fa",
    db_ticker: "BUTT",
    db_name: "BUTT",
    db_url: "https://butt.lol/",
    roa: "3.298",
    roa_lifetime: "4.195",
    total_stake: "3248727819227",
    stake_x_deleg: "129949112769.08",
    saturated: "0.047258296020083",
    luck_lifetime: "104",
    blocks_epoch: "4",
    blocks_lifetime: "341",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BitPool",
    icon: "https://cdn.adapools.org/pool_logo/98a124471ceee3c944a0e4275ae20ae4bd78bfe108203043ff71a0ac.png",
    pool_id: "98a124471ceee3c944a0e4275ae20ae4bd78bfe108203043ff71a0ac",
    db_ticker: "BPOOL",
    db_name: "BitPool",
    db_url: "https://bitboycrypto.com",
    roa: "4.841",
    roa_lifetime: "3.171",
    total_stake: "3242062730051",
    stake_x_deleg: "2879274182.9938",
    saturated: "0.047161340911867",
    luck_lifetime: "87",
    blocks_epoch: "4",
    blocks_lifetime: "211",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1PCT Pools at 1-PCT.com",
    icon: "0",
    pool_id: "c0c231446dc398936f0f85506072f10641b861ce5012c0fcb1051442",
    db_ticker: "WOW",
    db_name: "1PCT Pools at 1-PCT.com",
    db_url: "http://1-PCT.com",
    roa: "4.226",
    roa_lifetime: "4.367",
    total_stake: "3170368416323",
    stake_x_deleg: "67454647155.809",
    saturated: "0.046118424641361",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "500",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CheekyCrypto",
    icon: "https://cdn.adapools.org/pool_logo/14ab7f5b1a6522028ce68a5c24f3a44a92376f27e52c7d238fe0b39e.png",
    pool_id: "14ab7f5b1a6522028ce68a5c24f3a44a92376f27e52c7d238fe0b39e",
    db_ticker: "CHEEK",
    db_name: "CheekyCrypto",
    db_url: "https://cheekycrypto.io",
    roa: "3.77",
    roa_lifetime: "4.293",
    total_stake: "5137602521468",
    stake_x_deleg: "4498776288.5009",
    saturated: "0.074735205379819",
    luck_lifetime: "98",
    blocks_epoch: "7",
    blocks_lifetime: "828",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TOPO Pool",
    icon: "https://cdn.adapools.org/pool_logo/9dda57fac3474adbe8b64be48aafeff6aa31f59909231f202b642d33.png",
    pool_id: "9dda57fac3474adbe8b64be48aafeff6aa31f59909231f202b642d33",
    db_ticker: "TOPO⚡",
    db_name: "TOPO Pool",
    db_url: "https://topopool.com",
    roa: "2.532",
    roa_lifetime: "3.994",
    total_stake: "2913028139921",
    stake_x_deleg: "3419047112.5833",
    saturated: "0.042374970699754",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "646",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ARTZ Pool",
    icon: "https://cdn.adapools.org/pool_logo/578ce876fe97ffc5f2f999d9ff4199a2bc7bfaf732e648471fac4ed2.png",
    pool_id: "578ce876fe97ffc5f2f999d9ff4199a2bc7bfaf732e648471fac4ed2",
    db_ticker: "ARTZ",
    db_name: "ARTZ Pool",
    db_url: "https://artzstakepool.com",
    roa: "2.684",
    roa_lifetime: "4.639",
    total_stake: "2946356082516",
    stake_x_deleg: "18414725515.725",
    saturated: "0.042859782559822",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "1349",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LongShortSignal",
    icon: "https://cdn.adapools.org/pool_logo/4d470d31f80c01d797996b43abdfe7339ae5b408fb31cc655a80ccd7.png",
    pool_id: "4d470d31f80c01d797996b43abdfe7339ae5b408fb31cc655a80ccd7",
    db_ticker: "LSS",
    db_name: "LongShortSignal",
    db_url: "https://longshortsignal.com/cardanopool",
    roa: "3.679",
    roa_lifetime: "4.258",
    total_stake: "2682161856813",
    stake_x_deleg: "12417416003.764",
    saturated: "0.039016626216845",
    luck_lifetime: "100",
    blocks_epoch: "2",
    blocks_lifetime: "375",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeNuts",
    icon: "https://cdn.adapools.org/pool_logo/0f292fcaa02b8b2f9b3c8f9fd8e0bb21abedb692a6d5058df3ef2735.png",
    pool_id: "0f292fcaa02b8b2f9b3c8f9fd8e0bb21abedb692a6d5058df3ef2735",
    db_ticker: "NUTS",
    db_name: "StakeNuts",
    db_url: "https://stakenuts.com/",
    roa: "2.809",
    roa_lifetime: "4.94",
    total_stake: "2914999281134",
    stake_x_deleg: "18105585597.106",
    saturated: "0.042403644316051",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "1939",
    tax_ratio: "0.049",
    tax_fix: "340000000",
    pledge: "510000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "X-StakePool",
    icon: "https://cdn.adapools.org/pool_logo/788898a81174665316af96880459dcca053f7825abb1b0db9a433630.png",
    pool_id: "788898a81174665316af96880459dcca053f7825abb1b0db9a433630",
    db_ticker: "XSP",
    db_name: "X-StakePool",
    db_url: "https://xstakepool.com",
    roa: "3.947",
    roa_lifetime: "4.415",
    total_stake: "49832128362666",
    stake_x_deleg: "40349901508.232",
    saturated: "0.72489343660498",
    luck_lifetime: "99",
    blocks_epoch: "38",
    blocks_lifetime: "5336",
    tax_ratio: "0.037",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "X-StakePool2",
    icon: "https://cdn.adapools.org/pool_logo/aa65bf8422f6b5af3a1197e9fe6649539025f2e18257cfb2f1e631aa.png",
    pool_id: "aa65bf8422f6b5af3a1197e9fe6649539025f2e18257cfb2f1e631aa",
    db_ticker: "XSP2",
    db_name: "X-StakePool2",
    db_url: "https://xstakepool.com",
    roa: "3.8",
    roa_lifetime: "3.956",
    total_stake: "26762638798881",
    stake_x_deleg: "20382817059.315",
    saturated: "0.38930830066799",
    luck_lifetime: "101",
    blocks_epoch: "23",
    blocks_lifetime: "1787",
    tax_ratio: "0.037",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PsyADA",
    icon: "https://cdn.adapools.org/pool_logo/111646b0848804b1c0ff51888432c9c4b0b0aa3b55f11ecbd0e36624.png",
    pool_id: "111646b0848804b1c0ff51888432c9c4b0b0aa3b55f11ecbd0e36624",
    db_ticker: "PSYA",
    db_name: "PsyADA",
    db_url: "https://psyada.com",
    roa: "4.308",
    roa_lifetime: "4.074",
    total_stake: "25207206899230",
    stake_x_deleg: "33254890368.377",
    saturated: "0.36668188650126",
    luck_lifetime: "101",
    blocks_epoch: "22",
    blocks_lifetime: "808",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Quixote Dream Pool",
    icon: "https://cdn.adapools.org/pool_logo/351a90a0253fb3595ccc460438b9947cd8a0b9a7f457f3478bbb31d8.png",
    pool_id: "351a90a0253fb3595ccc460438b9947cd8a0b9a7f457f3478bbb31d8",
    db_ticker: "QXT",
    db_name: "Quixote Dream Pool",
    db_url: "https://quixote.systems",
    roa: "4.314",
    roa_lifetime: "3.624",
    total_stake: "19390107882769",
    stake_x_deleg: "20325060673.762",
    saturated: "0.28206224379957",
    luck_lifetime: "93",
    blocks_epoch: "15",
    blocks_lifetime: "388",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Elite Stake Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/b6bbf5a7b34420b56f6641fa5731116842480b87c59226c78cd74487.png",
    pool_id: "b6bbf5a7b34420b56f6641fa5731116842480b87c59226c78cd74487",
    db_ticker: "ELITE",
    db_name: "Elite Stake Pool 2",
    db_url: "https://elitestakepool.com",
    roa: "2.721",
    roa_lifetime: "4.117",
    total_stake: "2645957468607",
    stake_x_deleg: "10888713862.58",
    saturated: "0.038489971541455",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "415",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DenAdaPool",
    icon: "https://cdn.adapools.org/pool_logo/d28e4ae3848720195f09becd15e6985b54cbf3b62e52bf53c5af8501.png",
    pool_id: "d28e4ae3848720195f09becd15e6985b54cbf3b62e52bf53c5af8501",
    db_ticker: "DEN",
    db_name: "DenAdaPool",
    db_url: "https://denadapool.io/",
    roa: "3.742",
    roa_lifetime: "4.019",
    total_stake: "9868792703144",
    stake_x_deleg: "33116754037.396",
    saturated: "0.14355844899219",
    luck_lifetime: "101",
    blocks_epoch: "11",
    blocks_lifetime: "502",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UKADA",
    icon: "0",
    pool_id: "738585e4df0f9fb93dec66a9bfddd770f309d0f029bd2fd570732c8f",
    db_ticker: "UKADA",
    db_name: "UKADA",
    db_url: "https://ukada.godaddysites.com/",
    roa: "2.471",
    roa_lifetime: "4.791",
    total_stake: "3005925411563",
    stake_x_deleg: "4903630361.4405",
    saturated: "0.043726320214703",
    luck_lifetime: "95",
    blocks_epoch: "2",
    blocks_lifetime: "2067",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RISE",
    icon: "https://cdn.adapools.org/pool_logo/1e4f8dc9672d16131da6533287e32ea100b34d67543ff7c7358c7b5e.png",
    pool_id: "1e4f8dc9672d16131da6533287e32ea100b34d67543ff7c7358c7b5e",
    db_ticker: "RISE",
    db_name: "RISE",
    db_url: "https://risecardano.com",
    roa: "3.471",
    roa_lifetime: "4.778",
    total_stake: "3725664850477",
    stake_x_deleg: "17826147609.938",
    saturated: "0.054196159904019",
    luck_lifetime: "106",
    blocks_epoch: "6",
    blocks_lifetime: "732",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chaos",
    icon: "https://cdn.adapools.org/pool_logo/607a7ae66035ef4bcc840e40985e0805e724888d6a01e15569df597d.png",
    pool_id: "607a7ae66035ef4bcc840e40985e0805e724888d6a01e15569df597d",
    db_ticker: "CHAOS",
    db_name: "Chaos",
    db_url: "https://chaos-staking.com",
    roa: "3.516",
    roa_lifetime: "4.313",
    total_stake: "4616602779375",
    stake_x_deleg: "96179224570.312",
    saturated: "0.067156374093153",
    luck_lifetime: "107",
    blocks_epoch: "4",
    blocks_lifetime: "307",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RIOT",
    icon: "0",
    pool_id: "0dbd895b0d875c64aa8d76fefcfc8765e46f383e983b38464eeafb3c",
    db_ticker: "RIOT",
    db_name: "RIOT",
    db_url: "https://riotpools.com",
    roa: "4.436",
    roa_lifetime: "4.457",
    total_stake: "2962263138923",
    stake_x_deleg: "11526315715.654",
    saturated: "0.043091177869716",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "892",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PXLZ Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/ccf9fa7bd7cc72f851effb0e229f7ab163ae670250c38b18dbdd8f8f.png",
    pool_id: "ccf9fa7bd7cc72f851effb0e229f7ab163ae670250c38b18dbdd8f8f",
    db_ticker: "PXLZ",
    db_name: "PXLZ Staking Pool",
    db_url: "https://pxlz.org",
    roa: "2.316",
    roa_lifetime: "2.796",
    total_stake: "2286365504560",
    stake_x_deleg: "18145757972.698",
    saturated: "0.033259092123733",
    luck_lifetime: "93",
    blocks_epoch: "3",
    blocks_lifetime: "64",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "champADA",
    icon: "0",
    pool_id: "96d4a78a1459a66cea6b396fd92f7abba092bd59535d21ad3c0463c7",
    db_ticker: "CHAMP",
    db_name: "champADA",
    db_url: "https://champada.io",
    roa: "3.107",
    roa_lifetime: "4.048",
    total_stake: "2713226975743",
    stake_x_deleg: "23800236629.325",
    saturated: "0.039468521441063",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "348",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Joey Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/03f79dedaead96339d36df69158116e83208b938afb5fcd0a30e5b5c.png",
    pool_id: "03f79dedaead96339d36df69158116e83208b938afb5fcd0a30e5b5c",
    db_ticker: "JOEY",
    db_name: "Joey Stake Pool",
    db_url: "https://joeystakepool.com/",
    roa: "3.659",
    roa_lifetime: "4.408",
    total_stake: "4519110475527",
    stake_x_deleg: "9824153207.6674",
    saturated: "0.06573818198495",
    luck_lifetime: "94",
    blocks_epoch: "4",
    blocks_lifetime: "961",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Drunken Dragon Pool",
    icon: "0",
    pool_id: "0084f4fee5502c87ee5c4f5c592856f2bfb6269355b9d87ed549e551",
    db_ticker: "DND",
    db_name: "Drunken Dragon Pool",
    db_url: "https://www.drunkendragon.games",
    roa: "1.965",
    roa_lifetime: "3.489",
    total_stake: "2862563894108",
    stake_x_deleg: "3198395412.4112",
    saturated: "0.04164088203497",
    luck_lifetime: "91",
    blocks_epoch: "3",
    blocks_lifetime: "214",
    tax_ratio: "0.022",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Advanced Crypto Team",
    icon: "https://cdn.adapools.org/pool_logo/7b92c27d47addf920a4b74db73d99c8ebe06057c802b332d945077d1.png",
    pool_id: "7b92c27d47addf920a4b74db73d99c8ebe06057c802b332d945077d1",
    db_ticker: "ACT",
    db_name: "Advanced Crypto Team",
    db_url: "https://actpool.io",
    roa: "2.787",
    roa_lifetime: "4.497",
    total_stake: "2825501169099",
    stake_x_deleg: "12502217562.385",
    saturated: "0.041101741384461",
    luck_lifetime: "106",
    blocks_epoch: "5",
    blocks_lifetime: "454",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "120000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Berlin Pool",
    icon: "https://cdn.adapools.org/pool_logo/c2e85124c962d9eada3bdf2c0a27a0f5b153bb861c16432daf504dfe.png",
    pool_id: "c2e85124c962d9eada3bdf2c0a27a0f5b153bb861c16432daf504dfe",
    db_ticker: "BRLN",
    db_name: "Berlin Pool",
    db_url: "https://staking.berlin",
    roa: "3.515",
    roa_lifetime: "4.07",
    total_stake: "2783609299076",
    stake_x_deleg: "12316855305.646",
    saturated: "0.040492352569964",
    luck_lifetime: "104",
    blocks_epoch: "3",
    blocks_lifetime: "450",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Da VINCI pool",
    icon: "https://cdn.adapools.org/pool_logo/91d030730d73b037e1c230d6e8bd80c05687c9bf1efbc051de22d803.png",
    pool_id: "91d030730d73b037e1c230d6e8bd80c05687c9bf1efbc051de22d803",
    db_ticker: "VINCI",
    db_name: "Da VINCI pool",
    db_url: "https://www.vincepool.com",
    roa: "4.167",
    roa_lifetime: "3.959",
    total_stake: "2799647386140",
    stake_x_deleg: "28567830470.816",
    saturated: "0.040725653944607",
    luck_lifetime: "100",
    blocks_epoch: "3",
    blocks_lifetime: "256",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Terminada",
    icon: "0",
    pool_id: "08f05bcfaada3bb5c038b8c88c6b502ceabfd9978973159458c6535b",
    db_ticker: "TERM",
    db_name: "Terminada",
    db_url: "https://terminada.io",
    roa: "5.469",
    roa_lifetime: "3.983",
    total_stake: "3094751849400",
    stake_x_deleg: "281341077218.18",
    saturated: "0.045018452497644",
    luck_lifetime: "111",
    blocks_epoch: "1",
    blocks_lifetime: "144",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ROMER",
    icon: "0",
    pool_id: "7c5ec13dc6be50f6b3a319efbcf599f8602d868a07310d1f1e760bc4",
    db_ticker: "ROMER",
    db_name: "ROMER",
    db_url: "https://romerstakepool.io",
    roa: "2.73",
    roa_lifetime: "4.422",
    total_stake: "2772219763443",
    stake_x_deleg: "1624030324.2197",
    saturated: "0.040326672317131",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "655",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DutchPool NLD",
    icon: "0",
    pool_id: "a6d947298c4b1560fec35d5e4f93e46378d653a60526476bf38b1e8b",
    db_ticker: "NLD",
    db_name: "DutchPool NLD",
    db_url: "https://www.dutchpool.eu",
    roa: "3.825",
    roa_lifetime: "4.604",
    total_stake: "2727386015595",
    stake_x_deleg: "6635975707.0438",
    saturated: "0.03967448886398",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "880",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EPOCH Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/03e5b1d3dc6da6101ed402d0ba310d900a110c59627472eb4e34ce24.png",
    pool_id: "03e5b1d3dc6da6101ed402d0ba310d900a110c59627472eb4e34ce24",
    db_ticker: "EPOCH",
    db_name: "EPOCH Stake Pool",
    db_url: "https://EpochStakePool.com/",
    roa: "4.036",
    roa_lifetime: "3.902",
    total_stake: "2644932813043",
    stake_x_deleg: "15837921036.186",
    saturated: "0.03847506617583",
    luck_lifetime: "98",
    blocks_epoch: "2",
    blocks_lifetime: "524",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanoscan Pool 1",
    icon: "0",
    pool_id: "7df262feae9201d1b2e32d4c825ca91b29fbafb2b8e556f6efb7f549",
    db_ticker: "CSN1",
    db_name: "Cardanoscan Pool 1",
    db_url: "https://cardanoscan.io",
    roa: "4.085",
    roa_lifetime: "4.846",
    total_stake: "29245547940974",
    stake_x_deleg: "168077861729.74",
    saturated: "0.42542645576044",
    luck_lifetime: "102",
    blocks_epoch: "29",
    blocks_lifetime: "3792",
    tax_ratio: "0.0189",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cafe Pepe",
    icon: "https://cdn.adapools.org/pool_logo/9d520aa1da84c4096431093f23b71aef7b53227e3a3f43da3a7b40d7.png",
    pool_id: "9d520aa1da84c4096431093f23b71aef7b53227e3a3f43da3a7b40d7",
    db_ticker: "PEPE",
    db_name: "Cafe Pepe",
    db_url: "https://rarepepes.com",
    roa: "2.958",
    roa_lifetime: "3.752",
    total_stake: "2773799749424",
    stake_x_deleg: "71123070498.051",
    saturated: "0.040349655912357",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "341",
    tax_ratio: "0.0369",
    tax_fix: "340000000",
    pledge: "42000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "M1 Crypto EAGLE Pool",
    icon: "https://cdn.adapools.org/pool_logo/2cdc5cef88f1c15e19c33fd8f47d9bd89c3d7ee4fa09512fbd44a126.png",
    pool_id: "2cdc5cef88f1c15e19c33fd8f47d9bd89c3d7ee4fa09512fbd44a126",
    db_ticker: "EAGLE",
    db_name: "M1 Crypto EAGLE Pool",
    db_url: "https://m1crypto.co/ada-pool",
    roa: "3.915",
    roa_lifetime: "4.078",
    total_stake: "4252088241516",
    stake_x_deleg: "10196854296.201",
    saturated: "0.06185388742997",
    luck_lifetime: "100",
    blocks_epoch: "6",
    blocks_lifetime: "399",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bravo pool Two",
    icon: "https://cdn.adapools.org/pool_logo/0a5ad3b4f3f48cf34ed5e659134750c9fd8f57ced7d4da1bc9ecef47.png",
    pool_id: "0a5ad3b4f3f48cf34ed5e659134750c9fd8f57ced7d4da1bc9ecef47",
    db_ticker: "BRAVO",
    db_name: "Bravo pool Two",
    db_url: "https://bravostakepool.nl",
    roa: "2.145",
    roa_lifetime: "3.449",
    total_stake: "2491657431611",
    stake_x_deleg: "13253496976.654",
    saturated: "0.03624541390843",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "184",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "310000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Swiss",
    icon: "https://cdn.adapools.org/pool_logo/ca7b80cf2dfa477c961dd42b2100f12c280a81a3ff9f9dbc5da78c8d.png",
    pool_id: "ca7b80cf2dfa477c961dd42b2100f12c280a81a3ff9f9dbc5da78c8d",
    db_ticker: "ADACH",
    db_name: "ADA Swiss",
    db_url: "https://swisscryptojay.ch",
    roa: "2.715",
    roa_lifetime: "4.054",
    total_stake: "2556110310955",
    stake_x_deleg: "31556917419.198",
    saturated: "0.037182991145082",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "264",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaNet.io",
    icon: "https://cdn.adapools.org/pool_logo/33804ceb7f4add2479faee0544c802f2369a81a4c94a284bef8f6a3e.png",
    pool_id: "33804ceb7f4add2479faee0544c802f2369a81a4c94a284bef8f6a3e",
    db_ticker: "ANET",
    db_name: "AdaNet.io",
    db_url: "https://adanet.io",
    roa: "3.015",
    roa_lifetime: "4.152",
    total_stake: "2519451774967",
    stake_x_deleg: "86877647412.655",
    saturated: "0.03664973011437",
    luck_lifetime: "98",
    blocks_epoch: "2",
    blocks_lifetime: "462",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "630000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1 Percent Pool #1 (v3)",
    icon: "0",
    pool_id: "16d7b39329f79540967e35631dd1f6630a608a5c94e642ee8b2e9e8a",
    db_ticker: "1PCT1",
    db_name: "1 Percent Pool #1 (v3)",
    db_url: "https://www.1percentpool.eu",
    roa: "3.401",
    roa_lifetime: "4.407",
    total_stake: "2673258685900",
    stake_x_deleg: "29376469075.824",
    saturated: "0.038887114386388",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "4275",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Proof of Africa - Enabling Web3 in Africa",
    icon: "https://cdn.adapools.org/pool_logo/683e89fa1bcde139504b11fbfd914f8ebe9b8db2678b3da0abdcb2f1.png",
    pool_id: "683e89fa1bcde139504b11fbfd914f8ebe9b8db2678b3da0abdcb2f1",
    db_ticker: "POA",
    db_name: "Proof of Africa - Enabling Web3 in Africa",
    db_url: "https://poapool.com",
    roa: "3.75",
    roa_lifetime: "4.559",
    total_stake: "2468303395057",
    stake_x_deleg: "5919192793.9017",
    saturated: "0.035905689550421",
    luck_lifetime: "102",
    blocks_epoch: "2",
    blocks_lifetime: "827",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ITN Certified/Ardana Stake Pool Alliance(ASPA)",
    icon: "https://cdn.adapools.org/pool_logo/e3f8a83cefed03fe4ebdc4f50f3cdd880a7c2fd0b2ce7ff9da9cdce2.png",
    pool_id: "e3f8a83cefed03fe4ebdc4f50f3cdd880a7c2fd0b2ce7ff9da9cdce2",
    db_ticker: "VOLCY",
    db_name: "ITN Certified/Ardana Stake Pool Alliance(ASPA)",
    db_url: "https://www.volcyada.com",
    roa: "2.946",
    roa_lifetime: "2.522",
    total_stake: "3331031575759",
    stake_x_deleg: "20561923307.154",
    saturated: "0.04845554476057",
    luck_lifetime: "79",
    blocks_epoch: "1",
    blocks_lifetime: "48",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Canada",
    icon: "https://cdn.adapools.org/pool_logo/0d8fb11358f653adc406c1508d27f9c1741c4eb06b75bef6d676489a.png",
    pool_id: "0d8fb11358f653adc406c1508d27f9c1741c4eb06b75bef6d676489a",
    db_ticker: "POLAR",
    db_name: "Cardano Canada",
    db_url: "https://cardanocanada.org",
    roa: "2.116",
    roa_lifetime: "4.366",
    total_stake: "2387637936914",
    stake_x_deleg: "5305862082.0311",
    saturated: "0.034732272658751",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "469",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANGEL stake pool #2",
    icon: "https://cdn.adapools.org/pool_logo/0000558092d1645b110130bb1b9d449dced79bfccf313e2880e6cf38.png",
    pool_id: "0000558092d1645b110130bb1b9d449dced79bfccf313e2880e6cf38",
    db_ticker: "SION",
    db_name: "ANGEL stake pool #2",
    db_url: "https://www.angelstakepool.net",
    roa: "3.588",
    roa_lifetime: "4.705",
    total_stake: "2171759773110",
    stake_x_deleg: "5775956843.3777",
    saturated: "0.031591955975728",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "642",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CO2-Pool - eco-friendly, green and climate-action",
    icon: "https://cdn.adapools.org/pool_logo/11cad31f95526b86724c84b818228fc2500bca5ce9b2864a85b94058.png",
    pool_id: "11cad31f95526b86724c84b818228fc2500bca5ce9b2864a85b94058",
    db_ticker: "CO2P",
    db_name: "CO2-Pool - eco-friendly, green and climate-action",
    db_url: "https://co2pool.com/",
    roa: "3.189",
    roa_lifetime: "4.32",
    total_stake: "2379547534442",
    stake_x_deleg: "10575766819.742",
    saturated: "0.034614583933743",
    luck_lifetime: "101",
    blocks_epoch: "4",
    blocks_lifetime: "623",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CarPool",
    icon: "https://cdn.adapools.org/pool_logo/138031b823a08dec4535e583ca8ea91530abd9c62b1c0b768fd1f834.png",
    pool_id: "138031b823a08dec4535e583ca8ea91530abd9c62b1c0b768fd1f834",
    db_ticker: "VROOM",
    db_name: "CarPool",
    db_url: "https://carpoolcrypto.com/",
    roa: "4.328",
    roa_lifetime: "4.55",
    total_stake: "3500452462306",
    stake_x_deleg: "14524699013.718",
    saturated: "0.05092006098167",
    luck_lifetime: "107",
    blocks_epoch: "2",
    blocks_lifetime: "343",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Open Source Software Fund",
    icon: "https://cdn.adapools.org/pool_logo/c04742e0e70c0f52950b465038ef97c7c7e6fe71a20bd88c1b655b63.png",
    pool_id: "c04742e0e70c0f52950b465038ef97c7c7e6fe71a20bd88c1b655b63",
    db_ticker: "LACE",
    db_name: "Open Source Software Fund",
    db_url: "https://lacepool.com",
    roa: "2.788",
    roa_lifetime: "4.164",
    total_stake: "2320822428874",
    stake_x_deleg: "17852480222.108",
    saturated: "0.033760326951574",
    luck_lifetime: "103",
    blocks_epoch: "3",
    blocks_lifetime: "480",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "75000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ALTZ",
    icon: "https://cdn.adapools.org/pool_logo/46ebbc0d27d9169cae12d12dcf8f6cacbcf27937c67ae4350abbcaba.png",
    pool_id: "46ebbc0d27d9169cae12d12dcf8f6cacbcf27937c67ae4350abbcaba",
    db_ticker: "ALTZ",
    db_name: "ALTZ",
    db_url: "https://altzpool.com",
    roa: "4.912",
    roa_lifetime: "3.949",
    total_stake: "2398094423445",
    stake_x_deleg: "37470225366.328",
    saturated: "0.034884380118443",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "282",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kangaroo Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/403c276d619cbcee266c2f8d73563905ffd8ac7889df11c478704301.png",
    pool_id: "403c276d619cbcee266c2f8d73563905ffd8ac7889df11c478704301",
    db_ticker: "KANGA",
    db_name: "Kangaroo Stake Pool",
    db_url: "https://kangaroo.onl",
    roa: "1.731",
    roa_lifetime: "4.431",
    total_stake: "6624087842299",
    stake_x_deleg: "414005490143.69",
    saturated: "0.096358673774304",
    luck_lifetime: "101",
    blocks_epoch: "6",
    blocks_lifetime: "352",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GimbalPool",
    icon: "https://cdn.adapools.org/pool_logo/dddce8834df02109524aeb5afa0fd820fc29920eca91a63551ecc26a.png",
    pool_id: "dddce8834df02109524aeb5afa0fd820fc29920eca91a63551ecc26a",
    db_ticker: "GMBL",
    db_name: "GimbalPool",
    db_url: "https://gimbalabs.com",
    roa: "4.089",
    roa_lifetime: "3.378",
    total_stake: "16252138803936",
    stake_x_deleg: "169293112541",
    saturated: "0.23641512287066",
    luck_lifetime: "91",
    blocks_epoch: "11",
    blocks_lifetime: "308",
    tax_ratio: "0.031416",
    tax_fix: "340000000",
    pledge: "18000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HERO POOL",
    icon: "0",
    pool_id: "259d8027c33ee454ec2319531269495c07fefcdb52a513c5090f59ed",
    db_ticker: "HERO",
    db_name: "HERO POOL",
    db_url: "https://herostakepool.com",
    roa: "3.226",
    roa_lifetime: "2.923",
    total_stake: "2110314723058",
    stake_x_deleg: "5538883787.5538",
    saturated: "0.030698132754484",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "138",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GetGigs.com",
    icon: "0",
    pool_id: "4351216c25d5acf6be12a9d5aa51c0f80f25e2ab299c73092e49aab9",
    db_ticker: "GIGS",
    db_name: "GetGigs.com",
    db_url: "https://www.getgigs.com",
    roa: "3.494",
    roa_lifetime: "4.278",
    total_stake: "2462859851808",
    stake_x_deleg: "82095328393.6",
    saturated: "0.035826503914512",
    luck_lifetime: "114",
    blocks_epoch: "3",
    blocks_lifetime: "237",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANO REPUBLIC - BY CRYPTO JEROME (FRANCE)",
    icon: "https://cdn.adapools.org/pool_logo/126ee7508a3ae2f88b5c2022eaf836ba766738ad84ce560ccbc87e85.png",
    pool_id: "126ee7508a3ae2f88b5c2022eaf836ba766738ad84ce560ccbc87e85",
    db_ticker: "CARE4",
    db_name: "CARDANO REPUBLIC - BY CRYPTO JEROME (FRANCE)",
    db_url: "https://www.cardano-republic.com",
    roa: "3.63",
    roa_lifetime: "4.254",
    total_stake: "2242263808123",
    stake_x_deleg: "10780114462.13",
    saturated: "0.032617557608938",
    luck_lifetime: "98",
    blocks_epoch: "2",
    blocks_lifetime: "464",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "222222000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SAPIO pool",
    icon: "0",
    pool_id: "c3cd2527a5f0b82a86ff69fdd5a3440dc51bcb957953ba19137738c0",
    db_ticker: "SAPIO",
    db_name: "SAPIO pool",
    db_url: "https://sapiopool.com",
    roa: "3.068",
    roa_lifetime: "3.061",
    total_stake: "3647518442392",
    stake_x_deleg: "5122919160.6629",
    saturated: "0.053059386898804",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "113",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SUNRISE",
    icon: "https://cdn.adapools.org/pool_logo/355986ef712d76569dffcaa0cd3b1ad426129ea5ba8baac4e5e5782d.png",
    pool_id: "355986ef712d76569dffcaa0cd3b1ad426129ea5ba8baac4e5e5782d",
    db_ticker: "SUN",
    db_name: "SUNRISE",
    db_url: "https://twitter.com/SUNRISE_POOL",
    roa: "4.448",
    roa_lifetime: "4.843",
    total_stake: "2129992921622",
    stake_x_deleg: "11703257811.11",
    saturated: "0.030984385769395",
    luck_lifetime: "103",
    blocks_epoch: "4",
    blocks_lifetime: "882",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Santo",
    icon: "https://cdn.adapools.org/pool_logo/9837e1eca79504f57acd4e12f2db93ee04a76bca308cae61a6ee205a.png",
    pool_id: "9837e1eca79504f57acd4e12f2db93ee04a76bca308cae61a6ee205a",
    db_ticker: "SANTO",
    db_name: "Santo",
    db_url: "https://santoelectronics.com/santonode",
    roa: "3.233",
    roa_lifetime: "3.304",
    total_stake: "4156610667336",
    stake_x_deleg: "17105393692.741",
    saturated: "0.060465003006605",
    luck_lifetime: "94",
    blocks_epoch: "4",
    blocks_lifetime: "151",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BOBA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6b3d0a2675d95b9c9e7861a97b2d3b84cdaea08dbcfc538d2d04ba2e.png",
    pool_id: "6b3d0a2675d95b9c9e7861a97b2d3b84cdaea08dbcfc538d2d04ba2e",
    db_ticker: "BOBA",
    db_name: "BOBA Stake Pool",
    db_url: "https://www.boba-pool.com/",
    roa: "3.585",
    roa_lifetime: "4.402",
    total_stake: "2189679930073",
    stake_x_deleg: "13600496460.081",
    saturated: "0.031852635272242",
    luck_lifetime: "100",
    blocks_epoch: "3",
    blocks_lifetime: "436",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NFT-MAKER",
    icon: "https://cdn.adapools.org/pool_logo/e815603425324aeaf98b5b3609f6ee6d618128046b6032febb48f950.png",
    pool_id: "e815603425324aeaf98b5b3609f6ee6d618128046b6032febb48f950",
    db_ticker: "NMKR",
    db_name: "NFT-MAKER",
    db_url: "https://www.nft-maker.io",
    roa: "3.832",
    roa_lifetime: "2.668",
    total_stake: "3649003834243",
    stake_x_deleg: "17131473400.202",
    saturated: "0.053080994460812",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "44",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Viper Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/d69b6b16c6a135c4157365ded9b0d772d44c7628a05b49741d3ae25c.png",
    pool_id: "d69b6b16c6a135c4157365ded9b0d772d44c7628a05b49741d3ae25c",
    db_ticker: "VIPER",
    db_name: "Viper Stake Pool",
    db_url: "https://viperstaking.com/ada-pools/viper-pool",
    roa: "3.902",
    roa_lifetime: "4.491",
    total_stake: "33916567517840",
    stake_x_deleg: "12057080525.361",
    saturated: "0.49337441513478",
    luck_lifetime: "98",
    blocks_epoch: "27",
    blocks_lifetime: "3012",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "355000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlocksWell",
    icon: "https://cdn.adapools.org/pool_logo/188d429c9e1b559b3ed53529ec4cc948d72e4a8f2e5849523bb789d9.png",
    pool_id: "188d429c9e1b559b3ed53529ec4cc948d72e4a8f2e5849523bb789d9",
    db_ticker: "WELL",
    db_name: "BlocksWell",
    db_url: "https://blockswell.com",
    roa: "1.364",
    roa_lifetime: "4.12",
    total_stake: "2402648698543",
    stake_x_deleg: "82849955122.172",
    saturated: "0.034950629829934",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "709",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "428000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC Cardano Stake World",
    icon: "0",
    pool_id: "c7c1d13803c3f76e714ffa9423e0fc07dd25ca929f743b16afe26526",
    db_ticker: "THCV",
    db_name: "THC Cardano Stake World",
    db_url: "https://thc-room335.com",
    roa: "4.384",
    roa_lifetime: "3.601",
    total_stake: "2759419037704",
    stake_x_deleg: "172463689856.5",
    saturated: "0.040140463893432",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "188",
    tax_ratio: "0.06",
    tax_fix: "350000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SHUA Stake Pool",
    icon: "0",
    pool_id: "123be4e0f074e9fa8efd7d5ad03fdb50f071dcac30378a6a55b2bea3",
    db_ticker: "SHUA",
    db_name: "SHUA Stake Pool",
    db_url: "https://www.youtube.com/channel/UCf64J3dPdVRT6OJf57m-uyw",
    roa: "2.749",
    roa_lifetime: "3.612",
    total_stake: "2205766199390",
    stake_x_deleg: "7279756433.6304",
    saturated: "0.032086637540066",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "40",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " ADA Skepsis ",
    icon: "https://cdn.adapools.org/pool_logo/a4b576a73fd6342c0ce8e44a040686b0e0ad54932df6724efd2364b3.png",
    pool_id: "a4b576a73fd6342c0ce8e44a040686b0e0ad54932df6724efd2364b3",
    db_ticker: "ASKP",
    db_name: " ADA Skepsis ",
    db_url: "https://www.skepsispool.com",
    roa: "2.575",
    roa_lifetime: "2.989",
    total_stake: "1869737939782",
    stake_x_deleg: "21008291458.225",
    saturated: "0.027198532457922",
    luck_lifetime: "91",
    blocks_epoch: "3",
    blocks_lifetime: "101",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COOL5",
    icon: "https://cdn.adapools.org/pool_logo/6371c7935c90ddb9b2bca3e5ae04d69711ec1bc28749393116c6d44c.png",
    pool_id: "6371c7935c90ddb9b2bca3e5ae04d69711ec1bc28749393116c6d44c",
    db_ticker: "COOL5",
    db_name: "COOL5",
    db_url: "https://www.stakecool.io",
    roa: "3.764",
    roa_lifetime: "4.829",
    total_stake: "17296200143847",
    stake_x_deleg: "30291068553.147",
    saturated: "0.25160277865784",
    luck_lifetime: "101",
    blocks_epoch: "9",
    blocks_lifetime: "4264",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Justice Pool",
    icon: "https://cdn.adapools.org/pool_logo/943fde2eb40fc5acdbc81c88230b56123231f83453eb72c023bc7707.png",
    pool_id: "943fde2eb40fc5acdbc81c88230b56123231f83453eb72c023bc7707",
    db_ticker: "CJ01",
    db_name: "Crypto Justice Pool",
    db_url: "https://cryptojustice.net",
    roa: "2.764",
    roa_lifetime: "4.651",
    total_stake: "2023858363470",
    stake_x_deleg: "4324483682.6282",
    saturated: "0.029440477308543",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "513",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aztec Staking",
    icon: "https://cdn.adapools.org/pool_logo/2119e61a82bde0dd3e41aaffe530229b98164831a6ea2f4875571163.png",
    pool_id: "2119e61a82bde0dd3e41aaffe530229b98164831a6ea2f4875571163",
    db_ticker: "AZTEC",
    db_name: "Aztec Staking",
    db_url: "https://aztecstaking.com/",
    roa: "1.641",
    roa_lifetime: "4.594",
    total_stake: "2029884920085",
    stake_x_deleg: "9184999638.3937",
    saturated: "0.029528143869838",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "800",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "112000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gold Coast ",
    icon: "0",
    pool_id: "00000a1e448bdd902bb3884d1df7f26efaec3afadfeb719f65cf0051",
    db_ticker: "COAST",
    db_name: "Gold Coast ",
    db_url: "https://www.goldcoaststaking.com",
    roa: "3.959",
    roa_lifetime: "4.477",
    total_stake: "2590626008645",
    stake_x_deleg: "199278923741.92",
    saturated: "0.037685080932081",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "238",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "STAKE for CAKE",
    icon: "https://cdn.adapools.org/pool_logo/b31af8dc07b05f799805f82853f69dd8d9de48bb89f4371bc46f5cc9.png",
    pool_id: "b31af8dc07b05f799805f82853f69dd8d9de48bb89f4371bc46f5cc9",
    db_ticker: "CAKE",
    db_name: "STAKE for CAKE",
    db_url: "https://stakeforcake.com",
    roa: "2.311",
    roa_lifetime: "3.744",
    total_stake: "2006873002490",
    stake_x_deleg: "6515821436.6558",
    saturated: "0.029193396216539",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "329",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "115000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chef Pool",
    icon: "https://cdn.adapools.org/pool_logo/682fd73eea4d7370b6f7a781c80b1252817b23f74688cea6257471f3.png",
    pool_id: "682fd73eea4d7370b6f7a781c80b1252817b23f74688cea6257471f3",
    db_ticker: "CHEF",
    db_name: "Chef Pool",
    db_url: "https://chefpool.org",
    roa: "2.77",
    roa_lifetime: "3.001",
    total_stake: "2042855873300",
    stake_x_deleg: "5853455224.3553",
    saturated: "0.02971682854298",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "74",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bullet Proof Investing Club",
    icon: "https://cdn.adapools.org/pool_logo/f37a8ad3edc8598b01a6630217349571c181404feb97c580925045d8.png",
    pool_id: "f37a8ad3edc8598b01a6630217349571c181404feb97c580925045d8",
    db_ticker: "BULPR",
    db_name: "Bullet Proof Investing Club",
    db_url: "https://bulletproofinvestingclub.com/index.html",
    roa: "1.987",
    roa_lifetime: "4.968",
    total_stake: "4215692361379",
    stake_x_deleg: "8154143832.4545",
    saturated: "0.061324447177313",
    luck_lifetime: "99",
    blocks_epoch: "4",
    blocks_lifetime: "2217",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "consensusMonky Stakepool Germany",
    icon: "https://cdn.adapools.org/pool_logo/a3ee9595fc779621978c97960b4835e14fd2dfea7fff28ccb977b56b.png",
    pool_id: "a3ee9595fc779621978c97960b4835e14fd2dfea7fff28ccb977b56b",
    db_ticker: "MONKY",
    db_name: "consensusMonky Stakepool Germany",
    db_url: "https://consensusmonky.de",
    roa: "4.632",
    roa_lifetime: "4.109",
    total_stake: "2055939238467",
    stake_x_deleg: "33703921942.082",
    saturated: "0.02990714844",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "337",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "365000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HADA.VN.COM",
    icon: "0",
    pool_id: "18109d01af0c5c4495a64a9de061ad621156729afc699128c0ceee0e",
    db_ticker: "HADA",
    db_name: "HADA.VN.COM",
    db_url: "http://hada.vn.com/",
    roa: "2.99",
    roa_lifetime: "3.88",
    total_stake: "2292914848279",
    stake_x_deleg: "99691949925.174",
    saturated: "0.033354363516546",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "140",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "P2P &amp; CEX",
    icon: "0",
    pool_id: "9dfeca095f80562460252072fd8438f08936ef5a2077583a9722bd97",
    db_ticker: "P2PCX",
    db_name: "P2P &amp; CEX",
    db_url: "https://p2p.org/",
    roa: "3.92",
    roa_lifetime: "4.52",
    total_stake: "27164908532353",
    stake_x_deleg: "11693890887.797",
    saturated: "0.39516000114958",
    luck_lifetime: "95",
    blocks_epoch: "13",
    blocks_lifetime: "4725",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano.Amsterdam",
    icon: "0",
    pool_id: "e11427a833aff02b782a0fd93b087bfacdb0414b7d5a545cd45bd8ed",
    db_ticker: "AMS01",
    db_name: "Cardano.Amsterdam",
    db_url: "https://cardano.amsterdam",
    roa: "2.94",
    roa_lifetime: "3.646",
    total_stake: "2195104548695",
    stake_x_deleg: "70809824151.452",
    saturated: "0.031931545617122",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "211",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adaly Stakepool",
    icon: "0",
    pool_id: "bed7baef960d110f25030bec52fbeccdea1800a00e41dd7dbc400b38",
    db_ticker: "ADALY",
    db_name: "Adaly Stakepool",
    db_url: "https://adaly.io",
    roa: "2.789",
    roa_lifetime: "3.978",
    total_stake: "2946427582167",
    stake_x_deleg: "147321379108.35",
    saturated: "0.042860822644391",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "355",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CashFlow",
    icon: "https://cdn.adapools.org/pool_logo/e7b605b72af41d6e8e6894274dedd18114f1759fea500b6d07031535.png",
    pool_id: "e7b605b72af41d6e8e6894274dedd18114f1759fea500b6d07031535",
    db_ticker: "CFLOW",
    db_name: "CashFlow",
    db_url: "https://www.cflowpool.com/",
    roa: "3.784",
    roa_lifetime: "4.839",
    total_stake: "21578464544362",
    stake_x_deleg: "11830298544.058",
    saturated: "0.31389562987119",
    luck_lifetime: "101",
    blocks_epoch: "17",
    blocks_lifetime: "4729",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SCARdano",
    icon: "0",
    pool_id: "e56066e8cf0409ecb3f25aaac5cc97099af29e7a79609c6fe4d61e5d",
    db_ticker: "SCAR",
    db_name: "SCARdano",
    db_url: "https://scardano.io/",
    roa: "3.098",
    roa_lifetime: "4.878",
    total_stake: "11696091377646",
    stake_x_deleg: "17829407587.875",
    saturated: "0.17013962983647",
    luck_lifetime: "98",
    blocks_epoch: "6",
    blocks_lifetime: "4226",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Waldmops",
    icon: "0",
    pool_id: "b3cdd3bbf09af72badca85e515c0206d6d22038e2af843521e10a928",
    db_ticker: "WMOPS",
    db_name: "Waldmops",
    db_url: "https://waldmops.nvmr.de",
    roa: "4.372",
    roa_lifetime: "4.44",
    total_stake: "15959694193751",
    stake_x_deleg: "19875086169.055",
    saturated: "0.2321610164245",
    luck_lifetime: "93",
    blocks_epoch: "17",
    blocks_lifetime: "2104",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Allnodes",
    icon: "https://cdn.adapools.org/pool_logo/6f223af7c6069f7428cf7605efba84ec410f5476550f83dbb3096238.png",
    pool_id: "6f223af7c6069f7428cf7605efba84ec410f5476550f83dbb3096238",
    db_ticker: "ALND",
    db_name: "Allnodes",
    db_url: "https://www.allnodes.com/ada/staking",
    roa: "2.39",
    roa_lifetime: "3.096",
    total_stake: "1998704131643",
    stake_x_deleg: "13596626745.871",
    saturated: "0.029074566034967",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "157",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/2656fab6a4b043ede4fcc2a46523e6198e7b2cbcbed9a966fe35b6be.png",
    pool_id: "2656fab6a4b043ede4fcc2a46523e6198e7b2cbcbed9a966fe35b6be",
    db_ticker: "HODL",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "2.458",
    roa_lifetime: "4.469",
    total_stake: "1938868467350",
    stake_x_deleg: "14914372825.769",
    saturated: "0.028204154078944",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "533",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PARIS Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/310daa501ac5ead2953ccb9cbb35d23516c48de31019b766fb560229.png",
    pool_id: "310daa501ac5ead2953ccb9cbb35d23516c48de31019b766fb560229",
    db_ticker: "PARIS",
    db_name: "PARIS Stake Pool",
    db_url: "https://parisadastakepool.fr/",
    roa: "3.235",
    roa_lifetime: "4.314",
    total_stake: "1910171174245",
    stake_x_deleg: "25813123976.284",
    saturated: "0.027786702926372",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "572",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "1555555555555",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adavanz",
    icon: "https://cdn.adapools.org/pool_logo/2f4e768d6769a2ee28b93d198f974e9424b2b3f3fca8b1d48d90007d.png",
    pool_id: "2f4e768d6769a2ee28b93d198f974e9424b2b3f3fca8b1d48d90007d",
    db_ticker: "ADAVZ",
    db_name: "Adavanz",
    db_url: "https://adavanz.com",
    roa: "1.15",
    roa_lifetime: "4.017",
    total_stake: "1917052837632",
    stake_x_deleg: "33052635131.586",
    saturated: "0.027886808476467",
    luck_lifetime: "90",
    blocks_epoch: "1",
    blocks_lifetime: "319",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1200000308687",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adaseal.eu",
    icon: "https://cdn.adapools.org/pool_logo/7d59eb08203e86f74d3011c7e967ed50fbd0954001eab1cb00b69422.png",
    pool_id: "7d59eb08203e86f74d3011c7e967ed50fbd0954001eab1cb00b69422",
    db_ticker: "SEAL",
    db_name: "adaseal.eu",
    db_url: "https://adaseal.eu",
    roa: "3.42",
    roa_lifetime: "4.008",
    total_stake: "2408731405394",
    stake_x_deleg: "16385927927.85",
    saturated: "0.035039113192334",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "260",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "32000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GHISX POOL",
    icon: "https://cdn.adapools.org/pool_logo/291173f43cc28027f0b61d5d22825189aeef3e7e128fa37fef46beb4.png",
    pool_id: "291173f43cc28027f0b61d5d22825189aeef3e7e128fa37fef46beb4",
    db_ticker: "GHISX",
    db_name: "GHISX POOL",
    db_url: "https://ghisx-pool.com",
    roa: "6.43",
    roa_lifetime: "4.922",
    total_stake: "1955320255990",
    stake_x_deleg: "55866293028.286",
    saturated: "0.028443473449748",
    luck_lifetime: "110",
    blocks_epoch: "1",
    blocks_lifetime: "507",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wired",
    icon: "https://cdn.adapools.org/pool_logo/72af8398023064ef34ce06a961ec1e28a0e631bb822070bead939676.png",
    pool_id: "72af8398023064ef34ce06a961ec1e28a0e631bb822070bead939676",
    db_ticker: "WIRED",
    db_name: "Wired",
    db_url: "https://www.wiredfoundation.org",
    roa: "4.491",
    roa_lifetime: "4.273",
    total_stake: "1915468468841",
    stake_x_deleg: "51769418076.784",
    saturated: "0.027863761125782",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "399",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "YOKOHAMA stake pool",
    icon: "0",
    pool_id: "9e85e1a7ca55f208d18a699905a5d9121ab3e18fda56165465974917",
    db_ticker: "YKSP",
    db_name: "YOKOHAMA stake pool",
    db_url: "https://yokohamapool.com/",
    roa: "2.435",
    roa_lifetime: "4.261",
    total_stake: "2328790806169",
    stake_x_deleg: "258754534018.78",
    saturated: "0.033876240611923",
    luck_lifetime: "106",
    blocks_epoch: "1",
    blocks_lifetime: "302",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEWM Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/926b65d00c21aa1fb19e44faa2293e342578ed6a80aca4a551191a70.png",
    pool_id: "926b65d00c21aa1fb19e44faa2293e342578ed6a80aca4a551191a70",
    db_ticker: "NEWM",
    db_name: "NEWM Stake Pool",
    db_url: "https://projectNEWM.io",
    roa: "3.182",
    roa_lifetime: "3.649",
    total_stake: "38490035590885",
    stake_x_deleg: "35182847889.292",
    saturated: "0.55990332123618",
    luck_lifetime: "100",
    blocks_epoch: "38",
    blocks_lifetime: "1453",
    tax_ratio: "0.25",
    tax_fix: "340000000",
    pledge: "450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Switzerland Investment",
    icon: "https://cdn.adapools.org/pool_logo/01df29429173d263c7533a22742dae19f16a08798b7a57873c34cf58.png",
    pool_id: "01df29429173d263c7533a22742dae19f16a08798b7a57873c34cf58",
    db_ticker: "000",
    db_name: "Switzerland Investment",
    db_url: "https://ispool.live/",
    roa: "5.213",
    roa_lifetime: "4.267",
    total_stake: "1802082291517",
    stake_x_deleg: "2623118328.2635",
    saturated: "0.026214365475937",
    luck_lifetime: "103",
    blocks_epoch: "3",
    blocks_lifetime: "318",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alliance",
    icon: "https://cdn.adapools.org/pool_logo/e01133b97103bb932f7be0f7896ed1ca1d0ee350e4c8058e101b73d6.png",
    pool_id: "e01133b97103bb932f7be0f7896ed1ca1d0ee350e4c8058e101b73d6",
    db_ticker: "ALLI",
    db_name: "Alliance",
    db_url: "https://alliancestakepools.online",
    roa: "2.172",
    roa_lifetime: "4.352",
    total_stake: "1942132721306",
    stake_x_deleg: "6031468078.5901",
    saturated: "0.028251638229148",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "480",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "110000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Right Stake Pool",
    icon: "0",
    pool_id: "5370e250fa08c07881085a59d339a1240ad7561f513b71b64491a001",
    db_ticker: "RIGHT",
    db_name: "Right Stake Pool",
    db_url: "https://rightstakepool.com",
    roa: "5.717",
    roa_lifetime: "3.593",
    total_stake: "1916744869434",
    stake_x_deleg: "53242913039.833",
    saturated: "0.027882328552917",
    luck_lifetime: "111",
    blocks_epoch: "2",
    blocks_lifetime: "95",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "757000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " SHARE - For a good cause &amp; ADA rewards",
    icon: "https://cdn.adapools.org/pool_logo/ea7cdd8918204b6c53e41ac6b0c319a1ffa95312660eeeb2ced8be3a.png",
    pool_id: "ea7cdd8918204b6c53e41ac6b0c319a1ffa95312660eeeb2ced8be3a",
    db_ticker: "SHARE",
    db_name: " SHARE - For a good cause &amp; ADA rewards",
    db_url: "https://share-pool.com",
    roa: "2.449",
    roa_lifetime: "3.895",
    total_stake: "1803846543742",
    stake_x_deleg: "18790068163.979",
    saturated: "0.026240029538469",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "231",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "201000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WILL Pool",
    icon: "0",
    pool_id: "335d9516b35b276cce8a643bd1fb2d304d95de156b21b3d43bae2e40",
    db_ticker: "WILL",
    db_name: "WILL Pool",
    db_url: "https://staking-fm.site",
    roa: "3.214",
    roa_lifetime: "3.999",
    total_stake: "8906798007382",
    stake_x_deleg: "809708909762",
    saturated: "0.12956459274083",
    luck_lifetime: "98",
    blocks_epoch: "13",
    blocks_lifetime: "2908",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC Cardano Stake World",
    icon: "0",
    pool_id: "a6bda93bfbd23b3c252282a482b1343385b804965bbd46feb6ef3048",
    db_ticker: "THC3",
    db_name: "THC Cardano Stake World",
    db_url: "https://thc-room335.com",
    roa: "6.862",
    roa_lifetime: "3.111",
    total_stake: "2799821685025",
    stake_x_deleg: "199987263216.07",
    saturated: "0.040728189419649",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "115",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEOPool",
    icon: "0",
    pool_id: "f83cb7810af45813ad4e16f2497a0d02997e2987f4a41fa3830a4d88",
    db_ticker: "LEO",
    db_name: "LEOPool",
    db_url: "https://staking-fm.site",
    roa: "3.207",
    roa_lifetime: "3.979",
    total_stake: "11419046972586",
    stake_x_deleg: "878388228660.46",
    saturated: "0.16610954568244",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "4960",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaCryptoPool2",
    icon: "0",
    pool_id: "688548655448745d74c4b61da7383127ea9b56b2c6944ca7e3071893",
    db_ticker: "ACP2",
    db_name: "AdaCryptoPool2",
    db_url: "https://adacryptopool.com/",
    roa: "3.96",
    roa_lifetime: "3.793",
    total_stake: "2078986676007",
    stake_x_deleg: "159922052000.54",
    saturated: "0.030242412791579",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "248",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EARTH",
    icon: "0",
    pool_id: "0915c3599c9155abd550abdc6fab4dbbd08e80e7532bb7decd29767d",
    db_ticker: "EARTH",
    db_name: "EARTH",
    db_url: "https://www.adainground.com",
    roa: "1.656",
    roa_lifetime: "3.783",
    total_stake: "2169758143146",
    stake_x_deleg: "77491362255.214",
    saturated: "0.031562838848463",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "263",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "100098000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "One Community ADA",
    icon: "https://cdn.adapools.org/pool_logo/75f7b7dec6e4c973407a6a64132961635f405f2c3a9ff31598db01da.png",
    pool_id: "75f7b7dec6e4c973407a6a64132961635f405f2c3a9ff31598db01da",
    db_ticker: "1COMM",
    db_name: "One Community ADA",
    db_url: "https://www.onecommunityada.com",
    roa: "3.533",
    roa_lifetime: "3.729",
    total_stake: "1683155977267",
    stake_x_deleg: "9049225684.2312",
    saturated: "0.024484379070138",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "168",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "402000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GENKIPOOL",
    icon: "https://cdn.adapools.org/pool_logo/e7cc5cce7c9a06bc7310f8c222c702cd12d6fd05d5dee748f10e9218.png",
    pool_id: "e7cc5cce7c9a06bc7310f8c222c702cd12d6fd05d5dee748f10e9218",
    db_ticker: "GENKI",
    db_name: "GENKIPOOL",
    db_url: "https://genkipool.com/",
    roa: "3.872",
    roa_lifetime: "5.129",
    total_stake: "2889812820389",
    stake_x_deleg: "17728913008.521",
    saturated: "0.042037264217804",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "441",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "253467828509",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ArmADA StakePool",
    icon: "https://cdn.adapools.org/pool_logo/a2ccd8e93f5a518d4400790cb081673e79ed41e4bf1132f740f26a28.png",
    pool_id: "a2ccd8e93f5a518d4400790cb081673e79ed41e4bf1132f740f26a28",
    db_ticker: "ARM1",
    db_name: "ArmADA StakePool",
    db_url: "https://www.armadastakepool.com",
    roa: "3.282",
    roa_lifetime: "4.173",
    total_stake: "1734725338421",
    stake_x_deleg: "6802844464.3961",
    saturated: "0.025234543525455",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "688",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "688000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Green Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/34a89f77c5fcf0563d129bde9b255f20b269a2dda1561c65d418ec40.png",
    pool_id: "34a89f77c5fcf0563d129bde9b255f20b269a2dda1561c65d418ec40",
    db_ticker: "GREEN",
    db_name: "Green Stake Pool",
    db_url: "https://greenstakepool.com",
    roa: "3.062",
    roa_lifetime: "4.012",
    total_stake: "1735040005430",
    stake_x_deleg: "6751128425.7977",
    saturated: "0.025239120894655",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "394",
    tax_ratio: "0.0185",
    tax_fix: "340000000",
    pledge: "206000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardaspians",
    icon: "https://cdn.adapools.org/pool_logo/7ac19e69af887439f7e0fb1b0bec30fc8dd02b0f73b4d1f41a36cff4.png",
    pool_id: "7ac19e69af887439f7e0fb1b0bec30fc8dd02b0f73b4d1f41a36cff4",
    db_ticker: "CASP",
    db_name: "Cardaspians",
    db_url: "https://cardaspians.io",
    roa: "3.129",
    roa_lifetime: "4.687",
    total_stake: "1726817778118",
    stake_x_deleg: "17620589572.633",
    saturated: "0.025119514552149",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "491",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Star Forge ",
    icon: "https://cdn.adapools.org/pool_logo/c825168836c5bf850dec38567eb4771c2e03eea28658ff291df768ae.png",
    pool_id: "c825168836c5bf850dec38567eb4771c2e03eea28658ff291df768ae",
    db_ticker: "OTG",
    db_name: " Star Forge ",
    db_url: "https://adamantium.online",
    roa: "4.243",
    roa_lifetime: "4.283",
    total_stake: "1675716097341",
    stake_x_deleg: "15515889790.194",
    saturated: "0.024376153306867",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "435",
    tax_ratio: "0.0125",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Israel",
    icon: "https://cdn.adapools.org/pool_logo/5121819e5114235cb8c377ecf0a9d4e311898c447043e3b5b2848f63.png",
    pool_id: "5121819e5114235cb8c377ecf0a9d4e311898c447043e3b5b2848f63",
    db_ticker: "ISR",
    db_name: "Cardano Israel",
    db_url: "https://www.cardano-israel.com",
    roa: "1.5",
    roa_lifetime: "3.547",
    total_stake: "2788491281696",
    stake_x_deleg: "5565850861.6687",
    saturated: "0.040563369347195",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "266",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kurdopia Pool",
    icon: "https://cdn.adapools.org/pool_logo/f9f646eb4b04adea5afee58b63cc9f53e0da82118c2ab10a6557b922.png",
    pool_id: "f9f646eb4b04adea5afee58b63cc9f53e0da82118c2ab10a6557b922",
    db_ticker: "KURD",
    db_name: "Kurdopia Pool",
    db_url: "https://kurdopia.store",
    roa: "3.142",
    roa_lifetime: "4.107",
    total_stake: "1759998386489",
    stake_x_deleg: "17777761479.687",
    saturated: "0.025602183184234",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "281",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stakhanovite #1",
    icon: "0",
    pool_id: "b62ecc8ce7e46c4443b63b91fffaeb19f869d191a7d2381087aaa768",
    db_ticker: "STKH1",
    db_name: "Stakhanovite #1",
    db_url: "https://www.stakhanovite.io",
    roa: "3.391",
    roa_lifetime: "4.852",
    total_stake: "1849681060525",
    stake_x_deleg: "8112636230.3728",
    saturated: "0.02690677088542",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "4646",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeADA Pool",
    icon: "0",
    pool_id: "d68eed68381b65beb04fc7984dfa156c4dbf53c7911e900af6a535ef",
    db_ticker: "KARMA",
    db_name: "StakeADA Pool",
    db_url: "https://stakeada.com",
    roa: "5.448",
    roa_lifetime: "4.303",
    total_stake: "2040331915673",
    stake_x_deleg: "20609413289.626",
    saturated: "0.02968011326755",
    luck_lifetime: "104",
    blocks_epoch: "2",
    blocks_lifetime: "241",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lucky Pool",
    icon: "0",
    pool_id: "4194112bdb4702d01263a5614f48790e23351c867bbe67ca840dbb4a",
    db_ticker: "LUCKY",
    db_name: "Lucky Pool",
    db_url: "https://lucky-pool.com",
    roa: "2.271",
    roa_lifetime: "3.591",
    total_stake: "1996367875749",
    stake_x_deleg: "142597705410.64",
    saturated: "0.029040581201899",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "226",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Odin's Pool",
    icon: "https://cdn.adapools.org/pool_logo/c77e03da421b0278fcef1ad88df3fde43e5282ab926c257c2a579962.png",
    pool_id: "c77e03da421b0278fcef1ad88df3fde43e5282ab926c257c2a579962",
    db_ticker: "ODIN",
    db_name: "Odin's Pool",
    db_url: "https://odin.baselinux.net",
    roa: "5.423",
    roa_lifetime: "4.526",
    total_stake: "1567679133365",
    stake_x_deleg: "2488379576.7698",
    saturated: "0.022804571103374",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "576",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "160000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JUNO stake pool",
    icon: "https://cdn.adapools.org/pool_logo/765e6963038b3152067bb8a08707d0306045f0ed5b1f02641b58fff6.png",
    pool_id: "765e6963038b3152067bb8a08707d0306045f0ed5b1f02641b58fff6",
    db_ticker: "JUNO",
    db_name: "JUNO stake pool",
    db_url: "https://junostakepool.com",
    roa: "2.148",
    roa_lifetime: "4.307",
    total_stake: "1615729716704",
    stake_x_deleg: "10357241773.744",
    saturated: "0.023503548924148",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "627",
    tax_ratio: "0.0145",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "YOADA_STAKEPOOL",
    icon: "https://cdn.adapools.org/pool_logo/96553a437b99271fdcaa30c279e432224342e88147741db73872f03c.png",
    pool_id: "96553a437b99271fdcaa30c279e432224342e88147741db73872f03c",
    db_ticker: "YOADA",
    db_name: "YOADA_STAKEPOOL",
    db_url: "https://www.yoada.fr",
    roa: "2.547",
    roa_lifetime: "3.883",
    total_stake: "1599181189312",
    stake_x_deleg: "6320874266.0553",
    saturated: "0.023262822322935",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "224",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chichen Itza",
    icon: "https://cdn.adapools.org/pool_logo/c29dd3c55a4f3f3b02aae6463ef8e1e66a70b5d000f8a6dc3430ef04.png",
    pool_id: "c29dd3c55a4f3f3b02aae6463ef8e1e66a70b5d000f8a6dc3430ef04",
    db_ticker: "ITZA",
    db_name: "Chichen Itza",
    db_url: "https://latinstakepools.com/",
    roa: "1.366",
    roa_lifetime: "3.864",
    total_stake: "1923135139158",
    stake_x_deleg: "7541706428.0706",
    saturated: "0.027975285942723",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "155",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePools Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/e66b34646251e760c1701d0348819fa73e08c97bffe55d91ed7cd472.png",
    pool_id: "e66b34646251e760c1701d0348819fa73e08c97bffe55d91ed7cd472",
    db_ticker: "STP",
    db_name: "StakePools Cardano Pool",
    db_url: "https://cardano.stakepools.info",
    roa: "1.356",
    roa_lifetime: "3.873",
    total_stake: "9903527120339",
    stake_x_deleg: "412646963347.46",
    saturated: "0.14406372042804",
    luck_lifetime: "89",
    blocks_epoch: "10",
    blocks_lifetime: "742",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Love2Stake",
    icon: "https://cdn.adapools.org/pool_logo/65a6837264ea1d99e8af24e3a0f54cd4b3821919eb3e59b3a56b857e.png",
    pool_id: "65a6837264ea1d99e8af24e3a0f54cd4b3821919eb3e59b3a56b857e",
    db_ticker: "LOVE2",
    db_name: "Love2Stake",
    db_url: "https://love2stake.com",
    roa: "1.885",
    roa_lifetime: "3.395",
    total_stake: "1615388859500",
    stake_x_deleg: "20447960246.835",
    saturated: "0.02349859057382",
    luck_lifetime: "99",
    blocks_epoch: "3",
    blocks_lifetime: "133",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LovelaceCommunityPool",
    icon: "0",
    pool_id: "bcc34d3c45cd3b8770c75c91c3023a9146aa505c4bd5cf094dae9acc",
    db_ticker: "LCP",
    db_name: "LovelaceCommunityPool",
    db_url: "https://lovelace.community",
    roa: "3.847",
    roa_lifetime: "4.251",
    total_stake: "1683009885187",
    stake_x_deleg: "16182787357.567",
    saturated: "0.024482253911261",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "378",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "333333333333",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dot Stake Pool",
    icon: "0",
    pool_id: "28c5a664f316b8c017c4805d78a58bf39f16c2509ed2bd37878dd8bc",
    db_ticker: "DGK",
    db_name: "Dot Stake Pool",
    db_url: "https://dot-stake-pool.amebaownd.com/",
    roa: "1.792",
    roa_lifetime: "3.106",
    total_stake: "2202978764406",
    stake_x_deleg: "244775418267.33",
    saturated: "0.032046089536374",
    luck_lifetime: "96",
    blocks_epoch: "3",
    blocks_lifetime: "101",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "【DEE Stake Pool】",
    icon: "https://cdn.adapools.org/pool_logo/4bdfe221a4313f1b4a0548cf5d680f07e20ddf0a20c6f2546d32e30a.png",
    pool_id: "4bdfe221a4313f1b4a0548cf5d680f07e20ddf0a20c6f2546d32e30a",
    db_ticker: "DEE",
    db_name: "【DEE Stake Pool】",
    db_url: "https://www.cardano-ada-staking.com/",
    roa: "1.599",
    roa_lifetime: "3.837",
    total_stake: "1625206435615",
    stake_x_deleg: "65008257424.6",
    saturated: "0.023641403990043",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "226",
    tax_ratio: "0.0075",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano VET Pool",
    icon: "https://cdn.adapools.org/pool_logo/72b90b4cce444f1ede43323879d8bbed7219801b2928a736df89dfa5.png",
    pool_id: "72b90b4cce444f1ede43323879d8bbed7219801b2928a736df89dfa5",
    db_ticker: "VET",
    db_name: "Cardano VET Pool",
    db_url: "https://vet05.webnode.kr",
    roa: "4.177",
    roa_lifetime: "3.789",
    total_stake: "1703299458784",
    stake_x_deleg: "81109498037.333",
    saturated: "0.024777400420456",
    luck_lifetime: "123",
    blocks_epoch: "1",
    blocks_lifetime: "47",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SOUL Cardano Pool",
    icon: "0",
    pool_id: "3866bed6c94a75ab0290bc86d83467c6557cf2275e8d49b3d727c78c",
    db_ticker: "SOUL",
    db_name: "SOUL Cardano Pool",
    db_url: "https://cloudboom.io",
    roa: "1.925",
    roa_lifetime: "3.062",
    total_stake: "1751320227654",
    stake_x_deleg: "103018836920.82",
    saturated: "0.025475944538846",
    luck_lifetime: "94",
    blocks_epoch: "3",
    blocks_lifetime: "99",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NOORNFT Art Market Place.",
    icon: "https://cdn.adapools.org/pool_logo/3199811db9dd4dc48a7eab144f261e7976974561017515dc48a66749.png",
    pool_id: "3199811db9dd4dc48a7eab144f261e7976974561017515dc48a66749",
    db_ticker: "DUBAI",
    db_name: "NOORNFT Art Market Place.",
    db_url: "https://twitter.com/io_natasha",
    roa: "4.11",
    roa_lifetime: "4.654",
    total_stake: "1626438112051",
    stake_x_deleg: "12806599307.488",
    saturated: "0.023659320827911",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "680",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zeitgeist Cardano Pool",
    icon: "0",
    pool_id: "cb0e97271899d0378b028f08183d6fde0794e3502d1d2c6bb96c668c",
    db_ticker: "ZEIT",
    db_name: "Zeitgeist Cardano Pool",
    db_url: "https://zeitgeistpool.com",
    roa: "3.292",
    roa_lifetime: "4.365",
    total_stake: "1039366131399",
    stake_x_deleg: "3982245714.1724",
    saturated: "0.015119355958416",
    luck_lifetime: "102",
    blocks_epoch: "2",
    blocks_lifetime: "405",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "31000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FintruX Staked Financing",
    icon: "0",
    pool_id: "6523c0a727664d2ce8180e0dc3d72f1b98abc0d9ecd7cd065b0b9e5f",
    db_ticker: "TRUX",
    db_name: "FintruX Staked Financing",
    db_url: "https://staked.finance",
    roa: "1.799",
    roa_lifetime: "4.007",
    total_stake: "2223175946889",
    stake_x_deleg: "317596563841.29",
    saturated: "0.032339892058981",
    luck_lifetime: "107",
    blocks_epoch: "3",
    blocks_lifetime: "249",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lambda StakePool",
    icon: "https://cdn.adapools.org/pool_logo/d5a7362f2270b680cf8763e2ebaf44af3db7271e00b44512da53abd8.png",
    pool_id: "d5a7362f2270b680cf8763e2ebaf44af3db7271e00b44512da53abd8",
    db_ticker: "LAMB",
    db_name: "Lambda StakePool",
    db_url: "https://lambdastake.com",
    roa: "2.786",
    roa_lifetime: "4.561",
    total_stake: "1448380713757",
    stake_x_deleg: "2812389735.4505",
    saturated: "0.021069171789465",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "546",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NSPYR POOL",
    icon: "https://cdn.adapools.org/pool_logo/45faad3e1ab98f4a06a54e3d1a3bc70bed80d6e437ce8f9361a5a9be.png",
    pool_id: "45faad3e1ab98f4a06a54e3d1a3bc70bed80d6e437ce8f9361a5a9be",
    db_ticker: "NSPYR",
    db_name: "NSPYR POOL",
    db_url: "https://nspyrpool.com",
    roa: "1.715",
    roa_lifetime: "4.276",
    total_stake: "1523519240969",
    stake_x_deleg: "4644875734.6616",
    saturated: "0.022162190028938",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "480",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "0% fees - HOSKY ISPO (Rug Pool) - QCPOL Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c2b8bff5160dd75149f2cae0955698550e8cf0d390025b26a9508a3e.png",
    pool_id: "c2b8bff5160dd75149f2cae0955698550e8cf0d390025b26a9508a3e",
    db_ticker: "QCPOL",
    db_name: "0% fees - HOSKY ISPO (Rug Pool) - QCPOL Stake Pool",
    db_url: "https://qcpol.stakepool.quebec",
    roa: "2.409",
    roa_lifetime: "3.706",
    total_stake: "2365088648604",
    stake_x_deleg: "6855329416.2435",
    saturated: "0.034404254738724",
    luck_lifetime: "106",
    blocks_epoch: "1",
    blocks_lifetime: "150",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Suisse ",
    icon: "https://cdn.adapools.org/pool_logo/71499ef7ad908b4d456955fd6eac7fe4986991cde9f3f38b5abbd58b.png",
    pool_id: "71499ef7ad908b4d456955fd6eac7fe4986991cde9f3f38b5abbd58b",
    db_ticker: "SUI",
    db_name: "ADA Suisse ",
    db_url: "https://adasuisse.io",
    roa: "2.246",
    roa_lifetime: "2.486",
    total_stake: "1850770983952",
    stake_x_deleg: "10397589797.483",
    saturated: "0.026922625683612",
    luck_lifetime: "76",
    blocks_epoch: "3",
    blocks_lifetime: "86",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "31000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NANI Pool",
    icon: "0",
    pool_id: "d0deba25ce9e6ff59af7f39305fce373241156616cdf0a49b1d49e48",
    db_ticker: "NANI",
    db_name: "NANI Pool",
    db_url: "https://nanipool.com",
    roa: "3.85",
    roa_lifetime: "4.413",
    total_stake: "15554030759349",
    stake_x_deleg: "501742927720.94",
    saturated: "0.22625994876533",
    luck_lifetime: "102",
    blocks_epoch: "16",
    blocks_lifetime: "448",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "190833000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockchain Better Have My Money",
    icon: "https://cdn.adapools.org/pool_logo/7874e696cf4baca102c098067ca483f2370d2bdc958ad322dadf650b.png",
    pool_id: "7874e696cf4baca102c098067ca483f2370d2bdc958ad322dadf650b",
    db_ticker: "BBHMM",
    db_name: "Blockchain Better Have My Money",
    db_url: "https://www.bbhmm.net/",
    roa: "2.416",
    roa_lifetime: "3.485",
    total_stake: "1456849149584",
    stake_x_deleg: "15175511974.833",
    saturated: "0.021192359655426",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "222",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "550000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nimuë Lady of the Lake Pool",
    icon: "https://cdn.adapools.org/pool_logo/68d8505aea5a000e630f904f8741c1deec9135a729e5df770fe3b659.png",
    pool_id: "68d8505aea5a000e630f904f8741c1deec9135a729e5df770fe3b659",
    db_ticker: "NIMUE",
    db_name: "Nimuë Lady of the Lake Pool",
    db_url: "https://nimuepool.io",
    roa: "6.508",
    roa_lifetime: "4.002",
    total_stake: "1451484217606",
    stake_x_deleg: "21663943546.358",
    saturated: "0.021114317554748",
    luck_lifetime: "110",
    blocks_epoch: "1",
    blocks_lifetime: "165",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "800008000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SGEN Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/40960205ad46c9883d32eaf4ab512ab2ea2962d5fd52a4a470df5125.png",
    pool_id: "40960205ad46c9883d32eaf4ab512ab2ea2962d5fd52a4a470df5125",
    db_ticker: "SGEN",
    db_name: "SGEN Stake Pool",
    db_url: "https://genesis.space/",
    roa: "2.701",
    roa_lifetime: "3.107",
    total_stake: "1828137580181",
    stake_x_deleg: "261162511454.43",
    saturated: "0.026593384160508",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "27",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sparta Stake Pool",
    icon: "0",
    pool_id: "e6e1f4291b1e6a29c8f7818a6572bdbd50971d1c657725de72466365",
    db_ticker: "SPRTA",
    db_name: "Sparta Stake Pool",
    db_url: "https://spartasp.com",
    roa: "1.7",
    roa_lifetime: "4.373",
    total_stake: "1525179425825",
    stake_x_deleg: "63549142742.708",
    saturated: "0.022186340253807",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "399",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "705000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swiss Staking",
    icon: "0",
    pool_id: "feaf6e416cd79649df5a7575b240b5740d7735a80a8f688e8b73bf06",
    db_ticker: "CASCH",
    db_name: "Swiss Staking",
    db_url: "https://www.cardano-staking.ch/",
    roa: "4.376",
    roa_lifetime: "4.416",
    total_stake: "1541614296333",
    stake_x_deleg: "61664571853.32",
    saturated: "0.022425413521479",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "328",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GAIA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/f66a36de855d53b9d5110bd46640f48dbab1c848c9e2f5c36d8c12dc.png",
    pool_id: "f66a36de855d53b9d5110bd46640f48dbab1c848c9e2f5c36d8c12dc",
    db_ticker: "GAIA",
    db_name: "GAIA Stake Pool",
    db_url: "https://www.gaiastakepool.com",
    roa: "3.476",
    roa_lifetime: "3.865",
    total_stake: "2883777375530",
    stake_x_deleg: "9424109070.3595",
    saturated: "0.041949468361816",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "401",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pyur Ada Pool",
    icon: "0",
    pool_id: "5175ee9d87674546f82c0deed9ec0f87c68ab6337b83ef2928dbeb95",
    db_ticker: "PYUR",
    db_name: "Pyur Ada Pool",
    db_url: "https://pyur.io",
    roa: "1.703",
    roa_lifetime: "3.047",
    total_stake: "1765778464505",
    stake_x_deleg: "98098803583.611",
    saturated: "0.02568626429324",
    luck_lifetime: "85",
    blocks_epoch: "2",
    blocks_lifetime: "142",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ABC Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/478bc2098b2ef7117a4dc1a618ea75ca37516be7c6a65fab763f72a2.png",
    pool_id: "478bc2098b2ef7117a4dc1a618ea75ca37516be7c6a65fab763f72a2",
    db_ticker: "ABC",
    db_name: "ABC Stake Pool",
    db_url: "https://abcstake.com/",
    roa: "3.842",
    roa_lifetime: "4.047",
    total_stake: "18162174321974",
    stake_x_deleg: "21045393188.846",
    saturated: "0.26419985244576",
    luck_lifetime: "99",
    blocks_epoch: "20",
    blocks_lifetime: "629",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BavarianStakePool",
    icon: "https://cdn.adapools.org/pool_logo/da7659a5e9c29d69e0b5698996cc916197b6556fa7460f604434368f.png",
    pool_id: "da7659a5e9c29d69e0b5698996cc916197b6556fa7460f604434368f",
    db_ticker: "BYADA",
    db_name: "BavarianStakePool",
    db_url: "https://BavarianStakePool.de",
    roa: "1.504",
    roa_lifetime: "4.264",
    total_stake: "1457717841806",
    stake_x_deleg: "69415135324.095",
    saturated: "0.021204996267806",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "237",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Apex Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/538299a358e79a289c8de779f8cd09dd6a6bb286de717d1f744bb357.png",
    pool_id: "538299a358e79a289c8de779f8cd09dd6a6bb286de717d1f744bb357",
    db_ticker: "APEX",
    db_name: "Apex Cardano Pool",
    db_url: "https://apexpool.info/",
    roa: "2.369",
    roa_lifetime: "4.061",
    total_stake: "1826846860467",
    stake_x_deleg: "9874847894.4162",
    saturated: "0.026574608437296",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "789",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAttitude",
    icon: "0",
    pool_id: "f9317946c36f6ea5625732382ba4de05ddf4a3fe5952caa838cee051",
    db_ticker: "HELLO",
    db_name: "ADAttitude",
    db_url: "https://adattitude.github.io",
    roa: "0",
    roa_lifetime: "2.416",
    total_stake: "92904948116",
    stake_x_deleg: "3440924004.2963",
    saturated: "0.0013514611823779",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake Pool Central",
    icon: "0",
    pool_id: "d34d33e345f006058bf136745a2c6b6e557621cf02dbaa29e71f3a0f",
    db_ticker: "CENT",
    db_name: "Stake Pool Central",
    db_url: "https://cent.stakepoolcentral.com",
    roa: "5.177",
    roa_lifetime: "4.212",
    total_stake: "1652741357046",
    stake_x_deleg: "51648167407.688",
    saturated: "0.024041946460907",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "730",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Asset Stake Pool 2% fee",
    icon: "0",
    pool_id: "9429e7fed8869710ba128e5bd788558ef1446caa577341b4d84ccf54",
    db_ticker: "ASSET",
    db_name: "Asset Stake Pool 2% fee",
    db_url: "https://assetstakepool.wixsite.com/asset",
    roa: "0.668",
    roa_lifetime: "4.685",
    total_stake: "1547714315044",
    stake_x_deleg: "67291926741.043",
    saturated: "0.022514148714457",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "519",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zetetic 2",
    icon: "https://cdn.adapools.org/pool_logo/524591b9cfbeecf4919de48545aac8d5ff599dbd11e8301292b1c16a.png",
    pool_id: "524591b9cfbeecf4919de48545aac8d5ff599dbd11e8301292b1c16a",
    db_ticker: "ZETE2",
    db_name: "Zetetic 2",
    db_url: "https://zetetic.tech",
    roa: "3.983",
    roa_lifetime: "3.967",
    total_stake: "1472470382383",
    stake_x_deleg: "11874761148.25",
    saturated: "0.021419597172661",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "198",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Unbounded Earth Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6c0189b8357adeec1f0d565213ed30e95f4a21e577e9b98a1eb1b691.png",
    pool_id: "6c0189b8357adeec1f0d565213ed30e95f4a21e577e9b98a1eb1b691",
    db_ticker: "UESP",
    db_name: "Unbounded Earth Stake Pool",
    db_url: "https://unbounded.earth/",
    roa: "2.857",
    roa_lifetime: "2.989",
    total_stake: "1518733169531",
    stake_x_deleg: "4930951849.1266",
    saturated: "0.022092568443697",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "36",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "St₳ke and EGGS Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/43ef09ca6b7b791b063144b9d8cc33c4d0389115ed9424b6eda899b2.png",
    pool_id: "43ef09ca6b7b791b063144b9d8cc33c4d0389115ed9424b6eda899b2",
    db_ticker: "EGGS",
    db_name: "St₳ke and EGGS Stakepool",
    db_url: "https://www.stakeandeggs.io/",
    roa: "3.762",
    roa_lifetime: "4.328",
    total_stake: "1345224902252",
    stake_x_deleg: "9473414804.5915",
    saturated: "0.019568594287268",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "497",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blocks and Clouds",
    icon: "https://cdn.adapools.org/pool_logo/1784413b65c86721d861798781219b765b27ac6d8e7c7688fbf2276c.png",
    pool_id: "1784413b65c86721d861798781219b765b27ac6d8e7c7688fbf2276c",
    db_ticker: "OPAL",
    db_name: "Blocks and Clouds",
    db_url: "https://blocksandclouds.com",
    roa: "0",
    roa_lifetime: "1.162",
    total_stake: "1754099621600",
    stake_x_deleg: "219262452700",
    saturated: "0.025516375571904",
    luck_lifetime: "35",
    blocks_epoch: "0",
    blocks_lifetime: "43",
    tax_ratio: "0",
    tax_fix: "500000000",
    pledge: "504000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aus Staker",
    icon: "https://cdn.adapools.org/pool_logo/ca3933242172d1f2722b3eabc07b4478b8fa43c1937b1c9505a4ddf7.png",
    pool_id: "ca3933242172d1f2722b3eabc07b4478b8fa43c1937b1c9505a4ddf7",
    db_ticker: "AUSST",
    db_name: "Aus Staker",
    db_url: "https://ausstaker.com.au",
    roa: "3.986",
    roa_lifetime: "4.151",
    total_stake: "1327156101483",
    stake_x_deleg: "11343214542.59",
    saturated: "0.019305752712662",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "502",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "505000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "#Luna Staking",
    icon: "https://cdn.adapools.org/pool_logo/b25395824b7e80cb54a5a2c51cc405bc707c3405ce5a311c0389273c.png",
    pool_id: "b25395824b7e80cb54a5a2c51cc405bc707c3405ce5a311c0389273c",
    db_ticker: "LUNA",
    db_name: "#Luna Staking",
    db_url: "https://luna-pool.com/",
    roa: "3.019",
    roa_lifetime: "4.128",
    total_stake: "1400115345296",
    stake_x_deleg: "10770118040.738",
    saturated: "0.020367069552168",
    luck_lifetime: "92",
    blocks_epoch: "2",
    blocks_lifetime: "307",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adappt Online",
    icon: "https://cdn.adapools.org/pool_logo/780623d70c1168241e1d4d623e5e14e7f02e701b536329e9a735ad61.png",
    pool_id: "780623d70c1168241e1d4d623e5e14e7f02e701b536329e9a735ad61",
    db_ticker: "ADAPT",
    db_name: "Adappt Online",
    db_url: "https://adappt.online",
    roa: "4.965",
    roa_lifetime: "3.437",
    total_stake: "1450054625720",
    stake_x_deleg: "120837885476.67",
    saturated: "0.021093521698556",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "231",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "44325545672",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SABAI  Relaxing Stakepool",
    icon: "0",
    pool_id: "83ed61f50d38d4621370d1dff2e72dad62c8e789df464b0649ba87ac",
    db_ticker: "SABAI",
    db_name: "SABAI  Relaxing Stakepool",
    db_url: "https://scoop.tech/sabai",
    roa: "3.327",
    roa_lifetime: "4.334",
    total_stake: "1460817742007",
    stake_x_deleg: "97387849467.133",
    saturated: "0.021250089611872",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "552",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PooPool",
    icon: "https://cdn.adapools.org/pool_logo/dcee476cc7f068dfc2c7d4050bf3739d03f9367dc0df938a005322ac.png",
    pool_id: "dcee476cc7f068dfc2c7d4050bf3739d03f9367dc0df938a005322ac",
    db_ticker: "POOP",
    db_name: "PooPool",
    db_url: "https://poopool.org",
    roa: "6.751",
    roa_lifetime: "3.941",
    total_stake: "1392226334860",
    stake_x_deleg: "24425023418.596",
    saturated: "0.020252310418367",
    luck_lifetime: "120",
    blocks_epoch: "0",
    blocks_lifetime: "128",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Loop Pool",
    icon: "https://cdn.adapools.org/pool_logo/dec6d338be22ca05289dce3358dfd9da9378239b1b9de174b26b9653.png",
    pool_id: "dec6d338be22ca05289dce3358dfd9da9378239b1b9de174b26b9653",
    db_ticker: "LOOP",
    db_name: "Loop Pool",
    db_url: "http://www.loop-pool.one",
    roa: "4.38",
    roa_lifetime: "4.064",
    total_stake: "1412041482683",
    stake_x_deleg: "28817173115.98",
    saturated: "0.020540555594204",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "334",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hygge Is The Art Of Happiness",
    icon: "https://cdn.adapools.org/pool_logo/f8cac661341d248b2194f93c257eefd1c9b122a6d82d449c3c559516.png",
    pool_id: "f8cac661341d248b2194f93c257eefd1c9b122a6d82d449c3c559516",
    db_ticker: "HYGGE",
    db_name: "Hygge Is The Art Of Happiness",
    db_url: "https://hyggepool.com",
    roa: "4.177",
    roa_lifetime: "4.057",
    total_stake: "2195262895482",
    stake_x_deleg: "17993958159.689",
    saturated: "0.031933849041647",
    luck_lifetime: "107",
    blocks_epoch: "4",
    blocks_lifetime: "258",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaLoop",
    icon: "https://cdn.adapools.org/pool_logo/297b5acd8b90df8a1bfd98aa1a61f323b87fead4b8a2b55f2fb83ce6.png",
    pool_id: "297b5acd8b90df8a1bfd98aa1a61f323b87fead4b8a2b55f2fb83ce6",
    db_ticker: "ALoop",
    db_name: "AdaLoop",
    db_url: "https://adaloop.org",
    roa: "3.708",
    roa_lifetime: "3.61",
    total_stake: "9154687137219",
    stake_x_deleg: "89751834678.618",
    saturated: "0.13317056361",
    luck_lifetime: "95",
    blocks_epoch: "8",
    blocks_lifetime: "114",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO8 Pool",
    icon: "0",
    pool_id: "4a78ca15af1b42f94106e94d419c2cdf72667389cf2b1b04df8f140e",
    db_ticker: "LEO8",
    db_name: "LEO8 Pool",
    db_url: "https://staking-fm.site",
    roa: "2.922",
    roa_lifetime: "3.804",
    total_stake: "6306170374639",
    stake_x_deleg: "1261234074927.8",
    saturated: "0.091734021100198",
    luck_lifetime: "98",
    blocks_epoch: "12",
    blocks_lifetime: "3754",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "mihopool",
    icon: "0",
    pool_id: "8ee464463c1dde60718e8c6d299e8477a55571c073c2cb9e1ac6bc38",
    db_ticker: "MIHOP",
    db_name: "mihopool",
    db_url: "https://www.mihopool.com/",
    roa: "1.908",
    roa_lifetime: "3.361",
    total_stake: "2147791622766",
    stake_x_deleg: "143186108184.4",
    saturated: "0.031243298283536",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "57",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "QubitPool",
    icon: "0",
    pool_id: "08dd84d27f00aa9ee6caf195d81256a3d217dd345857cd8be60f18b0",
    db_ticker: "QUBIT",
    db_name: "QubitPool",
    db_url: "https://qubitpool.com",
    roa: "3.347",
    roa_lifetime: "2.742",
    total_stake: "1566783812482",
    stake_x_deleg: "174087090275.78",
    saturated: "0.02279154713163",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "82",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Centre for Open Systems Deployment",
    icon: "https://cdn.adapools.org/pool_logo/c94e6fe1123bf111b77b57994bcd836af8ba2b3aa72cfcefbec2d3d4.png",
    pool_id: "c94e6fe1123bf111b77b57994bcd836af8ba2b3aa72cfcefbec2d3d4",
    db_ticker: "COSD",
    db_name: "Centre for Open Systems Deployment",
    db_url: "https://cosd.com/pool",
    roa: "4.671",
    roa_lifetime: "4.972",
    total_stake: "15473493862203",
    stake_x_deleg: "144612092170.12",
    saturated: "0.22508840201298",
    luck_lifetime: "107",
    blocks_epoch: "11",
    blocks_lifetime: "744",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MANGO FARM",
    icon: "https://cdn.adapools.org/pool_logo/9addc1ee114be55b0334724282e2ffec09f0bffbe3bcadd533d6993e.png",
    pool_id: "9addc1ee114be55b0334724282e2ffec09f0bffbe3bcadd533d6993e",
    db_ticker: "MANGO",
    db_name: "MANGO FARM",
    db_url: "https://sites.google.com/view/mango-staking-pool",
    roa: "5.966",
    roa_lifetime: "3.681",
    total_stake: "1257749703215",
    stake_x_deleg: "66197352800.789",
    saturated: "0.018296118081031",
    luck_lifetime: "102",
    blocks_epoch: "1",
    blocks_lifetime: "163",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "180000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Functional",
    icon: "https://cdn.adapools.org/pool_logo/1a905733d222fd7e9103d8f0a5c16ad9f65840638e3457a521a97972.png",
    pool_id: "1a905733d222fd7e9103d8f0a5c16ad9f65840638e3457a521a97972",
    db_ticker: "CRFN",
    db_name: "Crypto Functional",
    db_url: "https://cryptofunctional.com",
    roa: "2.148",
    roa_lifetime: "4.697",
    total_stake: "1414471269850",
    stake_x_deleg: "78581737213.889",
    saturated: "0.020575900999419",
    luck_lifetime: "109",
    blocks_epoch: "2",
    blocks_lifetime: "372",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "750001000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Budz Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/de7f214056fa551217d6ed21203bb09e7f479b34e8f4740bcf87f6f9.png",
    pool_id: "de7f214056fa551217d6ed21203bb09e7f479b34e8f4740bcf87f6f9",
    db_ticker: "BUDZ",
    db_name: "Cardano Budz Stakepool",
    db_url: "https://cardanobudz.io",
    roa: "3.1",
    roa_lifetime: "3.085",
    total_stake: "14856027372044",
    stake_x_deleg: "74653403879.618",
    saturated: "0.21610629707895",
    luck_lifetime: "86",
    blocks_epoch: "6",
    blocks_lifetime: "138",
    tax_ratio: "0.042",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Minasan stakepool in Central Europe",
    icon: "https://cdn.adapools.org/pool_logo/c7211fc0d32e9498acd17c2e45f5a5e1ea7f483300b47f517db986ad.png",
    pool_id: "c7211fc0d32e9498acd17c2e45f5a5e1ea7f483300b47f517db986ad",
    db_ticker: "MINA",
    db_name: "Minasan stakepool in Central Europe",
    db_url: "http://minasan.io",
    roa: "3.068",
    roa_lifetime: "3.115",
    total_stake: "1369100119290",
    stake_x_deleg: "76061117738.333",
    saturated: "0.019915900105763",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "89",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tai Chinh - Tam An",
    icon: "0",
    pool_id: "1e174471d30ea762a6bf6ce8b1c615549f3dd066cf48ca6be6e7698b",
    db_ticker: "FIMI",
    db_name: "Tai Chinh - Tam An",
    db_url: "https://fimi.vn/ada",
    roa: "2.374",
    roa_lifetime: "4.015",
    total_stake: "8110351579176",
    stake_x_deleg: "10828239758.579",
    saturated: "0.11797891885164",
    luck_lifetime: "97",
    blocks_epoch: "7",
    blocks_lifetime: "515",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Yummi Pool",
    icon: "0",
    pool_id: "440781cb7ac2ec0e653ad9e502303f63e51a7afc0ccaae5be06e37ef",
    db_ticker: "YUMMI",
    db_name: "Yummi Pool",
    db_url: "https://yummiuniverse.com",
    roa: "3.555",
    roa_lifetime: "3.277",
    total_stake: "6354059460422",
    stake_x_deleg: "3981240263.4223",
    saturated: "0.092430649663129",
    luck_lifetime: "98",
    blocks_epoch: "5",
    blocks_lifetime: "63",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Steak Pool",
    icon: "0",
    pool_id: "85a0503824424acda33cbd675ec611d5a34c44131782da800c750eaa",
    db_ticker: "STEAK",
    db_name: "Steak Pool",
    db_url: "",
    roa: "1.745",
    roa_lifetime: "4.196",
    total_stake: "1527910306965",
    stake_x_deleg: "69450468498.409",
    saturated: "0.022226065585226",
    luck_lifetime: "103",
    blocks_epoch: "4",
    blocks_lifetime: "252",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/39890eb30a6f442663c6e8097d2cbad0bfd72381b34444e1e48057ed.png",
    pool_id: "39890eb30a6f442663c6e8097d2cbad0bfd72381b34444e1e48057ed",
    db_ticker: "GOAT3",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "3.219",
    roa_lifetime: "4.12",
    total_stake: "17094571751146",
    stake_x_deleg: "128530614670.27",
    saturated: "0.24866974923877",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "219",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cryptofay",
    icon: "0",
    pool_id: "9ac6f4118dccc20d978e7239d73d47e939cc594c350effa8711f5ac4",
    db_ticker: "FAY",
    db_name: "Cryptofay",
    db_url: "https://cryptofay.io",
    roa: "2.396",
    roa_lifetime: "4.137",
    total_stake: "1369226798382",
    stake_x_deleg: "26847584282",
    saturated: "0.019917742869565",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "394",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "324900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mirai BlockChain Group",
    icon: "https://cdn.adapools.org/pool_logo/28e52e9155c89591201620193eeb8ec53832fd753950e60d9e666993.png",
    pool_id: "28e52e9155c89591201620193eeb8ec53832fd753950e60d9e666993",
    db_ticker: "MBCG",
    db_name: "Mirai BlockChain Group",
    db_url: "https://mbcg.io",
    roa: "1.437",
    roa_lifetime: "3.066",
    total_stake: "1276491565435",
    stake_x_deleg: "22008475266.121",
    saturated: "0.01856875048425",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "92",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Let Them Eat Stake",
    icon: "https://cdn.adapools.org/pool_logo/86d56db6754947d5c6dfd788568c189cc188d448aa6bc4ee634f655a.png",
    pool_id: "86d56db6754947d5c6dfd788568c189cc188d448aa6bc4ee634f655a",
    db_ticker: "LTES",
    db_name: "Let Them Eat Stake",
    db_url: "https://let-them-eat-stake.com",
    roa: "2.426",
    roa_lifetime: "4.231",
    total_stake: "1302025429832",
    stake_x_deleg: "33385267431.59",
    saturated: "0.018940184162094",
    luck_lifetime: "97",
    blocks_epoch: "3",
    blocks_lifetime: "292",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardan.io",
    icon: "0",
    pool_id: "828723ad5da2d2c78c304f6def21f1b975c644603074a5ea5b8b6f5a",
    db_ticker: "CAR",
    db_name: "Cardan.io",
    db_url: "http://cardan.io",
    roa: "2.467",
    roa_lifetime: "4.687",
    total_stake: "1111967536287",
    stake_x_deleg: "3657787948.3125",
    saturated: "0.016175467419452",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "784",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "149999999999",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NICE rewards for you",
    icon: "https://cdn.adapools.org/pool_logo/1649d50e9181b60bab868c20980c191e4689b95791b61d44678cd6e6.png",
    pool_id: "1649d50e9181b60bab868c20980c191e4689b95791b61d44678cd6e6",
    db_ticker: "NICE",
    db_name: "NICE rewards for you",
    db_url: "https://tinyurl.com/Pool-NICE",
    roa: "3.918",
    roa_lifetime: "4.21",
    total_stake: "5697864568043",
    stake_x_deleg: "219148637232.42",
    saturated: "0.082885173958029",
    luck_lifetime: "95",
    blocks_epoch: "6",
    blocks_lifetime: "752",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vada Pool",
    icon: "https://cdn.adapools.org/pool_logo/bf6a228a7928bd7cd6204bd626142d01f0d96a2648e4782abae1cbfb.png",
    pool_id: "bf6a228a7928bd7cd6204bd626142d01f0d96a2648e4782abae1cbfb",
    db_ticker: "VADA",
    db_name: "Vada Pool",
    db_url: "https://vadapool.com",
    roa: "4.521",
    roa_lifetime: "4.58",
    total_stake: "1993671720259",
    stake_x_deleg: "14139515746.518",
    saturated: "0.029001361014382",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "510",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakeTREK.io",
    icon: "https://cdn.adapools.org/pool_logo/c6c0117e031cb813891a07439188fa3f3556fd7ee318d80a03d50fb6.png",
    pool_id: "c6c0117e031cb813891a07439188fa3f3556fd7ee318d80a03d50fb6",
    db_ticker: "TREK",
    db_name: "stakeTREK.io",
    db_url: "https://stakeTREK.io",
    roa: "2.988",
    roa_lifetime: "4.507",
    total_stake: "1627833178603",
    stake_x_deleg: "3617407063.5622",
    saturated: "0.02367961445414",
    luck_lifetime: "107",
    blocks_epoch: "2",
    blocks_lifetime: "792",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA ALPHA POOL",
    icon: "0",
    pool_id: "6c907940885e39f6440a034665cc26481ef885a6c33ee50589e041e9",
    db_ticker: "ADAAP",
    db_name: "ADA ALPHA POOL",
    db_url: "https://www.adaalphapool.com",
    roa: "4.346",
    roa_lifetime: "4.671",
    total_stake: "13847071383206",
    stake_x_deleg: "96160217938.931",
    saturated: "0.20142930859455",
    luck_lifetime: "100",
    blocks_epoch: "16",
    blocks_lifetime: "2163",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PoolFixAda",
    icon: "0",
    pool_id: "9af540f1fa5fd08967b919cfa525b2e14ed68c66e95d3746d5fdd2c4",
    db_ticker: "FIXA",
    db_name: "PoolFixAda",
    db_url: "https://poolfixada.com",
    roa: "1.403",
    roa_lifetime: "2.431",
    total_stake: "2502635718847",
    stake_x_deleg: "139035317713.72",
    saturated: "0.036405111850782",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "72",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AquaPoolADA",
    icon: "0",
    pool_id: "f3dea3f6c7047c04d734fa7c23e49c8611818102997f7a1fb5416681",
    db_ticker: "AQUA",
    db_name: "AquaPoolADA",
    db_url: "http://aquapool.io/",
    roa: "3.668",
    roa_lifetime: "4.616",
    total_stake: "1232525299643",
    stake_x_deleg: "21623250870.93",
    saturated: "0.017929186039546",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "409",
    tax_ratio: "0.0325",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "P2P Validator #3",
    icon: "0",
    pool_id: "c29c92f8319150962650bc8a5e24d918491e8a7b3ac43525afe76baa",
    db_ticker: "P2P3",
    db_name: "P2P Validator #3",
    db_url: "https://p2p.org/",
    roa: "4.516",
    roa_lifetime: "4.494",
    total_stake: "20650995254195",
    stake_x_deleg: "137673301694.63",
    saturated: "0.30040400462489",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "1621",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA HEART STAKE POOL",
    icon: "https://cdn.adapools.org/pool_logo/f8b811d63ef2ac9e19eff41ab7a99f37b82da701f8ddb34d6d12cdb7.png",
    pool_id: "f8b811d63ef2ac9e19eff41ab7a99f37b82da701f8ddb34d6d12cdb7",
    db_ticker: "HEART",
    db_name: "ADA HEART STAKE POOL",
    db_url: "https://adaheartpool.com",
    roa: "2.682",
    roa_lifetime: "3.743",
    total_stake: "1245039720752",
    stake_x_deleg: "14477206055.256",
    saturated: "0.0181112296733",
    luck_lifetime: "91",
    blocks_epoch: "2",
    blocks_lifetime: "235",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "57000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoPool.XYZ",
    icon: "https://cdn.adapools.org/pool_logo/3dec052cf085697aa6c32a934527f7c5761165a6a6f02a9af30c9ed1.png",
    pool_id: "3dec052cf085697aa6c32a934527f7c5761165a6a6f02a9af30c9ed1",
    db_ticker: "XYZ",
    db_name: "CardanoPool.XYZ",
    db_url: "https://cardanopool.xyz",
    roa: "3.928",
    roa_lifetime: "4.751",
    total_stake: "1222999390306",
    stake_x_deleg: "10823003454.035",
    saturated: "0.017790615414872",
    luck_lifetime: "104",
    blocks_epoch: "1",
    blocks_lifetime: "542",
    tax_ratio: "0.001",
    tax_fix: "340000000",
    pledge: "300001000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "K8S Staking Pool",
    icon: "0",
    pool_id: "0960deeaabd7ca77a970e2932d06ec594c902301973041c6d9b1dc2e",
    db_ticker: "K8S",
    db_name: "K8S Staking Pool",
    db_url: "https://k8s-pool.subnet.dev",
    roa: "3.665",
    roa_lifetime: "4.555",
    total_stake: "1160464072289",
    stake_x_deleg: "21099346768.891",
    saturated: "0.016880932383543",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "382",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hedges Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/281f1aa1feb755f5bb1173a59e78ae5167fea70f728219e6810f28ee.png",
    pool_id: "281f1aa1feb755f5bb1173a59e78ae5167fea70f728219e6810f28ee",
    db_ticker: "HEDGE",
    db_name: "Hedges Stake Pool",
    db_url: "https://Hedgespool.com",
    roa: "2.408",
    roa_lifetime: "4.356",
    total_stake: "1138321718223",
    stake_x_deleg: "10540015909.472",
    saturated: "0.016558834017271",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "360",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HYPE STAKING",
    icon: "https://cdn.adapools.org/pool_logo/29df06255834d24d6a149224cf114a371bea08b64e7537e224a561a5.png",
    pool_id: "29df06255834d24d6a149224cf114a371bea08b64e7537e224a561a5",
    db_ticker: "HYPE",
    db_name: "HYPE STAKING",
    db_url: "https://hype-staking.com",
    roa: "2.941",
    roa_lifetime: "2.912",
    total_stake: "1301271121384",
    stake_x_deleg: "3645017146.7339",
    saturated: "0.018929211457112",
    luck_lifetime: "87",
    blocks_epoch: "2",
    blocks_lifetime: "113",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lord Byron",
    icon: "https://cdn.adapools.org/pool_logo/c95617e76c620b0c55b02deb3bbac70067a3e8a074fb05008ee17215.png",
    pool_id: "c95617e76c620b0c55b02deb3bbac70067a3e8a074fb05008ee17215",
    db_ticker: "LORDB",
    db_name: "Lord Byron",
    db_url: "http://lordb.rocks",
    roa: "2.721",
    roa_lifetime: "4.202",
    total_stake: "1108391934529",
    stake_x_deleg: "16063651225.058",
    saturated: "0.016123454183585",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "288",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "4tun8",
    icon: "0",
    pool_id: "7bb1b0b25bdb3c1bfb0252b514d144c59939bb94b34217011cf560a0",
    db_ticker: "4TUN8",
    db_name: "4tun8",
    db_url: "http://www.4tun8.tech",
    roa: "0.581",
    roa_lifetime: "4.616",
    total_stake: "1248106411194",
    stake_x_deleg: "56732109599.727",
    saturated: "0.018155839924689",
    luck_lifetime: "107",
    blocks_epoch: "3",
    blocks_lifetime: "338",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaStarNode",
    icon: "https://cdn.adapools.org/pool_logo/f1263e0885fb00f352968294c5a6bf18da372b98236f55a457416d62.png",
    pool_id: "f1263e0885fb00f352968294c5a6bf18da372b98236f55a457416d62",
    db_ticker: "ASN",
    db_name: "AdaStarNode",
    db_url: "https://adastarnode.com",
    roa: "1.199",
    roa_lifetime: "4.249",
    total_stake: "1469836812005",
    stake_x_deleg: "2078977103.2603",
    saturated: "0.021381287392514",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "315",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swimming Pool #1",
    icon: "0",
    pool_id: "67df7fa6b2ce3711d9ecc60bb58794e415867ae2f8a25d61103e078a",
    db_ticker: "POOL",
    db_name: "The Swimming Pool #1",
    db_url: "https://pool.la",
    roa: "1.403",
    roa_lifetime: "3.214",
    total_stake: "1338700871016",
    stake_x_deleg: "95621490786.857",
    saturated: "0.019473691107761",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "110",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Patriots Pool",
    icon: "0",
    pool_id: "58e4c10a16eab7369cd2332427d93d1af584c24a8aad6d22e3fcf1ee",
    db_ticker: "PATPL",
    db_name: "Patriots Pool",
    db_url: "https://PatriotsPool.com",
    roa: "3.009",
    roa_lifetime: "2.851",
    total_stake: "1712225086940",
    stake_x_deleg: "428056271735",
    saturated: "0.024907238929876",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "104",
    tax_ratio: "0.025",
    tax_fix: "345000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "I was here - Ich war hier",
    icon: "https://cdn.adapools.org/pool_logo/e1ca526fe5d2d2ac26a2b3ab52bf73ba4f1506da4e533eff8c5faa7f.png",
    pool_id: "e1ca526fe5d2d2ac26a2b3ab52bf73ba4f1506da4e533eff8c5faa7f",
    db_ticker: "IWH",
    db_name: "I was here - Ich war hier",
    db_url: "https://cardano.ich-war-hier.de",
    roa: "2.66",
    roa_lifetime: "4.173",
    total_stake: "1118610091445",
    stake_x_deleg: "69913130715.312",
    saturated: "0.016272094731881",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "186",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "430000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Harbour",
    icon: "0",
    pool_id: "5bb951ce3ad7ab55800546b43e1223caf3a3d542f4e7bab08f0525d0",
    db_ticker: "HRBR",
    db_name: "The Harbour",
    db_url: "https://adastaking.eu",
    roa: "2.11",
    roa_lifetime: "4.541",
    total_stake: "1230701305632",
    stake_x_deleg: "15983133839.377",
    saturated: "0.017902652930678",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "594",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "940000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONYX Stake Pool #3",
    icon: "0",
    pool_id: "5b5804135b07be6f3635a655ed1b68ddef1854523b45cd1840a6fc33",
    db_ticker: "ONYX",
    db_name: "ONYX Stake Pool #3",
    db_url: "https://onyxstakepool.com",
    roa: "4.226",
    roa_lifetime: "4.523",
    total_stake: "61104878943424",
    stake_x_deleg: "209263284052.82",
    saturated: "0.88887485134621",
    luck_lifetime: "101",
    blocks_epoch: "54",
    blocks_lifetime: "4120",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Unique",
    icon: "0",
    pool_id: "2397e90130d990f207f6e576b6b97bafb430f942d26702eef9627dcf",
    db_ticker: "UNIQ",
    db_name: "Unique",
    db_url: "https://www.uniquestaking.com",
    roa: "1.212",
    roa_lifetime: "4.443",
    total_stake: "1204449797198",
    stake_x_deleg: "48177991887.92",
    saturated: "0.017520779894345",
    luck_lifetime: "108",
    blocks_epoch: "2",
    blocks_lifetime: "455",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Ocean Seven",
    icon: "https://cdn.adapools.org/pool_logo/3778d01333555227a997e141d50c95ac3824d8e28fefe8634037b114.png",
    pool_id: "3778d01333555227a997e141d50c95ac3824d8e28fefe8634037b114",
    db_ticker: "OCEA7",
    db_name: "ADA Ocean Seven",
    db_url: "https://adaocean.com/",
    roa: "1.783",
    roa_lifetime: "4.722",
    total_stake: "1110433989527",
    stake_x_deleg: "6729902966.8303",
    saturated: "0.016153159362028",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "1261",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Husky pool 2",
    icon: "0",
    pool_id: "c0401715513d541fe7489da5f7d942544fc76eb039af488c82363ace",
    db_ticker: "HUSKY",
    db_name: "Husky pool 2",
    db_url: "https://huskypool.com",
    roa: "2.894",
    roa_lifetime: "3.279",
    total_stake: "1256953779416",
    stake_x_deleg: "46553843682.074",
    saturated: "0.018284540009676",
    luck_lifetime: "104",
    blocks_epoch: "2",
    blocks_lifetime: "88",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "145400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RANKT",
    icon: "0",
    pool_id: "135605c391fc48beac4460c153b6bb0cfff2e29963920d2fa6b6e656",
    db_ticker: "RANKT",
    db_name: "RANKT",
    db_url: "https://ranket2.wordpress.com/",
    roa: "4.486",
    roa_lifetime: "4.208",
    total_stake: "17169982363239",
    stake_x_deleg: "238471977267.21",
    saturated: "0.24976672541765",
    luck_lifetime: "107",
    blocks_epoch: "11",
    blocks_lifetime: "407",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AIDpool 1",
    icon: "0",
    pool_id: "0e7a4cec5fb6f0e9eab8ad29b31e066f44b03cdc616ed37c2fb62061",
    db_ticker: "AID1",
    db_name: "AIDpool 1",
    db_url: "https://cardano-stakepool-aid.amebaownd.com/",
    roa: "5.482",
    roa_lifetime: "3.185",
    total_stake: "1268200106742",
    stake_x_deleg: "60390481273.429",
    saturated: "0.018448137053037",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "104",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DOG Pool",
    icon: "https://cdn.adapools.org/pool_logo/1d203e7d0dd203d63e04d8403b2ca9a92df7ebf72c1167854f1d2acf.png",
    pool_id: "1d203e7d0dd203d63e04d8403b2ca9a92df7ebf72c1167854f1d2acf",
    db_ticker: "DOG",
    db_name: "DOG Pool",
    db_url: "https://adadogpool.org/",
    roa: "1.027",
    roa_lifetime: "2.933",
    total_stake: "1145389721699",
    stake_x_deleg: "9874049324.9914",
    saturated: "0.016661650202291",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "77",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Otaku Pool",
    icon: "https://cdn.adapools.org/pool_logo/77ff160eacd3e99e84b70c9216f698965004da4b3dc628dac60cd290.png",
    pool_id: "77ff160eacd3e99e84b70c9216f698965004da4b3dc628dac60cd290",
    db_ticker: "OTAKU",
    db_name: "Otaku Pool",
    db_url: "https://otakupool.com/",
    roa: "2.547",
    roa_lifetime: "4.969",
    total_stake: "997743097912",
    stake_x_deleg: "5090526009.7551",
    saturated: "0.014513877830596",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "2099",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Rewards",
    icon: "https://cdn.adapools.org/pool_logo/f0e3c8d48efb750cdf256b68babc880787bc688972acfbbee1ffc537.png",
    pool_id: "f0e3c8d48efb750cdf256b68babc880787bc688972acfbbee1ffc537",
    db_ticker: "ADAR",
    db_name: "ADA Rewards",
    db_url: "https://adarewards.org",
    roa: "1.713",
    roa_lifetime: "4.342",
    total_stake: "1031026202706",
    stake_x_deleg: "12728718551.926",
    saturated: "0.014998037448251",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "350",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "101000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Early Bird Pool",
    icon: "https://cdn.adapools.org/pool_logo/4c15ba0f7f36b581e1ce18e095070695d96f584de0f00e41b16511bd.png",
    pool_id: "4c15ba0f7f36b581e1ce18e095070695d96f584de0f00e41b16511bd",
    db_ticker: "BIRD",
    db_name: "Early Bird Pool",
    db_url: "https://adaverse.com",
    roa: "2.159",
    roa_lifetime: "4.846",
    total_stake: "2111496984292",
    stake_x_deleg: "7181962531.6054",
    saturated: "0.030715330763821",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "2352",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aion",
    icon: "https://cdn.adapools.org/pool_logo/a7b2a175b2e439acc125decd1f4d3a8e42a4744dcfe74dddf527c386.png",
    pool_id: "a7b2a175b2e439acc125decd1f4d3a8e42a4744dcfe74dddf527c386",
    db_ticker: "AION",
    db_name: "Aion",
    db_url: "https://aionpool.pl",
    roa: "1.387",
    roa_lifetime: "4.614",
    total_stake: "1045612027448",
    stake_x_deleg: "11243140080.086",
    saturated: "0.015210213186482",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "629",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "657000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool Service No.2",
    icon: "0",
    pool_id: "78380abbe8bb86cddda2c79190294125c594630e9535d32907b99f89",
    db_ticker: "SPS2",
    db_name: "StakePool Service No.2",
    db_url: "https://stakepoolservice.com/",
    roa: "3.873",
    roa_lifetime: "4.533",
    total_stake: "43200923141344",
    stake_x_deleg: "96430632011.929",
    saturated: "0.62843122839397",
    luck_lifetime: "98",
    blocks_epoch: "26",
    blocks_lifetime: "5829",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " NOVApool.io",
    icon: "https://cdn.adapools.org/pool_logo/827c7aa359964ec100a9a3b943c73c1dd2056725b8897fd744c8adc0.png",
    pool_id: "827c7aa359964ec100a9a3b943c73c1dd2056725b8897fd744c8adc0",
    db_ticker: "NOVA",
    db_name: " NOVApool.io",
    db_url: "https://novapool.io/",
    roa: "2.572",
    roa_lifetime: "4.072",
    total_stake: "1047466160685",
    stake_x_deleg: "6846184056.7647",
    saturated: "0.01523718472188",
    luck_lifetime: "116",
    blocks_epoch: "2",
    blocks_lifetime: "136",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AACC",
    icon: "0",
    pool_id: "db827cb962df26faab4c289f72f36aeee0e42453f1c78b27ea4e22ba",
    db_ticker: "AACC",
    db_name: "AACC",
    db_url: "https://www.aastakepool.com",
    roa: "4.568",
    roa_lifetime: "4.527",
    total_stake: "8088178145183",
    stake_x_deleg: "260908972425.26",
    saturated: "0.11765636837474",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "1031",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kobe Stake Pool by Kaizen Crypto",
    icon: "https://cdn.adapools.org/pool_logo/ab8ab9d5d17b5cd57d2cae1d242baacc7964b5f300c8d347c99093e1.png",
    pool_id: "ab8ab9d5d17b5cd57d2cae1d242baacc7964b5f300c8d347c99093e1",
    db_ticker: "KOBE",
    db_name: "Kobe Stake Pool by Kaizen Crypto",
    db_url: "https://www.kaizencrypto.com",
    roa: "3.052",
    roa_lifetime: "3.379",
    total_stake: "722387814693",
    stake_x_deleg: "11113658687.585",
    saturated: "0.010508364839313",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "126",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fat Matt Staking",
    icon: "https://cdn.adapools.org/pool_logo/6bc5d11446acb3259f33db041f71fbcc1b22a2c0ee4d8e18391e37ad.png",
    pool_id: "6bc5d11446acb3259f33db041f71fbcc1b22a2c0ee4d8e18391e37ad",
    db_ticker: "FATTY",
    db_name: "Fat Matt Staking",
    db_url: "https://fatmattstaking.com",
    roa: "1.458",
    roa_lifetime: "3.173",
    total_stake: "1093410231434",
    stake_x_deleg: "13173617246.193",
    saturated: "0.01590551971842",
    luck_lifetime: "117",
    blocks_epoch: "1",
    blocks_lifetime: "54",
    tax_ratio: "0.022",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AlphaPool",
    icon: "https://cdn.adapools.org/pool_logo/a1fa8385fa06ebd0b7d47e6d90eb399970dfb41005635ffd5b0bc964.png",
    pool_id: "a1fa8385fa06ebd0b7d47e6d90eb399970dfb41005635ffd5b0bc964",
    db_ticker: "ALPHA",
    db_name: "AlphaPool",
    db_url: "https://alphastaking.com",
    roa: "0.974",
    roa_lifetime: "4.31",
    total_stake: "1098655378521",
    stake_x_deleg: "47767625153.087",
    saturated: "0.015981819343227",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "215",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Korea Pool",
    icon: "https://cdn.adapools.org/pool_logo/44d7fba88039561837d64773c1e7728c34f1c300edc9fa280263cc7f.png",
    pool_id: "44d7fba88039561837d64773c1e7728c34f1c300edc9fa280263cc7f",
    db_ticker: "KOR",
    db_name: "Korea Pool",
    db_url: "https://koreapool.io",
    roa: "2.618",
    roa_lifetime: "2.801",
    total_stake: "4362205346522",
    stake_x_deleg: "68159458539.406",
    saturated: "0.063455728838305",
    luck_lifetime: "86",
    blocks_epoch: "7",
    blocks_lifetime: "36",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SeasideStaking",
    icon: "https://cdn.adapools.org/pool_logo/f1ae552b032c2d87a416f4067eaf4636b3fe5e80230a1f63aa33f6bc.png",
    pool_id: "f1ae552b032c2d87a416f4067eaf4636b3fe5e80230a1f63aa33f6bc",
    db_ticker: "SEA",
    db_name: "SeasideStaking",
    db_url: "https://seaside-staking.github.io/",
    roa: "3.1",
    roa_lifetime: "2.612",
    total_stake: "1937141442192",
    stake_x_deleg: "2297913929.0534",
    saturated: "0.028179031547698",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "63",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CurryOn Crypto",
    icon: "0",
    pool_id: "96449f370656b2d484db0f2efa1333d57a5c4ab536d48443a1c186b0",
    db_ticker: "CURRY",
    db_name: "CurryOn Crypto",
    db_url: "https://curryoncrypto.com",
    roa: "1.417",
    roa_lifetime: "4.038",
    total_stake: "1120630669328",
    stake_x_deleg: "21973150378.98",
    saturated: "0.016301487488997",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "475",
    tax_ratio: "0.0274",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aloha Cardano",
    icon: "0",
    pool_id: "e459e81629534ba3f4f6c674242778b24d4d7c9a56a56dff52679687",
    db_ticker: "ALOHA",
    db_name: "Aloha Cardano",
    db_url: "https://alohacardano.com",
    roa: "1.591",
    roa_lifetime: "4.321",
    total_stake: "1161178140840",
    stake_x_deleg: "35187216389.091",
    saturated: "0.016891319730479",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "484",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "321000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool Service",
    icon: "0",
    pool_id: "a531c8c1fa9423ffa6eff3262c87ab4f18b8c290f71df6aca310a947",
    db_ticker: "SPS",
    db_name: "StakePool Service",
    db_url: "https://stakepoolservice.com/",
    roa: "3.596",
    roa_lifetime: "4.34",
    total_stake: "56391942560801",
    stake_x_deleg: "187973141869.34",
    saturated: "0.82031714042451",
    luck_lifetime: "97",
    blocks_epoch: "55",
    blocks_lifetime: "8608",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NGS発達障がい支援KGSP",
    icon: "https://cdn.adapools.org/pool_logo/d4b296f51882044cd8b222c49c88d4ec989b1172422fd0c355b885ae.png",
    pool_id: "d4b296f51882044cd8b222c49c88d4ec989b1172422fd0c355b885ae",
    db_ticker: "NGS",
    db_name: "NGS発達障がい支援KGSP",
    db_url: "https://mobile.twitter.com/NGS_KGSP",
    roa: "3.281",
    roa_lifetime: "3.694",
    total_stake: "3276145316000",
    stake_x_deleg: "86214350421.053",
    saturated: "0.047657130348696",
    luck_lifetime: "102",
    blocks_epoch: "1",
    blocks_lifetime: "198",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "12400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Reliable Staking",
    icon: "https://cdn.adapools.org/pool_logo/c9d833d3b3b14a8d24ca1f972cfd5bbaa444843301353d84ec1bfa32.png",
    pool_id: "c9d833d3b3b14a8d24ca1f972cfd5bbaa444843301353d84ec1bfa32",
    db_ticker: "RELY",
    db_name: "Reliable Staking",
    db_url: "https://www.reliablestaking.com",
    roa: "1.048",
    roa_lifetime: "4.48",
    total_stake: "1079304521865",
    stake_x_deleg: "6202899550.9483",
    saturated: "0.015700328075575",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "806",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "75000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Thai Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6b48ee351c6a7a1b84eb348a04fbbf94796d38446a95cf61aad92e46.png",
    pool_id: "6b48ee351c6a7a1b84eb348a04fbbf94796d38446a95cf61aad92e46",
    db_ticker: "SIAM",
    db_name: "Thai Stake Pool",
    db_url: "https://siam.mof.one",
    roa: "2.207",
    roa_lifetime: "2.433",
    total_stake: "1020404887509",
    stake_x_deleg: "92764080682.636",
    saturated: "0.014843532274032",
    luck_lifetime: "98",
    blocks_epoch: "2",
    blocks_lifetime: "29",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "245400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RX-78",
    icon: "https://cdn.adapools.org/pool_logo/3df201c968cab3b4aaf98576d3587ed10a51097605aa05c8656f3ef6.png",
    pool_id: "3df201c968cab3b4aaf98576d3587ed10a51097605aa05c8656f3ef6",
    db_ticker: "RX78",
    db_name: "RX-78",
    db_url: "https://rx78pool.wordpress.com",
    roa: "5.862",
    roa_lifetime: "3.869",
    total_stake: "1538617996903",
    stake_x_deleg: "96163624806.438",
    saturated: "0.02238182722761",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "248",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Oyster Pool",
    icon: "https://cdn.adapools.org/pool_logo/febb924e349d816997e007dc09847ded9a2e72d218023c0e5186176d.png",
    pool_id: "febb924e349d816997e007dc09847ded9a2e72d218023c0e5186176d",
    db_ticker: "OYSTR",
    db_name: "Oyster Pool",
    db_url: "https://twitter.com/OystrPool",
    roa: "0.835",
    roa_lifetime: "4.834",
    total_stake: "855356975790",
    stake_x_deleg: "6529442563.2824",
    saturated: "0.012442628442276",
    luck_lifetime: "108",
    blocks_epoch: "1",
    blocks_lifetime: "369",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "556000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Felix Pham",
    icon: "https://cdn.adapools.org/pool_logo/901c5c323e73f39731a38e9cab770d538aebd38eda89166f0f4eda9c.png",
    pool_id: "901c5c323e73f39731a38e9cab770d538aebd38eda89166f0f4eda9c",
    db_ticker: "LIX",
    db_name: "Felix Pham",
    db_url: "https://www.felixpham.com/",
    roa: "3.534",
    roa_lifetime: "4.332",
    total_stake: "857435448393",
    stake_x_deleg: "2748190539.7212",
    saturated: "0.012472863376997",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "474",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "80000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano CORE Staking Pool",
    icon: "0",
    pool_id: "3412ef6c600a5092f48fd3f880d75b566f1e11ad8d3ee29317c4e03b",
    db_ticker: "CORE",
    db_name: "Cardano CORE Staking Pool",
    db_url: "https://www.adacore.io",
    roa: "2.311",
    roa_lifetime: "3.948",
    total_stake: "977527543336",
    stake_x_deleg: "23842135203.317",
    saturated: "0.014219808054511",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "345",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "600000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Climate_Action_Fund",
    icon: "https://cdn.adapools.org/pool_logo/0036c901c2c2221798e5e96edd7d0e600077114ed6f067e21d302a6d.png",
    pool_id: "0036c901c2c2221798e5e96edd7d0e600077114ed6f067e21d302a6d",
    db_ticker: "CLIMA",
    db_name: "Climate_Action_Fund",
    db_url: "https://climateactionfundgroup.com",
    roa: "2.889",
    roa_lifetime: "4.274",
    total_stake: "874759102772",
    stake_x_deleg: "2840126957.0519",
    saturated: "0.012724865524406",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "466",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "55000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CheapStaking #2 - PublicPrivate",
    icon: "https://cdn.adapools.org/pool_logo/e7234026db910ce0ee214056940de0474b252456c3d70907f831eaf3.png",
    pool_id: "e7234026db910ce0ee214056940de0474b252456c3d70907f831eaf3",
    db_ticker: "CHEAP",
    db_name: "CheapStaking #2 - PublicPrivate",
    db_url: "https://cheapstaking.com",
    roa: "5.209",
    roa_lifetime: "4.019",
    total_stake: "3902007833816",
    stake_x_deleg: "67275997134.759",
    saturated: "0.056761369848163",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "468",
    tax_ratio: "0.009",
    tax_fix: "500000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AGAPEpool",
    icon: "0",
    pool_id: "2a46b8dba635aac1d4d174fbd0cd7c7f3ef6c330e7bce483bd76297a",
    db_ticker: "AGAPE",
    db_name: "AGAPEpool",
    db_url: "https://agapepool.ca",
    roa: "2.073",
    roa_lifetime: "4.949",
    total_stake: "1238288716098",
    stake_x_deleg: "13913356360.652",
    saturated: "0.018013024777684",
    luck_lifetime: "108",
    blocks_epoch: "2",
    blocks_lifetime: "603",
    tax_ratio: "0.0499",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hazelpool",
    icon: "https://cdn.adapools.org/pool_logo/be80794a946cf5e578846fc81e3c62ac13f4ab3335e0f5dc046edad4.png",
    pool_id: "be80794a946cf5e578846fc81e3c62ac13f4ab3335e0f5dc046edad4",
    db_ticker: "HAZEL",
    db_name: "Hazelpool",
    db_url: "https://www.hazelpool.com",
    roa: "3.79",
    roa_lifetime: "2.354",
    total_stake: "943749958905",
    stake_x_deleg: "8903301499.1038",
    saturated: "0.013728455385803",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "37",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "164000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Patience 辛抱",
    icon: "https://cdn.adapools.org/pool_logo/42aebb6bd342c6ea6c5a9851c8af88de3804a38ff9b67dab4df1bc57.png",
    pool_id: "42aebb6bd342c6ea6c5a9851c8af88de3804a38ff9b67dab4df1bc57",
    db_ticker: "PATI⏳",
    db_name: "Patience 辛抱",
    db_url: "https://www.patienceovertime.com",
    roa: "2.084",
    roa_lifetime: "3.355",
    total_stake: "854234812287",
    stake_x_deleg: "19414427551.977",
    saturated: "0.012426304657103",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "151",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Devil's Delegate",
    icon: "https://cdn.adapools.org/pool_logo/9cb4471bad9979623d32ad3c14ff14b340f046e2a5b3ae2e3c8f575b.png",
    pool_id: "9cb4471bad9979623d32ad3c14ff14b340f046e2a5b3ae2e3c8f575b",
    db_ticker: "DD666",
    db_name: "Devil's Delegate",
    db_url: "https://devilsdelegate.com",
    roa: "4.053",
    roa_lifetime: "3.692",
    total_stake: "943942267826",
    stake_x_deleg: "23022982142.098",
    saturated: "0.013731252847585",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "261",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "425000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nomad Pool",
    icon: "0",
    pool_id: "bbebbbf81c42de5b84fbbc82c4feab78f8bd8bcf8b5af7c73a06664a",
    db_ticker: "NOMAD",
    db_name: "Nomad Pool",
    db_url: "https://nomadpool.io",
    roa: "4.689",
    roa_lifetime: "2.944",
    total_stake: "1141596765425",
    stake_x_deleg: "87815135801.923",
    saturated: "0.016606475173676",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "52",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CanadaStakes",
    icon: "https://cdn.adapools.org/pool_logo/566ebd3406088123f8dcc99630f122520db5d479e661c72b43571627.png",
    pool_id: "566ebd3406088123f8dcc99630f122520db5d479e661c72b43571627",
    db_ticker: "CAN1",
    db_name: "CanadaStakes",
    db_url: "https://www.canadastakes.ca",
    roa: "4.25",
    roa_lifetime: "2.958",
    total_stake: "898195483223",
    stake_x_deleg: "28068608850.719",
    saturated: "0.013065787715068",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "72",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Oyaji Stake Pool",
    icon: "0",
    pool_id: "1f7f6c5b7c0aa91b78b7e86b8bdb95a3c0485f22bd61d848177fd66e",
    db_ticker: "OYAJI",
    db_name: "Oyaji Stake Pool",
    db_url: "https://twitter.com/N_oyaji_ada",
    roa: "4.763",
    roa_lifetime: "4.004",
    total_stake: "1788780547611",
    stake_x_deleg: "105222385153.59",
    saturated: "0.026020868887096",
    luck_lifetime: "102",
    blocks_epoch: "1",
    blocks_lifetime: "159",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FAITH Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/383696c7f29a9a49c1da49ed35bebbd6097cea5b58a95da5c7df27ee.png",
    pool_id: "383696c7f29a9a49c1da49ed35bebbd6097cea5b58a95da5c7df27ee",
    db_ticker: "FAITH",
    db_name: "FAITH Stake Pool",
    db_url: "https://faithpool.io",
    roa: "5.113",
    roa_lifetime: "2.545",
    total_stake: "1050416204975",
    stake_x_deleg: "50019819284.524",
    saturated: "0.015280098155718",
    luck_lifetime: "87",
    blocks_epoch: "2",
    blocks_lifetime: "53",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "110000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanode: 0.00% Fee and 100% Rewards Guaranteed",
    icon: "https://cdn.adapools.org/pool_logo/fdb0d8ba88d434c8dd3ab3974a3401efc7b016e54c7387c63398aec4.png",
    pool_id: "fdb0d8ba88d434c8dd3ab3974a3401efc7b016e54c7387c63398aec4",
    db_ticker: "FUND2",
    db_name: "Cardanode: 0.00% Fee and 100% Rewards Guaranteed",
    db_url: "https://www.cardanode.io",
    roa: "4.007",
    roa_lifetime: "1.724",
    total_stake: "886450164790",
    stake_x_deleg: "26862126205.758",
    saturated: "0.012894931993615",
    luck_lifetime: "66",
    blocks_epoch: "2",
    blocks_lifetime: "12",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OHOH7 Staking",
    icon: "https://cdn.adapools.org/pool_logo/d4b5b8d5c83e97b2cd90a0fd818f3c9d8e7388bcc7706327a92d2092.png",
    pool_id: "d4b5b8d5c83e97b2cd90a0fd818f3c9d8e7388bcc7706327a92d2092",
    db_ticker: "OHOH7",
    db_name: "OHOH7 Staking",
    db_url: "https://ohoh7.github.io",
    roa: "2.196",
    roa_lifetime: "3.651",
    total_stake: "864769679288",
    stake_x_deleg: "43238483964.4",
    saturated: "0.01257955229463",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "107",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Universe",
    icon: "0",
    pool_id: "4f29af22aadfd854fb33cdbc2cdcb5c3fb7594eead5bcf41455bf3f1",
    db_ticker: "ADAU",
    db_name: "Cardano Universe",
    db_url: "https://cardano-universe.com",
    roa: "2.473",
    roa_lifetime: "4.632",
    total_stake: "1067357651861",
    stake_x_deleg: "12706638712.631",
    saturated: "0.015526540442206",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "768",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Staking Pool",
    icon: "0",
    pool_id: "ab93ac9ffaabacb840a98bd7216e8bb9f87a33f88c315e7dc7f2d20b",
    db_ticker: "THRIV",
    db_name: "Cardano Staking Pool",
    db_url: "https://cardanostakingpool.com/",
    roa: "4.097",
    roa_lifetime: "4.446",
    total_stake: "978967210491",
    stake_x_deleg: "14611450902.851",
    saturated: "0.014240750472703",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "487",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "910000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Texan_Stakes",
    icon: "0",
    pool_id: "9d1efdf43a630ceb1a63753656237200f6be9b9c359006085ed14b8f",
    db_ticker: "TEXAN",
    db_name: "Texan_Stakes",
    db_url: "https://texanstakes.com/",
    roa: "3.259",
    roa_lifetime: "2.814",
    total_stake: "1052488227866",
    stake_x_deleg: "19136149597.564",
    saturated: "0.01531023926836",
    luck_lifetime: "79",
    blocks_epoch: "1",
    blocks_lifetime: "133",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "630000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "INDIE",
    icon: "0",
    pool_id: "b2f23eb98a5189d3eaab04885081495ef815fd08239605c43eeb7264",
    db_ticker: "INDIE",
    db_name: "INDIE",
    db_url: "https://indiepool.co/",
    roa: "3.471",
    roa_lifetime: "4.508",
    total_stake: "1080666495838",
    stake_x_deleg: "34860209543.161",
    saturated: "0.015720140313709",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "601",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kyoto Stake Pool",
    icon: "0",
    pool_id: "144b0b64741e666be425df847ef72a25e9a2d5e8712c15a7097d235f",
    db_ticker: "KYOTO",
    db_name: "Kyoto Stake Pool",
    db_url: "https://kyotostakepool.com",
    roa: "2.558",
    roa_lifetime: "3.805",
    total_stake: "1035295236788",
    stake_x_deleg: "27980952345.622",
    saturated: "0.01506013784188",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "168",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoProxy",
    icon: "0",
    pool_id: "655c82616621bf8495e05a13f14d944d538deb04788efde2f85e6cb5",
    db_ticker: "CPX",
    db_name: "CardanoProxy",
    db_url: "https://cardanoproxy.com",
    roa: "0.373",
    roa_lifetime: "3.521",
    total_stake: "1016117179616",
    stake_x_deleg: "32777973536",
    saturated: "0.014781160237921",
    luck_lifetime: "84",
    blocks_epoch: "1",
    blocks_lifetime: "187",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "305000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "888POOL.IO",
    icon: "https://cdn.adapools.org/pool_logo/7c5fd84421a68f545d09ad8580b2a154a7bb6f79f4a3a8acb4d15e27.png",
    pool_id: "7c5fd84421a68f545d09ad8580b2a154a7bb6f79f4a3a8acb4d15e27",
    db_ticker: "888",
    db_name: "888POOL.IO",
    db_url: "https://888pool.io",
    roa: "1.529",
    roa_lifetime: "3.133",
    total_stake: "1197212683715",
    stake_x_deleg: "23474758504.216",
    saturated: "0.017415503715379",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "97",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Colony Pool",
    icon: "https://cdn.adapools.org/pool_logo/6381c4d5cbaea9fbdb2bc1152f49c1153fafbf19d77c547624ab91e4.png",
    pool_id: "6381c4d5cbaea9fbdb2bc1152f49c1153fafbf19d77c547624ab91e4",
    db_ticker: "CCP",
    db_name: "Crypto Colony Pool",
    db_url: "https://cryptocolony.cz",
    roa: "1.732",
    roa_lifetime: "2.941",
    total_stake: "1331858994571",
    stake_x_deleg: "22573881263.915",
    saturated: "0.019374164326706",
    luck_lifetime: "111",
    blocks_epoch: "1",
    blocks_lifetime: "36",
    tax_ratio: "0.0095",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANIANS.io #1",
    icon: "https://cdn.adapools.org/pool_logo/ed24d8995c6fd81d976baa03de565fea21a02d77d49b047064ba8445.png",
    pool_id: "ed24d8995c6fd81d976baa03de565fea21a02d77d49b047064ba8445",
    db_ticker: "CRDN",
    db_name: "CARDANIANS.io #1",
    db_url: "https://cardanians.io",
    roa: "4.474",
    roa_lifetime: "3.858",
    total_stake: "1593598908703",
    stake_x_deleg: "4034427616.9696",
    saturated: "0.023181618515117",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "566",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "0% Fees! High-Yield Canadian Pool ",
    icon: "https://cdn.adapools.org/pool_logo/4af5266d2b3ad11fb848d393008ff934678e02989e2ce263ac05a80d.png",
    pool_id: "4af5266d2b3ad11fb848d393008ff934678e02989e2ce263ac05a80d",
    db_ticker: "FRCA🍁",
    db_name: "0% Fees! High-Yield Canadian Pool ",
    db_url: "https://www.frcan.com/",
    roa: "3.792",
    roa_lifetime: "3.576",
    total_stake: "783336894043",
    stake_x_deleg: "11032914000.606",
    saturated: "0.011394973319416",
    luck_lifetime: "112",
    blocks_epoch: "1",
    blocks_lifetime: "50",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DOLCA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/550f5dc96814537404c7544175d348262699e448d74005dfa26540d3.png",
    pool_id: "550f5dc96814537404c7544175d348262699e448d74005dfa26540d3",
    db_ticker: "DOLCA",
    db_name: "DOLCA Stake Pool",
    db_url: "https://dolcapool.eu",
    roa: "0.882",
    roa_lifetime: "4.48",
    total_stake: "837688743285",
    stake_x_deleg: "7685217828.3028",
    saturated: "0.012185613817372",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "329",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOTO Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/d5e493b834ee233104cff9d141ffae2d8d76a563f829e78987540046.png",
    pool_id: "d5e493b834ee233104cff9d141ffae2d8d76a563f829e78987540046",
    db_ticker: "MOTO",
    db_name: "MOTO Stake Pool",
    db_url: "https://motostakepool.com",
    roa: "3.08",
    roa_lifetime: "2.806",
    total_stake: "1513849931145",
    stake_x_deleg: "126154160928.75",
    saturated: "0.022021533399205",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "32",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Universe 25",
    icon: "https://cdn.adapools.org/pool_logo/27f3f042175fcf8a85babce3e5777e2d0c2dcd1927abbbb2b7493787.png",
    pool_id: "27f3f042175fcf8a85babce3e5777e2d0c2dcd1927abbbb2b7493787",
    db_ticker: "UNV25",
    db_name: "Universe 25",
    db_url: "https://www.universe25.io/",
    roa: "1.66",
    roa_lifetime: "2.869",
    total_stake: "821213344881",
    stake_x_deleg: "2451383119.0478",
    saturated: "0.011945951002218",
    luck_lifetime: "125",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.0042",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lab44 Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/a69c888f3f42ff777294145c10706be8468e65392ac43edafc01098d.png",
    pool_id: "a69c888f3f42ff777294145c10706be8468e65392ac43edafc01098d",
    db_ticker: "LAB44",
    db_name: "Lab44 Stakepool",
    db_url: "http://lab44.io",
    roa: "0.81",
    roa_lifetime: "4.074",
    total_stake: "874619628615",
    stake_x_deleg: "36442484525.625",
    saturated: "0.012722836634525",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "293",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/2d790b2ae76601a238e53429d80d8e5290f3db0927946224739d91a0.png",
    pool_id: "2d790b2ae76601a238e53429d80d8e5290f3db0927946224739d91a0",
    db_ticker: "BLOCK",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "2.489",
    roa_lifetime: "3.969",
    total_stake: "755831682062",
    stake_x_deleg: "15425136368.612",
    saturated: "0.010994863023256",
    luck_lifetime: "109",
    blocks_epoch: "1",
    blocks_lifetime: "81",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IT_SYS_POOL",
    icon: "0",
    pool_id: "ef5962c7ecb81378591d897d0eb7ef745ebe7cb00d0fe45fc33ed7a5",
    db_ticker: "ITSYS",
    db_name: "IT_SYS_POOL",
    db_url: "https://itsys.com.au/cardano",
    roa: "3.579",
    roa_lifetime: "4.244",
    total_stake: "879593588785",
    stake_x_deleg: "87959358878.5",
    saturated: "0.012795191382348",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "385",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everest Summit Pool - 0PCT Fee",
    icon: "https://cdn.adapools.org/pool_logo/6f365275ed3b789fc7bf4d4d0b769dc70117542aee93ca328f94ee53.png",
    pool_id: "6f365275ed3b789fc7bf4d4d0b769dc70117542aee93ca328f94ee53",
    db_ticker: "ESP",
    db_name: "Everest Summit Pool - 0PCT Fee",
    db_url: "https://everestada.com/",
    roa: "3.196",
    roa_lifetime: "4.668",
    total_stake: "1325312709818",
    stake_x_deleg: "6866905232.2176",
    saturated: "0.019278937431779",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "356",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XOR NAND",
    icon: "https://cdn.adapools.org/pool_logo/6a37c771eb33798edea1cc2b95759fc30a7765bbfc63668bdc92c633.png",
    pool_id: "6a37c771eb33798edea1cc2b95759fc30a7765bbfc63668bdc92c633",
    db_ticker: "XORN",
    db_name: "XOR NAND",
    db_url: "https://www.xor-nand.io/",
    roa: "4.938",
    roa_lifetime: "3.902",
    total_stake: "846964300332",
    stake_x_deleg: "47053572240.667",
    saturated: "0.012320542640305",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "133",
    tax_ratio: "0.013",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano.Amsterdam",
    icon: "0",
    pool_id: "40e5daacb89e62565395417ae78392dee9b0ccc00c2e0253f3031f91",
    db_ticker: "AMS02",
    db_name: "Cardano.Amsterdam",
    db_url: "https://cardano.amsterdam",
    roa: "2.334",
    roa_lifetime: "2.834",
    total_stake: "962877031608",
    stake_x_deleg: "56639825388.706",
    saturated: "0.014006691333562",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "79",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lion Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/ba8afcb79a251e47979fee3cbd5878e7fb7b153270986adc19063df5.png",
    pool_id: "ba8afcb79a251e47979fee3cbd5878e7fb7b153270986adc19063df5",
    db_ticker: "LION",
    db_name: "Lion Staking Pool",
    db_url: "https://lionstakingpool.com/",
    roa: "3.898",
    roa_lifetime: "3.025",
    total_stake: "776443664619",
    stake_x_deleg: "38822183230.95",
    saturated: "0.011294699521554",
    luck_lifetime: "95",
    blocks_epoch: "2",
    blocks_lifetime: "91",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nora Neko Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/074c396a747cfa8f83629503c0520d7f5c8215d306c960bb78edeac1.png",
    pool_id: "074c396a747cfa8f83629503c0520d7f5c8215d306c960bb78edeac1",
    db_ticker: "🐈NNSP",
    db_name: "Nora Neko Stake Pool",
    db_url: "https://twitter.com/7nekonymous",
    roa: "3.85",
    roa_lifetime: "3.414",
    total_stake: "785692378102",
    stake_x_deleg: "46217198711.882",
    saturated: "0.011429237858991",
    luck_lifetime: "109",
    blocks_epoch: "1",
    blocks_lifetime: "78",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adastrong-Two",
    icon: "0",
    pool_id: "4366857b44e3464c961500ffa27dce993144f50bc06b50bb00da09a5",
    db_ticker: "ADAST",
    db_name: "Adastrong-Two",
    db_url: "https://www.adastrong.com/",
    roa: "0.707",
    roa_lifetime: "4.56",
    total_stake: "961653215138",
    stake_x_deleg: "14570503259.667",
    saturated: "0.013988888832328",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "1699",
    tax_ratio: "0.039",
    tax_fix: "345000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano ADEN Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/e2be8bcd95ee62227a2484be8619d2f2f800ec89eb2deacaa743d130.png",
    pool_id: "e2be8bcd95ee62227a2484be8619d2f2f800ec89eb2deacaa743d130",
    db_ticker: "ADEN",
    db_name: "Cardano ADEN Stake Pool",
    db_url: "https://cardano-aden.com",
    roa: "2.089",
    roa_lifetime: "4.084",
    total_stake: "1056407182613",
    stake_x_deleg: "29344643961.472",
    saturated: "0.015367247160012",
    luck_lifetime: "115",
    blocks_epoch: "0",
    blocks_lifetime: "125",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "{SAFE}STAK",
    icon: "0",
    pool_id: "74a10b8241fc67a17e189a58421506b7edd629ac490234933afbed97",
    db_ticker: "SAFE",
    db_name: "{SAFE}STAK",
    db_url: "https://www.safestak.com",
    roa: "2.08",
    roa_lifetime: "3.86",
    total_stake: "886259937428",
    stake_x_deleg: "21101427081.619",
    saturated: "0.012892164811664",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "381",
    tax_ratio: "0.028",
    tax_fix: "340000000",
    pledge: "550000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KnockturnAlley",
    icon: "https://cdn.adapools.org/pool_logo/991a64a6e3d866f4af4e0a2bfd61c15486a47ccc352e61e8a6b4fef8.png",
    pool_id: "991a64a6e3d866f4af4e0a2bfd61c15486a47ccc352e61e8a6b4fef8",
    db_ticker: "KTA",
    db_name: "KnockturnAlley",
    db_url: "https://knockturnalley.io",
    roa: "2.722",
    roa_lifetime: "4.266",
    total_stake: "810592768648",
    stake_x_deleg: "90065863183.111",
    saturated: "0.011791456577492",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "225",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "8BALL",
    icon: "0",
    pool_id: "8ba11724b0b39f34223d166a752da604269db13562095d6386a14bb3",
    db_ticker: "8BALL",
    db_name: "8BALL",
    db_url: "https://metablx.com",
    roa: "1.574",
    roa_lifetime: "3.652",
    total_stake: "984643988715",
    stake_x_deleg: "51823367827.105",
    saturated: "0.014323328909764",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "122",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "750000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CHOCO Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/552d7f65e42fdc4ed602a657b85bd7623a592d21e5dcf7b045132da8.png",
    pool_id: "552d7f65e42fdc4ed602a657b85bd7623a592d21e5dcf7b045132da8",
    db_ticker: "CHOCO",
    db_name: "CHOCO Stake Pool",
    db_url: "https://twitter.com/choco_stake",
    roa: "3.265",
    roa_lifetime: "3.662",
    total_stake: "3991160166540",
    stake_x_deleg: "199558008327",
    saturated: "0.05805824282897",
    luck_lifetime: "101",
    blocks_epoch: "4",
    blocks_lifetime: "149",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DADA Pool",
    icon: "0",
    pool_id: "c89501f87a5893dd43c270ec601a9fe905bb65429c09d8bb4b2f7ef8",
    db_ticker: "DADA",
    db_name: "DADA Pool",
    db_url: "https://adadada.club",
    roa: "1.331",
    roa_lifetime: "3.674",
    total_stake: "815753703660",
    stake_x_deleg: "23992755990",
    saturated: "0.011866531193806",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "287",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "True Staking",
    icon: "https://cdn.adapools.org/pool_logo/b6a57cb78218fb7062335c6eb082e1bcf3eafd4aaa715a1002025059.png",
    pool_id: "b6a57cb78218fb7062335c6eb082e1bcf3eafd4aaa715a1002025059",
    db_ticker: "TRU",
    db_name: "True Staking",
    db_url: "https://truestaking.com",
    roa: "2.07",
    roa_lifetime: "3.937",
    total_stake: "651222204929",
    stake_x_deleg: "3995228251.0982",
    saturated: "0.0094731394711634",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "357",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "215000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Metro Mermaids",
    icon: "https://cdn.adapools.org/pool_logo/2ce9bfc4e0a732d21ec8739cbfc3b10957b4e3297fd320d556f48ada.png",
    pool_id: "2ce9bfc4e0a732d21ec8739cbfc3b10957b4e3297fd320d556f48ada",
    db_ticker: "TAILS",
    db_name: "Metro Mermaids",
    db_url: "https://metromermaids.com",
    roa: "3.9",
    roa_lifetime: "3.702",
    total_stake: "803987199346",
    stake_x_deleg: "1710611062.4383",
    saturated: "0.011695367287522",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "301",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50555000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "POWER Stake Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/d93d0cb0cbc55a964b4ad01fc9001cb4d69ea98fe708d069cd398f76.png",
    pool_id: "d93d0cb0cbc55a964b4ad01fc9001cb4d69ea98fe708d069cd398f76",
    db_ticker: "POWER",
    db_name: "POWER Stake Pool 2",
    db_url: "https://powerstakepool.com",
    roa: "1.57",
    roa_lifetime: "3.583",
    total_stake: "740477266104",
    stake_x_deleg: "3836669772.5596",
    saturated: "0.010771506812784",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "127",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MoxiePool",
    icon: "https://cdn.adapools.org/pool_logo/26d2e140cb8d11143501d7883d1cf5a6adba156e1f8f27d327f10b57.png",
    pool_id: "26d2e140cb8d11143501d7883d1cf5a6adba156e1f8f27d327f10b57",
    db_ticker: "MOXIE",
    db_name: "MoxiePool",
    db_url: "https://moxietech.co",
    roa: "8.719",
    roa_lifetime: "4.466",
    total_stake: "637774011732",
    stake_x_deleg: "8982732559.6056",
    saturated: "0.0092775125272016",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "472",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "77000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Retro Pool",
    icon: "https://cdn.adapools.org/pool_logo/93728a5a67dfbcb3fa2ce2425bac852a7ef4c6fc624fc113f6511a2a.png",
    pool_id: "93728a5a67dfbcb3fa2ce2425bac852a7ef4c6fc624fc113f6511a2a",
    db_ticker: "RETRO",
    db_name: "Retro Pool",
    db_url: "https://cardano.teamretro.com.au",
    roa: "0.561",
    roa_lifetime: "3.322",
    total_stake: "579912625019",
    stake_x_deleg: "3332831178.2701",
    saturated: "0.0084358198112923",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "123",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA 180",
    icon: "0",
    pool_id: "5c83181bf753299cdf5ee2f654fc68ece2add493cefc06eec3483b39",
    db_ticker: "A180",
    db_name: "ADA 180",
    db_url: "https://www.ada180.com/",
    roa: "0",
    roa_lifetime: "2.374",
    total_stake: "757449950866",
    stake_x_deleg: "8807557568.2093",
    saturated: "0.011018403507543",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "22",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "80000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardaPool",
    icon: "https://cdn.adapools.org/pool_logo/59ebe72ae96462018fbe04633100f90b3066688d85f00f3bd254707f.png",
    pool_id: "59ebe72ae96462018fbe04633100f90b3066688d85f00f3bd254707f",
    db_ticker: "CAPO",
    db_name: "CardaPool",
    db_url: "https://cardapool.com",
    roa: "0.968",
    roa_lifetime: "2.701",
    total_stake: "873117095488",
    stake_x_deleg: "1247310136.4114",
    saturated: "0.012700979723375",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "55",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DOXA",
    icon: "https://cdn.adapools.org/pool_logo/a6d3691e1ff660bc6f6da75feea466a26b7f8a21c0f41d74940a7639.png",
    pool_id: "a6d3691e1ff660bc6f6da75feea466a26b7f8a21c0f41d74940a7639",
    db_ticker: "DOXA",
    db_name: "DOXA",
    db_url: "https://doxacrypto.io",
    roa: "4.811",
    roa_lifetime: "4.535",
    total_stake: "722938454444",
    stake_x_deleg: "12909615257.929",
    saturated: "0.010516374835164",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "433",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "320000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amsterdam Node",
    icon: "https://cdn.adapools.org/pool_logo/e0079c2670ee8206a962d3c6ffe255b7277c546371fff68c2d624c50.png",
    pool_id: "e0079c2670ee8206a962d3c6ffe255b7277c546371fff68c2d624c50",
    db_ticker: "AMS",
    db_name: "Amsterdam Node",
    db_url: "http://www.amsterdamnode.com",
    roa: "3.598",
    roa_lifetime: "3.783",
    total_stake: "738851653187",
    stake_x_deleg: "10865465488.044",
    saturated: "0.010747859495828",
    luck_lifetime: "108",
    blocks_epoch: "1",
    blocks_lifetime: "102",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HUAT 發 Singapore",
    icon: "https://cdn.adapools.org/pool_logo/c406bea064ed6f8a07385e14f81e1eeee39d302d94b5d9ac0264d5f4.png",
    pool_id: "c406bea064ed6f8a07385e14f81e1eeee39d302d94b5d9ac0264d5f4",
    db_ticker: "HUAT",
    db_name: "HUAT 發 Singapore",
    db_url: "https://huatpool.com",
    roa: "2.185",
    roa_lifetime: "4.412",
    total_stake: "682047318999",
    stake_x_deleg: "20668100575.727",
    saturated: "0.009921543414686",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "215",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool in Germany with 0% Margin and high Pledge",
    icon: "0",
    pool_id: "ba98b30a3ad9639535c5dcebfe5f558f94bf16a084b0142e2900664c",
    db_ticker: "FRITZ",
    db_name: "Pool in Germany with 0% Margin and high Pledge",
    db_url: "https://fritz-stake-pool.com/",
    roa: "0.442",
    roa_lifetime: "2.203",
    total_stake: "825432215336",
    stake_x_deleg: "30571563530.963",
    saturated: "0.012007321680196",
    luck_lifetime: "72",
    blocks_epoch: "1",
    blocks_lifetime: "49",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO2 Pool",
    icon: "0",
    pool_id: "c4a4b16b33b63da2117f4a84d3ea686f34fcf9f4603ce016af9e39aa",
    db_ticker: "LEO2",
    db_name: "LEO2 Pool",
    db_url: "https://staking-fm.site",
    roa: "3.57",
    roa_lifetime: "4.068",
    total_stake: "17685113272004",
    stake_x_deleg: "1965012585778.2",
    saturated: "0.25726018449768",
    luck_lifetime: "99",
    blocks_epoch: "19",
    blocks_lifetime: "4727",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Prometheus Pool",
    icon: "0",
    pool_id: "1b5f8eb4c3de5274fca5cab708ac8b100feaed5db8fbdc9923b8663d",
    db_ticker: "PROM",
    db_name: "Prometheus Pool",
    db_url: "https://www.prometheus-pool.com",
    roa: "4.252",
    roa_lifetime: "4.083",
    total_stake: "871579571765",
    stake_x_deleg: "96842174640.556",
    saturated: "0.012678613814231",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "122",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "800000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/c2a9551cf5a0f7e80558974b2476e11d2dffa8b01c9a215cf50f0f5e.png",
    pool_id: "c2a9551cf5a0f7e80558974b2476e11d2dffa8b01c9a215cf50f0f5e",
    db_ticker: "CODE",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "4.018",
    roa_lifetime: "4.186",
    total_stake: "645317253407",
    stake_x_deleg: "30729393019.381",
    saturated: "0.0093872418636879",
    luck_lifetime: "117",
    blocks_epoch: "0",
    blocks_lifetime: "248",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tech pool",
    icon: "https://cdn.adapools.org/pool_logo/a60950c7a6e3f7a8247b34035d0a98cb0d11f2d04ba5fa9fd17e1065.png",
    pool_id: "a60950c7a6e3f7a8247b34035d0a98cb0d11f2d04ba5fa9fd17e1065",
    db_ticker: "TECH",
    db_name: "Tech pool",
    db_url: "https://cardanotech.io/",
    roa: "7.375",
    roa_lifetime: "3.332",
    total_stake: "724691270061",
    stake_x_deleg: "26840417409.667",
    saturated: "0.010541872532695",
    luck_lifetime: "120",
    blocks_epoch: "0",
    blocks_lifetime: "57",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "101000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bring ADA around the World",
    icon: "0",
    pool_id: "9e111a78364194546a43772762d42e366af2d96620dc783edad31d6c",
    db_ticker: "BIMI1",
    db_name: "Bring ADA around the World",
    db_url: "",
    roa: "2.488",
    roa_lifetime: "2.472",
    total_stake: "1567775577918",
    stake_x_deleg: "111983969851.29",
    saturated: "0.022805974054156",
    luck_lifetime: "86",
    blocks_epoch: "2",
    blocks_lifetime: "24",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mirabilia",
    icon: "https://cdn.adapools.org/pool_logo/fd9f7e46723207d8e7684f7d1568f9f9ca011179c252f488453abac6.png",
    pool_id: "fd9f7e46723207d8e7684f7d1568f9f9ca011179c252f488453abac6",
    db_ticker: "IMPI",
    db_name: "Mirabilia",
    db_url: "https://www.mirabiliacrypto.com",
    roa: "0.513",
    roa_lifetime: "4.421",
    total_stake: "701232707029",
    stake_x_deleg: "43827044189.312",
    saturated: "0.010200627658499",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "291",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The White Project",
    icon: "https://cdn.adapools.org/pool_logo/8fa4537044c02b232430dca78f51ca74c22d1ed7fb990be8b65d2794.png",
    pool_id: "8fa4537044c02b232430dca78f51ca74c22d1ed7fb990be8b65d2794",
    db_ticker: "WHITE",
    db_name: "The White Project",
    db_url: "https://white.topopool.com",
    roa: "4.64",
    roa_lifetime: "4.113",
    total_stake: "2584946836426",
    stake_x_deleg: "12989682595.106",
    saturated: "0.037602467670272",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "291",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fly Pool",
    icon: "0",
    pool_id: "99948f34582bf9cafb27b05f4f0e8541c5480b4c79bda74037398380",
    db_ticker: "FLY",
    db_name: "Fly Pool",
    db_url: "https://flycardano.com/",
    roa: "3.271",
    roa_lifetime: "1.222",
    total_stake: "1027310891074",
    stake_x_deleg: "205462178214.8",
    saturated: "0.014943991893597",
    luck_lifetime: "47",
    blocks_epoch: "0",
    blocks_lifetime: "25",
    tax_ratio: "0.0147",
    tax_fix: "340000000",
    pledge: "1000006619057",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO3 Pool",
    icon: "0",
    pool_id: "20b95f87c93f3be9e5094c8fbdb924f9ab8b5e1c7bfb542d3a8bc7fc",
    db_ticker: "LEO3",
    db_name: "LEO3 Pool",
    db_url: "https://staking-fm.site",
    roa: "3.012",
    roa_lifetime: "3.914",
    total_stake: "15766834886016",
    stake_x_deleg: "1970854360752",
    saturated: "0.22935554832673",
    luck_lifetime: "96",
    blocks_epoch: "16",
    blocks_lifetime: "4883",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Luminapool",
    icon: "0",
    pool_id: "b5b762b66218e4b9c3325ac8dce360d44d5686df9f9e44ff2dee5b39",
    db_ticker: "LUM",
    db_name: "Luminapool",
    db_url: "https://luminapool.io",
    roa: "1.818",
    roa_lifetime: "3.631",
    total_stake: "808399863406",
    stake_x_deleg: "67366655283.833",
    saturated: "0.011759557024548",
    luck_lifetime: "98",
    blocks_epoch: "2",
    blocks_lifetime: "169",
    tax_ratio: "0.0238",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CloudStruct Cardano Staking",
    icon: "0",
    pool_id: "27b1b4470c84db78ce1ffbfff77bb068abb4e47d43cb6009caaa3523",
    db_ticker: "CSCS",
    db_name: "CloudStruct Cardano Staking",
    db_url: "https://cardano.cloudstruct.net",
    roa: "3.638",
    roa_lifetime: "3.622",
    total_stake: "15295779705039",
    stake_x_deleg: "402520518553.66",
    saturated: "0.22250324600314",
    luck_lifetime: "94",
    blocks_epoch: "14",
    blocks_lifetime: "133",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "8000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sonarch Pool",
    icon: "0",
    pool_id: "fb05a2dfaed44ea665a40ddc1bfa7079e63eaf75d166c5774ded4960",
    db_ticker: "ARCH1",
    db_name: "Sonarch Pool",
    db_url: "https://sonarch.io",
    roa: "0",
    roa_lifetime: "2.271",
    total_stake: "791442450222",
    stake_x_deleg: "65953537518.5",
    saturated: "0.01151288248098",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "40",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PLANTA CRYPTO",
    icon: "https://cdn.adapools.org/pool_logo/6bd9b29295a7e24520a1eecd5a5ab9a94f3ffeed3b89551cac71f6bf.png",
    pool_id: "6bd9b29295a7e24520a1eecd5a5ab9a94f3ffeed3b89551cac71f6bf",
    db_ticker: "PLANT",
    db_name: "PLANTA CRYPTO",
    db_url: "https://www.plantacrypto.com",
    roa: "8.697",
    roa_lifetime: "4.482",
    total_stake: "805903675282",
    stake_x_deleg: "12592244926.281",
    saturated: "0.011723245703979",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "345",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "90000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kobe ADA POOL",
    icon: "0",
    pool_id: "505c13dff37e250331f7575f5d4410edad776569b900ef305b0b9aa9",
    db_ticker: "KAP",
    db_name: "Kobe ADA POOL",
    db_url: "https://twitter.com/kobe_ada_pool",
    roa: "0.811",
    roa_lifetime: "3.184",
    total_stake: "903098061319",
    stake_x_deleg: "129014008759.86",
    saturated: "0.01313710408868",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "55",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Block'n'Roll",
    icon: "0",
    pool_id: "8139fa7f71f09e525a2462a253b6131795418fd90c2d0234d805bd94",
    db_ticker: "BNR",
    db_name: "Block'n'Roll",
    db_url: "https://blocknroll101.github.io",
    roa: "2.326",
    roa_lifetime: "2.992",
    total_stake: "803101201065",
    stake_x_deleg: "100387650133.12",
    saturated: "0.01168247892895",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "67",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "600000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stoic Pool",
    icon: "https://cdn.adapools.org/pool_logo/099d512b6ffedd552ae38a9ae2123c116b1d20e1f01bb42a1f539fba.png",
    pool_id: "099d512b6ffedd552ae38a9ae2123c116b1d20e1f01bb42a1f539fba",
    db_ticker: "STOIC",
    db_name: "Stoic Pool",
    db_url: "https://stoicpool.com",
    roa: "0.629",
    roa_lifetime: "3.047",
    total_stake: "962289227219",
    stake_x_deleg: "8154993451.0085",
    saturated: "0.013998140714562",
    luck_lifetime: "78",
    blocks_epoch: "2",
    blocks_lifetime: "130",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "26800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Global UK Staking Pool by the Three Lions",
    icon: "https://cdn.adapools.org/pool_logo/878b802dd6a4155fe216cf9f5c58deb283d44cf7c168333091325bdd.png",
    pool_id: "878b802dd6a4155fe216cf9f5c58deb283d44cf7c168333091325bdd",
    db_ticker: "UK007",
    db_name: "Global UK Staking Pool by the Three Lions",
    db_url: "https://www.alizeb.com",
    roa: "2.061",
    roa_lifetime: "4.605",
    total_stake: "705744145637",
    stake_x_deleg: "2811729663.8924",
    saturated: "0.010266254240064",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "592",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kyoku Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/302f38b9b9cf8ace04859882b78e885cec18ee88832e7267224c2b28.png",
    pool_id: "302f38b9b9cf8ace04859882b78e885cec18ee88832e7267224c2b28",
    db_ticker: "KYOKU",
    db_name: "Kyoku Stake Pool",
    db_url: "https://kyoku-pool.online",
    roa: "2.514",
    roa_lifetime: "4.881",
    total_stake: "557013360787",
    stake_x_deleg: "9283556013.1167",
    saturated: "0.0081027108936066",
    luck_lifetime: "178",
    blocks_epoch: "0",
    blocks_lifetime: "29",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake for Nature",
    icon: "https://cdn.adapools.org/pool_logo/5707e4254180d481a4f1e690be0c2c54b3a618051d907df7e4434c0b.png",
    pool_id: "5707e4254180d481a4f1e690be0c2c54b3a618051d907df7e4434c0b",
    db_ticker: "ST4NA",
    db_name: "Stake for Nature",
    db_url: "https://stakefornature.com",
    roa: "1.467",
    roa_lifetime: "3.704",
    total_stake: "1789225756665",
    stake_x_deleg: "10342345414.249",
    saturated: "0.026027345213349",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "349",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "13000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Chicago Public Pool",
    icon: "https://cdn.adapools.org/pool_logo/6cabf3837d2884000af0eb9b661870f0080233cfb4e9a239a007450c.png",
    pool_id: "6cabf3837d2884000af0eb9b661870f0080233cfb4e9a239a007450c",
    db_ticker: "K9K",
    db_name: "Chicago Public Pool",
    db_url: "https://www.k9kstaking.com",
    roa: "4.639",
    roa_lifetime: "3.715",
    total_stake: "737230196197",
    stake_x_deleg: "11003435764.134",
    saturated: "0.01072427263393",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "205",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaCryptoPool1",
    icon: "0",
    pool_id: "6e67b485616dc8a604e02a96b1efbf4c6c49919eea39c6805901e2df",
    db_ticker: "ACP1",
    db_name: "AdaCryptoPool1",
    db_url: "https://adacryptopool.com/",
    roa: "1.74",
    roa_lifetime: "3.891",
    total_stake: "823320904542",
    stake_x_deleg: "63332377272.462",
    saturated: "0.011976609057889",
    luck_lifetime: "117",
    blocks_epoch: "0",
    blocks_lifetime: "110",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alfa Pool",
    icon: "https://cdn.adapools.org/pool_logo/381ad61d5031c2a570c268d2f018bfb65c6eb7e32e535a58384f9a21.png",
    pool_id: "381ad61d5031c2a570c268d2f018bfb65c6eb7e32e535a58384f9a21",
    db_ticker: "ALFA",
    db_name: "Alfa Pool",
    db_url: "https://www.alfa-pool.com/",
    roa: "5.436",
    roa_lifetime: "3.168",
    total_stake: "580784046273",
    stake_x_deleg: "2664147001.2523",
    saturated: "0.0084484961221041",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "91",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Noops Pool",
    icon: "https://cdn.adapools.org/pool_logo/57147265c639309d365ef7c419698cbc9c1d3337d58c1c6a373a4130.png",
    pool_id: "57147265c639309d365ef7c419698cbc9c1d3337d58c1c6a373a4130",
    db_ticker: "NOOPS",
    db_name: "Noops Pool",
    db_url: "https://noopspool.com",
    roa: "1.262",
    roa_lifetime: "4.877",
    total_stake: "595002859951",
    stake_x_deleg: "1514002188.1705",
    saturated: "0.0086553330574339",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "319",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " PALMA Staking Pool",
    icon: "0",
    pool_id: "b5c8c50a51ec09717b5da8d562555069a7140391fe08e8c25eaac7b7",
    db_ticker: "PALMA",
    db_name: " PALMA Staking Pool",
    db_url: "https://twitter.com/PalmaDeCardano",
    roa: "4.542",
    roa_lifetime: "3.236",
    total_stake: "847109695019",
    stake_x_deleg: "70592474584.917",
    saturated: "0.012322657654409",
    luck_lifetime: "127",
    blocks_epoch: "3",
    blocks_lifetime: "39",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blueskystaking 0PCT Pool",
    icon: "https://cdn.adapools.org/pool_logo/d154d3001887584db84a3bab55aa5724a7db3255fc50e129a01f47bc.png",
    pool_id: "d154d3001887584db84a3bab55aa5724a7db3255fc50e129a01f47bc",
    db_ticker: "BLUE",
    db_name: "Blueskystaking 0PCT Pool",
    db_url: "https://www.blueskystaking.com",
    roa: "1.313",
    roa_lifetime: "3.182",
    total_stake: "557276752093",
    stake_x_deleg: "3148456226.5141",
    saturated: "0.0081065423701109",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "92",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "One Equity",
    icon: "https://cdn.adapools.org/pool_logo/39c6ec8fe6dd619d005f17905dde221e31cbd4aadf5eecef9c611311.png",
    pool_id: "39c6ec8fe6dd619d005f17905dde221e31cbd4aadf5eecef9c611311",
    db_ticker: "EQUTY",
    db_name: "One Equity",
    db_url: "https://oneequity-staking-pool.com",
    roa: "4.201",
    roa_lifetime: "2.242",
    total_stake: "532709291872",
    stake_x_deleg: "8071352907.1515",
    saturated: "0.007749166691941",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "31",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TOKYO",
    icon: "0",
    pool_id: "7d479a2fb245ebb087d8f56b6bf434d7d3a7970997e7da1212b73670",
    db_ticker: "TOKYO",
    db_name: "TOKYO",
    db_url: "https://tokyo-pool.com",
    roa: "0.422",
    roa_lifetime: "4.495",
    total_stake: "838167292891",
    stake_x_deleg: "83816729289.1",
    saturated: "0.012192575138911",
    luck_lifetime: "110",
    blocks_epoch: "1",
    blocks_lifetime: "146",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "80000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Medium Rare Stake Pool",
    icon: "0",
    pool_id: "44e8b30cdee91e1343d8979f97182036ab004c2b9db15e4448b99872",
    db_ticker: "MRSP",
    db_name: "Medium Rare Stake Pool",
    db_url: "https://mediumrarestake.mitchjonesengineering.us",
    roa: "1.006",
    roa_lifetime: "2.576",
    total_stake: "736889102283",
    stake_x_deleg: "26317467938.679",
    saturated: "0.010719310840251",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "34",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "54000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ACME Staking",
    icon: "https://cdn.adapools.org/pool_logo/9075ebe17e99f4b6d87e01869bc40f37cbe88ffa29f5deb8d61805a7.png",
    pool_id: "9075ebe17e99f4b6d87e01869bc40f37cbe88ffa29f5deb8d61805a7",
    db_ticker: "ACME",
    db_name: "ACME Staking",
    db_url: "https://acmestaking.com",
    roa: "2.042",
    roa_lifetime: "2.441",
    total_stake: "555572955785",
    stake_x_deleg: "7824971208.2394",
    saturated: "0.0080817577421698",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "41",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sagan Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/387c1f515894d2511d1e791161e69882ddb427ab1701b7af719a8c9f.png",
    pool_id: "387c1f515894d2511d1e791161e69882ddb427ab1701b7af719a8c9f",
    db_ticker: "SAGAN",
    db_name: "Sagan Stake Pool",
    db_url: "https://www.saganpool.com",
    roa: "0.664",
    roa_lifetime: "2.204",
    total_stake: "553021362105",
    stake_x_deleg: "11286150247.041",
    saturated: "0.0080446404531378",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "39",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TSC Stake Pool",
    icon: "0",
    pool_id: "da23e855d5e0a1b72bd384c4adb93ff9e0deba6861233d53cbf4daf2",
    db_ticker: "TSC",
    db_name: "TSC Stake Pool",
    db_url: "https://tylerscrypto.com",
    roa: "1.927",
    roa_lifetime: "3.398",
    total_stake: "8409350057616",
    stake_x_deleg: "8554781340.4028",
    saturated: "0.12232836250772",
    luck_lifetime: "106",
    blocks_epoch: "6",
    blocks_lifetime: "104",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BraveHeartStakePool",
    icon: "0",
    pool_id: "c7013e0a9e0f04a0363998cac5db01f08aa466b755a1a91c42b4c4b3",
    db_ticker: "BRAVE",
    db_name: "BraveHeartStakePool",
    db_url: "https://braveheartstakepool.com",
    roa: "3.214",
    roa_lifetime: "4.512",
    total_stake: "1033829024680",
    stake_x_deleg: "8989817605.913",
    saturated: "0.015038809282",
    luck_lifetime: "106",
    blocks_epoch: "2",
    blocks_lifetime: "332",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sublayer Pool",
    icon: "https://cdn.adapools.org/pool_logo/8264de3cdb1798dd8758e24cda5101184b44543e7c4421c7815f9ed8.png",
    pool_id: "8264de3cdb1798dd8758e24cda5101184b44543e7c4421c7815f9ed8",
    db_ticker: "SBLYR",
    db_name: "Sublayer Pool",
    db_url: "https://pool.sublayer.io",
    roa: "1.715",
    roa_lifetime: "3.328",
    total_stake: "1545542928086",
    stake_x_deleg: "61821717123.44",
    saturated: "0.022482562181714",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "168",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WolverinePool",
    icon: "https://cdn.adapools.org/pool_logo/0abed0bdf287e9f298282efde1759706ddeeed68a4fe97c9dc6ff8a1.png",
    pool_id: "0abed0bdf287e9f298282efde1759706ddeeed68a4fe97c9dc6ff8a1",
    db_ticker: "WOLV",
    db_name: "WolverinePool",
    db_url: "https://wolverinestakepool.com",
    roa: "2.877",
    roa_lifetime: "4.835",
    total_stake: "516116050384",
    stake_x_deleg: "43009670865.333",
    saturated: "0.0075077896478157",
    luck_lifetime: "104",
    blocks_epoch: "1",
    blocks_lifetime: "299",
    tax_ratio: "0.0175",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1STEP",
    icon: "https://cdn.adapools.org/pool_logo/e8f538755c14d1e7f1274b46b5f292ba0c247149a8ba7b9eb410eb1a.png",
    pool_id: "e8f538755c14d1e7f1274b46b5f292ba0c247149a8ba7b9eb410eb1a",
    db_ticker: "1STEP",
    db_name: "1STEP",
    db_url: "https://www.one-step-cardano.com/",
    roa: "0",
    roa_lifetime: "1.466",
    total_stake: "926126335610",
    stake_x_deleg: "51451463089.444",
    saturated: "0.013472089678066",
    luck_lifetime: "70",
    blocks_epoch: "2",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TRUST Pool",
    icon: "https://cdn.adapools.org/pool_logo/b42ea10739065e30e388d4781a7f5a446c3a31343c87dcf26750f83d.png",
    pool_id: "b42ea10739065e30e388d4781a7f5a446c3a31343c87dcf26750f83d",
    db_ticker: "TRUST",
    db_name: "TRUST Pool",
    db_url: "https://adatrust.space",
    roa: "2.223",
    roa_lifetime: "3.177",
    total_stake: "499346250856",
    stake_x_deleg: "15604570339.25",
    saturated: "0.0072638442653797",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "87",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "90000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cexplorer.io",
    icon: "https://cdn.adapools.org/pool_logo/c3798f717a40ee027583dee28161a9a812d84ade635d8310b878e892.png",
    pool_id: "c3798f717a40ee027583dee28161a9a812d84ade635d8310b878e892",
    db_ticker: "POOLS",
    db_name: "cexplorer.io",
    db_url: "https://cexplorer.io",
    roa: "2.63",
    roa_lifetime: "4.773",
    total_stake: "1701480592508",
    stake_x_deleg: "6594886017.4729",
    saturated: "0.024750941903253",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "2349",
    tax_ratio: "0.0189",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Globe Network",
    icon: "0",
    pool_id: "8b9c6073d09171c9e3b1460ffe4c0b11f34e4ca1b8ee0a28fe7a79fd",
    db_ticker: "ADAGL",
    db_name: "ADA Globe Network",
    db_url: "https://www.adaglobe.com",
    roa: "3.906",
    roa_lifetime: "4.442",
    total_stake: "669949830972",
    stake_x_deleg: "20935932217.875",
    saturated: "0.0097455647848679",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "312",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TotalReturn-Staking",
    icon: "https://cdn.adapools.org/pool_logo/aef7d56822ab0e73fc665fc5b529926e8f7a5da72da5e977a8d5a66f.png",
    pool_id: "aef7d56822ab0e73fc665fc5b529926e8f7a5da72da5e977a8d5a66f",
    db_ticker: "TRT",
    db_name: "TotalReturn-Staking",
    db_url: "https://www.totalreturn-staking.com/",
    roa: "3.509",
    roa_lifetime: "4.102",
    total_stake: "1268732611094",
    stake_x_deleg: "15663365569.062",
    saturated: "0.018455883238529",
    luck_lifetime: "115",
    blocks_epoch: "0",
    blocks_lifetime: "131",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10015000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool for Bikes",
    icon: "0",
    pool_id: "33502e7de0cc0331534210f16726b7c0c8407ca40e2ab2754dff0bd5",
    db_ticker: "BIKES",
    db_name: "Pool for Bikes",
    db_url: "https://cardanochest.com",
    roa: "4.846",
    roa_lifetime: "3.079",
    total_stake: "503912540360",
    stake_x_deleg: "10078250807.2",
    saturated: "0.007330268746931",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "40",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SummitPool",
    icon: "0",
    pool_id: "0505da3db180fcff1adb92c8383d9ed6446248e83f2e8426cf727fef",
    db_ticker: "SMMT",
    db_name: "SummitPool",
    db_url: "https://www.summitpool.io/",
    roa: "0.559",
    roa_lifetime: "3.896",
    total_stake: "642137209963",
    stake_x_deleg: "45866943568.786",
    saturated: "0.0093409826992408",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "348",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Investor Accelerator Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/ad3b67e040364beca62714b81b3c22a12de0f6f820da9ce55f1ce137.png",
    pool_id: "ad3b67e040364beca62714b81b3c22a12de0f6f820da9ce55f1ce137",
    db_ticker: "TIA",
    db_name: "The Investor Accelerator Staking Pool",
    db_url: "http://youtube.com/c/JasonPizzinoOfficial",
    roa: "3.413",
    roa_lifetime: "3.951",
    total_stake: "2707274975624",
    stake_x_deleg: "2018847856.5429",
    saturated: "0.039381939431369",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "467",
    tax_ratio: "0.045",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano FREE stake pool",
    icon: "https://cdn.adapools.org/pool_logo/5254329935732eb7c5e674ae1930942056357a801a81823666a7a563.png",
    pool_id: "5254329935732eb7c5e674ae1930942056357a801a81823666a7a563",
    db_ticker: "FREE1",
    db_name: "Cardano FREE stake pool",
    db_url: "https://fedot-netot.github.io/free-cardano-pools/",
    roa: "5.114",
    roa_lifetime: "3.952",
    total_stake: "499708255878",
    stake_x_deleg: "27761569771",
    saturated: "0.0072691102468477",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "82",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Atom Stake Pool",
    icon: "0",
    pool_id: "566eb6f2974a521c8105d1d0b08313933d7726515c2e6c38ff447cb0",
    db_ticker: "ATOM",
    db_name: "Atom Stake Pool",
    db_url: "https://atompool.info",
    roa: "2.902",
    roa_lifetime: "4.478",
    total_stake: "2474848286246",
    stake_x_deleg: "32140886834.364",
    saturated: "0.036000896173579",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "509",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UNION",
    icon: "0",
    pool_id: "fddc2812f7f376e2bb82f00d3dfd42a65348be3f0fa464420a04fab5",
    db_ticker: "UNION",
    db_name: "UNION",
    db_url: "https://union4blockchain.com/",
    roa: "5.758",
    roa_lifetime: "4.155",
    total_stake: "545513175480",
    stake_x_deleg: "60612575053.333",
    saturated: "0.0079354210522393",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "151",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake Dingo",
    icon: "0",
    pool_id: "0dda2d4271340a92116cb8032c16b49117b27752ab0ff8b3d6813021",
    db_ticker: "DINGO",
    db_name: "Stake Dingo",
    db_url: "https://www.stakedingo.com",
    roa: "2.297",
    roa_lifetime: "4.408",
    total_stake: "565680157107",
    stake_x_deleg: "14142003927.675",
    saturated: "0.0082287842518031",
    luck_lifetime: "112",
    blocks_epoch: "2",
    blocks_lifetime: "202",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rabbitholepools",
    icon: "0",
    pool_id: "9fa14b049b735e287da702a2b0a7c2a6612e81db9cc4284339a6a2f1",
    db_ticker: "RABIT",
    db_name: "Rabbitholepools",
    db_url: "https://rabbitholepools.io",
    roa: "1.339",
    roa_lifetime: "3.47",
    total_stake: "548127634163",
    stake_x_deleg: "39151973868.786",
    saturated: "0.0079734528201339",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "97",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "65000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Grc1Pool",
    icon: "https://cdn.adapools.org/pool_logo/b3291d8a4e04835ebaa71e0523b37abe64e4cd2eeea35c0886a2dd40.png",
    pool_id: "b3291d8a4e04835ebaa71e0523b37abe64e4cd2eeea35c0886a2dd40",
    db_ticker: "GRC1",
    db_name: "Grc1Pool",
    db_url: "https://grc1.netcount.gr",
    roa: "0.753",
    roa_lifetime: "4.223",
    total_stake: "443991310050",
    stake_x_deleg: "8072569273.6364",
    saturated: "0.0064586120870169",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "577",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCJ4",
    icon: "0",
    pool_id: "d1d6f37f6f6ada84f9b472b63dfb9f83ab8105ca2f88c186d928772c",
    db_ticker: "CCJ4",
    db_name: "CCJ4",
    db_url: "http://ccj123.html.xdomain.jp",
    roa: "2.967",
    roa_lifetime: "4.609",
    total_stake: "4108467320328",
    stake_x_deleg: "316035947717.54",
    saturated: "0.059764675779794",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "2918",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CastleCardano",
    icon: "https://cdn.adapools.org/pool_logo/f1e470e5967d43d257e8128b82928ab45e607fdceca826c0020f4b27.png",
    pool_id: "f1e470e5967d43d257e8128b82928ab45e607fdceca826c0020f4b27",
    db_ticker: "CASTE",
    db_name: "CastleCardano",
    db_url: "https://castlecardano.com",
    roa: "2.356",
    roa_lifetime: "3.013",
    total_stake: "483404495184",
    stake_x_deleg: "23019261675.429",
    saturated: "0.0070319441953989",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "48",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "180000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DEGAS - The Pool Fighting GHG Emissions",
    icon: "https://cdn.adapools.org/pool_logo/4159a9cc7339bd2c495ad83239c2f1b97c8d2097eed6b120e08948c9.png",
    pool_id: "4159a9cc7339bd2c495ad83239c2f1b97c8d2097eed6b120e08948c9",
    db_ticker: "DEGAS",
    db_name: "DEGAS - The Pool Fighting GHG Emissions",
    db_url: "https://degas-pool.com",
    roa: "2.475",
    roa_lifetime: "3.839",
    total_stake: "463425125552",
    stake_x_deleg: "4499273063.6117",
    saturated: "0.0067413101328051",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "207",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Anejo Stake Pool",
    icon: "0",
    pool_id: "cc35edb3368354af97bf4de98baf4e55878f7f030444c3603d952ee3",
    db_ticker: "ANEJO",
    db_name: "Anejo Stake Pool",
    db_url: "https://anejopool.com",
    roa: "1.76",
    roa_lifetime: "3.283",
    total_stake: "1009434689597",
    stake_x_deleg: "32562409341.839",
    saturated: "0.014683952004716",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "97",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dad Stakes",
    icon: "https://cdn.adapools.org/pool_logo/dad893073815ca8b225554a2fc5048e366bd65938551323fc13cf7f5.png",
    pool_id: "dad893073815ca8b225554a2fc5048e366bd65938551323fc13cf7f5",
    db_ticker: "DAD",
    db_name: "Dad Stakes",
    db_url: "https://www.dadstakes.com",
    roa: "2.855",
    roa_lifetime: "3.958",
    total_stake: "517427145978",
    stake_x_deleg: "7498944144.6087",
    saturated: "0.0075268617730104",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "228",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Angkor Wat Stakepool",
    icon: "0",
    pool_id: "ee92ba5963b30f6fcfad5f338d5b8ab5e0ba028c5af5ed877d7a9893",
    db_ticker: "WAT",
    db_name: "Angkor Wat Stakepool",
    db_url: "https://angkorwatstakepool.net",
    roa: "4.327",
    roa_lifetime: "2.408",
    total_stake: "611678626858",
    stake_x_deleg: "27803573948.091",
    saturated: "0.0088979105747589",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "25",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Centola Pool",
    icon: "0",
    pool_id: "402347bf8fba7c29392680eb4100cf8ea0e7a7a1be64cd7fb01a9d97",
    db_ticker: "CENTO",
    db_name: "Centola Pool",
    db_url: "https://centolapool.com",
    roa: "2.435",
    roa_lifetime: "2.906",
    total_stake: "607667747831",
    stake_x_deleg: "28936559420.524",
    saturated: "0.0088395654874183",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "54",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aardvark",
    icon: "0",
    pool_id: "9bb1c8f7f46277e5c5ab6139cadffbfb3226be98a3227d00afbfc2bb",
    db_ticker: "AARD",
    db_name: "Aardvark",
    db_url: "https://pigofapool.github.io",
    roa: "0",
    roa_lifetime: "2.885",
    total_stake: "766470609652",
    stake_x_deleg: "191617652413",
    saturated: "0.01114962439982",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "80",
    tax_ratio: "0.0075",
    tax_fix: "340000000",
    pledge: "450000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockNgine",
    icon: "https://cdn.adapools.org/pool_logo/1c0e6221a71b41290e9cdc1ee2f02e2f1c31d99c6124c01dcc1c6d9b.png",
    pool_id: "1c0e6221a71b41290e9cdc1ee2f02e2f1c31d99c6124c01dcc1c6d9b",
    db_ticker: "XEON",
    db_name: "BlockNgine",
    db_url: "https://blockngine.io",
    roa: "2.196",
    roa_lifetime: "4.274",
    total_stake: "500452795137",
    stake_x_deleg: "62556599392.125",
    saturated: "0.0072799408422863",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "117",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ideal",
    icon: "https://cdn.adapools.org/pool_logo/829d0b5055e3d2ae4f69260e4cf9f4eb6eee918f7657fb2ab0ebb649.png",
    pool_id: "829d0b5055e3d2ae4f69260e4cf9f4eb6eee918f7657fb2ab0ebb649",
    db_ticker: "IDEAL",
    db_name: "Ideal",
    db_url: "https://ideal-stake-pool.com/",
    roa: "1.617",
    roa_lifetime: "4.449",
    total_stake: "440371361298",
    stake_x_deleg: "11291573366.615",
    saturated: "0.0064059537483629",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "252",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TIGER View",
    icon: "0",
    pool_id: "016ceeebd4928548cb381c3419c520860a409870207946a39abf77b0",
    db_ticker: "TIGER",
    db_name: "TIGER View",
    db_url: "https://t.me/TIGERpool",
    roa: "3.537",
    roa_lifetime: "2.538",
    total_stake: "596559957007",
    stake_x_deleg: "7187469361.5301",
    saturated: "0.0086779836941443",
    luck_lifetime: "56",
    blocks_epoch: "1",
    blocks_lifetime: "80",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Tango Pool ",
    icon: "https://cdn.adapools.org/pool_logo/bd5f6b254798e3ddde1a9c3609fa9d6e468d638bd9103afb02e29ae5.png",
    pool_id: "bd5f6b254798e3ddde1a9c3609fa9d6e468d638bd9103afb02e29ae5",
    db_ticker: "TANGO",
    db_name: " Tango Pool ",
    db_url: "https://www.tangocrypto.com",
    roa: "3.647",
    roa_lifetime: "4.007",
    total_stake: "514841036663",
    stake_x_deleg: "5850466325.7159",
    saturated: "0.0074892423951034",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "411",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stayk Pool",
    icon: "https://cdn.adapools.org/pool_logo/fd6049e6058460efa683a0e090e5b98d0ea1d6db539a5a9b0bc649de.png",
    pool_id: "fd6049e6058460efa683a0e090e5b98d0ea1d6db539a5a9b0bc649de",
    db_ticker: "STAYK",
    db_name: "Stayk Pool",
    db_url: "https://www.staykpool.com",
    roa: "4.679",
    roa_lifetime: "3.164",
    total_stake: "528943641228",
    stake_x_deleg: "6372814954.5542",
    saturated: "0.0076943888703613",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "119",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake with Max",
    icon: "0",
    pool_id: "9db81214b7b00001361999868ccc297d3bc076b4cabcb17c00277cfb",
    db_ticker: "MAX1",
    db_name: "Stake with Max",
    db_url: "http://stakewithmax.com/",
    roa: "2.772",
    roa_lifetime: "3.204",
    total_stake: "3941415747964",
    stake_x_deleg: "1332910296.9104",
    saturated: "0.057334625281049",
    luck_lifetime: "91",
    blocks_epoch: "2",
    blocks_lifetime: "151",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaDog",
    icon: "https://cdn.adapools.org/pool_logo/075eb57f38d8b6c14f42f7c58ac4ae67d02799f5fb260c3482655047.png",
    pool_id: "075eb57f38d8b6c14f42f7c58ac4ae67d02799f5fb260c3482655047",
    db_ticker: "ADOG1",
    db_name: "AdaDog",
    db_url: "https://adadog.io",
    roa: "0.705",
    roa_lifetime: "2.012",
    total_stake: "509377306030",
    stake_x_deleg: "50937730603",
    saturated: "0.0074097630992079",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "21",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano (ADA) Staking",
    icon: "https://cdn.adapools.org/pool_logo/00a6461d1561987241f41c34d42c5c5830a11510d3007a32d33f007e.png",
    pool_id: "00a6461d1561987241f41c34d42c5c5830a11510d3007a32d33f007e",
    db_ticker: "ADAX1",
    db_name: "Cardano (ADA) Staking",
    db_url: "https://www.adaxstaking.io",
    roa: "3.333",
    roa_lifetime: "3.448",
    total_stake: "408844551413",
    stake_x_deleg: "4129742943.5657",
    saturated: "0.0059473424404852",
    luck_lifetime: "111",
    blocks_epoch: "1",
    blocks_lifetime: "53",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA TO THE MOON",
    icon: "https://cdn.adapools.org/pool_logo/b14a49e33d3ac050a73684484d925a4ba49af4267c2a57c9e5dd49ad.png",
    pool_id: "b14a49e33d3ac050a73684484d925a4ba49af4267c2a57c9e5dd49ad",
    db_ticker: "PUMP",
    db_name: "ADA TO THE MOON",
    db_url: "https://pumppool.github.io",
    roa: "1.804",
    roa_lifetime: "3.695",
    total_stake: "407992216937",
    stake_x_deleg: "5298600219.961",
    saturated: "0.0059349437794658",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "69",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sunner",
    icon: "0",
    pool_id: "3c870eb5bb1b963693046eed1131e9375d363c0735fc9d248150d55f",
    db_ticker: "SUNNE",
    db_name: "Sunner",
    db_url: "https://sunner.org",
    roa: "2.997",
    roa_lifetime: "3.624",
    total_stake: "1103683401529",
    stake_x_deleg: "21224680798.635",
    saturated: "0.016054960527386",
    luck_lifetime: "116",
    blocks_epoch: "2",
    blocks_lifetime: "85",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "14800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stake4me",
    icon: "0",
    pool_id: "a4b95b2c1b5dfa560c95da6b5b8d41824eff4ebdc5e5ed08fcbf5c87",
    db_ticker: "S4M",
    db_name: "stake4me",
    db_url: "https://stake4me.com",
    roa: "3.432",
    roa_lifetime: "3.519",
    total_stake: "972122618234",
    stake_x_deleg: "23710307761.805",
    saturated: "0.014141184185523",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "113",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Erik The Red",
    icon: "0",
    pool_id: "dcfbfc65083fd8a1d931b826e67549323d4946f02eda20622b618321",
    db_ticker: "ETR",
    db_name: "Erik The Red",
    db_url: "https://kunostakepool.com/",
    roa: "1.16",
    roa_lifetime: "4.66",
    total_stake: "622716025858",
    stake_x_deleg: "22239858066.357",
    saturated: "0.0090584683987006",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "951",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Reservoir Node",
    icon: "https://cdn.adapools.org/pool_logo/1be0613ee44270f56b5fbb71f667528e1d877d77d349dd7324d50869.png",
    pool_id: "1be0613ee44270f56b5fbb71f667528e1d877d77d349dd7324d50869",
    db_ticker: "RSV",
    db_name: "Reservoir Node",
    db_url: "https://reservoir.network",
    roa: "1.801",
    roa_lifetime: "3.827",
    total_stake: "403977238604",
    stake_x_deleg: "5386363181.3867",
    saturated: "0.0058765390607164",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "96",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "215000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool For Lovelace 01",
    icon: "https://cdn.adapools.org/pool_logo/24bfb62716d755bbf035fcb3f7e87a8d325fde0f04062e692aff3216.png",
    pool_id: "24bfb62716d755bbf035fcb3f7e87a8d325fde0f04062e692aff3216",
    db_ticker: "PFL01",
    db_name: "Pool For Lovelace 01",
    db_url: "https://poolforlovelace.me",
    roa: "2.502",
    roa_lifetime: "3.02",
    total_stake: "764846756320",
    stake_x_deleg: "382423378160",
    saturated: "0.011126002679034",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "24",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "757000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vape Avenue",
    icon: "https://cdn.adapools.org/pool_logo/6e8c50febc151bde603d07e35df55dcb8669fed81db9dba4bc5e87c3.png",
    pool_id: "6e8c50febc151bde603d07e35df55dcb8669fed81db9dba4bc5e87c3",
    db_ticker: "VapE",
    db_name: "Vape Avenue",
    db_url: "https://vapeavenue.info",
    roa: "4.54",
    roa_lifetime: "4.112",
    total_stake: "740344376182",
    stake_x_deleg: "10427385580.028",
    saturated: "0.010769573701849",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "284",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alpen Cardano",
    icon: "https://cdn.adapools.org/pool_logo/38d44f2bbccd27b654e532e6937e3a60b900168082f5c1b4fe236974.png",
    pool_id: "38d44f2bbccd27b654e532e6937e3a60b900168082f5c1b4fe236974",
    db_ticker: "ALPCH",
    db_name: "Alpen Cardano",
    db_url: "https://www.alpencardano.io",
    roa: "2.954",
    roa_lifetime: "3.683",
    total_stake: "539036649252",
    stake_x_deleg: "2542625704.0189",
    saturated: "0.0078412089142285",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "191",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "27400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Timmy1 Pool",
    icon: "https://cdn.adapools.org/pool_logo/23daffaee011a07501e488a32b14a19759e87ba80e52de9806e0aaff.png",
    pool_id: "23daffaee011a07501e488a32b14a19759e87ba80e52de9806e0aaff",
    db_ticker: "TIM1",
    db_name: "Timmy1 Pool",
    db_url: "https://timmyverse.com",
    roa: "1.978",
    roa_lifetime: "1.079",
    total_stake: "429583980892",
    stake_x_deleg: "1614977371.7744",
    saturated: "0.0062490328719845",
    luck_lifetime: "56",
    blocks_epoch: "2",
    blocks_lifetime: "8",
    tax_ratio: "0.0399",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "100x by Texas StakeHouse",
    icon: "https://cdn.adapools.org/pool_logo/90722261a7047faeaa946b0ee5f9815d7bd21fff076cd9f996e6e907.png",
    pool_id: "90722261a7047faeaa946b0ee5f9815d7bd21fff076cd9f996e6e907",
    db_ticker: "100x",
    db_name: "100x by Texas StakeHouse",
    db_url: "https://www.TexasStakeHouse.com",
    roa: "0",
    roa_lifetime: "4.423",
    total_stake: "388300178458",
    stake_x_deleg: "2457596066.1899",
    saturated: "0.0056484894393479",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "512",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Euro Pool",
    icon: "https://cdn.adapools.org/pool_logo/3bd3996595321d951291b11e1331061c5d8659d9e69390536dfc922c.png",
    pool_id: "3bd3996595321d951291b11e1331061c5d8659d9e69390536dfc922c",
    db_ticker: "EURO",
    db_name: "Euro Pool",
    db_url: "https://cardano-tools.io/europool",
    roa: "3.333",
    roa_lifetime: "1.568",
    total_stake: "411141707409",
    stake_x_deleg: "25696356713.062",
    saturated: "0.0059807584987406",
    luck_lifetime: "64",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DYNE",
    icon: "https://cdn.adapools.org/pool_logo/e069112add6af5fa1a11a1716357de1848c22229eb2eaa007702d5c9.png",
    pool_id: "e069112add6af5fa1a11a1716357de1848c22229eb2eaa007702d5c9",
    db_ticker: "DYNE",
    db_name: "DYNE",
    db_url: "https://www.dyne.org/",
    roa: "3.854",
    roa_lifetime: "4.696",
    total_stake: "40735899814572",
    stake_x_deleg: "993558532062.73",
    saturated: "0.59257325304019",
    luck_lifetime: "99",
    blocks_epoch: "43",
    blocks_lifetime: "5633",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoCafe Three",
    icon: "https://cdn.adapools.org/pool_logo/3fe5cbddcaf2cf4135a278590dc250152cfdfc4f88450322e7074788.png",
    pool_id: "3fe5cbddcaf2cf4135a278590dc250152cfdfc4f88450322e7074788",
    db_ticker: "CAFE3",
    db_name: "CardanoCafe Three",
    db_url: "https://cardanocafe.org/",
    roa: "2.698",
    roa_lifetime: "3.106",
    total_stake: "550803159338",
    stake_x_deleg: "14494819982.579",
    saturated: "0.0080123729044761",
    luck_lifetime: "124",
    blocks_epoch: "1",
    blocks_lifetime: "15",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA doch_doch",
    icon: "0",
    pool_id: "ed6a2fb43057bac61d5beef6afca31d8c760344b896641a2746dad19",
    db_ticker: "ADD",
    db_name: "ADA doch_doch",
    db_url: "https://dochdoch.gitlab.io/add/",
    roa: "1.403",
    roa_lifetime: "3.214",
    total_stake: "1039982786855",
    stake_x_deleg: "32499462089.219",
    saturated: "0.015128326265473",
    luck_lifetime: "86",
    blocks_epoch: "1",
    blocks_lifetime: "106",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "21700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wine Pool",
    icon: "0",
    pool_id: "76992ac7c605ff6ec397c296d7964dd3139dd32f8cef70cfc4019361",
    db_ticker: "WINE",
    db_name: "Wine Pool",
    db_url: "https://WinePool.io",
    roa: "2.035",
    roa_lifetime: "2.892",
    total_stake: "537007026507",
    stake_x_deleg: "48818820591.545",
    saturated: "0.0078116845841431",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "55",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "8SQRD Cardano Stake Pool",
    icon: "0",
    pool_id: "6333007fadda8a9e086c2c426a02197f02e3abb4f17e22bf51341231",
    db_ticker: "8SQRD",
    db_name: "8SQRD Cardano Stake Pool",
    db_url: "https://8sqrdpools.com",
    roa: "0.884",
    roa_lifetime: "1.805",
    total_stake: "488288928007",
    stake_x_deleg: "32552595200.467",
    saturated: "0.0071029966150179",
    luck_lifetime: "67",
    blocks_epoch: "1",
    blocks_lifetime: "20",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "51000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZENITH",
    icon: "0",
    pool_id: "c1ede3cc9133209466774d4826044e408db13d6fe6df751a73500f16",
    db_ticker: "ZEN",
    db_name: "ZENITH",
    db_url: "https://zenithpool.io",
    roa: "2.071",
    roa_lifetime: "2.983",
    total_stake: "535235257739",
    stake_x_deleg: "53523525773.9",
    saturated: "0.0077859111806522",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "96",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Meritus Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/49a7d7cf28799e20fb0505922bf88821d8104943d9ad5b183c36a1b7.png",
    pool_id: "49a7d7cf28799e20fb0505922bf88821d8104943d9ad5b183c36a1b7",
    db_ticker: "MERIT",
    db_name: "Meritus Stake Pool",
    db_url: "https://meritus.work",
    roa: "0.768",
    roa_lifetime: "1.079",
    total_stake: "548403865042",
    stake_x_deleg: "49854896822",
    saturated: "0.0079774710701616",
    luck_lifetime: "56",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A CardanoLand pool ACL",
    icon: "https://cdn.adapools.org/pool_logo/5f6ea5224bc7a6b1b722654b724328c3822ea73d8d17a4d6f38b86a2.png",
    pool_id: "5f6ea5224bc7a6b1b722654b724328c3822ea73d8d17a4d6f38b86a2",
    db_ticker: "ACL",
    db_name: "A CardanoLand pool ACL",
    db_url: "https://cardanoland.com",
    roa: "0.934",
    roa_lifetime: "2.786",
    total_stake: "365671256742",
    stake_x_deleg: "18283562837.1",
    saturated: "0.0053193131153908",
    luck_lifetime: "89",
    blocks_epoch: "1",
    blocks_lifetime: "40",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "300000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Artano NFT Pool",
    icon: "https://cdn.adapools.org/pool_logo/5db269adccba1e9041469efc646bf200a560d9e1fd666366334d4fc3.png",
    pool_id: "5db269adccba1e9041469efc646bf200a560d9e1fd666366334d4fc3",
    db_ticker: "ARTA",
    db_name: "Artano NFT Pool",
    db_url: "https://www.artano.io",
    roa: "2.724",
    roa_lifetime: "3.906",
    total_stake: "761664167545",
    stake_x_deleg: "4279012177.2191",
    saturated: "0.011079706488399",
    luck_lifetime: "128",
    blocks_epoch: "2",
    blocks_lifetime: "76",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ICRYP NETWORK",
    icon: "https://cdn.adapools.org/pool_logo/2671702f26f947ecc114861c4983bfdc8616d3fa2bace18dd4a91e65.png",
    pool_id: "2671702f26f947ecc114861c4983bfdc8616d3fa2bace18dd4a91e65",
    db_ticker: "ICRYP",
    db_name: "ICRYP NETWORK",
    db_url: "https://icryp.net",
    roa: "0.914",
    roa_lifetime: "4.147",
    total_stake: "389522611703",
    stake_x_deleg: "35411146518.455",
    saturated: "0.0056662718192121",
    luck_lifetime: "92",
    blocks_epoch: "2",
    blocks_lifetime: "334",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "55000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HIVE",
    icon: "https://cdn.adapools.org/pool_logo/de18050a35497096eecd2f93ddc2ea7e6c05cdae5575325327e223b3.png",
    pool_id: "de18050a35497096eecd2f93ddc2ea7e6c05cdae5575325327e223b3",
    db_ticker: "HIVE",
    db_name: "HIVE",
    db_url: "https://hivestaking.com/",
    roa: "1.011",
    roa_lifetime: "4.318",
    total_stake: "356192835869",
    stake_x_deleg: "13192327254.407",
    saturated: "0.0051814332915508",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "143",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "243400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "snode.co",
    icon: "0",
    pool_id: "66b6d414db2f4ef941ef11a91c6a7d461d4dbf65f3e7baec950d87f2",
    db_ticker: "SND",
    db_name: "snode.co",
    db_url: "http://staking.snode.co",
    roa: "0",
    roa_lifetime: "4.536",
    total_stake: "336967121812",
    stake_x_deleg: "7020148371.0833",
    saturated: "0.0049017624367855",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "284",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DFii - ZERO Margin Fees Promotion",
    icon: "https://cdn.adapools.org/pool_logo/fc74618b0eb94205fd1bcf75ac018f1684afb110cc678cd1e6ecb130.png",
    pool_id: "fc74618b0eb94205fd1bcf75ac018f1684afb110cc678cd1e6ecb130",
    db_ticker: "DFII",
    db_name: "DFii - ZERO Margin Fees Promotion",
    db_url: "https://cardano.dfii.co",
    roa: "1.067",
    roa_lifetime: "3.374",
    total_stake: "312864065359",
    stake_x_deleg: "7275908496.7209",
    saturated: "0.0045511423047746",
    luck_lifetime: "108",
    blocks_epoch: "0",
    blocks_lifetime: "44",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ARSA Stake Pool",
    icon: "0",
    pool_id: "a8cdbf3cc6248af6b9e6a1f7027fc8b6640f894b484bcf74aa11aa82",
    db_ticker: "ARSA",
    db_name: "ARSA Stake Pool",
    db_url: "https://twitter.com/ARSA_usui",
    roa: "2.538",
    roa_lifetime: "3.066",
    total_stake: "3153076672137",
    stake_x_deleg: "116780617486.56",
    saturated: "0.045866886682223",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "55",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoKeysPool",
    icon: "https://cdn.adapools.org/pool_logo/4647f86d5a076723b985362a71ef667e7c867236a40255b087febdf7.png",
    pool_id: "4647f86d5a076723b985362a71ef667e7c867236a40255b087febdf7",
    db_ticker: "CKEYS",
    db_name: "CardanoKeysPool",
    db_url: "https://discord.gg/jgVVFwgGuQ",
    roa: "1.844",
    roa_lifetime: "2.409",
    total_stake: "820569278589",
    stake_x_deleg: "6564554228.712",
    saturated: "0.011936581957725",
    luck_lifetime: "78",
    blocks_epoch: "0",
    blocks_lifetime: "34",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "21000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "flux",
    icon: "https://cdn.adapools.org/pool_logo/822ee46a8ae3b3eb4376a7fa6325743cb56de823edd936b6d1868483.png",
    pool_id: "822ee46a8ae3b3eb4376a7fa6325743cb56de823edd936b6d1868483",
    db_ticker: "FLUX",
    db_name: "flux",
    db_url: "https://twitter.com/flux_pool",
    roa: "0",
    roa_lifetime: "4.232",
    total_stake: "316014923508",
    stake_x_deleg: "13739779282.957",
    saturated: "0.0045969769192479",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "260",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "101000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pie Stake",
    icon: "https://cdn.adapools.org/pool_logo/3949021b324378f6d3915471d902afce34e87700893ac8f3fe4b4d0d.png",
    pool_id: "3949021b324378f6d3915471d902afce34e87700893ac8f3fe4b4d0d",
    db_ticker: "PIE",
    db_name: "Pie Stake",
    db_url: "https://piestake.com",
    roa: "3.374",
    roa_lifetime: "2.742",
    total_stake: "318342060814",
    stake_x_deleg: "15917103040.7",
    saturated: "0.0046308291068751",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "26",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EUSKAL STAKE POOL",
    icon: "https://cdn.adapools.org/pool_logo/b4122e4d02a6986e9604d5c74ab2974e80638f78e6bd1aa01b883e6e.png",
    pool_id: "b4122e4d02a6986e9604d5c74ab2974e80638f78e6bd1aa01b883e6e",
    db_ticker: "EUSKL",
    db_name: "EUSKAL STAKE POOL",
    db_url: "https://euskalstakepool.win",
    roa: "0",
    roa_lifetime: "3.011",
    total_stake: "373151900006",
    stake_x_deleg: "1688470135.7738",
    saturated: "0.0054281318510505",
    luck_lifetime: "88",
    blocks_epoch: "1",
    blocks_lifetime: "74",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Republic of Gamers",
    icon: "https://cdn.adapools.org/pool_logo/59a5636fc0427e6eeb3cf23016fce0e749b2f4a3f0f589d6caa10f05.png",
    pool_id: "59a5636fc0427e6eeb3cf23016fce0e749b2f4a3f0f589d6caa10f05",
    db_ticker: "ROG",
    db_name: "Republic of Gamers",
    db_url: "https://rogpool.com",
    roa: "2.66",
    roa_lifetime: "3.937",
    total_stake: "281946247081",
    stake_x_deleg: "4475337255.254",
    saturated: "0.0041013898201776",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "113",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PlebPool",
    icon: "https://cdn.adapools.org/pool_logo/51120922fb9d7c0ecbb20378950da7880cb6dd9d3c9e06e17bff6aca.png",
    pool_id: "51120922fb9d7c0ecbb20378950da7880cb6dd9d3c9e06e17bff6aca",
    db_ticker: "PLBPL",
    db_name: "PlebPool",
    db_url: "https://plebpool.com",
    roa: "0",
    roa_lifetime: "4.392",
    total_stake: "373838359217",
    stake_x_deleg: "12890977904.034",
    saturated: "0.0054381175729713",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "124",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "31544000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VaporPool",
    icon: "https://cdn.adapools.org/pool_logo/66e23c4d144f945e72f9ba40fee7ef0b8a62b7fcb02cdfeeca4cfb63.png",
    pool_id: "66e23c4d144f945e72f9ba40fee7ef0b8a62b7fcb02cdfeeca4cfb63",
    db_ticker: "VAPOR",
    db_name: "VaporPool",
    db_url: "https://vaporpool.eu",
    roa: "0",
    roa_lifetime: "3.543",
    total_stake: "278235408946",
    stake_x_deleg: "5678273651.9592",
    saturated: "0.0040474093401791",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "59",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Super Nova",
    icon: "https://cdn.adapools.org/pool_logo/614bb865b1902f54b540209b37ad404eef1db1589a5d9eafe7a6db71.png",
    pool_id: "614bb865b1902f54b540209b37ad404eef1db1589a5d9eafe7a6db71",
    db_ticker: "SUNO",
    db_name: "Super Nova",
    db_url: "https://supernova-pool.com/",
    roa: "2.053",
    roa_lifetime: "4.114",
    total_stake: "535609426586",
    stake_x_deleg: "28189969820.316",
    saturated: "0.0077913541057345",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "163",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sapporo Stake Pool",
    icon: "0",
    pool_id: "9fb3d40a488461cc8d9607d4a2f4ef3be54b68e655256f9c493197ce",
    db_ticker: "SAPPO",
    db_name: "Sapporo Stake Pool",
    db_url: "https://twitter.com/Sappo77sapporo",
    roa: "4.04",
    roa_lifetime: "2.391",
    total_stake: "657344056656",
    stake_x_deleg: "93906293808",
    saturated: "0.0095621922626572",
    luck_lifetime: "87",
    blocks_epoch: "1",
    blocks_lifetime: "22",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Capital",
    icon: "https://cdn.adapools.org/pool_logo/22ab39540db22349b1934f5dcb7788440c33709ea9fdac07fb343395.png",
    pool_id: "22ab39540db22349b1934f5dcb7788440c33709ea9fdac07fb343395",
    db_ticker: "ADACT",
    db_name: "ADA Capital",
    db_url: "https://adacapital.io",
    roa: "3.304",
    roa_lifetime: "3.61",
    total_stake: "14989707454106",
    stake_x_deleg: "1070693389579",
    saturated: "0.21805090224183",
    luck_lifetime: "91",
    blocks_epoch: "12",
    blocks_lifetime: "129",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PANL Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/05e11c24a4de15366c6d548f8847589164d02260b6a059a08c22be2a.png",
    pool_id: "05e11c24a4de15366c6d548f8847589164d02260b6a059a08c22be2a",
    db_ticker: "PANL",
    db_name: "PANL Stake Pool",
    db_url: "https://www.panl.org",
    roa: "5.266",
    roa_lifetime: "3.983",
    total_stake: "238791896857",
    stake_x_deleg: "10854177129.864",
    saturated: "0.0034736360744282",
    luck_lifetime: "112",
    blocks_epoch: "0",
    blocks_lifetime: "109",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Toshz Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/bf082b24ce9ad72f0eb48beecf231466b62b7a8ce0890c7a9dcef87a.png",
    pool_id: "bf082b24ce9ad72f0eb48beecf231466b62b7a8ce0890c7a9dcef87a",
    db_ticker: "TOSHZ",
    db_name: "Toshz Stake Pool",
    db_url: "https://lungurazvan.ro/cardano",
    roa: "2.533",
    roa_lifetime: "4.414",
    total_stake: "279500166872",
    stake_x_deleg: "13975008343.6",
    saturated: "0.0040658074048329",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "264",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OKNW Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/627f8da92dc94041606ffdaccadd6b5e626c8750263b01afa67686b5.png",
    pool_id: "627f8da92dc94041606ffdaccadd6b5e626c8750263b01afa67686b5",
    db_ticker: "OKNW",
    db_name: "OKNW Cardano Pool",
    db_url: "https://twitter.com/oknw_pool",
    roa: "4.259",
    roa_lifetime: "3.414",
    total_stake: "1984184507145",
    stake_x_deleg: "141727464796.07",
    saturated: "0.028863353292377",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "49",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardabros",
    icon: "https://cdn.adapools.org/pool_logo/d395b8436f88c76a4856c30c4df7de160c6ab212bb85ef5edd8c5138.png",
    pool_id: "d395b8436f88c76a4856c30c4df7de160c6ab212bb85ef5edd8c5138",
    db_ticker: "BROS",
    db_name: "Cardabros",
    db_url: "https://cardabros.com",
    roa: "0.806",
    roa_lifetime: "2.747",
    total_stake: "430991302744",
    stake_x_deleg: "20523395368.762",
    saturated: "0.0062695047724877",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "32",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nerd Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/32c6e03b9a94ce5d40527408634b02feaf82c015fde9d368e9f35714.png",
    pool_id: "32c6e03b9a94ce5d40527408634b02feaf82c015fde9d368e9f35714",
    db_ticker: "NERD2",
    db_name: "Nerd Stake Pool",
    db_url: "https://www.stakewithnerd.com/",
    roa: "4.452",
    roa_lifetime: "4.002",
    total_stake: "12723854311041",
    stake_x_deleg: "8510939338.489",
    saturated: "0.18509019745787",
    luck_lifetime: "103",
    blocks_epoch: "14",
    blocks_lifetime: "606",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Meta Stake Pool – Helping You Learn Cardano",
    icon: "https://cdn.adapools.org/pool_logo/b75a65868d3fd3583e423833381d104c02bedd3a83c9392d92e421b1.png",
    pool_id: "b75a65868d3fd3583e423833381d104c02bedd3a83c9392d92e421b1",
    db_ticker: "META",
    db_name: "Meta Stake Pool – Helping You Learn Cardano",
    db_url: "https://metastakepool.com",
    roa: "5.723",
    roa_lifetime: "2.159",
    total_stake: "259110330481",
    stake_x_deleg: "9965781941.5769",
    saturated: "0.0037692024020179",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200002000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Southtyrol",
    icon: "https://cdn.adapools.org/pool_logo/2dabd15e4759334738940a65e06b6589f674a29347db0a36d75a5d36.png",
    pool_id: "2dabd15e4759334738940a65e06b6589f674a29347db0a36d75a5d36",
    db_ticker: "SUDTI",
    db_name: "Southtyrol",
    db_url: "https://southtyrolpool.it",
    roa: "3.796",
    roa_lifetime: "2.999",
    total_stake: "1028237197258",
    stake_x_deleg: "14901988366.058",
    saturated: "0.014957466599477",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "55",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MyADA by Netspectrum",
    icon: "0",
    pool_id: "66bd46cec6924459230b7bd70b109c6424804a8f6abd8d3855ed043d",
    db_ticker: "MYADA",
    db_name: "MyADA by Netspectrum",
    db_url: "http://cardano.netspectrum.com",
    roa: "2.198",
    roa_lifetime: "3.269",
    total_stake: "171246247861",
    stake_x_deleg: "15567840714.636",
    saturated: "0.0024910692197259",
    luck_lifetime: "123",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "160000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockademia",
    icon: "0",
    pool_id: "ad7672d51077091866aff6fb43f9f3b75d427c961f6de8a135b07219",
    db_ticker: "ACI",
    db_name: "Blockademia",
    db_url: "https://pool.smartcontract.hr",
    roa: "2.879",
    roa_lifetime: "4.279",
    total_stake: "1545558884444",
    stake_x_deleg: "7155365205.7593",
    saturated: "0.0224827942942",
    luck_lifetime: "102",
    blocks_epoch: "3",
    blocks_lifetime: "270",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "QUEST - The Quest for ADA",
    icon: "https://cdn.adapools.org/pool_logo/321cc307b088cf3620a27a6986e083a69d724edf2b43d7f2c10544b9.png",
    pool_id: "321cc307b088cf3620a27a6986e083a69d724edf2b43d7f2c10544b9",
    db_ticker: "QUEST",
    db_name: "QUEST - The Quest for ADA",
    db_url: "https://ada-quest.com",
    roa: "4.051",
    roa_lifetime: "4.264",
    total_stake: "918314601887",
    stake_x_deleg: "4063338946.4027",
    saturated: "0.013358454666069",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "367",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "12000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Commercial Sovereignty Restoration",
    icon: "https://cdn.adapools.org/pool_logo/5059d31b0c8c11d6d2404266844e31f13dab05eae77815db8994a598.png",
    pool_id: "5059d31b0c8c11d6d2404266844e31f13dab05eae77815db8994a598",
    db_ticker: "SVRN",
    db_name: "Commercial Sovereignty Restoration",
    db_url: "https://commerce.sleepingnatives.org",
    roa: "3.333",
    roa_lifetime: "4.531",
    total_stake: "254285079965",
    stake_x_deleg: "8768451033.2759",
    saturated: "0.0036990108901569",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "280",
    tax_ratio: "0.016",
    tax_fix: "340000000",
    pledge: "120700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zipang Stake Pool 1",
    icon: "https://cdn.adapools.org/pool_logo/1c908c07101f9c99aea3febc41f556d50e4060a4d5e2215ba0a18a3f.png",
    pool_id: "1c908c07101f9c99aea3febc41f556d50e4060a4d5e2215ba0a18a3f",
    db_ticker: "ZPNG1",
    db_name: "Zipang Stake Pool 1",
    db_url: "https://zpngstakepool.com/",
    roa: "4.171",
    roa_lifetime: "4.606",
    total_stake: "5873566063626",
    stake_x_deleg: "33372534452.42",
    saturated: "0.085441052366892",
    luck_lifetime: "108",
    blocks_epoch: "5",
    blocks_lifetime: "721",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sparkle",
    icon: "https://cdn.adapools.org/pool_logo/782310d6e6ab81cde977da3eef0a9f533bd255dadd30d01d33e0dde6.png",
    pool_id: "782310d6e6ab81cde977da3eef0a9f533bd255dadd30d01d33e0dde6",
    db_ticker: "SPKL",
    db_name: "Sparkle",
    db_url: "http://spklpool.com",
    roa: "1.345",
    roa_lifetime: "2.74",
    total_stake: "265733153583",
    stake_x_deleg: "8572037212.3548",
    saturated: "0.0038655426779839",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "26",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A3C Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/159bd971439653da2b97d12facae06ff5e6d7410d074edaab425202b.png",
    pool_id: "159bd971439653da2b97d12facae06ff5e6d7410d074edaab425202b",
    db_ticker: "A3C",
    db_name: "A3C Cardano Pool",
    db_url: "https://www.a3cmodernconsulting.com",
    roa: "4.22",
    roa_lifetime: "3.724",
    total_stake: "2026498958981",
    stake_x_deleg: "9837373587.2864",
    saturated: "0.029478889281252",
    luck_lifetime: "108",
    blocks_epoch: "2",
    blocks_lifetime: "146",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Single Stake",
    icon: "0",
    pool_id: "3c28d08beab81c1852ef4787495f5c14cd31880cf0cf108cb6185bd2",
    db_ticker: "SSPO",
    db_name: "Single Stake",
    db_url: "http://sspo.one",
    roa: "0.998",
    roa_lifetime: "1.149",
    total_stake: "367096630388",
    stake_x_deleg: "26221187884.857",
    saturated: "0.0053400476100762",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Poem Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/d6262a18f56264232e0d4522267c916039bb4830b87ea4e693df09fd.png",
    pool_id: "d6262a18f56264232e0d4522267c916039bb4830b87ea4e693df09fd",
    db_ticker: "POEM",
    db_name: "Poem Stake Pool",
    db_url: "https://poemstakepool.com",
    roa: "0",
    roa_lifetime: "4.092",
    total_stake: "354553059203",
    stake_x_deleg: "88638264800.75",
    saturated: "0.0051575799386691",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "279",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "350000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TDOT Staking",
    icon: "https://cdn.adapools.org/pool_logo/8833a263b80491140592cb907f46add5e1a1ddbce66725ce7675e2af.png",
    pool_id: "8833a263b80491140592cb907f46add5e1a1ddbce66725ce7675e2af",
    db_ticker: "TDOT",
    db_name: "TDOT Staking",
    db_url: "https://ada.tdot.finance",
    roa: "0",
    roa_lifetime: "2.262",
    total_stake: "252349916576",
    stake_x_deleg: "11470450753.455",
    saturated: "0.0036708606327721",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardanoX",
    icon: "https://cdn.adapools.org/pool_logo/52475f2885fb85223e944078c099a4a43e1cbf3253c24b373d5fe851.png",
    pool_id: "52475f2885fb85223e944078c099a4a43e1cbf3253c24b373d5fe851",
    db_ticker: "CARDX",
    db_name: "cardanoX",
    db_url: "https://www.cardanox.info",
    roa: "0",
    roa_lifetime: "2.19",
    total_stake: "265427439745",
    stake_x_deleg: "18959102838.929",
    saturated: "0.003861095547951",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "18",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "46700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "InvestX",
    icon: "https://cdn.adapools.org/pool_logo/fbf52221935b5205fbcb6e914184b0acc98e65ec01e3e96c64412cbb.png",
    pool_id: "fbf52221935b5205fbcb6e914184b0acc98e65ec01e3e96c64412cbb",
    db_ticker: "NVSTX",
    db_name: "InvestX",
    db_url: "https://www.youtube.com/c/InvestX",
    roa: "3.333",
    roa_lifetime: "4.41",
    total_stake: "257822929885",
    stake_x_deleg: "987827317.56705",
    saturated: "0.0037504749610479",
    luck_lifetime: "101",
    blocks_epoch: "3",
    blocks_lifetime: "494",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Carden Pool",
    icon: "https://cdn.adapools.org/pool_logo/cc49ee3c6b02850b63dd9c18b1b6a18b16c2dd1f20962c320cfd94a7.png",
    pool_id: "cc49ee3c6b02850b63dd9c18b1b6a18b16c2dd1f20962c320cfd94a7",
    db_ticker: "CRPL",
    db_name: "Carden Pool",
    db_url: "https://cardenpool.org",
    roa: "2.281",
    roa_lifetime: "3.903",
    total_stake: "240097202934",
    stake_x_deleg: "4530135904.4151",
    saturated: "0.0034926239812077",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "272",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ItalyADA",
    icon: "https://cdn.adapools.org/pool_logo/de2e00a923f6dd016a43087e608df9b6ddd4e587e8ff4f39c64e2d43.png",
    pool_id: "de2e00a923f6dd016a43087e608df9b6ddd4e587e8ff4f39c64e2d43",
    db_ticker: "HWL",
    db_name: "ItalyADA",
    db_url: "https://www.hwlegnano.it/cardano/",
    roa: "5.095",
    roa_lifetime: "3.722",
    total_stake: "232029568514",
    stake_x_deleg: "4735297316.6122",
    saturated: "0.0033752664564112",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "105",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Great Pool",
    icon: "0",
    pool_id: "fa8b96740590a9638e8b548ca07992c776efda3ea56f50f8d99bf73d",
    db_ticker: "GREAT",
    db_name: "The Great Pool",
    db_url: "https://greatstakepool.com",
    roa: "0.957",
    roa_lifetime: "1.992",
    total_stake: "372511373458",
    stake_x_deleg: "46563921682.25",
    saturated: "0.0054188142981811",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "22",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The OLEUM Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/4013a7d086bec89ec1b2ba4e43633012d02e66c54278c229b324a982.png",
    pool_id: "4013a7d086bec89ec1b2ba4e43633012d02e66c54278c229b324a982",
    db_ticker: "OLEUM",
    db_name: "The OLEUM Stakepool",
    db_url: "https://oleum.net",
    roa: "3.635",
    roa_lifetime: "4.439",
    total_stake: "379437393912",
    stake_x_deleg: "8824125439.814",
    saturated: "0.0055195650975922",
    luck_lifetime: "101",
    blocks_epoch: "2",
    blocks_lifetime: "357",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Financial Revolution",
    icon: "https://cdn.adapools.org/pool_logo/3ef2fd2433dd22627c88a1667232e48996c96563ec57d9d1631b07a2.png",
    pool_id: "3ef2fd2433dd22627c88a1667232e48996c96563ec57d9d1631b07a2",
    db_ticker: "FIRE",
    db_name: "Financial Revolution",
    db_url: "https://fire-pool.com",
    roa: "3.333",
    roa_lifetime: "4.232",
    total_stake: "513143243573",
    stake_x_deleg: "28507957976.278",
    saturated: "0.0074645450942236",
    luck_lifetime: "180",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "16500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Stake Pool by Coin Trade Ledger",
    icon: "https://cdn.adapools.org/pool_logo/8c35ca0a53467e3a71adb8088001345e3817a23d9667beebbb2145ce.png",
    pool_id: "8c35ca0a53467e3a71adb8088001345e3817a23d9667beebbb2145ce",
    db_ticker: "CTL",
    db_name: "Cardano Stake Pool by Coin Trade Ledger",
    db_url: "https://cointradeledger.com/cardano",
    roa: "1.195",
    roa_lifetime: "1.821",
    total_stake: "303712849926",
    stake_x_deleg: "60742569985.2",
    saturated: "0.0044180222430333",
    luck_lifetime: "81",
    blocks_epoch: "0",
    blocks_lifetime: "18",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "220000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UK pool",
    icon: "https://cdn.adapools.org/pool_logo/81ab938d86519a6459ce524325ed839f52b48d69465453627e55efeb.png",
    pool_id: "81ab938d86519a6459ce524325ed839f52b48d69465453627e55efeb",
    db_ticker: "UK1",
    db_name: "UK pool",
    db_url: "https://londonpool.co.uk",
    roa: "3.075",
    roa_lifetime: "3.658",
    total_stake: "617185775771",
    stake_x_deleg: "20572859192.367",
    saturated: "0.0089780214637096",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "227",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Avocado Pool  by Numic",
    icon: "https://cdn.adapools.org/pool_logo/5df46b9796868647647f6d3a4ebd188920e70cd12720513337203b62.png",
    pool_id: "5df46b9796868647647f6d3a4ebd188920e70cd12720513337203b62",
    db_ticker: "AVOC",
    db_name: "Avocado Pool  by Numic",
    db_url: "http://AvocPool.com",
    roa: "1.626",
    roa_lifetime: "2.554",
    total_stake: "232934311321",
    stake_x_deleg: "13702018313",
    saturated: "0.0033884274861356",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Berch_Pool",
    icon: "0",
    pool_id: "445d20288447ae65e6c42e49b3a6f8299abe2f51842bae974834db70",
    db_ticker: "BERCH",
    db_name: "Berch_Pool",
    db_url: "http://www.berchpool.com",
    roa: "3.251",
    roa_lifetime: "2.924",
    total_stake: "450264899060",
    stake_x_deleg: "11545253822.051",
    saturated: "0.0065498721565088",
    luck_lifetime: "112",
    blocks_epoch: "0",
    blocks_lifetime: "36",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "33000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Prophet",
    icon: "https://cdn.adapools.org/pool_logo/5693aa7edfe81d12c9062bbe7b7c0b314b9665c6b3cd426152108347.png",
    pool_id: "5693aa7edfe81d12c9062bbe7b7c0b314b9665c6b3cd426152108347",
    db_ticker: "XPPX",
    db_name: "Prophet",
    db_url: "https://xppx.io",
    roa: "3.333",
    roa_lifetime: "2.307",
    total_stake: "214602718277",
    stake_x_deleg: "8253950702.9615",
    saturated: "0.0031217631489554",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "106000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MUEN-StakePool",
    icon: "https://cdn.adapools.org/pool_logo/a08fe0a8ba2477ec80e17738704357532ad561733448d53001fe966b.png",
    pool_id: "a08fe0a8ba2477ec80e17738704357532ad561733448d53001fe966b",
    db_ticker: "MUEN",
    db_name: "MUEN-StakePool",
    db_url: "https://muen-project.com/",
    roa: "4.694",
    roa_lifetime: "2.038",
    total_stake: "1406994838943",
    stake_x_deleg: "63954310861.045",
    saturated: "0.020467143539688",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Coconut Pool",
    icon: "0",
    pool_id: "05bbc9d838b4c9eab301bfd151de99077899c997cf146abf8436edf2",
    db_ticker: "COCO",
    db_name: "Coconut Pool",
    db_url: "https://coconutpool.com",
    roa: "0",
    roa_lifetime: "2.502",
    total_stake: "302538021781",
    stake_x_deleg: "14406572465.762",
    saturated: "0.0044009323606737",
    luck_lifetime: "71",
    blocks_epoch: "1",
    blocks_lifetime: "136",
    tax_ratio: "0",
    tax_fix: "350000000",
    pledge: "290000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MAIDS",
    icon: "0",
    pool_id: "1408010df5450238cd9e626c7fb711e25e6fcb072256d8002630024c",
    db_ticker: "AKANE",
    db_name: "MAIDS",
    db_url: "https://akanepool.github.io",
    roa: "0.653",
    roa_lifetime: "2.878",
    total_stake: "558354166136",
    stake_x_deleg: "79764880876.571",
    saturated: "0.0081222151979418",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "56",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Blockhouse",
    icon: "https://cdn.adapools.org/pool_logo/c4039f4fa97ed735b884800d6d605dbc65fbe995c4ba70b532e162a2.png",
    pool_id: "c4039f4fa97ed735b884800d6d605dbc65fbe995c4ba70b532e162a2",
    db_ticker: "CBH",
    db_name: "Cardano Blockhouse",
    db_url: "https://cardano-blockhouse.de/",
    roa: "5.936",
    roa_lifetime: "3.853",
    total_stake: "176118590331",
    stake_x_deleg: "8386599539.5714",
    saturated: "0.0025619457645062",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "104",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "63500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LGBTQ pride pool",
    icon: "https://cdn.adapools.org/pool_logo/fc0ef418b0cce20c7a1ac7b8aec9dabd8e9e83b3fcb88c228b4f2c1d.png",
    pool_id: "fc0ef418b0cce20c7a1ac7b8aec9dabd8e9e83b3fcb88c228b4f2c1d",
    db_ticker: "LGBTQ",
    db_name: "LGBTQ pride pool",
    db_url: "https://lgbtqpool.com/",
    roa: "0",
    roa_lifetime: "4.137",
    total_stake: "245864572864",
    stake_x_deleg: "775598021.653",
    saturated: "0.0035765202293933",
    luck_lifetime: "100",
    blocks_epoch: "1",
    blocks_lifetime: "339",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "33000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TetonBlocks",
    icon: "https://cdn.adapools.org/pool_logo/a2a7050e1ef0618dc76bdcc9bbba0c01f88fe1b88e19cc3218acacd8.png",
    pool_id: "a2a7050e1ef0618dc76bdcc9bbba0c01f88fe1b88e19cc3218acacd8",
    db_ticker: "TETON",
    db_name: "TetonBlocks",
    db_url: "https://tetonblocks.io",
    roa: "1.72",
    roa_lifetime: "3.315",
    total_stake: "703043697088",
    stake_x_deleg: "21304354457.212",
    saturated: "0.01022697160267",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "130",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lighthouse Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6083db098b3574bf583fee83c879c19c2d908dbf19b1184d272f9632.png",
    pool_id: "6083db098b3574bf583fee83c879c19c2d908dbf19b1184d272f9632",
    db_ticker: "LHS",
    db_name: "Lighthouse Stake Pool",
    db_url: "https://lighthousestakepool.com",
    roa: "5.233",
    roa_lifetime: "3.026",
    total_stake: "387170266154",
    stake_x_deleg: "9003959678",
    saturated: "0.0056320529346265",
    luck_lifetime: "119",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adanaut Stake Pool",
    icon: "0",
    pool_id: "f11fc71643e98cb05abbba2d7b132a66a4014cf5e1effd1fe533811c",
    db_ticker: "ADNT",
    db_name: "Adanaut Stake Pool",
    db_url: "https://adanaut.com",
    roa: "1.019",
    roa_lifetime: "4.693",
    total_stake: "352831750959",
    stake_x_deleg: "17641587547.95",
    saturated: "0.0051325405697028",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "102",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Smile Rose Pool",
    icon: "https://cdn.adapools.org/pool_logo/7f8096e50577c2b2926b47b547d84ceff5dbff45d486ac754ff6a3cb.png",
    pool_id: "7f8096e50577c2b2926b47b547d84ceff5dbff45d486ac754ff6a3cb",
    db_ticker: "SRP",
    db_name: "Smile Rose Pool",
    db_url: "https://twitter.com/smilerosepool",
    roa: "1.134",
    roa_lifetime: "2.778",
    total_stake: "322012965557",
    stake_x_deleg: "32201296555.7",
    saturated: "0.0046842286874677",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "57",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SCARdano 2",
    icon: "0",
    pool_id: "8318582d4a691c12e4ce87d6bf61b8ab14d20d3517e0f7cb3062be26",
    db_ticker: "SCAR2",
    db_name: "SCARdano 2",
    db_url: "https://scardano.io/",
    roa: "3.652",
    roa_lifetime: "4.713",
    total_stake: "2732429424664",
    stake_x_deleg: "14850159916.652",
    saturated: "0.039747853864681",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "992",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Buff Stake Pool",
    icon: "0",
    pool_id: "0d4fbe8a8742e8c50f1f2518504e049c0732a0ed1e7f966b33bd5014",
    db_ticker: "CBUFF",
    db_name: "Crypto Buff Stake Pool",
    db_url: "https://thecryptobuff.com",
    roa: "5.033",
    roa_lifetime: "4.974",
    total_stake: "1779918528038",
    stake_x_deleg: "22819468308.179",
    saturated: "0.025891955673179",
    luck_lifetime: "196",
    blocks_epoch: "2",
    blocks_lifetime: "12",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adria StakePool",
    icon: "https://cdn.adapools.org/pool_logo/764bcdf4629bffa904fbc6ca7228ff37382c70620cbed9c9cb436e87.png",
    pool_id: "764bcdf4629bffa904fbc6ca7228ff37382c70620cbed9c9cb436e87",
    db_ticker: "ADRIA",
    db_name: "Adria StakePool",
    db_url: "https://adriapool.net",
    roa: "0",
    roa_lifetime: "2.008",
    total_stake: "157544094986",
    stake_x_deleg: "3424871630.1304",
    saturated: "0.0022917479984014",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "52000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeLake LAKE",
    icon: "0",
    pool_id: "abcd27d5ee8f838e24cdb8653cbe309a86d7a4c48576ac3e0893a810",
    db_ticker: "LAKE",
    db_name: "StakeLake LAKE",
    db_url: "https://stakelake.com/",
    roa: "8.091",
    roa_lifetime: "3.801",
    total_stake: "453414113950",
    stake_x_deleg: "14626261740.323",
    saturated: "0.0065956828669726",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "369",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WYNFT",
    icon: "0",
    pool_id: "cff42cd3d53a80249e2ffeaea217927fca91d11e30b83395b569dfa2",
    db_ticker: "WYNFT",
    db_name: "WYNFT",
    db_url: "https://wynft.io",
    roa: "0",
    roa_lifetime: "3.615",
    total_stake: "343268368792",
    stake_x_deleg: "12713643288.593",
    saturated: "0.0049934248386999",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "91",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BLOC pool",
    icon: "0",
    pool_id: "bcfd7fb870c5c04b702e70d6d4d98b572bce999041897d0b51f3e215",
    db_ticker: "BLOC",
    db_name: "BLOC pool",
    db_url: "https://blocpool.com",
    roa: "1.172",
    roa_lifetime: "4.431",
    total_stake: "303321174876",
    stake_x_deleg: "30332117487.6",
    saturated: "0.004412324660322",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "257",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PetzPool",
    icon: "0",
    pool_id: "dc6f409d5bd30a59b7b1f0f7969d83b01758e55d3b4e5b7b487532b8",
    db_ticker: "PETZ",
    db_name: "PetzPool",
    db_url: "https://cryptopetz.io/",
    roa: "3.564",
    roa_lifetime: "2.883",
    total_stake: "1947343068316",
    stake_x_deleg: "1658724930.4225",
    saturated: "0.028327431627384",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "53",
    tax_ratio: "0.045",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Better Pool",
    icon: "https://cdn.adapools.org/pool_logo/3e14044e8965189bc1809326a7c8b58825dca20f693e80c37b7095f3.png",
    pool_id: "3e14044e8965189bc1809326a7c8b58825dca20f693e80c37b7095f3",
    db_ticker: "BETTR",
    db_name: "The Better Pool",
    db_url: "https://stakeitbetter.com",
    roa: "0",
    roa_lifetime: "0.933",
    total_stake: "209828205367",
    stake_x_deleg: "4768822849.25",
    saturated: "0.0030523097022501",
    luck_lifetime: "39",
    blocks_epoch: "1",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Canucks Pool Two",
    icon: "https://cdn.adapools.org/pool_logo/90b265d73d812200a842f90bd01bc9f943a713b0dc936238c686c0a9.png",
    pool_id: "90b265d73d812200a842f90bd01bc9f943a713b0dc936238c686c0a9",
    db_ticker: "CNUK2",
    db_name: "Cardano Canucks Pool Two",
    db_url: "https://cardanocanucks.com",
    roa: "2.136",
    roa_lifetime: "1.599",
    total_stake: "177144970992",
    stake_x_deleg: "5368029424",
    saturated: "0.0025768762246143",
    luck_lifetime: "64",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "  Sophia's ZERO Margin Pool",
    icon: "https://cdn.adapools.org/pool_logo/ea2da5590f8174fd33c7c9b83fd23b041bcad5ba0678220783a67d6e.png",
    pool_id: "ea2da5590f8174fd33c7c9b83fd23b041bcad5ba0678220783a67d6e",
    db_ticker: "SoPHi",
    db_name: "  Sophia's ZERO Margin Pool",
    db_url: "https://stackblock.github.io/",
    roa: "0.873",
    roa_lifetime: "3.791",
    total_stake: "688171575410",
    stake_x_deleg: "34408578770.5",
    saturated: "0.010010631186417",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "143",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ara StakePool",
    icon: "https://cdn.adapools.org/pool_logo/aed2477a1b56db48312eb9dc98803b482e33e51fd37d23e6817051e6.png",
    pool_id: "aed2477a1b56db48312eb9dc98803b482e33e51fd37d23e6817051e6",
    db_ticker: "ARA",
    db_name: "Ara StakePool",
    db_url: "https://ara-stakepool.com",
    roa: "1.857",
    roa_lifetime: "1.421",
    total_stake: "205769539382",
    stake_x_deleg: "15828426106.308",
    saturated: "0.0029932694719697",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA DARKPOOL",
    icon: "https://cdn.adapools.org/pool_logo/4ccfb4803592f4f2f88800c59a0e424449481d3659a0202205da14a9.png",
    pool_id: "4ccfb4803592f4f2f88800c59a0e424449481d3659a0202205da14a9",
    db_ticker: "DARK",
    db_name: "ADA DARKPOOL",
    db_url: "https://www.ada-darkpool.com",
    roa: "2.779",
    roa_lifetime: "3.525",
    total_stake: "247418346949",
    stake_x_deleg: "1638532099",
    saturated: "0.0035991225278139",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "158",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25010000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Australia New Zealand Pool",
    icon: "https://cdn.adapools.org/pool_logo/a5612b0dcde1dee0eb73932ceb529d9523fab23e1544ee7d3db7aacc.png",
    pool_id: "a5612b0dcde1dee0eb73932ceb529d9523fab23e1544ee7d3db7aacc",
    db_ticker: "ANZ",
    db_name: "Australia New Zealand Pool",
    db_url: "https://anzpool.com",
    roa: "0",
    roa_lifetime: "3.683",
    total_stake: "258742538710",
    stake_x_deleg: "4385466757.7966",
    saturated: "0.0037638522424001",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "169",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "64000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mokum Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/1ae768c8f263ddf7804a91cc0ade84cddfc63a2697a274a3fa762b0c.png",
    pool_id: "1ae768c8f263ddf7804a91cc0ade84cddfc63a2697a274a3fa762b0c",
    db_ticker: "MOKUM",
    db_name: "Mokum Stake Pool",
    db_url: "http://mokumstakepool.com",
    roa: "1.657",
    roa_lifetime: "2.477",
    total_stake: "202024475218",
    stake_x_deleg: "22447163913.111",
    saturated: "0.0029387911159101",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A New Pool for A New Africa",
    icon: "https://cdn.adapools.org/pool_logo/d7dc0487d80b04e9eb77e3d128debded2da917647df35da25267689f.png",
    pool_id: "d7dc0487d80b04e9eb77e3d128debded2da917647df35da25267689f",
    db_ticker: "ANEW",
    db_name: "A New Pool for A New Africa",
    db_url: "https://www.anewpool.io",
    roa: "0",
    roa_lifetime: "2.51",
    total_stake: "233399462654",
    stake_x_deleg: "7072710989.5152",
    saturated: "0.0033951939069046",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "17",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LoveLike",
    icon: "https://cdn.adapools.org/pool_logo/331711ffbc442853ede9bbf478f6c7ddc62772cd1633df72285878d3.png",
    pool_id: "331711ffbc442853ede9bbf478f6c7ddc62772cd1633df72285878d3",
    db_ticker: "LOVLK",
    db_name: "LoveLike",
    db_url: "https://lovelike.io",
    roa: "5.319",
    roa_lifetime: "3.034",
    total_stake: "139212373306",
    stake_x_deleg: "9280824887.0667",
    saturated: "0.0020250817899908",
    luck_lifetime: "150",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "120000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Relay Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6c1c1fc2b1e3e1ac056f6dd4dd4c3b78e66bd01896ca6e98a4d2431e.png",
    pool_id: "6c1c1fc2b1e3e1ac056f6dd4dd4c3b78e66bd01896ca6e98a4d2431e",
    db_ticker: "RELAY",
    db_name: "Relay Stake Pool",
    db_url: "https://relaypool.com",
    roa: "5.346",
    roa_lifetime: "2.57",
    total_stake: "193797834428",
    stake_x_deleg: "12919855628.533",
    saturated: "0.002819120571827",
    luck_lifetime: "112",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "69000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Janus Stake Pool",
    icon: "0",
    pool_id: "b9d331d59f4e185fd65f61721efd172444890499ab987a62a3ea42c6",
    db_ticker: "JANUS",
    db_name: "Janus Stake Pool",
    db_url: "https://januspool.org",
    roa: "1.282",
    roa_lifetime: "2.165",
    total_stake: "294481905085",
    stake_x_deleg: "32720211676.111",
    saturated: "0.0042837423808487",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardanohub.net stake pool",
    icon: "https://cdn.adapools.org/pool_logo/82d9c37bb38d82a0d293ebc3ece70acfbfad20180f31e270b8351555.png",
    pool_id: "82d9c37bb38d82a0d293ebc3ece70acfbfad20180f31e270b8351555",
    db_ticker: "CHUB",
    db_name: "cardanohub.net stake pool",
    db_url: "https://cardanohub.net",
    roa: "2.671",
    roa_lifetime: "2.711",
    total_stake: "276930147061",
    stake_x_deleg: "19780724790.071",
    saturated: "0.0040284220762476",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "20",
    tax_ratio: "0.016",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FasoPool",
    icon: "https://cdn.adapools.org/pool_logo/70ab9e73071c50927631a218137d53e765665a578b744feeae3c5d81.png",
    pool_id: "70ab9e73071c50927631a218137d53e765665a578b744feeae3c5d81",
    db_ticker: "FASO",
    db_name: "FasoPool",
    db_url: "https://fasostakepool.com/",
    roa: "2.445",
    roa_lifetime: "2.962",
    total_stake: "3472799202443",
    stake_x_deleg: "27131243769.086",
    saturated: "0.050517797076152",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "55",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Neon Pool",
    icon: "https://cdn.adapools.org/pool_logo/b8eaba75f450efef459bf9423ed03fecf9b7297299708d4e03103559.png",
    pool_id: "b8eaba75f450efef459bf9423ed03fecf9b7297299708d4e03103559",
    db_ticker: "NEON",
    db_name: "Neon Pool",
    db_url: "https://neonpool.io",
    roa: "1.751",
    roa_lifetime: "4.244",
    total_stake: "97917748895",
    stake_x_deleg: "12239718611.875",
    saturated: "0.0014243809332113",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "81",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Block Producer (0% fee - 100% rewards) Austria",
    icon: "https://cdn.adapools.org/pool_logo/b28891f97d080494367e6015cad54aa0d6036179f8f4d0bcd2021037.png",
    pool_id: "b28891f97d080494367e6015cad54aa0d6036179f8f4d0bcd2021037",
    db_ticker: "ERE",
    db_name: "Block Producer (0% fee - 100% rewards) Austria",
    db_url: "https://blockproducer.io/",
    roa: "0",
    roa_lifetime: "2.379",
    total_stake: "223810272449",
    stake_x_deleg: "5738724934.5897",
    saturated: "0.0032557027539004",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "27000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Greek Pool",
    icon: "https://cdn.adapools.org/pool_logo/e356539d5414ec5e43e2ab1f445e986c614cd50fc34b8aa057c242d5.png",
    pool_id: "e356539d5414ec5e43e2ab1f445e986c614cd50fc34b8aa057c242d5",
    db_ticker: "ZORBA",
    db_name: "The Greek Pool",
    db_url: "https://www.zorbapool.com",
    roa: "0",
    roa_lifetime: "3.994",
    total_stake: "83529834306",
    stake_x_deleg: "2456759832.5294",
    saturated: "0.0012150841362514",
    luck_lifetime: "173",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mamak_Pool",
    icon: "0",
    pool_id: "9a9e68c028c4bcab3fa2f9e8832fca0086ac83d01d22bfedcd41dc31",
    db_ticker: "MAMAK",
    db_name: "Mamak_Pool",
    db_url: "https://mamakpool.com",
    roa: "1.147",
    roa_lifetime: "1.42",
    total_stake: "320846832983",
    stake_x_deleg: "12340262807.038",
    saturated: "0.0046672652970431",
    luck_lifetime: "63",
    blocks_epoch: "0",
    blocks_lifetime: "29",
    tax_ratio: "0.0001",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HOLA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ce9f192835046ba71ed302d5d3e0ba99d65fd3950346cadad4781814.png",
    pool_id: "ce9f192835046ba71ed302d5d3e0ba99d65fd3950346cadad4781814",
    db_ticker: "HOLA",
    db_name: "HOLA Stake Pool",
    db_url: "https://holastakepool.com",
    roa: "2.019",
    roa_lifetime: "2.594",
    total_stake: "161649350570",
    stake_x_deleg: "10103084410.625",
    saturated: "0.0023514659539896",
    luck_lifetime: "70",
    blocks_epoch: "0",
    blocks_lifetime: "75",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "75000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Money Pool UK",
    icon: "0",
    pool_id: "5d1f0fab23727b9251bcae7c89868a493e1fde80ac95515ac6b15746",
    db_ticker: "MONEY",
    db_name: "Money Pool UK",
    db_url: "https://themoneypool.uk",
    roa: "0.746",
    roa_lifetime: "3.435",
    total_stake: "486201907346",
    stake_x_deleg: "69457415335.143",
    saturated: "0.0070726373341899",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "60",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Epic Stake Pool",
    icon: "0",
    pool_id: "ffffb02a48c007d4531ef7c38a6e354c013d47f9eb4862ac463d553d",
    db_ticker: "EPIC",
    db_name: "Epic Stake Pool",
    db_url: "https://www.epicpool.eu/",
    roa: "1.075",
    roa_lifetime: "4.71",
    total_stake: "295454611913",
    stake_x_deleg: "5094045032.9828",
    saturated: "0.0042978920633633",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "411",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JustStaking.com",
    icon: "0",
    pool_id: "6e244c629b648b065ca4854ea7f8fb9838be54f688a5ee005074bdba",
    db_ticker: "JUST",
    db_name: "JustStaking.com",
    db_url: "https://juststaking.com",
    roa: "0.761",
    roa_lifetime: "2.87",
    total_stake: "475542527293",
    stake_x_deleg: "6096699067.859",
    saturated: "0.0069175784416124",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "78",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoWeed",
    icon: "https://cdn.adapools.org/pool_logo/932a460e99687c00e8afbda204f1aedb9479a9c4448c4bf738058590.png",
    pool_id: "932a460e99687c00e8afbda204f1aedb9479a9c4448c4bf738058590",
    db_ticker: "WEED",
    db_name: "CardanoWeed",
    db_url: "https://cardano420.com",
    roa: "3.152",
    roa_lifetime: "4.032",
    total_stake: "1005073769518",
    stake_x_deleg: "4036440841.4378",
    saturated: "0.01462051497229",
    luck_lifetime: "111",
    blocks_epoch: "1",
    blocks_lifetime: "162",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardino Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/1b2523e031e0efe6e5fe0edf8c82fd51abf33a1a2feedad5456ef4d9.png",
    pool_id: "1b2523e031e0efe6e5fe0edf8c82fd51abf33a1a2feedad5456ef4d9",
    db_ticker: "DINO",
    db_name: "Cardino Stake Pool",
    db_url: "https://cardino-ada.netlify.app",
    roa: "2.333",
    roa_lifetime: "4.461",
    total_stake: "14042944659283",
    stake_x_deleg: "12594569201.151",
    saturated: "0.20427862001069",
    luck_lifetime: "96",
    blocks_epoch: "8",
    blocks_lifetime: "2034",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MELD",
    icon: "https://cdn.adapools.org/pool_logo/ed6b2d4bb0ac257bc4182e8671eef06c8d2e4d7c11871ad5f213a52c.png",
    pool_id: "ed6b2d4bb0ac257bc4182e8671eef06c8d2e4d7c11871ad5f213a52c",
    db_ticker: "MELD",
    db_name: "MELD",
    db_url: "https://meld.com",
    roa: "3.424",
    roa_lifetime: "0.087",
    total_stake: "705585038152",
    stake_x_deleg: "1030051150.5869",
    saturated: "0.010263939749887",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "1834",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAVibe",
    icon: "https://cdn.adapools.org/pool_logo/131719731bf99518d44caadc1dfd37f9a7ddb86109f82011cd2ccb5c.png",
    pool_id: "131719731bf99518d44caadc1dfd37f9a7ddb86109f82011cd2ccb5c",
    db_ticker: "VIBE",
    db_name: "ADAVibe",
    db_url: "https://adavibe.eu",
    roa: "0",
    roa_lifetime: "4.604",
    total_stake: "150907621852",
    stake_x_deleg: "6287817577.1667",
    saturated: "0.0021952091594012",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "77",
    tax_ratio: "0.0195",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Coudre Bros",
    icon: "https://cdn.adapools.org/pool_logo/74d66da57368e12f12bd07f303da7f8cd3c2494af9e32e4dea210c58.png",
    pool_id: "74d66da57368e12f12bd07f303da7f8cd3c2494af9e32e4dea210c58",
    db_ticker: "CBROS",
    db_name: "Coudre Bros",
    db_url: "https://www.coudrebros.eu",
    roa: "0",
    roa_lifetime: "1.713",
    total_stake: "153767231022",
    stake_x_deleg: "11828248540.154",
    saturated: "0.0022368070599264",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Roam Pool 天涯",
    icon: "https://cdn.adapools.org/pool_logo/07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d.png",
    pool_id: "07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d",
    db_ticker: "ROAM",
    db_name: "The Roam Pool 天涯",
    db_url: "https://roampool.com",
    roa: "0",
    roa_lifetime: "2.55",
    total_stake: "521895765378",
    stake_x_deleg: "24852179303.714",
    saturated: "0.0075918654760465",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "16",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mylo Pool",
    icon: "0",
    pool_id: "90e719ff625d4652a6f41568f9cdc0c8ddf90a7556929b4d30298e8c",
    db_ticker: "MYLO",
    db_name: "Mylo Pool",
    db_url: "https://mylo.farm",
    roa: "0",
    roa_lifetime: "3.018",
    total_stake: "355556478811",
    stake_x_deleg: "2079277653.8655",
    saturated: "0.0051721763910363",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "86",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "0 % fees. Cardano Singapore Pool",
    icon: "https://cdn.adapools.org/pool_logo/627a4ecacdc3d0e3fb5fe9a8cee8880a43069ec2f46d5217decef71c.png",
    pool_id: "627a4ecacdc3d0e3fb5fe9a8cee8880a43069ec2f46d5217decef71c",
    db_ticker: "KAYA",
    db_name: "0 % fees. Cardano Singapore Pool",
    db_url: "https://www.kayapool.com",
    roa: "2.56",
    roa_lifetime: "2.588",
    total_stake: "120765206499",
    stake_x_deleg: "2808493174.3953",
    saturated: "0.00175673623499",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VICISpool",
    icon: "https://cdn.adapools.org/pool_logo/8881495966f1b73427233aa761c0fd04adfd91b3599a012d7d845472.png",
    pool_id: "8881495966f1b73427233aa761c0fd04adfd91b3599a012d7d845472",
    db_ticker: "VICIS",
    db_name: "VICISpool",
    db_url: "https://www.vicispool.com",
    roa: "6.368",
    roa_lifetime: "2.428",
    total_stake: "179816444732",
    stake_x_deleg: "7492351863.8333",
    saturated: "0.0026157373739133",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "87619000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Paradise Staking Pool",
    icon: "0",
    pool_id: "227b824daf6762372bcb97da913e89d5d32ba591d39cdbce2d22ae90",
    db_ticker: "PARAD",
    db_name: "Paradise Staking Pool",
    db_url: "https://paradise-staking.com/",
    roa: "1.152",
    roa_lifetime: "3.651",
    total_stake: "323048427858",
    stake_x_deleg: "16152421392.9",
    saturated: "0.004699291255544",
    luck_lifetime: "72",
    blocks_epoch: "0",
    blocks_lifetime: "90",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Love Cardano",
    icon: "0",
    pool_id: "887caa7fcc065a6658705f41ed911ccbb165c91fe85df84479f3bb63",
    db_ticker: "ANCO",
    db_name: "Love Cardano",
    db_url: "https://t.me/StakingADA",
    roa: "3.132",
    roa_lifetime: "2.426",
    total_stake: "348864302247",
    stake_x_deleg: "49837757463.857",
    saturated: "0.005074827250487",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SolidOne",
    icon: "https://cdn.adapools.org/pool_logo/bbd6930ee861b48f45ede4f2990275a80c6b76374f592edb99fc3ba2.png",
    pool_id: "bbd6930ee861b48f45ede4f2990275a80c6b76374f592edb99fc3ba2",
    db_ticker: "SONE",
    db_name: "SolidOne",
    db_url: "http://solidone.io",
    roa: "3.782",
    roa_lifetime: "2.044",
    total_stake: "690946401329",
    stake_x_deleg: "138189280265.8",
    saturated: "0.010050995769718",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "46",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HISTAKE POOL",
    icon: "https://cdn.adapools.org/pool_logo/402d40881b1e0b8d3e2fdd21254234d3c9bdd09b046e096c5a625bb3.png",
    pool_id: "402d40881b1e0b8d3e2fdd21254234d3c9bdd09b046e096c5a625bb3",
    db_ticker: "HIST",
    db_name: "HISTAKE POOL",
    db_url: "https://histake.eu",
    roa: "3.26",
    roa_lifetime: "3.228",
    total_stake: "235626941239",
    stake_x_deleg: "10710315510.864",
    saturated: "0.0034275963881853",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "91",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanoscan Pool 2",
    icon: "0",
    pool_id: "da50099e7aa1d926e1888990b1c404caf554dd6f68a1cb0322999d1d",
    db_ticker: "CSN2",
    db_name: "Cardanoscan Pool 2",
    db_url: "https://cardanoscan.io",
    roa: "4.708",
    roa_lifetime: "3.212",
    total_stake: "1382730269169",
    stake_x_deleg: "62851375871.318",
    saturated: "0.020114173920506",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "27",
    tax_ratio: "0.0189",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HUNNY pool",
    icon: "https://cdn.adapools.org/pool_logo/ff5eb7a210b3b322271da7f17753a06d107bda666f34be10017ad723.png",
    pool_id: "ff5eb7a210b3b322271da7f17753a06d107bda666f34be10017ad723",
    db_ticker: "HUNNY",
    db_name: "HUNNY pool",
    db_url: "https://www.hunny-pool.com",
    roa: "1.307",
    roa_lifetime: "3.679",
    total_stake: "1117239464223",
    stake_x_deleg: "22344789284.46",
    saturated: "0.016252156617458",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "208",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wookie Nation",
    icon: "https://cdn.adapools.org/pool_logo/6ce0221601e7b75e1607902e5a6c9c0743c93b12e50b1c830247e9ab.png",
    pool_id: "6ce0221601e7b75e1607902e5a6c9c0743c93b12e50b1c830247e9ab",
    db_ticker: "WOOK",
    db_name: "Wookie Nation",
    db_url: "https://wookie-nation.com",
    roa: "5.509",
    roa_lifetime: "2.411",
    total_stake: "196394589698",
    stake_x_deleg: "28056369956.857",
    saturated: "0.0028568948133363",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The ADA House",
    icon: "https://cdn.adapools.org/pool_logo/5201765589793cc9a04d8f378bc85125ec0ef7bf70beb84d01692c82.png",
    pool_id: "5201765589793cc9a04d8f378bc85125ec0ef7bf70beb84d01692c82",
    db_ticker: "ADAHS",
    db_name: "The ADA House",
    db_url: "https://the.adahou.se",
    roa: "0",
    roa_lifetime: "2.982",
    total_stake: "128589385744",
    stake_x_deleg: "1530826020.7619",
    saturated: "0.0018705522883652",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "71",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BM Pool",
    icon: "0",
    pool_id: "d0a506b386ec4ae2daa7c15b0f4279ae0bfbe3fbde3e4a470deaeb3e",
    db_ticker: "BMP",
    db_name: "BM Pool",
    db_url: "https://bmpool.online",
    roa: "0",
    roa_lifetime: "3.341",
    total_stake: "466619293748",
    stake_x_deleg: "33329949553.429",
    saturated: "0.0067877747658996",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "92",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vivid Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/194430bee1245b2d7e19a33e52635e5328ef24431874a0cb191c0195.png",
    pool_id: "194430bee1245b2d7e19a33e52635e5328ef24431874a0cb191c0195",
    db_ticker: "VSP",
    db_name: "Vivid Stake Pool",
    db_url: "https://vivid-pool.info",
    roa: "1.511",
    roa_lifetime: "3.045",
    total_stake: "235433957310",
    stake_x_deleg: "15695597154",
    saturated: "0.0034247891072583",
    luck_lifetime: "117",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RarePool",
    icon: "0",
    pool_id: "dc31fffacd510b8a6accf2eecf59852ba4c18fcae383be5c37efd721",
    db_ticker: "RARE",
    db_name: "RarePool",
    db_url: "https://rarepool.io",
    roa: "2.068",
    roa_lifetime: "5.036",
    total_stake: "227458698219",
    stake_x_deleg: "2879224028.0886",
    saturated: "0.0033087753394293",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "319",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Allincrypto",
    icon: "https://cdn.adapools.org/pool_logo/37a4728a33f13c83c0967ea7dbe06eb3437bbb4d26d671c5093f3e4b.png",
    pool_id: "37a4728a33f13c83c0967ea7dbe06eb3437bbb4d26d671c5093f3e4b",
    db_ticker: "ALLIN",
    db_name: "Allincrypto",
    db_url: "https://www.youtube.com/channel/UClFNxD5mGJa-XlZIcX2ko2A",
    roa: "3.822",
    roa_lifetime: "3.641",
    total_stake: "3640773485624",
    stake_x_deleg: "9682908206.4468",
    saturated: "0.052961269980022",
    luck_lifetime: "106",
    blocks_epoch: "4",
    blocks_lifetime: "94",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FlairStaking",
    icon: "https://cdn.adapools.org/pool_logo/f9188d72c00f3701e9c27c997f1b8bc21abc108dcd5d66056227ce30.png",
    pool_id: "f9188d72c00f3701e9c27c997f1b8bc21abc108dcd5d66056227ce30",
    db_ticker: "FLAIR",
    db_name: "FlairStaking",
    db_url: "http://flairstaking.io",
    roa: "1.615",
    roa_lifetime: "2.485",
    total_stake: "210909035772",
    stake_x_deleg: "6391182902.1818",
    saturated: "0.0030680322269026",
    luck_lifetime: "125",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Level Up!",
    icon: "https://cdn.adapools.org/pool_logo/91e34a10c60a797011bdafc723e0b86a2fdecd11731c8c9e403cfe6e.png",
    pool_id: "91e34a10c60a797011bdafc723e0b86a2fdecd11731c8c9e403cfe6e",
    db_ticker: "LvLUp",
    db_name: "Level Up!",
    db_url: "https://www.lvlup-stakepool.com/",
    roa: "1.711",
    roa_lifetime: "2.871",
    total_stake: "639602649808",
    stake_x_deleg: "45685903557.714",
    saturated: "0.0093041131919288",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "73",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake for Africa",
    icon: "0",
    pool_id: "23f62d132c05a3545aabdfe3eee957d1568a160916990f8995826896",
    db_ticker: "SFA",
    db_name: "Stake for Africa",
    db_url: "https://stakepools.africa",
    roa: "0",
    roa_lifetime: "1.895",
    total_stake: "249258047136",
    stake_x_deleg: "22659822466.909",
    saturated: "0.0036258841098433",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Women in STEM Pool",
    icon: "0",
    pool_id: "b278f51fc76c04afa83bf9475525978abf514e3b84988499fef02243",
    db_ticker: "WOMEN",
    db_name: "Women in STEM Pool",
    db_url: "https://womenpool.net",
    roa: "0",
    roa_lifetime: "2.035",
    total_stake: "359888969461",
    stake_x_deleg: "17137569974.333",
    saturated: "0.0052351998688513",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "19",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25005000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Latin America Stake Pool",
    icon: "0",
    pool_id: "dc5dddfa4734bb70d3607c469b613825696cb049266db693d531d174",
    db_ticker: "LATSP",
    db_name: "Latin America Stake Pool",
    db_url: "https://www.latamstaker.com",
    roa: "0",
    roa_lifetime: "2.367",
    total_stake: "518639669286",
    stake_x_deleg: "47149060844.182",
    saturated: "0.0075444999959116",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "54",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "27700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lido Nation",
    icon: "https://cdn.adapools.org/pool_logo/b5a1a820cc3783a4e637bce79d1cc2774b241c08251e45c5d1f8f3f6.png",
    pool_id: "b5a1a820cc3783a4e637bce79d1cc2774b241c08251e45c5d1f8f3f6",
    db_ticker: "LIDO",
    db_name: "Lido Nation",
    db_url: "https://www.lidonation.com",
    roa: "0",
    roa_lifetime: "1.522",
    total_stake: "131308709974",
    stake_x_deleg: "2303661578.4912",
    saturated: "0.0019101095047855",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "41500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BreizhStakePool",
    icon: "https://cdn.adapools.org/pool_logo/9b9ad921921db31ca91cd6dfdf11f5efee8c1ab94671a6b4a8edc748.png",
    pool_id: "9b9ad921921db31ca91cd6dfdf11f5efee8c1ab94671a6b4a8edc748",
    db_ticker: "BZH",
    db_name: "BreizhStakePool",
    db_url: "https://breizhstakepool.io",
    roa: "0",
    roa_lifetime: "2.436",
    total_stake: "138269096900",
    stake_x_deleg: "7681616494.4444",
    saturated: "0.002011360223241",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Riverpool",
    icon: "https://cdn.adapools.org/pool_logo/5e44ad1c1a617a9152b884d5f4169677884912d8884c71928875adaa.png",
    pool_id: "5e44ad1c1a617a9152b884d5f4169677884912d8884c71928875adaa",
    db_ticker: "RIVER",
    db_name: "Riverpool",
    db_url: "https://riverpool-crypto.com",
    roa: "3.239",
    roa_lifetime: "4.369",
    total_stake: "116922933794",
    stake_x_deleg: "1826920840.5312",
    saturated: "0.0017008438146376",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "315",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "𝛑 + ₳ = ∞",
    icon: "https://cdn.adapools.org/pool_logo/b8d8742c7b7b512468448429c776b3b0f824cef460db61aa1d24bc65.png",
    pool_id: "b8d8742c7b7b512468448429c776b3b0f824cef460db61aa1d24bc65",
    db_ticker: "PIADA",
    db_name: "𝛑 + ₳ = ∞",
    db_url: "https://tinyurl.com/3n6ftstn",
    roa: "3.333",
    roa_lifetime: "4.339",
    total_stake: "111210436244",
    stake_x_deleg: "1917421314.5517",
    saturated: "0.0016177457789591",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "264",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "55000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hermes Stakepool Germany #2",
    icon: "https://cdn.adapools.org/pool_logo/be1078775557de6c9116130289c6099520a267820ba1eebd040909e4.png",
    pool_id: "be1078775557de6c9116130289c6099520a267820ba1eebd040909e4",
    db_ticker: "HRMS2",
    db_name: "Hermes Stakepool Germany #2",
    db_url: "https://www.hermes-stakepool.com",
    roa: "3.333",
    roa_lifetime: "3.961",
    total_stake: "288064059664",
    stake_x_deleg: "2769846727.5385",
    saturated: "0.0041903838554217",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "255",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UN_ADA_SDGs",
    icon: "0",
    pool_id: "118af2b045067d1eb1816bc9e0030e11d294b27c0e89c02b27b83f58",
    db_ticker: "SDGs",
    db_name: "UN_ADA_SDGs",
    db_url: "https://twitter.com/SDGsPOOL",
    roa: "4.903",
    roa_lifetime: "4.198",
    total_stake: "6685484903048",
    stake_x_deleg: "27066740498.17",
    saturated: "0.097251798909154",
    luck_lifetime: "100",
    blocks_epoch: "8",
    blocks_lifetime: "617",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WHALEWALLET.IO",
    icon: "https://cdn.adapools.org/pool_logo/6ab1fd13a4e182f731ecf356ce3e145311f43cd512be2579ba3572e9.png",
    pool_id: "6ab1fd13a4e182f731ecf356ce3e145311f43cd512be2579ba3572e9",
    db_ticker: "WHALE",
    db_name: "WHALEWALLET.IO",
    db_url: "https://whalewallet.io",
    roa: "3.103",
    roa_lifetime: "3.87",
    total_stake: "113745094812",
    stake_x_deleg: "3159585967",
    saturated: "0.0016546167178563",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "174",
    tax_ratio: "0.0289",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Toba Mint",
    icon: "0",
    pool_id: "e2e2566040addf160449c4fd081bafa8056248f3372b30546963e703",
    db_ticker: "TOBA",
    db_name: "Toba Mint",
    db_url: "https://www.tobamint.io",
    roa: "3.076",
    roa_lifetime: "4.783",
    total_stake: "233225449118",
    stake_x_deleg: "17940419162.923",
    saturated: "0.0033926625827515",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "288",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ELEMENTAL Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/2f17551ea621427535afc23e233b2d6a40ef44999ced2b6b7b0ea8fc.png",
    pool_id: "2f17551ea621427535afc23e233b2d6a40ef44999ced2b6b7b0ea8fc",
    db_ticker: "ELEMT",
    db_name: "ELEMENTAL Stake Pool",
    db_url: "https://www.elemental-staking.com/",
    roa: "5.051",
    roa_lifetime: "4.211",
    total_stake: "202618836047",
    stake_x_deleg: "6331838626.4688",
    saturated: "0.0029474371095305",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "205",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rewards Notifications Bot Pool",
    icon: "https://cdn.adapools.org/pool_logo/adb8f11466290d1a8bef7c9665848cd66e45a7e74e5c25f586f0192e.png",
    pool_id: "adb8f11466290d1a8bef7c9665848cd66e45a7e74e5c25f586f0192e",
    db_ticker: "BOT",
    db_name: "Rewards Notifications Bot Pool",
    db_url: "https://t.me/AdaStatBot",
    roa: "0.25",
    roa_lifetime: "3.674",
    total_stake: "263735063682",
    stake_x_deleg: "37676437668.857",
    saturated: "0.003836477046983",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "91",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FLUID pool",
    icon: "https://cdn.adapools.org/pool_logo/38c2ea35c92392314b84f70535632b1b8ac3d3bd99fa7786296b160c.png",
    pool_id: "38c2ea35c92392314b84f70535632b1b8ac3d3bd99fa7786296b160c",
    db_ticker: "FLUID",
    db_name: "FLUID pool",
    db_url: "https://pool.fluid7.com",
    roa: "5.372",
    roa_lifetime: "2.587",
    total_stake: "137822207411",
    stake_x_deleg: "6891110370.55",
    saturated: "0.0020048594521902",
    luck_lifetime: "133",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "36000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA for Warriors",
    icon: "https://cdn.adapools.org/pool_logo/b6063f0f2fa05d98132f15defed4c69c06ea61451b4ea4cea0ce1b80.png",
    pool_id: "b6063f0f2fa05d98132f15defed4c69c06ea61451b4ea4cea0ce1b80",
    db_ticker: "4WARD",
    db_name: "ADA for Warriors",
    db_url: "https://4wardpool.swiftcryptollc.com",
    roa: "0.963",
    roa_lifetime: "2.784",
    total_stake: "394574201465",
    stake_x_deleg: "39457420146.5",
    saturated: "0.0057397558220675",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "16600000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hexadecimal",
    icon: "0",
    pool_id: "ad91e2a62ca7c09606995819b26b65b70419b4ef7531fad403f9d61e",
    db_ticker: "HEX",
    db_name: "Hexadecimal",
    db_url: "https://twitter.com/HexadecimalPool",
    roa: "4.346",
    roa_lifetime: "4.244",
    total_stake: "337255262546",
    stake_x_deleg: "24089661610.429",
    saturated: "0.0049059539360001",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "314",
    tax_ratio: "0.042",
    tax_fix: "340000000",
    pledge: "109000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hodler Coalition",
    icon: "https://cdn.adapools.org/pool_logo/cf7249855783f4ca98e0d5249da8c0c362dc1de36294f46ccc720fba.png",
    pool_id: "cf7249855783f4ca98e0d5249da8c0c362dc1de36294f46ccc720fba",
    db_ticker: "HODLR",
    db_name: "Hodler Coalition",
    db_url: "https://www.hodlerstaking.com",
    roa: "3.643",
    roa_lifetime: "2.786",
    total_stake: "185154171670",
    stake_x_deleg: "10286342870.556",
    saturated: "0.0026933837308095",
    luck_lifetime: "137",
    blocks_epoch: "1",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EnigmA",
    icon: "https://cdn.adapools.org/pool_logo/3805681d8d4bc9c7f298c24fdeca3406849e2db84a0708718d96d0b2.png",
    pool_id: "3805681d8d4bc9c7f298c24fdeca3406849e2db84a0708718d96d0b2",
    db_ticker: "one",
    db_name: "EnigmA",
    db_url: "https://red-sky.one",
    roa: "0",
    roa_lifetime: "3.546",
    total_stake: "147228229962",
    stake_x_deleg: "2133742463.2174",
    saturated: "0.0021416861187567",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "100",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "27000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Development Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ca97f539e6a878e7a7d87d762982a016ac6959d76719c8212a4a39e0.png",
    pool_id: "ca97f539e6a878e7a7d87d762982a016ac6959d76719c8212a4a39e0",
    db_ticker: "DEV",
    db_name: "Development Stake Pool",
    db_url: "https://devstakepool.github.io/",
    roa: "5.209",
    roa_lifetime: "2.177",
    total_stake: "981516913702",
    stake_x_deleg: "25829392465.842",
    saturated: "0.014277840261633",
    luck_lifetime: "111",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Panda Panda Pool",
    icon: "https://cdn.adapools.org/pool_logo/359f35c5dbf9121784988355771054feb9c82d61d1f2b1226cea5448.png",
    pool_id: "359f35c5dbf9121784988355771054feb9c82d61d1f2b1226cea5448",
    db_ticker: "PPP",
    db_name: "Panda Panda Pool",
    db_url: "https://pandapandapool.net/",
    roa: "3.201",
    roa_lifetime: "3.047",
    total_stake: "115546755522",
    stake_x_deleg: "5023771979.2174",
    saturated: "0.0016808249507089",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "49000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alamo Stakepool",
    icon: "0",
    pool_id: "cace07533247e80000ecaa1a98657e3a2580dee73e30a37644b211de",
    db_ticker: "ALAM0",
    db_name: "Alamo Stakepool",
    db_url: "https://alamopool.io",
    roa: "3.85",
    roa_lifetime: "3.59",
    total_stake: "1319535830910",
    stake_x_deleg: "10072792602.366",
    saturated: "0.019194902859264",
    luck_lifetime: "142",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanodo",
    icon: "https://cdn.adapools.org/pool_logo/20c00ebe39ed12dbd2f3e84a5b57b85c972d1039cf79c70d893b6227.png",
    pool_id: "20c00ebe39ed12dbd2f3e84a5b57b85c972d1039cf79c70d893b6227",
    db_ticker: "NODO",
    db_name: "Cardanodo",
    db_url: "https://cardanodo.com",
    roa: "5.572",
    roa_lifetime: "2.822",
    total_stake: "134551978721",
    stake_x_deleg: "12231998065.545",
    saturated: "0.0019572883892742",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "33",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VILAI",
    icon: "0",
    pool_id: "e7843464d334090c2e7377e5291363f3ef93a8a91d2716653361de8e",
    db_ticker: "VILAI",
    db_name: "VILAI",
    db_url: "https://github.com/tienna",
    roa: "1.156",
    roa_lifetime: "4.255",
    total_stake: "322610911544",
    stake_x_deleg: "3258696076.202",
    saturated: "0.0046929268333359",
    luck_lifetime: "106",
    blocks_epoch: "1",
    blocks_lifetime: "118",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADASquirrel",
    icon: "https://cdn.adapools.org/pool_logo/c9a397b8aa1d963030999d4edfd0f809f290f9899c73dfa1ad3a6d70.png",
    pool_id: "c9a397b8aa1d963030999d4edfd0f809f290f9899c73dfa1ad3a6d70",
    db_ticker: "SQRL",
    db_name: "ADASquirrel",
    db_url: "https://adasquirrel.com",
    roa: "0",
    roa_lifetime: "1.109",
    total_stake: "154727064869",
    stake_x_deleg: "3223480518.1042",
    saturated: "0.0022507694829411",
    luck_lifetime: "49",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "resilientpool",
    icon: "0",
    pool_id: "9af4bf5a4d81006bfb8e821e6438006b4511ad196dcbd5e5820d3568",
    db_ticker: "MAROC",
    db_name: "resilientpool",
    db_url: "https://maroc.resilientpool.com",
    roa: "1.74",
    roa_lifetime: "1.881",
    total_stake: "188271624295",
    stake_x_deleg: "5705200736.2121",
    saturated: "0.0027387324048686",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "13",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Q - Multiowners",
    icon: "https://cdn.adapools.org/pool_logo/9d08bca04e8e670c25d559dd09116312947ea19b2e226ef478cf8289.png",
    pool_id: "9d08bca04e8e670c25d559dd09116312947ea19b2e226ef478cf8289",
    db_ticker: "_Q_",
    db_name: "Q - Multiowners",
    db_url: "https://sites.google.com/view/mango-staking-pool",
    roa: "2.061",
    roa_lifetime: "4.25",
    total_stake: "174610991951",
    stake_x_deleg: "8314809140.5238",
    saturated: "0.0025400151705981",
    luck_lifetime: "122",
    blocks_epoch: "0",
    blocks_lifetime: "35",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25010000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GranADA_Staking_Pool",
    icon: "https://cdn.adapools.org/pool_logo/7a4c1ee9d663dd8d3b1bedf13155cc624c5dfa95f432c30d198cd3c6.png",
    pool_id: "7a4c1ee9d663dd8d3b1bedf13155cc624c5dfa95f432c30d198cd3c6",
    db_ticker: "GRANA",
    db_name: "GranADA_Staking_Pool",
    db_url: "https://granadapool.com",
    roa: "5.631",
    roa_lifetime: "3.852",
    total_stake: "130621758630",
    stake_x_deleg: "3185896551.9512",
    saturated: "0.0019001166239495",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "260",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANFRA, The antifragile Pool",
    icon: "0",
    pool_id: "007824d2d830f7c4e80854e7c2e21ebc94dd5b314b8543ffc3fe5382",
    db_ticker: "ANFRA",
    db_name: "ANFRA, The antifragile Pool",
    db_url: "https://anfra.io",
    roa: "1.673",
    roa_lifetime: "4.676",
    total_stake: "222514180822",
    stake_x_deleg: "24723797869.111",
    saturated: "0.0032368488870374",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "109",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000003",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VALHALLA",
    icon: "https://cdn.adapools.org/pool_logo/29889ecc8151e5a93fcd321cd905eee0f50c8364273550205058bfe8.png",
    pool_id: "29889ecc8151e5a93fcd321cd905eee0f50c8364273550205058bfe8",
    db_ticker: "VLHLA",
    db_name: "VALHALLA",
    db_url: "https://valhallapool.net",
    roa: "2.665",
    roa_lifetime: "3.387",
    total_stake: "145468280554",
    stake_x_deleg: "1564175059.7204",
    saturated: "0.0021160846480482",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "95",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nuclear Stake",
    icon: "https://cdn.adapools.org/pool_logo/d114d9973ec39fe72f23411661c740f91a9cfbb22a8d7650b9d04591.png",
    pool_id: "d114d9973ec39fe72f23411661c740f91a9cfbb22a8d7650b9d04591",
    db_ticker: "NUKE",
    db_name: "Nuclear Stake",
    db_url: "https://nuclearstake.io",
    roa: "0",
    roa_lifetime: "0.565",
    total_stake: "117192458648",
    stake_x_deleg: "7324528665.5",
    saturated: "0.0017047645140756",
    luck_lifetime: "28",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "105000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rain Pool",
    icon: "0",
    pool_id: "a8767e82b9e6b70a8e9941352aa1f327b5808104abc0821ba7c552f5",
    db_ticker: "RAIN",
    db_name: "Rain Pool",
    db_url: "https://rainstake.io",
    roa: "3.333",
    roa_lifetime: "3.91",
    total_stake: "245408559831",
    stake_x_deleg: "8764591422.5357",
    saturated: "0.003569886740809",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "111",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SHIFT: a climate positive Cardano stake pool",
    icon: "0",
    pool_id: "90dd0a6038d4c8de3c861e4c9a313f423af48ea0fb7823e2110a01b2",
    db_ticker: "SHIFT",
    db_name: "SHIFT: a climate positive Cardano stake pool",
    db_url: "https://stakeshift.team/",
    roa: "0",
    roa_lifetime: "3.712",
    total_stake: "164650098500",
    stake_x_deleg: "3430210385.4167",
    saturated: "0.0023951169588902",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "469",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CANDY POOL",
    icon: "https://cdn.adapools.org/pool_logo/9e2e33c5387dc64b32ff9df559c5df5d1966f3caf1a590d0a298dc28.png",
    pool_id: "9e2e33c5387dc64b32ff9df559c5df5d1966f3caf1a590d0a298dc28",
    db_ticker: "CANDY",
    db_name: "CANDY POOL",
    db_url: "https://ada-candy-stakepool.com",
    roa: "1.679",
    roa_lifetime: "2.919",
    total_stake: "869616992252",
    stake_x_deleg: "57974466150.133",
    saturated: "0.012650064742487",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "86",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MAD TURK",
    icon: "https://cdn.adapools.org/pool_logo/d7876f8358f5e479ae2839de62d43353b07b2006a40fbf94e723c18c.png",
    pool_id: "d7876f8358f5e479ae2839de62d43353b07b2006a40fbf94e723c18c",
    db_ticker: "MADT",
    db_name: "MAD TURK",
    db_url: "https://mtpool.live/",
    roa: "0",
    roa_lifetime: "3.354",
    total_stake: "116453137249",
    stake_x_deleg: "8957933634.5385",
    saturated: "0.0016940098213245",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "83",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Al dia",
    icon: "https://cdn.adapools.org/pool_logo/3d32cae041dd2f829b8d550dfc0a9fb867f5484b0761ada0dc902d9f.png",
    pool_id: "3d32cae041dd2f829b8d550dfc0a9fb867f5484b0761ada0dc902d9f",
    db_ticker: "ALDIA",
    db_name: "Cardano Al dia",
    db_url: "https://cardanoaldia.com",
    roa: "3.051",
    roa_lifetime: "3.1",
    total_stake: "2646916885138",
    stake_x_deleg: "1893359717.5522",
    saturated: "0.038503927893895",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "104",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Private Cardano R&amp;D",
    icon: "https://cdn.adapools.org/pool_logo/ae7015d54c634f922f800d9800529c3c5a007148ab9d792ac4ff33ae.png",
    pool_id: "ae7015d54c634f922f800d9800529c3c5a007148ab9d792ac4ff33ae",
    db_ticker: "ATDT",
    db_name: "Private Cardano R&amp;D",
    db_url: "http://pool.atdt.io",
    roa: "2.213",
    roa_lifetime: "1.404",
    total_stake: "170519507038",
    stake_x_deleg: "34103901407.6",
    saturated: "0.0024804975329444",
    luck_lifetime: "67",
    blocks_epoch: "1",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "155000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaHero",
    icon: "https://cdn.adapools.org/pool_logo/f7811118c400834b2539bd24684266b786e986c97d74a3db104bf049.png",
    pool_id: "f7811118c400834b2539bd24684266b786e986c97d74a3db104bf049",
    db_ticker: "ADAHR",
    db_name: "AdaHero",
    db_url: "https://adahero.com",
    roa: "1.051",
    roa_lifetime: "2.699",
    total_stake: "350873637188",
    stake_x_deleg: "7797191937.5111",
    saturated: "0.005104056459805",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "30",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10160000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Honey Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/f6c74a55ccd5222aad6388c8d5d1b03234351c90e537f11718a74d15.png",
    pool_id: "f6c74a55ccd5222aad6388c8d5d1b03234351c90e537f11718a74d15",
    db_ticker: "HSP",
    db_name: "Honey Stake Pool",
    db_url: "https://honeystake.com",
    roa: "0",
    roa_lifetime: "2.282",
    total_stake: "113123206044",
    stake_x_deleg: "4350892540.1538",
    saturated: "0.0016455702833364",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SOLIA",
    icon: "https://cdn.adapools.org/pool_logo/eeb180952144eaf1aa5ff27a19322a5560e027ca1510bae10ec2e76a.png",
    pool_id: "eeb180952144eaf1aa5ff27a19322a5560e027ca1510bae10ec2e76a",
    db_ticker: "SOLIA",
    db_name: "SOLIA",
    db_url: "https://solia.inmanencia.org",
    roa: "2.222",
    roa_lifetime: "4.094",
    total_stake: "153450970964",
    stake_x_deleg: "1029872288.349",
    saturated: "0.0022322065171072",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "314",
    tax_ratio: "0.0165",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Earn Coin Pool",
    icon: "https://cdn.adapools.org/pool_logo/d61ab012cf4b0c24daf2447309638b25573f85b0a7e4ffdb60db9b22.png",
    pool_id: "d61ab012cf4b0c24daf2447309638b25573f85b0a7e4ffdb60db9b22",
    db_ticker: "ECP",
    db_name: "Earn Coin Pool",
    db_url: "https://earncoinpool.com",
    roa: "0",
    roa_lifetime: "2.227",
    total_stake: "171595721923",
    stake_x_deleg: "9031353785.4211",
    saturated: "0.0024961529169737",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COR Stake Pool",
    icon: "0",
    pool_id: "f9c2cef77a4a9d257676aeb847062a73ba1023a7c9f8b6663b4085c3",
    db_ticker: "COR",
    db_name: "COR Stake Pool",
    db_url: "https://corstakepool.com",
    roa: "7.843",
    roa_lifetime: "3.119",
    total_stake: "303324120297",
    stake_x_deleg: "75831030074.25",
    saturated: "0.0044123675065022",
    luck_lifetime: "128",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "49990000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hawaii Cardano",
    icon: "https://cdn.adapools.org/pool_logo/e9794106e9403e8fbe059b1a2728fa8f686cf6e90565f7ba542b2dca.png",
    pool_id: "e9794106e9403e8fbe059b1a2728fa8f686cf6e90565f7ba542b2dca",
    db_ticker: "HAW",
    db_name: "Hawaii Cardano",
    db_url: "https://hawaiicardano.com",
    roa: "0",
    roa_lifetime: "2.247",
    total_stake: "113170776663",
    stake_x_deleg: "11317077666.3",
    saturated: "0.0016462622792559",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DAPPS LATAM",
    icon: "0",
    pool_id: "5308082c3c654eb441444269e987fa0dc50c0d3ffd75f2034b757f53",
    db_ticker: "DALAT",
    db_name: "DAPPS LATAM",
    db_url: "http://dappslatam.com/",
    roa: "9.613",
    roa_lifetime: "3.192",
    total_stake: "365537437549",
    stake_x_deleg: "33230676140.818",
    saturated: "0.0053173664866217",
    luck_lifetime: "123",
    blocks_epoch: "0",
    blocks_lifetime: "25",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Froge Pool",
    icon: "https://cdn.adapools.org/pool_logo/889a3be6dadd665af903b8f6ff79425bcbf1ed4c1592987faaa67c40.png",
    pool_id: "889a3be6dadd665af903b8f6ff79425bcbf1ed4c1592987faaa67c40",
    db_ticker: "FPOOL",
    db_name: "Froge Pool",
    db_url: "https://www.froge.world/",
    roa: "1.862",
    roa_lifetime: "3.659",
    total_stake: "154924510557",
    stake_x_deleg: "3227593969.9375",
    saturated: "0.0022536416677748",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "102",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "11000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GrahamsNumberPlus1",
    icon: "0",
    pool_id: "4b0a9386e29fae1d2a240bb585a7d1e94f76fa63b9ddbf23e1c72e9d",
    db_ticker: "GNP1",
    db_name: "GrahamsNumberPlus1",
    db_url: "https://grahamsnumberplus1.com",
    roa: "0",
    roa_lifetime: "2.664",
    total_stake: "258854267583",
    stake_x_deleg: "5282740154.7551",
    saturated: "0.0037654775297273",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "26",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Pools United",
    icon: "https://cdn.adapools.org/pool_logo/b45c1860e038baa0642b352ccf447ed5e14430342a11dd75bae52f39.png",
    pool_id: "b45c1860e038baa0642b352ccf447ed5e14430342a11dd75bae52f39",
    db_ticker: "CPU",
    db_name: "Cardano Pools United",
    db_url: "https://www.cpoolsunited.com/pool?id=CPU",
    roa: "3.333",
    roa_lifetime: "3.94",
    total_stake: "85683670064",
    stake_x_deleg: "5355229379",
    saturated: "0.0012464153568073",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "240",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CDN Cardano",
    icon: "https://cdn.adapools.org/pool_logo/22531954c0bef931e312836efe14061ba1e33417ea246a7c165dbee9.png",
    pool_id: "22531954c0bef931e312836efe14061ba1e33417ea246a7c165dbee9",
    db_ticker: "CDNC",
    db_name: "CDN Cardano",
    db_url: "https://www.cdncardano.com",
    roa: "2.92",
    roa_lifetime: "3.341",
    total_stake: "122008902845",
    stake_x_deleg: "4518848253.5185",
    saturated: "0.0017748279229826",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "88",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeChaiin - ADA staking pool",
    icon: "https://cdn.adapools.org/pool_logo/e523d0b6e3cdca996ccb1e1668db22bc9bcc576a4fb096bca46f83a9.png",
    pool_id: "e523d0b6e3cdca996ccb1e1668db22bc9bcc576a4fb096bca46f83a9",
    db_ticker: "AENS",
    db_name: "StakeChaiin - ADA staking pool",
    db_url: "https://www.stakechaiin.io",
    roa: "0",
    roa_lifetime: "1.942",
    total_stake: "179330901969",
    stake_x_deleg: "22416362746.125",
    saturated: "0.0026086743249596",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Red Panda",
    icon: "https://cdn.adapools.org/pool_logo/796cf5f959b9d66176a15cfa89a480b4a7b4e74fea76bdd5ac917e2f.png",
    pool_id: "796cf5f959b9d66176a15cfa89a480b4a7b4e74fea76bdd5ac917e2f",
    db_ticker: "PNDA",
    db_name: "Red Panda",
    db_url: "https://blackhengeinv.github.io/RedPandaPool/",
    roa: "0.319",
    roa_lifetime: "1.817",
    total_stake: "1173602712094",
    stake_x_deleg: "1465171925.211",
    saturated: "0.017072056344599",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "29",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAZOO",
    icon: "https://cdn.adapools.org/pool_logo/0a8e559e598e166b7ef5b76a7f60d81fce421852c991921080b026f3.png",
    pool_id: "0a8e559e598e166b7ef5b76a7f60d81fce421852c991921080b026f3",
    db_ticker: "ZOO",
    db_name: "ADAZOO",
    db_url: "https://adazoo.com",
    roa: "1.681",
    roa_lifetime: "2.901",
    total_stake: "210903321724",
    stake_x_deleg: "3515055362.0667",
    saturated: "0.003067949106313",
    luck_lifetime: "132",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "11500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nizi Stake Pool ",
    icon: "https://cdn.adapools.org/pool_logo/cfb6ee1de1dc2406a4ffe78704b65487dbd85e1fffdf70cbc3b9a2ed.png",
    pool_id: "cfb6ee1de1dc2406a4ffe78704b65487dbd85e1fffdf70cbc3b9a2ed",
    db_ticker: "NIZI🌈",
    db_name: "Nizi Stake Pool ",
    db_url: "https://nizi-stake-pool.com",
    roa: "2.19",
    roa_lifetime: "1.322",
    total_stake: "171279555643",
    stake_x_deleg: "24468507949",
    saturated: "0.0024915537383157",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Free York",
    icon: "https://cdn.adapools.org/pool_logo/9aa5e16dda2a131764018bbb9546a18fc9695a152e0f5dff2046e7b4.png",
    pool_id: "9aa5e16dda2a131764018bbb9546a18fc9695a152e0f5dff2046e7b4",
    db_ticker: "YORK",
    db_name: "Free York",
    db_url: "https://free-york.com",
    roa: "0",
    roa_lifetime: "4.397",
    total_stake: "81970302838",
    stake_x_deleg: "4553905713.2222",
    saturated: "0.0011923980868597",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "70",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adapool.at",
    icon: "https://cdn.adapools.org/pool_logo/b1962b3224f93153dd12059d92c40a658daf3102ea0ab6013dfddd09.png",
    pool_id: "b1962b3224f93153dd12059d92c40a658daf3102ea0ab6013dfddd09",
    db_ticker: "ADAAT",
    db_name: "adapool.at",
    db_url: "https://www.adapool.at",
    roa: "0",
    roa_lifetime: "1.791",
    total_stake: "137872201804",
    stake_x_deleg: "22978700300.667",
    saturated: "0.0020055867060431",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "125000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Enact Pool",
    icon: "0",
    pool_id: "96cf088e26056c1ebbac23cba183f92eab937f31f99d30b0da53a537",
    db_ticker: "ENACT",
    db_name: "Enact Pool",
    db_url: "https://EnactPool.com",
    roa: "0",
    roa_lifetime: "4.33",
    total_stake: "147500368897",
    stake_x_deleg: "2418038834.377",
    saturated: "0.0021456448444685",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "278",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pacific Digital Technologies",
    icon: "0",
    pool_id: "8fb1ce80165d9e3bde3d28a551e2e3f501b7f8281233608ff9a00805",
    db_ticker: "PDT1",
    db_name: "Pacific Digital Technologies",
    db_url: "https://pdtpools.io",
    roa: "1.533",
    roa_lifetime: "2.121",
    total_stake: "237499259538",
    stake_x_deleg: "15833283969.2",
    saturated: "0.0034548324563761",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ziber Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/61a8252b7fb1a2fde1bda9c6bd0927995701f799e7fac8227c61ab4e.png",
    pool_id: "61a8252b7fb1a2fde1bda9c6bd0927995701f799e7fac8227c61ab4e",
    db_ticker: "ZIBER",
    db_name: "Ziber Stake Pool",
    db_url: "https://grafleck.com",
    roa: "6.055",
    roa_lifetime: "3.925",
    total_stake: "119455275230",
    stake_x_deleg: "5972763761.5",
    saturated: "0.0017376810468915",
    luck_lifetime: "168",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.0125",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZOEPOOL",
    icon: "0",
    pool_id: "9d217694cd5d60a46d5e49b13e665d05efb0e433d62ee560eab027c1",
    db_ticker: "ZOE",
    db_name: "ZOEPOOL",
    db_url: "https://zoepool.com",
    roa: "4.547",
    roa_lifetime: "4.041",
    total_stake: "583541303885",
    stake_x_deleg: "5453657045.6542",
    saturated: "0.0084886051443683",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "202",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Techs2Help",
    icon: "https://cdn.adapools.org/pool_logo/15b80fb09b9541a2786a7dda7c516178f9acef18ef9c98d7146f5d2e.png",
    pool_id: "15b80fb09b9541a2786a7dda7c516178f9acef18ef9c98d7146f5d2e",
    db_ticker: "T2H",
    db_name: "Techs2Help",
    db_url: "https://www.techs2help.ch",
    roa: "0",
    roa_lifetime: "8.133",
    total_stake: "209880225347",
    stake_x_deleg: "20988022534.7",
    saturated: "0.0030530664217264",
    luck_lifetime: "288",
    blocks_epoch: "1",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Midgard",
    icon: "0",
    pool_id: "edbf17f6796a0434a8269f000cf2cba87452abf39c9967c8a318f456",
    db_ticker: "MGARD",
    db_name: "Midgard",
    db_url: "https://midgard.digital",
    roa: "2.606",
    roa_lifetime: "2.257",
    total_stake: "160534086941",
    stake_x_deleg: "8449162470.5789",
    saturated: "0.0023352425392709",
    luck_lifetime: "94",
    blocks_epoch: "1",
    blocks_lifetime: "10",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADABLUE Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/b9147445047312aa139a331390bba715eebb58f1b66c96e660e48986.png",
    pool_id: "b9147445047312aa139a331390bba715eebb58f1b66c96e660e48986",
    db_ticker: "BLU",
    db_name: "ADABLUE Stake Pool",
    db_url: "https://www.adablue.io/",
    roa: "3.333",
    roa_lifetime: "1.533",
    total_stake: "101154264742",
    stake_x_deleg: "2661954335.3158",
    saturated: "0.0014714615852333",
    luck_lifetime: "52",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pilot Pool",
    icon: "https://cdn.adapools.org/pool_logo/c83beb18d532234c72a304ab935982be670c22e771b227a3febc3a82.png",
    pool_id: "c83beb18d532234c72a304ab935982be670c22e771b227a3febc3a82",
    db_ticker: "PILOT",
    db_name: "Pilot Pool",
    db_url: "https://www.adapilot.com",
    roa: "3.837",
    roa_lifetime: "4.28",
    total_stake: "68030481597083",
    stake_x_deleg: "8668511926.2338",
    saturated: "0.98961957313763",
    luck_lifetime: "98",
    blocks_epoch: "52",
    blocks_lifetime: "3519",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1050000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fine Stake Pool",
    icon: "0",
    pool_id: "e36bc28e2bc668de6f51fc111360f399c98fabff351110053ae5b15b",
    db_ticker: "FINE",
    db_name: "Fine Stake Pool",
    db_url: "https://twitter.com/Fine_stake_pool",
    roa: "4.437",
    roa_lifetime: "4.382",
    total_stake: "319936202308",
    stake_x_deleg: "9695036433.5758",
    saturated: "0.0046540186182203",
    luck_lifetime: "101",
    blocks_epoch: "1",
    blocks_lifetime: "306",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Black Box",
    icon: "https://cdn.adapools.org/pool_logo/628e6fdeda9732cbc61079581cf8e2e7b06d6f3219ec7cccb5861ba6.png",
    pool_id: "628e6fdeda9732cbc61079581cf8e2e7b06d6f3219ec7cccb5861ba6",
    db_ticker: "BLKBX",
    db_name: "Black Box",
    db_url: "https://www.blackboxpool.io",
    roa: "7.198",
    roa_lifetime: "4.506",
    total_stake: "161371143234",
    stake_x_deleg: "2017139290.425",
    saturated: "0.0023474189530184",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "297",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Here To STAY",
    icon: "https://cdn.adapools.org/pool_logo/aa6dd7ae211c87f6cadd6031a641e224d00f49c5cb736a7a502d5056.png",
    pool_id: "aa6dd7ae211c87f6cadd6031a641e224d00f49c5cb736a7a502d5056",
    db_ticker: "STAY",
    db_name: "Here To STAY",
    db_url: "https://www.stakepoolada.com",
    roa: "0",
    roa_lifetime: "4.112",
    total_stake: "73070800690",
    stake_x_deleg: "2706325951.4815",
    saturated: "0.0010629396248573",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "101",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Signature Staking",
    icon: "0",
    pool_id: "bbc236d1986b35cb7ae8f896dc0121a6052019a469ac6b36e838c765",
    db_ticker: "SIGN",
    db_name: "Signature Staking",
    db_url: "https://signaturestaking.com/",
    roa: "2.311",
    roa_lifetime: "4.618",
    total_stake: "306595338506",
    stake_x_deleg: "7299889012.0476",
    saturated: "0.0044599529636625",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "267",
    tax_ratio: "0.022",
    tax_fix: "340000000",
    pledge: "26000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Clean Water Pool",
    icon: "0",
    pool_id: "7cdddc5037c62acb499c3536ed218d17cdc1d9a5ad734218a4227a78",
    db_ticker: "H2O",
    db_name: "Cardano Clean Water Pool",
    db_url: "https://www.cleanwaterstakepool.com/",
    roa: "6.667",
    roa_lifetime: "4.843",
    total_stake: "97063805295",
    stake_x_deleg: "795604961.43443",
    saturated: "0.0014119588647344",
    luck_lifetime: "109",
    blocks_epoch: "1",
    blocks_lifetime: "222",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DIONYSUS",
    icon: "https://cdn.adapools.org/pool_logo/3d8103dd2ad0838d985a3fc00277c55da15279088f3b1dc11dd814ce.png",
    pool_id: "3d8103dd2ad0838d985a3fc00277c55da15279088f3b1dc11dd814ce",
    db_ticker: "DIO",
    db_name: "DIONYSUS",
    db_url: "https://dionysus-pool.io",
    roa: "0.384",
    roa_lifetime: "0.199",
    total_stake: "244678972442",
    stake_x_deleg: "6990827784.0571",
    saturated: "0.0035592736458621",
    luck_lifetime: "42",
    blocks_epoch: "1",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "🕱",
    icon: "https://cdn.adapools.org/pool_logo/bbd852e7fb67e8f182def1f99d22a51c76b40222ab47d0682223e606.png",
    pool_id: "bbd852e7fb67e8f182def1f99d22a51c76b40222ab47d0682223e606",
    db_ticker: "SKULL",
    db_name: "🕱",
    db_url: "https://nemo.global/",
    roa: "1.168",
    roa_lifetime: "3.024",
    total_stake: "860592156186",
    stake_x_deleg: "1933914957.7213",
    saturated: "0.012518783084536",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "36",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TricahuePool",
    icon: "https://cdn.adapools.org/pool_logo/70e483a5702e3907e4079113470209ceab93ad83070c84a62b20b350.png",
    pool_id: "70e483a5702e3907e4079113470209ceab93ad83070c84a62b20b350",
    db_ticker: "TCHP",
    db_name: "TricahuePool",
    db_url: "https://eztero.github.io/TricahuePool/index.html",
    roa: "0",
    roa_lifetime: "3.214",
    total_stake: "134971801578",
    stake_x_deleg: "14996866842",
    saturated: "0.0019633954299239",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "120",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AirAda",
    icon: "0",
    pool_id: "2f87930fa6d8ad4db23aba88921c35fb8c2f80999cad478de03b7171",
    db_ticker: "AADA",
    db_name: "AirAda",
    db_url: "http://AirAdaPool.com",
    roa: "0",
    roa_lifetime: "1.555",
    total_stake: "198703132431",
    stake_x_deleg: "6851832152.7931",
    saturated: "0.0028904765111336",
    luck_lifetime: "49",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.023",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tesla Pool",
    icon: "0",
    pool_id: "576bb31c4b323cd7757bd7f69dc740f648445971246ea2aedf6603a6",
    db_ticker: "TSLA",
    db_name: "Tesla Pool",
    db_url: "https://teslapool.io",
    roa: "0",
    roa_lifetime: "2.64",
    total_stake: "140624119828",
    stake_x_deleg: "7401269464.6316",
    saturated: "0.002045618054878",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "13",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano On the Ride",
    icon: "https://cdn.adapools.org/pool_logo/996affa4d66f85e88052a1294a907fcac7b422865649d2ac28fde3c7.png",
    pool_id: "996affa4d66f85e88052a1294a907fcac7b422865649d2ac28fde3c7",
    db_ticker: "FUNON",
    db_name: "Cardano On the Ride",
    db_url: "https://www.funontheride.com/pool-cardano",
    roa: "1.69",
    roa_lifetime: "3.165",
    total_stake: "1250727101687",
    stake_x_deleg: "2992170099.7297",
    saturated: "0.018193962344906",
    luck_lifetime: "107",
    blocks_epoch: "2",
    blocks_lifetime: "33",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mexico Pool",
    icon: "https://cdn.adapools.org/pool_logo/d39231fdecb829f683aae46c23ef651fbf40efe0071fad7909912c9b.png",
    pool_id: "d39231fdecb829f683aae46c23ef651fbf40efe0071fad7909912c9b",
    db_ticker: "ADAMX",
    db_name: "Mexico Pool",
    db_url: "https://twitter.com/AdaMXPool/",
    roa: "3.333",
    roa_lifetime: "4.746",
    total_stake: "75752105432",
    stake_x_deleg: "2705432336.8571",
    saturated: "0.0011019437828749",
    luck_lifetime: "116",
    blocks_epoch: "0",
    blocks_lifetime: "121",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "31787000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lalkul",
    icon: "https://cdn.adapools.org/pool_logo/671dd64d0255f9b857ff8d445ea5b2b4cead3a62411560ee649ddaf7.png",
    pool_id: "671dd64d0255f9b857ff8d445ea5b2b4cead3a62411560ee649ddaf7",
    db_ticker: "ALKUL",
    db_name: "Lalkul",
    db_url: "https://www.lalkul.com",
    roa: "0",
    roa_lifetime: "2.305",
    total_stake: "155107568692",
    stake_x_deleg: "11931351437.846",
    saturated: "0.0022563045610716",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "54",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adaberry Pi",
    icon: "https://cdn.adapools.org/pool_logo/33708d590f4549170125524fcbd6bc189fa8711fba52f8ead4276ca4.png",
    pool_id: "33708d590f4549170125524fcbd6bc189fa8711fba52f8ead4276ca4",
    db_ticker: "00000",
    db_name: "Adaberry Pi",
    db_url: "https://adaberrypi.com",
    roa: "0",
    roa_lifetime: "1.338",
    total_stake: "58266146083",
    stake_x_deleg: "2080933788.6786",
    saturated: "0.00084758063240737",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.0314",
    tax_fix: "340000000",
    pledge: "32000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TEAM Staking",
    icon: "0",
    pool_id: "9d2df92d8dba14560ee3d732905bda68721125dc641c7135e2a1009e",
    db_ticker: "TEAM",
    db_name: "TEAM Staking",
    db_url: "https://teamstaking.com/",
    roa: "1.917",
    roa_lifetime: "2.69",
    total_stake: "380737002693",
    stake_x_deleg: "38073700269.3",
    saturated: "0.0055384701274686",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "40",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Colombia",
    icon: "https://cdn.adapools.org/pool_logo/ddf686213f0a98f6515a7a700652e229ce222174158f5f82b43457bc.png",
    pool_id: "ddf686213f0a98f6515a7a700652e229ce222174158f5f82b43457bc",
    db_ticker: "CCOL",
    db_name: "Cardano Colombia",
    db_url: "https://www.cardanocolombia.com.co",
    roa: "1.448",
    roa_lifetime: "2.581",
    total_stake: "507391872327",
    stake_x_deleg: "11531633461.977",
    saturated: "0.0073808815742277",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "26",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WISE-POOL",
    icon: "https://cdn.adapools.org/pool_logo/0d549d59c5fd90ea2e7ef58041e14a10e15e0145386ba3a3b8709da4.png",
    pool_id: "0d549d59c5fd90ea2e7ef58041e14a10e15e0145386ba3a3b8709da4",
    db_ticker: "WISE",
    db_name: "WISE-POOL",
    db_url: "https://wise-pool.eu",
    roa: "0.79",
    roa_lifetime: "4.137",
    total_stake: "622367766608",
    stake_x_deleg: "24894710664.32",
    saturated: "0.0090534023729686",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "112",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jolly Roger's COFFR",
    icon: "https://cdn.adapools.org/pool_logo/651151eed67c4b506a0e4279040a5210d85ec88704d680f39bca2dda.png",
    pool_id: "651151eed67c4b506a0e4279040a5210d85ec88704d680f39bca2dda",
    db_ticker: "COFFR",
    db_name: "Jolly Roger's COFFR",
    db_url: "https://coffr.info",
    roa: "0",
    roa_lifetime: "1.542",
    total_stake: "70663641415",
    stake_x_deleg: "6423967401.3636",
    saturated: "0.0010279233809872",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "35000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AJGoldman NFT Community Pool",
    icon: "https://cdn.adapools.org/pool_logo/643808a16d63204df62860a87c5ec7e551ca11af8b8e15a6d08ad667.png",
    pool_id: "643808a16d63204df62860a87c5ec7e551ca11af8b8e15a6d08ad667",
    db_ticker: "KING1",
    db_name: "AJGoldman NFT Community Pool",
    db_url: "https://arthurjgoldman.com",
    roa: "3.046",
    roa_lifetime: "4.787",
    total_stake: "116344617684",
    stake_x_deleg: "3231794935.6667",
    saturated: "0.0016924312188647",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "466",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vetspool",
    icon: "0",
    pool_id: "a39c252e5179a5919a0b74b22bfc279801c3c88c305f7d3655b09a0d",
    db_ticker: "VETS",
    db_name: "Vetspool",
    db_url: "https://www.vetspool.io/",
    roa: "2.223",
    roa_lifetime: "2.47",
    total_stake: "207076324381",
    stake_x_deleg: "6090480128.8529",
    saturated: "0.0030122788921962",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Piece of Stake",
    icon: "0",
    pool_id: "2b88135bb280ef62e3193ef3a21031c19446022754679801133b7c79",
    db_ticker: "POS",
    db_name: "Piece of Stake",
    db_url: "https://pieceofstake.com",
    roa: "0",
    roa_lifetime: "3.677",
    total_stake: "233086465001",
    stake_x_deleg: "14567904062.562",
    saturated: "0.0033906408213394",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "107",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "25025000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Pool Party",
    icon: "https://cdn.adapools.org/pool_logo/dfd6ebd73fca6256db9c13e87fa13c61bc5c51d898a3017791e8ef02.png",
    pool_id: "dfd6ebd73fca6256db9c13e87fa13c61bc5c51d898a3017791e8ef02",
    db_ticker: "PARTY",
    db_name: "Crypto Pool Party",
    db_url: "https://cryptopoolparty.com",
    roa: "0",
    roa_lifetime: "2.015",
    total_stake: "152525623585",
    stake_x_deleg: "21789374797.857",
    saturated: "0.0022187457586837",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0.03",
    tax_fix: "345000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOStaking",
    icon: "0",
    pool_id: "4b4e09f1acb8957ecff8c36091b9a6dedce48c9df8f64d7744d2b080",
    db_ticker: "MOST",
    db_name: "MOStaking",
    db_url: "https://www.mostaking.com",
    roa: "0",
    roa_lifetime: "2.754",
    total_stake: "258208265283",
    stake_x_deleg: "32276033160.375",
    saturated: "0.0037560803226906",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "125000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wada Stakepool",
    icon: "0",
    pool_id: "77f64feb102629b2c0d9163fd6822b5ac95bcba5df33a8b5cdbd11e2",
    db_ticker: "WADA",
    db_name: "Wada Stakepool",
    db_url: "https://wada.org",
    roa: "0",
    roa_lifetime: "3.542",
    total_stake: "105750239642",
    stake_x_deleg: "2782901043.2105",
    saturated: "0.0015383178915818",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "244",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "11000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Austrian Volunteer Firefighters",
    icon: "https://cdn.adapools.org/pool_logo/fdb8e1e7f181f23d31f09bb4547370ef104688b37b3e7f4fdb222f4f.png",
    pool_id: "fdb8e1e7f181f23d31f09bb4547370ef104688b37b3e7f4fdb222f4f",
    db_ticker: "ATFF",
    db_name: "Austrian Volunteer Firefighters",
    db_url: "https://www.ATFFpool.at",
    roa: "0",
    roa_lifetime: "4.288",
    total_stake: "54020845606",
    stake_x_deleg: "1929315914.5",
    saturated: "0.000785825484608",
    luck_lifetime: "147",
    blocks_epoch: "0",
    blocks_lifetime: "21",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Silver City ",
    icon: "0",
    pool_id: "0000066c3b2b15f7aece355a112ef141e9fe84d2e2152d4398986e40",
    db_ticker: "SILVR",
    db_name: "Silver City ",
    db_url: "https://silvercitystaking.com",
    roa: "1.826",
    roa_lifetime: "4.057",
    total_stake: "200725444835",
    stake_x_deleg: "8363560201.4583",
    saturated: "0.0029198944998207",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "179",
    tax_ratio: "0.0175",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Cloud Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c0147890c29db928bb7cf0f40540c30038238c6f672357c78bc3d083.png",
    pool_id: "c0147890c29db928bb7cf0f40540c30038238c6f672357c78bc3d083",
    db_ticker: "CLOUD",
    db_name: "Cardano Cloud Stake Pool",
    db_url: "https://www.ada-pool.cloud",
    roa: "0",
    roa_lifetime: "4.234",
    total_stake: "48913364994",
    stake_x_deleg: "2126668043.2174",
    saturated: "0.00071152845385947",
    luck_lifetime: "210",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "18000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Summoning Pool",
    icon: "0",
    pool_id: "dc508ac7975e573adf6ef17873c74d200e0cd71931c139eb76853281",
    db_ticker: "SUMN",
    db_name: "The Summoning Pool",
    db_url: "https://cardania.com",
    roa: "1.919",
    roa_lifetime: "3.754",
    total_stake: "4367953872267",
    stake_x_deleg: "2532147172.3287",
    saturated: "0.063539350965628",
    luck_lifetime: "97",
    blocks_epoch: "4",
    blocks_lifetime: "289",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gaming Cardano",
    icon: "https://cdn.adapools.org/pool_logo/a5b7de0846612aa8ccdc4d0e8092e381dd34059b6584eacf0bcab7b9.png",
    pool_id: "a5b7de0846612aa8ccdc4d0e8092e381dd34059b6584eacf0bcab7b9",
    db_ticker: "GAME",
    db_name: "Gaming Cardano",
    db_url: "https://gamingcardano.com",
    roa: "3.796",
    roa_lifetime: "2.863",
    total_stake: "283886563179",
    stake_x_deleg: "1144703883.7863",
    saturated: "0.0041296150325174",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "45",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sir Reginald's Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/0a9db4326c331c435c9ebe91c14b3003831b70eba5a16c73fa2cd56f.png",
    pool_id: "0a9db4326c331c435c9ebe91c14b3003831b70eba5a16c73fa2cd56f",
    db_ticker: "CDINO",
    db_name: "Sir Reginald's Stake Pool",
    db_url: "https://cardinos.io/stake",
    roa: "3.333",
    roa_lifetime: "2.368",
    total_stake: "126726012905",
    stake_x_deleg: "431040860.22109",
    saturated: "0.0018434463471718",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "20",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Snowing ADA",
    icon: "https://cdn.adapools.org/pool_logo/c3102f5ac6b0518e15f1ba1125c105f6c974ec592e695f61c2862949.png",
    pool_id: "c3102f5ac6b0518e15f1ba1125c105f6c974ec592e695f61c2862949",
    db_ticker: "SNOWY",
    db_name: "Snowing ADA",
    db_url: "https://snowingada.io",
    roa: "0",
    roa_lifetime: "3.068",
    total_stake: "86874175532",
    stake_x_deleg: "5429635970.75",
    saturated: "0.001263733292612",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cloudberry Online Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/120440ea6ba3cf5c9c7998570b049db41be2f19fadc6964e02098891.png",
    pool_id: "120440ea6ba3cf5c9c7998570b049db41be2f19fadc6964e02098891",
    db_ticker: "CBOSP",
    db_name: "Cloudberry Online Stake Pool",
    db_url: "https://cbosp.com",
    roa: "3.335",
    roa_lifetime: "3.793",
    total_stake: "351190851450",
    stake_x_deleg: "15269167454.348",
    saturated: "0.0051086708831515",
    luck_lifetime: "137",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "4000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Red Horse",
    icon: "https://cdn.adapools.org/pool_logo/9539696ef32ea50fef0dd5a24dca2c9c931706532c3a3bdbdbf31766.png",
    pool_id: "9539696ef32ea50fef0dd5a24dca2c9c931706532c3a3bdbdbf31766",
    db_ticker: "REDH",
    db_name: "Red Horse",
    db_url: "https://redhorsepool.com",
    roa: "0",
    roa_lifetime: "3.983",
    total_stake: "45703535291",
    stake_x_deleg: "427135843.84112",
    saturated: "0.00066483599739061",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "110",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA LUCID",
    icon: "https://cdn.adapools.org/pool_logo/0d7af673b35b05c292168bab17b1069493d2d10fe095071f355fd724.png",
    pool_id: "0d7af673b35b05c292168bab17b1069493d2d10fe095071f355fd724",
    db_ticker: "LUCID",
    db_name: "ADA LUCID",
    db_url: "https://lucid.rizome.io/",
    roa: "3.333",
    roa_lifetime: "3.239",
    total_stake: "73920360655",
    stake_x_deleg: "8213373406.1111",
    saturated: "0.0010752979258744",
    luck_lifetime: "140",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Peachy Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/b7dcd684b724061fbb76ef4f78b7541a0a058ab7b860ef71828e5e19.png",
    pool_id: "b7dcd684b724061fbb76ef4f78b7541a0a058ab7b860ef71828e5e19",
    db_ticker: "PECHY",
    db_name: "Peachy Stake Pool",
    db_url: "https://peachypool.io",
    roa: "6.667",
    roa_lifetime: "3.018",
    total_stake: "66549766079",
    stake_x_deleg: "5119212775.3077",
    saturated: "0.00096808003638083",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "P3LOL Pool",
    icon: "https://cdn.adapools.org/pool_logo/bb236318e9fe9f7b2e287dd388f9cbd32cb2e3ead404684c720c91b6.png",
    pool_id: "bb236318e9fe9f7b2e287dd388f9cbd32cb2e3ead404684c720c91b6",
    db_ticker: "P3LOL",
    db_name: "P3LOL Pool",
    db_url: "https://p3lol.de",
    roa: "0",
    roa_lifetime: "3.03",
    total_stake: "51862830051",
    stake_x_deleg: "4714802731.9091",
    saturated: "0.0007544334617643",
    luck_lifetime: "127",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Envy Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/14eae9da8ab6d322176ea88f40e9d32d843996bf2de88240e35594ea.png",
    pool_id: "14eae9da8ab6d322176ea88f40e9d32d843996bf2de88240e35594ea",
    db_ticker: "ENVY",
    db_name: "Envy Stake Pool",
    db_url: "https://envystakepool.com",
    roa: "3.333",
    roa_lifetime: "2.65",
    total_stake: "76402200314",
    stake_x_deleg: "3056088012.56",
    saturated: "0.0011114005235083",
    luck_lifetime: "138",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Trump Fan",
    icon: "https://cdn.adapools.org/pool_logo/bebfeddd60032c8052e46587218dcd6f3e6f0b1ad9be3864508049bc.png",
    pool_id: "bebfeddd60032c8052e46587218dcd6f3e6f0b1ad9be3864508049bc",
    db_ticker: "Trump",
    db_name: "Trump Fan",
    db_url: "https://trumpfanpool.github.io/",
    roa: "6.667",
    roa_lifetime: "2.855",
    total_stake: "91982792296",
    stake_x_deleg: "8362072026.9091",
    saturated: "0.0013380468506323",
    luck_lifetime: "130",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garden Pool Three",
    icon: "https://cdn.adapools.org/pool_logo/c7a434e33111ad62c0b99882c748e9e748c3442d161565661ad8dda4.png",
    pool_id: "c7a434e33111ad62c0b99882c748e9e748c3442d161565661ad8dda4",
    db_ticker: "EDEN",
    db_name: "Garden Pool Three",
    db_url: "https://garden-pool.com",
    roa: "4.178",
    roa_lifetime: "4.588",
    total_stake: "67885441429673",
    stake_x_deleg: "19285636769.793",
    saturated: "0.98750971612662",
    luck_lifetime: "101",
    blocks_epoch: "51",
    blocks_lifetime: "4500",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOON1 Stake Pool powered by lunarpool.io",
    icon: "https://cdn.adapools.org/pool_logo/8253f8a94044070ddb196884eb3e912e9b6dca9a0e3210325a09a335.png",
    pool_id: "8253f8a94044070ddb196884eb3e912e9b6dca9a0e3210325a09a335",
    db_ticker: "MOON1",
    db_name: "MOON1 Stake Pool powered by lunarpool.io",
    db_url: "https://lunarpool.io",
    roa: "3.619",
    roa_lifetime: "4.037",
    total_stake: "1300300088753",
    stake_x_deleg: "24533963938.736",
    saturated: "0.018915086128653",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "152",
    tax_ratio: "0.0175",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAvault",
    icon: "https://cdn.adapools.org/pool_logo/3116c834a09b0060aef7284f63d3275456364e3309b3c19ec328af60.png",
    pool_id: "3116c834a09b0060aef7284f63d3275456364e3309b3c19ec328af60",
    db_ticker: "ADV",
    db_name: "ADAvault",
    db_url: "https://adavault.com",
    roa: "4.09",
    roa_lifetime: "4.667",
    total_stake: "68577550151175",
    stake_x_deleg: "14888742976.807",
    saturated: "0.99757761982888",
    luck_lifetime: "99",
    blocks_epoch: "52",
    blocks_lifetime: "6280",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAvault Stake Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/3b3327c0a885ba7c1ebeec8b44158aab79c32148d45b4c701344cd97.png",
    pool_id: "3b3327c0a885ba7c1ebeec8b44158aab79c32148d45b4c701344cd97",
    db_ticker: "ADV2",
    db_name: "ADAvault Stake Pool 2",
    db_url: "https://adavault.com",
    roa: "3.967",
    roa_lifetime: "4.605",
    total_stake: "68761128341249",
    stake_x_deleg: "20531838859.734",
    saturated: "1.0002480782151",
    luck_lifetime: "100",
    blocks_epoch: "66",
    blocks_lifetime: "5412",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeCare",
    icon: "0",
    pool_id: "e34717fcd0077c52fc9f2dfa64bdc241c10144dd4a90b081359aa0fd",
    db_ticker: "CARE",
    db_name: "StakeCare",
    db_url: "http://stake.care",
    roa: "1.513",
    roa_lifetime: "2.239",
    total_stake: "469371686773",
    stake_x_deleg: "13410619622.086",
    saturated: "0.0068278130244355",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Livemint NFT Pool",
    icon: "https://cdn.adapools.org/pool_logo/ae96cb925985079c1108a412e810be1871d68cab6dffb6eddbac9adb.png",
    pool_id: "ae96cb925985079c1108a412e810be1871d68cab6dffb6eddbac9adb",
    db_ticker: "LVMNT",
    db_name: "Livemint NFT Pool",
    db_url: "https://livemintnft.com",
    roa: "0",
    roa_lifetime: "0.904",
    total_stake: "95027133963",
    stake_x_deleg: "5001428103.3158",
    saturated: "0.0013823320009099",
    luck_lifetime: "34",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "16000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PLOT Stake pool",
    icon: "https://cdn.adapools.org/pool_logo/45e00d4c106ecc6c84b21a77f7cb52b1634e5c1e697f4ebfebfff2cf.png",
    pool_id: "45e00d4c106ecc6c84b21a77f7cb52b1634e5c1e697f4ebfebfff2cf",
    db_ticker: "PLOT",
    db_name: "PLOT Stake pool",
    db_url: "https://www.plotcardano.com",
    roa: "0",
    roa_lifetime: "3.888",
    total_stake: "69856028680",
    stake_x_deleg: "4657068578.6667",
    saturated: "0.00101617527409",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "97",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Studio3121",
    icon: "0",
    pool_id: "134389055732f9fa6b7094bd815406f2a1bf7767731b49f42852e1c7",
    db_ticker: "3121",
    db_name: "Studio3121",
    db_url: "https://studio3121.com",
    roa: "0",
    roa_lifetime: "1.651",
    total_stake: "141495188584",
    stake_x_deleg: "10106799184.571",
    saturated: "0.0020582892380043",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardistack",
    icon: "https://cdn.adapools.org/pool_logo/f510658bb80e3657f5b20b3f796d219552b57622e0208dd287ba620f.png",
    pool_id: "f510658bb80e3657f5b20b3f796d219552b57622e0208dd287ba620f",
    db_ticker: "CSK",
    db_name: "cardistack",
    db_url: "https://cardistack.cryptoconnect.uk",
    roa: "3.333",
    roa_lifetime: "2.007",
    total_stake: "85063830074",
    stake_x_deleg: "12151975724.857",
    saturated: "0.0012373987252634",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAgogo1",
    icon: "https://cdn.adapools.org/pool_logo/a47f708038dc2bdf47795e24dac4b73904cbad4424bfce75856260cf.png",
    pool_id: "a47f708038dc2bdf47795e24dac4b73904cbad4424bfce75856260cf",
    db_ticker: "AGG1",
    db_name: "ADAgogo1",
    db_url: "https://www.adagogo1.com",
    roa: "2.954",
    roa_lifetime: "0.888",
    total_stake: "123062207128",
    stake_x_deleg: "12306220712.8",
    saturated: "0.001790150033167",
    luck_lifetime: "41",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BICEP",
    icon: "https://cdn.adapools.org/pool_logo/bdc9e0311857f72fb9071689d46bc4913e307085ba0ce25d018532ff.png",
    pool_id: "bdc9e0311857f72fb9071689d46bc4913e307085ba0ce25d018532ff",
    db_ticker: "BICEP",
    db_name: "BICEP",
    db_url: "http://www.biceppool.com",
    roa: "4.983",
    roa_lifetime: "3.093",
    total_stake: "339265271270",
    stake_x_deleg: "3609205013.5106",
    saturated: "0.0049351929466428",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "140",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEND pool",
    icon: "0",
    pool_id: "664730a4d85918a7d8574df549135228508072916d95cbc0ecd9ac67",
    db_ticker: "LEND",
    db_name: "LEND pool",
    db_url: "https://www.lendpool.org/",
    roa: "3.261",
    roa_lifetime: "3.541",
    total_stake: "217823682149",
    stake_x_deleg: "5186278146.4048",
    saturated: "0.0031686175709331",
    luck_lifetime: "123",
    blocks_epoch: "0",
    blocks_lifetime: "19",
    tax_ratio: "0.045",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPACE: Infinite Rewards",
    icon: "https://cdn.adapools.org/pool_logo/69ac9f5a2074c2809809264e42c996f7d991c5363b41ec55a1164442.png",
    pool_id: "69ac9f5a2074c2809809264e42c996f7d991c5363b41ec55a1164442",
    db_ticker: "SP₳CE",
    db_name: "SPACE: Infinite Rewards",
    db_url: "https://outerspace.money",
    roa: "1.886",
    roa_lifetime: "4.952",
    total_stake: "3761991181094",
    stake_x_deleg: "12294088827.105",
    saturated: "0.054724588440094",
    luck_lifetime: "99",
    blocks_epoch: "2",
    blocks_lifetime: "1537",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SnowPunkz",
    icon: "0",
    pool_id: "6beb0ed994e6d58390618ff659e278e051ff27c273fe2513828c8628",
    db_ticker: "SNOWP",
    db_name: "SnowPunkz",
    db_url: "https://snowpunkz.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66534407100",
    stake_x_deleg: "1073135598.3871",
    saturated: "0.00096785661379311",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "staca.co",
    icon: "https://cdn.adapools.org/pool_logo/6f31ed70d9969a5f22486b8c021ffd9aac569bb4351a040e4e9e1249.png",
    pool_id: "6f31ed70d9969a5f22486b8c021ffd9aac569bb4351a040e4e9e1249",
    db_ticker: "STACA",
    db_name: "staca.co",
    db_url: "https://staca.co",
    roa: "0",
    roa_lifetime: "4.181",
    total_stake: "187508524928",
    stake_x_deleg: "2640965139.831",
    saturated: "0.0027276318209523",
    luck_lifetime: "135",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1740000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAHEADZ Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/dd8b689ca625202688987a42b26b49e1623bba7d1c54f5308f6f9c5e.png",
    pool_id: "dd8b689ca625202688987a42b26b49e1623bba7d1c54f5308f6f9c5e",
    db_ticker: "HEADZ",
    db_name: "ADAHEADZ Stake Pool",
    db_url: "https://adaheadz.io/",
    roa: "0",
    roa_lifetime: "0.903",
    total_stake: "55705329527",
    stake_x_deleg: "1465929724.3947",
    saturated: "0.0008103291808883",
    luck_lifetime: "36",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swiss Premium Cardano Pool",
    icon: "https://cdn.adapools.org/pool_logo/112bd1da11c5c23e1171505fbbb81c8802d410de9e64373f530723c1.png",
    pool_id: "112bd1da11c5c23e1171505fbbb81c8802d410de9e64373f530723c1",
    db_ticker: "SPCP",
    db_name: "Swiss Premium Cardano Pool",
    db_url: "https://informatik-spezialist.ch",
    roa: "0",
    roa_lifetime: "2.009",
    total_stake: "12720662618",
    stake_x_deleg: "706703478.77778",
    saturated: "0.00018504376883013",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LIGHT",
    icon: "https://cdn.adapools.org/pool_logo/0e212a8bebee0026cb2980204241168dec3e40cd0d7845bdb9fe4737.png",
    pool_id: "0e212a8bebee0026cb2980204241168dec3e40cd0d7845bdb9fe4737",
    db_ticker: "LIGHT",
    db_name: "LIGHT",
    db_url: "https://twitter.com/LightStakePool",
    roa: "3.246",
    roa_lifetime: "4.11",
    total_stake: "3025809886251",
    stake_x_deleg: "756452471562.75",
    saturated: "0.044015573868225",
    luck_lifetime: "107",
    blocks_epoch: "3",
    blocks_lifetime: "270",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1Mate",
    icon: "https://cdn.adapools.org/pool_logo/19ffb80dbc1e627a5c5aafadf50257210d66ff35ef2961cb10065b53.png",
    pool_id: "19ffb80dbc1e627a5c5aafadf50257210d66ff35ef2961cb10065b53",
    db_ticker: "1Mate",
    db_name: "1Mate",
    db_url: "https://1mate.io",
    roa: "2.019",
    roa_lifetime: "2.045",
    total_stake: "536632993064",
    stake_x_deleg: "1412192087.0105",
    saturated: "0.0078062436287433",
    luck_lifetime: "83",
    blocks_epoch: "1",
    blocks_lifetime: "9",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "45000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adazul Pool",
    icon: "https://cdn.adapools.org/pool_logo/d0507160a231932b401c64a1afc8dc21e682993e2e6c0cdba129fa77.png",
    pool_id: "d0507160a231932b401c64a1afc8dc21e682993e2e6c0cdba129fa77",
    db_ticker: "ADABR",
    db_name: "Adazul Pool",
    db_url: "https://adazulpool.com",
    roa: "2.058",
    roa_lifetime: "4.081",
    total_stake: "526837933112",
    stake_x_deleg: "1211121685.3149",
    saturated: "0.0076637577485761",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "162",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stupid Cotton",
    icon: "https://cdn.adapools.org/pool_logo/5e1c0f2519b2ea005de5c418568a3323d7a2dddb2dc1e6a39d88cd02.png",
    pool_id: "5e1c0f2519b2ea005de5c418568a3323d7a2dddb2dc1e6a39d88cd02",
    db_ticker: "STUPD",
    db_name: "Stupid Cotton",
    db_url: "https://www.stupidcotton.com/stake-pool",
    roa: "0",
    roa_lifetime: "2.454",
    total_stake: "103496669162",
    stake_x_deleg: "5447193113.7895",
    saturated: "0.0015055358591149",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Digital Ark Pool",
    icon: "https://cdn.adapools.org/pool_logo/bb788f2b96ce42d300c27cf2e3e633b829825ad388476215c818a8b4.png",
    pool_id: "bb788f2b96ce42d300c27cf2e3e633b829825ad388476215c818a8b4",
    db_ticker: "DAP",
    db_name: "Digital Ark Pool",
    db_url: "https://digitalarkpool.com",
    roa: "0",
    roa_lifetime: "4.968",
    total_stake: "52803539437",
    stake_x_deleg: "2779133654.5789",
    saturated: "0.00076811768682291",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "235",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CashPool",
    icon: "https://cdn.adapools.org/pool_logo/fed2de9b6534835ccdd910c89282aaf244ea0b04b55e0cd0086c6271.png",
    pool_id: "fed2de9b6534835ccdd910c89282aaf244ea0b04b55e0cd0086c6271",
    db_ticker: "CASH",
    db_name: "CashPool",
    db_url: "https://adacashpool.com",
    roa: "5.822",
    roa_lifetime: "2.79",
    total_stake: "486777957986",
    stake_x_deleg: "2222730401.7626",
    saturated: "0.0070810169748316",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "27",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MIYABI KYOTO",
    icon: "https://cdn.adapools.org/pool_logo/de1d84fcbacbbc51ee5511b371c621a5335ce94d5d5d720d4c066107.png",
    pool_id: "de1d84fcbacbbc51ee5511b371c621a5335ce94d5d5d720d4c066107",
    db_ticker: "MYB",
    db_name: "MIYABI KYOTO",
    db_url: "https://miyabi-kyoto.org/",
    roa: "5.264",
    roa_lifetime: "4.068",
    total_stake: "4079992108013",
    stake_x_deleg: "14216000376.352",
    saturated: "0.059350455171698",
    luck_lifetime: "104",
    blocks_epoch: "3",
    blocks_lifetime: "273",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pixel Pool",
    icon: "https://cdn.adapools.org/pool_logo/4f3410f074e7363091a1cc1c21b128ae423d1cce897bd19478e534bb.png",
    pool_id: "4f3410f074e7363091a1cc1c21b128ae423d1cce897bd19478e534bb",
    db_ticker: "PIXEL",
    db_name: "Pixel Pool",
    db_url: "https://pixelpool.io/",
    roa: "0",
    roa_lifetime: "2.998",
    total_stake: "37350733875",
    stake_x_deleg: "1287956340.5172",
    saturated: "0.00054333023147876",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lucy_Pool",
    icon: "https://cdn.adapools.org/pool_logo/79219df8a6919aa5443f301d07a6f9eb9be38a6388215baaf55291b3.png",
    pool_id: "79219df8a6919aa5443f301d07a6f9eb9be38a6388215baaf55291b3",
    db_ticker: "LUCY",
    db_name: "Lucy_Pool",
    db_url: "https://www.lucy-pool.com",
    roa: "1.862",
    roa_lifetime: "2.88",
    total_stake: "384773730546",
    stake_x_deleg: "25651582036.4",
    saturated: "0.0055971912301417",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "38",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cargino Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/0b613140fd4afd748226c6d88fad6566e7874190716940a8b31f4e8a.png",
    pool_id: "0b613140fd4afd748226c6d88fad6566e7874190716940a8b31f4e8a",
    db_ticker: "GINO",
    db_name: "Cargino Stake Pool",
    db_url: "https://cardino-ada.netlify.app",
    roa: "3.13",
    roa_lifetime: "4.49",
    total_stake: "746640373583",
    stake_x_deleg: "4392002197.5471",
    saturated: "0.010861159739669",
    luck_lifetime: "99",
    blocks_epoch: "1",
    blocks_lifetime: "825",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Spanish Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/20a299827005c01b614b902756d9d03ce633f696c428f478ef9ddeeb.png",
    pool_id: "20a299827005c01b614b902756d9d03ce633f696c428f478ef9ddeeb",
    db_ticker: "ADAES",
    db_name: "Spanish Stake Pool",
    db_url: "https://cardanopool.es",
    roa: "0",
    roa_lifetime: "3.23",
    total_stake: "200472971118",
    stake_x_deleg: "2088260115.8125",
    saturated: "0.0029162218382992",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "52",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TERA #1",
    icon: "https://cdn.adapools.org/pool_logo/7113de447fb62c38e3e3aeec434cac6fc76e05e997b84227e9c637b8.png",
    pool_id: "7113de447fb62c38e3e3aeec434cac6fc76e05e997b84227e9c637b8",
    db_ticker: "TERA",
    db_name: "TERA #1",
    db_url: "https://git.io/JItdr",
    roa: "4.364",
    roa_lifetime: "4.526",
    total_stake: "28815675486195",
    stake_x_deleg: "19804587963.021",
    saturated: "0.41917322654296",
    luck_lifetime: "99",
    blocks_epoch: "20",
    blocks_lifetime: "3314",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TERA #2",
    icon: "https://cdn.adapools.org/pool_logo/50e113d1ada46a83a16d5ee6cfdc1a8baed6634c9f046ed30131eb11.png",
    pool_id: "50e113d1ada46a83a16d5ee6cfdc1a8baed6634c9f046ed30131eb11",
    db_ticker: "TERA2",
    db_name: "TERA #2",
    db_url: "https://git.io/JItdr",
    roa: "4.053",
    roa_lifetime: "4.428",
    total_stake: "31964538489708",
    stake_x_deleg: "19878444334.396",
    saturated: "0.46497881821672",
    luck_lifetime: "100",
    blocks_epoch: "31",
    blocks_lifetime: "3402",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Medical Pool",
    icon: "https://cdn.adapools.org/pool_logo/a2a8d3800e2e244b62c0b15a8168db3372cde8e2240222bfb8a1c356.png",
    pool_id: "a2a8d3800e2e244b62c0b15a8168db3372cde8e2240222bfb8a1c356",
    db_ticker: "MED",
    db_name: "Medical Pool",
    db_url: "https://medicalstakepool.com",
    roa: "1.538",
    roa_lifetime: "3.101",
    total_stake: "282186150918",
    stake_x_deleg: "7235542331.2308",
    saturated: "0.004104879631321",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "86",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RaspberryPool",
    icon: "0",
    pool_id: "fb2ef0b6933e23890ae8ca4a4d3137f61991aab76b33a2426e0f6aa2",
    db_ticker: "RAOOL",
    db_name: "RaspberryPool",
    db_url: "https://www.raspberry-stakepool.fr",
    roa: "0",
    roa_lifetime: "4.639",
    total_stake: "60761386275",
    stake_x_deleg: "7595173284.375",
    saturated: "0.00088387816368618",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "339",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CNFT Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/68cc6dc773c067328a43e5e21086b515d23f8326c59035245996f34b.png",
    pool_id: "68cc6dc773c067328a43e5e21086b515d23f8326c59035245996f34b",
    db_ticker: "CNFT",
    db_name: "CNFT Stake Pool",
    db_url: "https://cnftpool.com",
    roa: "3.155",
    roa_lifetime: "2.936",
    total_stake: "96503230440",
    stake_x_deleg: "1507862975.625",
    saturated: "0.0014038043458233",
    luck_lifetime: "141",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MEAPOOL",
    icon: "0",
    pool_id: "a73897c05473ec1a3ddb4fcdbf9d29988e77d5ea9b254ae6afcfbe73",
    db_ticker: "MEA",
    db_name: "MEAPOOL",
    db_url: "https://www.meapool.com",
    roa: "0",
    roa_lifetime: "1.557",
    total_stake: "199869672647",
    stake_x_deleg: "7138202594.5357",
    saturated: "0.0029074458314075",
    luck_lifetime: "41",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20246000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ROOT Stake Pool",
    icon: "0",
    pool_id: "756d13b231bedef4d4e7fe498278d51fbd8d091d329d17baeb1d935e",
    db_ticker: "ROOT",
    db_name: "ROOT Stake Pool",
    db_url: "http://rootstakepool.eu/",
    roa: "2.048",
    roa_lifetime: "3.669",
    total_stake: "178570446118",
    stake_x_deleg: "13736188162.923",
    saturated: "0.0025976121954996",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "27000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stakepool Germany",
    icon: "https://cdn.adapools.org/pool_logo/e8cd531f3899b95d18614c8e7bbb6aa48a8c10ef3be026274aebfacb.png",
    pool_id: "e8cd531f3899b95d18614c8e7bbb6aa48a8c10ef3be026274aebfacb",
    db_ticker: "SPG01",
    db_name: "Stakepool Germany",
    db_url: "https://stakepool-germany.de/",
    roa: "3.333",
    roa_lifetime: "1.583",
    total_stake: "68871145803",
    stake_x_deleg: "1765926815.4615",
    saturated: "0.001001848470142",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "9a22c008779c455a124d86191047d35690a0ca2c094a34d90cd29542",
    db_ticker: "EVE2",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.497",
    roa_lifetime: "4.13",
    total_stake: "46006993120174",
    stake_x_deleg: "1593481335.556",
    saturated: "0.66925030992114",
    luck_lifetime: "94",
    blocks_epoch: "34",
    blocks_lifetime: "3761",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "446109cdad3e7dfc6e0bb4be5ecf25df02fcc2ec8f6df46a3ca2c537",
    db_ticker: "VRSTK",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.439",
    roa_lifetime: "4.326",
    total_stake: "49673656147519",
    stake_x_deleg: "2340447424.9679",
    saturated: "0.7225881875133",
    luck_lifetime: "97",
    blocks_epoch: "40",
    blocks_lifetime: "4565",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "2382bac66b58a4df1cf1775285529bbbefb9102ecea991e1da0aa064",
    db_ticker: "EVE4",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.801",
    roa_lifetime: "4.115",
    total_stake: "42104224973125",
    stake_x_deleg: "1326618721.1899",
    saturated: "0.61247788001814",
    luck_lifetime: "95",
    blocks_epoch: "39",
    blocks_lifetime: "3335",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "120ccc012f6b2ef1669acb15d2499cc9a226b6374184d1e322b1aa5f",
    db_ticker: "RSTK",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.722",
    roa_lifetime: "4.345",
    total_stake: "41367449792867",
    stake_x_deleg: "1547545912.7181",
    saturated: "0.60176022636836",
    luck_lifetime: "98",
    blocks_epoch: "41",
    blocks_lifetime: "4064",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "36e0db9278d5b481dad2f2f84c42d20c324b046aaff97ff45fa4e134",
    db_ticker: "EVE3",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.573",
    roa_lifetime: "4.185",
    total_stake: "45088148616751",
    stake_x_deleg: "1844322355.1663",
    saturated: "0.65588414693199",
    luck_lifetime: "96",
    blocks_epoch: "36",
    blocks_lifetime: "3797",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "9417e18efcc4c258df21c89942009a498a751a0a63f8d72714cf75b2",
    db_ticker: "ESTK",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "4.099",
    roa_lifetime: "4.426",
    total_stake: "47416264165576",
    stake_x_deleg: "2374731515.279",
    saturated: "0.68975056477228",
    luck_lifetime: "98",
    blocks_epoch: "37",
    blocks_lifetime: "4462",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "24cc639f00790ec41c830464376b21df8602f4e7a1591ac005f8a44f",
    db_ticker: "EVE",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.86",
    roa_lifetime: "4.216",
    total_stake: "43807143001568",
    stake_x_deleg: "1869623276.9224",
    saturated: "0.63724973188268",
    luck_lifetime: "94",
    blocks_epoch: "33",
    blocks_lifetime: "4159",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RISC-V powered Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/277b4daf3e85094412cb9471891ac0495a158794518bc82b38dca607.png",
    pool_id: "277b4daf3e85094412cb9471891ac0495a158794518bc82b38dca607",
    db_ticker: "RV64",
    db_name: "RISC-V powered Stake Pool",
    db_url: "https://riscv-pool.tech/",
    roa: "0",
    roa_lifetime: "3.068",
    total_stake: "55602227062",
    stake_x_deleg: "6950278382.75",
    saturated: "0.00080882937940215",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "31000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "57eb48cdf25980039f087207af09fafb4970018e322d37c11c9a2496",
    db_ticker: "EVERS",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.758",
    roa_lifetime: "4.293",
    total_stake: "42273434031185",
    stake_x_deleg: "2189425835.4664",
    saturated: "0.61493931483203",
    luck_lifetime: "94",
    blocks_epoch: "35",
    blocks_lifetime: "4522",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "e4abcf4408584601e7c707a8902996c0c291e1a3c8300b327ae3f6ab",
    db_ticker: "EVE1",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "3.313",
    roa_lifetime: "3.655",
    total_stake: "41857957759159",
    stake_x_deleg: "1556810271.1035",
    saturated: "0.60889550268608",
    luck_lifetime: "92",
    blocks_epoch: "31",
    blocks_lifetime: "4577",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake Green",
    icon: "https://cdn.adapools.org/pool_logo/1ae6ca324f47cdc2b95bb7ca15e59e56666f5112136d83f069bf583a.png",
    pool_id: "1ae6ca324f47cdc2b95bb7ca15e59e56666f5112136d83f069bf583a",
    db_ticker: "STKGR",
    db_name: "Stake Green",
    db_url: "https://www.stakegreen.com",
    roa: "2.767",
    roa_lifetime: "9.61",
    total_stake: "35233360909",
    stake_x_deleg: "1531885256.913",
    saturated: "0.0005125294245229",
    luck_lifetime: "559",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "12500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "ac2d2d66a30cbb3163e68a7073bcd3f9cdd4a11a8af6e2c5653402c7",
    db_ticker: "EVRST",
    db_name: "Everstake",
    db_url: "https://everstake.one",
    roa: "4.063",
    roa_lifetime: "4.315",
    total_stake: "48967326482158",
    stake_x_deleg: "3215399992.262",
    saturated: "0.71231341588859",
    luck_lifetime: "95",
    blocks_epoch: "33",
    blocks_lifetime: "4718",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Papertopia Stake Pool",
    icon: "0",
    pool_id: "97a01b8ee302ff28aad1efef39cc310a4c07cf5b767f0292134f5d4d",
    db_ticker: "PAPER",
    db_name: "Papertopia Stake Pool",
    db_url: "https://papertopia.io",
    roa: "0",
    roa_lifetime: "3.85",
    total_stake: "141598674756",
    stake_x_deleg: "2052154706.6087",
    saturated: "0.0020597946211643",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "100",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GameOn Adalove",
    icon: "https://cdn.adapools.org/pool_logo/6caf9e779b5e86799af7fcaa35507e14c297db873bf2a2aba3566491.png",
    pool_id: "6caf9e779b5e86799af7fcaa35507e14c297db873bf2a2aba3566491",
    db_ticker: "GmeOn",
    db_name: "GameOn Adalove",
    db_url: "https://www.gameon.ch",
    roa: "3.333",
    roa_lifetime: "6.437",
    total_stake: "58824321287",
    stake_x_deleg: "9804053547.8333",
    saturated: "0.00085570024429531",
    luck_lifetime: "299",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40444000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "timsahabutdinov",
    icon: "https://cdn.adapools.org/pool_logo/8be06c73a6ba898ae2f0d1f1594321ab3c24dd0d0717bd3e686b1658.png",
    pool_id: "8be06c73a6ba898ae2f0d1f1594321ab3c24dd0d0717bd3e686b1658",
    db_ticker: "TIM",
    db_name: "timsahabutdinov",
    db_url: "https://timsahabutdinov.com",
    roa: "0",
    roa_lifetime: "4.7",
    total_stake: "31938783108",
    stake_x_deleg: "778994709.95122",
    saturated: "0.00046460416219117",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "346",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "600000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dyno Pool",
    icon: "https://cdn.adapools.org/pool_logo/4ebf3e184e2bf7b6c4a5998126088432369ade380fa5a08690f1b618.png",
    pool_id: "4ebf3e184e2bf7b6c4a5998126088432369ade380fa5a08690f1b618",
    db_ticker: "DYNO",
    db_name: "Dyno Pool",
    db_url: "https://dynopool.com",
    roa: "10.879",
    roa_lifetime: "9.76",
    total_stake: "179102365235",
    stake_x_deleg: "7164094609.4",
    saturated: "0.0026053498677481",
    luck_lifetime: "285",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Behemoth",
    icon: "https://cdn.adapools.org/pool_logo/052f4ae6c6f39c00a45e390ed0849fd719e1848c60cf3ba6b985635e.png",
    pool_id: "052f4ae6c6f39c00a45e390ed0849fd719e1848c60cf3ba6b985635e",
    db_ticker: "BHMT",
    db_name: "Behemoth",
    db_url: "https://www.behemothpool.com",
    roa: "0",
    roa_lifetime: "1.851",
    total_stake: "16706128763",
    stake_x_deleg: "451516993.59459",
    saturated: "0.00024301918238855",
    luck_lifetime: "56",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTO_KR",
    icon: "0",
    pool_id: "48884756b7647792989da5313bc13358cc5f304acb045f1aa831cee7",
    db_ticker: "KTOP",
    db_name: "KTO_KR",
    db_url: "https://ktopool.github.io",
    roa: "2.069",
    roa_lifetime: "3.299",
    total_stake: "1522385793278",
    stake_x_deleg: "31716370693.292",
    saturated: "0.022145702095973",
    luck_lifetime: "106",
    blocks_epoch: "2",
    blocks_lifetime: "85",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ShareTheLovelace",
    icon: "https://cdn.adapools.org/pool_logo/033c7a00ae33e9a0fa6802b388ccfa5ca9633d712231c41c468c2d39.png",
    pool_id: "033c7a00ae33e9a0fa6802b388ccfa5ca9633d712231c41c468c2d39",
    db_ticker: "STLL",
    db_name: "ShareTheLovelace",
    db_url: "https://sharethelovelace.xyz",
    roa: "0",
    roa_lifetime: "3.46",
    total_stake: "195390318794",
    stake_x_deleg: "16282526566.167",
    saturated: "0.0028422859774145",
    luck_lifetime: "122",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VELA Pool",
    icon: "https://cdn.adapools.org/pool_logo/de16904b33d013f4db5460957d9c50cbfbacc50a396f878d0c1effef.png",
    pool_id: "de16904b33d013f4db5460957d9c50cbfbacc50a396f878d0c1effef",
    db_ticker: "VELA",
    db_name: "VELA Pool",
    db_url: "https://adacardano.cl/",
    roa: "0",
    roa_lifetime: "1.338",
    total_stake: "41011897902",
    stake_x_deleg: "2734126526.8",
    saturated: "0.00059658811671681",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "18000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alice Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/1c220012e987c342ec4b4c6cea04501d0cf003459804b0e7018d3c73.png",
    pool_id: "1c220012e987c342ec4b4c6cea04501d0cf003459804b0e7018d3c73",
    db_ticker: "ALICE",
    db_name: "Alice Stake Pool",
    db_url: "https://skilov3.github.io/AliceStakePool/",
    roa: "0",
    roa_lifetime: "2.7",
    total_stake: "28177185651",
    stake_x_deleg: "2012656117.9286",
    saturated: "0.00040988530114063",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "7350000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Thailand Pool ",
    icon: "https://cdn.adapools.org/pool_logo/8952900d84070b044336b443c2afd2318fe53b54a3219879b5056063.png",
    pool_id: "8952900d84070b044336b443c2afd2318fe53b54a3219879b5056063",
    db_ticker: "ADATH",
    db_name: "ADA Thailand Pool ",
    db_url: "https://www.ada-thailand.com",
    roa: "1.596",
    roa_lifetime: "2.7",
    total_stake: "219766542386",
    stake_x_deleg: "947269579.25",
    saturated: "0.0031968797921209",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCATALYST",
    icon: "https://cdn.adapools.org/pool_logo/4a4e69f422ffe7f75e22cb05658372351b702b8630ab9a9a7c3f8e60.png",
    pool_id: "4a4e69f422ffe7f75e22cb05658372351b702b8630ab9a9a7c3f8e60",
    db_ticker: "CCYT",
    db_name: "CCATALYST",
    db_url: "https://crypto-catalyst.com/",
    roa: "0",
    roa_lifetime: "1.052",
    total_stake: "43069338817",
    stake_x_deleg: "1794555784.0417",
    saturated: "0.00062651710960734",
    luck_lifetime: "61",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The FreeBSD Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/5808e8716e8b968c3043fc9bdc21db652c916ce97ffaddfe891db16c.png",
    pool_id: "5808e8716e8b968c3043fc9bdc21db652c916ce97ffaddfe891db16c",
    db_ticker: "FBSD",
    db_name: "The FreeBSD Stake Pool",
    db_url: "",
    roa: "0",
    roa_lifetime: "1.564",
    total_stake: "99683733013",
    stake_x_deleg: "24920933253.25",
    saturated: "0.001450070189086",
    luck_lifetime: "68",
    blocks_epoch: "1",
    blocks_lifetime: "7",
    tax_ratio: "0.0314",
    tax_fix: "340000000",
    pledge: "4997000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TERA #3",
    icon: "https://cdn.adapools.org/pool_logo/a95b4f891edea1c877954d48968968b2c91a80786858d89110caf7f9.png",
    pool_id: "a95b4f891edea1c877954d48968968b2c91a80786858d89110caf7f9",
    db_ticker: "TERA3",
    db_name: "TERA #3",
    db_url: "https://git.io/JItdr",
    roa: "4.191",
    roa_lifetime: "4.021",
    total_stake: "34059250832368",
    stake_x_deleg: "30906761190.897",
    saturated: "0.49544998769435",
    luck_lifetime: "97",
    blocks_epoch: "26",
    blocks_lifetime: "1394",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XMAL - Retiring Maladex's Pool.",
    icon: "https://cdn.adapools.org/pool_logo/a89a0033d1494dd28f2508b1584988d93097b775163f28d45c0c9f15.png",
    pool_id: "a89a0033d1494dd28f2508b1584988d93097b775163f28d45c0c9f15",
    db_ticker: "XMAL",
    db_name: "XMAL - Retiring Maladex's Pool.",
    db_url: "https://maladex.com",
    roa: "3.538",
    roa_lifetime: "0.245",
    total_stake: "3181803582352",
    stake_x_deleg: "2954320874.9786",
    saturated: "0.046284768666256",
    luck_lifetime: "96",
    blocks_epoch: "2",
    blocks_lifetime: "1300",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "46 South Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/12d4d2f7a7c5b4ae10853d093257d71bd3ac6d2abccc2b65fc0bff8f.png",
    pool_id: "12d4d2f7a7c5b4ae10853d093257d71bd3ac6d2abccc2b65fc0bff8f",
    db_ticker: "46S",
    db_name: "46 South Stake Pool",
    db_url: "https://46s.au.ngrok.io",
    roa: "0",
    roa_lifetime: "3.644",
    total_stake: "51653085842",
    stake_x_deleg: "2718583465.3684",
    saturated: "0.00075138237393271",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "82",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "17000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HOPE2",
    icon: "0",
    pool_id: "31cb0848348e7025dfab4acaef57d52d2879c051082929be9ad46a14",
    db_ticker: "HOPE2",
    db_name: "HOPE2",
    db_url: "https://cardano.ipclub29.com/",
    roa: "1.903",
    roa_lifetime: "4.058",
    total_stake: "76477722180",
    stake_x_deleg: "2637162833.7931",
    saturated: "0.0011124991180653",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "316",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PINOY ADA",
    icon: "https://cdn.adapools.org/pool_logo/c44e34fbdef89ff767823d3465e5625054b481f5327f983ce53c4abc.png",
    pool_id: "c44e34fbdef89ff767823d3465e5625054b481f5327f983ce53c4abc",
    db_ticker: "PINOY",
    db_name: "PINOY ADA",
    db_url: "https://twitter.com/AdaPinoy",
    roa: "3.333",
    roa_lifetime: "2.573",
    total_stake: "54806071133",
    stake_x_deleg: "3425379445.8125",
    saturated: "0.00079724793132018",
    luck_lifetime: "72",
    blocks_epoch: "1",
    blocks_lifetime: "31",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HANABI stake pool",
    icon: "0",
    pool_id: "992fa96053b40ee67f36f0fc3fb105ba71748004670312f116d8f564",
    db_ticker: "HNB1",
    db_name: "HANABI stake pool",
    db_url: "https://hanabistakepool.mystrikingly.com",
    roa: "4.828",
    roa_lifetime: "3.429",
    total_stake: "153538995535",
    stake_x_deleg: "5686629464.2593",
    saturated: "0.0022334869848671",
    luck_lifetime: "154",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Dodo Pool",
    icon: "https://cdn.adapools.org/pool_logo/6858fc0ed225ad40157e358cff1d08ccc306b6ab00bdc96301fbedef.png",
    pool_id: "6858fc0ed225ad40157e358cff1d08ccc306b6ab00bdc96301fbedef",
    db_ticker: "DODO",
    db_name: "The Dodo Pool",
    db_url: "https://dodopool.io/",
    roa: "2.891",
    roa_lifetime: "2.609",
    total_stake: "121233301249",
    stake_x_deleg: "5271013097.7826",
    saturated: "0.0017635454727877",
    luck_lifetime: "129",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.03",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moai Stake Pool ",
    icon: "https://cdn.adapools.org/pool_logo/d44442e0f92b4173205c4e8609cd48cbbb09c7dbcb805704c22be1ff.png",
    pool_id: "d44442e0f92b4173205c4e8609cd48cbbb09c7dbcb805704c22be1ff",
    db_ticker: "MOAI",
    db_name: "Moai Stake Pool ",
    db_url: "https://moaipool.com/",
    roa: "0",
    roa_lifetime: "3.168",
    total_stake: "45573307158",
    stake_x_deleg: "2680782774",
    saturated: "0.00066294160672389",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "219",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "European ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/aa1c56d0973268bd5b4758181e96971f51cf1c9c20fdfd95022b4ad3.png",
    pool_id: "aa1c56d0973268bd5b4758181e96971f51cf1c9c20fdfd95022b4ad3",
    db_ticker: "EUADA",
    db_name: "European ADA Pool",
    db_url: "https://euadapool.com",
    roa: "0",
    roa_lifetime: "2.778",
    total_stake: "15742927068",
    stake_x_deleg: "684475089.91304",
    saturated: "0.00022900776827132",
    luck_lifetime: "137",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "8850000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Far Reaching Use of Information Technology",
    icon: "0",
    pool_id: "afc65ca69d52a68a1b1c50ee1b5b07d72890aaa3a2272d5d9391b706",
    db_ticker: "FRUIT",
    db_name: "Far Reaching Use of Information Technology",
    db_url: "https://phlovers.org/fruit",
    roa: "3.59",
    roa_lifetime: "4.306",
    total_stake: "184776235708",
    stake_x_deleg: "1986841244.172",
    saturated: "0.002687886006604",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "132",
    tax_ratio: "0.031",
    tax_fix: "340000000",
    pledge: "31000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "0 Percent Pool",
    icon: "https://cdn.adapools.org/pool_logo/f0e708ba4407cb2414d6138b739d2fb416dd1621deb8371b6ccc8088.png",
    pool_id: "f0e708ba4407cb2414d6138b739d2fb416dd1621deb8371b6ccc8088",
    db_ticker: "0PCT",
    db_name: "0 Percent Pool",
    db_url: "https://www.0pctpools.com",
    roa: "3.201",
    roa_lifetime: "4.577",
    total_stake: "5422617485389",
    stake_x_deleg: "21182099552.301",
    saturated: "0.078881234928806",
    luck_lifetime: "94",
    blocks_epoch: "2",
    blocks_lifetime: "2196",
    tax_ratio: "0",
    tax_fix: "840000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LKBH POOL",
    icon: "0",
    pool_id: "0ce16f30fdae49328160cb3d68e3fd109ca86b580f4f47882307f943",
    db_ticker: "LKBH",
    db_name: "LKBH POOL",
    db_url: "https://www.lkbh-pools.org/",
    roa: "4.153",
    roa_lifetime: "3.705",
    total_stake: "333063486251",
    stake_x_deleg: "27755290520.917",
    saturated: "0.0048449773888647",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "83",
    tax_ratio: "0.027",
    tax_fix: "340000000",
    pledge: "12900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Norb Pool",
    icon: "https://cdn.adapools.org/pool_logo/71b00c23502112d4c634e325f28c9ade2a4ba4ec251677d306ae6b7b.png",
    pool_id: "71b00c23502112d4c634e325f28c9ade2a4ba4ec251677d306ae6b7b",
    db_ticker: "NORB",
    db_name: "Norb Pool",
    db_url: "https://norbpool.club",
    roa: "3.333",
    roa_lifetime: "4.059",
    total_stake: "69985727096",
    stake_x_deleg: "6362338826.9091",
    saturated: "0.001018061959118",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "354",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CountessOfLovelace",
    icon: "0",
    pool_id: "1d1a0727f1cae44f801091fe2b80d5589f3ffed24c602a8df7cdb2a3",
    db_ticker: "ACOL",
    db_name: "CountessOfLovelace",
    db_url: "https://ada.CountessOfLovelace.io",
    roa: "0",
    roa_lifetime: "2.301",
    total_stake: "126171608190",
    stake_x_deleg: "7421859305.2941",
    saturated: "0.0018353815834876",
    luck_lifetime: "65",
    blocks_epoch: "0",
    blocks_lifetime: "61",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VALENCE",
    icon: "https://cdn.adapools.org/pool_logo/45db53a3789c6d03a447741cd526fbed788380077efc880521ba9dd9.png",
    pool_id: "45db53a3789c6d03a447741cd526fbed788380077efc880521ba9dd9",
    db_ticker: "VLNS",
    db_name: "VALENCE",
    db_url: "https://valencepool.com",
    roa: "2.302",
    roa_lifetime: "3.267",
    total_stake: "1817225512672",
    stake_x_deleg: "64900911166.857",
    saturated: "0.02643464949721",
    luck_lifetime: "89",
    blocks_epoch: "2",
    blocks_lifetime: "168",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Frontier Pool",
    icon: "https://cdn.adapools.org/pool_logo/ad9a5fae28836eca9e4575588c712e7accd608b1519d72b7a1b058ac.png",
    pool_id: "ad9a5fae28836eca9e4575588c712e7accd608b1519d72b7a1b058ac",
    db_ticker: "FRNTR",
    db_name: "Frontier Pool",
    db_url: "https://frontierpool.com",
    roa: "0",
    roa_lifetime: "4.057",
    total_stake: "40208155690",
    stake_x_deleg: "3092935053.0769",
    saturated: "0.00058489631318875",
    luck_lifetime: "157",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "16000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gibon Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/b352315bd54d61dfcd8fd78a2aa4f3e0cc8ef342b9495465b0b0b2ce.png",
    pool_id: "b352315bd54d61dfcd8fd78a2aa4f3e0cc8ef342b9495465b0b0b2ce",
    db_ticker: "GIBON",
    db_name: "Gibon Stake Pool",
    db_url: "https://gibon.online",
    roa: "0",
    roa_lifetime: "3.818",
    total_stake: "39301994217",
    stake_x_deleg: "1708782357.2609",
    saturated: "0.00057171464654386",
    luck_lifetime: "149",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fast-G-Pool",
    icon: "0",
    pool_id: "0725ac07ceaafa307ae3c361237d18dcf93da8cc9859b929ff2cc3fe",
    db_ticker: "FGPX",
    db_name: "Fast-G-Pool",
    db_url: "https://www.fast-g-pool.com",
    roa: "2.545",
    roa_lifetime: "1.289",
    total_stake: "143900312853",
    stake_x_deleg: "3128267670.7174",
    saturated: "0.0020932758792356",
    luck_lifetime: "45",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "60000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GIVE",
    icon: "https://cdn.adapools.org/pool_logo/9242098f44c84674782c9ba1e255c40be04a56281103b389b0ab59e8.png",
    pool_id: "9242098f44c84674782c9ba1e255c40be04a56281103b389b0ab59e8",
    db_ticker: "GIVE",
    db_name: "GIVE",
    db_url: "https://www.givepool.io",
    roa: "0",
    roa_lifetime: "1.909",
    total_stake: "63744586441",
    stake_x_deleg: "5794962403.7273",
    saturated: "0.00092727390638202",
    luck_lifetime: "89",
    blocks_epoch: "1",
    blocks_lifetime: "4",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BLACK Rocket",
    icon: "https://cdn.adapools.org/pool_logo/59b59f232e80f18ce64e0f74560effbf49a3e95ddf6d079681db8b23.png",
    pool_id: "59b59f232e80f18ce64e0f74560effbf49a3e95ddf6d079681db8b23",
    db_ticker: "BLACK",
    db_name: "BLACK Rocket",
    db_url: "https://blackrocket.space",
    roa: "0",
    roa_lifetime: "3.858",
    total_stake: "76182149222",
    stake_x_deleg: "3627721391.5238",
    saturated: "0.0011081995044559",
    luck_lifetime: "159",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Galaxy Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/74e1795dc06165e4a1b55d8930c00379b029c2a145ff225255154129.png",
    pool_id: "74e1795dc06165e4a1b55d8930c00379b029c2a145ff225255154129",
    db_ticker: "GXY",
    db_name: "Galaxy Stake Pool",
    db_url: "https://galaxy-stakepool.com/",
    roa: "1.496",
    roa_lifetime: "4.807",
    total_stake: "32923330007",
    stake_x_deleg: "1646166500.35",
    saturated: "0.00047892607876517",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "250",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pasta Pool",
    icon: "https://cdn.adapools.org/pool_logo/ad54e70ce213438e1f8e9a3fdc58438f28840f6934252ebe1eee53ad.png",
    pool_id: "ad54e70ce213438e1f8e9a3fdc58438f28840f6934252ebe1eee53ad",
    db_ticker: "PASTA",
    db_name: "Pasta Pool",
    db_url: "https://www.pasta-pool.com",
    roa: "0",
    roa_lifetime: "2.034",
    total_stake: "36478786408",
    stake_x_deleg: "3039898867.3333",
    saturated: "0.00053064626599985",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JOY2",
    icon: "0",
    pool_id: "e36c628f68125e717842a87c4b05a0835949a5ee3f1313b8706ce8d1",
    db_ticker: "JOY2",
    db_name: "JOY2",
    db_url: "https://cardano.ipclub29.com/",
    roa: "4.076",
    roa_lifetime: "4.812",
    total_stake: "4776486374777",
    stake_x_deleg: "37027026161.062",
    saturated: "0.069482154121737",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "1606",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "La Isla ADA Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/679b1d8a164791f21ab5a9a7fd2c8cfc108f76aad351912ec841847e.png",
    pool_id: "679b1d8a164791f21ab5a9a7fd2c8cfc108f76aad351912ec841847e",
    db_ticker: "ISLA",
    db_name: "La Isla ADA Stakepool",
    db_url: "https://0xisla.live",
    roa: "0",
    roa_lifetime: "2.672",
    total_stake: "21985327637",
    stake_x_deleg: "1221407090.9444",
    saturated: "0.00031981414860882",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/09d4cd832d9c3bf96551d86622f2205947c978a6e1e3b1e827596297.png",
    pool_id: "09d4cd832d9c3bf96551d86622f2205947c978a6e1e3b1e827596297",
    db_ticker: "RCADA",
    db_name: "Stake ADA Pool",
    db_url: "https://www.stakecardanopool.com",
    roa: "0",
    roa_lifetime: "5.001",
    total_stake: "42485497289",
    stake_x_deleg: "3862317935.3636",
    saturated: "0.00061802413719033",
    luck_lifetime: "330",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "16500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ayman - Cardano Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/97115807c7758d54b4af65d5f974143018cd5ad0922b8b0bd8d1d1dd.png",
    pool_id: "97115807c7758d54b4af65d5f974143018cd5ad0922b8b0bd8d1d1dd",
    db_ticker: "AYMAN",
    db_name: "Ayman - Cardano Stake Pool",
    db_url: "https://aymanstake.com",
    roa: "0",
    roa_lifetime: "3.943",
    total_stake: "39007916224",
    stake_x_deleg: "2786279730.2857",
    saturated: "0.00056743677975431",
    luck_lifetime: "123",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "koipond",
    icon: "https://cdn.adapools.org/pool_logo/63335621af9e092677e3d1859e981b4899606cc983a5ef93b9382080.png",
    pool_id: "63335621af9e092677e3d1859e981b4899606cc983a5ef93b9382080",
    db_ticker: "KOI",
    db_name: "koipond",
    db_url: "https://koipond.xyz",
    roa: "0",
    roa_lifetime: "3.952",
    total_stake: "7860510490",
    stake_x_deleg: "436695027.22222",
    saturated: "0.0001143445533993",
    luck_lifetime: "148",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADASRN",
    icon: "https://cdn.adapools.org/pool_logo/cc1b1c03798884c636703443a23b8d9e827d6c0417921600394198a0.png",
    pool_id: "cc1b1c03798884c636703443a23b8d9e827d6c0417921600394198a0",
    db_ticker: "SRN",
    db_name: "ADASRN",
    db_url: "https://www.adasrn.com",
    roa: "0",
    roa_lifetime: "3.579",
    total_stake: "21432053719",
    stake_x_deleg: "1428803581.2667",
    saturated: "0.00031176583429874",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "79",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool Germany (Deutsch + English)",
    icon: "https://cdn.adapools.org/pool_logo/0e8d16944b4e7f2061ce389ab2e06d5c1900404dba69a039888ab3f8.png",
    pool_id: "0e8d16944b4e7f2061ce389ab2e06d5c1900404dba69a039888ab3f8",
    db_ticker: "POOLG",
    db_name: "Pool Germany (Deutsch + English)",
    db_url: "https://poolg.de",
    roa: "0",
    roa_lifetime: "3.042",
    total_stake: "83422543562",
    stake_x_deleg: "5561502904.1333",
    saturated: "0.0012135234090923",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "34",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Logical Mechanism",
    icon: "https://cdn.adapools.org/pool_logo/07ac7dee6c82177096b70ccf21cfb8965c1fb08e079f9ca4af4b2b3e.png",
    pool_id: "07ac7dee6c82177096b70ccf21cfb8965c1fb08e079f9ca4af4b2b3e",
    db_ticker: "LOGIC",
    db_name: "Logical Mechanism",
    db_url: "https://www.logicalmechanism.io/",
    roa: "3.333",
    roa_lifetime: "0.951",
    total_stake: "59927867236",
    stake_x_deleg: "5447987930.5455",
    saturated: "0.00087175320534085",
    luck_lifetime: "46",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEXUS Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/2b5ac5073e3e8071024263a8fe69e4577bcf3bfed41110ced8ac8aa7.png",
    pool_id: "2b5ac5073e3e8071024263a8fe69e4577bcf3bfed41110ced8ac8aa7",
    db_ticker: "NEXUS",
    db_name: "NEXUS Stake Pool",
    db_url: "https://nexuspool.org",
    roa: "0",
    roa_lifetime: "1.509",
    total_stake: "15859307676",
    stake_x_deleg: "932900451.52941",
    saturated: "0.00023070072303081",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AVENE Pool",
    icon: "https://cdn.adapools.org/pool_logo/5e7ff9707b194d0a8abc37b35d6ca34112faaa777032e2268b2f8d07.png",
    pool_id: "5e7ff9707b194d0a8abc37b35d6ca34112faaa777032e2268b2f8d07",
    db_ticker: "AVENE",
    db_name: "AVENE Pool",
    db_url: "https://avenepool.com",
    roa: "3.333",
    roa_lifetime: "4.288",
    total_stake: "52764676120",
    stake_x_deleg: "7537810874.2857",
    saturated: "0.00076755235348589",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "250",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "9ad2692a4865c5999f27d65baf170be5ba38b25489c8e21007193edd",
    db_ticker: "RAY2",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "3.873",
    roa_lifetime: "3.967",
    total_stake: "51865026716919",
    stake_x_deleg: "22326744174.309",
    saturated: "0.75446541602272",
    luck_lifetime: "94",
    blocks_epoch: "50",
    blocks_lifetime: "1723",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adaimpulse",
    icon: "https://cdn.adapools.org/pool_logo/06be20d0da5810fae2f0376dc0a01a31d459c0ca6da8e3ddf58a67d9.png",
    pool_id: "06be20d0da5810fae2f0376dc0a01a31d459c0ca6da8e3ddf58a67d9",
    db_ticker: "ADAIM",
    db_name: "adaimpulse",
    db_url: "https://adaimpulse.club",
    roa: "0",
    roa_lifetime: "3.779",
    total_stake: "30111795729",
    stake_x_deleg: "1881987233.0625",
    saturated: "0.00043802750967176",
    luck_lifetime: "185",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Flan Pool",
    icon: "0",
    pool_id: "663994de529e370169cea87da4c5b5b997f8e1ba7ac718a92e60a998",
    db_ticker: "FLAN",
    db_name: "Flan Pool",
    db_url: "https://flantoshi.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "616751164556",
    stake_x_deleg: "9636736946.1875",
    saturated: "0.0089716993011294",
    luck_lifetime: "0",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Antrix",
    icon: "https://cdn.adapools.org/pool_logo/8b315cd9c1b128c5cfe70925a786f6db96cf220bbcea8a2a9c6afdb9.png",
    pool_id: "8b315cd9c1b128c5cfe70925a786f6db96cf220bbcea8a2a9c6afdb9",
    db_ticker: "ANTRX",
    db_name: "Antrix",
    db_url: "https://antrixy.github.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "22544040524",
    stake_x_deleg: "1502936034.9333",
    saturated: "0.00032794158201454",
    luck_lifetime: "0",
    blocks_epoch: "1",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cozy Pool",
    icon: "https://cdn.adapools.org/pool_logo/880597bb225f4df2f259f224320a07a6ddf33f132850d9d9a6844cce.png",
    pool_id: "880597bb225f4df2f259f224320a07a6ddf33f132850d9d9a6844cce",
    db_ticker: "COZY",
    db_name: "Cozy Pool",
    db_url: "https://cozytokens.io",
    roa: "0",
    roa_lifetime: "2.339",
    total_stake: "29549451771",
    stake_x_deleg: "1343156898.6818",
    saturated: "0.00042984725613528",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ITALIA2050",
    icon: "https://cdn.adapools.org/pool_logo/c8567f630284a3efe9dc7677acb5ebcf394cb69f90756fb79004cde3.png",
    pool_id: "c8567f630284a3efe9dc7677acb5ebcf394cb69f90756fb79004cde3",
    db_ticker: "ITA50",
    db_name: "ITALIA2050",
    db_url: "http://www.ita50pool.com",
    roa: "0",
    roa_lifetime: "2.534",
    total_stake: "18484810853",
    stake_x_deleg: "1232320723.5333",
    saturated: "0.00026889315195823",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Reaction",
    icon: "https://cdn.adapools.org/pool_logo/644621c954eda2f6df841f036365ad5af2b30af5c08b8c065f49b97d.png",
    pool_id: "644621c954eda2f6df841f036365ad5af2b30af5c08b8c065f49b97d",
    db_ticker: "XRX",
    db_name: "Reaction",
    db_url: "https://xreactx.github.io/",
    roa: "3.333",
    roa_lifetime: "4.093",
    total_stake: "27152503172",
    stake_x_deleg: "2088654090.1538",
    saturated: "0.00039497954399084",
    luck_lifetime: "166",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "lowIQ",
    icon: "https://cdn.adapools.org/pool_logo/a393cf2db41d6caf6476a028e5bf46a161631880929fa5b669da776f.png",
    pool_id: "a393cf2db41d6caf6476a028e5bf46a161631880929fa5b669da776f",
    db_ticker: "lowIQ",
    db_name: "lowIQ",
    db_url: "https://lowIQ.rocks/lowIQ.metadata.json",
    roa: "0",
    roa_lifetime: "1.763",
    total_stake: "35364516778",
    stake_x_deleg: "2357634451.8667",
    saturated: "0.00051443731069462",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "123000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The GAINS Collective",
    icon: "0",
    pool_id: "039a94f0e1106857118e448297db4d6d33faded2618f74432e31d1fa",
    db_ticker: "GAINS",
    db_name: "The GAINS Collective",
    db_url: "https://gainspool.com",
    roa: "2.97",
    roa_lifetime: "2.211",
    total_stake: "154060809490",
    stake_x_deleg: "30812161898",
    saturated: "0.0022410776602715",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SN₳KE",
    icon: "https://cdn.adapools.org/pool_logo/342350284fd76ba9dbd7fd4ed579b2a2058d5ee558f8872b37817b28.png",
    pool_id: "342350284fd76ba9dbd7fd4ed579b2a2058d5ee558f8872b37817b28",
    db_ticker: "SNAKE",
    db_name: "SN₳KE",
    db_url: "https://www.adasnakepool.com",
    roa: "3.333",
    roa_lifetime: "3.63",
    total_stake: "56294183668",
    stake_x_deleg: "2680675412.7619",
    saturated: "0.00081889507032457",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "248",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "26000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA 24/7",
    icon: "https://cdn.adapools.org/pool_logo/732edacd3adf46a33c5dcd94bcebe0b0f16b17665cb0bc8887f1fb11.png",
    pool_id: "732edacd3adf46a33c5dcd94bcebe0b0f16b17665cb0bc8887f1fb11",
    db_ticker: "AD247",
    db_name: "ADA 24/7",
    db_url: "https://www.ada247.net/",
    roa: "1.796",
    roa_lifetime: "3.205",
    total_stake: "214145951003",
    stake_x_deleg: "16472765461.769",
    saturated: "0.0031151186886472",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "19",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Noble Stake Pool",
    icon: "0",
    pool_id: "45d3bedbd9e557a4fb213cad00ae87d7cea38a9534cc977a2a21bf34",
    db_ticker: "NOBLE",
    db_name: "Noble Stake Pool",
    db_url: "https://stakenoble.com",
    roa: "0",
    roa_lifetime: "0.754",
    total_stake: "117077673233",
    stake_x_deleg: "13008630359.222",
    saturated: "0.0017030947641234",
    luck_lifetime: "34",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nova Era Pool",
    icon: "https://cdn.adapools.org/pool_logo/13375a4a5470b564246a3251ea0ccfef046ee5bcaf3ed6de6315abc7.png",
    pool_id: "13375a4a5470b564246a3251ea0ccfef046ee5bcaf3ed6de6315abc7",
    db_ticker: "ERA",
    db_name: "Nova Era Pool",
    db_url: "https://www.cpoolsunited.com/pool?id=ERA",
    roa: "0",
    roa_lifetime: "1.077",
    total_stake: "80012089669",
    stake_x_deleg: "3810099508.0476",
    saturated: "0.0011639125310482",
    luck_lifetime: "46",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DropCNFT",
    icon: "https://cdn.adapools.org/pool_logo/881c0f317de70d04f64eb3f2dab21bbb216cd69ca30fa2bd4bbca139.png",
    pool_id: "881c0f317de70d04f64eb3f2dab21bbb216cd69ca30fa2bd4bbca139",
    db_ticker: "DCNFT",
    db_name: "DropCNFT",
    db_url: "https://dropcnft.com/pool",
    roa: "0",
    roa_lifetime: "6.812",
    total_stake: "31278994532",
    stake_x_deleg: "2406076502.4615",
    saturated: "0.00045500641021861",
    luck_lifetime: "353",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FLOWR Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/58475ab22132ee593d295574d64064307b3338d4525eaaba11ce193f.png",
    pool_id: "58475ab22132ee593d295574d64064307b3338d4525eaaba11ce193f",
    db_ticker: "FLOWR",
    db_name: "FLOWR Stake Pool",
    db_url: "https://flowrpool.com",
    roa: "0",
    roa_lifetime: "4.48",
    total_stake: "3749263625",
    stake_x_deleg: "288404894.23077",
    saturated: "0.000054539444393879",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "246",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ACID Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/af6254651bde1e60fb9160d5f321010de5b2fa794e9b1a1163e592e0.png",
    pool_id: "af6254651bde1e60fb9160d5f321010de5b2fa794e9b1a1163e592e0",
    db_ticker: "ACID",
    db_name: "ACID Stake Pool",
    db_url: "https://acidpools.com",
    roa: "3.333",
    roa_lifetime: "0.397",
    total_stake: "53964489195",
    stake_x_deleg: "4905862654.0909",
    saturated: "0.00078500568433483",
    luck_lifetime: "19",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Southern California Stake Pool",
    icon: "0",
    pool_id: "2f5750897a45f2bbf3f0f66e37b286d5308f6e4f1727e2e733ca7aff",
    db_ticker: "SOCAL",
    db_name: "Southern California Stake Pool",
    db_url: "https://socalstakepool.com",
    roa: "3.333",
    roa_lifetime: "4.502",
    total_stake: "104919079356",
    stake_x_deleg: "10491907935.6",
    saturated: "0.0015262272453284",
    luck_lifetime: "190",
    blocks_epoch: "1",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DPADA",
    icon: "0",
    pool_id: "4ec969524032c26e3d47a29e5e8e3e5f5d45074701fd3da34c0e2d77",
    db_ticker: "DPADA",
    db_name: "DPADA",
    db_url: "http://dpadapools.com",
    roa: "0",
    roa_lifetime: "4.246",
    total_stake: "101827150821",
    stake_x_deleg: "10182715082.1",
    saturated: "0.0014812498627618",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "313",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAstra Mines",
    icon: "https://cdn.adapools.org/pool_logo/3e5fcbaf750c0291cecb72384091724a1c2d35da10a71473e16c926f.png",
    pool_id: "3e5fcbaf750c0291cecb72384091724a1c2d35da10a71473e16c926f",
    db_ticker: "MINES",
    db_name: "ADAstra Mines",
    db_url: "https://www.cpoolsunited.com/pool?id=MINES",
    roa: "0",
    roa_lifetime: "4.357",
    total_stake: "23217583920",
    stake_x_deleg: "2110689447.2727",
    saturated: "0.00033773942134172",
    luck_lifetime: "198",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10481000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Happy HUG Staking",
    icon: "https://cdn.adapools.org/pool_logo/2f5de17f4aef94923d82520eb9787d3081772bdea1264d919dcf48cb.png",
    pool_id: "2f5de17f4aef94923d82520eb9787d3081772bdea1264d919dcf48cb",
    db_ticker: "HUG",
    db_name: "Happy HUG Staking",
    db_url: "https://adastaking.co.uk",
    roa: "1.366",
    roa_lifetime: "2.347",
    total_stake: "1315664682125",
    stake_x_deleg: "46988024361.607",
    saturated: "0.019138590386998",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "5ch Pool",
    icon: "https://cdn.adapools.org/pool_logo/383dd348581c3c16a981de1178c42f1d3134280f66bed7518b772ffd.png",
    pool_id: "383dd348581c3c16a981de1178c42f1d3134280f66bed7518b772ffd",
    db_ticker: "5CH",
    db_name: "5ch Pool",
    db_url: "https://www.5chpool.net/",
    roa: "0",
    roa_lifetime: "3.314",
    total_stake: "209175456728",
    stake_x_deleg: "23241717414.222",
    saturated: "0.0030428143581878",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "43",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano in Africa",
    icon: "https://cdn.adapools.org/pool_logo/9e4ecaff3dac5c4c754a96cad961b404b9eab497afe4357160bb21c7.png",
    pool_id: "9e4ecaff3dac5c4c754a96cad961b404b9eab497afe4357160bb21c7",
    db_ticker: "CIA",
    db_name: "Cardano in Africa",
    db_url: "https://cardanoin.africa",
    roa: "0",
    roa_lifetime: "2.732",
    total_stake: "8172787976",
    stake_x_deleg: "681065664.66667",
    saturated: "0.00011888716290523",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Eternity",
    icon: "0",
    pool_id: "34db4f43dedbbda73146be5fceac7b3a80b6f100d2a2334044412155",
    db_ticker: "ETERN",
    db_name: "Eternity",
    db_url: "https://eternitypool.io",
    roa: "3.333",
    roa_lifetime: "3.231",
    total_stake: "95039670168",
    stake_x_deleg: "7919972514",
    saturated: "0.0013825143614275",
    luck_lifetime: "117",
    blocks_epoch: "1",
    blocks_lifetime: "13",
    tax_ratio: "0.0075",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "8088a5b6523f6bd6475475a20524082c07ffed6f89e39d30a1d6ab34",
    db_ticker: "RAY5",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "4.7",
    roa_lifetime: "3.93",
    total_stake: "23369613701992",
    stake_x_deleg: "32189550553.708",
    saturated: "0.33995095423738",
    luck_lifetime: "97",
    blocks_epoch: "16",
    blocks_lifetime: "348",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "28f85efe5e9d3b3d8a0f4950117e47b7415a92f80d69bfb64cfb4d8b",
    db_ticker: "RAY4",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "4.342",
    roa_lifetime: "4.067",
    total_stake: "43034535616364",
    stake_x_deleg: "34677305089.737",
    saturated: "0.62601083759884",
    luck_lifetime: "99",
    blocks_epoch: "36",
    blocks_lifetime: "779",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Xystence - ADA against child abuse",
    icon: "https://cdn.adapools.org/pool_logo/72100e82150b95fab37f7d9e155b6c083c1de629fea1b760f894aec6.png",
    pool_id: "72100e82150b95fab37f7d9e155b6c083c1de629fea1b760f894aec6",
    db_ticker: "XST",
    db_name: "Xystence - ADA against child abuse",
    db_url: "http://xystence.com",
    roa: "0",
    roa_lifetime: "2.575",
    total_stake: "66806707121",
    stake_x_deleg: "2303679555.8966",
    saturated: "0.00097181768277603",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5430000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Neat Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/9c84327ad8de82f858801d2f699e55a5714dca44cf589718c4588541.png",
    pool_id: "9c84327ad8de82f858801d2f699e55a5714dca44cf589718c4588541",
    db_ticker: "NEAT",
    db_name: "Neat Stake Pool",
    db_url: "https://www.neatstaking.com",
    roa: "0",
    roa_lifetime: "1.531",
    total_stake: "54090760459",
    stake_x_deleg: "10818152091.8",
    saturated: "0.00078684251558232",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "49000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "22cfa3b8612c146a0737c974dcfcbb8cddd86f3a511cf531ce8d91a1",
    db_ticker: "RAY3",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "4.016",
    roa_lifetime: "4.28",
    total_stake: "64394693053248",
    stake_x_deleg: "20345874582.385",
    saturated: "0.93673081765184",
    luck_lifetime: "102",
    blocks_epoch: "39",
    blocks_lifetime: "2117",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BONE pool",
    icon: "0",
    pool_id: "97aaa4621cffb87c3e8380376406cc9501d6c9e1cbb459e17cd60602",
    db_ticker: "BONE",
    db_name: "BONE pool",
    db_url: "https://poolbone.github.io/meta/",
    roa: "0",
    roa_lifetime: "2.43",
    total_stake: "117388630686",
    stake_x_deleg: "13043181187.333",
    saturated: "0.0017076181714943",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "62000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "1c8cd022e993a8366be641c17cb6d9c5d8944e00bfce3189d8b1515a",
    db_ticker: "RAY",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "3.915",
    roa_lifetime: "4.218",
    total_stake: "64973421965905",
    stake_x_deleg: "19025892230.133",
    saturated: "0.94514941834466",
    luck_lifetime: "97",
    blocks_epoch: "54",
    blocks_lifetime: "3279",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bit Cat",
    icon: "https://cdn.adapools.org/pool_logo/c495cbbc9f0c147c0b1287e623d18a11127dfdaf7517c0063dd9ad59.png",
    pool_id: "c495cbbc9f0c147c0b1287e623d18a11127dfdaf7517c0063dd9ad59",
    db_ticker: "CAT",
    db_name: "Bit Cat",
    db_url: "https://www.bitcat365.com",
    roa: "1.69",
    roa_lifetime: "4.862",
    total_stake: "216578628665",
    stake_x_deleg: "4708231057.9348",
    saturated: "0.0031505061410499",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "314",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TERO",
    icon: "https://cdn.adapools.org/pool_logo/88ad8407d0ca94fae8254604b29fbc2c5ae3d00a47b7117664ad849d.png",
    pool_id: "88ad8407d0ca94fae8254604b29fbc2c5ae3d00a47b7117664ad849d",
    db_ticker: "TERO",
    db_name: "TERO",
    db_url: "https://tero-pool.jimdosite.com/",
    roa: "3.333",
    roa_lifetime: "3.594",
    total_stake: "28447522389",
    stake_x_deleg: "748619010.23684",
    saturated: "0.00041381781081839",
    luck_lifetime: "162",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1889000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANMAL - Retiring Maladex's Pool.",
    icon: "https://cdn.adapools.org/pool_logo/229049e2c50b6b99d062dbc7cc81aa06fd071cbd06ed89ada72958c5.png",
    pool_id: "229049e2c50b6b99d062dbc7cc81aa06fd071cbd06ed89ada72958c5",
    db_ticker: "ANMAL",
    db_name: "ANMAL - Retiring Maladex's Pool.",
    db_url: "https://maladex.com",
    roa: "4.398",
    roa_lifetime: "0.294",
    total_stake: "1775162996705",
    stake_x_deleg: "1798544069.6099",
    saturated: "0.025822778345938",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "821",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CryptYoLo",
    icon: "https://cdn.adapools.org/pool_logo/dd9da0c2dc7c99d769fc07f3cf9646fec8b51b2b8f0721a39af46832.png",
    pool_id: "dd9da0c2dc7c99d769fc07f3cf9646fec8b51b2b8f0721a39af46832",
    db_ticker: "CRYL",
    db_name: "CryptYoLo",
    db_url: "https://www.cryptyolo.com",
    roa: "0",
    roa_lifetime: "1.676",
    total_stake: "55221258451",
    stake_x_deleg: "6902657306.375",
    saturated: "0.00080328754013619",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ERGO POOL",
    icon: "https://cdn.adapools.org/pool_logo/8503ccc673f332870e2af61932036da5df86433e75a09d347c09ca86.png",
    pool_id: "8503ccc673f332870e2af61932036da5df86433e75a09d347c09ca86",
    db_ticker: "ERGO",
    db_name: "ERGO POOL",
    db_url: "https://ergo-pool.com/",
    roa: "3.963",
    roa_lifetime: "3.142",
    total_stake: "366366620245",
    stake_x_deleg: "2475450136.7905",
    saturated: "0.0053294283654502",
    luck_lifetime: "119",
    blocks_epoch: "0",
    blocks_lifetime: "29",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TKCoin.org Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/9850877b3c73df643d6b9d21aa47b6e86f319460e0ca4044eb769bcb.png",
    pool_id: "9850877b3c73df643d6b9d21aa47b6e86f319460e0ca4044eb769bcb",
    db_ticker: "TIK",
    db_name: "TKCoin.org Stake Pool",
    db_url: "https://tkcoin.org/tikpool",
    roa: "0",
    roa_lifetime: "5.199",
    total_stake: "41283216387",
    stake_x_deleg: "4128321638.7",
    saturated: "0.00060053490758183",
    luck_lifetime: "293",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "930000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "First Vica Solutions",
    icon: "0",
    pool_id: "39543229fb6734b60f7ce76886eedee4dd17bbcb49e180ffe1200ee5",
    db_ticker: "1VICA",
    db_name: "First Vica Solutions",
    db_url: "https://vica-stake-pool.com/",
    roa: "5.822",
    roa_lifetime: "3.882",
    total_stake: "135265497683",
    stake_x_deleg: "7514749871.2778",
    saturated: "0.0019676677415001",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "165",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "22222000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hong Kong Beyondr",
    icon: "0",
    pool_id: "a3cbd717c489c9fd603ddb022650f7b971af986841a10274ab748a9d",
    db_ticker: "HKB",
    db_name: "Hong Kong Beyondr",
    db_url: "https://HKBeyondr.io",
    roa: "0",
    roa_lifetime: "0.618",
    total_stake: "48644985550",
    stake_x_deleg: "4422271413.6364",
    saturated: "0.00070762441636664",
    luck_lifetime: "24",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.00111",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cheaper Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/071e5664b4c8055da08899fa7a4af70e33aa78ff625908fd6ca27eba.png",
    pool_id: "071e5664b4c8055da08899fa7a4af70e33aa78ff625908fd6ca27eba",
    db_ticker: "CHIPI",
    db_name: "Cheaper Stake Pool",
    db_url: "https://cheaperpool.com",
    roa: "0",
    roa_lifetime: "2.384",
    total_stake: "16080412731",
    stake_x_deleg: "1608041273.1",
    saturated: "0.00023391707377552",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "11200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DRMAL - Retiring Maladex's Pool.",
    icon: "https://cdn.adapools.org/pool_logo/5accb407fb2299e59614d237131af021b3a3d8267b391e2d707c42ab.png",
    pool_id: "5accb407fb2299e59614d237131af021b3a3d8267b391e2d707c42ab",
    db_ticker: "DRMAL",
    db_name: "DRMAL - Retiring Maladex's Pool.",
    db_url: "https://maladex.com",
    roa: "3.555",
    roa_lifetime: "0.272",
    total_stake: "1572766715185",
    stake_x_deleg: "1580670065.5126",
    saturated: "0.022878578672199",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "751",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KURIS JAPAN",
    icon: "https://cdn.adapools.org/pool_logo/78149b89d9aa69657ff326a82a0949e5c61b44d4d25b87f8ea6c9a0a.png",
    pool_id: "78149b89d9aa69657ff326a82a0949e5c61b44d4d25b87f8ea6c9a0a",
    db_ticker: "KURIS",
    db_name: "KURIS JAPAN",
    db_url: "https://kuris.org/",
    roa: "2.14",
    roa_lifetime: "4.085",
    total_stake: "2798731714884",
    stake_x_deleg: "58306910726.75",
    saturated: "0.040712333941922",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "298",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ada Swing",
    icon: "https://cdn.adapools.org/pool_logo/0f2a5e0daf7812510b28b8ed07c56c8084c1b459c3b552f5146b9aff.png",
    pool_id: "0f2a5e0daf7812510b28b8ed07c56c8084c1b459c3b552f5146b9aff",
    db_ticker: "SWING",
    db_name: "Ada Swing",
    db_url: "https://ada-swing.net",
    roa: "0",
    roa_lifetime: "3.331",
    total_stake: "43257116581",
    stake_x_deleg: "5407139572.625",
    saturated: "0.00062924865797054",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "83",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaRare Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/f65731aa68706607718d140426c75cd7ac2c40703c31c325fdd46f91.png",
    pool_id: "f65731aa68706607718d140426c75cd7ac2c40703c31c325fdd46f91",
    db_ticker: "ARARE",
    db_name: "AdaRare Stake Pool",
    db_url: "https://arare.io/",
    roa: "0",
    roa_lifetime: "1.93",
    total_stake: "6490463780",
    stake_x_deleg: "811307972.5",
    saturated: "0.000094414883514572",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TheHolyGrail",
    icon: "https://cdn.adapools.org/pool_logo/a463f246be423fa966aa5f7dff358ad2ae87fa670d487645530a19d9.png",
    pool_id: "a463f246be423fa966aa5f7dff358ad2ae87fa670d487645530a19d9",
    db_ticker: "MONTY",
    db_name: "TheHolyGrail",
    db_url: "https://holygrailstaking.com",
    roa: "0",
    roa_lifetime: "2.293",
    total_stake: "36002153715",
    stake_x_deleg: "4000239301.6667",
    saturated: "0.00052371282923567",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vrijstaat der Nederlanden",
    icon: "https://cdn.adapools.org/pool_logo/ab60e74d183c8aae66c557a58cb55f4e3d8a87b5840a8190b3e1477b.png",
    pool_id: "ab60e74d183c8aae66c557a58cb55f4e3d8a87b5840a8190b3e1477b",
    db_ticker: "VRIJ",
    db_name: "Vrijstaat der Nederlanden",
    db_url: "https://vrijstaat.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "135110706150",
    stake_x_deleg: "22518451025",
    saturated: "0.0019654160342181",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Olympic-Size Pool",
    icon: "https://cdn.adapools.org/pool_logo/f9db7272010a3ef3fcf1eeeab378fbccbb08fc9985feec2676b4cc11.png",
    pool_id: "f9db7272010a3ef3fcf1eeeab378fbccbb08fc9985feec2676b4cc11",
    db_ticker: "OLYMP",
    db_name: "Olympic-Size Pool",
    db_url: "https://cardano-olympic.ge",
    roa: "0.55",
    roa_lifetime: "3.783",
    total_stake: "61875579918",
    stake_x_deleg: "7734447489.75",
    saturated: "0.00090008601363069",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "95",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Prime Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/729bc73ff6f7cd20a412470cc585e29dfa049fea230584b7caf9d3cb.png",
    pool_id: "729bc73ff6f7cd20a412470cc585e29dfa049fea230584b7caf9d3cb",
    db_ticker: "PSP",
    db_name: "Prime Stake Pool",
    db_url: "https://www.primestakepool.com",
    roa: "0",
    roa_lifetime: "4.639",
    total_stake: "9980605361",
    stake_x_deleg: "1108956151.2222",
    saturated: "0.00014518495511329",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "22",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "4950000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Carl's Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c19a50d82b0d9ba8703bab6ef5ff85924da8fea694e6a4d986b51e45.png",
    pool_id: "c19a50d82b0d9ba8703bab6ef5ff85924da8fea694e6a4d986b51e45",
    db_ticker: "CARL⭐",
    db_name: "Carl's Stake Pool",
    db_url: "https://carlworks.com",
    roa: "0",
    roa_lifetime: "3.311",
    total_stake: "54920888981",
    stake_x_deleg: "9153481496.8333",
    saturated: "0.00079891815306577",
    luck_lifetime: "121",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "21000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Elik Pool",
    icon: "0",
    pool_id: "6d9751817286a5a69e139f74f8290f7a9adc4a97d2a79cd0bb64053b",
    db_ticker: "ELIK",
    db_name: "Elik Pool",
    db_url: "https://adacardano.ro",
    roa: "4.88",
    roa_lifetime: "2.236",
    total_stake: "390818690175",
    stake_x_deleg: "8684859781.6667",
    saturated: "0.0056851254947132",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADACZECH",
    icon: "https://cdn.adapools.org/pool_logo/22c8e8d7f8b0cf62b6f3a16ea3f9c01929fb69dd02d373674c2d5954.png",
    pool_id: "22c8e8d7f8b0cf62b6f3a16ea3f9c01929fb69dd02d373674c2d5954",
    db_ticker: "CZBE1",
    db_name: "ADACZECH",
    db_url: "https://ada-czech.org",
    roa: "0",
    roa_lifetime: "0.481",
    total_stake: "33824388661",
    stake_x_deleg: "4228048582.625",
    saturated: "0.00049203351619042",
    luck_lifetime: "24",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAPunkz",
    icon: "0",
    pool_id: "b6446922df11a2249e242b850eea39876494744f7125227515aff625",
    db_ticker: "PUNKZ",
    db_name: "ADAPunkz",
    db_url: "https://adapunkz.io/pool",
    roa: "1.763",
    roa_lifetime: "2.598",
    total_stake: "171432598614",
    stake_x_deleg: "2116451834.7407",
    saturated: "0.0024937800097763",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zünder",
    icon: "https://cdn.adapools.org/pool_logo/a0b7055f7e18129061bb5585667ac27a9da93ec2b5c8c9bf156a70b9.png",
    pool_id: "a0b7055f7e18129061bb5585667ac27a9da93ec2b5c8c9bf156a70b9",
    db_ticker: "ZUND",
    db_name: "Zünder",
    db_url: "https://zunderpool.io",
    roa: "0",
    roa_lifetime: "1.447",
    total_stake: "103260424880",
    stake_x_deleg: "4489583690.4348",
    saturated: "0.0015020992824507",
    luck_lifetime: "58",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rose Pool Staking",
    icon: "https://cdn.adapools.org/pool_logo/fffda60991eafa48674f7137a76e11ddc2464e94c73a4319629072b4.png",
    pool_id: "fffda60991eafa48674f7137a76e11ddc2464e94c73a4319629072b4",
    db_ticker: "ROSE",
    db_name: "Rose Pool Staking",
    db_url: "http://www.whiterosestaking.live",
    roa: "0",
    roa_lifetime: "5.837",
    total_stake: "3260915125",
    stake_x_deleg: "362323902.77778",
    saturated: "0.000047435581202455",
    luck_lifetime: "146",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "真正的中国池",
    icon: "https://cdn.adapools.org/pool_logo/ae3bc5a76ac9e46678ab650a81cc6491a074a9d970b5a7703a2d0c8e.png",
    pool_id: "ae3bc5a76ac9e46678ab650a81cc6491a074a9d970b5a7703a2d0c8e",
    db_ticker: "996",
    db_name: "真正的中国池",
    db_url: "https://adapool.ssmu.win",
    roa: "1.404",
    roa_lifetime: "3.994",
    total_stake: "1019788182230",
    stake_x_deleg: "16448196487.581",
    saturated: "0.014834561242214",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "166",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "2020000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Oz Green Australia – 100% Renewable Energy",
    icon: "https://cdn.adapools.org/pool_logo/db8f20cf666a94c756fa3f00ef3e6655589972bdeb0a314f30c55939.png",
    pool_id: "db8f20cf666a94c756fa3f00ef3e6655589972bdeb0a314f30c55939",
    db_ticker: "OZGRN",
    db_name: "Oz Green Australia – 100% Renewable Energy",
    db_url: "http://www.ozgreen-cardano.com",
    roa: "0",
    roa_lifetime: "0.712",
    total_stake: "19574705496",
    stake_x_deleg: "1505746576.6154",
    saturated: "0.0002847475314371",
    luck_lifetime: "24",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EEL",
    icon: "0",
    pool_id: "a0022c62e100c18970d64feb7d0f8c08afe261e4d4435f5254ae92a7",
    db_ticker: "EEL",
    db_name: "EEL",
    db_url: "http://eelada.com/",
    roa: "2.283",
    roa_lifetime: "4.097",
    total_stake: "1022314580819",
    stake_x_deleg: "30979229721.788",
    saturated: "0.014871312025606",
    luck_lifetime: "119",
    blocks_epoch: "2",
    blocks_lifetime: "99",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ashes",
    icon: "https://cdn.adapools.org/pool_logo/e82caad6754faa93bdb222f0e70e88c1b70c05f9f6854ec224157bfe.png",
    pool_id: "e82caad6754faa93bdb222f0e70e88c1b70c05f9f6854ec224157bfe",
    db_ticker: "ASHES",
    db_name: "Ashes",
    db_url: "https://www.ashesstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "73937762023",
    stake_x_deleg: "4929184134.8667",
    saturated: "0.0010755510584992",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOBC StakePoolServer",
    icon: "https://cdn.adapools.org/pool_logo/c5c43637ce16dc7e2619be1733a769f63aaf193e6e93458479eafd63.png",
    pool_id: "c5c43637ce16dc7e2619be1733a769f63aaf193e6e93458479eafd63",
    db_ticker: "MOBC",
    db_name: "MOBC StakePoolServer",
    db_url: "https://mobcsp.work",
    roa: "2.653",
    roa_lifetime: "3.405",
    total_stake: "1136806423147",
    stake_x_deleg: "87446647934.385",
    saturated: "0.016536791461772",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "132",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kowloon Cardano Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/73a2cf679cce99614176523741aa05942722890b632184e377724df1.png",
    pool_id: "73a2cf679cce99614176523741aa05942722890b632184e377724df1",
    db_ticker: "kowln",
    db_name: "Kowloon Cardano Stake Pool",
    db_url: "https://kowloon-central.github.io/kowln",
    roa: "0",
    roa_lifetime: "3.171",
    total_stake: "65772186449",
    stake_x_deleg: "8221523306.125",
    saturated: "0.0009567688123022",
    luck_lifetime: "180",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.028",
    tax_fix: "380000000",
    pledge: "1800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mate",
    icon: "0",
    pool_id: "79b7b997ca4105df4e470570423d830cf5600b6d12b8bf1273c1daf2",
    db_ticker: "MATE",
    db_name: "Mate",
    db_url: "https://www.mateada.club",
    roa: "0",
    roa_lifetime: "1.238",
    total_stake: "175824689841",
    stake_x_deleg: "25117812834.429",
    saturated: "0.002557670479801",
    luck_lifetime: "58",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amaterasu",
    icon: "0",
    pool_id: "765c443459fdcbbaedf691f9e787632d3df3585f3db75dad701b5be7",
    db_ticker: "AMT",
    db_name: "Amaterasu",
    db_url: "https://twitter.com/ProjectNTK",
    roa: "2.526",
    roa_lifetime: "3.567",
    total_stake: "912730258253",
    stake_x_deleg: "24668385358.189",
    saturated: "0.013277220848027",
    luck_lifetime: "128",
    blocks_epoch: "3",
    blocks_lifetime: "24",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Decentralized Reality",
    icon: "0",
    pool_id: "374c1852bb8be71a2728c3b3cdc707d047e3055bde189835eff30a18",
    db_ticker: "DECNT",
    db_name: "Decentralized Reality",
    db_url: "http://decentralizedreality.io/",
    roa: "0",
    roa_lifetime: "2.711",
    total_stake: "144730268943",
    stake_x_deleg: "12060855745.25",
    saturated: "0.0021053490084011",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "80",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amigo Pool",
    icon: "https://cdn.adapools.org/pool_logo/436f170421ea00dcc71c8a11225ccbf341e9a470788bacdb10da720a.png",
    pool_id: "436f170421ea00dcc71c8a11225ccbf341e9a470788bacdb10da720a",
    db_ticker: "AMIGO",
    db_name: "Amigo Pool",
    db_url: "https://twitter.com/antoniosoloz",
    roa: "0",
    roa_lifetime: "4.624",
    total_stake: "1702443912",
    stake_x_deleg: "212805489",
    saturated: "0.000024764955030929",
    luck_lifetime: "209",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hippo Pool",
    icon: "https://cdn.adapools.org/pool_logo/718f15efee599d498a7582c4f56a940cfebffc14e850656db8d529e1.png",
    pool_id: "718f15efee599d498a7582c4f56a940cfebffc14e850656db8d529e1",
    db_ticker: "HIPPO",
    db_name: "Hippo Pool",
    db_url: "https://hippo-pool.com",
    roa: "0",
    roa_lifetime: "3.615",
    total_stake: "34380727390",
    stake_x_deleg: "5730121231.6667",
    saturated: "0.00050012641341219",
    luck_lifetime: "152",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FIVE SecureStaking.io",
    icon: "https://cdn.adapools.org/pool_logo/acfdedecd7d754d2d9f4977626ba0e82ba465892c9f98f7f2d2d0e1d.png",
    pool_id: "acfdedecd7d754d2d9f4977626ba0e82ba465892c9f98f7f2d2d0e1d",
    db_ticker: "SECUR",
    db_name: "FIVE SecureStaking.io",
    db_url: "https://cardano.securestaking.io",
    roa: "0",
    roa_lifetime: "4.437",
    total_stake: "8373081698",
    stake_x_deleg: "1046635212.25",
    saturated: "0.00012180077725889",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "84",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Axnosis Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ee1f91a5e08196567a88de2f1180b4a9f673961e5c2e170421a39002.png",
    pool_id: "ee1f91a5e08196567a88de2f1180b4a9f673961e5c2e170421a39002",
    db_ticker: "AXNSA",
    db_name: "Axnosis Stake Pool",
    db_url: "https://axnstakepool.co.za",
    roa: "0",
    roa_lifetime: "3.041",
    total_stake: "1420291113",
    stake_x_deleg: "177536389.125",
    saturated: "0.000020660560560231",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "82",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ELLADA",
    icon: "0",
    pool_id: "f765c73fa3700f6fa735310ebc0255265613db23fd7bb14e8d84e795",
    db_ticker: "HELAS",
    db_name: "ELLADA",
    db_url: "https://elladapool.online",
    roa: "0",
    roa_lifetime: "1.571",
    total_stake: "115418414454",
    stake_x_deleg: "403560889.6993",
    saturated: "0.0016789580106263",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Merkle Validator",
    icon: "https://cdn.adapools.org/pool_logo/232b0ab2239204974aa236cf022ab95301a1772609f3c6907628cde2.png",
    pool_id: "232b0ab2239204974aa236cf022ab95301a1772609f3c6907628cde2",
    db_ticker: "MERK",
    db_name: "Merkle Validator",
    db_url: "https://merklestaking.io",
    roa: "0",
    roa_lifetime: "1.043",
    total_stake: "24013076203",
    stake_x_deleg: "2668119578.1111",
    saturated: "0.0003493112155589",
    luck_lifetime: "35",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaVerse",
    icon: "https://cdn.adapools.org/pool_logo/4be4a454d63ef9aab7b44a31440a1f85eea3ee724bd13ca07b9ff255.png",
    pool_id: "4be4a454d63ef9aab7b44a31440a1f85eea3ee724bd13ca07b9ff255",
    db_ticker: "ADAV",
    db_name: "AdaVerse",
    db_url: "https://adaversepool.com",
    roa: "2.672",
    roa_lifetime: "2.652",
    total_stake: "960701534981",
    stake_x_deleg: "26686153749.472",
    saturated: "0.013975045018663",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Time to Live",
    icon: "https://cdn.adapools.org/pool_logo/45dfa4d34b9da5ae5fe91fe6b74f02fa77a5c05bdeb9b4763d9e5935.png",
    pool_id: "45dfa4d34b9da5ae5fe91fe6b74f02fa77a5c05bdeb9b4763d9e5935",
    db_ticker: "TTL",
    db_name: "Time to Live",
    db_url: "https://ttl-pool.site",
    roa: "3.333",
    roa_lifetime: "1.903",
    total_stake: "59185685507",
    stake_x_deleg: "11837137101.4",
    saturated: "0.00086095690420347",
    luck_lifetime: "72",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "fehupool",
    icon: "0",
    pool_id: "3e7510d94ad31d6f4a36be66a9c1105397106b1ee4473e7a42d976b3",
    db_ticker: "FEHU",
    db_name: "fehupool",
    db_url: "https://fehupool.net",
    roa: "0",
    roa_lifetime: "2.167",
    total_stake: "96813766982",
    stake_x_deleg: "10757085220.222",
    saturated: "0.0014083216303246",
    luck_lifetime: "41",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "36000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakingKakis",
    icon: "https://cdn.adapools.org/pool_logo/09ae473a0781eefa6345e2236a8ffa92c26dc151c93a8f67ae735860.png",
    pool_id: "09ae473a0781eefa6345e2236a8ffa92c26dc151c93a8f67ae735860",
    db_ticker: "KAKIS",
    db_name: "StakingKakis",
    db_url: "https://ada.stakingkakis.com",
    roa: "2.643",
    roa_lifetime: "1.689",
    total_stake: "142361592870",
    stake_x_deleg: "23726932145",
    saturated: "0.002070892568446",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1337",
    icon: "https://cdn.adapools.org/pool_logo/a84d7b46aef878ae8873710bdb9ee3119094dc0bbe0d80e94f53cb9e.png",
    pool_id: "a84d7b46aef878ae8873710bdb9ee3119094dc0bbe0d80e94f53cb9e",
    db_ticker: "1337",
    db_name: "1337",
    db_url: "http://1337.chiplife.art",
    roa: "3.397",
    roa_lifetime: "2.678",
    total_stake: "551546351915",
    stake_x_deleg: "68943293989.375",
    saturated: "0.0080231839101245",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "20",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pure Resilience Cluster for Cardano",
    icon: "https://cdn.adapools.org/pool_logo/4687b887da2d05e326e1d28b7c424f2ce5c9a933809f10c870bb8e34.png",
    pool_id: "4687b887da2d05e326e1d28b7c424f2ce5c9a933809f10c870bb8e34",
    db_ticker: "GROOT",
    db_name: "Pure Resilience Cluster for Cardano",
    db_url: "https://groot.earth/",
    roa: "2.861",
    roa_lifetime: "3.259",
    total_stake: "96398280213",
    stake_x_deleg: "7415252324.0769",
    saturated: "0.0014022776654823",
    luck_lifetime: "132",
    blocks_epoch: "0",
    blocks_lifetime: "19",
    tax_ratio: "0.0222",
    tax_fix: "340000000",
    pledge: "27222222222",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PMWare_Pool",
    icon: "0",
    pool_id: "0e95ebdf3855fba77340fc7dec46ecaee4d569deffde6239591227cd",
    db_ticker: "PMW",
    db_name: "PMWare_Pool",
    db_url: "https://www.pmware.ca/",
    roa: "4.846",
    roa_lifetime: "4.45",
    total_stake: "150345125523",
    stake_x_deleg: "37586281380.75",
    saturated: "0.0021870266893682",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "107",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NetSecology",
    icon: "0",
    pool_id: "142567448fb12f8ceb30475711c599c14451bb8df17e163dfc3d755d",
    db_ticker: "NETSC",
    db_name: "NetSecology",
    db_url: "https://netsecology.io",
    roa: "2.787",
    roa_lifetime: "1.246",
    total_stake: "130438026554",
    stake_x_deleg: "26087605310.8",
    saturated: "0.0018974439270296",
    luck_lifetime: "62",
    blocks_epoch: "1",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Opendawn",
    icon: "https://cdn.adapools.org/pool_logo/9f2049983941787cf7d02c27bc7b09553a14d9e8e09bcab5c613f8e9.png",
    pool_id: "9f2049983941787cf7d02c27bc7b09553a14d9e8e09bcab5c613f8e9",
    db_ticker: "DAWN",
    db_name: "Opendawn",
    db_url: "https://opendawn.com/DAWN/",
    roa: "0",
    roa_lifetime: "1.627",
    total_stake: "20132105616",
    stake_x_deleg: "2236900624",
    saturated: "0.00029285586840417",
    luck_lifetime: "60",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.0183",
    tax_fix: "340000000",
    pledge: "5700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raspberry Pi Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/87ae8b395304a15fbd9faa78a4d26d16e6e8cd39be2e9ae6cc860988.png",
    pool_id: "87ae8b395304a15fbd9faa78a4d26d16e6e8cd39be2e9ae6cc860988",
    db_ticker: "RASPI",
    db_name: "Raspberry Pi Stakepool",
    db_url: "https://raspipool.nl",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "151737336579",
    stake_x_deleg: "10115822438.6",
    saturated: "0.0022072787775295",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bizarre Stars",
    icon: "0",
    pool_id: "d291a7ebd793c51590c39fb2f76f028e8800ea66e4735353b7b39932",
    db_ticker: "BIZAR",
    db_name: "Bizarre Stars",
    db_url: "https://twitter.com/BizarreStarsNFT",
    roa: "1.037",
    roa_lifetime: "2.989",
    total_stake: "756280977969",
    stake_x_deleg: "2305734688.9299",
    saturated: "0.011001398799768",
    luck_lifetime: "96",
    blocks_epoch: "1",
    blocks_lifetime: "63",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4999000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Politikoz",
    icon: "0",
    pool_id: "0c0755334363223ee4104147c50894686b8c5fedc9eee299771860b3",
    db_ticker: "PLKOZ",
    db_name: "Politikoz",
    db_url: "https://politikoz.io",
    roa: "4.187",
    roa_lifetime: "2.202",
    total_stake: "924700440097",
    stake_x_deleg: "5439414353.5118",
    saturated: "0.013451347591933",
    luck_lifetime: "86",
    blocks_epoch: "1",
    blocks_lifetime: "27",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GIGA POS Pool",
    icon: "0",
    pool_id: "2b0217f1b7d017ca35856ba10eec105acde17a65dfd4ecb75e93c2db",
    db_ticker: "GIGA",
    db_name: "GIGA POS Pool",
    db_url: "https://gigapos.online/",
    roa: "1.522",
    roa_lifetime: "4.949",
    total_stake: "271763410093",
    stake_x_deleg: "11815800438.826",
    saturated: "0.0039532630605719",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "367",
    tax_ratio: "0.023",
    tax_fix: "340000000",
    pledge: "11886000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Feeling lucky?",
    icon: "https://cdn.adapools.org/pool_logo/e542f88759c997c8a3357a542efbf00c85405baea7f6006b3b344160.png",
    pool_id: "e542f88759c997c8a3357a542efbf00c85405baea7f6006b3b344160",
    db_ticker: "777",
    db_name: "Feeling lucky?",
    db_url: "http://lucky777.io/",
    roa: "0",
    roa_lifetime: "1.505",
    total_stake: "10555422159",
    stake_x_deleg: "1319427769.875",
    saturated: "0.00015354664741525",
    luck_lifetime: "68",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TI-Rocinante",
    icon: "https://cdn.adapools.org/pool_logo/e28f147e344364afeb14c6d05c0f88497a0f3b4aaa82339d9accc67e.png",
    pool_id: "e28f147e344364afeb14c6d05c0f88497a0f3b4aaa82339d9accc67e",
    db_ticker: "ROCI",
    db_name: "TI-Rocinante",
    db_url: "https://adakailabs.com",
    roa: "0",
    roa_lifetime: "1.537",
    total_stake: "36164610929",
    stake_x_deleg: "6027435154.8333",
    saturated: "0.00052607604694335",
    luck_lifetime: "64",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Global Investment Registrar",
    icon: "0",
    pool_id: "e77b1fe68290a4b1770c47d0c2c2fd5044717f28f9f8d21447fddadc",
    db_ticker: "GIR",
    db_name: "Global Investment Registrar",
    db_url: "https://adagir.com",
    roa: "0",
    roa_lifetime: "1.199",
    total_stake: "98098291450",
    stake_x_deleg: "16349715241.667",
    saturated: "0.0014270072330995",
    luck_lifetime: "54",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dented Moon",
    icon: "0",
    pool_id: "7182292d23f142d4c35bd311a36f0095fc54a4add41024b42c8280ad",
    db_ticker: "DMOON",
    db_name: "Dented Moon",
    db_url: "https://dentedmoon.com",
    roa: "0",
    roa_lifetime: "3.988",
    total_stake: "208675627067",
    stake_x_deleg: "52168906766.75",
    saturated: "0.003035543481896",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "70",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "129990000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SpidarPool",
    icon: "0",
    pool_id: "5cff121b7239ea76035bcb79711674a29fdf35d0bbe83a212467fab9",
    db_ticker: "SPIDR",
    db_name: "SpidarPool",
    db_url: "https://cardano.weebl.me",
    roa: "0",
    roa_lifetime: "2.826",
    total_stake: "65140923357",
    stake_x_deleg: "2714205139.875",
    saturated: "0.00094758601222528",
    luck_lifetime: "127",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Salmon Pool",
    icon: "0",
    pool_id: "54da9f3a89aa5b3f5750a0bfd257b93331094f139ee4e484e9effb0c",
    db_ticker: "SAMON",
    db_name: "Salmon Pool",
    db_url: "https://www.salmonpool.io/",
    roa: "3.333",
    roa_lifetime: "1.943",
    total_stake: "389510592029",
    stake_x_deleg: "12564857807.387",
    saturated: "0.0056660969725202",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Poolailler",
    icon: "https://cdn.adapools.org/pool_logo/15e94d5b8ab9ff7e7eaa0479a1bb7209bdca5f29ff7af1cf98d6c176.png",
    pool_id: "15e94d5b8ab9ff7e7eaa0479a1bb7209bdca5f29ff7af1cf98d6c176",
    db_ticker: "POULE",
    db_name: "Poolailler",
    db_url: "https://poolailler.xyz",
    roa: "0",
    roa_lifetime: "3.375",
    total_stake: "28643466365",
    stake_x_deleg: "5728693273",
    saturated: "0.00041666815068569",
    luck_lifetime: "154",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.013",
    tax_fix: "340000000",
    pledge: "25500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aerospace Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6cc9e3fad65bf5e427ef1ae0aa0fe08a9879870a4eccc479f3b217ad.png",
    pool_id: "6cc9e3fad65bf5e427ef1ae0aa0fe08a9879870a4eccc479f3b217ad",
    db_ticker: "AERO",
    db_name: "Aerospace Stake Pool",
    db_url: "https://aeropool.org",
    roa: "0",
    roa_lifetime: "1.777",
    total_stake: "21013604566",
    stake_x_deleg: "3502267427.6667",
    saturated: "0.00030567877652037",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "8000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Straight Pool",
    icon: "https://cdn.adapools.org/pool_logo/000006d97fd0415d2dafdbb8b782717a3d3ff32f865792b8df7ddd00.png",
    pool_id: "000006d97fd0415d2dafdbb8b782717a3d3ff32f865792b8df7ddd00",
    db_ticker: "STR8",
    db_name: "Straight Pool",
    db_url: "https://straightpool.github.io",
    roa: "3.951",
    roa_lifetime: "4.609",
    total_stake: "60598486245644",
    stake_x_deleg: "13781779905.764",
    saturated: "0.88150850447268",
    luck_lifetime: "99",
    blocks_epoch: "28",
    blocks_lifetime: "4849",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Stake Pool Cologne",
    icon: "https://cdn.adapools.org/pool_logo/803c14fb539f9c6abd11bf12d760a8a18f9e3cc7e4de8b4ae228dd79.png",
    pool_id: "803c14fb539f9c6abd11bf12d760a8a18f9e3cc7e4de8b4ae228dd79",
    db_ticker: "SPCOL",
    db_name: "Cardano Stake Pool Cologne",
    db_url: "https://cardano-stake-pool-cologne.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "35747692977",
    stake_x_deleg: "3971965886.3333",
    saturated: "0.00052001126309932",
    luck_lifetime: "141",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADALovelacePool",
    icon: "https://cdn.adapools.org/pool_logo/cdb10209d937fc1559c635e35b9147febde5307b4a7d276f868775cd.png",
    pool_id: "cdb10209d937fc1559c635e35b9147febde5307b4a7d276f868775cd",
    db_ticker: "ADALO",
    db_name: "ADALovelacePool",
    db_url: "https://adalovelacepool.com",
    roa: "3.747",
    roa_lifetime: "4.74",
    total_stake: "67070023416228",
    stake_x_deleg: "18250346507.817",
    saturated: "0.97564806812046",
    luck_lifetime: "99",
    blocks_epoch: "45",
    blocks_lifetime: "7495",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VNPOOL",
    icon: "https://cdn.adapools.org/pool_logo/9a60391a0872cb6d0a013e51aab80aa08b85201b01b4804f8880c7d1.png",
    pool_id: "9a60391a0872cb6d0a013e51aab80aa08b85201b01b4804f8880c7d1",
    db_ticker: "VNP",
    db_name: "VNPOOL",
    db_url: "https://vnpool.org",
    roa: "0",
    roa_lifetime: "4.018",
    total_stake: "13803752715",
    stake_x_deleg: "2300625452.5",
    saturated: "0.00020079916456304",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "94",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEON POOL",
    icon: "https://cdn.adapools.org/pool_logo/1995ead012d50034638bd848d616d1fb4b178780a297e85b0d8e6730.png",
    pool_id: "1995ead012d50034638bd848d616d1fb4b178780a297e85b0d8e6730",
    db_ticker: "KUNI",
    db_name: "LEON POOL",
    db_url: "https://twitter.com/leon_stake_pool",
    roa: "3.333",
    roa_lifetime: "4.167",
    total_stake: "89391084995",
    stake_x_deleg: "6385077499.6429",
    saturated: "0.0013003460404557",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "268",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OneTreeBlock",
    icon: "https://cdn.adapools.org/pool_logo/a2c95524f2dcdb424490a5b7126b5826b3b98126d112e86f43efde27.png",
    pool_id: "a2c95524f2dcdb424490a5b7126b5826b3b98126d112e86f43efde27",
    db_ticker: "OTB",
    db_name: "OneTreeBlock",
    db_url: "https://onetreeblock.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "118991882545",
    stake_x_deleg: "23798376509",
    saturated: "0.0017309402086619",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Peak Pool",
    icon: "https://cdn.adapools.org/pool_logo/e80753d6214589d0682aa53cc44cd033a72a4299da8dc82696a93b5e.png",
    pool_id: "e80753d6214589d0682aa53cc44cd033a72a4299da8dc82696a93b5e",
    db_ticker: "PEAK",
    db_name: "Peak Pool",
    db_url: "https://www.peakstaking.com",
    roa: "0",
    roa_lifetime: "3.899",
    total_stake: "45181358699",
    stake_x_deleg: "9036271739.8",
    saturated: "0.0006572400468116",
    luck_lifetime: "111",
    blocks_epoch: "0",
    blocks_lifetime: "49",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "16000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ACA Pool",
    icon: "0",
    pool_id: "2fce76dab5c44fdd3bcaa886264a94014c0b94524dc1a4568dc1f10e",
    db_ticker: "ACA",
    db_name: "ACA Pool",
    db_url: "https://AskCharlesAnything.com",
    roa: "3.333",
    roa_lifetime: "4.438",
    total_stake: "80195633011",
    stake_x_deleg: "11456519001.571",
    saturated: "0.0011665824825096",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "265",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DOT5",
    icon: "0",
    pool_id: "1ed9bb5ed9e686c0ae6bfbcd8f61f7189b56c77d1ec42eb2b3465323",
    db_ticker: "DOT5",
    db_name: "DOT5",
    db_url:
      "https://adapools.org/pool/1ed9bb5ed9e686c0ae6bfbcd8f61f7189b56c77d1ec42eb2b3465323",
    roa: "1.161",
    roa_lifetime: "1.141",
    total_stake: "220905706521",
    stake_x_deleg: "1686303103.2137",
    saturated: "0.0032134508805293",
    luck_lifetime: "60",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Caranify",
    icon: "https://cdn.adapools.org/pool_logo/7a696d3656f6cb46fe077a69352f3b97eddc400dafbd438946efce8f.png",
    pool_id: "7a696d3656f6cb46fe077a69352f3b97eddc400dafbd438946efce8f",
    db_ticker: "ADAFY",
    db_name: "Caranify",
    db_url: "https://cardanify.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "75614832863",
    stake_x_deleg: "7561483286.3",
    saturated: "0.0010999469188524",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tribe Pool Rocks",
    icon: "https://cdn.adapools.org/pool_logo/69e9c9861cdc0db056c9611e11cb91994f16063d973acdad43a2c827.png",
    pool_id: "69e9c9861cdc0db056c9611e11cb91994f16063d973acdad43a2c827",
    db_ticker: "TRIBE",
    db_name: "Tribe Pool Rocks",
    db_url: "https://tribepoolrocks.com",
    roa: "0",
    roa_lifetime: "3.509",
    total_stake: "16341921236",
    stake_x_deleg: "2723653539.3333",
    saturated: "0.00023772116172278",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VRITS",
    icon: "https://cdn.adapools.org/pool_logo/f137e6702bb09babf88cc93c93dffd64379d5d43bdbe3ce870ff5c54.png",
    pool_id: "f137e6702bb09babf88cc93c93dffd64379d5d43bdbe3ce870ff5c54",
    db_ticker: "VRITS",
    db_name: "VRITS",
    db_url: "http://vrits.nl",
    roa: "0",
    roa_lifetime: "3.847",
    total_stake: "39890815725",
    stake_x_deleg: "6648469287.5",
    saturated: "0.0005802800612774",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "81",
    tax_ratio: "0.0075",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/db172ffa295369edb048b0a5b802f1d7ed5579b71e78334635b7c0e7.png",
    pool_id: "db172ffa295369edb048b0a5b802f1d7ed5579b71e78334635b7c0e7",
    db_ticker: "GOAT4",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "0",
    roa_lifetime: "3.058",
    total_stake: "9282314415",
    stake_x_deleg: "1547052402.5",
    saturated: "0.00013502712039445",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "53",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/1b39e57d6a93c4f04922f82f8c318ce159ebcb9abcdabb75091318c9.png",
    pool_id: "1b39e57d6a93c4f04922f82f8c318ce159ebcb9abcdabb75091318c9",
    db_ticker: "GOAT6",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "0",
    roa_lifetime: "3.042",
    total_stake: "2685388399",
    stake_x_deleg: "447564733.16667",
    saturated: "0.000039063561784944",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "54",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Green Cripto Pool",
    icon: "https://cdn.adapools.org/pool_logo/82bc9685ac388c74feddebef6b9ff06f166f9ac816a72c388750894c.png",
    pool_id: "82bc9685ac388c74feddebef6b9ff06f166f9ac816a72c388750894c",
    db_ticker: "CRIP",
    db_name: "Green Cripto Pool",
    db_url: "https://greencripto.com",
    roa: "0",
    roa_lifetime: "1.733",
    total_stake: "1583294274",
    stake_x_deleg: "263882379",
    saturated: "0.000023031719999676",
    luck_lifetime: "56",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DELTA POOL",
    icon: "https://cdn.adapools.org/pool_logo/a49bc4fe3dbde289bc359a4aa09a40bc140daace4e95abb7a6b2e652.png",
    pool_id: "a49bc4fe3dbde289bc359a4aa09a40bc140daace4e95abb7a6b2e652",
    db_ticker: "DELTA",
    db_name: "DELTA POOL",
    db_url: "https://adadeltapool.com",
    roa: "0",
    roa_lifetime: "4.286",
    total_stake: "1962084939",
    stake_x_deleg: "327014156.5",
    saturated: "0.000028541877320419",
    luck_lifetime: "108",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " KysenPool Thunder ",
    icon: "https://cdn.adapools.org/pool_logo/490353aa6b85efb28922acd9e0ee1dcf6d0c269b9f0583718b0274ba.png",
    pool_id: "490353aa6b85efb28922acd9e0ee1dcf6d0c269b9f0583718b0274ba",
    db_ticker: "KYSN",
    db_name: " KysenPool Thunder ",
    db_url: "https://kysenpool.io",
    roa: "3.659",
    roa_lifetime: "4.918",
    total_stake: "16615116381586",
    stake_x_deleg: "7702881957.1562",
    saturated: "0.24169525182197",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "3592",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GACHI pool",
    icon: "0",
    pool_id: "08635d4c628b1d427241621855fe2cbb66bce416069b725921a4285a",
    db_ticker: "GACHI",
    db_name: "GACHI pool",
    db_url: "http://www.gachipool.com",
    roa: "2.56",
    roa_lifetime: "2.786",
    total_stake: "102836128119",
    stake_x_deleg: "6427258007.4375",
    saturated: "0.0014959271612244",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "37",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PhillyStake",
    icon: "https://cdn.adapools.org/pool_logo/35da8aa474f37a5025658da17218bebbe7e9d680bf44857313ae4e28.png",
    pool_id: "35da8aa474f37a5025658da17218bebbe7e9d680bf44857313ae4e28",
    db_ticker: "PHL",
    db_name: "PhillyStake",
    db_url: "https://phillystake.com/cardano",
    roa: "0",
    roa_lifetime: "2.926",
    total_stake: "52123032108",
    stake_x_deleg: "10424606421.6",
    saturated: "0.00075821854519357",
    luck_lifetime: "125",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MARTE Pool",
    icon: "https://cdn.adapools.org/pool_logo/9062e5b6e80e79a02691e05d41f03d0397ff044cc738424de93f2264.png",
    pool_id: "9062e5b6e80e79a02691e05d41f03d0397ff044cc738424de93f2264",
    db_ticker: "MARTE",
    db_name: "MARTE Pool",
    db_url: "https://www.martestaking.eu",
    roa: "0",
    roa_lifetime: "1.939",
    total_stake: "26646960515",
    stake_x_deleg: "5329392103",
    saturated: "0.0003876255624126",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The ADA Put a stake in it pool.",
    icon: "0",
    pool_id: "7811d7ce6a7ae17b5197b8e27b389ee99f0a9c152891dcb15541b75d",
    db_ticker: "ADAIO",
    db_name: "The ADA Put a stake in it pool.",
    db_url: "https://www.adaio.io",
    roa: "0",
    roa_lifetime: "2.587",
    total_stake: "124426972317",
    stake_x_deleg: "6221348615.85",
    saturated: "0.0018100028743062",
    luck_lifetime: "120",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MAL2 - Retiring Maladex's Pool.",
    icon: "https://cdn.adapools.org/pool_logo/15343989c5a8cc6dd36eb10c96b9399512dbbede7d3480434eb8ceb2.png",
    pool_id: "15343989c5a8cc6dd36eb10c96b9399512dbbede7d3480434eb8ceb2",
    db_ticker: "MAL2",
    db_name: "MAL2 - Retiring Maladex's Pool.",
    db_url: "https://maladex.com",
    roa: "0",
    roa_lifetime: "3.418",
    total_stake: "232649520321",
    stake_x_deleg: "1344794915.1503",
    saturated: "0.0033842847145244",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "228",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Armada Cardano",
    icon: "https://cdn.adapools.org/pool_logo/532d0ff7ce3720cb99ebbd7377f7af0221b2c1102f2315ea0f062342.png",
    pool_id: "532d0ff7ce3720cb99ebbd7377f7af0221b2c1102f2315ea0f062342",
    db_ticker: "ARMDA",
    db_name: "Armada Cardano",
    db_url: "https://armadacardano.io",
    roa: "1.16",
    roa_lifetime: "2.106",
    total_stake: "923901478951",
    stake_x_deleg: "41995521770.5",
    saturated: "0.013439725337178",
    luck_lifetime: "79",
    blocks_epoch: "1",
    blocks_lifetime: "43",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sushi Pool",
    icon: "0",
    pool_id: "23c3d7bcf08c81925998f577d1bfd35e522ac1da93df1692a12d1e35",
    db_ticker: "SUSHI",
    db_name: "Sushi Pool",
    db_url: "https://cardanosushi.com",
    roa: "0",
    roa_lifetime: "0.685",
    total_stake: "88107746022",
    stake_x_deleg: "4405387301.1",
    saturated: "0.0012816776827308",
    luck_lifetime: "25",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Game Industrial Japan Pool ",
    icon: "https://cdn.adapools.org/pool_logo/9b922e2f7bd657422f5b6db4d8fe14f013b74b2fbbb1908e2d9e8fbe.png",
    pool_id: "9b922e2f7bd657422f5b6db4d8fe14f013b74b2fbbb1908e2d9e8fbe",
    db_ticker: "GAIN💎",
    db_name: "Game Industrial Japan Pool ",
    db_url: "https://twitter.com/GAIN_SPO",
    roa: "0",
    roa_lifetime: "3.445",
    total_stake: "102564533004",
    stake_x_deleg: "20512906600.8",
    saturated: "0.0014919763463034",
    luck_lifetime: "148",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BananaBit Pool",
    icon: "0",
    pool_id: "d397ad89845f0b6c9347ca59ec1b424d8137b4b617e6bf57582718f1",
    db_ticker: "BANAN",
    db_name: "BananaBit Pool",
    db_url: "https://adapool.bananabit.net",
    roa: "0",
    roa_lifetime: "1.6",
    total_stake: "292872105048",
    stake_x_deleg: "41838872149.714",
    saturated: "0.0042603250892457",
    luck_lifetime: "58",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Institute Ecoterm Modular",
    icon: "https://cdn.adapools.org/pool_logo/322b4060366aa7c5c067b08e37bf64f935444d806db324f7c38a91aa.png",
    pool_id: "322b4060366aa7c5c067b08e37bf64f935444d806db324f7c38a91aa",
    db_ticker: "TERRA",
    db_name: "Institute Ecoterm Modular",
    db_url: "https://ecoterm.org",
    roa: "3.333",
    roa_lifetime: "1.621",
    total_stake: "95183974252",
    stake_x_deleg: "5287998569.5556",
    saturated: "0.0013846135108479",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanian Korea Pool",
    icon: "0",
    pool_id: "fa04322f4f5e2c394033f09e83703adc3501b546dafe5279cc5d1303",
    db_ticker: "CKP",
    db_name: "Cardanian Korea Pool",
    db_url: "https://ckpool.io",
    roa: "0.983",
    roa_lifetime: "1.193",
    total_stake: "174973906802",
    stake_x_deleg: "370707429.66525",
    saturated: "0.0025452943870832",
    luck_lifetime: "40",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Ka-tet - Free Stake Pool",
    icon: "0",
    pool_id: "e768a8d7bef0345cb0f439dc1ec0ac7c293188fcc18f60f5208a850f",
    db_ticker: "KATET",
    db_name: "Cardano Ka-tet - Free Stake Pool",
    db_url: "https://CardanoKatet.com",
    roa: "0",
    roa_lifetime: "0.609",
    total_stake: "58983961114",
    stake_x_deleg: "3932264074.2667",
    saturated: "0.00085802247829606",
    luck_lifetime: "25",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake Pool in Geneva",
    icon: "0",
    pool_id: "7a173fdf2ceee8a24ddc173be6810cf3259a80fe02a8d10923921d17",
    db_ticker: "STKCH",
    db_name: "Stake Pool in Geneva",
    db_url: "https://cardanoadapool.wixsite.com/pvtstakepool",
    roa: "0",
    roa_lifetime: "1.794",
    total_stake: "93135157249",
    stake_x_deleg: "31045052416.333",
    saturated: "0.0013548099674898",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "92000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Token Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/1e5f4eebc56a6726fd36af2f870b3bd62b204f3cd1b36e32e8a56737.png",
    pool_id: "1e5f4eebc56a6726fd36af2f870b3bd62b204f3cd1b36e32e8a56737",
    db_ticker: "TOKEN",
    db_name: "Token Stake Pool",
    db_url: "https://token.stakepool.cc",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20503850232",
    stake_x_deleg: "427163546.5",
    saturated: "0.00029826352890525",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Nobes Official Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ae179bd67c71180eb7ace4ba93808fa214f3935a6b7785d58ef8b839.png",
    pool_id: "ae179bd67c71180eb7ace4ba93808fa214f3935a6b7785d58ef8b839",
    db_ticker: "NOBES",
    db_name: "Crypto Nobes Official Stake Pool",
    db_url: "http://bit.ly/CryptoNOBES",
    roa: "2.627",
    roa_lifetime: "3.341",
    total_stake: "133843526563",
    stake_x_deleg: "1503859849.0225",
    saturated: "0.0019469827423681",
    luck_lifetime: "78",
    blocks_epoch: "0",
    blocks_lifetime: "47",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "7Pool",
    icon: "0",
    pool_id: "af2483fd46d277496dfe937524aecb5c68d142794fd52d98ad3b075c",
    db_ticker: "7PL",
    db_name: "7Pool",
    db_url: "https://7pool.io/",
    roa: "2.563",
    roa_lifetime: "1.762",
    total_stake: "124491146406",
    stake_x_deleg: "889222474.32857",
    saturated: "0.0018109363960611",
    luck_lifetime: "59",
    blocks_epoch: "1",
    blocks_lifetime: "6",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "7000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AVA",
    icon: "0",
    pool_id: "4fde86a71b122b24c6ee3c4b90b579e745b546694d6ac6b7a17bc6f8",
    db_ticker: "AVA",
    db_name: "AVA",
    db_url: "https://www.AVAcsp.com",
    roa: "1.343",
    roa_lifetime: "2.672",
    total_stake: "58642655245",
    stake_x_deleg: "3665165952.8125",
    saturated: "0.00085305760136942",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20022022002",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Khan Pool",
    icon: "0",
    pool_id: "98d41fc48036a970688a19f93fcc79926c40e6e7a8b224d6bcf23f81",
    db_ticker: "KHAN",
    db_name: "Khan Pool",
    db_url: "https://khanpool.com",
    roa: "2.974",
    roa_lifetime: "3.963",
    total_stake: "118526774837",
    stake_x_deleg: "3386479281.0571",
    saturated: "0.001724174422493",
    luck_lifetime: "91",
    blocks_epoch: "1",
    blocks_lifetime: "248",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "26000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SHOP Pool",
    icon: "0",
    pool_id: "76ce5348012e971dff93887b4870dd8aeb0a7ffc02a25ccdf337fc1c",
    db_ticker: "SHOP",
    db_name: "SHOP Pool",
    db_url: "https://github.com/uniVocity/shopify",
    roa: "2.428",
    roa_lifetime: "4.606",
    total_stake: "150636996851",
    stake_x_deleg: "1407822400.4766",
    saturated: "0.0021912724564457",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "367",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "David Shin",
    icon: "https://cdn.adapools.org/pool_logo/40e81ed617140994bab5a00619d0269f0dd3af9963e40e09f5ee1a6d.png",
    pool_id: "40e81ed617140994bab5a00619d0269f0dd3af9963e40e09f5ee1a6d",
    db_ticker: "DSHIN",
    db_name: "David Shin",
    db_url: "https://davidshin.kr",
    roa: "3.267",
    roa_lifetime: "3.715",
    total_stake: "14931258749130",
    stake_x_deleg: "4939218904.7734",
    saturated: "0.21720066597846",
    luck_lifetime: "93",
    blocks_epoch: "13",
    blocks_lifetime: "1099",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hamster Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/bb32c21c1ca7dc8f73fad1b6263987139a868fad820b3c3069fefcd6.png",
    pool_id: "bb32c21c1ca7dc8f73fad1b6263987139a868fad820b3c3069fefcd6",
    db_ticker: "HAMDA",
    db_name: "Hamster Stake Pool",
    db_url: "https://hamster-stake-pool.de",
    roa: "3.333",
    roa_lifetime: "2.616",
    total_stake: "71037670636",
    stake_x_deleg: "10148238662.286",
    saturated: "0.0010333642749709",
    luck_lifetime: "121",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "29270000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "David Shin 2",
    icon: "https://cdn.adapools.org/pool_logo/b4e403540bc4aec00e7b44224c612c97f594fe8c1e11917276581903.png",
    pool_id: "b4e403540bc4aec00e7b44224c612c97f594fe8c1e11917276581903",
    db_ticker: "DSHN2",
    db_name: "David Shin 2",
    db_url: "https://davidshin.kr",
    roa: "2.594",
    roa_lifetime: "3.687",
    total_stake: "2498574461661",
    stake_x_deleg: "6064501120.5364",
    saturated: "0.036346033927056",
    luck_lifetime: "102",
    blocks_epoch: "4",
    blocks_lifetime: "162",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FAST Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/a915178a0195af9166fccd42a69407f1af109faf64c3c409ab2790ec.png",
    pool_id: "a915178a0195af9166fccd42a69407f1af109faf64c3c409ab2790ec",
    db_ticker: "FAST",
    db_name: "FAST Stakepool",
    db_url: "https://www.faststakepool.com",
    roa: "0",
    roa_lifetime: "4.098",
    total_stake: "12984096605",
    stake_x_deleg: "2596819321",
    saturated: "0.00018887586620244",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "12000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Decentralization for better world",
    icon: "0",
    pool_id: "5a272ee0f96ef8f0ad0209b11fa2812f9eedbc68ed303367fc50bcfb",
    db_ticker: "SONLA",
    db_name: "Decentralization for better world",
    db_url: "http://sonla.online",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "261250220024",
    stake_x_deleg: "52250044004.8",
    saturated: "0.0038003307510519",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "H3ART POOL",
    icon: "https://cdn.adapools.org/pool_logo/5362bbd0f44754ea1639e50409dbb784ff81482036772c3c17b73dd7.png",
    pool_id: "5362bbd0f44754ea1639e50409dbb784ff81482036772c3c17b73dd7",
    db_ticker: "H3ART",
    db_name: "H3ART POOL",
    db_url: "https://h3artpool.com",
    roa: "0",
    roa_lifetime: "2.706",
    total_stake: "10320706257",
    stake_x_deleg: "2064141251.4",
    saturated: "0.00015013230364915",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Knights of Cardano",
    icon: "https://cdn.adapools.org/pool_logo/41ddaaf2303176365dc36a58483aa8aba694da490d04d84783d418e1.png",
    pool_id: "41ddaaf2303176365dc36a58483aa8aba694da490d04d84783d418e1",
    db_ticker: "KNGHT",
    db_name: "Knights of Cardano",
    db_url: "https://knightsofcardano.com",
    roa: "0",
    roa_lifetime: "0.644",
    total_stake: "15959863424",
    stake_x_deleg: "3191972684.8",
    saturated: "0.00023216347816757",
    luck_lifetime: "49",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DROPS",
    icon: "https://cdn.adapools.org/pool_logo/111fb41d77dd7bcae01d15102af6e5dc3472d72a602df2f75f570156.png",
    pool_id: "111fb41d77dd7bcae01d15102af6e5dc3472d72a602df2f75f570156",
    db_ticker: "DROPS",
    db_name: "DROPS",
    db_url: "https://www.appledrops.de",
    roa: "0",
    roa_lifetime: "4.457",
    total_stake: "189888601091",
    stake_x_deleg: "37977720218.2",
    saturated: "0.0027622540946915",
    luck_lifetime: "135",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hephaestus Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/f4ebee070198a0db41e46d5e59a7c582357013d15b907f65ad75d55e.png",
    pool_id: "f4ebee070198a0db41e46d5e59a7c582357013d15b907f65ad75d55e",
    db_ticker: "HEPHY",
    db_name: "Hephaestus Stake Pool",
    db_url: "https://hephy.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "74346184799",
    stake_x_deleg: "8260687199.8889",
    saturated: "0.0010814922654958",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0249",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amantis.club",
    icon: "https://cdn.adapools.org/pool_logo/5912d220f45d1ff968b9ba3682bf3530f44aaaf9d05670b37de1d943.png",
    pool_id: "5912d220f45d1ff968b9ba3682bf3530f44aaaf9d05670b37de1d943",
    db_ticker: "AMANT",
    db_name: "Amantis.club",
    db_url: "https://amantis.club",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "57865733819",
    stake_x_deleg: "1091806298.4717",
    saturated: "0.00084175595199241",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "After School Labs",
    icon: "0",
    pool_id: "1e29d8901dabb4556aa564fa2c3a972f459fb71146348b36c5c2ff31",
    db_ticker: "ASLAB",
    db_name: "After School Labs",
    db_url: "https://cardano.afterschoollabs.io",
    roa: "1.092",
    roa_lifetime: "1.888",
    total_stake: "331548723683",
    stake_x_deleg: "17449932825.421",
    saturated: "0.0048229425796034",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "38",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Prosperia Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/258e41d1df2d51ca3669c5a2701cbfc22f6da1a7ee4961ff7e74418f.png",
    pool_id: "258e41d1df2d51ca3669c5a2701cbfc22f6da1a7ee4961ff7e74418f",
    db_ticker: "PROSP",
    db_name: "Prosperia Stake Pool",
    db_url: "https://prosperspective.com",
    roa: "1.223",
    roa_lifetime: "1.766",
    total_stake: "383833893659",
    stake_x_deleg: "1958336192.1378",
    saturated: "0.0055835196970715",
    luck_lifetime: "87",
    blocks_epoch: "1",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swiss Cardano Staking",
    icon: "https://cdn.adapools.org/pool_logo/47fffd661662289acb190d16306100e9c41cd92c3b4fdd48611b30ee.png",
    pool_id: "47fffd661662289acb190d16306100e9c41cd92c3b4fdd48611b30ee",
    db_ticker: "SCS",
    db_name: "Swiss Cardano Staking",
    db_url: "https://swisscardanostaking.ch",
    roa: "0",
    roa_lifetime: "1.235",
    total_stake: "107871102204",
    stake_x_deleg: "21574220440.8",
    saturated: "0.0015691694606728",
    luck_lifetime: "33",
    blocks_epoch: "2",
    blocks_lifetime: "2",
    tax_ratio: "0.025",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kriptolando stake pool",
    icon: "https://cdn.adapools.org/pool_logo/41b9165ae775d044362adc8d0fd1776f8275018531109b3210e95fb2.png",
    pool_id: "41b9165ae775d044362adc8d0fd1776f8275018531109b3210e95fb2",
    db_ticker: "STELO",
    db_name: "Kriptolando stake pool",
    db_url: "https://kriptolando.net",
    roa: "0",
    roa_lifetime: "2.165",
    total_stake: "31111566191",
    stake_x_deleg: "777789154.775",
    saturated: "0.00045257087897641",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goat Stake",
    icon: "https://cdn.adapools.org/pool_logo/9d15bc119ffeaee3eaa6581a00124e968b48f41c049fcac951f1debc.png",
    pool_id: "9d15bc119ffeaee3eaa6581a00124e968b48f41c049fcac951f1debc",
    db_ticker: "GOAT5",
    db_name: "Goat Stake",
    db_url: "https://goatstake.com",
    roa: "0",
    roa_lifetime: "3.639",
    total_stake: "12392861078",
    stake_x_deleg: "2478572215.6",
    saturated: "0.00018027533543861",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "61",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NEPTUNE Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ef603340ac08cbe1cbe003821a2272e15612b50b86c0066e043dc64e.png",
    pool_id: "ef603340ac08cbe1cbe003821a2272e15612b50b86c0066e043dc64e",
    db_ticker: "NEP",
    db_name: "NEPTUNE Stake Pool",
    db_url: "https://neptune-pool.com/",
    roa: "0",
    roa_lifetime: "4.075",
    total_stake: "4559643520",
    stake_x_deleg: "911928704",
    saturated: "0.000066327804360504",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "71",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FightPool",
    icon: "0",
    pool_id: "45956dccec3785e88f7b4a2be09b6f8e25add18fd00125a33cd96931",
    db_ticker: "FIGHT",
    db_name: "FightPool",
    db_url: "https://fightpool.io",
    roa: "0",
    roa_lifetime: "1.653",
    total_stake: "266973535056",
    stake_x_deleg: "5803772501.2174",
    saturated: "0.0038835861454859",
    luck_lifetime: "61",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ellipal wallet",
    icon: "0",
    pool_id: "879ad8338e795acb7b2bd2cf5532ed4e3603962439c91cb0bd5ae7af",
    db_ticker: "ELLIP",
    db_name: "Ellipal wallet",
    db_url: "https://www.ellipal.com/pages/ellipal-staking",
    roa: "2.033",
    roa_lifetime: "3.031",
    total_stake: "2339823438641",
    stake_x_deleg: "3817003978.2072",
    saturated: "0.034036729098572",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "135",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Julien Aklei",
    icon: "0",
    pool_id: "1f973a6bf2a4bcbcf844945cc04dee238eb1c505d62eed7dd301a5b4",
    db_ticker: "AKLEI",
    db_name: "Julien Aklei",
    db_url: "https://www.julienaklei.com/cardano",
    roa: "0",
    roa_lifetime: "2.35",
    total_stake: "96680002593",
    stake_x_deleg: "3333793192.8621",
    saturated: "0.0014063757987733",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.024",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CLYDEdev Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/fab24ffce0611c290e10211cac009b1366ae1cece4a1c57d110ddfa1.png",
    pool_id: "fab24ffce0611c290e10211cac009b1366ae1cece4a1c57d110ddfa1",
    db_ticker: "CLYDE",
    db_name: "CLYDEdev Stake Pool",
    db_url: "https://spo.clydedev.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46743986106",
    stake_x_deleg: "1198563746.3077",
    saturated: "0.000679971131925",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Kibatsu Club Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/72bbf0816bef3e1e5b0313e457aad63bb60d1c0506e8fc6a61e9036f.png",
    pool_id: "72bbf0816bef3e1e5b0313e457aad63bb60d1c0506e8fc6a61e9036f",
    db_ticker: "KIBA",
    db_name: "The Kibatsu Club Stake Pool",
    db_url: "https://www.kibatsu.club",
    roa: "3.121",
    roa_lifetime: "1.99",
    total_stake: "484045205081",
    stake_x_deleg: "121011301270.25",
    saturated: "0.0070412644153928",
    luck_lifetime: "77",
    blocks_epoch: "1",
    blocks_lifetime: "21",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CHF SWISS",
    icon: "0",
    pool_id: "5a7d13018a8d4995606de677208f3ce57291b53b0cfb1f95ae0115c2",
    db_ticker: "CHF",
    db_name: "CHF SWISS",
    db_url: "https://chfpool.wordpress.com/",
    roa: "0",
    roa_lifetime: "1.161",
    total_stake: "91716760832",
    stake_x_deleg: "18343352166.4",
    saturated: "0.0013341769685197",
    luck_lifetime: "48",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CHARM STAKE POOL",
    icon: "0",
    pool_id: "88afe2e00c8923ac22d31750eca02723edc167ea93eba96f812faf79",
    db_ticker: "CHARM",
    db_name: "CHARM STAKE POOL",
    db_url: "https://www.cardanoromania.ro",
    roa: "0",
    roa_lifetime: "4.497",
    total_stake: "79884555487",
    stake_x_deleg: "2048321935.5641",
    saturated: "0.0011620573285009",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "194",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "STacking_PiZza",
    icon: "https://cdn.adapools.org/pool_logo/f218d7c550fecc856bdf09c6ec12d64f68a59b6727f29bedb06d88b1.png",
    pool_id: "f218d7c550fecc856bdf09c6ec12d64f68a59b6727f29bedb06d88b1",
    db_ticker: "STPZ1",
    db_name: "STacking_PiZza",
    db_url: "https://www.facebook.com/STPZpool/",
    roa: "4.455",
    roa_lifetime: "3.735",
    total_stake: "2436550365439",
    stake_x_deleg: "39943448613.754",
    saturated: "0.035443787490069",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "120",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2501000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PCS is the stake pool of the Puurrty Cats Society",
    icon: "https://cdn.adapools.org/pool_logo/883eecf210abca18ca7c05dbdd11d976e098817159031d8945a772c4.png",
    pool_id: "883eecf210abca18ca7c05dbdd11d976e098817159031d8945a772c4",
    db_ticker: "PCS",
    db_name: "PCS is the stake pool of the Puurrty Cats Society",
    db_url: "https://puurrty.io",
    roa: "3.333",
    roa_lifetime: "5.524",
    total_stake: "56571062469",
    stake_x_deleg: "2175810094.9615",
    saturated: "0.00082292274548464",
    luck_lifetime: "311",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TAVN Viet Nam Stake Pool",
    icon: "0",
    pool_id: "0a744b89a32b0385d0788b56ec8e1fafc9c47c277feb2c6bd64a8125",
    db_ticker: "TAVN",
    db_name: "TAVN Viet Nam Stake Pool",
    db_url: "https://adavn.com",
    roa: "1.795",
    roa_lifetime: "3.793",
    total_stake: "2123454962170",
    stake_x_deleg: "2621549336.0123",
    saturated: "0.030889279980193",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "292",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SOCK STAKE",
    icon: "https://cdn.adapools.org/pool_logo/564e6152a892f02d3456bf3a5e00ad0aa3806369d1b152a8abae435b.png",
    pool_id: "564e6152a892f02d3456bf3a5e00ad0aa3806369d1b152a8abae435b",
    db_ticker: "SOCK",
    db_name: "SOCK STAKE",
    db_url: "https://sockstake.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18094257095",
    stake_x_deleg: "623939899.82759",
    saturated: "0.00026321188035459",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "台灣艾達幣(ADA)權益池 | CARDANO STAKEPOOL TAIWAN",
    icon: "0",
    pool_id: "e04defbc8381975f96aba1938b76f2d6fc15d1a1dccdae69f091b73a",
    db_ticker: "TEN",
    db_name: "台灣艾達幣(ADA)權益池 | CARDANO STAKEPOOL TAIWAN",
    db_url: "https://cardano.tw",
    roa: "0",
    roa_lifetime: "0.894",
    total_stake: "53019820620",
    stake_x_deleg: "1893565022.1429",
    saturated: "0.00077126386610863",
    luck_lifetime: "42",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Darkartopia Family",
    icon: "https://cdn.adapools.org/pool_logo/4d9333fceb79761fb826b22395c4bff84740fbfa8af232d6f6944eb7.png",
    pool_id: "4d9333fceb79761fb826b22395c4bff84740fbfa8af232d6f6944eb7",
    db_ticker: "FAM",
    db_name: "Darkartopia Family",
    db_url: "http://darkartopia.art/",
    roa: "0",
    roa_lifetime: "1.969",
    total_stake: "30719927407",
    stake_x_deleg: "2363071339",
    saturated: "0.00044687382381603",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Coinpayu",
    icon: "0",
    pool_id: "a6e5b851154535a57563313c45e1f7936113db705a43f6f5863f66a2",
    db_ticker: "CPU1",
    db_name: "Coinpayu",
    db_url: "https://www.coinpayu.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "62524774436",
    stake_x_deleg: "1202399508.3846",
    saturated: "0.00090952965693152",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "cardanoYYC",
    icon: "https://cdn.adapools.org/pool_logo/1795531808124bd8bf39943b5cb453db0f74115e840c04e02015550c.png",
    pool_id: "1795531808124bd8bf39943b5cb453db0f74115e840c04e02015550c",
    db_ticker: "YYC",
    db_name: "cardanoYYC",
    db_url: "https://bit.ly/3cRZRen",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46250942670",
    stake_x_deleg: "2720643686.4706",
    saturated: "0.00067279897286896",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zero Fees",
    icon: "https://cdn.adapools.org/pool_logo/d1a8987c096237020fb566c2ce081e0d122cfe6aabf33635c9daa418.png",
    pool_id: "d1a8987c096237020fb566c2ce081e0d122cfe6aabf33635c9daa418",
    db_ticker: "0FEES",
    db_name: "Zero Fees",
    db_url: "https://www.zerofeespool.com",
    roa: "0",
    roa_lifetime: "1.745",
    total_stake: "39999660253",
    stake_x_deleg: "1538448471.2692",
    saturated: "0.00058186339087921",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RetroNFTs.art Pool",
    icon: "https://cdn.adapools.org/pool_logo/383ee9f587c77c5683eea207359749784d9af5cd2306d099b9397f3f.png",
    pool_id: "383ee9f587c77c5683eea207359749784d9af5cd2306d099b9397f3f",
    db_ticker: "RNFT",
    db_name: "RetroNFTs.art Pool",
    db_url: "https://retronfts.art",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "78401735913",
    stake_x_deleg: "4611866818.4118",
    saturated: "0.0011404871846563",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Vivian's Pool",
    icon: "0",
    pool_id: "993622c4a6d00d30b5a85550480a9d3af64aa49fac91a644e93f1074",
    db_ticker: "VIV",
    db_name: "Vivian's Pool",
    db_url: "http://www.vivpool.com",
    roa: "3.333",
    roa_lifetime: "1.056",
    total_stake: "58485412363",
    stake_x_deleg: "6498379151.4444",
    saturated: "0.00085077023502847",
    luck_lifetime: "54",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "90000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Upstream",
    icon: "https://cdn.adapools.org/pool_logo/b67b0635ab2b26df9e44ba14fac3560720ca0902446d68fe282d4b97.png",
    pool_id: "b67b0635ab2b26df9e44ba14fac3560720ca0902446d68fe282d4b97",
    db_ticker: "UPSTR",
    db_name: "Upstream",
    db_url: "https://upstream.org.uk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "132363943370",
    stake_x_deleg: "6016542880.4545",
    saturated: "0.001925459677214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "9000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dawnstar Pool",
    icon: "0",
    pool_id: "4d044cd3d376a701e056b0ac169face93a3f4aa7149d40f159a5a278",
    db_ticker: "TRUCK",
    db_name: "Dawnstar Pool",
    db_url: "https://dawnstarcorporation.com",
    roa: "0",
    roa_lifetime: "1.959",
    total_stake: "89538257633",
    stake_x_deleg: "8139841603",
    saturated: "0.0013024869178944",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "God BlESS You",
    icon: "0",
    pool_id: "bb224ee756ce73c513d9e43323560e6ab644db83fb57eff613503953",
    db_ticker: "BLESS",
    db_name: "God BlESS You",
    db_url:
      "https://sites.google.com/view/poolblessvenezuela/p%C3%A1gina-principal",
    roa: "1.855",
    roa_lifetime: "4.082",
    total_stake: "193748039014",
    stake_x_deleg: "2061149351.2128",
    saturated: "0.0028183962124635",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "183",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "dataDyne.earth",
    icon: "https://cdn.adapools.org/pool_logo/8dcdf33740ee8e9da6e36337d875fb9222f5c8a1a315fda36886c615.png",
    pool_id: "8dcdf33740ee8e9da6e36337d875fb9222f5c8a1a315fda36886c615",
    db_ticker: "dtDn",
    db_name: "dataDyne.earth",
    db_url: "http://dataDyne.earth/cardano",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "76692034339",
    stake_x_deleg: "12782005723.167",
    saturated: "0.0011156166545332",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "62000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mooney Mint",
    icon: "0",
    pool_id: "6b529e9dc00b3d7451296f55b538abf5074fc3dc82cefdeac575d74f",
    db_ticker: "MMINT",
    db_name: "Mooney Mint",
    db_url: "https://MooneyMint.com",
    roa: "3.353",
    roa_lifetime: "1.997",
    total_stake: "769278466795",
    stake_x_deleg: "30771138671.8",
    saturated: "0.011190469478703",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeLove Japan",
    icon: "0",
    pool_id: "6226fce477c40a148f695fcd2de150dbd7566ee30137d7deda8071a4",
    db_ticker: "LOVE",
    db_name: "StakeLove Japan",
    db_url: "https://pooltool.io/about",
    roa: "0",
    roa_lifetime: "4.682",
    total_stake: "153084676289",
    stake_x_deleg: "10205645085.933",
    saturated: "0.0022268781353082",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "202",
    tax_ratio: "0.0025",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GDeFi Stake Pool",
    icon: "0",
    pool_id: "9f35856900fe897987a8807535bc37c474c4c874014cfce47ba52354",
    db_ticker: "GDEFI",
    db_name: "GDeFi Stake Pool",
    db_url: "https://gdefipool.com",
    roa: "0",
    roa_lifetime: "3.025",
    total_stake: "85336139259",
    stake_x_deleg: "5689075950.6",
    saturated: "0.0012413599275523",
    luck_lifetime: "139",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoBits Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/6782865c91a230fb379a433a0630a68a9adcdca9e518ad73302ef805.png",
    pool_id: "6782865c91a230fb379a433a0630a68a9adcdca9e518ad73302ef805",
    db_ticker: "CBITS",
    db_name: "CardanoBits Stake Pool",
    db_url: "https://cardanobits.art",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "15735198444",
    stake_x_deleg: "874177691.33333",
    saturated: "0.00022889534223222",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "7500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PetLoverStake",
    icon: "https://cdn.adapools.org/pool_logo/d8cc8d4198e23dbc5685c6682e6ea06e82fbd93879e42086e64ccc21.png",
    pool_id: "d8cc8d4198e23dbc5685c6682e6ea06e82fbd93879e42086e64ccc21",
    db_ticker: "PET♥",
    db_name: "PetLoverStake",
    db_url: "https://petloverstake.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12462847995",
    stake_x_deleg: "655939368.15789",
    saturated: "0.00018129341470691",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stale Pool",
    icon: "0",
    pool_id: "b92012c1afbd6c7ffcf034fefb4500eada5e98529f4d0f54e7db8a81",
    db_ticker: "STALE",
    db_name: "Stale Pool",
    db_url: "https://discord.com/invite/yA9cAtD3et",
    roa: "1.363",
    roa_lifetime: "3.42",
    total_stake: "748580553382",
    stake_x_deleg: "2346647502.7649",
    saturated: "0.010889382969307",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "185",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Candlestake Pool",
    icon: "0",
    pool_id: "aa3f700fc539642a938fbcc8b0bb4a37c0fd6b5023fd412f89693aa2",
    db_ticker: "CNDL",
    db_name: "Candlestake Pool",
    db_url: "https://www.candlestake.com",
    roa: "3.333",
    roa_lifetime: "2.032",
    total_stake: "201591110919",
    stake_x_deleg: "10610058469.421",
    saturated: "0.0029324870918532",
    luck_lifetime: "125",
    blocks_epoch: "1",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Infinity",
    icon: "https://cdn.adapools.org/pool_logo/d0c7cae9b825957a024e0691333b9b6c8e2b4a53b99ab6071f200f7d.png",
    pool_id: "d0c7cae9b825957a024e0691333b9b6c8e2b4a53b99ab6071f200f7d",
    db_ticker: "INFTY",
    db_name: "Infinity",
    db_url: "https://www.infinityfos.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1154920578",
    stake_x_deleg: "64162254.333333",
    saturated: "0.000016800292789008",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "20000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "African Charity Pool #1",
    icon: "0",
    pool_id: "d27af8f35c3d336500ff190011cd01edd1f84254002fad053d326356",
    db_ticker: "AFCH1",
    db_name: "African Charity Pool #1",
    db_url: "https://smilespool.com",
    roa: "4.485",
    roa_lifetime: "0.301",
    total_stake: "5092786481201",
    stake_x_deleg: "4074229184.9608",
    saturated: "0.074083279513684",
    luck_lifetime: "107",
    blocks_epoch: "6",
    blocks_lifetime: "676",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " PLANETSTAKE 3",
    icon: "https://cdn.adapools.org/pool_logo/266601f53114e6ff036c5b4ee42a2926e037b21bcf864557d41d4ecb.png",
    pool_id: "266601f53114e6ff036c5b4ee42a2926e037b21bcf864557d41d4ecb",
    db_ticker: "STAKE",
    db_name: " PLANETSTAKE 3",
    db_url: "https://planetstake.com",
    roa: "0",
    roa_lifetime: "4.414",
    total_stake: "7126739274",
    stake_x_deleg: "419219957.29412",
    saturated: "0.0001036705975414",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "57",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AGUA",
    icon: "https://cdn.adapools.org/pool_logo/751d0f6979f4432be43512cfd35d43d3b02dbd4a7a0352a7699cb626.png",
    pool_id: "751d0f6979f4432be43512cfd35d43d3b02dbd4a7a0352a7699cb626",
    db_ticker: "AGUA",
    db_name: "AGUA",
    db_url: "https://agua.gratis",
    roa: "0",
    roa_lifetime: "1.618",
    total_stake: "59704269026",
    stake_x_deleg: "7463033628.25",
    saturated: "0.00086850058739754",
    luck_lifetime: "68",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADARockPool",
    icon: "0",
    pool_id: "39025777f1d7c42746bc961b03b1690c26db08153d458dc74fcb034b",
    db_ticker: "AROCK",
    db_name: "ADARockPool",
    db_url: "https://adarockpool.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10761386798",
    stake_x_deleg: "633022752.82353",
    saturated: "0.00015654275494446",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HAN",
    icon: "https://cdn.adapools.org/pool_logo/aa5bd21fb25c3424a95707a463bcc5f58d3352ac549ff9a4030f41f6.png",
    pool_id: "aa5bd21fb25c3424a95707a463bcc5f58d3352ac549ff9a4030f41f6",
    db_ticker: "HAN",
    db_name: "HAN",
    db_url: "https://git.io/JPW0U",
    roa: "3.333",
    roa_lifetime: "1.349",
    total_stake: "47476429845",
    stake_x_deleg: "2967276865.3125",
    saturated: "0.00069062577736216",
    luck_lifetime: "68",
    blocks_epoch: "1",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "African Charity Pool #2",
    icon: "0",
    pool_id: "4c419cdf15bae400b98aa9ec9a7d3de6dafc9eb258b31f172c891dd5",
    db_ticker: "AFCH2",
    db_name: "African Charity Pool #2",
    db_url: "https://poapool.com",
    roa: "2.498",
    roa_lifetime: "0.321",
    total_stake: "3315411767531",
    stake_x_deleg: "8145974858.7985",
    saturated: "0.048228327966154",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "241",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " SANTA dream a dream",
    icon: "0",
    pool_id: "245250300ae6d209edee53fa4e18ffc0b17c76d78b28e1565411cbd0",
    db_ticker: "SANTA",
    db_name: " SANTA dream a dream",
    db_url: "https://en.thuvienuocmo.org",
    roa: "0",
    roa_lifetime: "2.279",
    total_stake: "57653722512",
    stake_x_deleg: "576537225.12",
    saturated: "0.00083867188534745",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "25100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MonedaCloud",
    icon: "https://cdn.adapools.org/pool_logo/64a4392e99179be25e27a31a6f9e3b31111d761e3d301a7e8f6e9d09.png",
    pool_id: "64a4392e99179be25e27a31a6f9e3b31111d761e3d301a7e8f6e9d09",
    db_ticker: "MOC",
    db_name: "MonedaCloud",
    db_url: "https://monedacloud.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "35003983336",
    stake_x_deleg: "3500398333.6",
    saturated: "0.00050919273587172",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Diing staking! ",
    icon: "0",
    pool_id: "65c525e07820894fb5f4dc8f96929d5c7c6aa3cde1f8c6420f8faead",
    db_ticker: "DIING",
    db_name: "Diing staking! ",
    db_url: "https://www.diing.com",
    roa: "0",
    roa_lifetime: "2.535",
    total_stake: "245728023062",
    stake_x_deleg: "7926710421.3548",
    saturated: "0.0035745338792515",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CrimsonPool",
    icon: "0",
    pool_id: "dcf43cff10f366a4804905092fb8dda95a57bc348342ed2a5f356965",
    db_ticker: "RED",
    db_name: "CrimsonPool",
    db_url: "https://crimsonpool.github.io",
    roa: "3.129",
    roa_lifetime: "4.234",
    total_stake: "5720637116252",
    stake_x_deleg: "112169355220.63",
    saturated: "0.083216439574688",
    luck_lifetime: "98",
    blocks_epoch: "5",
    blocks_lifetime: "523",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Armadillo",
    icon: "https://cdn.adapools.org/pool_logo/2730ca79a60b3b3f29b709bd39c21d66cb2c83e5441c479f755d0a3d.png",
    pool_id: "2730ca79a60b3b3f29b709bd39c21d66cb2c83e5441c479f755d0a3d",
    db_ticker: "AMDLO",
    db_name: "Armadillo",
    db_url: "https://armadillo-pool.org",
    roa: "0",
    roa_lifetime: "2.47",
    total_stake: "6394490449",
    stake_x_deleg: "1598622612.25",
    saturated: "0.000093018787461346",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AssurancePool",
    icon: "0",
    pool_id: "029378e3fa199ee6510325406a7eb7d5001927c6294db06b53049499",
    db_ticker: "ASTCH",
    db_name: "AssurancePool",
    db_url: "http://pool.astch.com",
    roa: "0",
    roa_lifetime: "5.381",
    total_stake: "22103665044",
    stake_x_deleg: "2210366504.4",
    saturated: "0.00032153556835262",
    luck_lifetime: "162",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FourBrothersForADA",
    icon: "0",
    pool_id: "1669e0e5fa49d508b0fc448061cc94d425a8b20ad437401fc5eded60",
    db_ticker: "4BFA",
    db_name: "FourBrothersForADA",
    db_url: "http://www.fourbrothersforada.com",
    roa: "0",
    roa_lifetime: "1.904",
    total_stake: "24703308243",
    stake_x_deleg: "2058609020.25",
    saturated: "0.00035935181972272",
    luck_lifetime: "65",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cypherkicks Stake Pool",
    icon: "0",
    pool_id: "2542912669f44fa5da940b6c1e279d74af0c0152d797a3bd2d4792d5",
    db_ticker: "KICKS",
    db_name: "Cypherkicks Stake Pool",
    db_url: "https://www.cypherkicks.com/",
    roa: "0",
    roa_lifetime: "1.64",
    total_stake: "134571746435",
    stake_x_deleg: "1035167280.2692",
    saturated: "0.001957575944444",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "sporepool",
    icon: "https://cdn.adapools.org/pool_logo/d7e062ee4a829c38c7d7616af5666e79cfa6bca819a987b6a1b17ced.png",
    pool_id: "d7e062ee4a829c38c7d7616af5666e79cfa6bca819a987b6a1b17ced",
    db_ticker: "SPORE",
    db_name: "sporepool",
    db_url: "https://sporepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "31061748775",
    stake_x_deleg: "3451305419.4444",
    saturated: "0.00045184619955626",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " KysenPool Thunder 2 ",
    icon: "https://cdn.adapools.org/pool_logo/6fb41f0933511a0a7bc2aba0cdcfeaf9ab837630a303f4f2ec19c097.png",
    pool_id: "6fb41f0933511a0a7bc2aba0cdcfeaf9ab837630a303f4f2ec19c097",
    db_ticker: "KYSN2",
    db_name: " KysenPool Thunder 2 ",
    db_url: "https://kysenpool.io",
    roa: "4.813",
    roa_lifetime: "4.666",
    total_stake: "12235205011378",
    stake_x_deleg: "52511609490.893",
    saturated: "0.17798195862148",
    luck_lifetime: "99",
    blocks_epoch: "9",
    blocks_lifetime: "3421",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Army Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/061ae3f2d469737fb6f8c10aad70e7d02e37f8dd8d2a41dbbb6b72f0.png",
    pool_id: "061ae3f2d469737fb6f8c10aad70e7d02e37f8dd8d2a41dbbb6b72f0",
    db_ticker: "ARMY",
    db_name: "Cardano Army Stakepool",
    db_url: "https://twitter.com/CardanoArmy",
    roa: "0",
    roa_lifetime: "4.407",
    total_stake: "21504813484",
    stake_x_deleg: "2150481348.4",
    saturated: "0.00031282424937813",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "222",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ubiquity",
    icon: "0",
    pool_id: "e443a1446a4521edf4587bb663b1f824a5d87208bae9116eb69563a6",
    db_ticker: "UBQT",
    db_name: "Ubiquity",
    db_url: "http://cardano.ubqt-splice.com",
    roa: "1.52",
    roa_lifetime: "2.192",
    total_stake: "522131135749",
    stake_x_deleg: "40163933519.154",
    saturated: "0.0075952893401823",
    luck_lifetime: "83",
    blocks_epoch: "1",
    blocks_lifetime: "52",
    tax_ratio: "0.0125",
    tax_fix: "340000000",
    pledge: "1500000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VIBES",
    icon: "0",
    pool_id: "7316e1d6886e526e20a1cc1c28a0916f814e04e007cabafd0e5db571",
    db_ticker: "VIBES",
    db_name: "VIBES",
    db_url: "https://vibs.buzz/",
    roa: "2.973",
    roa_lifetime: "3.024",
    total_stake: "1548808249629",
    stake_x_deleg: "172089805514.33",
    saturated: "0.022530061861794",
    luck_lifetime: "103",
    blocks_epoch: "3",
    blocks_lifetime: "19",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardo Fortress",
    icon: "0",
    pool_id: "3be1e0e83e8669fc8dae0d32d8c5a8cdf68303c050fc9f084d935e30",
    db_ticker: "CRF",
    db_name: "Cardo Fortress",
    db_url: "https://cardofortress.com",
    roa: "0",
    roa_lifetime: "1.114",
    total_stake: "69394903137",
    stake_x_deleg: "2891454297.375",
    saturated: "0.0010094674153139",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Demon",
    icon: "0",
    pool_id: "a59e2c8422ed40242742f3b6e8219fa0c454eaa7288d71981d3b65a9",
    db_ticker: "DEMON",
    db_name: "Demon",
    db_url: "https://www.npdemons.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "184763056182",
    stake_x_deleg: "4619076404.55",
    saturated: "0.0026876942878834",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alto Pool",
    icon: "https://cdn.adapools.org/pool_logo/6a73808b4710aa4dd86a6c35b620d129c73becb80011b574b892ff1f.png",
    pool_id: "6a73808b4710aa4dd86a6c35b620d129c73becb80011b574b892ff1f",
    db_ticker: "ALTO",
    db_name: "Alto Pool",
    db_url: "https://altopool.com",
    roa: "3.333",
    roa_lifetime: "6.057",
    total_stake: "51477922129",
    stake_x_deleg: "5719769125.4444",
    saturated: "0.00074883431849022",
    luck_lifetime: "162",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CEAP - Central European ADA Pool - Austria",
    icon: "0",
    pool_id: "665872ecb1cf0ab2c5e983927ce546966ee18ec477b706e77277eb96",
    db_ticker: "CEAP",
    db_name: "CEAP - Central European ADA Pool - Austria",
    db_url: "https://ceap.io",
    roa: "0",
    roa_lifetime: "3.686",
    total_stake: "56559178192",
    stake_x_deleg: "11311835638.4",
    saturated: "0.00082274986837345",
    luck_lifetime: "131",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Transferium Network",
    icon: "0",
    pool_id: "dae77c46a4f8d774f3cc14bb1ced52a621d0472e6d04a2d6f0399ca7",
    db_ticker: "78653",
    db_name: "Transferium Network",
    db_url: "https://transferium.io/",
    roa: "0",
    roa_lifetime: "1.312",
    total_stake: "40124052847",
    stake_x_deleg: "5015506605.875",
    saturated: "0.00058367289366216",
    luck_lifetime: "54",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.025",
    tax_fix: "345000000",
    pledge: "33000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GINKGO",
    icon: "0",
    pool_id: "f8868abd1e639033268abd5fb933de711dd966d648e81d4518ef7483",
    db_ticker: "GINKG",
    db_name: "GINKGO",
    db_url: "http://www.ginkg.org/index-en.html",
    roa: "3.209",
    roa_lifetime: "4.324",
    total_stake: "191749341626",
    stake_x_deleg: "2905293054.9394",
    saturated: "0.0027893217445264",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "565",
    tax_ratio: "0.03",
    tax_fix: "369000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ESGpool",
    icon: "https://cdn.adapools.org/pool_logo/0089edb03016268a135babbbe8fb1f12f5789fc7718e67255bfbe570.png",
    pool_id: "0089edb03016268a135babbbe8fb1f12f5789fc7718e67255bfbe570",
    db_ticker: "ESG",
    db_name: "ESGpool",
    db_url: "https://esgpool.co.uk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "16140471431",
    stake_x_deleg: "1614047143.1",
    saturated: "0.00023479073016692",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "5100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Trollbitt",
    icon: "0",
    pool_id: "2220471638833bba1e69d450b5da722b592888d7a56f5cddf6efe1eb",
    db_ticker: "TROLL",
    db_name: "Trollbitt",
    db_url: "https://trollbitt.no",
    roa: "2.518",
    roa_lifetime: "3.207",
    total_stake: "809617262659",
    stake_x_deleg: "50601078916.188",
    saturated: "0.011777266176399",
    luck_lifetime: "176",
    blocks_epoch: "2",
    blocks_lifetime: "34",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BlockSpaces Stake Pool",
    icon: "0",
    pool_id: "ec174b4a4516465805ea4d1d5acc8086295a1cbf523e40d1378223d7",
    db_ticker: "BLKSP",
    db_name: "BlockSpaces Stake Pool",
    db_url: "https://blockspaces.io",
    roa: "0",
    roa_lifetime: "1.023",
    total_stake: "76745112003",
    stake_x_deleg: "7674511200.3",
    saturated: "0.0011163887598301",
    luck_lifetime: "40",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WILDR Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/57a5c4cfe6b567abe4f5049270845b340b094c1fbf3f3e0a167f30f7.png",
    pool_id: "57a5c4cfe6b567abe4f5049270845b340b094c1fbf3f3e0a167f30f7",
    db_ticker: "WILDR",
    db_name: "WILDR Staking Pool",
    db_url: "https://getwildr.github.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10479730233",
    stake_x_deleg: "1047973023.3",
    saturated: "0.00015244557904502",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "4000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeVault",
    icon: "0",
    pool_id: "b52214d5c29ab51c3cf98b87142d736a738d9535e0b3646178316be6",
    db_ticker: "VAULT",
    db_name: "StakeVault",
    db_url: "https://stakevault.io",
    roa: "3.04",
    roa_lifetime: "3.341",
    total_stake: "116263933314",
    stake_x_deleg: "3321826666.1143",
    saturated: "0.0016912575268677",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "84",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Us As One - Unified Shores IT",
    icon: "https://cdn.adapools.org/pool_logo/576e0390ac54e3751afdaa8aa256f41b84a6324006f23991f6fdefb7.png",
    pool_id: "576e0390ac54e3751afdaa8aa256f41b84a6324006f23991f6fdefb7",
    db_ticker: "USONE",
    db_name: "Us As One - Unified Shores IT",
    db_url: "https://usas.one",
    roa: "0",
    roa_lifetime: "2.347",
    total_stake: "13209530339",
    stake_x_deleg: "3302382584.75",
    saturated: "0.000192155184978",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "12000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Codex Pool",
    icon: "https://cdn.adapools.org/pool_logo/f1773c591a30aaea1b562be9a27fc3566474b4fce68e9c299f86f9f4.png",
    pool_id: "f1773c591a30aaea1b562be9a27fc3566474b4fce68e9c299f86f9f4",
    db_ticker: "CODEX",
    db_name: "Crypto Codex Pool",
    db_url: "https://ccodexstakepools.com",
    roa: "0",
    roa_lifetime: "0.934",
    total_stake: "57805796436",
    stake_x_deleg: "5780579643.6",
    saturated: "0.00084088406036402",
    luck_lifetime: "44",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "341000000",
    pledge: "101000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDAZIO",
    icon: "https://cdn.adapools.org/pool_logo/d1779777832efba4a5da315e6bca4f858b23939124c0253e577c5b25.png",
    pool_id: "d1779777832efba4a5da315e6bca4f858b23939124c0253e577c5b25",
    db_ticker: "CDZIO",
    db_name: "CARDAZIO",
    db_url: "https://cardazio.com",
    roa: "0",
    roa_lifetime: "2.529",
    total_stake: "13969038187",
    stake_x_deleg: "3492259546.75",
    saturated: "0.00020320352411492",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "9680000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dream Cardano",
    icon: "0",
    pool_id: "d71613e6097382e4d522fb17cff10b6c8bbe8575521ae174ba506c70",
    db_ticker: "DRMZ",
    db_name: "Dream Cardano",
    db_url: "https://DreamCardano.com",
    roa: "0",
    roa_lifetime: "2.055",
    total_stake: "184750869301",
    stake_x_deleg: "7105802665.4231",
    saturated: "0.0026875170088801",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "14200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAGO POOL",
    icon: "0",
    pool_id: "c73186434c6fc6676bd67304d34518fc6fd7d5eaddaf78641b1e7dcf",
    db_ticker: "ADAGO",
    db_name: "ADAGO POOL",
    db_url: "https://cutt.ly/ADAGO-pool",
    roa: "1.83",
    roa_lifetime: "4.478",
    total_stake: "594909834877",
    stake_x_deleg: "2468505538.9087",
    saturated: "0.0086539798488153",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "720",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Target",
    icon: "https://cdn.adapools.org/pool_logo/317a33b89efc9c788439d10a1d5d448587b4d1a9ebd6d8e881b698b4.png",
    pool_id: "317a33b89efc9c788439d10a1d5d448587b4d1a9ebd6d8e881b698b4",
    db_ticker: "TRGT",
    db_name: "Target",
    db_url: "https://target-pool.com",
    roa: "0",
    roa_lifetime: "3.038",
    total_stake: "6008222335",
    stake_x_deleg: "1502055583.75",
    saturated: "0.00008739985787097",
    luck_lifetime: "52",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "5754000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Block Investment Group",
    icon: "0",
    pool_id: "12b719fbc569012ead5b3f68cf96b31feb136d4bfd8ed5e49a599083",
    db_ticker: "BIG",
    db_name: "Block Investment Group",
    db_url: "https://blockinvestmentgroup.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "496895104488",
    stake_x_deleg: "749464712.65158",
    saturated: "0.0072281881540976",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GIFT2-Pool",
    icon: "0",
    pool_id: "62d31aef92824633bb3041e6cdcf81b7a611792a3f9d7fad8a476aec",
    db_ticker: "GIFT2",
    db_name: "GIFT2-Pool",
    db_url: "https://gift-pool.com",
    roa: "3.817",
    roa_lifetime: "4.119",
    total_stake: "6967556756262",
    stake_x_deleg: "1393511351252.4",
    saturated: "0.10135501588511",
    luck_lifetime: "106",
    blocks_epoch: "4",
    blocks_lifetime: "350",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MojitoPool",
    icon: "https://cdn.adapools.org/pool_logo/d32d813142d2f64987dc0bef23864d8f642beaea38698fd79cfd6f68.png",
    pool_id: "d32d813142d2f64987dc0bef23864d8f642beaea38698fd79cfd6f68",
    db_ticker: "MOJTO",
    db_name: "MojitoPool",
    db_url: "https://www.mojitopool.com",
    roa: "0",
    roa_lifetime: "3.531",
    total_stake: "7284415826",
    stake_x_deleg: "251186752.62069",
    saturated: "0.00010596427235335",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "61",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Empower",
    icon: "0",
    pool_id: "5e421c3bb8083c5ee95fe3a15974c0ddcd46181cf3f51d2b25274e77",
    db_ticker: "EMPWR",
    db_name: "Empower",
    db_url: "https://empowerstakepool.com",
    roa: "0",
    roa_lifetime: "1.147",
    total_stake: "70838460754",
    stake_x_deleg: "7870940083.7778",
    saturated: "0.0010304664269216",
    luck_lifetime: "51",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.0275",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA LANIN POOL ",
    icon: "https://cdn.adapools.org/pool_logo/52d92549f4bdf485c18e8d4ace66049bc41bdfd4e799d6de83b4a14d.png",
    pool_id: "52d92549f4bdf485c18e8d4ace66049bc41bdfd4e799d6de83b4a14d",
    db_ticker: "LANIN",
    db_name: "ADA LANIN POOL ",
    db_url: "http://laninpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "17763869375",
    stake_x_deleg: "1973763263.8889",
    saturated: "0.00025840582655693",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cashback Pool",
    icon: "https://cdn.adapools.org/pool_logo/20abf75c081efec5cd005a3665e995d69480b79caf6b8c1ecec3db70.png",
    pool_id: "20abf75c081efec5cd005a3665e995d69480b79caf6b8c1ecec3db70",
    db_ticker: "CBACK",
    db_name: "Cashback Pool",
    db_url: "https://cashbackpool.net",
    roa: "0",
    roa_lifetime: "1.45",
    total_stake: "19225288291",
    stake_x_deleg: "2136143143.4444",
    saturated: "0.00027966466127153",
    luck_lifetime: "50",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "skylightpool.com",
    icon: "0",
    pool_id: "34eb602eb435e3c6a4feb6d766a44735ca09e963f82098973c85951e",
    db_ticker: "SKY3",
    db_name: "skylightpool.com",
    db_url: "https://skylightpool.com",
    roa: "3.732",
    roa_lifetime: "4.058",
    total_stake: "68189647051301",
    stake_x_deleg: "66267878572.693",
    saturated: "0.99193490657587",
    luck_lifetime: "82",
    blocks_epoch: "54",
    blocks_lifetime: "1918",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stake.fish",
    icon: "0",
    pool_id: "37c37fde7262737abd98024a96e5c25c25ab10c0d69f5b77180b1efe",
    db_ticker: "FISH",
    db_name: "stake.fish",
    db_url: "https://stake.fish",
    roa: "4.017",
    roa_lifetime: "4.418",
    total_stake: "69075345143530",
    stake_x_deleg: "10362338005.33",
    saturated: "1.0048188983893",
    luck_lifetime: "97",
    blocks_epoch: "43",
    blocks_lifetime: "4898",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " KITCHEN for KID",
    icon: "0",
    pool_id: "415a0a81f48061d36dfc8e00624044c489cb098de271e565685e0fea",
    db_ticker: "KID",
    db_name: " KITCHEN for KID",
    db_url: "https://bepsechia.org/",
    roa: "0",
    roa_lifetime: "3.758",
    total_stake: "29242281499",
    stake_x_deleg: "281175783.64423",
    saturated: "0.00042537893978178",
    luck_lifetime: "172",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.0168",
    tax_fix: "340000000",
    pledge: "16080000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "skylightpool.com",
    icon: "0",
    pool_id: "c1011645958df9e805df499bbd30056c83094b99c8effe500350d8da",
    db_ticker: "SKY4",
    db_name: "skylightpool.com",
    db_url: "https://skylightpool.com",
    roa: "3.964",
    roa_lifetime: "4.031",
    total_stake: "69170617133981",
    stake_x_deleg: "74457069035.502",
    saturated: "1.0062047922461",
    luck_lifetime: "81",
    blocks_epoch: "59",
    blocks_lifetime: "1957",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fytoboros Pool",
    icon: "0",
    pool_id: "f06bccfa2d64e58989542975c105147e0872fcea2fd72c6739a6f118",
    db_ticker: "FYTO",
    db_name: "Fytoboros Pool",
    db_url: "https://fytoboros.com",
    roa: "0",
    roa_lifetime: "1.422",
    total_stake: "197306046215",
    stake_x_deleg: "4812342590.6098",
    saturated: "0.0028701535054418",
    luck_lifetime: "62",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bullet Proof Investing Club 2",
    icon: "https://cdn.adapools.org/pool_logo/6bf7a699ac063eb6119295faa652e4054664d4c287a45a31efb84cc1.png",
    pool_id: "6bf7a699ac063eb6119295faa652e4054664d4c287a45a31efb84cc1",
    db_ticker: "BULPF",
    db_name: "Bullet Proof Investing Club 2",
    db_url: "https://bulletproofinvestingclub.com/index.html",
    roa: "0",
    roa_lifetime: "3.216",
    total_stake: "9196258628",
    stake_x_deleg: "1021806514.2222",
    saturated: "0.00013377529196111",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Puffin Pool",
    icon: "https://cdn.adapools.org/pool_logo/ff5940e0061e5c8c3e37f76c47bd7be5ab8eb9dfe43f6c2d1b74f028.png",
    pool_id: "ff5940e0061e5c8c3e37f76c47bd7be5ab8eb9dfe43f6c2d1b74f028",
    db_ticker: "PUFF",
    db_name: "Puffin Pool",
    db_url: "https://puffinpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11827803490",
    stake_x_deleg: "1314200387.7778",
    saturated: "0.00017205560751802",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Goofinator Pool",
    icon: "https://cdn.adapools.org/pool_logo/443e35eb7bfca00b092bd4daf079de42924b982636a12aa53a3994ab.png",
    pool_id: "443e35eb7bfca00b092bd4daf079de42924b982636a12aa53a3994ab",
    db_ticker: "GOOF",
    db_name: "Goofinator Pool",
    db_url: "https://goofinator.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "28547812577",
    stake_x_deleg: "4757968762.8333",
    saturated: "0.00041527670293128",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "00c3598b7df81a8c175d67412bf4a20eb76edd62fe47a5b39c08bec6",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "3.182",
    roa_lifetime: "1.444",
    total_stake: "618144196622",
    stake_x_deleg: "154536049155.5",
    saturated: "0.0089919633322836",
    luck_lifetime: "61",
    blocks_epoch: "0",
    blocks_lifetime: "20",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "13000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hodl4Tesla",
    icon: "0",
    pool_id: "772a93f3dd0eff49db3edcb0c9292c9bfa149317328b4f7d25463823",
    db_ticker: "H4T1",
    db_name: "Hodl4Tesla",
    db_url: "https://hodl4tesla.com",
    roa: "1.13",
    roa_lifetime: "1.702",
    total_stake: "329705774871",
    stake_x_deleg: "47100824981.571",
    saturated: "0.0047961337407737",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EduStake",
    icon: "0",
    pool_id: "226ab72c289945eded248cd928eb5cdaf1421f27ba291f1f3873b8ff",
    db_ticker: "EDU",
    db_name: "EduStake",
    db_url: "https://edustake.io",
    roa: "0",
    roa_lifetime: "2.262",
    total_stake: "54792561266",
    stake_x_deleg: "7827508752.2857",
    saturated: "0.0007970514072254",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N2O",
    icon: "0",
    pool_id: "150217c1e72aeb12c9023f9f1b7d90c99531a4157b380023bad5d890",
    db_ticker: "N2O",
    db_name: "N2O",
    db_url: "https://0xdeadbeef.network",
    roa: "0.913",
    roa_lifetime: "1.898",
    total_stake: "371690694034",
    stake_x_deleg: "3792762184.0204",
    saturated: "0.0054068761139702",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1140000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Guarda Wallet",
    icon: "0",
    pool_id: "48c4f6c2b3ba2ab7e62f975b8a59c68d2c2929890973db1737babcaf",
    db_ticker: "GRD",
    db_name: "Guarda Wallet",
    db_url: "https://guarda.com",
    roa: "6.075",
    roa_lifetime: "3.076",
    total_stake: "1510526645025",
    stake_x_deleg: "1857966353.0443",
    saturated: "0.02197319052533",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "85",
    tax_ratio: "0.025",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Ada Protocol NC",
    icon: "https://cdn.adapools.org/pool_logo/73e52ec92ce0e919987fc5673dfa2f3fbfb89409e6f2038eb52c9cc1.png",
    pool_id: "73e52ec92ce0e919987fc5673dfa2f3fbfb89409e6f2038eb52c9cc1",
    db_ticker: "TAPNC",
    db_name: "The Ada Protocol NC",
    db_url: "https://www.tap.nc",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "34049631023",
    stake_x_deleg: "4256203877.875",
    saturated: "0.00049531005113331",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SOLyDAR_1",
    icon: "0",
    pool_id: "9adbf9ce3c2da566017898c4871126f1371def427c6e245fd7c02211",
    db_ticker: "SOL_1",
    db_name: "SOLyDAR_1",
    db_url: "https://www.solydar.org",
    roa: "6.667",
    roa_lifetime: "2.058",
    total_stake: "102294167199",
    stake_x_deleg: "5114708359.95",
    saturated: "0.0014880434137965",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "3",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "10500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Poolnet",
    icon: "https://cdn.adapools.org/pool_logo/69892569215cabbe4b4351827bebf00c6052611c0ea10c3f3a574667.png",
    pool_id: "69892569215cabbe4b4351827bebf00c6052611c0ea10c3f3a574667",
    db_ticker: "POET",
    db_name: "The Poolnet",
    db_url: "https://poolnet.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3983661952",
    stake_x_deleg: "497957744",
    saturated: "0.000057949168489084",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lo-Fi Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/b1f66fdf23c559b4d8a1d9a0f1d95e06f918008f441b8b550e6f054f.png",
    pool_id: "b1f66fdf23c559b4d8a1d9a0f1d95e06f918008f441b8b550e6f054f",
    db_ticker: "LOFI",
    db_name: "Lo-Fi Stake Pool",
    db_url: "https://lofipool.com",
    roa: "0",
    roa_lifetime: "3.531",
    total_stake: "27155011543",
    stake_x_deleg: "9051670514.3333",
    saturated: "0.00039501603253215",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Celsius Network 2",
    icon: "0",
    pool_id: "a37fa22b02a007a80ea13555c28341987bb61193c258a1e03db7f3f6",
    db_ticker: "CLS2",
    db_name: "Celsius Network 2",
    db_url: "https://celsius.network/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "48497971456481",
    stake_x_deleg: "16165990485494",
    saturated: "0.70548584522826",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Celsius Network 3",
    icon: "0",
    pool_id: "850ea4e988183b844b646a38e60b3cf13befb0385c1aa9a402dae5b9",
    db_ticker: "CLS3",
    db_name: "Celsius Network 3",
    db_url: "https://celsius.network/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "48801331810470",
    stake_x_deleg: "24400665905235",
    saturated: "0.70989873981571",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano AIM: A guild of tool builders.",
    icon: "https://cdn.adapools.org/pool_logo/b61f05ec1e907ab9b069eaec6c664056c16f56cab59076109c66d2ae.png",
    pool_id: "b61f05ec1e907ab9b069eaec6c664056c16f56cab59076109c66d2ae",
    db_ticker: "AIM",
    db_name: "Cardano AIM: A guild of tool builders.",
    db_url: "https://cardanoaim.io/",
    roa: "3.333",
    roa_lifetime: "1.737",
    total_stake: "48311143236",
    stake_x_deleg: "4831114323.6",
    saturated: "0.00070276810959767",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SOCIAL CH4IN",
    icon: "0",
    pool_id: "1abc4b63943d87337c2f29e7e38f2a1a4d127f68624093d673283e93",
    db_ticker: "CH4IN",
    db_name: "SOCIAL CH4IN",
    db_url: "https://www.socialch4in.com",
    roa: "4.257",
    roa_lifetime: "4.247",
    total_stake: "3027835201319",
    stake_x_deleg: "756958800329.75",
    saturated: "0.04404503553579",
    luck_lifetime: "107",
    blocks_epoch: "3",
    blocks_lifetime: "321",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BankOnBlocks",
    icon: "https://cdn.adapools.org/pool_logo/8b964766617311484e783504c3658c190f4c06382cc0f37cdfc58bff.png",
    pool_id: "8b964766617311484e783504c3658c190f4c06382cc0f37cdfc58bff",
    db_ticker: "0BoB",
    db_name: "BankOnBlocks",
    db_url: "http://18.119.65.114/index.html",
    roa: "0",
    roa_lifetime: "1.728",
    total_stake: "193764598127",
    stake_x_deleg: "96882299063.5",
    saturated: "0.0028186370930505",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONYX Stake Pool #4",
    icon: "0",
    pool_id: "052100e65f3fa89fe1e2d3ae521446b2013b3c91ef5c7961e1b1a1ac",
    db_ticker: "ONYX",
    db_name: "ONYX Stake Pool #4",
    db_url: "https://onyxstakepool.com",
    roa: "3.662",
    roa_lifetime: "4.161",
    total_stake: "66700977474138",
    stake_x_deleg: "1588118511289",
    saturated: "0.97027966444162",
    luck_lifetime: "98",
    blocks_epoch: "46",
    blocks_lifetime: "3168",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bitcão Stake Pool",
    icon: "0",
    pool_id: "51c670011f289048a535f50bd5021f2779ca2fb6ee711998d5eeb6c9",
    db_ticker: "BTCAO",
    db_name: "Bitcão Stake Pool",
    db_url: "https://www.youtube.com/c/augustobackes",
    roa: "3.32",
    roa_lifetime: "4.311",
    total_stake: "4573534154896",
    stake_x_deleg: "594274188.52599",
    saturated: "0.066529867374813",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "901",
    tax_ratio: "0",
    tax_fix: "700000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arcadia's ADAMS Pool",
    icon: "0",
    pool_id: "452cf4da07afbac289bd221f4bae723b8cfedfd2dcdce07d7a88e4d1",
    db_ticker: "ADAMS",
    db_name: "Arcadia's ADAMS Pool",
    db_url: "https://arcadiastakepool.com",
    roa: "0",
    roa_lifetime: "3.941",
    total_stake: "162149640876",
    stake_x_deleg: "23164234410.857",
    saturated: "0.0023587435311498",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "57",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GOLGI Italy Pool 1",
    icon: "0",
    pool_id: "effc78f36adf09718712f78a4ae84f02e6d7cd79e3c9492fcaa2a7f5",
    db_ticker: "GOLGI",
    db_name: "GOLGI Italy Pool 1",
    db_url: "https://golgi-pool.it",
    roa: "0",
    roa_lifetime: "2.213",
    total_stake: "89939522637",
    stake_x_deleg: "2901274923.7742",
    saturated: "0.0013083240028694",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "8000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Bring ADA to African savanna",
    icon: "0",
    pool_id: "ef35be2059b0dd8c0d59cdb40e9d9f1066cf653cf037c0ab46caa49b",
    db_ticker: "SIMBA",
    db_name: " Bring ADA to African savanna",
    db_url: "https://www.oasisgreenafrica.com",
    roa: "0",
    roa_lifetime: "2.325",
    total_stake: "27021995123",
    stake_x_deleg: "270219951.23",
    saturated: "0.00039308108146771",
    luck_lifetime: "125",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "14140000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Boss Pool",
    icon: "0",
    pool_id: "b1dde27cdda55577137376f9cd867c68bd22f7343a9c0ce0cc2cd551",
    db_ticker: "DAO",
    db_name: "Boss Pool",
    db_url: "https://info.bossdao.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "368516291709",
    stake_x_deleg: "1730123435.2535",
    saturated: "0.0053606990092359",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cute Pool",
    icon: "0",
    pool_id: "aee1cc55e9c498efa91a4942a698caae6d2f0199dffb108ad6ce827d",
    db_ticker: "CUTE",
    db_name: "Cute Pool",
    db_url: "https://adapools.org/CUTE",
    roa: "0",
    roa_lifetime: "4.153",
    total_stake: "69948359961",
    stake_x_deleg: "242035847.61592",
    saturated: "0.0010175183903042",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "277",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA SAVER",
    icon: "https://cdn.adapools.org/pool_logo/d567bfbe1162d4f934fc4bfee4d2f29aeacc766584b6619efc07aca0.png",
    pool_id: "d567bfbe1162d4f934fc4bfee4d2f29aeacc766584b6619efc07aca0",
    db_ticker: "SAVER",
    db_name: "ADA SAVER",
    db_url: "https://CardanoADAsaverPool.ca",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18667843296",
    stake_x_deleg: "2074204810.6667",
    saturated: "0.00027155567152092",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FOFA Stake Pool",
    icon: "0",
    pool_id: "07c399aad671c987864494b08a8bf075c7dba7d1494a48f727a5a44d",
    db_ticker: "FOFA",
    db_name: "FOFA Stake Pool",
    db_url: "https://www.youtube.com/c/augustobackes",
    roa: "0",
    roa_lifetime: "2.147",
    total_stake: "82367976316",
    stake_x_deleg: "784456917.29524",
    saturated: "0.0011981829269535",
    luck_lifetime: "79",
    blocks_epoch: "0",
    blocks_lifetime: "17",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Ocean Cleanup Pool",
    icon: "https://cdn.adapools.org/pool_logo/f54ab5770babf306264f73d0fcf47efc6e39c9383fe83f9f229f2e76.png",
    pool_id: "f54ab5770babf306264f73d0fcf47efc6e39c9383fe83f9f229f2e76",
    db_ticker: "TOCP",
    db_name: "The Ocean Cleanup Pool",
    db_url: "https://www.theoceancleanuppool.ch/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "41843167905",
    stake_x_deleg: "1307598997.0312",
    saturated: "0.00060868035899143",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CArdano Pools EXplained - 2",
    icon: "0",
    pool_id: "2efcfd92f766bbeaa79ad07ab4ff648f11ec17c029e66d2abe343937",
    db_ticker: "CAPEY",
    db_name: "CArdano Pools EXplained - 2",
    db_url: "https://www.cardano-pool.eu/",
    roa: "0",
    roa_lifetime: "1.59",
    total_stake: "76094887136",
    stake_x_deleg: "9511860892",
    saturated: "0.0011069301283428",
    luck_lifetime: "55",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Slice of Stake",
    icon: "0",
    pool_id: "49be9d6c06cf02bdf84b73421509aedf9704e0914bb3cf67844ebade",
    db_ticker: "SLICE",
    db_name: "Slice of Stake",
    db_url: "https://www.sliceofstake.com",
    roa: "0",
    roa_lifetime: "1.661",
    total_stake: "73678803288",
    stake_x_deleg: "3349036513.0909",
    saturated: "0.0010717840613124",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pegasus",
    icon: "0",
    pool_id: "bbbe8bd9d3942563c7cf02e2d605516fb8376e3a49689d9b028cc2ab",
    db_ticker: "PEGA",
    db_name: "Pegasus",
    db_url: "https://pegasuspool.info",
    roa: "3.361",
    roa_lifetime: "4.716",
    total_stake: "2184537077429",
    stake_x_deleg: "6368912762.1837",
    saturated: "0.031777823694861",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "2441",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "baidu",
    icon: "0",
    pool_id: "c1f5cfd4330339e90ba83a64d269a81cf415d7adab36403e27b910f7",
    db_ticker: "BAIDU",
    db_name: "baidu",
    db_url: "https://zjavax.github.io/cardano_doc/#/",
    roa: "2.861",
    roa_lifetime: "2.258",
    total_stake: "2034430570183",
    stake_x_deleg: "9205568190.8733",
    saturated: "0.02959426811597",
    luck_lifetime: "81",
    blocks_epoch: "4",
    blocks_lifetime: "26",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "40000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aada Finance 2 - Stake ADA earn AADA",
    icon: "0",
    pool_id: "4bae51055777821b4fa7d4fdbf130816b4d9d9b7b9000edeec8e95d4",
    db_ticker: "ISPO2",
    db_name: "Aada Finance 2 - Stake ADA earn AADA",
    db_url: "https://aada.finance",
    roa: "0",
    roa_lifetime: "0.564",
    total_stake: "44099713466",
    stake_x_deleg: "864700264.03922",
    saturated: "0.00064150566909387",
    luck_lifetime: "30",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.0099",
    tax_fix: "345000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Pool in Amsterdam",
    icon: "https://cdn.adapools.org/pool_logo/5e1e8add666098850a985512905098e5a65bcaf1cfe8d113daf0baa4.png",
    pool_id: "5e1e8add666098850a985512905098e5a65bcaf1cfe8d113daf0baa4",
    db_ticker: "AMSC1",
    db_name: "Cardano Pool in Amsterdam",
    db_url: "https://www.amscardanopool.com",
    roa: "0",
    roa_lifetime: "4.902",
    total_stake: "10333239988",
    stake_x_deleg: "1476177141.1429",
    saturated: "0.00015031462817826",
    luck_lifetime: "108",
    blocks_epoch: "0",
    blocks_lifetime: "378",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Serve Stake Pool",
    icon: "0",
    pool_id: "5cd18b5b3eda95e89ea3666ee6ecabaf85bbf09a76965c22795e23c9",
    db_ticker: "SERVE",
    db_name: "Serve Stake Pool",
    db_url: "https://servepool.co",
    roa: "0",
    roa_lifetime: "3.12",
    total_stake: "24225994409",
    stake_x_deleg: "3460856344.1429",
    saturated: "0.00035240847459909",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "24",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JulianX",
    icon: "0",
    pool_id: "6a36ff3daf685f36ce14d7888b21b993d7fb115b1a1ebe39106999cd",
    db_ticker: "JULX",
    db_name: "JulianX",
    db_url: "https://julianx.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "168027151045",
    stake_x_deleg: "33605430209",
    saturated: "0.0024442419572672",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Glow Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/5dc1f763f14baaabc8d14aa9c0717e8d4662e4d16b2e10095d7ec78b.png",
    pool_id: "5dc1f763f14baaabc8d14aa9c0717e8d4662e4d16b2e10095d7ec78b",
    db_ticker: "GLOW",
    db_name: "Glow Stake Pool",
    db_url: "https://www.glowstake.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5659990620",
    stake_x_deleg: "808570088.57143",
    saturated: "0.000082334232682656",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DeepEnd",
    icon: "0",
    pool_id: "8e8af8edf6ea15ddfd1d5103811dd34f9b4a28082517a6094107c5c0",
    db_ticker: "DEEP",
    db_name: "DeepEnd",
    db_url: "https://www.deependpool.net",
    roa: "1.85",
    roa_lifetime: "3.127",
    total_stake: "197765436296",
    stake_x_deleg: "3803181467.2308",
    saturated: "0.0028768361189584",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "22",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GIFT2-Pool",
    icon: "0",
    pool_id: "606e6f06f41f49237380db817fba2a0fe3bd7e0bc0e9213f38ec9b8a",
    db_ticker: "GIFT2",
    db_name: "GIFT2-Pool",
    db_url: "https://gift-pool.com",
    roa: "2.95",
    roa_lifetime: "3.59",
    total_stake: "3725265594839",
    stake_x_deleg: "931316398709.75",
    saturated: "0.054190352048705",
    luck_lifetime: "92",
    blocks_epoch: "2",
    blocks_lifetime: "299",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MEGA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/33e79c08b65fe7db968fa2629b36781ff2113c0a41121a8fc191a60a.png",
    pool_id: "33e79c08b65fe7db968fa2629b36781ff2113c0a41121a8fc191a60a",
    db_ticker: "MEGA",
    db_name: "MEGA Stake Pool",
    db_url: "https://megastakepool.com",
    roa: "0",
    roa_lifetime: "2.643",
    total_stake: "13648777803",
    stake_x_deleg: "4549592601",
    saturated: "0.00019854478972018",
    luck_lifetime: "118",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HSt ADA Pool",
    icon: "https://cdn.adapools.org/pool_logo/f85629efed5aa2fdbf88b70a4419f2e637ea93d046fb8aef94328c59.png",
    pool_id: "f85629efed5aa2fdbf88b70a4419f2e637ea93d046fb8aef94328c59",
    db_ticker: "HST",
    db_name: "HSt ADA Pool",
    db_url: "https://adapool.hst-soft.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8342676904",
    stake_x_deleg: "1390446150.6667",
    saturated: "0.00012135848758883",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "White Lotus Pool",
    icon: "https://cdn.adapools.org/pool_logo/4860157484c418192ad58ca785b4317eb7c1897e9887f43d3c210652.png",
    pool_id: "4860157484c418192ad58ca785b4317eb7c1897e9887f43d3c210652",
    db_ticker: "WLP",
    db_name: "White Lotus Pool",
    db_url: "https://whitelotuspool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "35241487664",
    stake_x_deleg: "7048297532.8",
    saturated: "0.00051264764205753",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "31500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arch Pool",
    icon: "https://cdn.adapools.org/pool_logo/31decc344873fc96bd98c8fe97b02e86a6a5387eeb1861cd408f69be.png",
    pool_id: "31decc344873fc96bd98c8fe97b02e86a6a5387eeb1861cd408f69be",
    db_ticker: "ARCH",
    db_name: "Arch Pool",
    db_url: "https://archpool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "31783733170",
    stake_x_deleg: "6356746634",
    saturated: "0.00046234869596696",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "bbe49b11205f94561ae03cda64a7009aba995c42fb819c0d90e251ca",
    db_ticker: "RAID3",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "4.207",
    total_stake: "110913927212",
    stake_x_deleg: "27728481803",
    saturated: "0.0016134325485543",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "30",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The HIGH Pool",
    icon: "https://cdn.adapools.org/pool_logo/5f48715600af6a8d92f32208b7ae923d447c85d21f5fa7014794e7b8.png",
    pool_id: "5f48715600af6a8d92f32208b7ae923d447c85d21f5fa7014794e7b8",
    db_ticker: "HIGH",
    db_name: "The HIGH Pool",
    db_url: "http://high-pool.atwebpages.com",
    roa: "0",
    roa_lifetime: "0.961",
    total_stake: "9474627812",
    stake_x_deleg: "3158209270.6667",
    saturated: "0.00013782464728798",
    luck_lifetime: "50",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Traditional ADA Pool",
    icon: "0",
    pool_id: "f25197cb96c05ec03b8fc5ef195ea87e76ba8fab3b0dcc0cd499e140",
    db_ticker: "TRADA",
    db_name: "Traditional ADA Pool",
    db_url: "https://ada.vnpips.com",
    roa: "0",
    roa_lifetime: "4.167",
    total_stake: "45057803916",
    stake_x_deleg: "3754816993",
    saturated: "0.00065544273142089",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "92",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RenewPool",
    icon: "0",
    pool_id: "559a8f376d4ddeb2df64ac42cfc4e95f28d6042499f4e45b15900f4a",
    db_ticker: "RENEW",
    db_name: "RenewPool",
    db_url: "https://renewpool.ch",
    roa: "0",
    roa_lifetime: "1.42",
    total_stake: "93336339232",
    stake_x_deleg: "6666881373.7143",
    saturated: "0.0013577365031172",
    luck_lifetime: "70",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "bulb pool",
    icon: "https://cdn.adapools.org/pool_logo/96c2ec4a88c4a76c770ffffdf442e8e0c7b6b4ee4bb5a02c59e308ec.png",
    pool_id: "96c2ec4a88c4a76c770ffffdf442e8e0c7b6b4ee4bb5a02c59e308ec",
    db_ticker: "BULB",
    db_name: "bulb pool",
    db_url: "https://bulbpool.com",
    roa: "0",
    roa_lifetime: "6.757",
    total_stake: "10132954278",
    stake_x_deleg: "3377651426",
    saturated: "0.00014740113037282",
    luck_lifetime: "203",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JED Stakepool",
    icon: "https://cdn.adapools.org/pool_logo/df2c28671a7d2e9b29703547533543926445f3a70f119a07af41d434.png",
    pool_id: "df2c28671a7d2e9b29703547533543926445f3a70f119a07af41d434",
    db_ticker: "JED",
    db_name: "JED Stakepool",
    db_url: "https://adapool.jed.id.au",
    roa: "0",
    roa_lifetime: "0.847",
    total_stake: "5856283344",
    stake_x_deleg: "1952094448",
    saturated: "0.000085189645685395",
    luck_lifetime: "37",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rollway",
    icon: "https://cdn.adapools.org/pool_logo/f1e5b8134b7c78d42b83662d0c9093c688a9580ad5fb2f30cb74058f.png",
    pool_id: "f1e5b8134b7c78d42b83662d0c9093c688a9580ad5fb2f30cb74058f",
    db_ticker: "ROLL",
    db_name: "Rollway",
    db_url: "https://rollway.it",
    roa: "0",
    roa_lifetime: "4.37",
    total_stake: "809126279",
    stake_x_deleg: "269708759.66667",
    saturated: "0.000011770123980318",
    luck_lifetime: "136",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everstake",
    icon: "0",
    pool_id: "aa687b2685ce3c4ddbdbdd1cff75ac4a21aea91ce0e28a0889ad38c2",
    db_ticker: "EVE5",
    db_name: "Everstake",
    db_url: "https://everstake.one/cardano",
    roa: "2.482",
    roa_lifetime: "1.521",
    total_stake: "846841239368",
    stake_x_deleg: "33873649574.72",
    saturated: "0.012318752508356",
    luck_lifetime: "69",
    blocks_epoch: "2",
    blocks_lifetime: "24",
    tax_ratio: "0.03",
    tax_fix: "400000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BEADA Cardano stake pool",
    icon: "https://cdn.adapools.org/pool_logo/a803d6a5b213b52b38c4d7b97c934b904810c36e40a9d7260670d618.png",
    pool_id: "a803d6a5b213b52b38c4d7b97c934b904810c36e40a9d7260670d618",
    db_ticker: "BEADA",
    db_name: "BEADA Cardano stake pool",
    db_url: "www.beadapool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "31412613972",
    stake_x_deleg: "5235435662",
    saturated: "0.00045695013323911",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dr Meow Cardano Stake Pool",
    icon: "0",
    pool_id: "6d54378bf38d98cc52aea96b4f468898f7a8d3b03de47112275c8237",
    db_ticker: "yabak",
    db_name: "Dr Meow Cardano Stake Pool",
    db_url: "https://hysleeper.github.io/meow",
    roa: "2.002",
    roa_lifetime: "1.789",
    total_stake: "3333829797251",
    stake_x_deleg: "34018671400.52",
    saturated: "0.048496249672449",
    luck_lifetime: "63",
    blocks_epoch: "2",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alleycat Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/2c46dc5e913171488f695fc081494a66c5a71c47bdf8b562c26a8599.png",
    pool_id: "2c46dc5e913171488f695fc081494a66c5a71c47bdf8b562c26a8599",
    db_ticker: "ALLEY",
    db_name: "Alleycat Stake Pool",
    db_url: "http://alleycatmining.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6345847026",
    stake_x_deleg: "1057641171",
    saturated: "0.000092311185774938",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Pool Europe",
    icon: "https://cdn.adapools.org/pool_logo/3a0903012e156471ee2cfdf5af79902cd5108d891f90ec9cfbbce9ce.png",
    pool_id: "3a0903012e156471ee2cfdf5af79902cd5108d891f90ec9cfbbce9ce",
    db_ticker: "EUR",
    db_name: "ADA Pool Europe",
    db_url: "https://adapool-europe.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9662372729",
    stake_x_deleg: "1610395454.8333",
    saturated: "0.00014055571783545",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Brisnode Australia",
    icon: "0",
    pool_id: "acc4bd79e795648e5134ec8c80bc2d486aa9007de6e87a348a1f98ba",
    db_ticker: "BN1AU",
    db_name: "Brisnode Australia",
    db_url: "https://brisnode-cardano.org",
    roa: "0",
    roa_lifetime: "2.201",
    total_stake: "15103314585",
    stake_x_deleg: "2517219097.5",
    saturated: "0.00021970351203881",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "4500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SCV Pool",
    icon: "0",
    pool_id: "a7c31f27cdebd1c96b4a960c47e498a961441b8dc34b191c02acd386",
    db_ticker: "SCV01",
    db_name: "SCV Pool",
    db_url: "https://github.com/korea-gazuaa",
    roa: "4.34",
    roa_lifetime: "4.084",
    total_stake: "3755242525632",
    stake_x_deleg: "28448807012.364",
    saturated: "0.054626417717489",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "459",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mercy Charity Pool",
    icon: "0",
    pool_id: "a4ac19aa597233380e7cfc36fb0172a1e7de311343c0c22b86afa4d7",
    db_ticker: "MERCY",
    db_name: "Mercy Charity Pool",
    db_url: "https://sites.google.com/view/mercy-charity-pool",
    roa: "0",
    roa_lifetime: "3.15",
    total_stake: "36727474600",
    stake_x_deleg: "6121245766.6667",
    saturated: "0.00053426386059324",
    luck_lifetime: "176",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fidelio",
    icon: "0",
    pool_id: "6dd22f13b63b81b0b374ff127c92b5ee5dd2df03fb44fcf623bdcbe9",
    db_ticker: "FIDE",
    db_name: "Fidelio",
    db_url: "https://fideliostakepool.github.io",
    roa: "0",
    roa_lifetime: "8.901",
    total_stake: "5174796074",
    stake_x_deleg: "862466012.33333",
    saturated: "0.000075276249140146",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "520000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Theta Staking Pool",
    icon: "0",
    pool_id: "90f35b86d1bb85f15f559a612b5f3a406af7ea04530f65807950177f",
    db_ticker: "THETA",
    db_name: "Theta Staking Pool",
    db_url: "https://thetapool.online",
    roa: "0.876",
    roa_lifetime: "4.593",
    total_stake: "166372242218",
    stake_x_deleg: "8318612110.9",
    saturated: "0.0024201684812531",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "394",
    tax_ratio: "0.032",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Calva Group",
    icon: "https://cdn.adapools.org/pool_logo/f3e52b8630f21ca8e3e6d11896a58c2af5943ba295ed14128479a60d.png",
    pool_id: "f3e52b8630f21ca8e3e6d11896a58c2af5943ba295ed14128479a60d",
    db_ticker: "CALVA",
    db_name: "Calva Group",
    db_url: "https://calvagroup.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18429383703",
    stake_x_deleg: "3071563950.5",
    saturated: "0.00026808686937378",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "101000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Freedom Socal",
    icon: "https://cdn.adapools.org/pool_logo/f594d0a1863c0366dd09a4bc6a73c10482cfbbbed438876667fc4cbb.png",
    pool_id: "f594d0a1863c0366dd09a4bc6a73c10482cfbbbed438876667fc4cbb",
    db_ticker: "FRSC",
    db_name: "Freedom Socal",
    db_url: "http://facebook.com/Freedom.Socal",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18261520138",
    stake_x_deleg: "3043586689.6667",
    saturated: "0.00026564500705499",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "INFINITY",
    icon: "0",
    pool_id: "d22d7c62ed2007ee958be0717c37a0acf9ef2c055be647d8bb3fbdcf",
    db_ticker: "0000",
    db_name: "INFINITY",
    db_url: "http://sbwh.com.au/infinity-pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8092331884",
    stake_x_deleg: "337180495.16667",
    saturated: "0.00011771679160177",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WIRA Pool (D1)",
    icon: "0",
    pool_id: "fec0c5f77fb6eac6e01124a0a11af032b322157d4c35a14361732d19",
    db_ticker: "WIRA💎",
    db_name: "WIRA Pool (D1)",
    db_url: "https://staking.wira.co",
    roa: "0",
    roa_lifetime: "2.279",
    total_stake: "53739731555",
    stake_x_deleg: "5971081283.8889",
    saturated: "0.00078173620050149",
    luck_lifetime: "58",
    blocks_epoch: "0",
    blocks_lifetime: "66",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nodeasy",
    icon: "0",
    pool_id: "b59cdd43a43adc0888b98e994f75ae7aa4a3515d861bacf3662504dd",
    db_ticker: "NDE",
    db_name: "Nodeasy",
    db_url: "https://www.nodeasy.com",
    roa: "0",
    roa_lifetime: "4.668",
    total_stake: "183376942267",
    stake_x_deleg: "7972910533.3478",
    saturated: "0.0026675308930539",
    luck_lifetime: "94",
    blocks_epoch: "0",
    blocks_lifetime: "1314",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rhodium Coin",
    icon: "0",
    pool_id: "97451e5fe172032877a463a32679f00bd00150be1989373393c81f9d",
    db_ticker: "RHOD",
    db_name: "Rhodium Coin",
    db_url: "https://www.rhodiumcoin.net/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "62450691309",
    stake_x_deleg: "2973842443.2857",
    saturated: "0.0009084519912911",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FOMO Stake Pool",
    icon: "0",
    pool_id: "6268cc4f99059ad14d229a97b72ae8327b444b8887e2ca55f9df27b2",
    db_ticker: "FOMO",
    db_name: "FOMO Stake Pool",
    db_url: "https://www.stakeithere.com",
    roa: "2.713",
    roa_lifetime: "3.954",
    total_stake: "139809544401",
    stake_x_deleg: "11650795366.75",
    saturated: "0.0020337686637311",
    luck_lifetime: "168",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "ce8720c3f555e18a28582c15d75d8a6a68f209d589978f35ec1f661c",
    db_ticker: "RAY7",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "6.667",
    roa_lifetime: "31.781",
    total_stake: "62380322152",
    stake_x_deleg: "3283174850.1053",
    saturated: "0.000907428351689",
    luck_lifetime: "1554",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "2six4",
    icon: "0",
    pool_id: "b4b13182eca340d8454021c10c1d298388a7d9cc4bea9f456d8cf991",
    db_ticker: "2six4",
    db_name: "2six4",
    db_url: "https://www.2six4.com",
    roa: "3.699",
    roa_lifetime: "1.98",
    total_stake: "192598177631",
    stake_x_deleg: "10136746191.105",
    saturated: "0.0028016695143085",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Unstoppable ADA",
    icon: "https://cdn.adapools.org/pool_logo/fa40fcde282587cbcf983d7fc5a6e6e05ce326f127d3c20d7af5af2b.png",
    pool_id: "fa40fcde282587cbcf983d7fc5a6e6e05ce326f127d3c20d7af5af2b",
    db_ticker: "UNST",
    db_name: "Unstoppable ADA",
    db_url: "https://unstoppable.staking4ada.org",
    roa: "0",
    roa_lifetime: "2.896",
    total_stake: "47187192977",
    stake_x_deleg: "9437438595.4",
    saturated: "0.00068641833300595",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "43",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THK Pool",
    icon: "0",
    pool_id: "484a23d00655b5244e754d57d37d13799cf716d15d7c08a967893793",
    db_ticker: "THK",
    db_name: "THK Pool",
    db_url: "https://thkpool.com",
    roa: "6.667",
    roa_lifetime: "1.188",
    total_stake: "99088094655",
    stake_x_deleg: "24772023663.75",
    saturated: "0.0014414056116238",
    luck_lifetime: "59",
    blocks_epoch: "1",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Global-Harmony Pool",
    icon: "0",
    pool_id: "8f302d7bc723929a0778ea2a1c5a602a37a93ea05a2faa6385c536bf",
    db_ticker: "GLOBE",
    db_name: "Global-Harmony Pool",
    db_url: "http://www.global-harmony.live/",
    roa: "0",
    roa_lifetime: "4.109",
    total_stake: "3172407254",
    stake_x_deleg: "528734542.33333",
    saturated: "0.000046148083018375",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "262",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "50116000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JustGain",
    icon: "0",
    pool_id: "94126ee530b946391802017a637e550f9ae096c9e841083564e18f93",
    db_ticker: "JGAIN",
    db_name: "JustGain",
    db_url: "https://justgain.co.uk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6164272073",
    stake_x_deleg: "362604239.58824",
    saturated: "0.00008966986789416",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aionios Pool",
    icon: "0",
    pool_id: "80bb78c248a32ed7943d79349c4092ea86b5c04b685e891e419a0061",
    db_ticker: "TIME",
    db_name: "Aionios Pool",
    db_url: "https://aioniospool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "36009003169",
    stake_x_deleg: "2118176657",
    saturated: "0.00052381246624521",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 4",
    icon: "0",
    pool_id: "3e695a66ff6e2ed3806701bc0f6e8dfc85db114b71864999425147c5",
    db_ticker: "BD4",
    db_name: "Blockdaemon  Stake Pool 4",
    db_url: "https://blockdaemon.com/",
    roa: "4.23",
    roa_lifetime: "4.23",
    total_stake: "67508180866279",
    stake_x_deleg: "16877045216570",
    saturated: "0.98202181674765",
    luck_lifetime: "108",
    blocks_epoch: "61",
    blocks_lifetime: "437",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HOSK's CLIPS",
    icon: "https://cdn.adapools.org/pool_logo/094dd5ec76789aec588bc28a2fcfabadef31c6d071e1684e10af45bb.png",
    pool_id: "094dd5ec76789aec588bc28a2fcfabadef31c6d071e1684e10af45bb",
    db_ticker: "HOSK",
    db_name: "HOSK's CLIPS",
    db_url: "https://hoskclips.com",
    roa: "0",
    roa_lifetime: "3.255",
    total_stake: "82531486674",
    stake_x_deleg: "10316435834.25",
    saturated: "0.0012005614644398",
    luck_lifetime: "138",
    blocks_epoch: "0",
    blocks_lifetime: "12",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Incrypture-ADA",
    icon: "0",
    pool_id: "a5863f812d8f3079e7aa5b8e28bbea63d3325733bf2203954539c4cf",
    db_ticker: "INCA",
    db_name: "Incrypture-ADA",
    db_url: "https://incrypture-ada.com",
    roa: "3.333",
    roa_lifetime: "4.131",
    total_stake: "36192470800",
    stake_x_deleg: "2784036215.3846",
    saturated: "0.00052648131636081",
    luck_lifetime: "119",
    blocks_epoch: "0",
    blocks_lifetime: "15",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Colorado Mint",
    icon: "0",
    pool_id: "cd4e5544ade6727692d2f6646f377d1416e9e89a724d87da5d33a97b",
    db_ticker: "MINT",
    db_name: "Colorado Mint",
    db_url: "https://coloradomint.io/",
    roa: "0",
    roa_lifetime: "4.117",
    total_stake: "18417090547",
    stake_x_deleg: "1315506467.6429",
    saturated: "0.00026790804441903",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "264",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VIVA Cardano",
    icon: "0",
    pool_id: "b36f71c3e7eb34810fcc1a6807e82ef8e033eaba75a5643ef4a3d6ed",
    db_ticker: "VIVA",
    db_name: "VIVA Cardano",
    db_url: "https://www.vivacardano.com",
    roa: "4.508",
    roa_lifetime: "3.311",
    total_stake: "325415849038",
    stake_x_deleg: "23243989217",
    saturated: "0.0047337294409366",
    luck_lifetime: "154",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.029",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOUSELANE",
    icon: "0",
    pool_id: "b94ba8b903956a63685d13e716ca4f0c0f355a7d16d00f6e4bade7b5",
    db_ticker: "MOUSE",
    db_name: "MOUSELANE",
    db_url: "https://mouselane.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "159266330076",
    stake_x_deleg: "22752332868",
    saturated: "0.0023168008499262",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.001",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAMars",
    icon: "0",
    pool_id: "4bafc2b9423aa741faedcf0fd27cab2387de0e0bbb7c7d98d9b0f421",
    db_ticker: "MARS",
    db_name: "ADAMars",
    db_url: "https://github.com/ADA-MARS/POOL",
    roa: "3.324",
    roa_lifetime: "2.753",
    total_stake: "671368240306",
    stake_x_deleg: "67136824030.6",
    saturated: "0.0097661979717379",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "48",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Home for Cardanians",
    icon: "0",
    pool_id: "489c79a5c0d3cf84289a2bc795504879d4be89611eed8646076bbc27",
    db_ticker: "H4C",
    db_name: "Home for Cardanians",
    db_url: "https://home4cardanians.online",
    roa: "0",
    roa_lifetime: "2.096",
    total_stake: "162443672715",
    stake_x_deleg: "27073945452.5",
    saturated: "0.0023630207265506",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "16",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bitterbal Pool",
    icon: "https://cdn.adapools.org/pool_logo/666768d552a3408574bccea7361e951dd6aa7a35903a6ece6802c72b.png",
    pool_id: "666768d552a3408574bccea7361e951dd6aa7a35903a6ece6802c72b",
    db_ticker: "BITB",
    db_name: "Bitterbal Pool",
    db_url: "https://www.bitterbalpool.nl",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "67876459702",
    stake_x_deleg: "7541828855.7778",
    saturated: "0.00098737906155389",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "12500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANERS",
    icon: "0",
    pool_id: "bd23a3c0401818927966c506af93a4798d54b02a744fb8383f05a7d3",
    db_ticker: "OSADA",
    db_name: "CARDANERS",
    db_url: "https://cardaners.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "30283922597",
    stake_x_deleg: "2163137328.3571",
    saturated: "0.00044053138901579",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakit.io Pool 4 by TOBG",
    icon: "0",
    pool_id: "ec1c7c5b2d5e56e2bbde3b49dd4f733d45d74a6d7f92ebeceb9a8a1b",
    db_ticker: "STI4",
    db_name: "stakit.io Pool 4 by TOBG",
    db_url: "https://stakit.io",
    roa: "0",
    roa_lifetime: "3.37",
    total_stake: "10405612220",
    stake_x_deleg: "743258015.71429",
    saturated: "0.00015136740592813",
    luck_lifetime: "173",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "600000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "beehive",
    icon: "0",
    pool_id: "707cc05abbb19ee7b8c2090fd65d2ba81d8469e6fb5d9dab972cea17",
    db_ticker: "BEEHI",
    db_name: "beehive",
    db_url: "https://twitter.com/beehivepool",
    roa: "6.667",
    roa_lifetime: "4.33",
    total_stake: "51757760995",
    stake_x_deleg: "10351552199",
    saturated: "0.00075290505285248",
    luck_lifetime: "159",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Veo Cardano Staking Pool",
    icon: "https://cdn.adapools.org/pool_logo/9aca46f8cc5aac73760b588a57f3e96ee75aa415fb64e614be225ac2.png",
    pool_id: "9aca46f8cc5aac73760b588a57f3e96ee75aa415fb64e614be225ac2",
    db_ticker: "VEO",
    db_name: "Veo Cardano Staking Pool",
    db_url: "https://cardano.veo.link",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12125180840",
    stake_x_deleg: "2425036168",
    saturated: "0.00017638146909152",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "3500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swedish Staking Pool",
    icon: "0",
    pool_id: "59716910b52b6c5f046986bb321ccd910056f3411cc3f33f53aa713a",
    db_ticker: "SWADA",
    db_name: "The Swedish Staking Pool",
    db_url: "https://swada.blockfront.se",
    roa: "0",
    roa_lifetime: "1.644",
    total_stake: "40459562385",
    stake_x_deleg: "10114890596.25",
    saturated: "0.00058855345305237",
    luck_lifetime: "56",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "80539000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Steekpoel",
    icon: "0",
    pool_id: "596371eefd3d7c3a201d12d64428b82a4c967bc7746d8a31350799ca",
    db_ticker: "STKPL",
    db_name: "Steekpoel",
    db_url: "http://www.steekpoel.nl",
    roa: "0",
    roa_lifetime: "4.534",
    total_stake: "65455221386",
    stake_x_deleg: "21818407128.667",
    saturated: "0.0009521580139809",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "215",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MAHALO",
    icon: "0",
    pool_id: "81c21ece04d545721f3146f84facd1b89344944e82bcc2ff2ebf4447",
    db_ticker: "MAHLO",
    db_name: "MAHALO",
    db_url: "https://mahalopool.com",
    roa: "0",
    roa_lifetime: "0.387",
    total_stake: "50795867449",
    stake_x_deleg: "12698966862.25",
    saturated: "0.0007389126679972",
    luck_lifetime: "14",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "49000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "US Cardano",
    icon: "https://cdn.adapools.org/pool_logo/f3746e12eeb3034456a0a49dcb0d3f6dd9814720e29768d49c20daf9.png",
    pool_id: "f3746e12eeb3034456a0a49dcb0d3f6dd9814720e29768d49c20daf9",
    db_ticker: "US1",
    db_name: "US Cardano",
    db_url: "https://cardano-us-east-1.tradecartel.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18282446158",
    stake_x_deleg: "3656489231.6",
    saturated: "0.00026594941176438",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IAGON",
    icon: "0",
    pool_id: "718a38284f9f04d87bb22b5815bb03e183c5de2d63138eb26e1eaf69",
    db_ticker: "IAGON",
    db_name: "IAGON",
    db_url: "https://iagon.com",
    roa: "0",
    roa_lifetime: "0.334",
    total_stake: "2066594810",
    stake_x_deleg: "413318962",
    saturated: "0.000030062151931148",
    luck_lifetime: "17",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Forbole",
    icon: "https://cdn.adapools.org/pool_logo/be83111ec4e29494b880bd5324494eb9651b95c11b7e6ca74e8115f7.png",
    pool_id: "be83111ec4e29494b880bd5324494eb9651b95c11b7e6ca74e8115f7",
    db_ticker: "FORBP",
    db_name: "Forbole",
    db_url: "https://www.forbole.com",
    roa: "0",
    roa_lifetime: "1.46",
    total_stake: "67271141331",
    stake_x_deleg: "13454228266.2",
    saturated: "0.00097857367176598",
    luck_lifetime: "56",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OCTIVIO",
    icon: "https://cdn.adapools.org/pool_logo/2acf18f0920155dec9344f80d2ba32c4c80f1c35bc0cf4cf51ee6499.png",
    pool_id: "2acf18f0920155dec9344f80d2ba32c4c80f1c35bc0cf4cf51ee6499",
    db_ticker: "OCT",
    db_name: "OCTIVIO",
    db_url: "https://www.octivio.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3099421614",
    stake_x_deleg: "619884322.8",
    saturated: "0.000045086382201236",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CELL",
    icon: "0",
    pool_id: "6ea2bd81ea5a176f11ba469412deb5754c06a04521e56de0625a77b0",
    db_ticker: "CELL",
    db_name: "CELL",
    db_url: "https://twitter.com/cellpoolCZ",
    roa: "0",
    roa_lifetime: "5.395",
    total_stake: "60943240392",
    stake_x_deleg: "15235810098",
    saturated: "0.00088652354248424",
    luck_lifetime: "156",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "17356000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Investments and Development",
    icon: "0",
    pool_id: "dd029d74b3ed9e6a7525dad0f07973a1e94c4132d626c528ca090c7e",
    db_ticker: "CID",
    db_name: "Crypto Investments and Development",
    db_url: " ",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "28428305065",
    stake_x_deleg: "2186792697.3077",
    saturated: "0.00041353826201133",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "STAKE POOL NINJA",
    icon: "0",
    pool_id: "e602442ead829084d3eb38a96111937386f4206b04fef0a3aee369f8",
    db_ticker: "NINJA",
    db_name: "STAKE POOL NINJA",
    db_url: "https://stakepool.ninja",
    roa: "0",
    roa_lifetime: "4.37",
    total_stake: "10667962096",
    stake_x_deleg: "888996841.33333",
    saturated: "0.00015518373305393",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "297",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LaurenOCrypto",
    icon: "0",
    pool_id: "2dbe88a8d570dd73912d1f75ca281d8fb79666151e8e13764677fec1",
    db_ticker: "TEACH",
    db_name: "LaurenOCrypto",
    db_url: "https://www.LaurenOCrypto.com",
    roa: "0",
    roa_lifetime: "2.841",
    total_stake: "32223810167",
    stake_x_deleg: "3580423351.8889",
    saturated: "0.00046875036768374",
    luck_lifetime: "130",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HumanityPool",
    icon: "https://cdn.adapools.org/pool_logo/399c37889049086833ba82fbeeff226d6b387693ec32edc8ceca0b04.png",
    pool_id: "399c37889049086833ba82fbeeff226d6b387693ec32edc8ceca0b04",
    db_ticker: "HUM",
    db_name: "HumanityPool",
    db_url: "https://www.humanity-pool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "120571597301",
    stake_x_deleg: "13396844144.556",
    saturated: "0.0017539198584573",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "350000000",
    pledge: "5500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EAS Pool",
    icon: "0",
    pool_id: "99fbfd6e50ad28ff0c5e9e3944afb1b77bba25638a11fb465e237b59",
    db_ticker: "EAS",
    db_name: "EAS Pool",
    db_url: "https://enjoyadastaking.it",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8493130126",
    stake_x_deleg: "707760843.83333",
    saturated: "0.00012354708672612",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1950000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Abu",
    icon: "0",
    pool_id: "0db403b246e3eafac8407b42bf2719208a4e53dfd9a9884f275ef19a",
    db_ticker: "CABU",
    db_name: "Cardano Abu",
    db_url: "https://www.cardanoabu.com",
    roa: "0",
    roa_lifetime: "4.67",
    total_stake: "1267536523",
    stake_x_deleg: "126753652.3",
    saturated: "0.000018438484093892",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "We are Kirin",
    icon: "0",
    pool_id: "a86b685a2d6817d315f48121ee4bb6099cc4c26b94d9fe1c256c5c62",
    db_ticker: "KIRIN",
    db_name: "We are Kirin",
    db_url: "https://fimi.vn/ada",
    roa: "0",
    roa_lifetime: "4.32",
    total_stake: "22263966274",
    stake_x_deleg: "1855330522.8333",
    saturated: "0.00032386742358989",
    luck_lifetime: "169",
    blocks_epoch: "0",
    blocks_lifetime: "24",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Cardano Forge (pool 1)",
    icon: "0",
    pool_id: "759e779221f08d0642f6132ee88bd2d8ffd312b908d9a2488aa9abf6",
    db_ticker: "TCF1",
    db_name: "The Cardano Forge (pool 1)",
    db_url: "https://cardanoforge.io",
    roa: "3.333",
    roa_lifetime: "1.727",
    total_stake: "80942101350",
    stake_x_deleg: "11563157335.714",
    saturated: "0.0011774411397124",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Loxe Inc. Pool",
    icon: "0",
    pool_id: "5f7ad5f7869dc00ffa143a44bfee70321fa3bf6de895420c95502bba",
    db_ticker: "LOXE",
    db_name: "Loxe Inc. Pool",
    db_url: "https://loxeinc.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "322217983761",
    stake_x_deleg: "40277247970.125",
    saturated: "0.004687211027483",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RAMBO Stakepool",
    icon: "0",
    pool_id: "7ef984f328f4dc3e33a8b3d339612bc02cb9317a569465c19dae8141",
    db_ticker: "RAMBO",
    db_name: "RAMBO Stakepool",
    db_url: "https://rambopool.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23011456618",
    stake_x_deleg: "2301145661.8",
    saturated: "0.0003347409476013",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SASA Stake Pool",
    icon: "0",
    pool_id: "1ec2c7dde1ce090ee2e75c9fa618c575b8a0b77eaeec5c7266f50aca",
    db_ticker: "SASA",
    db_name: "SASA Stake Pool",
    db_url: "https://twitter.com/nagamaru_panda?s=06",
    roa: "2.322",
    roa_lifetime: "3.595",
    total_stake: "4205165065844",
    stake_x_deleg: "116810140717.89",
    saturated: "0.061171309679693",
    luck_lifetime: "103",
    blocks_epoch: "2",
    blocks_lifetime: "159",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "80000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "anetaBTC - LISO Pool 3",
    icon: "0",
    pool_id: "19e397043c50cd043700c7c54ab3ce86cb4595b912d637ae7abdb3f9",
    db_ticker: "NETA3",
    db_name: "anetaBTC - LISO Pool 3",
    db_url: "https://www.anetabtc.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7722056076",
    stake_x_deleg: "702005097.81818",
    saturated: "0.00011233049742226",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "880000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lamport Pool",
    icon: "0",
    pool_id: "f905a9f5572e359f4ab59affc55d506916683b93249de0d30b426baf",
    db_ticker: "LMPRT",
    db_name: "Lamport Pool",
    db_url: "https://lamportpool.com",
    roa: "0",
    roa_lifetime: "1.15",
    total_stake: "51454573370",
    stake_x_deleg: "10290914674",
    saturated: "0.00074849467090325",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Get Lovelace",
    icon: "0",
    pool_id: "1663610a0f8c45da8cbae578d6b923f3dac67ad489ebd31e03eb942c",
    db_ticker: "GETLO",
    db_name: "Get Lovelace",
    db_url: "n/a",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "61997287482",
    stake_x_deleg: "12399457496.4",
    saturated: "0.00090185645806539",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pernis Pool",
    icon: "https://cdn.adapools.org/pool_logo/d6a7ae863359d7f908e1a446f8fc5cbccf378770682d1a53c901ae22.png",
    pool_id: "d6a7ae863359d7f908e1a446f8fc5cbccf378770682d1a53c901ae22",
    db_ticker: "PERN",
    db_name: "Pernis Pool",
    db_url: "https://pernispool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5633528188",
    stake_x_deleg: "625947576.44444",
    saturated: "0.000081949291402729",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.042",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FightCancerPool",
    icon: "0",
    pool_id: "74374fee363869f5a737fcc74102d6257da0eac92ff358d726e5098e",
    db_ticker: "FCP",
    db_name: "FightCancerPool",
    db_url: "https://fcp.marcelwettach.eu",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "27896781553",
    stake_x_deleg: "6974195388.25",
    saturated: "0.00040580634451333",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crazeekennee's ADA Staking Pool",
    icon: "0",
    pool_id: "d2bf4dfff27f93ac77cc11f32a608d32e1978446f8db748032d23902",
    db_ticker: "CRAZY",
    db_name: "Crazeekennee's ADA Staking Pool",
    db_url: "https://www.crazeekennee.com/",
    roa: "3.333",
    roa_lifetime: "5.779",
    total_stake: "34448947368",
    stake_x_deleg: "5741491228",
    saturated: "0.00050111878953423",
    luck_lifetime: "258",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "9550000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Community driven grassroot network of visionairs",
    icon: "https://cdn.adapools.org/pool_logo/e40edb5a243c9ef00296860c6b7c8272fd5923ac52b5050d68e80d9b.png",
    pool_id: "e40edb5a243c9ef00296860c6b7c8272fd5923ac52b5050d68e80d9b",
    db_ticker: "SWARM",
    db_name: "Community driven grassroot network of visionairs",
    db_url: "https://catalystswarm.com/",
    roa: "3.887",
    roa_lifetime: "4.071",
    total_stake: "14745930685430",
    stake_x_deleg: "446846384406.97",
    saturated: "0.21450475269101",
    luck_lifetime: "104",
    blocks_epoch: "14",
    blocks_lifetime: "415",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MauiStakingPool",
    icon: "0",
    pool_id: "d7a2dd6a3bfd42fdac79e58893524bf66d6287b28108cd7162ff6c29",
    db_ticker: "MAUI",
    db_name: "MauiStakingPool",
    db_url: "https://mauicardano.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "56817226828",
    stake_x_deleg: "5681722682.8",
    saturated: "0.00082650362661552",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Z3ldaPool",
    icon: "https://cdn.adapools.org/pool_logo/263578cc21e4acdfb86ca47a90fbb9200a203e45975e6fea423fb438.png",
    pool_id: "263578cc21e4acdfb86ca47a90fbb9200a203e45975e6fea423fb438",
    db_ticker: "Z3LDA",
    db_name: "Z3ldaPool",
    db_url: "https://www.facebook.com/z3ldastakingpool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "40274911245",
    stake_x_deleg: "10068727811.25",
    saturated: "0.00058586738677655",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "11670000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dos Stake Pool",
    icon: "0",
    pool_id: "7c9bed86fb7ef639a58422c508ad23dcb6bb33bf498f307fcbee5fa6",
    db_ticker: "DOS",
    db_name: "Dos Stake Pool",
    db_url: "https://dosstakepool.com",
    roa: "0",
    roa_lifetime: "3.838",
    total_stake: "10849562948",
    stake_x_deleg: "1084956294.8",
    saturated: "0.00015782542767991",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "28",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Boricua Pool",
    icon: "0",
    pool_id: "00000722842ca5ae6575eecd1ddb629e7a61fef816c22a75bbfb1ad4",
    db_ticker: "WEPA",
    db_name: "The Boricua Pool",
    db_url: "https://boricua-staking.com",
    roa: "0",
    roa_lifetime: "4.901",
    total_stake: "3628467145",
    stake_x_deleg: "362846714.5",
    saturated: "0.000052782253232392",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "257",
    tax_ratio: "0.0275",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FIXION MEDI₳",
    icon: "https://cdn.adapools.org/pool_logo/ffd23ba5f75e28a3cb1b095a352c5094d6589148b49329d05395cbdf.png",
    pool_id: "ffd23ba5f75e28a3cb1b095a352c5094d6589148b49329d05395cbdf",
    db_ticker: "METAL",
    db_name: "FIXION MEDI₳",
    db_url: "https://fixionmedia.com/cardano/",
    roa: "0",
    roa_lifetime: "1.768",
    total_stake: "126033719894",
    stake_x_deleg: "63016859947",
    saturated: "0.001833375762664",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "6660000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NobodyCardanos",
    icon: "0",
    pool_id: "1d947b86870addd0dfa59e0b5af60b8a91f99fbeb9b59e65e81725e8",
    db_ticker: "NOCAR",
    db_name: "NobodyCardanos",
    db_url: "https://bluehares.com/",
    roa: "0",
    roa_lifetime: "1.673",
    total_stake: "34870749088",
    stake_x_deleg: "8717687272",
    saturated: "0.00050725461612689",
    luck_lifetime: "78",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Everest Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/31c4a56aec831daf9de9a038f9681f0720b9638d9f62f0b2d4aad007.png",
    pool_id: "31c4a56aec831daf9de9a038f9681f0720b9638d9f62f0b2d4aad007",
    db_ticker: "EVT",
    db_name: "Everest Stake Pool",
    db_url: "https://everestpool.net",
    roa: "1.827",
    roa_lifetime: "3.869",
    total_stake: "1026472409353",
    stake_x_deleg: "205294481870.6",
    saturated: "0.014931794744564",
    luck_lifetime: "98",
    blocks_epoch: "1",
    blocks_lifetime: "315",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Iluka",
    icon: "0",
    pool_id: "23e146390a1b58999264bdd1779eed1b7d50e869bb8c260ccc4cc6c9",
    db_ticker: "ILUKA",
    db_name: "Iluka",
    db_url: "https://ilukapool.com",
    roa: "2.24",
    roa_lifetime: "0.837",
    total_stake: "160249820933",
    stake_x_deleg: "53416606977.667",
    saturated: "0.0023311074045652",
    luck_lifetime: "70",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HAIRY Staking Pool",
    icon: "0",
    pool_id: "43a92030961c6d68bcde62be938a53abd9d97e90101ee2bb7b5a60ab",
    db_ticker: "HAIRY",
    db_name: "HAIRY Staking Pool",
    db_url: "https://cardano.hairystakingpool.com",
    roa: "0",
    roa_lifetime: "2.781",
    total_stake: "13655019142",
    stake_x_deleg: "2731003828.4",
    saturated: "0.00019863558065818",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Balance Stake Pool",
    icon: "0",
    pool_id: "a43ceac028a673e9f8611de0f683c70fdcadde560f28c2fb8cfabc81",
    db_ticker: "BALNC",
    db_name: "Balance Stake Pool",
    db_url: "https://balanceanalytics.io",
    roa: "0",
    roa_lifetime: "8.522",
    total_stake: "12035281599",
    stake_x_deleg: "1337253511",
    saturated: "0.00017507373105388",
    luck_lifetime: "423",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.015",
    tax_fix: "345000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MiDeFi",
    icon: "0",
    pool_id: "27014c58edd2610ec65c7beae9967d0c7ff7eaedd3fba5f7950ae478",
    db_ticker: "MIDFI",
    db_name: "MiDeFi",
    db_url: "https://mi-defi.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "47969811279",
    stake_x_deleg: "7994968546.5",
    saturated: "0.00069780285317651",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "22000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kryptoknechte",
    icon: "https://cdn.adapools.org/pool_logo/a92f670dbeb38ed2a98d0cffce94a81fa1cdc7bef354327fab121028.png",
    pool_id: "a92f670dbeb38ed2a98d0cffce94a81fa1cdc7bef354327fab121028",
    db_ticker: "KK01",
    db_name: "Kryptoknechte",
    db_url: "https://kryptoknechte.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "24169051853",
    stake_x_deleg: "2685450205.8889",
    saturated: "0.00035158014784556",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Foster Pool",
    icon: "0",
    pool_id: "743883835692e597dc4e0e8ffc5ddffbdf5d2f57e73b14f831a256b3",
    db_ticker: "FSTR",
    db_name: "Foster Pool",
    db_url: "https://twitter.com/Oveis_HJ",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50995953975",
    stake_x_deleg: "5666217108.3333",
    saturated: "0.00074182326833108",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "1STKE",
    icon: "0",
    pool_id: "bfb3964f94889a9e480917daeac90d95ebea71e8fb163e3873db764c",
    db_ticker: "1STKE",
    db_name: "1STKE",
    db_url: "https://one.stke.club",
    roa: "1.966",
    roa_lifetime: "3.595",
    total_stake: "742685315038",
    stake_x_deleg: "57129639618.308",
    saturated: "0.010803626656599",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "103",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Midwest",
    icon: "0",
    pool_id: "9a19ef65a16ba583656828f22f5d9794d86c0c8d3d804964b4f9a88e",
    db_ticker: "MDWST",
    db_name: "Midwest",
    db_url: "https://ADAMDWST.com",
    roa: "0",
    roa_lifetime: "1.353",
    total_stake: "110284500973",
    stake_x_deleg: "12253833441.444",
    saturated: "0.0016042764686422",
    luck_lifetime: "70",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SKYpool",
    icon: "0",
    pool_id: "1a1119aa4fcf8db858b826d7cb899dad49ee855bdc9351f90c6f418e",
    db_ticker: "SKY",
    db_name: "SKYpool",
    db_url: "https://skypool.pages.dev",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6028805181",
    stake_x_deleg: "669867242.33333",
    saturated: "0.000087699270528271",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "123000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Reyts Stake Pool",
    icon: "0",
    pool_id: "b3471fb159f5e8b345018a5c309afd91f06bf99552b8e91eb9980c9d",
    db_ticker: "REYTS",
    db_name: "Reyts Stake Pool",
    db_url: "https://www.reyts.com/reytspool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "744864328",
    stake_x_deleg: "82762703.111111",
    saturated: "0.000010835324122598",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NKLEO Pool",
    icon: "0",
    pool_id: "9c34cce57970faf103ff9023a1b975445b483ff361b62d98eac8437a",
    db_ticker: "NKLEO",
    db_name: "NKLEO Pool",
    db_url: "https://nkleo.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5332096068",
    stake_x_deleg: "592455118.66667",
    saturated: "0.000077564446272702",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Bank Pool",
    icon: "0",
    pool_id: "0deabfea77fa4fffe204e09b10b36ef42cd807da33fb86e8735545f9",
    db_ticker: "BANK",
    db_name: " Bank Pool",
    db_url: "https://bankpool.io",
    roa: "0",
    roa_lifetime: "1.479",
    total_stake: "23157633518",
    stake_x_deleg: "5789408379.5",
    saturated: "0.00033686734032974",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amazon Pool 1",
    icon: "0",
    pool_id: "db3ef28510cc87c6a2f2b83c50707c40d8024438c8a61f24ac16a33f",
    db_ticker: "AMZ1",
    db_name: "Amazon Pool 1",
    db_url: "https://adapools.org/AMZ1",
    roa: "4.369",
    roa_lifetime: "4.304",
    total_stake: "10789096141521",
    stake_x_deleg: "59608265975.254",
    saturated: "0.15694583468259",
    luck_lifetime: "111",
    blocks_epoch: "6",
    blocks_lifetime: "207",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 5",
    icon: "0",
    pool_id: "494b1adec5eb9aaa6f66fbdad55fa0637a59fc1c74ef40b355bc2aa1",
    db_ticker: "BD5",
    db_name: "Blockdaemon  Stake Pool 5",
    db_url: "https://blockdaemon.com/",
    roa: "4.022",
    roa_lifetime: "3.893",
    total_stake: "67669005337555",
    stake_x_deleg: "33834502668778",
    saturated: "0.98436128342315",
    luck_lifetime: "99",
    blocks_epoch: "53",
    blocks_lifetime: "311",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mongkok Bro's",
    icon: "0",
    pool_id: "96cf541540c4b919b3ab4d12c319c59bca1b662a2aa578ad875194bd",
    db_ticker: "MKBRO",
    db_name: "Mongkok Bro's",
    db_url: "https://impacthk.org/",
    roa: "3.333",
    roa_lifetime: "0.995",
    total_stake: "69999344192",
    stake_x_deleg: "17499836048",
    saturated: "0.0010182600430418",
    luck_lifetime: "51",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.0018",
    tax_fix: "340000000",
    pledge: "40000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PANJ",
    icon: "0",
    pool_id: "7b3d7f57b55b1d2690c30b0c823c2aec3caa6a4416ee942c0c2edc61",
    db_ticker: "PANJ",
    db_name: "PANJ",
    db_url: "https://goodpanj.org",
    roa: "0",
    roa_lifetime: "1.59",
    total_stake: "103835036142",
    stake_x_deleg: "25958759035.5",
    saturated: "0.0015104579848805",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bakrypt",
    icon: "0",
    pool_id: "02c7bc63afd139ca2f651259abf66bb6d4605feaade7b41ed3d9bc19",
    db_ticker: "BAK",
    db_name: "Bakrypt",
    db_url: "https://bakrypt.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12252368836",
    stake_x_deleg: "1531546104.5",
    saturated: "0.00017823163577203",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Recycling Pool",
    icon: "https://cdn.adapools.org/pool_logo/10afb96159de0f4debc8586a063458482fdb3e65dbcec06f808feaa0.png",
    pool_id: "10afb96159de0f4debc8586a063458482fdb3e65dbcec06f808feaa0",
    db_ticker: "RECY",
    db_name: "Recycling Pool",
    db_url: "https://www.hidden-water.com/",
    roa: "0",
    roa_lifetime: "2.706",
    total_stake: "61781391375",
    stake_x_deleg: "15445347843.75",
    saturated: "0.00089871588036793",
    luck_lifetime: "87",
    blocks_epoch: "1",
    blocks_lifetime: "58",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Iron pool",
    icon: "0",
    pool_id: "f59e3495e638303cfa63750d5da209412a59c880680e7a8393dd5368",
    db_ticker: "IRON",
    db_name: "Iron pool",
    db_url: "https://ironpool.net",
    roa: "0",
    roa_lifetime: "3.941",
    total_stake: "8992059533",
    stake_x_deleg: "1124007441.625",
    saturated: "0.00013080486728551",
    luck_lifetime: "126",
    blocks_epoch: "0",
    blocks_lifetime: "30",
    tax_ratio: "0.009",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Benefit Of All",
    icon: "https://cdn.adapools.org/pool_logo/9cbaa13cc5fdaae9c2b517c8596d184620375b5b77191f50bb46abcd.png",
    pool_id: "9cbaa13cc5fdaae9c2b517c8596d184620375b5b77191f50bb46abcd",
    db_ticker: "BOA",
    db_name: "Benefit Of All",
    db_url:
      "https://armada-alliance.com/stake-pools/9cbaa13cc5fdaae9c2b517c8596d184620375b5b77191f50bb46abcd",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8888112162",
    stake_x_deleg: "1111014020.25",
    saturated: "0.00012929277519821",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kairos1",
    icon: "0",
    pool_id: "852a7af9a41e02834990932f290243b0a26d332c27bc6e8259a4f883",
    db_ticker: "KAI1",
    db_name: "Kairos1",
    db_url: "https://kairospool.azurewebsites.net",
    roa: "0",
    roa_lifetime: "0.386",
    total_stake: "26625189141",
    stake_x_deleg: "6656297285.25",
    saturated: "0.00038730886058515",
    luck_lifetime: "19",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VERTICA ADA Pool",
    icon: "0",
    pool_id: "33c5796b7d62d5d5b13526662d03f1d94e16f4ffd236386137e17ec1",
    db_ticker: "VERT",
    db_name: "VERTICA ADA Pool",
    db_url: "https://pool.vertica.se",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3385550479",
    stake_x_deleg: "846387619.75",
    saturated: "0.000049248615344324",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Maasai.io Cardano Stake Pool",
    icon: "0",
    pool_id: "161ae9d04faa1dedbe7e009c62a0133f4bf8b938666588b86d32797c",
    db_ticker: "MASAI",
    db_name: "Maasai.io Cardano Stake Pool",
    db_url: "https://maasai.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3556258575",
    stake_x_deleg: "889064643.75",
    saturated: "0.000051731856225893",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Whimsy",
    icon: "https://cdn.adapools.org/pool_logo/2cefb325aa5ae0f39666f55483c9785126e82ed70aa85a91ee7074da.png",
    pool_id: "2cefb325aa5ae0f39666f55483c9785126e82ed70aa85a91ee7074da",
    db_ticker: "WMSY",
    db_name: "Whimsy",
    db_url: "https://whimsy.tech",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "145826675",
    stake_x_deleg: "36456668.75",
    saturated: "0.0000021212981075202",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.023",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pale Blue Dot pool",
    icon: "https://cdn.adapools.org/pool_logo/5283b9e53fff62fa8578f428e816a7e28e2ee6cf3db57b453b71c68b.png",
    pool_id: "5283b9e53fff62fa8578f428e816a7e28e2ee6cf3db57b453b71c68b",
    db_ticker: "PBDOT",
    db_name: "Pale Blue Dot pool",
    db_url: "https://www.palebluedotpool.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3381330115",
    stake_x_deleg: "845332528.75",
    saturated: "0.000049187222940183",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zebra Staking",
    icon: "https://cdn.adapools.org/pool_logo/d436a2dd1100ed4f903ea831f92e27f9f4ca09882d225a5ca800e7a0.png",
    pool_id: "d436a2dd1100ed4f903ea831f92e27f9f4ca09882d225a5ca800e7a0",
    db_ticker: "ZEBRA",
    db_name: "Zebra Staking",
    db_url: "https://zebrastaking.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1935491212",
    stake_x_deleg: "483872803",
    saturated: "0.000028155026130422",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "howijd.network",
    icon: "https://cdn.adapools.org/pool_logo/e9efe8540ad7ab0a77a0b068d450a705de5f42b61c9c8434c6785c8c.png",
    pool_id: "e9efe8540ad7ab0a77a0b068d450a705de5f42b61c9c8434c6785c8c",
    db_ticker: "HOWI",
    db_name: "howijd.network",
    db_url: "https://cardano.bridge.howijd.network",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "127219442",
    stake_x_deleg: "31804860.5",
    saturated: "0.0000018506241162968",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Yuzuppy-BCG StakePool",
    icon: "0",
    pool_id: "7630ccde9faa19387b289a00d6d416579cfb1818d1e521ce76448c2d",
    db_ticker: "YUZUP",
    db_name: "Yuzuppy-BCG StakePool",
    db_url: "https://twitter.com/yuzuppy1",
    roa: "0",
    roa_lifetime: "2.408",
    total_stake: "103970772041",
    stake_x_deleg: "12996346505.125",
    saturated: "0.0015124324954127",
    luck_lifetime: "121",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Waiting for delegators",
    icon: "0",
    pool_id: "608ab3036db99d94049ee0870e84f5145e5667370323cbd61a781c6b",
    db_ticker: "BOOM",
    db_name: "Waiting for delegators",
    db_url: "",
    roa: "0",
    roa_lifetime: "3.796",
    total_stake: "47447340867",
    stake_x_deleg: "5930917608.375",
    saturated: "0.00069020262848366",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "101",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FRESH POOL",
    icon: "0",
    pool_id: "d183d3db6556df80179399eb8c8887223ead8caf5d00c67790727652",
    db_ticker: "FRESH",
    db_name: "FRESH POOL",
    db_url: "https://freshpool.ca",
    roa: "0",
    roa_lifetime: "6.346",
    total_stake: "3038984948",
    stake_x_deleg: "379873118.5",
    saturated: "0.000044207227648678",
    luck_lifetime: "183",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Atomic Wallet",
    icon: "0",
    pool_id: "ae66e56ab11ccb39e882669f220a37956c683e4ce84fefd910012d7a",
    db_ticker: "AWP2",
    db_name: "Atomic Wallet",
    db_url: "https://atomicwallet.io",
    roa: "3.541",
    roa_lifetime: "3.96",
    total_stake: "27035520568535",
    stake_x_deleg: "1437295086.0465",
    saturated: "0.3932778321789",
    luck_lifetime: "88",
    blocks_epoch: "22",
    blocks_lifetime: "3556",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking Ada Germany",
    icon: "0",
    pool_id: "5e333f3a0fd5a77c3a893eedd7a36b2be6be21cc7706a99cd543bf90",
    db_ticker: "STADA",
    db_name: "Staking Ada Germany",
    db_url: "https://www.staking-ada.de",
    roa: "0",
    roa_lifetime: "1.131",
    total_stake: "35387861497",
    stake_x_deleg: "5897976916.1667",
    saturated: "0.00051477689951288",
    luck_lifetime: "38",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ELMPI",
    icon: "https://cdn.adapools.org/pool_logo/7d49f7486660c27f0fded15ba46ba8e3c8d3427aba360936034d7d9d.png",
    pool_id: "7d49f7486660c27f0fded15ba46ba8e3c8d3427aba360936034d7d9d",
    db_ticker: "ELMPI",
    db_name: "ELMPI",
    db_url: "https://elmpi.net/",
    roa: "2.904",
    roa_lifetime: "2.042",
    total_stake: "891558284778",
    stake_x_deleg: "127365469254",
    saturated: "0.01296923832518",
    luck_lifetime: "83",
    blocks_epoch: "1",
    blocks_lifetime: "14",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Reet good Cardano",
    icon: "https://cdn.adapools.org/pool_logo/6c3c35c806ef129a5664af590f00db662f90f19ae78b6421f049ebb9.png",
    pool_id: "6c3c35c806ef129a5664af590f00db662f90f19ae78b6421f049ebb9",
    db_ticker: "REETG",
    db_name: "Reet good Cardano",
    db_url: "https://reetgoodcardano.co.uk/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20681776367",
    stake_x_deleg: "5170444091.75",
    saturated: "0.00030085176849485",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "14700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FORTIS",
    icon: "0",
    pool_id: "545222d246ff149401791e5b001b7fa5223597f0e17298d156ea37e0",
    db_ticker: "FORTS",
    db_name: "FORTIS",
    db_url: "https://fort.is",
    roa: "0",
    roa_lifetime: "1.401",
    total_stake: "20941163042",
    stake_x_deleg: "2991594720.2857",
    saturated: "0.00030462499079998",
    luck_lifetime: "65",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "7495000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tiger Pool",
    icon: "0",
    pool_id: "12b5c6db2202c6439978eab8549dfe0ad2ffff0cf3c731c64a8d3c80",
    db_ticker: "TIG",
    db_name: "Tiger Pool",
    db_url: "http://www.thetigerparty.com/",
    roa: "0",
    roa_lifetime: "4.269",
    total_stake: "58097128549",
    stake_x_deleg: "14524282137.25",
    saturated: "0.00084512198363812",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "197",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "48400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " The DUCK Pool",
    icon: "0",
    pool_id: "8fdf253d44c9817d7dfd72f392a234aaab546d22cbcbc5984f84fc1f",
    db_ticker: "DUCK",
    db_name: " The DUCK Pool",
    db_url: "https://theduckpool.jimdofree.com",
    roa: "0",
    roa_lifetime: "2.761",
    total_stake: "87135376550",
    stake_x_deleg: "5445961034.375",
    saturated: "0.0012675329076356",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "63",
    tax_ratio: "0",
    tax_fix: "1000000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "All In On Ada",
    icon: "0",
    pool_id: "3973fc5be925fb8177b09348319b77ee72146bac9950692f3e3cd732",
    db_ticker: "AIOA",
    db_name: "All In On Ada",
    db_url: "https://allinonada.net/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "15104024701",
    stake_x_deleg: "2157717814.4286",
    saturated: "0.00021971384188914",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Argyros_Crypto",
    icon: "0",
    pool_id: "f04fd2e69f967986b370fe95dd6bc4f7dce314c270a5cfbb9e6a540f",
    db_ticker: "AGC",
    db_name: "Argyros_Crypto",
    db_url: "http://argyroscrypto.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10830601359",
    stake_x_deleg: "1547228765.5714",
    saturated: "0.00015754959897531",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "We are ANADA",
    icon: "0",
    pool_id: "f5256fd81c12e0fe3e9d374257f00126e969ce46b1225d6c45a5efde",
    db_ticker: "ANADA",
    db_name: "We are ANADA",
    db_url: "http://anadapool.site/",
    roa: "3.333",
    roa_lifetime: "5.321",
    total_stake: "37474416536",
    stake_x_deleg: "5353488076.5714",
    saturated: "0.00054512940707344",
    luck_lifetime: "267",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Money",
    icon: "https://cdn.adapools.org/pool_logo/4d911c0cb99d3d8c43736e54fce596ee791cfc2fbd48405a3c6dac61.png",
    pool_id: "4d911c0cb99d3d8c43736e54fce596ee791cfc2fbd48405a3c6dac61",
    db_ticker: "ADMON",
    db_name: "ADA Money",
    db_url: "https://www.adamoney.net",
    roa: "6.751",
    roa_lifetime: "3.486",
    total_stake: "1189865716476",
    stake_x_deleg: "237973143295.2",
    saturated: "0.017308629525865",
    luck_lifetime: "121",
    blocks_epoch: "2",
    blocks_lifetime: "49",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPARK",
    icon: "0",
    pool_id: "00f52def8702b95aa04451ba3dcec0f3f0f4205e54e77f781bcb3fa5",
    db_ticker: "SPARK",
    db_name: "SPARK",
    db_url: "https://sparkadapool.eu",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1699652055",
    stake_x_deleg: "242807436.42857",
    saturated: "0.000024724342701459",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano On The Moon",
    icon: "0",
    pool_id: "b3e0db9b931d82cd340dbde7175b9f39166bc2cb2e46e2d6fad1b35d",
    db_ticker: "ON1",
    db_name: "Cardano On The Moon",
    db_url: "https://sites.google.com/view/cardanoonthemoon/inicio",
    roa: "0",
    roa_lifetime: "3.689",
    total_stake: "3142435132",
    stake_x_deleg: "448919304.57143",
    saturated: "0.0000457120873017",
    luck_lifetime: "111",
    blocks_epoch: "0",
    blocks_lifetime: "42",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mirqur.io 2",
    icon: "0",
    pool_id: "a8d6081e6813c79d759eca0258f2e6771c11aca1eeb5f22ebe7e00ad",
    db_ticker: "MRQR2",
    db_name: "Mirqur.io 2",
    db_url: "https://www.mirqur.io/",
    roa: "0",
    roa_lifetime: "7.344",
    total_stake: "2373254401",
    stake_x_deleg: "339036343",
    saturated: "0.000034523039557099",
    luck_lifetime: "312",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "275000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool for EUTXO innovation",
    icon: "0",
    pool_id: "6814b41473d2cdc32f12421d3944fb700a7c332edc48838acd7781ca",
    db_ticker: "EUTXO",
    db_name: "Pool for EUTXO innovation",
    db_url: "https://eutxo.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1340709010",
    stake_x_deleg: "191529858.57143",
    saturated: "0.000019502902919842",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adapool.finance",
    icon: "0",
    pool_id: "7335d1d638a94cda6fee29cb6cb2511da81be96eab0330af85dbe01d",
    db_ticker: "ADAFI",
    db_name: "adapool.finance",
    db_url: "https://adapool.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3929848975",
    stake_x_deleg: "561406996.42857",
    saturated: "0.000057166366808457",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "May the Meme's Be With You",
    icon: "https://cdn.adapools.org/pool_logo/c65a6561ab578bd07d88bc8b0461e84302a22ec8055ed31e835e89c9.png",
    pool_id: "c65a6561ab578bd07d88bc8b0461e84302a22ec8055ed31e835e89c9",
    db_ticker: "JEDI",
    db_name: "May the Meme's Be With You",
    db_url: "https://memepools.io/",
    roa: "2.075",
    roa_lifetime: "3.633",
    total_stake: "1066984115930",
    stake_x_deleg: "266746028982.5",
    saturated: "0.015521106723968",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "46",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Akiba Stake Pool",
    icon: "0",
    pool_id: "32cfd0c41c8ab66ecf56edf3001a96cdabf7515ca5c01508306a216c",
    db_ticker: "AKIBA",
    db_name: "Akiba Stake Pool",
    db_url: "https://akibapool.com",
    roa: "0",
    roa_lifetime: "4.276",
    total_stake: "32960058618",
    stake_x_deleg: "5493343103",
    saturated: "0.00047946035915664",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "370",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "W3CZone",
    icon: "0",
    pool_id: "8ce41e11632724915310c1d2fdfcd11e35ad48c54fd598bd58ae1a13",
    db_ticker: "W3C",
    db_name: "W3CZone",
    db_url: "https://www.w3c.zone/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20437485880",
    stake_x_deleg: "3406247646.6667",
    saturated: "0.0002972981460334",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "15500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OK-Man",
    icon: "0",
    pool_id: "9506474755e07102055dce7b06cc68725eccf75a53b0ca96b8fe45f7",
    db_ticker: "OKMAN",
    db_name: "OK-Man",
    db_url: "http://ok-man.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "99202723353",
    stake_x_deleg: "24800680838.25",
    saturated: "0.0014430730818595",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GomaIsdrgl",
    icon: "https://cdn.adapools.org/pool_logo/9021035ba7bf0b5ecb49aba303fe9bd4b80d99f7b4519854f24f71a1.png",
    pool_id: "9021035ba7bf0b5ecb49aba303fe9bd4b80d99f7b4519854f24f71a1",
    db_ticker: "GOMA",
    db_name: "GomaIsdrgl",
    db_url: "https://gomapool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50592389442",
    stake_x_deleg: "16864129814",
    saturated: "0.00073595273277841",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Read Thy Self",
    icon: "https://cdn.adapools.org/pool_logo/57f6917153d55a65b2340d3bba82e2b1b35e1b033653a8893c27d8e1.png",
    pool_id: "57f6917153d55a65b2340d3bba82e2b1b35e1b033653a8893c27d8e1",
    db_ticker: "TAROT",
    db_name: "Read Thy Self",
    db_url: "https://www.secretdecks.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46627584579",
    stake_x_deleg: "5828448072.375",
    saturated: "0.00067827787286291",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA4 SOIL",
    icon: "https://cdn.adapools.org/pool_logo/48b18f581c5e77a19870e8399e4d2100c66b2e15243c9557e79f2165.png",
    pool_id: "48b18f581c5e77a19870e8399e4d2100c66b2e15243c9557e79f2165",
    db_ticker: "SOIL",
    db_name: "ADA4 SOIL",
    db_url: "https://ada4.io/soil",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8436208142",
    stake_x_deleg: "2109052035.5",
    saturated: "0.00012271905922748",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Drinks Stake Pool",
    icon: "0",
    pool_id: "3010f955ac5dcd632746e2af4fbca2c94d6d278edda0d95dc0fb9c78",
    db_ticker: "DRINK",
    db_name: "Drinks Stake Pool",
    db_url: "https://drinkspool.com",
    roa: "0",
    roa_lifetime: "3.268",
    total_stake: "36177873267",
    stake_x_deleg: "7235574653.4",
    saturated: "0.00052626897030597",
    luck_lifetime: "151",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardturions",
    icon: "https://cdn.adapools.org/pool_logo/fa2a1c8668201f1936d0b0e3a3942caf6ad90fa501e761735772c591.png",
    pool_id: "fa2a1c8668201f1936d0b0e3a3942caf6ad90fa501e761735772c591",
    db_ticker: "CDTRN",
    db_name: "Cardturions",
    db_url: "https://cardturions.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "15559551029",
    stake_x_deleg: "2593258504.8333",
    saturated: "0.00022634025051783",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Wheel House Performance",
    icon: "0",
    pool_id: "343ee6d1a29b68ff30f725c827bce2c553ba094513ae6a562737e19d",
    db_ticker: "CWHP",
    db_name: "Cardano Wheel House Performance",
    db_url: "https://cwhp.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9208582307",
    stake_x_deleg: "1534763717.8333",
    saturated: "0.00013395456092504",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "demu",
    icon: "0",
    pool_id: "bd50a2d0dc45aba9348665774a10a3096df9bed4b90e925956ee534e",
    db_ticker: "DEMU",
    db_name: "demu",
    db_url: "https://demu.pro",
    roa: "3.333",
    roa_lifetime: "19.348",
    total_stake: "71689232403",
    stake_x_deleg: "11948205400.5",
    saturated: "0.0010428423539525",
    luck_lifetime: "1127",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "432000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jasmine Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/60a85cbdeaa3cc9962c7035f5b4e072ca9aac5ee9082d4c7c24ca732.png",
    pool_id: "60a85cbdeaa3cc9962c7035f5b4e072ca9aac5ee9082d4c7c24ca732",
    db_ticker: "JASM",
    db_name: "Jasmine Stake Pool",
    db_url: "http://www.dk-lab.ml:6285",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "45305003646",
    stake_x_deleg: "7550833941",
    saturated: "0.00065903867379172",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Smart Contract Made Easy",
    icon: "0",
    pool_id: "8116abbf4579892bd874dcd6c3e8e647ceadd5fec9e39ac053ef301b",
    db_ticker: "SCEZ",
    db_name: "Smart Contract Made Easy",
    db_url: "http://pool.smartcontractsez.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5983298312",
    stake_x_deleg: "997216385.33333",
    saturated: "0.000087037295378054",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "1100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zipang Stake Pool 2",
    icon: "0",
    pool_id: "dd777ca1053943ccf29a781014f4472405fb073020a37240bf68cc71",
    db_ticker: "ZPNG2",
    db_name: "Zipang Stake Pool 2",
    db_url: "https://zpngstakepool.com/",
    roa: "0",
    roa_lifetime: "3.942",
    total_stake: "6104635187",
    stake_x_deleg: "1017439197.8333",
    saturated: "0.000088802347507987",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "23",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "banC StakePool",
    icon: "https://cdn.adapools.org/pool_logo/e6d3f91ef201fd14c0a3d740a1311039c43850d283a6a14ecdee6ff1.png",
    pool_id: "e6d3f91ef201fd14c0a3d740a1311039c43850d283a6a14ecdee6ff1",
    db_ticker: "BANC",
    db_name: "banC StakePool",
    db_url: "https://bancpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "316980633268",
    stake_x_deleg: "52830105544.667",
    saturated: "0.0046110248174551",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0115",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SAUNA Cardano Pool",
    icon: "0",
    pool_id: "83850c9c2d575701c440f963101be29d31210fef4cb3285fc0887661",
    db_ticker: "SAUNA",
    db_name: "SAUNA Cardano Pool",
    db_url: "https://ada-sauna.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "13384425687",
    stake_x_deleg: "2230737614.5",
    saturated: "0.0001946993365931",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "United States of ADA",
    icon: "0",
    pool_id: "7138a8f6e3983e7be1e4a48cf7915964f6e7d1aa19a6407d00057e68",
    db_ticker: "USA",
    db_name: "United States of ADA",
    db_url: "https://cardano.org/stake-pool-delegation/",
    roa: "2.354",
    roa_lifetime: "3.472",
    total_stake: "156594717215",
    stake_x_deleg: "26099119535.833",
    saturated: "0.0022779376768746",
    luck_lifetime: "144",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CASINDO",
    icon: "0",
    pool_id: "0c4a944c3d5c364fcd5325c7aae7e0488c6b1c0aa1976990be0227fb",
    db_ticker: "CSID",
    db_name: "CASINDO",
    db_url: "https://casindo.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7031993592",
    stake_x_deleg: "1171998932",
    saturated: "0.00010229235974011",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VietCore Pool",
    icon: "0",
    pool_id: "dce4ff4ab3d3e8d94cd554ea039e54b3c8ce8c3ef8378ac4e76f2237",
    db_ticker: "VIET",
    db_name: "VietCore Pool",
    db_url: "https://adapools.org/VIET",
    roa: "0",
    roa_lifetime: "5.004",
    total_stake: "14528834305",
    stake_x_deleg: "2421472384.1667",
    saturated: "0.00021134671496605",
    luck_lifetime: "270",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BIOME Pool",
    icon: "0",
    pool_id: "56b8ba4a80151c87961230e5c46e570121f47765c125eeabd4214bfd",
    db_ticker: "BIOME",
    db_name: "BIOME Pool",
    db_url: "https://github.com/wir3code",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2869440505",
    stake_x_deleg: "478240084.16667",
    saturated: "0.000041740914087894",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Southeast Staking",
    icon: "0",
    pool_id: "1b7e19a0006d2a6566de81767bfe841e3cd3537fd95cb683abfb7749",
    db_ticker: "SES",
    db_name: "Southeast Staking",
    db_url: "http://southeaststaking.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1217236810",
    stake_x_deleg: "202872801.66667",
    saturated: "0.000017706788839949",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "43000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CIEL Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/88021ea76e8b0141feb976cccf0887a9a2385cc58370283c40abf06e.png",
    pool_id: "88021ea76e8b0141feb976cccf0887a9a2385cc58370283c40abf06e",
    db_ticker: "CIEL",
    db_name: "CIEL Stake Pool",
    db_url: "https://twitter.com/CIEL_Stake_Pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2851883148",
    stake_x_deleg: "475313858",
    saturated: "0.000041485512336623",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ChillerBude",
    icon: "0",
    pool_id: "c74fa52184a0dfab53f1bf3f00dd6153d6ce18194bfa31609993531d",
    db_ticker: "CHILL",
    db_name: "ChillerBude",
    db_url: "https://pool.chillerbude.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2797155590",
    stake_x_deleg: "466192598.33333",
    saturated: "0.000040689406512948",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fione",
    icon: "0",
    pool_id: "5fd0e6fdb98b29d0459fdf8363fe3e591bb94afcc412f41ea67496e6",
    db_ticker: "FIONE",
    db_name: "Fione",
    db_url: "https://fione.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "294885420",
    stake_x_deleg: "49147570",
    saturated: "0.0000042896121946228",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zero Percent Staking",
    icon: "0",
    pool_id: "d04e478446390de60b5905a0e29a57bc562c936c3620b1a1763cf0ef",
    db_ticker: "ZPCT",
    db_name: "Zero Percent Staking",
    db_url: "https://zeropercentstaking.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "27747555223",
    stake_x_deleg: "9249185074.3333",
    saturated: "0.00040363559261611",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "17000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Harbor Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/aa5fc91d88b98507943118997ff06f6cd2e27cd8d79d7e89e7f406b9.png",
    pool_id: "aa5fc91d88b98507943118997ff06f6cd2e27cd8d79d7e89e7f406b9",
    db_ticker: "BOATS",
    db_name: "Harbor Stake Pool",
    db_url: "https://harborstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5922268888",
    stake_x_deleg: "1480567222",
    saturated: "0.000086149518147761",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NECTR",
    icon: "0",
    pool_id: "2776a773d3744f514970cade5e5729f53843d5fb90f912cf97dbcdd0",
    db_ticker: "NECTR",
    db_name: "NECTR",
    db_url: "https://nectr.one",
    roa: "0",
    roa_lifetime: "3.745",
    total_stake: "56019253058",
    stake_x_deleg: "11203850611.6",
    saturated: "0.00081489573493074",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "223",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADELA Pool",
    icon: "0",
    pool_id: "1d9cc6b732016e62b7ac8ff4a9c5d467c5020490bef1459b024b2123",
    db_ticker: "ADELA",
    db_name: "ADELA Pool",
    db_url: "https://adela.dev",
    roa: "3.333",
    roa_lifetime: "1.761",
    total_stake: "105048698303",
    stake_x_deleg: "52524349151.5",
    saturated: "0.001528112774344",
    luck_lifetime: "73",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Coinbase Cloud",
    icon: "0",
    pool_id: "66c1296e3bee372fc37282535eb45710d9c8b96b05248d7ea636352f",
    db_ticker: "CLOUD",
    db_name: "Coinbase Cloud",
    db_url: "https://coinbase.com/cloud",
    roa: "0",
    roa_lifetime: "1.296",
    total_stake: "5599885607",
    stake_x_deleg: "799983658.14286",
    saturated: "0.000081459902589555",
    luck_lifetime: "67",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dana Node",
    icon: "0",
    pool_id: "52ffc8b767d55223231209643812d8de18685a4b0388ff08a7575803",
    db_ticker: "DNND",
    db_name: "Dana Node",
    db_url: " https://dananode.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "21174734305",
    stake_x_deleg: "4234946861",
    saturated: "0.00030802268383641",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "18308000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Azullian Pool",
    icon: "https://cdn.adapools.org/pool_logo/b9f9c1e90c5e20e7e1b244d749cb118f6594a11ea68dffd9b5a3bc28.png",
    pool_id: "b9f9c1e90c5e20e7e1b244d749cb118f6594a11ea68dffd9b5a3bc28",
    db_ticker: "AZP",
    db_name: "Azullian Pool",
    db_url: "https://azullian.com",
    roa: "0",
    roa_lifetime: "1.815",
    total_stake: "15768277545",
    stake_x_deleg: "7884138772.5",
    saturated: "0.00022937653426619",
    luck_lifetime: "126",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.03",
    tax_fix: "360000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "4KOMA-StakePool",
    icon: "0",
    pool_id: "d0fb74f0b1d39a9046ae4e967180cc6c8dd20fb44f8877980fe36604",
    db_ticker: "4KOMA",
    db_name: "4KOMA-StakePool",
    db_url: "https://twitter.com/4KOMA_StakePool",
    roa: "3.056",
    roa_lifetime: "3.369",
    total_stake: "2035393133063",
    stake_x_deleg: "101769656653.15",
    saturated: "0.029608270237432",
    luck_lifetime: "94",
    blocks_epoch: "3",
    blocks_lifetime: "85",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NeoCasbah",
    icon: "0",
    pool_id: "2c69918f9d408e48fec0a24986fa56f1325fb427d6a279cd7bdb825a",
    db_ticker: "CASBA",
    db_name: "NeoCasbah",
    db_url: "https://neocasbah.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11083547903",
    stake_x_deleg: "2216709580.6",
    saturated: "0.0001612291385732",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "11000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Grasp",
    icon: "0",
    pool_id: "1e1a028f497cdcc8fb5d4da8d34788ef37fbeeb6379075f33eab7ee4",
    db_ticker: "GALT1",
    db_name: "The Grasp",
    db_url: "https://tesseraecrypto.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "57073663640",
    stake_x_deleg: "14268415910",
    saturated: "0.00083023393812399",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PoolingTogether",
    icon: "0",
    pool_id: "60f9109e1070286b0e27c67aceb75e5eac3beb7a03c1550685ceaebb",
    db_ticker: "PTGR",
    db_name: "PoolingTogether",
    db_url: "https://poolingtogetherptgr.com/",
    roa: "0",
    roa_lifetime: "2.316",
    total_stake: "111907315724",
    stake_x_deleg: "22381463144.8",
    saturated: "0.0016278830814937",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "9",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "740000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Catalyst Circle Admin Pool",
    icon: "0",
    pool_id: "ac66c8efb8edba3991576235e421ef0524ab351a22598de520de7b16",
    db_ticker: "ADMIN",
    db_name: "Catalyst Circle Admin Pool",
    db_url: "https://www.catalystcircle.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "44770792564",
    stake_x_deleg: "7461798760.6667",
    saturated: "0.00065126766099682",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BitLamp.ch",
    icon: "0",
    pool_id: "ed971f72aa9a7282263d5b3588e3556f7cd774a81acb691ba713a450",
    db_ticker: "LAMP",
    db_name: "BitLamp.ch",
    db_url: "https://bitlamp.ch",
    roa: "3.333",
    roa_lifetime: "1.692",
    total_stake: "13519993817",
    stake_x_deleg: "2703998763.4",
    saturated: "0.00019667140663865",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "18",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "4100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Create Planets Stakepool",
    icon: "0",
    pool_id: "003e98e43df6cfe3bc2a91a9a3978a07dfa5b9ead7e91f79615d9428",
    db_ticker: "CPS",
    db_name: "Create Planets Stakepool",
    db_url: "https://createplanets.com/",
    roa: "2.73",
    roa_lifetime: "4.731",
    total_stake: "8528628527643",
    stake_x_deleg: "99170099158.64",
    saturated: "0.12406347162089",
    luck_lifetime: "100",
    blocks_epoch: "4",
    blocks_lifetime: "1851",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ReadyStakePool",
    icon: "0",
    pool_id: "e637bd0d0d5f5f075403c4a2cdfeae80ce1fb6a5a042be102642b9c6",
    db_ticker: "READY",
    db_name: "ReadyStakePool",
    db_url: "https://git.io/JR2Vl",
    roa: "0",
    roa_lifetime: "5.594",
    total_stake: "14916085770",
    stake_x_deleg: "2983217154",
    saturated: "0.0002169799490766",
    luck_lifetime: "242",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2650000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dharma Pool",
    icon: "0",
    pool_id: "f63b86c6ca8bc67885d434c9a6d4b1595d3a39de8bf7a7749c040770",
    db_ticker: "DHARM",
    db_name: "Dharma Pool",
    db_url: "https://dharmapool.com/",
    roa: "0",
    roa_lifetime: "2.102",
    total_stake: "33890425992",
    stake_x_deleg: "6778085198.4",
    saturated: "0.00049299414198317",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "1ea8f97684ac89e337501c847e4adcd90e60d8f602066e15488cdc4b",
    db_ticker: "RAY6",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20497521216",
    stake_x_deleg: "4099504243.2",
    saturated: "0.0002981714625555",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GenieStakePool",
    icon: "https://cdn.adapools.org/pool_logo/a2d7c530964b52d333cc664b1fc5acb697f1e3281787af54b021215e.png",
    pool_id: "a2d7c530964b52d333cc664b1fc5acb697f1e3281787af54b021215e",
    db_ticker: "GENIE",
    db_name: "GenieStakePool",
    db_url: "https://genieblockchain.netlify.app",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6889275902",
    stake_x_deleg: "1377855180.4",
    saturated: "0.00010021628713058",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "We are Grabber",
    icon: "0",
    pool_id: "6d043d8910b0050cd4f497834c7c8a515e62252352c802b4f3458443",
    db_ticker: "ELLY7",
    db_name: "We are Grabber",
    db_url: "https://fimi.vn/ada",
    roa: "0",
    roa_lifetime: "2.825",
    total_stake: "2132836767",
    stake_x_deleg: "426567353.4",
    saturated: "0.000031025754358636",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "46",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nudibranch",
    icon: "0",
    pool_id: "ae7c8c856f4931215e3188941022ea65c433eb9c8923cace3c1c39ed",
    db_ticker: "NUDI",
    db_name: "Nudibranch",
    db_url: "http://nudiada.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2517307792",
    stake_x_deleg: "503461558.4",
    saturated: "0.000036618542219491",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake-Crypto",
    icon: "0",
    pool_id: "4f506a2a5325e2d13dbe52b0a4e5b2641788ef6e5d7aadfec86404c0",
    db_ticker: "STCRP",
    db_name: "Stake-Crypto",
    db_url: "https://stake-crypto.co.uk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3446349675",
    stake_x_deleg: "689269935",
    saturated: "0.000050133043515052",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TesPool ",
    icon: "https://cdn.adapools.org/pool_logo/8f77e8a0202c6e39a1c19fc51ae1d822f84b048b32ec22c07b18226d.png",
    pool_id: "8f77e8a0202c6e39a1c19fc51ae1d822f84b048b32ec22c07b18226d",
    db_ticker: "TESP🍀",
    db_name: "TesPool ",
    db_url: "https://tespool.online",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1209766320",
    stake_x_deleg: "241953264",
    saturated: "0.00001759811780086",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "24884130",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Elevate Stake Pool",
    icon: "0",
    pool_id: "e86907283ec8c2cca199e639dd6904a3f1d6c980f2ba1e8df76086d6",
    db_ticker: "ELEV8",
    db_name: "Elevate Stake Pool",
    db_url: "https://elevate-staking.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3968610441",
    stake_x_deleg: "793722088.2",
    saturated: "0.000057730218548686",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Brazil Pool",
    icon: "0",
    pool_id: "50d717b296beeea2167d0c529ceb33634e5f80a049d35e406fccdce1",
    db_ticker: "BRAZL",
    db_name: "Brazil Pool",
    db_url: "https://BrazilPool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "276018461",
    stake_x_deleg: "55203692.2",
    saturated: "0.0000040151600450325",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Innovatio",
    icon: "https://cdn.adapools.org/pool_logo/ef18e97a80f3e1e9633a8cd815f250d574fb308604dee96516157779.png",
    pool_id: "ef18e97a80f3e1e9633a8cd815f250d574fb308604dee96516157779",
    db_ticker: "INNV",
    db_name: "Innovatio",
    db_url: "https://www.innovatiofounder.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "883496799",
    stake_x_deleg: "176699359.8",
    saturated: "0.000012851970242885",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "490000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KRISSHO",
    icon: "0",
    pool_id: "b021458a1b40e5607bb9824e3123b654c27d549ad020853e22212974",
    db_ticker: "KRISS",
    db_name: "KRISSHO",
    db_url: "https://krissho.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1235158899",
    stake_x_deleg: "247031779.8",
    saturated: "0.000017967496241243",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "ce29f6348d2b5170e442c81229d4e463a354a75cd6cabfdde7b4a5d3",
    db_ticker: "RAID2",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "2.661",
    total_stake: "36433673553",
    stake_x_deleg: "9108418388.25",
    saturated: "0.00052999002245637",
    luck_lifetime: "64",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "c7e29b935bcbe69a4a66edec52f86fa28bde6d65d7acea937fa307d0",
    db_ticker: "RAID",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "2.144",
    total_stake: "35263204546",
    stake_x_deleg: "8815801136.5",
    saturated: "0.00051296355120576",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not CryptoPunks Pool",
    icon: "0",
    pool_id: "8e76920827f7768c6faa80fcc8e1b6afebd8129539397032a1a190e5",
    db_ticker: "PUNKS",
    db_name: "Not CryptoPunks Pool",
    db_url: "https://memepools.io/",
    roa: "2.033",
    roa_lifetime: "2.996",
    total_stake: "59121325187",
    stake_x_deleg: "19707108395.667",
    saturated: "0.00086002067340059",
    luck_lifetime: "86",
    blocks_epoch: "0",
    blocks_lifetime: "107",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Evochro.me",
    icon: "0",
    pool_id: "f2132a2d557dc1d00fcc58fe3fdf64e89d1fe080625afd32b60999e0",
    db_ticker: "EVOC",
    db_name: "Evochro.me",
    db_url: "https://evochro.me",
    roa: "2.585",
    roa_lifetime: "2.286",
    total_stake: "1160390767262",
    stake_x_deleg: "290097691815.5",
    saturated: "0.016879866036696",
    luck_lifetime: "80",
    blocks_epoch: "1",
    blocks_lifetime: "35",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bedrock Staking",
    icon: "https://cdn.adapools.org/pool_logo/b046bd7ea0e09f16dc063c9ca5402d930346da176ce74d799fc98169.png",
    pool_id: "b046bd7ea0e09f16dc063c9ca5402d930346da176ce74d799fc98169",
    db_ticker: "BDRK",
    db_name: "Bedrock Staking",
    db_url: "https://www.bedrockstaking.com",
    roa: "0",
    roa_lifetime: "2.29",
    total_stake: "24877414925",
    stake_x_deleg: "12438707462.5",
    saturated: "0.00036188449884356",
    luck_lifetime: "84",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Watch A Video Pool",
    icon: "0",
    pool_id: "63e07c29ffd786939bf65d52acf96354add6bbf56567b1a6e30986fa",
    db_ticker: "WVP",
    db_name: "Watch A Video Pool",
    db_url: "http://pool.watchavideo.com",
    roa: "0",
    roa_lifetime: "1.458",
    total_stake: "90599644190",
    stake_x_deleg: "22649911047.5",
    saturated: "0.0013179265985613",
    luck_lifetime: "52",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.002",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "5hare pool",
    icon: "https://cdn.adapools.org/pool_logo/73a527af03d38b3659f20b263000a1f72d3f3b867df5a2bb0e5516f1.png",
    pool_id: "73a527af03d38b3659f20b263000a1f72d3f3b867df5a2bb0e5516f1",
    db_ticker: "5HARE",
    db_name: "5hare pool",
    db_url: "https://5hare-pool.5pecia1.dev",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "21937925585",
    stake_x_deleg: "7312641861.6667",
    saturated: "0.00031912460478427",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Derksen IT pool",
    icon: "0",
    pool_id: "ba05a5e3e734b6714081a6691376b32eecfea1f55fe73cce48cfa9c3",
    db_ticker: "DRKSN",
    db_name: "Derksen IT pool",
    db_url: "https://github.com/ronaldderksen/cardano",
    roa: "3.333",
    roa_lifetime: "7.885",
    total_stake: "40665759415",
    stake_x_deleg: "10166439853.75",
    saturated: "0.00059155294110567",
    luck_lifetime: "383",
    blocks_epoch: "1",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite I",
    icon: "https://cdn.adapools.org/pool_logo/49603e150a4abbb2868bcd9cb7e7c41b018ead754fb202d3982d2582.png",
    pool_id: "49603e150a4abbb2868bcd9cb7e7c41b018ead754fb202d3982d2582",
    db_ticker: "NUFII",
    db_name: "Nu.Fi &amp; AdaLite I",
    db_url: "https://nu.fi",
    roa: "0",
    roa_lifetime: "4.456",
    total_stake: "10506961709",
    stake_x_deleg: "3502320569.6667",
    saturated: "0.00015284170738371",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "2173",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TheAdaTeam",
    icon: "0",
    pool_id: "9127a24f62d03011d4f95bc766d8b2ab3849e9af037f0edf0f97e079",
    db_ticker: "ATEAM",
    db_name: "TheAdaTeam",
    db_url: "https://theada.team",
    roa: "0",
    roa_lifetime: "4.167",
    total_stake: "11536676262",
    stake_x_deleg: "3845558754",
    saturated: "0.00016782066464626",
    luck_lifetime: "203",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Random French Pool ",
    icon: "0",
    pool_id: "4a4b968fae3ca42471841d7f6da8cfc64bfd8cf0cb9cd443da315a7f",
    db_ticker: "RND🇫🇷",
    db_name: "Random French Pool ",
    db_url: "https://randomfrenchpool.com",
    roa: "0.4",
    roa_lifetime: "2.058",
    total_stake: "18517235626",
    stake_x_deleg: "6172411875.3333",
    saturated: "0.00026936482567363",
    luck_lifetime: "57",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LIA Increments ADA",
    icon: "https://cdn.adapools.org/pool_logo/91c7103cac7acd53a7c7e56c6fb77db0c8d89ebcb6b0828c7f14aebe.png",
    pool_id: "91c7103cac7acd53a7c7e56c6fb77db0c8d89ebcb6b0828c7f14aebe",
    db_ticker: "LIA",
    db_name: "LIA Increments ADA",
    db_url: "https://www.liapool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5932333237",
    stake_x_deleg: "1977444412.3333",
    saturated: "0.000086295921297165",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Alu &amp; Uifi",
    icon: "0",
    pool_id: "496fb41d19efcaf6a6028c837571ad93cef75215e2096fbc365f1aad",
    db_ticker: "ALUIF",
    db_name: "Alu &amp; Uifi",
    db_url: "",
    roa: "0",
    roa_lifetime: "2.698",
    total_stake: "33601228317",
    stake_x_deleg: "11200409439",
    saturated: "0.0004887872677561",
    luck_lifetime: "155",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CHINA Staking Pool for Good Fortune",
    icon: "https://cdn.adapools.org/pool_logo/90623c7965c5d12b86c4adcc09144670ff9009e33dee0fae9b39d13a.png",
    pool_id: "90623c7965c5d12b86c4adcc09144670ff9009e33dee0fae9b39d13a",
    db_ticker: "CHINA",
    db_name: "CHINA Staking Pool for Good Fortune",
    db_url: "https://chinablockchain.io",
    roa: "0",
    roa_lifetime: "1.872",
    total_stake: "2024705879",
    stake_x_deleg: "674901959.66667",
    saturated: "0.000029452805869761",
    luck_lifetime: "39",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tapioca Pool",
    icon: "0",
    pool_id: "9567685d24580eb738a55ad2e60f6c3cfc40296ce39e6f86f9f884ed",
    db_ticker: "TAPC",
    db_name: "Tapioca Pool",
    db_url: "https://tapioca.link/en/homepage",
    roa: "0.57",
    roa_lifetime: "2.993",
    total_stake: "2656273135",
    stake_x_deleg: "885424378.33333",
    saturated: "0.000038640030531672",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1730000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mamba Pool",
    icon: "https://cdn.adapools.org/pool_logo/3162c21a847e9869471ae27b7f1f52600291c1f5bbbea8e3572d3f8f.png",
    pool_id: "3162c21a847e9869471ae27b7f1f52600291c1f5bbbea8e3572d3f8f",
    db_ticker: "MAMBA",
    db_name: "Mamba Pool",
    db_url: "https://mambapool.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "423518800",
    stake_x_deleg: "141172933.33333",
    saturated: "0.0000061608044545981",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TLV Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/059d0cf2c00cf22bdca8963975c33e707cc3ecfd82336fc6bfbc5343.png",
    pool_id: "059d0cf2c00cf22bdca8963975c33e707cc3ecfd82336fc6bfbc5343",
    db_ticker: "TLV",
    db_name: "TLV Stake Pool",
    db_url: "https://www.adatlv.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "35245729",
    stake_x_deleg: "11748576.333333",
    saturated: "5.1270933953524e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amici Elettronici",
    icon: "https://cdn.adapools.org/pool_logo/d5304843b72bb13b70008c4b82a7bba391d89bc2feacead97f58286d.png",
    pool_id: "d5304843b72bb13b70008c4b82a7bba391d89bc2feacead97f58286d",
    db_ticker: "AMICI",
    db_name: "Amici Elettronici",
    db_url: "https://amicielettronici.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1075435349",
    stake_x_deleg: "358478449.66667",
    saturated: "0.000015644044346441",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Duck Puddle",
    icon: "https://cdn.adapools.org/pool_logo/b476b3354de12a7a9d063da6cfc3b0f3bde4c6cac07eeb6af2e7fcad.png",
    pool_id: "b476b3354de12a7a9d063da6cfc3b0f3bde4c6cac07eeb6af2e7fcad",
    db_ticker: "QUACK",
    db_name: "Duck Puddle",
    db_url: "http://duckpuddle.us",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2987126772",
    stake_x_deleg: "995708924",
    saturated: "0.000043452861888036",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RedCloud Stake Pool",
    icon: "0",
    pool_id: "69d93de6cef78d33979c03ca4aa3818b22176677be5654a0b72b654f",
    db_ticker: "REDC",
    db_name: "RedCloud Stake Pool",
    db_url: "https://redcloudsecurity.ca",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2060030794",
    stake_x_deleg: "686676931.33333",
    saturated: "0.000029966667104942",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Maple Pool",
    icon: "https://cdn.adapools.org/pool_logo/063951946e3d221231229a73c92446fdb9532d813e3b0d6144f16ece.png",
    pool_id: "063951946e3d221231229a73c92446fdb9532d813e3b0d6144f16ece",
    db_ticker: "MPOL",
    db_name: "Maple Pool",
    db_url: "https://twitter.com/maple_pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "221622018",
    stake_x_deleg: "73874006",
    saturated: "0.0000032238708546856",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Holy Tiger",
    icon: "https://cdn.adapools.org/pool_logo/726f46e3179898154ef9cfbd0fdf5f0bf70b53aabcbc642f05e8b3d7.png",
    pool_id: "726f46e3179898154ef9cfbd0fdf5f0bf70b53aabcbc642f05e8b3d7",
    db_ticker: "TIGR",
    db_name: "Holy Tiger",
    db_url:
      "https://adapools.org/pool/726f46e3179898154ef9cfbd0fdf5f0bf70b53aabcbc642f05e8b3d7",
    roa: "2.133",
    roa_lifetime: "2.852",
    total_stake: "173088570623",
    stake_x_deleg: "57696190207.667",
    saturated: "0.0025178689515889",
    luck_lifetime: "144",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Charity Lounge",
    icon: "https://cdn.adapools.org/pool_logo/4b9049b66d926ada722408b9beeaae9ef3d2ffe2377d9b17f6e078ef.png",
    pool_id: "4b9049b66d926ada722408b9beeaae9ef3d2ffe2377d9b17f6e078ef",
    db_ticker: "CLP",
    db_name: "Charity Lounge",
    db_url: "https://www.charitylounge.info",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3229888162",
    stake_x_deleg: "1076629387.3333",
    saturated: "0.000046984241021422",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AZ5 Stake Pool",
    icon: "0",
    pool_id: "11049c12ebde61e94531eb2d022b44ba4fdbc48eea14bf6cbf2a6f44",
    db_ticker: "AZ5",
    db_name: "AZ5 Stake Pool",
    db_url: "https://www.az5.io",
    roa: "0.724",
    roa_lifetime: "3.198",
    total_stake: "1556841452625",
    stake_x_deleg: "111202960901.79",
    saturated: "0.022646918522708",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "122",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Plus",
    icon: "0",
    pool_id: "ad35f18c2fec51045b6433c7b3bacddb6dcf51696ff85330294b0db0",
    db_ticker: "ADPL",
    db_name: "ADA Plus",
    db_url: "http://adpl.swebe.vn/",
    roa: "1.934",
    roa_lifetime: "3.945",
    total_stake: "186547185594",
    stake_x_deleg: "7772799399.75",
    saturated: "0.0027136474980573",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "105",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tokhun",
    icon: "https://cdn.adapools.org/pool_logo/af9519e909a139ab786282eb87e2bc903fa1ec8120e8cd0a3a506a47.png",
    pool_id: "af9519e909a139ab786282eb87e2bc903fa1ec8120e8cd0a3a506a47",
    db_ticker: "TKHN1",
    db_name: "Tokhun",
    db_url: "https://tokhun.io",
    roa: "2.423",
    roa_lifetime: "1.46",
    total_stake: "242231382810",
    stake_x_deleg: "1542875049.7452",
    saturated: "0.0035236692733813",
    luck_lifetime: "71",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OKAYAMA",
    icon: "0",
    pool_id: "adef0d0f8e9f3a9aa450d98e5db372fa817212929889657f2f89cfab",
    db_ticker: "OKYM",
    db_name: "OKAYAMA",
    db_url: "https://twitter.com/PoolOkayama",
    roa: "5.247",
    roa_lifetime: "3.977",
    total_stake: "3201642073417",
    stake_x_deleg: "188331886671.59",
    saturated: "0.046573353409426",
    luck_lifetime: "104",
    blocks_epoch: "4",
    blocks_lifetime: "238",
    tax_ratio: "0.007",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TAKOYAKI Stake Pool",
    icon: "0",
    pool_id: "e9c2db878b29143e6f458568a1b2b3b5f2fe8131308c484528e32da1",
    db_ticker: "TKY",
    db_name: "TAKOYAKI Stake Pool",
    db_url: "https://twitter.com/TAKO3_Takoyaki",
    roa: "1.563",
    roa_lifetime: "3.559",
    total_stake: "15077448983",
    stake_x_deleg: "5025816327.6667",
    saturated: "0.00021932725267082",
    luck_lifetime: "119",
    blocks_epoch: "0",
    blocks_lifetime: "39",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CCJ5",
    icon: "0",
    pool_id: "7fbab5f400e70149e558bbee678313fd8421ab478c2a8dfea474c0c4",
    db_ticker: "CCJ5",
    db_name: "CCJ5",
    db_url: "http://ccj123.html.xdomain.jp",
    roa: "3.12",
    roa_lifetime: "4.649",
    total_stake: "214829452000",
    stake_x_deleg: "26853681500",
    saturated: "0.0031250613782917",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "2770",
    tax_ratio: "0.048",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MANMARU ADA POOL",
    icon: "0",
    pool_id: "277a12172ea62110319a288c6700e19c6563e09f596de857326314b8",
    db_ticker: "MAP",
    db_name: "MANMARU ADA POOL",
    db_url: "https://twitter.com/maru7358",
    roa: "3.581",
    roa_lifetime: "3.046",
    total_stake: "1853852053027",
    stake_x_deleg: "115865753314.19",
    saturated: "0.026967445096781",
    luck_lifetime: "100",
    blocks_epoch: "3",
    blocks_lifetime: "80",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "versorium",
    icon: "0",
    pool_id: "73521bea76afce6a7da7fb18edcefc786fcb1b82e3dca0574bd0df3c",
    db_ticker: "VRSM",
    db_name: "versorium",
    db_url: "https://versorium.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "54866434354",
    stake_x_deleg: "18288811451.333",
    saturated: "0.00079812601748975",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Verified Ventures Mark 1",
    icon: "0",
    pool_id: "d96ef9b506fa0ddd45d08099eeebf531ffc1c68eb2ff31931bce967a",
    db_ticker: "VVM1",
    db_name: "Verified Ventures Mark 1",
    db_url: "https://verified.ventures/staking/1",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "26210533956",
    stake_x_deleg: "6552633489",
    saturated: "0.00038127699255268",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Byrd Pool",
    icon: "0",
    pool_id: "4fe880d45cd6170400e70adc79591d8562cbfee07e25e506422da0d7",
    db_ticker: "BYRD",
    db_name: "Byrd Pool",
    db_url:
      "https://adapools.org/pool/4fe880d45cd6170400e70adc79591d8562cbfee07e25e506422da0d7",
    roa: "2.884",
    roa_lifetime: "2.214",
    total_stake: "1603695689797",
    stake_x_deleg: "178188409977.44",
    saturated: "0.023328493444733",
    luck_lifetime: "76",
    blocks_epoch: "4",
    blocks_lifetime: "13",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Poolarized",
    icon: "https://cdn.adapools.org/pool_logo/ec5b48b7106b22ca49c4fbafad4b46d0524d6d8527cc90432ddd4862.png",
    pool_id: "ec5b48b7106b22ca49c4fbafad4b46d0524d6d8527cc90432ddd4862",
    db_ticker: "PLZ",
    db_name: "Poolarized",
    db_url: "https://poolarized.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "903714802307",
    stake_x_deleg: "180742960461.4",
    saturated: "0.013146075639946",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "505000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Catalyst School: Learning and Innovating",
    icon: "https://cdn.adapools.org/pool_logo/47cb5e8b0e65d199cdfbe035d3c26d0260614f922460ff2b51c45e10.png",
    pool_id: "47cb5e8b0e65d199cdfbe035d3c26d0260614f922460ff2b51c45e10",
    db_ticker: "LEARN",
    db_name: "The Catalyst School: Learning and Innovating",
    db_url: "https://thecatalyst.school/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8554601803",
    stake_x_deleg: "1710920360.6",
    saturated: "0.00012444129728181",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DeKrom Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/ade06ea849709842cf0d93a6679ad8fae5b691b9e90c9973d0e73997.png",
    pool_id: "ade06ea849709842cf0d93a6679ad8fae5b691b9e90c9973d0e73997",
    db_ticker: "KROM",
    db_name: "DeKrom Stake Pool",
    db_url: "https://dekrom.dev",
    roa: "0",
    roa_lifetime: "4.058",
    total_stake: "64584989228",
    stake_x_deleg: "32292494614",
    saturated: "0.0009394990006017",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "196",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The JDFR pool",
    icon: "0",
    pool_id: "e21d2f6855c1b5023a18e34f35b55b2ad1141330dce7f8d00357e9be",
    db_ticker: "JDFR",
    db_name: "The JDFR pool",
    db_url: "https://jduquennoy.gitlab.io/jdfr-stake/",
    roa: "0",
    roa_lifetime: "4.186",
    total_stake: "5902168208",
    stake_x_deleg: "1967389402.6667",
    saturated: "0.000085857119418627",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bridge Builders Pool",
    icon: "https://cdn.adapools.org/pool_logo/73322b2f81fa20871998c33aed30808fcec8e71c0e69ae866824b9d7.png",
    pool_id: "73322b2f81fa20871998c33aed30808fcec8e71c0e69ae866824b9d7",
    db_ticker: "BUILD",
    db_name: "Bridge Builders Pool",
    db_url: "https://www.bridgebuilders.io/",
    roa: "3.333",
    roa_lifetime: "16.88",
    total_stake: "3649817367",
    stake_x_deleg: "1216605789",
    saturated: "0.000053092828684543",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wells Pool",
    icon: "0",
    pool_id: "61c058b1764d58000390d2e1ef29c768cb7a2c452fe64fa65579a21f",
    db_ticker: "WELLS",
    db_name: "Wells Pool",
    db_url: "https://wells-pool.com",
    roa: "0",
    roa_lifetime: "2.064",
    total_stake: "24891820615",
    stake_x_deleg: "6222955153.75",
    saturated: "0.00036209405421424",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanotec",
    icon: "0",
    pool_id: "e5f9fff7747452412cb4d8c7fce151b651eb92a564e918a33a5ba006",
    db_ticker: "CDT",
    db_name: "Cardanotec",
    db_url: "https://cardanotec.com",
    roa: "6.667",
    roa_lifetime: "3.288",
    total_stake: "63878301481",
    stake_x_deleg: "15969575370.25",
    saturated: "0.00092921902006783",
    luck_lifetime: "163",
    blocks_epoch: "1",
    blocks_lifetime: "6",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Plutus Pool 1% fee",
    icon: "0",
    pool_id: "c12fda4a04d21fa768a55babd711527790693fa2dc4e416563f0bf0f",
    db_ticker: "PLUTS",
    db_name: "Plutus Pool 1% fee",
    db_url: "https://www.plutusoracle.com",
    roa: "0",
    roa_lifetime: "4.065",
    total_stake: "8383884321",
    stake_x_deleg: "2095971080.25",
    saturated: "0.00012195791986484",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Xirius",
    icon: "0",
    pool_id: "e5725bfa101aaf86d28fff196e74e747c9160b90e57495ec13d599fb",
    db_ticker: "XRS",
    db_name: "Xirius",
    db_url: "https://xiriuspool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5958902119",
    stake_x_deleg: "1489725529.75",
    saturated: "0.000086682411074194",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0069",
    tax_fix: "340000000",
    pledge: "4800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Possibilities Unite People",
    icon: "0",
    pool_id: "f78dcf516c5381312b083f8a4cda0edc5c0031c940afcfd91f1a6d08",
    db_ticker: "PUP",
    db_name: "Possibilities Unite People",
    db_url: "https://www.linkedin.com/company/puppool",
    roa: "0",
    roa_lifetime: "3.001",
    total_stake: "7374180017",
    stake_x_deleg: "1843545004.25",
    saturated: "0.00010727004585804",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JKLpool1",
    icon: "0",
    pool_id: "853fd2c733aef7289631c29573f3848009f8f4cb8bcfbad17b468daf",
    db_ticker: "JKL",
    db_name: "JKLpool1",
    db_url: "https://twitter.com/ada_jkl",
    roa: "0",
    roa_lifetime: "1.907",
    total_stake: "24020305236",
    stake_x_deleg: "6005076309",
    saturated: "0.00034941637419343",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZenaPool",
    icon: "https://cdn.adapools.org/pool_logo/231c32511b02fb45f11e44b691c63853aed924e75a496062dd341b84.png",
    pool_id: "231c32511b02fb45f11e44b691c63853aed924e75a496062dd341b84",
    db_ticker: "ZENA",
    db_name: "ZenaPool",
    db_url: "https://github.com/Kassiel706",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20017402636",
    stake_x_deleg: "5004350659",
    saturated: "0.00029118731760987",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WIRA Pool",
    icon: "0",
    pool_id: "fcedaf183e9430acc484e4e290f40cb19e98e0e07efea2a327a71b94",
    db_ticker: "WIRA",
    db_name: "WIRA Pool",
    db_url: "https://staking.wira.co",
    roa: "0",
    roa_lifetime: "3.622",
    total_stake: "205631079628",
    stake_x_deleg: "102815539814",
    saturated: "0.0029912553383132",
    luck_lifetime: "89",
    blocks_epoch: "0",
    blocks_lifetime: "38",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mill Stake Pool",
    icon: "0",
    pool_id: "1e659d44dafa9b7aaf4d393b1dbeb82fc81e5717c3f0c9d653b7bfbe",
    db_ticker: "MILL",
    db_name: "Mill Stake Pool",
    db_url: "https://adamill.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6627356696",
    stake_x_deleg: "1656839174",
    saturated: "0.000096406224835656",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UG Capital Venture 1",
    icon: "0",
    pool_id: "aae479cbf142052ab45880f7424b361f6e4586a2b5e30f1d1030ab8a",
    db_ticker: "UGCV1",
    db_name: "UG Capital Venture 1",
    db_url: "https://www.ugcapitalventure.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "72884718143",
    stake_x_deleg: "18221179535.75",
    saturated: "0.0010602327363213",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swoop Stake Pool",
    icon: "0",
    pool_id: "558adc3953efcd4b567d62078e73e4dc698ac86ae80035312bd627f6",
    db_ticker: "SWOOP",
    db_name: "Swoop Stake Pool",
    db_url: "https://swoopstake.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "26864457426",
    stake_x_deleg: "6716114356.5",
    saturated: "0.00039078942653894",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "150001000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoCoro",
    icon: "0",
    pool_id: "705e4c8a917808437353167322eca95437d53aa3a56f6779a9cb96b8",
    db_ticker: "CORO",
    db_name: "CardanoCoro",
    db_url: "https://arielcoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11605226555",
    stake_x_deleg: "2901306638.75",
    saturated: "0.00016881784576426",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lynx pool",
    icon: "0",
    pool_id: "5e759d13110d744637b618668b54b30b12cb638bb3c5400654338c54",
    db_ticker: "LYNX",
    db_name: "Lynx pool",
    db_url: "https://cryptolynxadapool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1155041489",
    stake_x_deleg: "288760372.25",
    saturated: "0.000016802051646059",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "360000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Vietnam Stake Pool",
    icon: "0",
    pool_id: "8f1bff8264dbcb38c54bb2339f733b815559c4c6e9770ed71af46bc5",
    db_ticker: "AVN",
    db_name: "Cardano Vietnam Stake Pool",
    db_url: "https://cardano.com.vn/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4446607838",
    stake_x_deleg: "1111651959.5",
    saturated: "0.000064683507263907",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Conde Pool ",
    icon: "0",
    pool_id: "3ef4d626c6064d57a3209b164eb2fd689d2e97789dddc23e34e68ab2",
    db_ticker: "CONDE",
    db_name: "Conde Pool ",
    db_url: "https://condepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3461487526",
    stake_x_deleg: "865371881.5",
    saturated: "0.000050353249418247",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Stakepool Online",
    icon: "0",
    pool_id: "d6f58a0f01dea36569fafd81d6a226a0496938750aef7916f5ecf00e",
    db_ticker: "MFM",
    db_name: "ADA Stakepool Online",
    db_url: "https://stake-pool.online",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "287686372",
    stake_x_deleg: "71921593",
    saturated: "0.0000041848897431348",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jersey Cardano Collective",
    icon: "0",
    pool_id: "095e4dda6af3a55f45997ff207f3502c69d5ca01803deb196b208408",
    db_ticker: "JSY",
    db_name: "Jersey Cardano Collective",
    db_url: "https://git.io/JuelI",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4907458502",
    stake_x_deleg: "1226864625.5",
    saturated: "0.000071387367455416",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CREAM",
    icon: "0",
    pool_id: "70e9625471b8b573667ffb0c1e4f08f489d3a1872c97d7c5f42b8b90",
    db_ticker: "CREAM",
    db_name: "CREAM",
    db_url: "http://www.unkrass.de/pool.html",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "410801996",
    stake_x_deleg: "102700499",
    saturated: "0.0000059758168159585",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Change",
    icon: "0",
    pool_id: "bd5e6901bd2bb6e2e129a4a108b9c598792b5fec468d2217f4e4a1ca",
    db_ticker: "CHG",
    db_name: "Change",
    db_url: "https://change.paradoxicalsphere.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1714679392",
    stake_x_deleg: "428669848",
    saturated: "0.00002494294098973",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LoneStar Staking Pool",
    icon: "0",
    pool_id: "01fbe26f514707ff29c38aa597ad8ff86a1572e760858d0e7ae4fc32",
    db_ticker: "LSSP",
    db_name: "LoneStar Staking Pool",
    db_url: "https://LoneStarStaking.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3920938314",
    stake_x_deleg: "980234578.5",
    saturated: "0.00005703674602189",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADALOT",
    icon: "0",
    pool_id: "e7db783b5abf2c185a23eeeb6923a0b2dd1faae4e5becfc4758c7814",
    db_ticker: "ALOT",
    db_name: "ADALOT",
    db_url: "https://adalot.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3508601123",
    stake_x_deleg: "877150280.75",
    saturated: "0.000051038597172041",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wolfen-Kuckucksheim",
    icon: "0",
    pool_id: "5acd06bff03ba34d8ffc376a563d90fafdd3d029e40c6cecc3b07b02",
    db_ticker: "KUCKO",
    db_name: "Wolfen-Kuckucksheim",
    db_url: "https://hendrikschmeer.de/crypto/pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3969519067",
    stake_x_deleg: "992379766.75",
    saturated: "0.000057743436066086",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAOOO",
    icon: "0",
    pool_id: "629a028f26afbc3302fe49dce2046bc3c0c30e2c6120cc87d39281cd",
    db_ticker: "OOO",
    db_name: "ADAOOO",
    db_url: "https://www.adaooo.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50076539378",
    stake_x_deleg: "12519134844.5",
    saturated: "0.00072844881235695",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SamuraiStakePool",
    icon: "0",
    pool_id: "50fc7a05a9e12d48131d284d0f2a10900c1222bd10c319429232b2ba",
    db_ticker: "SMRI",
    db_name: "SamuraiStakePool",
    db_url: "https://samuraicardano.wixsite.com/ada-stake-pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "30723577899",
    stake_x_deleg: "7680894474.75",
    saturated: "0.0004469269264584",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Magic ADA Pot",
    icon: "0",
    pool_id: "22326323f15f379f375567566360b6cf112f80566da91a90c2285b15",
    db_ticker: "MPOT",
    db_name: "The Magic ADA Pot",
    db_url: "https://www.themagicadapot.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4612844326",
    stake_x_deleg: "1153211081.5",
    saturated: "0.000067101700968147",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raffle Pool",
    icon: "https://cdn.adapools.org/pool_logo/810fafc64aef3664418154f6eaf2dc02978f34730736511fc5d5cd31.png",
    pool_id: "810fafc64aef3664418154f6eaf2dc02978f34730736511fc5d5cd31",
    db_ticker: "RAF",
    db_name: "Raffle Pool",
    db_url: "https://rafflepool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2401858477",
    stake_x_deleg: "800619492.33333",
    saturated: "0.000034939134707635",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CryptoMan Stake Pool",
    icon: "0",
    pool_id: "802441a365ac93b54344d716c1a7db865c1c0d33cf804f8d29cdb0f9",
    db_ticker: "CMAN1",
    db_name: "CryptoMan Stake Pool",
    db_url: "https://cryptoman.tech/",
    roa: "0",
    roa_lifetime: "1.49",
    total_stake: "73535964446",
    stake_x_deleg: "36767982223",
    saturated: "0.0010697062263401",
    luck_lifetime: "81",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "70000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "無双63 ADA POOL",
    icon: "0",
    pool_id: "69515a7b5592e42ea07b2526d58cd50f3bef9603aceef3fe3b042b13",
    db_ticker: "SS63",
    db_name: "無双63 ADA POOL",
    db_url: "https://ada63pool.com/",
    roa: "3.548",
    roa_lifetime: "4.155",
    total_stake: "14055125393660",
    stake_x_deleg: "1405512539366",
    saturated: "0.20445580960088",
    luck_lifetime: "93",
    blocks_epoch: "13",
    blocks_lifetime: "1114",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "9000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "067e5ba8e2e8f81aa28d31690de50848f8056ad5f7f07ce9db68bbd2",
    db_ticker: "RAID1",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "3.999",
    total_stake: "35656089060",
    stake_x_deleg: "11885363020",
    saturated: "0.00051867872763711",
    luck_lifetime: "115",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Benefits Stake Pool",
    icon: "0",
    pool_id: "7b62f8bb812be512ef6c2412ffdc6a36c71da62725190a7bd3dfc1a2",
    db_ticker: "CBCP",
    db_name: "Benefits Stake Pool",
    db_url: "https://bspdefi.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4285460124",
    stake_x_deleg: "1071365031",
    saturated: "0.000062339338470787",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2095000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SolPi - Solar powered Pi",
    icon: "https://cdn.adapools.org/pool_logo/827900818541013d518ee6b2af343a973c8d836884fb000742307400.png",
    pool_id: "827900818541013d518ee6b2af343a973c8d836884fb000742307400",
    db_ticker: "SOLPI",
    db_name: "SolPi - Solar powered Pi",
    db_url: "https://solpi.de",
    roa: "4.249",
    roa_lifetime: "4.13",
    total_stake: "14682194926347",
    stake_x_deleg: "564699804859.5",
    saturated: "0.21357760719363",
    luck_lifetime: "106",
    blocks_epoch: "14",
    blocks_lifetime: "148",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Zen ADA Holdings ",
    icon: "0",
    pool_id: "000007aa46044059162dcb0250ae057e17b3ba2378d19b17d3472419",
    db_ticker: "ZENY",
    db_name: "Zen ADA Holdings ",
    db_url: "https://www.zenholdings.io",
    roa: "0",
    roa_lifetime: "4.619",
    total_stake: "103821512191",
    stake_x_deleg: "51910756095.5",
    saturated: "0.0015102612559098",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "259",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PeakChain Pool",
    icon: "0",
    pool_id: "e57d8d7f0d9c6087c23d7ea26e746b5699576b8abd18b7580663268c",
    db_ticker: "PKCP",
    db_name: "PeakChain Pool",
    db_url: "https://peakchain-pool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "40052118849",
    stake_x_deleg: "13350706283",
    saturated: "0.00058262649077446",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LakeCardano",
    icon: "0",
    pool_id: "e94c464e6994f92111f356c29a8c4b6b87080faea06897eb60fa2758",
    db_ticker: "LAGO",
    db_name: "LakeCardano",
    db_url: "https://lakecardano.com",
    roa: "0",
    roa_lifetime: "4.616",
    total_stake: "104096254942",
    stake_x_deleg: "34698751647.333",
    saturated: "0.0015142578585736",
    luck_lifetime: "100",
    blocks_epoch: "0",
    blocks_lifetime: "106",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nobelware",
    icon: "0",
    pool_id: "c1f33491af1ae250d5c32dcf57922d5196027cc0a0e1c8db8747db5a",
    db_ticker: "NBLWR",
    db_name: "Nobelware",
    db_url: "https://nobelware.com/Cardano/",
    roa: "0",
    roa_lifetime: "4.925",
    total_stake: "28203659659",
    stake_x_deleg: "9401219886.3333",
    saturated: "0.00041027041081325",
    luck_lifetime: "252",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Connect",
    icon: "0",
    pool_id: "96dcf82c68c5cdc1b11dd9363c9a5357c81343f93744f07bfd8bfc76",
    db_ticker: "ADAC0",
    db_name: "ADA Connect",
    db_url: "https://ada-connect.com",
    roa: "3.333",
    roa_lifetime: "1.04",
    total_stake: "21986959848",
    stake_x_deleg: "7328986616",
    saturated: "0.00031983789190616",
    luck_lifetime: "53",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AndrejCardanoPool",
    icon: "0",
    pool_id: "73468a316c8cc777ec1b4f3b406e540c458b8ba4560477c9c9319c33",
    db_ticker: "ACP",
    db_name: "AndrejCardanoPool",
    db_url: "https://cpool.podzimek.org/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "16415339357",
    stake_x_deleg: "5471779785.6667",
    saturated: "0.00023878915371489",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.014",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ibra-Stakepool",
    icon: "0",
    pool_id: "e0f98b7f06508c15b2dd0330f6f7f6babfcaed7cf7bc69b7a0629844",
    db_ticker: "IBRA",
    db_name: "Ibra-Stakepool",
    db_url: "https://ibrastakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "82113145119",
    stake_x_deleg: "27371048373",
    saturated: "0.0011944759718581",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arkano Pool",
    icon: "https://cdn.adapools.org/pool_logo/506da8814a1e6bc0a9a83e96493977e7780e3c640465f340ac51456d.png",
    pool_id: "506da8814a1e6bc0a9a83e96493977e7780e3c640465f340ac51456d",
    db_ticker: "0AKN0",
    db_name: "Arkano Pool",
    db_url: "https://arkanopool.wordpress.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66733155349",
    stake_x_deleg: "22244385116.333",
    saturated: "0.00097074774660182",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2192515688",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swiss Dragonfly Network",
    icon: "0",
    pool_id: "e5ebc15274ba0d9532e52f6f919b55b16545312b6fc9f76071da2d18",
    db_ticker: "SDNET",
    db_name: "The Swiss Dragonfly Network",
    db_url: "https://cardano.sd-net.org",
    roa: "0",
    roa_lifetime: "2.732",
    total_stake: "30010290336",
    stake_x_deleg: "15005145168",
    saturated: "0.00043655094032617",
    luck_lifetime: "160",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "28000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bardels Pool",
    icon: "0",
    pool_id: "040d79b06f8ad1a94bd8fbc9eac9ba8ccf44a332d9d7f003d85f9393",
    db_ticker: "BRNS",
    db_name: "Bardels Pool",
    db_url: "https://burns.bardels.me/",
    roa: "0",
    roa_lifetime: "3.459",
    total_stake: "16940666580",
    stake_x_deleg: "5646888860",
    saturated: "0.0002464309356041",
    luck_lifetime: "122",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " BGOOD",
    icon: "0",
    pool_id: "4a8293b7b461807df7708a1a0bc15b512c774d0dcc38fc48d60b4b2b",
    db_ticker: "BGOOD",
    db_name: " BGOOD",
    db_url: "https://www.begoodpool.com",
    roa: "0",
    roa_lifetime: "2.596",
    total_stake: "24758762189",
    stake_x_deleg: "8252920729.6667",
    saturated: "0.00036015849210077",
    luck_lifetime: "59",
    blocks_epoch: "1",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SUPER",
    icon: "0",
    pool_id: "7791fbb569fd21f5d2a6f420ab91a5f8540207c03416827940e0b3f0",
    db_ticker: "SUPER",
    db_name: "SUPER",
    db_url: "https://superpool.cash",
    roa: "0",
    roa_lifetime: "1.836",
    total_stake: "10720422967",
    stake_x_deleg: "3573474322.3333",
    saturated: "0.00015594686604295",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.039",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Beach Stake Pool",
    icon: "0",
    pool_id: "d385e45001d129b78031c28f4ac4611021d769c5b60dfb928287e6e0",
    db_ticker: "BEACH",
    db_name: "Beach Stake Pool",
    db_url: "https://twitter.com/beachadapool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10689052170",
    stake_x_deleg: "3563017390",
    saturated: "0.000155490524209",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Boston",
    icon: "0",
    pool_id: "3b773c438f738499cb35571b68e73f6c44f13a4191a7dde63f09fa5b",
    db_ticker: "BOS",
    db_name: "Cardano Boston",
    db_url: "https://cardanostake.io/",
    roa: "4.337",
    roa_lifetime: "2.176",
    total_stake: "509438852456",
    stake_x_deleg: "169812950818.67",
    saturated: "0.0074106583971155",
    luck_lifetime: "89",
    blocks_epoch: "1",
    blocks_lifetime: "20",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Good4you",
    icon: "0",
    pool_id: "6144fa9047c414d64f6c48549df2e9640eef1ea6d3b433f6e53767c4",
    db_ticker: "GOOD4",
    db_name: "Good4you",
    db_url: "https://good4you.ru",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7349442396",
    stake_x_deleg: "2449814132",
    saturated: "0.00010691019490065",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.003",
    tax_fix: "340000000",
    pledge: "4039000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SERPIUS",
    icon: "0",
    pool_id: "dfed20e5e35ec66314cfed847e89914b65ccd747e50b76c68c44750e",
    db_ticker: "SERPX",
    db_name: "SERPIUS",
    db_url: "https://www.serpius.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7602923502",
    stake_x_deleg: "2534307834",
    saturated: "0.00011059751061603",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBlue",
    icon: "0",
    pool_id: "92f3de55bce1ea516b8d75bbb548410e5426c7eef902fa0e3efa3925",
    db_ticker: "SBLUE",
    db_name: "StakeBlue",
    db_url: "https://stake-blue.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "17185979383",
    stake_x_deleg: "5728659794.3333",
    saturated: "0.00024999942939822",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UnWoke Pool",
    icon: "0",
    pool_id: "b716d66f71d33519b46ba97416cb54768c2892ccb22b505b19618a5d",
    db_ticker: "UWOKE",
    db_name: "UnWoke Pool",
    db_url: "https://unwoke.tech",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6022370605",
    stake_x_deleg: "2007456868.3333",
    saturated: "0.000087605668627991",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool Service No.5",
    icon: "0",
    pool_id: "3257f318b0f84542aef842dc86ca362e5f35ce21a827b142233d5b63",
    db_ticker: "SPS5",
    db_name: "StakePool Service No.5",
    db_url: "https://stakepoolservice.com/",
    roa: "3.939",
    roa_lifetime: "4.342",
    total_stake: "39234629155645",
    stake_x_deleg: "112420140847.12",
    saturated: "0.5707347066449",
    luck_lifetime: "97",
    blocks_epoch: "33",
    blocks_lifetime: "4108",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Senti Africa Pool",
    icon: "0",
    pool_id: "a0999b4656cc44ae6ff43644bfe9cba685c26693c971deb447dca652",
    db_ticker: "SENTI",
    db_name: "Senti Africa Pool",
    db_url: "https://www.bitdax.io/senti",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23760953037",
    stake_x_deleg: "7920317679",
    saturated: "0.00034564365340062",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Matrix pools (SG)",
    icon: "0",
    pool_id: "11d6c617087878549e728bcbce59ca23a399455ba7bbf2a5993a9c70",
    db_ticker: "MTXSG",
    db_name: "Cardano Matrix pools (SG)",
    db_url: "https://mtxpool.github.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23914727310",
    stake_x_deleg: "7971575770",
    saturated: "0.00034788056289814",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StumptownADApool",
    icon: "0",
    pool_id: "07b960bfb24f21d474043f47034ed62f89f8ac1d3f7bf8c170a2dc7b",
    db_ticker: "PDX",
    db_name: "StumptownADApool",
    db_url: "https://stumptownadapool.xyz",
    roa: "0",
    roa_lifetime: "0.058",
    total_stake: "1347709184",
    stake_x_deleg: "449236394.66667",
    saturated: "0.000019604732409258",
    luck_lifetime: "47",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stack Cheddar",
    icon: "https://cdn.adapools.org/pool_logo/bb683d2acbe5636a4570b046734ba709174b698e8292627c1bf5c342.png",
    pool_id: "bb683d2acbe5636a4570b046734ba709174b698e8292627c1bf5c342",
    db_ticker: "CHDR",
    db_name: "Stack Cheddar",
    db_url: "https://cheddarpool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "136384486",
    stake_x_deleg: "45461495.333333",
    saturated: "0.0000019839453381689",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "A4GADAPool",
    icon: "0",
    pool_id: "804865a3987aa337f3d99b1b7b8eae32c80a786af20f5bbca134c5f5",
    db_ticker: "ADA4G",
    db_name: "A4GADAPool",
    db_url: "http://adapool.ssmu.win",
    roa: "0",
    roa_lifetime: "3.324",
    total_stake: "2111730905",
    stake_x_deleg: "703910301.66667",
    saturated: "0.000030718733540132",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sylvanium-1",
    icon: "0",
    pool_id: "edca900e81735725b8ea9dad885bb6ab17851a42f3f762433f133bf4",
    db_ticker: "SYLV1",
    db_name: "Sylvanium-1",
    db_url: "https://sylvanium.com",
    roa: "0",
    roa_lifetime: "3.501",
    total_stake: "139057150",
    stake_x_deleg: "46352383.333333",
    saturated: "0.0000020228238018329",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "41",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoPUB",
    icon: "0",
    pool_id: "5a165ab9e8ddf68a11ca6475a4c3f1292e57d96619f9651a068f7f02",
    db_ticker: "CPUB",
    db_name: "CardanoPUB",
    db_url: "https://www.cardanopub.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "26325875",
    stake_x_deleg: "8775291.6666667",
    saturated: "3.8295482507788e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nurse Pool",
    icon: "0",
    pool_id: "75a3e3a9e2471178693239e0b3cb402537a1420f60b3ca6f6394a4a8",
    db_ticker: "NURSE",
    db_name: "Nurse Pool",
    db_url: "https://nurse-node.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1815811531",
    stake_x_deleg: "605270510.33333",
    saturated: "0.000026414080718248",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Bring Julian Assange Home! ",
    icon: "0",
    pool_id: "8801de5a0177a23c04d27836cff6fbae2f46e1e31a80933d5732053c",
    db_ticker: "LEAKS",
    db_name: " Bring Julian Assange Home! ",
    db_url: "https://waldmops.nvmr.de/leaks",
    roa: "0",
    roa_lifetime: "4.237",
    total_stake: "5316565090",
    stake_x_deleg: "1772188363.3333",
    saturated: "0.000077338521665703",
    luck_lifetime: "118",
    blocks_epoch: "0",
    blocks_lifetime: "67",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CryptoTuts [GER]",
    icon: "0",
    pool_id: "5290994493153173e13633d6ba36090cd31d4392c306c30812da8e42",
    db_ticker: "CTUTS",
    db_name: "CryptoTuts [GER]",
    db_url: "https://CryptoTuts.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "67456466",
    stake_x_deleg: "22485488.666667",
    saturated: "9.8126953567172e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IRF ADA",
    icon: "0",
    pool_id: "28e0a3969fa22f29d5b13e0a00826a6d6bdd80dec01b1cf60deb29bf",
    db_ticker: "IRF",
    db_name: "IRF ADA",
    db_url: "https://irfada.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1415765685",
    stake_x_deleg: "471921895",
    saturated: "0.00002059473047906",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaSurge",
    icon: "0",
    pool_id: "e8a73e30e333fd9d3641be411b5df41f84edbc5af2b87634eb1c4868",
    db_ticker: "SURGE",
    db_name: "AdaSurge",
    db_url: "https://adasurge.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1340486162",
    stake_x_deleg: "446828720.66667",
    saturated: "0.000019499661214984",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sun Malaga Ada Pool",
    icon: "0",
    pool_id: "e772a4d7283eee2e70758b8bf3b036ab783a1dda7bccfc11594d3b34",
    db_ticker: "SMAP",
    db_name: "Sun Malaga Ada Pool",
    db_url: "https://cryptominingsunmalaga.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2559137316",
    stake_x_deleg: "853045772",
    saturated: "0.000037227024104576",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "400000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staking for your ADA",
    icon: "0",
    pool_id: "91403c390a3ed10e44e1a4ac3705774a681dee8e6842345d0a30d892",
    db_ticker: "OSCAR",
    db_name: "Staking for your ADA",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "19452754",
    stake_x_deleg: "6484251.3333333",
    saturated: "2.8297353859475e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "15000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Regenerative Farming Pool",
    icon: "0",
    pool_id: "cf0f698eaf884ea22ef9442ceda4eed30ff538f43b4f21780c60052f",
    db_ticker: "REGEN",
    db_name: "The Regenerative Farming Pool",
    db_url: "https://www.theregenerativefarmingpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5652703940",
    stake_x_deleg: "1884234646.6667",
    saturated: "0.000082228235474024",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANO A1 STEAK",
    icon: "0",
    pool_id: "cc3c7d7b25f4534291fee3a6fac09f737db20d95283dacc6400d7b4b",
    db_ticker: "A1STK",
    db_name: "CARDANO A1 STEAK",
    db_url: "https://www.cardanoa1steak.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2105657763",
    stake_x_deleg: "701885921",
    saturated: "0.00003063038931483",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Perky Pool",
    icon: "0",
    pool_id: "9f51c3f17ab8fcfb4b467ba6df45378f296a39e4a8770491419e7d41",
    db_ticker: "PERKY",
    db_name: "Perky Pool",
    db_url: "https://perkypool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "506609652",
    stake_x_deleg: "168869884",
    saturated: "0.0000073695028432834",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GPG StakePool",
    icon: "0",
    pool_id: "006325609534b3e3c4d1e94e479c82d59c38e77c7d05b51886193b29",
    db_ticker: "GPG",
    db_name: "GPG StakePool",
    db_url: "https://gpg-stakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1965321404",
    stake_x_deleg: "655107134.66667",
    saturated: "0.000028588957232784",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cloned Dodo Pool",
    icon: "0",
    pool_id: "c65e074e2cdbcdc33dcfd5baa8c0c158f72e7802ae10f992bc4d586d",
    db_ticker: "CDDPL",
    db_name: "Cloned Dodo Pool",
    db_url: "https://cloneddodo.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3042088386",
    stake_x_deleg: "1014029462",
    saturated: "0.000044252372456075",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TRIK.club",
    icon: "0",
    pool_id: "57e114f88c748ff328bf3272165a9f593bfd4346d5f03344c00a4273",
    db_ticker: "3KCLB",
    db_name: "TRIK.club",
    db_url: "https://ada.trik.club",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "956930582",
    stake_x_deleg: "318976860.66667",
    saturated: "0.000013920190065534",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IC-Pool1",
    icon: "0",
    pool_id: "930a14e9ca4ee400f73622fd55cadb91afbe9dd0040fc1c5abcd68b0",
    db_ticker: "ICP1",
    db_name: "IC-Pool1",
    db_url: "https://pools.cardano.icodeit.io",
    roa: "3.333",
    roa_lifetime: "18.641",
    total_stake: "14690556496",
    stake_x_deleg: "4896852165.3333",
    saturated: "0.00021369924050852",
    luck_lifetime: "1183",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aire Cardano",
    icon: "0",
    pool_id: "ac08f0499a4fe92c31e84cf42f9dc55827bd79a33737a5b77d1624c2",
    db_ticker: "AIRE",
    db_name: "Aire Cardano",
    db_url: "https://airecardano.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5097106144",
    stake_x_deleg: "1699035381.3333",
    saturated: "0.000074146116388492",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeMachine",
    icon: "0",
    pool_id: "fe8b5d6e7ca553a63dd9a9843e9ecede8f8bf921bdccc4116d7eebf1",
    db_ticker: "SMACH",
    db_name: "StakeMachine",
    db_url: "https://www.stakemachine.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1225201630",
    stake_x_deleg: "408400543.33333",
    saturated: "0.000017822650753366",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "150000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Lovelace Center",
    icon: "0",
    pool_id: "668c1603dd1a12b9a2af81de77d7244d77eec2b45155fec2af939f33",
    db_ticker: "ADAL",
    db_name: "ADA Lovelace Center",
    db_url: "https://ada.lovelace.center/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "163755733",
    stake_x_deleg: "54585244.333333",
    saturated: "0.0000023821068848239",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mine4me",
    icon: "https://cdn.adapools.org/pool_logo/0499a15733ca24d227895517b90a687f19da73d90c37e75f6f2cb0f2.png",
    pool_id: "0499a15733ca24d227895517b90a687f19da73d90c37e75f6f2cb0f2",
    db_ticker: "M4M",
    db_name: "Mine4me",
    db_url: "https://u.is",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2038894798",
    stake_x_deleg: "679631599.33333",
    saturated: "0.000029659207935929",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.003",
    tax_fix: "340000000",
    pledge: "1750000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Crypto Junction Pool",
    icon: "0",
    pool_id: "9642616c1f92eeb4b3062b6f80f864820b3abc5a91865c3bc5d46ef0",
    db_ticker: "JUNC",
    db_name: "Crypto Junction Pool",
    db_url: "https://thecryptojunction.ca",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "606805052",
    stake_x_deleg: "202268350.66667",
    saturated: "0.0000088270161027898",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Carbon Pool",
    icon: "0",
    pool_id: "7614c728ca3714cecebdc2ef7b3ce65f31b3b5a1e06fb73fb79e0a50",
    db_ticker: "CRBN",
    db_name: "Carbon Pool",
    db_url: "https://carbonpool.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2653379417",
    stake_x_deleg: "884459805.66667",
    saturated: "0.000038597936459946",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0099",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Green Pool ADA",
    icon: "0",
    pool_id: "28811ea05b77e6b0158444c4ecabbb8948abc1bd6b6a8ec370da976d",
    db_ticker: "GADA1",
    db_name: "Green Pool ADA",
    db_url: "https://greenpoolada.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1870232950",
    stake_x_deleg: "623410983.33333",
    saturated: "0.000027205733227182",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WEN K? Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/b8d2cf0a94b576a06917cef94d0e22b3bcd5d1cda1ba4a11deecfc1f.png",
    pool_id: "b8d2cf0a94b576a06917cef94d0e22b3bcd5d1cda1ba4a11deecfc1f",
    db_ticker: "WEN_K",
    db_name: "WEN K? Stake Pool",
    db_url: "https://cardano.dantup.com",
    roa: "0",
    roa_lifetime: "4.015",
    total_stake: "74789235163",
    stake_x_deleg: "18697308790.75",
    saturated: "0.001087937189915",
    luck_lifetime: "110",
    blocks_epoch: "0",
    blocks_lifetime: "102",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Cat lady's validator ",
    icon: "0",
    pool_id: "0ac4b2cb44acb291581564a22f3bb5bd4aab2ff6ce1088640cc1d2fd",
    db_ticker: "MEOW",
    db_name: " Cat lady's validator ",
    db_url: "http://www.deni64k.online/cardano/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "78744273074",
    stake_x_deleg: "39372136537",
    saturated: "0.0011454699728286",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "78500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPOCRA: Our mission is to support &amp; represent SPOs",
    icon: "https://cdn.adapools.org/pool_logo/83797e10178db38667ca3a2e620806ca128279653eb452028c314bba.png",
    pool_id: "83797e10178db38667ca3a2e620806ca128279653eb452028c314bba",
    db_ticker: "SPCRA",
    db_name: "SPOCRA: Our mission is to support &amp; represent SPOs",
    db_url: "https://www.spocra.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "13260323992",
    stake_x_deleg: "4420107997.3333",
    saturated: "0.00019289406543305",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EspressoPool.net",
    icon: "https://cdn.adapools.org/pool_logo/40372edc7c25cccce285415d778b5ee80dd55a33af7f5f93a963ce6b.png",
    pool_id: "40372edc7c25cccce285415d778b5ee80dd55a33af7f5f93a963ce6b",
    db_ticker: "ESPR",
    db_name: "EspressoPool.net",
    db_url: "https://espressopool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11196735075",
    stake_x_deleg: "5598367537.5",
    saturated: "0.00016287563935064",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "11000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool Service No.3",
    icon: "0",
    pool_id: "f839821b8c95969d853e4b547952b84a3bb20cd96c6b564ddfd95c0b",
    db_ticker: "SPS3",
    db_name: "StakePool Service No.3",
    db_url: "https://stakepoolservice.com/",
    roa: "3.628",
    roa_lifetime: "4.27",
    total_stake: "31523234485524",
    stake_x_deleg: "129193583957.07",
    saturated: "0.45855929758432",
    luck_lifetime: "95",
    blocks_epoch: "30",
    blocks_lifetime: "3659",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA TRADE POOL",
    icon: "https://cdn.adapools.org/pool_logo/b99b81d78a57c8b467c5b8e976c225c95f283777ea4376a273adfe06.png",
    pool_id: "b99b81d78a57c8b467c5b8e976c225c95f283777ea4376a273adfe06",
    db_ticker: "TRADE",
    db_name: "ADA TRADE POOL",
    db_url: "https://adatradepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12635634590",
    stake_x_deleg: "6317817295",
    saturated: "0.00018380689090719",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Support our eastern Cardano Community Network",
    icon: "https://cdn.adapools.org/pool_logo/f0a112d12e435e9659f80a41236daf525e08189702a25a7d0876f962.png",
    pool_id: "f0a112d12e435e9659f80a41236daf525e08189702a25a7d0876f962",
    db_ticker: "C3ETH",
    db_name: "Support our eastern Cardano Community Network",
    db_url: "https://easterntownhall.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2543588708",
    stake_x_deleg: "847862902.66667",
    saturated: "0.000037000843039109",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Algorithmi Pool of Arabia – مجمع الخوارزمي العربي",
    icon: "0",
    pool_id: "f53a65c2462fa4599d19d42f1dc6a465f5459db48ad3208e056bff97",
    db_ticker: "ALGO",
    db_name: "Algorithmi Pool of Arabia – مجمع الخوارزمي العربي",
    db_url: "https://algopool.io",
    roa: "6.667",
    roa_lifetime: "7.226",
    total_stake: "16322205239",
    stake_x_deleg: "8161102619.5",
    saturated: "0.00023743435886505",
    luck_lifetime: "523",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "7000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Bored Ape Yacht Club's Pool",
    icon: "0",
    pool_id: "fa12fc84aeccc7ac2727d7a335000582a7ea983cd7fa733319d2c4fb",
    db_ticker: "BAYC",
    db_name: "Not Bored Ape Yacht Club's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "1.516",
    total_stake: "6123408767",
    stake_x_deleg: "3061704383.5",
    saturated: "0.000089075441300501",
    luck_lifetime: "52",
    blocks_epoch: "0",
    blocks_lifetime: "16",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Selldone",
    icon: "https://cdn.adapools.org/pool_logo/d394429a616c96a55be2bf1d811641610803eaafde43f961c6aee5b8.png",
    pool_id: "d394429a616c96a55be2bf1d811641610803eaafde43f961c6aee5b8",
    db_ticker: "SEL",
    db_name: "Selldone",
    db_url: "https://selldone.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "532054545",
    stake_x_deleg: "266027272.5",
    saturated: "0.000007739642280166",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stonestake",
    icon: "https://cdn.adapools.org/pool_logo/fb9e6df48b27018b7687851ebb7d4bde2802300a987e4042b84b59c6.png",
    pool_id: "fb9e6df48b27018b7687851ebb7d4bde2802300a987e4042b84b59c6",
    db_ticker: "STONE",
    db_name: "stonestake",
    db_url: "https://www.stonestake.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23353470",
    stake_x_deleg: "11676735",
    saturated: "3.3971611651318e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cupid Pool",
    icon: "0",
    pool_id: "ab45a813bf36d7f25bbc6dad47aca65b32f9e54362e0806c8c4e514c",
    db_ticker: "CUPID",
    db_name: "Cupid Pool",
    db_url: "https://cupidpool.club",
    roa: "0",
    roa_lifetime: "2.617",
    total_stake: "1827591660",
    stake_x_deleg: "913795830",
    saturated: "0.000026585442818865",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kablammo!",
    icon: "https://cdn.adapools.org/pool_logo/ec89acc3e82cb9ed8402ff739b89eaefd164c3c9b441026fcee4fd68.png",
    pool_id: "ec89acc3e82cb9ed8402ff739b89eaefd164c3c9b441026fcee4fd68",
    db_ticker: "KABL",
    db_name: "Kablammo!",
    db_url: "https://www.kablammo.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1560630006",
    stake_x_deleg: "780315003",
    saturated: "0.000022702029503635",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Adrenaline",
    icon: "0",
    pool_id: "ae4c86522eced52771630060d32c7faddd9ca49bfac0f5197e50b4f3",
    db_ticker: "ADR",
    db_name: "Adrenaline",
    db_url: "coming",
    roa: "0",
    roa_lifetime: "3.834",
    total_stake: "2089804859",
    stake_x_deleg: "1044902429.5",
    saturated: "0.000030399781744206",
    luck_lifetime: "185",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CryptoPool.dev",
    icon: "https://cdn.adapools.org/pool_logo/a343a6e2eb4bb7f8abf039b82e579131b56966d88d9937e679365269.png",
    pool_id: "a343a6e2eb4bb7f8abf039b82e579131b56966d88d9937e679365269",
    db_ticker: "CRYPO",
    db_name: "CryptoPool.dev",
    db_url: "https://cryptopool.dev/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "97081412512",
    stake_x_deleg: "10786823612.444",
    saturated: "0.0014122149917846",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Uplevel staking pool",
    icon: "0",
    pool_id: "b6300321f150f4dc000166d87f744b5e388a711ac70650f7c0b71d8a",
    db_ticker: "ADAUP",
    db_name: "ADA Uplevel staking pool",
    db_url: "https://t.me/ADA_UP",
    roa: "4.081",
    roa_lifetime: "3.083",
    total_stake: "1888823511284",
    stake_x_deleg: "188882351128.4",
    saturated: "0.027476164699815",
    luck_lifetime: "108",
    blocks_epoch: "1",
    blocks_lifetime: "51",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mercator Stake",
    icon: "https://cdn.adapools.org/pool_logo/e5c35a37b7ab12021380ef8d365a291a52689d26a8ad07fc1fe19b73.png",
    pool_id: "e5c35a37b7ab12021380ef8d365a291a52689d26a8ad07fc1fe19b73",
    db_ticker: "MRX",
    db_name: "Mercator Stake",
    db_url: "https://www.mercatorstake.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23466072900",
    stake_x_deleg: "2346607290",
    saturated: "0.00034135411805625",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "700000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jettpools",
    icon: "0",
    pool_id: "cefc7f988d848f6cb8bc92fbfb3184a5d669566e4d1b6a978e8b082d",
    db_ticker: "JETT1",
    db_name: "Jettpools",
    db_url: "https://jettpools.io",
    roa: "2.831",
    roa_lifetime: "1.875",
    total_stake: "118003530088",
    stake_x_deleg: "13111503343.111",
    saturated: "0.0017165629337457",
    luck_lifetime: "91",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Share_the_love",
    icon: "0",
    pool_id: "62b8737a394f7be67f11aaa4d6d42d6342566f68ee305f2d64d63f23",
    db_ticker: "STL",
    db_name: "Share_the_love",
    db_url: "https://foo.com/",
    roa: "0",
    roa_lifetime: "1.236",
    total_stake: "52220435773",
    stake_x_deleg: "26110217886.5",
    saturated: "0.0007596354478983",
    luck_lifetime: "49",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO9 Pool",
    icon: "0",
    pool_id: "86a1ac91997ff78ae5f9d892afe8f3293af1dc616e00f96edbdeccc5",
    db_ticker: "LEO9",
    db_name: "LEO9 Pool",
    db_url: "https://staking-fm.site",
    roa: "2.677",
    roa_lifetime: "3.832",
    total_stake: "20780057920410",
    stake_x_deleg: "2968579702915.7",
    saturated: "0.30228144158622",
    luck_lifetime: "98",
    blocks_epoch: "17",
    blocks_lifetime: "3522",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bison Steak Pool",
    icon: "0",
    pool_id: "a79f362a5947f002a9087837627e8d720aadcab1f5655c7b3d41abeb",
    db_ticker: "RANCH",
    db_name: "Bison Steak Pool",
    db_url: "https://bisoncoin.io/",
    roa: "7.519",
    roa_lifetime: "3.297",
    total_stake: "63889482729",
    stake_x_deleg: "289092682.03167",
    saturated: "0.00092938167042122",
    luck_lifetime: "120",
    blocks_epoch: "0",
    blocks_lifetime: "10",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaSpin Stake Pool",
    icon: "0",
    pool_id: "d9931174a5260bf4ce6d94b3097fbaa0c4567e6a293ce91aa18c78fc",
    db_ticker: "SPIN",
    db_name: "AdaSpin Stake Pool",
    db_url: "https://stake.adaspin.io",
    roa: "0",
    roa_lifetime: "1.504",
    total_stake: "857828092",
    stake_x_deleg: "428914046",
    saturated: "0.000012478575049025",
    luck_lifetime: "70",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "250000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Madam ADA Pool",
    icon: "0",
    pool_id: "769cd003940124a5b9d0dff167d3f1965c5bc86c1ea1abb10c9ccfe3",
    db_ticker: "MADAM",
    db_name: "Madam ADA Pool",
    db_url: "https://madamadapool.com",
    roa: "0",
    roa_lifetime: "2.565",
    total_stake: "25205197415",
    stake_x_deleg: "12602598707.5",
    saturated: "0.00036665265512028",
    luck_lifetime: "108",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swiss Cardano Pool",
    icon: "0",
    pool_id: "d8864a40e1b2495573ea3659d079b6d03b93aabeb81a78647b8ee0fd",
    db_ticker: "SCP",
    db_name: "Swiss Cardano Pool",
    db_url: "https://swiss-cardano-pool.web.app/",
    roa: "0",
    roa_lifetime: "2.128",
    total_stake: "8555571479",
    stake_x_deleg: "4277785739.5",
    saturated: "0.00012445540287575",
    luck_lifetime: "83",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "191000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WINGMAN",
    icon: "0",
    pool_id: "60cebca27392d89cfb79d6803c5fa0d731bd9999d767ce102e9ea111",
    db_ticker: "WING",
    db_name: "WINGMAN",
    db_url: "https://adawingman.com/",
    roa: "0",
    roa_lifetime: "4.466",
    total_stake: "1185663711",
    stake_x_deleg: "592831855.5",
    saturated: "0.000017247504177817",
    luck_lifetime: "141",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "12500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "YakisobaPool",
    icon: "https://cdn.adapools.org/pool_logo/0ce3abe4dc6ce48d71cc3628bfb63d2291a590a5e8da2bcfd81ded71.png",
    pool_id: "0ce3abe4dc6ce48d71cc3628bfb63d2291a590a5e8da2bcfd81ded71",
    db_ticker: "YKSB",
    db_name: "YakisobaPool",
    db_url: "https://twitter.com/RootYakisoba",
    roa: "0",
    roa_lifetime: "2.662",
    total_stake: "4800393982",
    stake_x_deleg: "2400196991",
    saturated: "0.000069829931110806",
    luck_lifetime: "75",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.015",
    tax_fix: "345000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aegis Stakepool",
    icon: "0",
    pool_id: "ef5d6dd91e621e67b0dd05ea81bfee0d539b3a001c9bc27ccfcae2be",
    db_ticker: "AEGIS",
    db_name: "Aegis Stakepool",
    db_url: "https://aegis.eagle.fish",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50455134480",
    stake_x_deleg: "25227567240",
    saturated: "0.00073395612487977",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Turbo",
    icon: "0",
    pool_id: "02204fe35693145133b5778f14f089298d7978f65a25f349810e60ba",
    db_ticker: "TURBO",
    db_name: "Turbo",
    db_url: "https://www.turbocookie.com",
    roa: "0",
    roa_lifetime: "1.671",
    total_stake: "82363922832",
    stake_x_deleg: "41181961416",
    saturated: "0.0011981239621041",
    luck_lifetime: "71",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "24000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wild Pool",
    icon: "https://cdn.adapools.org/pool_logo/4be1c3218b5c9e36750734c79ea7a9f7c71bde50e0e9159354232598.png",
    pool_id: "4be1c3218b5c9e36750734c79ea7a9f7c71bde50e0e9159354232598",
    db_ticker: "WILD",
    db_name: "Wild Pool",
    db_url: "https://wildstakingpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3499353696",
    stake_x_deleg: "1749676848",
    saturated: "0.000050904077548697",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VerasPool",
    icon: "0",
    pool_id: "17525aab031e0a4c40ab7349911a2a584793b985d1dc7aeae641601f",
    db_ticker: "VERA",
    db_name: "VerasPool",
    db_url: "https://cardanorules.github.io/ada",
    roa: "1.367",
    roa_lifetime: "3.426",
    total_stake: "1063757207028",
    stake_x_deleg: "212751441405.6",
    saturated: "0.015474165821373",
    luck_lifetime: "122",
    blocks_epoch: "1",
    blocks_lifetime: "73",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 0",
    icon: "0",
    pool_id: "5eb362978a68a4780f4fd701b8f04f5aeb990eb80b1cb025d99e82a1",
    db_ticker: "BD0",
    db_name: "Blockdaemon  Stake Pool 0",
    db_url: "https://blockdaemon.com/",
    roa: "3.247",
    roa_lifetime: "3.992",
    total_stake: "4405394925948",
    stake_x_deleg: "231862890839.37",
    saturated: "0.06408399505298",
    luck_lifetime: "95",
    blocks_epoch: "1",
    blocks_lifetime: "2584",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakePool Service No.4",
    icon: "0",
    pool_id: "d808aa16caa44c3226b76340f9f9535719d6e1018d46f499c100b6f1",
    db_ticker: "SPS4",
    db_name: "StakePool Service No.4",
    db_url: "https://stakepoolservice.com/",
    roa: "3.58",
    roa_lifetime: "4.297",
    total_stake: "33865797867597",
    stake_x_deleg: "159744329564.14",
    saturated: "0.49263588384084",
    luck_lifetime: "97",
    blocks_epoch: "30",
    blocks_lifetime: "3850",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arcadian Crypto Pool 1",
    icon: "0",
    pool_id: "d7ca1bbf1baa816adcee07edd8a5de743c364e549d0a6110f6257e74",
    db_ticker: "RKDN",
    db_name: "Arcadian Crypto Pool 1",
    db_url: "https://rkdn.app",
    roa: "0",
    roa_lifetime: "3.519",
    total_stake: "24551530311",
    stake_x_deleg: "3507361473",
    saturated: "0.00035714395041544",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "4500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "4ad28817e17febf7f91f66763170fa081861b05099e9fc46fd5dfe0a",
    db_ticker: "RAID6",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "6.667",
    roa_lifetime: "3.224",
    total_stake: "34828705119",
    stake_x_deleg: "17414352559.5",
    saturated: "0.00050664301477294",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "0c2256564f8d33210d1d50174d6137971c689dcccb4e36bf7c92c97d",
    db_ticker: "RAID4",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "2.349",
    total_stake: "32566294083",
    stake_x_deleg: "16283147041.5",
    saturated: "0.00047373238131648",
    luck_lifetime: "53",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Raid Pools",
    icon: "0",
    pool_id: "636234f3f689a36d6fa9e41835cc44818a0b6118b40bd9abbfc0774a",
    db_ticker: "RAID5",
    db_name: "Raid Pools",
    db_url: "https://www.raidpools.com",
    roa: "0",
    roa_lifetime: "1.911",
    total_stake: "35602795730",
    stake_x_deleg: "17801397865",
    saturated: "0.00051790348510982",
    luck_lifetime: "52",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Because of loving ADA",
    icon: "0",
    pool_id: "85b55c13298b15b7bdf985e0d1688de465e1e05c230b16ffdacf0996",
    db_ticker: "SAIGO",
    db_name: "Because of loving ADA",
    db_url: "http://saigon.online",
    roa: "0",
    roa_lifetime: "1.223",
    total_stake: "34855801459",
    stake_x_deleg: "17427900729.5",
    saturated: "0.00050703717732765",
    luck_lifetime: "59",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SESH Pool",
    icon: "0",
    pool_id: "f83c7e3a8f0ff349447cb68668cc31e509d7df0802ad5f113b0f255d",
    db_ticker: "SESH",
    db_name: "SESH Pool",
    db_url: "https://designsesh.com.au",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "31765215518",
    stake_x_deleg: "15882607759",
    saturated: "0.00046207932508442",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AquaNode",
    icon: "0",
    pool_id: "83da1e31e86e8ccde51dec5b516a2c1357ce78a3e3171d03afb80dc4",
    db_ticker: "AQN",
    db_name: "AquaNode",
    db_url: "https://aquanow.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "280087053959",
    stake_x_deleg: "280087053959",
    saturated: "0.0040743446801083",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EVON Pool",
    icon: "0",
    pool_id: "ce00b3b376df15b5e41fb60dfab3570be6816d0136ada7055709d68e",
    db_ticker: "EVON",
    db_name: "EVON Pool",
    db_url: "https://evon.one",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "32376848729",
    stake_x_deleg: "16188424364.5",
    saturated: "0.00047097657500794",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "hodlwith.cam",
    icon: "0",
    pool_id: "9ea2e3dcf1bf17676ace756eccbad17fe4c613e9757ecf128524daaa",
    db_ticker: "HWC",
    db_name: "hodlwith.cam",
    db_url:
      "https://gateway.pinata.cloud/ipfs/QmUx2U8sReuR3JGuqBzK29A3E7F7Gbj45TDz1Pxf1PJXSS",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "24247807398",
    stake_x_deleg: "12123903699",
    saturated: "0.00035272578178781",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Fat Penguin",
    icon: "0",
    pool_id: "b3339c70f7355b6387251ed950557270ca6d822f9f71c507a9cfe572",
    db_ticker: "TFP",
    db_name: "The Fat Penguin",
    db_url: "https://node.thefatpenguin.com",
    roa: "3.333",
    roa_lifetime: "10.371",
    total_stake: "34737753201",
    stake_x_deleg: "17368876600.5",
    saturated: "0.00050531996375001",
    luck_lifetime: "588",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "r89.io Stake Pool",
    icon: "0",
    pool_id: "137f12859d0e3b2a7367991bce814cdcf55681c2a83f798b839e3a71",
    db_ticker: "R89",
    db_name: "r89.io Stake Pool",
    db_url: "https://r89.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20442538281",
    stake_x_deleg: "10221269140.5",
    saturated: "0.0002973716418371",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mataka1",
    icon: "0",
    pool_id: "7af088e539c63cfd38a7043d2a49c7accd6c1310cda01aeddaa864b2",
    db_ticker: "MATAK",
    db_name: "Mataka1",
    db_url: "https://matakanode.io",
    roa: "0",
    roa_lifetime: "3.547",
    total_stake: "16454516212",
    stake_x_deleg: "8227258106",
    saturated: "0.00023935904799774",
    luck_lifetime: "242",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "16451000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ray Network",
    icon: "0",
    pool_id: "d647f6ef66a4d8374f1c92c3af9a055ed883602bbb74f7873ae9b741",
    db_ticker: "RAY8",
    db_name: "Ray Network",
    db_url: "https://raynetwork.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "142004886220",
    stake_x_deleg: "71002443110",
    saturated: "0.0020657036608502",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swimming Pool #2",
    icon: "0",
    pool_id: "8a9df42ba66533f24c1ce39757af0aeb0a1fbe878f4db672b7762e5e",
    db_ticker: "SWIM",
    db_name: "The Swimming Pool #2",
    db_url: "https://pool.la",
    roa: "0",
    roa_lifetime: "2.298",
    total_stake: "16434417376",
    stake_x_deleg: "8217208688",
    saturated: "0.00023906667609274",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VoltPool 2",
    icon: "0",
    pool_id: "a522e3818309ba617a48c5973d78a04395cd4ce5d96940d646458c9e",
    db_ticker: "VOLT2",
    db_name: "VoltPool 2",
    db_url: "https://voltpool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23655260674",
    stake_x_deleg: "11827630337",
    saturated: "0.00034410617742367",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EvergreenPool1",
    icon: "0",
    pool_id: "649e75e75cc66df5e6b1c1358ecc1e5e6b404b7bdbdb7cb88fb320b2",
    db_ticker: "EGRN1",
    db_name: "EvergreenPool1",
    db_url: "https://evergreenstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10341439785",
    stake_x_deleg: "5170719892.5",
    saturated: "0.00015043390823356",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0075",
    tax_fix: "340000000",
    pledge: "8000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nordic Staking",
    icon: "0",
    pool_id: "e2d11c2a62badb1e0245c74fd2731c0beb16f88cb83f1f878bb5174d",
    db_ticker: "NORST",
    db_name: "Nordic Staking",
    db_url: "https://www.nordicstaking.se/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7131793421",
    stake_x_deleg: "3565896710.5",
    saturated: "0.00010374411874365",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "6900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Serene Salmon Pascal",
    icon: "0",
    pool_id: "07c30535c3781afef83200ebee872e4abe0aa8cc7c448ccd68730bb8",
    db_ticker: "SSP",
    db_name: "Serene Salmon Pascal",
    db_url: "",
    roa: "1.163",
    roa_lifetime: "3.024",
    total_stake: "79755218327964",
    stake_x_deleg: "39877609163982",
    saturated: "1.1601758985725",
    luck_lifetime: "90",
    blocks_epoch: "61",
    blocks_lifetime: "136",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sakonera",
    icon: "0",
    pool_id: "2a05a4a45d412e56f49b86184c6abbe81464d60fa3a9c0aee9c8ff37",
    db_ticker: "SAK01",
    db_name: "Sakonera",
    db_url: "https://pool.teknik.fr",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10137954961",
    stake_x_deleg: "5068977480.5",
    saturated: "0.00014747387384986",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5550000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "metaspan",
    icon: "0",
    pool_id: "cb47d5fc20c406149d5e850fd2fa03a88270c60aef3dd6d224ae772a",
    db_ticker: "MSPN",
    db_name: "metaspan",
    db_url: "https://metaspan.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8660898412",
    stake_x_deleg: "4330449206",
    saturated: "0.00012598756304908",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sidan Stake",
    icon: "0",
    pool_id: "74c634463f71526c4df789a2cc9afe3f03ad3fb245bc160fe3152d6c",
    db_ticker: "SIDAN",
    db_name: "Sidan Stake",
    db_url: "https://sidan.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6431550480",
    stake_x_deleg: "3215775240",
    saturated: "0.000093557888922892",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Umikaji stake pool",
    icon: "0",
    pool_id: "667fcfba65c8997a03386e21075bf58c196a6e82f69dc72d9a8133cb",
    db_ticker: "UMKJI",
    db_name: "Umikaji stake pool",
    db_url: "https://twitter.com/Umikajipool",
    roa: "0.788",
    roa_lifetime: "0.788",
    total_stake: "467704213794",
    stake_x_deleg: "233852106897",
    saturated: "0.0068035567813669",
    luck_lifetime: "41",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kestrel Pool",
    icon: "0",
    pool_id: "856e676567c8a484c8d9b6726c8844e2857445eab8726d27478dd907",
    db_ticker: "KSTRL",
    db_name: "Kestrel Pool",
    db_url: "https://kestrelpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11750120320",
    stake_x_deleg: "5875060160",
    saturated: "0.00017092557310211",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANSOPool",
    icon: "0",
    pool_id: "51a6135f11063eb4358395bb30cd991a36bf4e5b2e25528fb50610f7",
    db_ticker: "ANSO",
    db_name: "ANSOPool",
    db_url: "https://csp.lineofwork.eu",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23288038836",
    stake_x_deleg: "11644019418",
    saturated: "0.00033876430845498",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HotbitPool",
    icon: "0",
    pool_id: "85b5267ebc3eea1cb5ae01850fa16754e9e011e3e21527c922b0e4c2",
    db_ticker: "HTB",
    db_name: "HotbitPool",
    db_url: "https://www.hotbit.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "99627906165",
    stake_x_deleg: "49813953082.5",
    saturated: "0.0014492580922113",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EPYC",
    icon: "0",
    pool_id: "0a627257cebd81cebc366f8796e6fa9dfec4a756c58c7316e617cc72",
    db_ticker: "EPYC",
    db_name: "EPYC",
    db_url: "https://epycpool.com",
    roa: "0",
    roa_lifetime: "1.601",
    total_stake: "5383280646",
    stake_x_deleg: "2691640323",
    saturated: "0.000078309013399708",
    luck_lifetime: "55",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "In Good Fortune",
    icon: "0",
    pool_id: "71acb0e19dc67871621b95a967cf8a9fdbbf6943eeb65c8567f270c8",
    db_ticker: "IGF",
    db_name: "In Good Fortune",
    db_url: "https://www.youtube.com/c/InGoodFortune",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2230627675",
    stake_x_deleg: "1115313837.5",
    saturated: "0.000032448290174344",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Oracle Stake Pool",
    icon: "0",
    pool_id: "5fab8b03bc76e65ca684cbefec51d0425b1d08caaf32573255648b4c",
    db_ticker: "ORACL",
    db_name: "Oracle Stake Pool",
    db_url: "https://www.plutusoracle.com",
    roa: "0",
    roa_lifetime: "8.664",
    total_stake: "5650604193",
    stake_x_deleg: "2825302096.5",
    saturated: "0.000082197691066855",
    luck_lifetime: "133",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stakehaus.ca",
    icon: "0",
    pool_id: "8bf855ee961425617d75100a72c2314cf349941dbd9b8df777d1cee1",
    db_ticker: "HAUS",
    db_name: "Stakehaus.ca",
    db_url: "https://stakehaus.ca",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4515890108",
    stake_x_deleg: "2257945054",
    saturated: "0.000065691336237829",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KeenPool",
    icon: "0",
    pool_id: "b7ff8d79337bc800a52e3e4ad04ff8ef7d18dcbca4dc62f9975cbee5",
    db_ticker: "KEEN",
    db_name: "KeenPool",
    db_url: "https://keen.world",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "19605950",
    stake_x_deleg: "9802975",
    saturated: "2.8520203612361e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bionix Pool 01",
    icon: "0",
    pool_id: "214d4b335dc737939c66a4f9e96506c9a870d619394bfc2240038f4d",
    db_ticker: "BP1",
    db_name: "Bionix Pool 01",
    db_url: "https://proficardanostaking.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "537175907",
    stake_x_deleg: "268587953.5",
    saturated: "0.0000078141412394169",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GoldenState",
    icon: "0",
    pool_id: "1f29f5e12943a62d01bbc6005fe13ca8a78223b8dd1226385a580a2c",
    db_ticker: "GSS",
    db_name: "GoldenState",
    db_url: "https://goldenstatestaking.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5021439761",
    stake_x_deleg: "2510719880.5",
    saturated: "0.000073045419584832",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000001",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PINT",
    icon: "0",
    pool_id: "11152917b1e01cb25ac9d993b207bfed11c097b34b77a5ce6f97cfa9",
    db_ticker: "PINT",
    db_name: "PINT",
    db_url: "https://beerhouse.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "528332985",
    stake_x_deleg: "264166492.5",
    saturated: "0.0000076855058323246",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Black Wall Street",
    icon: "0",
    pool_id: "5462801d8bd4c445f1da7c06541152de6f839d2147c7218a0d3d890a",
    db_ticker: "bkwst",
    db_name: "Black Wall Street",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5054285683",
    stake_x_deleg: "2527142841.5",
    saturated: "0.000073523219631898",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bishnoi Cardano Pool",
    icon: "0",
    pool_id: "07ec3d0395dee2737bdf6854c99a6f47568057239b18d754261654be",
    db_ticker: "BISH",
    db_name: "Bishnoi Cardano Pool",
    db_url: "https://bishnoicardanopool.org/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507449373",
    stake_x_deleg: "253724686.5",
    saturated: "0.0000073817180197465",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FMS-stakepool",
    icon: "0",
    pool_id: "e07b50f6a0de11d0c2bcee1662e7b0ad469758b778cc8e763fa9a69d",
    db_ticker: "FMS",
    db_name: "FMS-stakepool",
    db_url: "coming",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4047100614",
    stake_x_deleg: "2023550307",
    saturated: "0.000058871992201852",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaHolland",
    icon: "0",
    pool_id: "f96080f834deb1a7d2a3e7a867fbcdc3d23cb14bb6e8297ffbb7f42d",
    db_ticker: "ADANL",
    db_name: "AdaHolland",
    db_url: "https://adaholland.eu",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "448387994",
    stake_x_deleg: "224193997",
    saturated: "0.0000065225693660435",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Canada West-Coast Pool",
    icon: "0",
    pool_id: "9c7a86e87909e4e8a129d1ebb9769a9c34e06e17e938feb3d736106f",
    db_ticker: "CANW0",
    db_name: "Canada West-Coast Pool",
    db_url: "https://adacanw.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4095742493",
    stake_x_deleg: "2047871246.5",
    saturated: "0.000059579571428142",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1097000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Terry's Lair",
    icon: "0",
    pool_id: "4ec6dab61e2c65dae29be13d774b40f5a10e5d6652b3baf1bb61b45a",
    db_ticker: "TLP",
    db_name: "Terry's Lair",
    db_url: "https://terryslair.com/cardano",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3188198901",
    stake_x_deleg: "1594099450.5",
    saturated: "0.000046377799501287",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "oceanblue_pool",
    icon: "0",
    pool_id: "46dc12d35b1a674be21e187d3ec3be305db31eb6b5b1f21aa733b97a",
    db_ticker: "OBLUE",
    db_name: "oceanblue_pool",
    db_url: "https://oceanblue.mr-bandwidth.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "158530782",
    stake_x_deleg: "79265391",
    saturated: "0.0000023061010466041",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanopool Germany 1",
    icon: "0",
    pool_id: "2f228fca2b2e1f3810481b7426de727e5b46db5e76d914c353c3fcf5",
    db_ticker: "CAGE1",
    db_name: "Cardanopool Germany 1",
    db_url: "https://cardanopool-germany.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2078492389",
    stake_x_deleg: "1039246194.5",
    saturated: "0.000030235222542658",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "everwin01",
    icon: "0",
    pool_id: "007c8cf86eb1eebd45871699623a283e77400e96789ffd2fa7d9a4b1",
    db_ticker: "EVE01",
    db_name: "everwin01",
    db_url: "https://ever-peace.blogspot.com/2021/03/welcome-to-my-blog.html",
    roa: "0",
    roa_lifetime: "2.636",
    total_stake: "180079852",
    stake_x_deleg: "90039926",
    saturated: "0.0000026195690826121",
    luck_lifetime: "118",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lucky8888",
    icon: "0",
    pool_id: "90e0b2f701fa0162d2ba28b9a39994348ef57c06ce8ea94a0d8944ad",
    db_ticker: "8888",
    db_name: "Lucky8888",
    db_url: "https://lucky8888.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7952568",
    stake_x_deleg: "3976284",
    saturated: "1.1568368714658e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0088",
    tax_fix: "340000000",
    pledge: "8000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MARIA POOL",
    icon: "0",
    pool_id: "3782c245cbf14dc7dabc7e52fc7a2b1e7a2bd9ae8f8fa6a2ef227b85",
    db_ticker: "MARIA",
    db_name: "MARIA POOL",
    db_url: "https://cardano.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1023950513",
    stake_x_deleg: "511975256.5",
    saturated: "0.000014895109453886",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Thamizh ADA Community",
    icon: "0",
    pool_id: "0d80bdc1e113546386f019a65b5a88d92a89f8b96a0b7bfda4b49af4",
    db_ticker: "TAMIL",
    db_name: "Thamizh ADA Community",
    db_url: "https://tamil.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "497196005",
    stake_x_deleg: "248598002.5",
    saturated: "0.0000072325652660812",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "123000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Eastern Bloc",
    icon: "0",
    pool_id: "30832995349da8afcd2285b86c92238de89b9c5e3c4e5041ba63889c",
    db_ticker: "SLAV",
    db_name: "Eastern Bloc",
    db_url: "https://eastern-bloc.biz",
    roa: "0",
    roa_lifetime: "3.068",
    total_stake: "134665471",
    stake_x_deleg: "67332735.5",
    saturated: "0.0000019589393283541",
    luck_lifetime: "114",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Moeko Pool",
    icon: "https://cdn.adapools.org/pool_logo/1285e74b0ad83a6b2bf1d765103253b4f15e624468236292ca718183.png",
    pool_id: "1285e74b0ad83a6b2bf1d765103253b4f15e624468236292ca718183",
    db_ticker: "MOEKO",
    db_name: "Moeko Pool",
    db_url: "https://cardano-ada.info/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1766220128",
    stake_x_deleg: "883110064",
    saturated: "0.00002569268904328",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "101000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KTO",
    icon: "0",
    pool_id: "008a049b06862ba605031c8fa3c31b5ae41890a6ee6e49ead3b4f28c",
    db_ticker: "KTO",
    db_name: "KTO",
    db_url: "https://myadapoolnamerocks.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "345062719",
    stake_x_deleg: "172531359.5",
    saturated: "0.0000050195267278121",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "InnovationsVault",
    icon: "0",
    pool_id: "c90be85fa265aa78ac40831bdd5d97389f6fb6a8a4206ecc68cadad4",
    db_ticker: "INV",
    db_name: "InnovationsVault",
    db_url: "https://innovationsvault.com/INV_MetaData.json",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3138124085",
    stake_x_deleg: "1569062042.5",
    saturated: "0.000045649375758407",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nam Pool",
    icon: "https://cdn.adapools.org/pool_logo/9382bd037bb313c831dd9d5250a6f3f07f286e31fe009f2564ec76bc.png",
    pool_id: "9382bd037bb313c831dd9d5250a6f3f07f286e31fe009f2564ec76bc",
    db_ticker: "NAM",
    db_name: "Nam Pool",
    db_url: "https://nampool.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "144698748",
    stake_x_deleg: "72349374",
    saturated: "0.0000021048904824371",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Arigato StakePool",
    icon: "https://cdn.adapools.org/pool_logo/cdf5b86f71679302bffd08b3b8fec1fd6135350b156e964d3b001662.png",
    pool_id: "cdf5b86f71679302bffd08b3b8fec1fd6135350b156e964d3b001662",
    db_ticker: "ARGT",
    db_name: "Arigato StakePool",
    db_url: "https://www.arigatopool.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2527598125",
    stake_x_deleg: "1263799062.5",
    saturated: "0.000036768232692229",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adastakeit.io Pool",
    icon: "0",
    pool_id: "2ee2613287e136ef6968aaefa0512eb20a2b2f07efd8424071f71f52",
    db_ticker: "ADAIT",
    db_name: "adastakeit.io Pool",
    db_url: "https://adastakeit.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2015433009",
    stake_x_deleg: "1007716504.5",
    saturated: "0.000029317916134517",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite J",
    icon: "https://cdn.adapools.org/pool_logo/1a84b07b5c4b1c647ea1263cf59d18c74390779b6c88cd7105d2382d.png",
    pool_id: "1a84b07b5c4b1c647ea1263cf59d18c74390779b6c88cd7105d2382d",
    db_ticker: "NUFIJ",
    db_name: "Nu.Fi &amp; AdaLite J",
    db_url: "https://nu.fi",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1012040808",
    stake_x_deleg: "506020404",
    saturated: "0.00001472186244899",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tropical Vibes Pool",
    icon: "0",
    pool_id: "7447913be564ff4e2505fb21ca5de980517f3b1575e006ce2d754b74",
    db_ticker: "TROPC",
    db_name: "Tropical Vibes Pool",
    db_url: "https://tropicpool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5403208721",
    stake_x_deleg: "2701604360.5",
    saturated: "0.000078598901294252",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MetaCrypt Pool",
    icon: "0",
    pool_id: "ddc6cb1a8c4b3c443694742d32604ae9dba437443ece343660777d2c",
    db_ticker: "MCRP",
    db_name: "MetaCrypt Pool",
    db_url: "https://www.mcrypt.me",
    roa: "0",
    roa_lifetime: "8.386",
    total_stake: "3895289982",
    stake_x_deleg: "1947644991",
    saturated: "0.000056663647217212",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "3110000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADACucumber",
    icon: "0",
    pool_id: "e5bf910142144e2fb4721a39206d839c1c916f81ed9090d1c247e651",
    db_ticker: "CUCUM",
    db_name: "ADACucumber",
    db_url: "https://adacucumber.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2642662192",
    stake_x_deleg: "1321331096",
    saturated: "0.000038442036113796",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bishnoi Cardano Pool",
    icon: "0",
    pool_id: "4ab8e5e8f3cda61258e172877d1d1b0e35814db9b7e84da953a09356",
    db_ticker: "BISH",
    db_name: "Bishnoi Cardano Pool",
    db_url: "https://bishnoicardanopool.org/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "738215807",
    stake_x_deleg: "369107903.5",
    saturated: "0.000010738610026805",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "345000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "xc0de pool",
    icon: "0",
    pool_id: "e1ab985427b2411e567d35355e5b0bca982c8602d1db642155b7bfe3",
    db_ticker: "XC0DE",
    db_name: "xc0de pool",
    db_url: "https://xc0de.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2701791320",
    stake_x_deleg: "1350895660",
    saturated: "0.000039302170292441",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2600000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo #5",
    icon: "0",
    pool_id: "446f60e9628689e2b6ad148e7e8862594cb4824798e26d8cb51abe65",
    db_ticker: "EMUR5",
    db_name: "Emurgo #5",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "519041456",
    stake_x_deleg: "259520728",
    saturated: "0.0000075503446700498",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swiss Mountains",
    icon: "https://cdn.adapools.org/pool_logo/2ab227ed469ebcb156c62e3b0e6eedb00a911743c6a5dacdbfa95872.png",
    pool_id: "2ab227ed469ebcb156c62e3b0e6eedb00a911743c6a5dacdbfa95872",
    db_ticker: "MOUNT",
    db_name: "Swiss Mountains",
    db_url: "https://cardano.schroeffu.ch",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "956643522",
    stake_x_deleg: "478321761",
    saturated: "0.000013916014287441",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "111000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaMoose",
    icon: "0",
    pool_id: "da3cb5918133919b8d42d6cfc6fe621f156f74b8f6a3a11c9e86ff98",
    db_ticker: "Moose",
    db_name: "AdaMoose",
    db_url: "https://adamoose.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "117015398",
    stake_x_deleg: "58507699",
    saturated: "0.000001702188864473",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CONTAMINATED WATER DATA STAKE POOL",
    icon: "0",
    pool_id: "f56054ca2ff464652ce2fee8638d8402e8ae73ebaca7e87c835a0e83",
    db_ticker: "CLEAN",
    db_name: "CONTAMINATED WATER DATA STAKE POOL",
    db_url: "https://clean.watercontaminationdata.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5585614991",
    stake_x_deleg: "2792807495.5",
    saturated: "0.000081252312100957",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "345000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockops Zone",
    icon: "0",
    pool_id: "4413cf615dc6877ad1b33c75b26e054fc25291f918420c88ef9bb37b",
    db_ticker: "BZONE",
    db_name: "Blockops Zone",
    db_url: "https://www.blockops.zone",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1358063457",
    stake_x_deleg: "679031728.5",
    saturated: "0.000019755352998527",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Creative Opco Stake Pool",
    icon: "0",
    pool_id: "b09e8bd0fa5cca9bfbc9463d9d957fd34eacc7e4a038e83189cb1901",
    db_ticker: "OPCO",
    db_name: "Creative Opco Stake Pool",
    db_url: "https://cardano.creativedock.cloud",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2568212709",
    stake_x_deleg: "1284106354.5",
    saturated: "0.0000373590411995",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Keep It Simple Stupid",
    icon: "https://cdn.adapools.org/pool_logo/d4bb78d4af682e20786aa0d9413e8657607da14ce05b3dc2cd596e2e.png",
    pool_id: "d4bb78d4af682e20786aa0d9413e8657607da14ce05b3dc2cd596e2e",
    db_ticker: "KISS",
    db_name: "Keep It Simple Stupid",
    db_url: "https://kiss-pool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "62561989",
    stake_x_deleg: "31280994.5",
    saturated: "9.1007100633954e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GenzPool",
    icon: "0",
    pool_id: "78bec72f0bc42c5dce0aebf344faf4037bd9ea977852c6349b7dd967",
    db_ticker: "GENZP",
    db_name: "GenzPool",
    db_url: "https://genz-pool.github.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "347543871",
    stake_x_deleg: "173771935.5",
    saturated: "0.0000050556193222711",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AIS stake pool 5",
    icon: "0",
    pool_id: "a5f9c6715a7612b989e0db859d7a7b0cf53e3c6e605014078b23d5eb",
    db_ticker: "AIS5",
    db_name: "AIS stake pool 5",
    db_url: "https://relay0.ais-staking.site",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "474234206547",
    stake_x_deleg: "158078068849",
    saturated: "0.0068985466813222",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MCA",
    icon: "0",
    pool_id: "a3acf7487de502cd0c3a746a88ee46066564ca24ed9e6e977a8f0383",
    db_ticker: "MCA",
    db_name: "MCA",
    db_url: "https://mcwhirter.com.au",
    roa: "0.977",
    roa_lifetime: "1.205",
    total_stake: "343636036007",
    stake_x_deleg: "49090862286.714",
    saturated: "0.0049987731864379",
    luck_lifetime: "69",
    blocks_epoch: "0",
    blocks_lifetime: "30",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ambassador's Guild Stake Pool",
    icon: "0",
    pool_id: "3ed0c5c6d486e8c9c2ab073d502df1d2dcf22f233704a14b02f80658",
    db_ticker: "AMB",
    db_name: "Ambassador's Guild Stake Pool",
    db_url: "https://www.ambassadors-guild.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "13658857124",
    stake_x_deleg: "6829428562",
    saturated: "0.0001986914106629",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "3900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ALPEN Pool for Development",
    icon: "0",
    pool_id: "34fa6e42ffafbb9bf63b1c78e99b09b30527f37ac544668dfaab624d",
    db_ticker: "ALPEN",
    db_name: "ALPEN Pool for Development",
    db_url: "https://github.com/gitmachtl/StakePool-Operator-Tools-Alliance",
    roa: "0",
    roa_lifetime: "1.235",
    total_stake: "68056370238",
    stake_x_deleg: "34028185119",
    saturated: "0.00098999616764603",
    luck_lifetime: "55",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ANDROMEDA",
    icon: "0",
    pool_id: "1aee47982a812687e08d09e8e608be8c05b02a703f281addb96d9df2",
    db_ticker: "MEDA🌌",
    db_name: "ANDROMEDA",
    db_url: "https://www.facebook.com/groups/1172142893230568",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "550762737",
    stake_x_deleg: "275381368.5",
    saturated: "0.0000080117848925154",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KUDOS",
    icon: "0",
    pool_id: "119ffddda4d8951cf9eac7c44239aea2953beb5907619e932787331c",
    db_ticker: "KUDOS",
    db_name: "KUDOS",
    db_url: "www.kudos.wiki",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "114280001",
    stake_x_deleg: "57140000.5",
    saturated: "0.0000016623978421555",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Next Apex Obtai Stake Pool",
    icon: "0",
    pool_id: "2cd4397dad6bfa983d58434fade6e15bbbd932969b569c70026ed020",
    db_ticker: "NAO",
    db_name: "Next Apex Obtai Stake Pool",
    db_url: "https://twitter.com/naonao_POOL",
    roa: "3.333",
    roa_lifetime: "3.154",
    total_stake: "3185410217",
    stake_x_deleg: "637082043.4",
    saturated: "0.000046337233328522",
    luck_lifetime: "113",
    blocks_epoch: "0",
    blocks_lifetime: "19",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Qinglong Stake Pool",
    icon: "0",
    pool_id: "945b1e23eb7aadfa971de44a35dc2eee8cb1af795cabe8ee192c2e39",
    db_ticker: "QSP",
    db_name: "Qinglong Stake Pool",
    db_url: "https://www.qinglong-pool.com/",
    roa: "3.542",
    roa_lifetime: "3.282",
    total_stake: "1713042414751",
    stake_x_deleg: "107065150921.94",
    saturated: "0.024919128359151",
    luck_lifetime: "102",
    blocks_epoch: "4",
    blocks_lifetime: "126",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fat Stakes",
    icon: "https://cdn.adapools.org/pool_logo/209b1ad731564c614a72caebfaeb42c266bcd073133247304b334dc4.png",
    pool_id: "209b1ad731564c614a72caebfaeb42c266bcd073133247304b334dc4",
    db_ticker: "FAT",
    db_name: "Fat Stakes",
    db_url: "https://fatstakes.com",
    roa: "3.333",
    roa_lifetime: "2.395",
    total_stake: "50063720186",
    stake_x_deleg: "50063720186",
    saturated: "0.00072826233530993",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "41000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WeCloudPool - Cardano Stake Pool",
    icon: "0",
    pool_id: "612d13564afa472a90eb51d46dbb212c743b9d0c53003b1917daafaf",
    db_ticker: "OMWCP",
    db_name: "WeCloudPool - Cardano Stake Pool",
    db_url: "https://www.wecloud-pool.ddnsfree.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6084483821",
    stake_x_deleg: "1521120955.25",
    saturated: "0.000088509211464395",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "4000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cornerstone",
    icon: "0",
    pool_id: "03ab72b1e401291da886d4d18951c7896a0f397b4d9facb7e1671e27",
    db_ticker: "CORN",
    db_name: "Cornerstone",
    db_url: "https://cornerstonestakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10287453848",
    stake_x_deleg: "2571863462",
    saturated: "0.00014964859055426",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "6000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake-Me",
    icon: "0",
    pool_id: "551ac8adfb28d1d417f848ce663e85627ff7d0b62746c7e327b13ed8",
    db_ticker: "STKME",
    db_name: "Stake-Me",
    db_url: "https://stakepool.me",
    roa: "0",
    roa_lifetime: "2.057",
    total_stake: "87706770229",
    stake_x_deleg: "87706770229",
    saturated: "0.0012758448048239",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "16",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "87000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CARDANIONS",
    icon: "0",
    pool_id: "8e5cafd11f60a5af9c6f05a40c5cd2223cb16d9a7d457a9347b32e9a",
    db_ticker: "ADALV",
    db_name: "CARDANIONS",
    db_url: "https://cardanions.github.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "55362712940",
    stake_x_deleg: "18454237646.667",
    saturated: "0.00080534523732922",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1130000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Swimming Pool #3",
    icon: "0",
    pool_id: "d07cbd3c49635bdafebeb5e0d2c9ce8089031b2da3fe6323daf8b846",
    db_ticker: "SWIM",
    db_name: "The Swimming Pool #3",
    db_url: "https://pool.la",
    roa: "0",
    roa_lifetime: "2.102",
    total_stake: "116377223713",
    stake_x_deleg: "116377223713",
    saturated: "0.0016929055292582",
    luck_lifetime: "78",
    blocks_epoch: "0",
    blocks_lifetime: "8",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ForivoraPool",
    icon: "0",
    pool_id: "d9bf76f675bf1a4e98f6aebf2fccecbfbbef9ffbb3aff330846b2afe",
    db_ticker: "FORIV",
    db_name: "ForivoraPool",
    db_url: "https://twitter.com/ForivoraPool",
    roa: "1.893",
    roa_lifetime: "3.28",
    total_stake: "791625364516",
    stake_x_deleg: "395812682258",
    saturated: "0.011515543281864",
    luck_lifetime: "118",
    blocks_epoch: "1",
    blocks_lifetime: "22",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RIPI Stake Pool",
    icon: "0",
    pool_id: "5ca3c3845af455c22b8b8c0afcbe473be44e164f664ac5f0a2950c24",
    db_ticker: "RPSP",
    db_name: "RIPI Stake Pool",
    db_url: "https://cardano-ada-staking-pool.com",
    roa: "3.086",
    roa_lifetime: "3.728",
    total_stake: "8293506326185",
    stake_x_deleg: "394928872675.48",
    saturated: "0.12064321753508",
    luck_lifetime: "94",
    blocks_epoch: "4",
    blocks_lifetime: "341",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WOOD POOL | 手数料 0%",
    icon: "https://cdn.adapools.org/pool_logo/aed203c22880727836ddd6116dea4243755ab58e57594b03a7756fd6.png",
    pool_id: "aed203c22880727836ddd6116dea4243755ab58e57594b03a7756fd6",
    db_ticker: "WOODY",
    db_name: "WOOD POOL | 手数料 0%",
    db_url: "https://ada-woodpool.com",
    roa: "3.333",
    roa_lifetime: "4.214",
    total_stake: "24645673957",
    stake_x_deleg: "24645673957",
    saturated: "0.00035851343057465",
    luck_lifetime: "230",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Jettpools JETT2 Stake",
    icon: "0",
    pool_id: "d0e71ce3f4dff0277ecf58ff2bade3a90ffffaaa4748eb9f0f178efe",
    db_ticker: "JETT2",
    db_name: "Jettpools JETT2 Stake",
    db_url: "https://jettpools.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "28656687597",
    stake_x_deleg: "14328343798.5",
    saturated: "0.00041686047609132",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Monster",
    icon: "https://cdn.adapools.org/pool_logo/a223873b6d882d07c44bfd2baac9f5acbb09c5b886858404cb375a99.png",
    pool_id: "a223873b6d882d07c44bfd2baac9f5acbb09c5b886858404cb375a99",
    db_ticker: "MNSTR",
    db_name: "Monster",
    db_url: "https://www.monster-stakepool.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10009493254",
    stake_x_deleg: "10009493254",
    saturated: "0.00014560517886694",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Simplicity Pool",
    icon: "https://cdn.adapools.org/pool_logo/9a659a6c7b867db50f91a93340883babacc8be128a41b28cc64a72ce.png",
    pool_id: "9a659a6c7b867db50f91a93340883babacc8be128a41b28cc64a72ce",
    db_ticker: "SIM0",
    db_name: "Simplicity Pool",
    db_url: "https://simplicity-ada.github.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5343849",
    stake_x_deleg: "5343849",
    saturated: "7.7735412746493e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Convergence Pool",
    icon: "0",
    pool_id: "2f9bb503374940213698c211e5d395b965414a9aec1924702bca4364",
    db_ticker: "VERGE",
    db_name: "Convergence Pool",
    db_url: "https://www.convergencepool.com",
    roa: "0",
    roa_lifetime: "4.104",
    total_stake: "5959554689",
    stake_x_deleg: "5959554689",
    saturated: "0.000086691903819646",
    luck_lifetime: "122",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5199000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Van Gogh's Pool",
    icon: "0",
    pool_id: "1b9bf256c280fbaaa578a8809c570cad2bce820d7947d7638ac2e81f",
    db_ticker: "VANGO",
    db_name: "Not Van Gogh's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "1.989",
    total_stake: "12605410207",
    stake_x_deleg: "12605410207",
    saturated: "0.00018336722562333",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "42",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not MC Escher's Pool",
    icon: "0",
    pool_id: "56f8839adfff7f7f62de80d1c297d4eb1916738900e91af7038bd4fb",
    db_ticker: "MCE",
    db_name: "Not MC Escher's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "1.284",
    total_stake: "6200401865",
    stake_x_deleg: "6200401865",
    saturated: "0.000090195437440298",
    luck_lifetime: "51",
    blocks_epoch: "0",
    blocks_lifetime: "16",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gratis ADA",
    icon: "https://cdn.adapools.org/pool_logo/424fdaea5abb2fd69f353df22abcf5346b09879d3dbcb8d8442baaa0.png",
    pool_id: "424fdaea5abb2fd69f353df22abcf5346b09879d3dbcb8d8442baaa0",
    db_ticker: "GRADA",
    db_name: "Gratis ADA",
    db_url: "https://cardano.gratis",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "827685351",
    stake_x_deleg: "827685351",
    saturated: "0.000012040097387522",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GELDTIGER",
    icon: "https://cdn.adapools.org/pool_logo/46a1e410fab850127d532108d8c00c81c407b25cc5608baaa541c62f.png",
    pool_id: "46a1e410fab850127d532108d8c00c81c407b25cc5608baaa541c62f",
    db_ticker: "GETI",
    db_name: "GELDTIGER",
    db_url: "https://www.geldtiger.at",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "143881594",
    stake_x_deleg: "143881594",
    saturated: "0.0000020930035815408",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "KODL Zero Pool",
    icon: "https://cdn.adapools.org/pool_logo/2412ac3192e68fa6179ca3a84cfcb080a7007e7a8c4958dc9c2c4d72.png",
    pool_id: "2412ac3192e68fa6179ca3a84cfcb080a7007e7a8c4958dc9c2c4d72",
    db_ticker: "KODL0",
    db_name: "KODL Zero Pool",
    db_url: "https://twitter.com/kodlpoolzero",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "105860951",
    stake_x_deleg: "105860951",
    saturated: "0.0000015399283774151",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAvault Stake Pool 4",
    icon: "https://cdn.adapools.org/pool_logo/2d8a81b805c360e9d6de6185b763f0556528d75a4561530e8ce6337c.png",
    pool_id: "2d8a81b805c360e9d6de6185b763f0556528d75a4561530e8ce6337c",
    db_ticker: "ADV4",
    db_name: "ADAvault Stake Pool 4",
    db_url: "https://adavault.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "500665361",
    stake_x_deleg: "500665361",
    saturated: "0.0000072830329758957",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PimpMyPool",
    icon: "https://cdn.adapools.org/pool_logo/fb83453645f6948f72ca95a381388e303bdafa91678ca653401d3ea1.png",
    pool_id: "fb83453645f6948f72ca95a381388e303bdafa91678ca653401d3ea1",
    db_ticker: "PMP",
    db_name: "PimpMyPool",
    db_url: "https://pimpmypool.github.io/PimpMyPool/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1000713424",
    stake_x_deleg: "1000713424",
    saturated: "0.000014557086297834",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Animal Welfare Pool",
    icon: "https://cdn.adapools.org/pool_logo/216c4e92bef5b9692600324c241e8af852c095b11e534646c2d817af.png",
    pool_id: "216c4e92bef5b9692600324c241e8af852c095b11e534646c2d817af",
    db_ticker: "AW1",
    db_name: "Animal Welfare Pool",
    db_url: "https://www.animalwelfare.cc/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3822575",
    stake_x_deleg: "3822575",
    saturated: "5.5605883583055e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "May the ADA be with you",
    icon: "https://cdn.adapools.org/pool_logo/d9987a7c15fb38031c678ff6efb0175cf910488fffdb7830f11a672a.png",
    pool_id: "d9987a7c15fb38031c678ff6efb0175cf910488fffdb7830f11a672a",
    db_ticker: "R2D2",
    db_name: "May the ADA be with you",
    db_url: "https://www.nodo-r2d2.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8247561",
    stake_x_deleg: "8247561",
    saturated: "1.1997486427608e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.006",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Dali's Pool",
    icon: "0",
    pool_id: "35f20ce45b66499e9dffb291b8e488e442e2d2ea5dc3ba20a40a6ba4",
    db_ticker: "DALI",
    db_name: "Not Dali's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0.647",
    total_stake: "2519329919",
    stake_x_deleg: "2519329919",
    saturated: "0.00003664795751116",
    luck_lifetime: "31",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EazyPeazy",
    icon: "https://cdn.adapools.org/pool_logo/dc3852b69aacfec0fc276e1971f03fa37984fc40b57fb97441c38f3e.png",
    pool_id: "dc3852b69aacfec0fc276e1971f03fa37984fc40b57fb97441c38f3e",
    db_ticker: "EZPZ",
    db_name: "EazyPeazy",
    db_url: "https://ezpz-sandbox.mxapps.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1786621656",
    stake_x_deleg: "1786621656",
    saturated: "0.000025989464120521",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1001000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaPies",
    icon: "https://cdn.adapools.org/pool_logo/fa199852f76ae97d4844f4134f7f48014d1c413473e84db28207ba59.png",
    pool_id: "fa199852f76ae97d4844f4134f7f48014d1c413473e84db28207ba59",
    db_ticker: "AdaPi",
    db_name: "AdaPies",
    db_url: "https://DougDo.github.io/AdaPies",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1001425841",
    stake_x_deleg: "1001425841",
    saturated: "0.000014567449620141",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The UK Pool",
    icon: "https://cdn.adapools.org/pool_logo/bbebda24411d5538125ef9b02bae0edf5f55aa87934ee6025828a3da.png",
    pool_id: "bbebda24411d5538125ef9b02bae0edf5f55aa87934ee6025828a3da",
    db_ticker: "UKPL",
    db_name: "The UK Pool",
    db_url: "https://www.theukpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "500823197",
    stake_x_deleg: "500823197",
    saturated: "0.0000072853289701512",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Design an individual",
    icon: "0",
    pool_id: "1e9a280b52e5157467f05e5231e7f521d3022cf8b14084b19beed138",
    db_ticker: "DAI",
    db_name: "Design an individual",
    db_url: "http://i-design.watson.jp/",
    roa: "2.326",
    roa_lifetime: "2.21",
    total_stake: "1381334073756",
    stake_x_deleg: "276266814751.2",
    saturated: "0.020093863873066",
    luck_lifetime: "77",
    blocks_epoch: "1",
    blocks_lifetime: "57",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "STANLEY BARREL POOL",
    icon: "0",
    pool_id: "6bbf60bbb60c1f4467e87377f536b5efd464c187b16481d9d0cb3e63",
    db_ticker: "SBP",
    db_name: "STANLEY BARREL POOL",
    db_url: "https://stanleybarrel.com",
    roa: "0",
    roa_lifetime: "2.799",
    total_stake: "28050272450",
    stake_x_deleg: "14025136225",
    saturated: "0.00040803913182284",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "6",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IRR pool",
    icon: "0",
    pool_id: "5bc677373fc02147e108ed81304a02d242e535a74d5e9109275cb406",
    db_ticker: "IRR",
    db_name: "IRR pool",
    db_url: "https://irrcompany.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1765101523",
    stake_x_deleg: "882550761.5",
    saturated: "0.000025676417022612",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Abbacadabba",
    icon: "0",
    pool_id: "2c601057bd42c0bb80d370bab54e91e2256218edaa9c0e69db3ff169",
    db_ticker: "ABCA",
    db_name: "Abbacadabba",
    db_url: "https://abbacadabba.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "489240394",
    stake_x_deleg: "244620197",
    saturated: "0.0000071168373133011",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO6 Pool",
    icon: "0",
    pool_id: "5ea0be57485dfdd737e36ecdb95d03b1472be4450267fbdae9625e99",
    db_ticker: "LEO6",
    db_name: "LEO6 Pool",
    db_url: "https://staking-fm.site",
    roa: "3.199",
    roa_lifetime: "3.931",
    total_stake: "19319912100575",
    stake_x_deleg: "3219985350095.8",
    saturated: "0.28104112623021",
    luck_lifetime: "101",
    blocks_epoch: "17",
    blocks_lifetime: "3704",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Distributed govenance through quality assurance",
    icon: "https://cdn.adapools.org/pool_logo/e8fb1e898f1d3d9e219b784a080793d65153abf9dd0ab35a698cdcee.png",
    pool_id: "e8fb1e898f1d3d9e219b784a080793d65153abf9dd0ab35a698cdcee",
    db_ticker: "QADAO",
    db_name: "Distributed govenance through quality assurance",
    db_url: "https://quality-assurance-dao.github.io/",
    roa: "3.979",
    roa_lifetime: "3.894",
    total_stake: "15323058470351",
    stake_x_deleg: "851281026130.61",
    saturated: "0.22290006224566",
    luck_lifetime: "100",
    blocks_epoch: "11",
    blocks_lifetime: "404",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cypoq Stakepool #1",
    icon: "0",
    pool_id: "211b3efbd6548e40552e79daa7a85ad55114b3ac4b01245115f9b6e1",
    db_ticker: "CYPOQ",
    db_name: "Cypoq Stakepool #1",
    db_url: "https://cypoq.com",
    roa: "0",
    roa_lifetime: "4.321",
    total_stake: "13127",
    stake_x_deleg: "13127",
    saturated: "1.9095464020844e-10",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "53",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "adalise A",
    icon: "0",
    pool_id: "e080d5bd7ebbe6ae668dac39e802b5d9e9ef8f8d9e85b00b244ffbca",
    db_ticker: "liseA",
    db_name: "adalise A",
    db_url: "https://adalise.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50428165761",
    stake_x_deleg: "50428165761",
    saturated: "0.00073356381879052",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.025",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TavernCrypto",
    icon: "0",
    pool_id: "e2a453d9123f25e10fc88b73cc892cf5c4c8e4789e5666d305086575",
    db_ticker: "TVRN",
    db_name: "TavernCrypto",
    db_url: "https://taverncrypto.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "20007256024",
    stake_x_deleg: "20007256024",
    saturated: "0.0002910397178046",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Hisa~iOkinawa",
    icon: "0",
    pool_id: "fa67f324c67325d6e60616eb8f20688473f979ec4b66193a5b43f898",
    db_ticker: "HISAI",
    db_name: "Hisa~iOkinawa",
    db_url: "https://twitter.com/hisa_iokinawa",
    roa: "4.492",
    roa_lifetime: "4.238",
    total_stake: "19179420731461",
    stake_x_deleg: "504721598196.34",
    saturated: "0.27899743926125",
    luck_lifetime: "100",
    blocks_epoch: "20",
    blocks_lifetime: "976",
    tax_ratio: "0.0035",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ONYX Stake Pool #5",
    icon: "0",
    pool_id: "422acce2a2fb6a9310c6122a258a34770689b92b20077440e287f77f",
    db_ticker: "ONYX",
    db_name: "ONYX Stake Pool #5",
    db_url: "https://onyxstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10000000000",
    stake_x_deleg: "10000000000",
    saturated: "0.00014546708326993",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "無双63 ADA POOL",
    icon: "0",
    pool_id: "bc83d31fe955f785ea2630e01c9442dec8ec0c4e394888cfb8e3fa61",
    db_ticker: "GG63",
    db_name: "無双63 ADA POOL",
    db_url: "https://ada63pool.com/",
    roa: "0",
    roa_lifetime: "5.031",
    total_stake: "10941092858",
    stake_x_deleg: "10941092858",
    saturated: "0.00015915688658387",
    luck_lifetime: "184",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "9000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AloraPool",
    icon: "0",
    pool_id: "0a149331a1f95a7b3609fce3f66dceb3fa1731da25b14493982cbbb4",
    db_ticker: "ALORA",
    db_name: "AloraPool",
    db_url: "https://alorapool.com",
    roa: "0",
    roa_lifetime: "1.524",
    total_stake: "28679053639",
    stake_x_deleg: "28679053639",
    saturated: "0.00041718582838072",
    luck_lifetime: "54",
    blocks_epoch: "0",
    blocks_lifetime: "11",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADALF1",
    icon: "0",
    pool_id: "041a8393e2256ccd2aad1a5161700804a24517eead41367d790dd6a7",
    db_ticker: "ADLF1",
    db_name: "ADALF1",
    db_url: "http://www.logiforge.com/cardano1",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8704721724",
    stake_x_deleg: "8704721724",
    saturated: "0.00012662504798667",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "8500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "grok",
    icon: "0",
    pool_id: "eb4646f0dba87979ebcbe1890f0eec6ff39ce90ea0705e266e70827b",
    db_ticker: "GROK",
    db_name: "grok",
    db_url: "https://www.grokism.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9306845910",
    stake_x_deleg: "9306845910",
    saturated: "0.00013538397289704",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IOADA 1",
    icon: "0",
    pool_id: "4e747c047c237eb5dc036f715c592d820d33323a88a73d19e6d400c8",
    db_ticker: "IOADA",
    db_name: "IOADA 1",
    db_url: "https://ioada.com",
    roa: "3.333",
    roa_lifetime: "4.495",
    total_stake: "12450611928",
    stake_x_deleg: "12450611928",
    saturated: "0.0001811154202092",
    luck_lifetime: "173",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1176000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BAHRTEC1",
    icon: "0",
    pool_id: "749522892c2efe4a6fdd351399bc67187cb948bc20a889bb644ee6ad",
    db_ticker: "BTEC1",
    db_name: "BAHRTEC1",
    db_url: "https://www.bahrtec.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11512818",
    stake_x_deleg: "11512818",
    saturated: "1.6747360546775e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bushido Pool",
    icon: "https://cdn.adapools.org/pool_logo/6688aae2724cb038fa348b3cacba7e002b653cc41dda86b750407c93.png",
    pool_id: "6688aae2724cb038fa348b3cacba7e002b653cc41dda86b750407c93",
    db_ticker: "BUSHI",
    db_name: "Bushido Pool",
    db_url: "https://samurai-pool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25000000000",
    stake_x_deleg: "25000000000",
    saturated: "0.00036366770817483",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LocalNFT",
    icon: "0",
    pool_id: "8427e64bd4d302c3d6f4622d663283159e20e821559a3039328253cd",
    db_ticker: "LOCAL",
    db_name: "LocalNFT",
    db_url: "https://localnft.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "197624030",
    stake_x_deleg: "197624030",
    saturated: "0.0000028747791228149",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.019",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Love_over_Gold",
    icon: "0",
    pool_id: "0a156f7c3a768ecfb1a4c710a26391dd86b78aa959ba4aa0e2c5e47f",
    db_ticker: "VJRAS",
    db_name: "Love_over_Gold",
    db_url: "http://75.119.134.23/index.html",
    roa: "0",
    roa_lifetime: "3.585",
    total_stake: "1005175300",
    stake_x_deleg: "1005175300",
    saturated: "0.000014621991906598",
    luck_lifetime: "74",
    blocks_epoch: "0",
    blocks_lifetime: "193",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EPYC",
    icon: "0",
    pool_id: "07377c78a5d32467d98c0387c97ba92ba8807ad72beb4e8025bf061f",
    db_ticker: "EPYC2",
    db_name: "EPYC",
    db_url: "https://epycpool.com",
    roa: "0",
    roa_lifetime: "2.871",
    total_stake: "2762079823",
    stake_x_deleg: "2762079823",
    saturated: "0.000040179169561053",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.021",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Satoshi's Pool",
    icon: "0",
    pool_id: "80e035936d2b40673796cc4a7118c5f2aa2b6167062f6df8c0900ace",
    db_ticker: "SATS",
    db_name: "Not Satoshi's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2546842214",
    stake_x_deleg: "2546842214",
    saturated: "0.000037048170841931",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeNode.One",
    icon: "0",
    pool_id: "29b4f77575cf0f0bd5507d1773fea1d64a3dee78d215dd57f2acc3d4",
    db_ticker: "SNODE",
    db_name: "StakeNode.One",
    db_url: "https://pool.stakenode.one",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2000000000",
    stake_x_deleg: "2000000000",
    saturated: "0.000029093416653986",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.008",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeZilla2",
    icon: "0",
    pool_id: "548446c24a24a53a14594b102502be1b1362d1e6c5b7435732c359cc",
    db_ticker: "ZILLA",
    db_name: "StakeZilla2",
    db_url: "https://foo.foo.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "24404162",
    stake_x_deleg: "24404162",
    saturated: "3.5500022657869e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "20000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IOADA 2",
    icon: "0",
    pool_id: "5ba20a27f1f3423a334563495607259c0641c0b0cb0e5e44611e7e56",
    db_ticker: "IOADA",
    db_name: "IOADA 2",
    db_url: "https://ioada.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1273621918",
    stake_x_deleg: "1273621918",
    saturated: "0.000018527006560011",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1176000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Potato #01 (Omelette)",
    icon: "0",
    pool_id: "994de52484777c9d63a586c355bc3fc561e4c67cfb6ef4cbfe7d2ab6",
    db_ticker: "POT1",
    db_name: "Cardano Potato #01 (Omelette)",
    db_url: "https://cardanopotato.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3031893987",
    stake_x_deleg: "3031893987",
    saturated: "0.000044104077507253",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0177",
    tax_fix: "340000000",
    pledge: "2500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Econoweb1",
    icon: "0",
    pool_id: "6be2a2ae3aee3dc6d8192da8eaa2b20cce618132bab9490c7badeada",
    db_ticker: "ECWB1",
    db_name: "Econoweb1",
    db_url: "https://econoweb.be",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1684766619",
    stake_x_deleg: "1684766619",
    saturated: "0.000024507808605647",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1254000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BareMetal on Pure Green Energy",
    icon: "0",
    pool_id: "d6e82701b80ff3fb20b185a599add6f0704bda7269b95298b9ebf76f",
    db_ticker: "SOLID",
    db_name: "BareMetal on Pure Green Energy",
    db_url: "https://solidstaking.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502821425",
    stake_x_deleg: "1502821425",
    saturated: "0.000021861104937031",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "aa6870421a52ab642e016125d7925d4e068fdc96c6ab7ce27107fa54",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LBC1M Pool",
    icon: "0",
    pool_id: "5acb298631df3b460a636ecff975400ca7dd9dcffdcc53aa10964ccd",
    db_ticker: "LBC1M",
    db_name: "LBC1M Pool",
    db_url: "http://lbcrypt.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "229776349",
    stake_x_deleg: "229776349",
    saturated: "0.0000033424895293444",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "180000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Publickey",
    icon: "0",
    pool_id: "9f857159422300472655f6a15358b779853c4011f574569f04447df8",
    db_ticker: "PUB",
    db_name: "Publickey",
    db_url: "https://www.publickey.co.jp",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "41645440",
    stake_x_deleg: "41645440",
    saturated: "6.0580406882929e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.035",
    tax_fix: "340000000",
    pledge: "24000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "evoL Humans",
    icon: "0",
    pool_id: "68acab8eea62d38c69d6a4f45dff5d79e583b4f08fc28f4a1d2370d7",
    db_ticker: "EVOL",
    db_name: "evoL Humans",
    db_url: "https://ada.evolhumans.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1000819149",
    stake_x_deleg: "1000819149",
    saturated: "0.000014558624248572",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OG Gamer's Pool",
    icon: "0",
    pool_id: "c05ea2ea5d61a3852b1d7be3979c36183acce8cb77b1ad3df83bbc56",
    db_ticker: "ATARI",
    db_name: "OG Gamer's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547232616",
    stake_x_deleg: "547232616",
    saturated: "0.0000079604332519694",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Hal Finney Tribute Pool",
    icon: "0",
    pool_id: "22267d5aef5031bc52f4f4ff7264bda96f8682b17e39b36dcae2056c",
    db_ticker: "FINN",
    db_name: "The Hal Finney Tribute Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547038515",
    stake_x_deleg: "547038515",
    saturated: "0.0000079576097213364",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rocket2Mars",
    icon: "0",
    pool_id: "cec5b8df6273a894f80053c87305cb4b6c69bdedb5e617f043804bbf",
    db_ticker: "RMARS",
    db_name: "Rocket2Mars",
    db_url: "https://rocket2mars-ada.com",
    roa: "0",
    roa_lifetime: "3.544",
    total_stake: "3990363175",
    stake_x_deleg: "3990363175",
    saturated: "0.000058046649225499",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "63",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kraymerica-WA1",
    icon: "0",
    pool_id: "d95e06a2d3639b971353f3445e26c902e18637a09ba20533a1b854f1",
    db_ticker: "KRAY1",
    db_name: "Kraymerica-WA1",
    db_url: "https://kraymerica.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "490214658",
    stake_x_deleg: "490214658",
    saturated: "0.0000071310096475426",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.015",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BIDA Pool",
    icon: "0",
    pool_id: "eef456d249122590baf0c8c8ea164ffc0337e1f17fa177458ac3120f",
    db_ticker: "BIDA",
    db_name: "BIDA Pool",
    db_url: "https://t.me/BIDAStakingPool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "102232264",
    stake_x_deleg: "102232264",
    saturated: "0.0000014871429260161",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Mountain",
    icon: "0",
    pool_id: "248459dd7f64fe519aba5aac9e25c66c627dbece7c8dd91f41025778",
    db_ticker: "ADM",
    db_name: "ADA Mountain",
    db_url: "https://adamountain.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5009060140",
    stake_x_deleg: "5009060140",
    saturated: "0.000072865336848947",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ArbolAda",
    icon: "0",
    pool_id: "bd66ad684a309ecf52a049f9c7970def81a3a024dae8dfa6a23619f4",
    db_ticker: "ARBOL",
    db_name: "ArbolAda",
    db_url: "https://arboladapool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25098487",
    stake_x_deleg: "25098487",
    saturated: "3.6510036983783e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "footystakepool",
    icon: "0",
    pool_id: "d29e9b1bfcea57060a9f465455cb838a7eee9dc46844023c4dae3ff3",
    db_ticker: "FOOTY",
    db_name: "footystakepool",
    db_url: "https://footystakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18621214",
    stake_x_deleg: "18621214",
    saturated: "2.7087736875252e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mr. Nout",
    icon: "0",
    pool_id: "2f139120da8d853f0a9e44410e66c295e4895aadabd949923213a61c",
    db_ticker: "NOUT2",
    db_name: "Mr. Nout",
    db_url: "www.mrnout-stakepool.com",
    roa: "0",
    roa_lifetime: "3.034",
    total_stake: "3064739",
    stake_x_deleg: "3064739",
    saturated: "4.458186433136e-8",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PoolsBrothers",
    icon: "0",
    pool_id: "56d9cee9f44f9ca440af6d0ef23844224ba965e4a03427604affb4bc",
    db_ticker: "PBJM",
    db_name: "PoolsBrothers",
    db_url: "https://poolsbrothers.de",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507423329",
    stake_x_deleg: "507423329",
    saturated: "0.0000073813391652748",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "One4All",
    icon: "0",
    pool_id: "2c70dbb43dcb719678da00611c861e85617530f6d30a41d578573fc9",
    db_ticker: "14ALL",
    db_name: "One4All",
    db_url: "https://14all.dev",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507449333",
    stake_x_deleg: "507449333",
    saturated: "0.0000073817174378781",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HaxMode Pool",
    icon: "0",
    pool_id: "1df2e2dda1d383c6dd4ab790492d8538cfe55f1d7eab33f107fda32d",
    db_ticker: "HAX",
    db_name: "HaxMode Pool",
    db_url: "https://haxmode.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9975834",
    stake_x_deleg: "9975834",
    saturated: "1.451155475165e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "copenhagenvertigo",
    icon: "0",
    pool_id: "eca423c446d29f3f24837a189e89f67f1b8d19e51b67600d1d370a07",
    db_ticker: "CPHV",
    db_name: "copenhagenvertigo",
    db_url: "www.copenhagenvertigo.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "205618530",
    stake_x_deleg: "205618530",
    saturated: "0.0000029910727825351",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " &amp;#128579;&amp;#128578;&amp;#128578;&amp;#128579; APPY",
    icon: "0",
    pool_id: "c7389400ca7137deb39ce732e1e08eb4000fa8a5e2246c3a9749d1c3",
    db_ticker: "APPY",
    db_name: " &amp;#128579;&amp;#128578;&amp;#128578;&amp;#128579; APPY",
    db_url: "https://appy.network",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2823411",
    stake_x_deleg: "2823411",
    saturated: "4.1071336304224e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0199",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "We are Cardano",
    icon: "0",
    pool_id: "5043304bc71793312dde1b90a435f9788238ec054423741b2f9e937b",
    db_ticker: "ADA01",
    db_name: "We are Cardano",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4823411",
    stake_x_deleg: "4823411",
    saturated: "7.016475295821e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "25000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WINPool",
    icon: "0",
    pool_id: "eb078a3e9c17db155e43f1c261f4f25eccc774cd5acc0508d0967a37",
    db_ticker: "WPool",
    db_name: "WINPool",
    db_url: "http://www.adawinpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "274557374",
    stake_x_deleg: "274557374",
    saturated: "0.0000039939060386031",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Siberia",
    icon: "https://cdn.adapools.org/pool_logo/1416ab45d3dac1a2b002dbcfa0177e679b38a030e15283114cec32ed.png",
    pool_id: "1416ab45d3dac1a2b002dbcfa0177e679b38a030e15283114cec32ed",
    db_ticker: "SBR",
    db_name: "Siberia",
    db_url: "https://siberiapool.github.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1097230020",
    stake_x_deleg: "1097230020",
    saturated: "0.000015961085068561",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ECOM Pool",
    icon: "0",
    pool_id: "f12a14524711c56fec3fcfd5f8ffcfa08de7e9d1d8fb85657383f98c",
    db_ticker: "ECOM🗲",
    db_name: "ECOM Pool",
    db_url: "https://pool.ecom.com.ar",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1035664597",
    stake_x_deleg: "1035664597",
    saturated: "0.000015065510817152",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Norther Lights Staking",
    icon: "0",
    pool_id: "cecbcac12ff8ac2af77b5e37db137c9852fffab5a74978e7c90b0647",
    db_ticker: "NLAP",
    db_name: "Norther Lights Staking",
    db_url: "https://gist.github.com/urbal999",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2046640231",
    stake_x_deleg: "2046640231",
    saturated: "0.000029771878490647",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.00575",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Europa Independent Pool",
    icon: "0",
    pool_id: "c5560c69f4f5528c100d2522c232ade42419284d2a07bd53ac9eb02f",
    db_ticker: "EIP",
    db_name: "Europa Independent Pool",
    db_url: "https://cardano.kleap.co/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3949541197",
    stake_x_deleg: "3949541197",
    saturated: "0.000057452823818202",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1900000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CryptoBetsExclusive",
    icon: "0",
    pool_id: "d91cf8bea6f0142e595508b6950dcec3bf7285450ce4aa7842961a59",
    db_ticker: "CBE",
    db_name: "CryptoBetsExclusive",
    db_url: "https://www.cryptobetsexclusive.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "340417081",
    stake_x_deleg: "340417081",
    saturated: "0.0000049519479868334",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "400000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Bots for Defi liquidation",
    icon: "0",
    pool_id: "73fc28b33a3068a689b14d91a1e47938024f0a03ad9d2d85d1a08269",
    db_ticker: "FIBOT",
    db_name: "Bots for Defi liquidation",
    db_url: "http://ada.wefin.vn",
    roa: "3.333",
    roa_lifetime: "1.66",
    total_stake: "980704794",
    stake_x_deleg: "980704794",
    saturated: "0.000014266026593202",
    luck_lifetime: "72",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DxbPool",
    icon: "0",
    pool_id: "cb55c84a0e4dd574919d5bb8ef9685251154d93a8f0cbeb71cc89ff5",
    db_ticker: "DXB",
    db_name: "DxbPool",
    db_url: "https://dxbpool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1112619894",
    stake_x_deleg: "1112619894",
    saturated: "0.000016184957076828",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Esquire Stake Pool",
    icon: "0",
    pool_id: "7e02278b2102a37debe3b1d889c1aeffbcaff6002e3bdced8e2dae5b",
    db_ticker: "ESQ",
    db_name: "Esquire Stake Pool",
    db_url: "https://EsquireAutos.ca",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2196838386",
    stake_x_deleg: "2196838386",
    saturated: "0.000031956767242684",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CZ Lukas O.",
    icon: "0",
    pool_id: "8dfc27a29adbfab072c20b567ecc6d22c245d3c6df0eca31871f619a",
    db_ticker: "CZLO",
    db_name: "CZ Lukas O.",
    db_url: "https://l.ondrac.cz/adapool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "759102736",
    stake_x_deleg: "759102736",
    saturated: "0.000011042446090814",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "512000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "EMPORIUM",
    icon: "0",
    pool_id: "d0d16de348779cff71a27a04be0aa2636bd1cdd8b477b685e8f5c0d9",
    db_ticker: "EMPR",
    db_name: "EMPORIUM",
    db_url: "https://emp0rium.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507929099",
    stake_x_deleg: "507929099",
    saturated: "0.0000073886964539454",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BATTLEWITS",
    icon: "0",
    pool_id: "1ac7a9cbb5bdcf23dec3de599bbbcf7945d8a78a6730c5787fd01a3d",
    db_ticker: "BWITS",
    db_name: "BATTLEWITS",
    db_url: "https://www.battlewits.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507617122",
    stake_x_deleg: "507617122",
    saturated: "0.0000073841582155216",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "350000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not MTV's Pool",
    icon: "0",
    pool_id: "f687f4625ae3abd2bf4a49bb5e04f669773cd8cb80954d1d2a32eb99",
    db_ticker: "MTV",
    db_name: "Not MTV's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547040627",
    stake_x_deleg: "547040627",
    saturated: "0.0000079576404439844",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Axie Infinity's Pool",
    icon: "0",
    pool_id: "7381d7472d9eb552348e5cdbf4bf46f0a53963c8ad3eb8a41dc9ff30",
    db_ticker: "AXIES",
    db_name: "Not Axie Infinity's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547040363",
    stake_x_deleg: "547040363",
    saturated: "0.0000079576366036534",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Doge You Go Swimming In This Pool",
    icon: "0",
    pool_id: "1e3adabdf86819c05cf029aa335ade679467d4338db9875495e3a3a3",
    db_ticker: "DOGE",
    db_name: "Doge You Go Swimming In This Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547039571",
    stake_x_deleg: "547039571",
    saturated: "0.0000079576250826604",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Money Printing Pool",
    icon: "0",
    pool_id: "b8d37b3ff76542cfe64c3c89abf34a6f8b1536269214bfae99d238f9",
    db_ticker: "FED",
    db_name: "The Money Printing Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547038031",
    stake_x_deleg: "547038031",
    saturated: "0.0000079576026807296",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Cosimo de Medici's Pool",
    icon: "0",
    pool_id: "3bc7e7e807aa937117f7655a18afc3c71ab59415326d56cd64d4a9e4",
    db_ticker: "SNOOP",
    db_name: "Not Cosimo de Medici's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546842742",
    stake_x_deleg: "546842742",
    saturated: "0.0000079547618686071",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not The GOAT's Pool",
    icon: "0",
    pool_id: "a837f1c88edd7c1e3b89a57e1c9ef919faee56f07732d2494146d33d",
    db_ticker: "JUMP",
    db_name: "Not The GOAT's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547039175",
    stake_x_deleg: "547039175",
    saturated: "0.0000079576193221639",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rat Poison Squared Pool",
    icon: "0",
    pool_id: "6c93d49fbef2f24adec2039a06a83ae94d8c4125ef44f07c6850f488",
    db_ticker: "BUFF",
    db_name: "Rat Poison Squared Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546842918",
    stake_x_deleg: "546842918",
    saturated: "0.0000079547644288278",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "This Pool Doesn't Leak",
    icon: "0",
    pool_id: "15d9a3670238083dca759e80dee207ee68b118d67cd58486f53818a2",
    db_ticker: "WIKI",
    db_name: "This Pool Doesn't Leak",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546842742",
    stake_x_deleg: "546842742",
    saturated: "0.0000079547618686071",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Amazon's Pool",
    icon: "0",
    pool_id: "10e18fee68dd18ce97d063c6429f6d1f1aeddd98baa07bad4b59c624",
    db_ticker: "BEZOS",
    db_name: "Not Amazon's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546842742",
    stake_x_deleg: "546842742",
    saturated: "0.0000079547618686071",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Marky Mark's Meta Pool",
    icon: "0",
    pool_id: "66aa03368960c1a20ae7aca70def839ed6a32939de279af447777786",
    db_ticker: "ZUCK",
    db_name: "Not Marky Mark's Meta Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546452340",
    stake_x_deleg: "546452340",
    saturated: "0.0000079490828045828",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Bill's Pearly Pool",
    icon: "0",
    pool_id: "39a751d32ec5c7ac68b6c0ee68230f8e286d853b692b4e9273a3401d",
    db_ticker: "GATES",
    db_name: "Not Bill's Pearly Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547039043",
    stake_x_deleg: "547039043",
    saturated: "0.0000079576174019984",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Loveliest Pool",
    icon: "0",
    pool_id: "f53c76b198e44ec385183d239e187b5a265394ad01f3df580740faea",
    db_ticker: "IVANA",
    db_name: "The Loveliest Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547038383",
    stake_x_deleg: "547038383",
    saturated: "0.0000079576078011709",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not PlanB's Pool",
    icon: "0",
    pool_id: "92b24e82dcd581753a3e8fb56611133d763a77c033c2892d98b744f4",
    db_ticker: "PLANB",
    db_name: "Not PlanB's Pool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547231736",
    stake_x_deleg: "547231736",
    saturated: "0.000007960420450866",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not Hefner's Grotto",
    icon: "0",
    pool_id: "ad89fef719f94f9454de79af3cddfb728189de118f52cde6070a884f",
    db_ticker: "PLAY",
    db_name: "Not Hefner's Grotto",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547036403",
    stake_x_deleg: "547036403",
    saturated: "0.0000079575789986884",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Not The Treasury's Pool",
    icon: "0",
    pool_id: "c8091a25943321a2cd223ff54436b7514464fcfc5990951941bc0c74",
    db_ticker: "YIELD",
    db_name: "Not The Treasury's Pool",
    db_url: "https://memepools.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "546841862",
    stake_x_deleg: "546841862",
    saturated: "0.0000079547490675038",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Matryoshka pool",
    icon: "0",
    pool_id: "4ebeb6c46d8e745b7c7150de677af42ff1ed7ef82ffcfa36277fe5ed",
    db_ticker: "MTSK",
    db_name: "Matryoshka pool",
    db_url: "https://mtsk.link",
    roa: "0",
    roa_lifetime: "2.494",
    total_stake: "2231681098",
    stake_x_deleg: "2231681098",
    saturated: "0.000032463614011469",
    luck_lifetime: "132",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sigma Stake",
    icon: "0",
    pool_id: "8f1a90ce93850a4aa28273e31e52e71bb304a86f9cbb54b138808cf0",
    db_ticker: "SIGMA",
    db_name: "Sigma Stake",
    db_url: "https://sigmastake.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5002455449",
    stake_x_deleg: "5002455449",
    saturated: "0.00007276926033538",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "North West Wind",
    icon: "0",
    pool_id: "7602450c48c691331f79bee98e355498c5c6fe83077dba8d9545d2d7",
    db_ticker: "NWW",
    db_name: "North West Wind",
    db_url: "https://www.northwestwind.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5044360546",
    stake_x_deleg: "5044360546",
    saturated: "0.000073378841558853",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sark Pool",
    icon: "0",
    pool_id: "e9c7b7cad4c4e4dbfc4504e926c02b5068ee84a98955e51cbf778714",
    db_ticker: "sark",
    db_name: "Sark Pool",
    db_url: "https://www.sarkpool.co.uk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "106134817",
    stake_x_deleg: "106134817",
    saturated: "0.0000015439122262378",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mercury",
    icon: "0",
    pool_id: "ccfe3b2bf890e443a0ad45fb8a2f81418b5c322133f7088639f6c1d0",
    db_ticker: "MERC",
    db_name: "Mercury",
    db_url: "https://www.mercurypool.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2076095187",
    stake_x_deleg: "2076095187",
    saturated: "0.000030200351144363",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPICA Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/348a34cacab673e724f4b3507a83e8dcc55580ea4dcbac8a292ff458.png",
    pool_id: "348a34cacab673e724f4b3507a83e8dcc55580ea4dcbac8a292ff458",
    db_ticker: "SPICA",
    db_name: "SPICA Stake Pool",
    db_url: "https://twitter.com/macne_y",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "122227952",
    stake_x_deleg: "122227952",
    saturated: "0.0000017780143671497",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano ERP",
    icon: "https://cdn.adapools.org/pool_logo/cd596bbfcb5cc46005fa340eb1046b40d1c49aeaadf03f6b700fbec1.png",
    pool_id: "cd596bbfcb5cc46005fa340eb1046b40d1c49aeaadf03f6b700fbec1",
    db_ticker: "CERP",
    db_name: "Cardano ERP",
    db_url: "https://cerp.dev",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1008478954",
    stake_x_deleg: "1008478954",
    saturated: "0.000014670049197749",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "f483240a6096421f885504a9ccaf932fa9edf41252369cf35127e4d3",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "b41db3f69d7d30dd5600f6766e0fb4ba56969faf96ef3bbff1bfe08e",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AdaYield Stake Pool",
    icon: "0",
    pool_id: "db49cc2df6435f7c4a53cf4a52ef3469801c847521dbcac379427716",
    db_ticker: "AY1",
    db_name: "AdaYield Stake Pool",
    db_url: "https://adayield.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "112621126",
    stake_x_deleg: "112621126",
    saturated: "0.0000016382666713795",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cairnz",
    icon: "0",
    pool_id: "634bdd01f2b4fa3bb92447b70087e3440c938a13ef8921fb031ac0d7",
    db_ticker: "PATH",
    db_name: "Cairnz",
    db_url: "https://cairnz.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "502624954",
    stake_x_deleg: "502624954",
    saturated: "0.0000073115386037063",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "125000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Pool - Retired",
    icon: "0",
    pool_id: "d1b58b2754899bcd0db7d999c8e68f47db715b431b4ad81f56870be9",
    db_ticker: "DEAD",
    db_name: "Pool - Retired",
    db_url: "https://www.dead.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Phoenix Stake Pool",
    icon: "0",
    pool_id: "aae9be46187fb2555b595840a3256e91142890e8301ee991581b7160",
    db_ticker: "PHNIX",
    db_name: "Phoenix Stake Pool",
    db_url: "https://www.phoenixstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "509144844",
    stake_x_deleg: "509144844",
    saturated: "0.0000074063815418604",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.001",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "테스트",
    icon: "0",
    pool_id: "330c9583aa63560a7ee8c59b6a7ca818775dddd468e4fbc51397c02b",
    db_ticker: "KOREA",
    db_name: "테스트",
    db_url: "https://koreapool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "17626846",
    stake_x_deleg: "17626846",
    saturated: "2.5641258748682e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PoolOfFore-",
    icon: "0",
    pool_id: "9211f9b970fe749ab590741b4daeedc04983c8c5854b95aa0869580b",
    db_ticker: "FORE",
    db_name: "PoolOfFore-",
    db_url: "https://www.fore-co.ltd/index.php",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "252998560",
    stake_x_deleg: "252998560",
    saturated: "0.0000036802962594692",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Komani Stake Pool",
    icon: "0",
    pool_id: "4f3586381dd8d26b0b0f0ca1b6fb60e8920bd287862d9070128e2cc4",
    db_ticker: "KOMA",
    db_name: "Komani Stake Pool",
    db_url: "https://komanipool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "300093772",
    stake_x_deleg: "300093772",
    saturated: "0.0000043653765720311",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "111000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LeXeN",
    icon: "0",
    pool_id: "af6d23bd22a89420993f6aa4e609b9fb30bc5a8ae758455a4c7fcaaa",
    db_ticker: "LXN",
    db_name: "LeXeN",
    db_url: "https://lexen.be",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "307620906",
    stake_x_deleg: "307620906",
    saturated: "0.0000044748715948673",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.005",
    tax_fix: "340000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PREEB",
    icon: "0",
    pool_id: "2873902b027727676630f3c9c63830183049b7e09998bd7b90114e13",
    db_ticker: "PREEB",
    db_name: "PREEB",
    db_url: "https://preeb.cloud/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1589138684",
    stake_x_deleg: "1589138684",
    saturated: "0.000023116736927289",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.0299",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Monster Stake Pool",
    icon: "0",
    pool_id: "621383a41cf96374e933f966a8e050821da31006512537fcc0de36c6",
    db_ticker: "MSP",
    db_name: "Monster Stake Pool",
    db_url: "https://monsterstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1487914206",
    stake_x_deleg: "1487914206",
    saturated: "0.000021644253970271",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Novertec Pool",
    icon: "0",
    pool_id: "9bde5d328df6baf1ff9467e775031e13bc4f8392f203ea364b9724e0",
    db_ticker: "MSS69",
    db_name: "Novertec Pool",
    db_url: "www.novertec.es",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "406839085",
    stake_x_deleg: "406839085",
    saturated: "0.0000059181695055157",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MAKOTO Bangkok Community Pool",
    icon: "0",
    pool_id: "71b90e50c2688f7dca03ad1fc1c6e368f3764526ddcc10a07d524230",
    db_ticker: "MBK",
    db_name: "MAKOTO Bangkok Community Pool",
    db_url: "https://mbk.world",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "30061225664",
    stake_x_deleg: "30061225664",
    saturated: "0.00043729188168612",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "8899e3b3c151d3c5a761cec15f007c02b8e8e8e2e6a69a03efbfb817",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stakinghouse",
    icon: "0",
    pool_id: "349e372e5c9b2c11e2c49e7d623072ebf959dc27f5e99ea37f554a61",
    db_ticker: "S10E",
    db_name: "Stakinghouse",
    db_url: "https://stakinghouse.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2485182272",
    stake_x_deleg: "2485182272",
    saturated: "0.000036151221650198",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.018",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CEX.IO Validator",
    icon: "0",
    pool_id: "596a06cadce501b584dcfc8738324dc2d097c07a62791ae410c897d9",
    db_ticker: "CEX1",
    db_name: "CEX.IO Validator",
    db_url: "https://cex.io/validator",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1039279388",
    stake_x_deleg: "1039279388",
    saturated: "0.000015118094127492",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CEX.IO Validator",
    icon: "0",
    pool_id: "3c81cdc939611c4c4a54aef4a47aa8bb615def8401261726ddcede6b",
    db_ticker: "CEX2",
    db_name: "CEX.IO Validator",
    db_url: "https://cex.io/validator",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1004622006",
    stake_x_deleg: "false",
    saturated: "0.000014613943300161",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TCNet1",
    icon: "0",
    pool_id: "351ee2911372b304573b259da97dc263f024f5743cc8b39c228f7f7e",
    db_ticker: "TCNT1",
    db_name: "TCNet1",
    db_url: "https://poolmeta.tcrs.co.jp/",
    roa: "1.77",
    roa_lifetime: "1.849",
    total_stake: "1006614983784",
    stake_x_deleg: "335538327928",
    saturated: "0.014642934566687",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "14",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aerea Network",
    icon: "0",
    pool_id: "52916fa2da464316ee881f50fb758211b0eb56c630c194248845e414",
    db_ticker: "AEREA",
    db_name: "Aerea Network",
    db_url: "https://aerea.network/",
    roa: "0",
    roa_lifetime: "1.989",
    total_stake: "5866027063",
    stake_x_deleg: "5866027063",
    saturated: "0.000085331384723708",
    luck_lifetime: "82",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LATAM",
    icon: "0",
    pool_id: "53f1bf9a204bc868885157cd862b3e3abe0ac48a515eb168a31f9253",
    db_ticker: "latam",
    db_name: "LATAM",
    db_url: "https://cardano.org/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10000000",
    stake_x_deleg: "10000000",
    saturated: "1.4546708326993e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Pool of SPAIN",
    icon: "0",
    pool_id: "217fa9844ec04caf91122fe39ef546269e321a29a3987d3c9cf67700",
    db_ticker: "SPAIN",
    db_name: "The Pool of SPAIN",
    db_url: "https://storage.googleapis.com/spain-pool/index.html",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1038303343",
    stake_x_deleg: "1038303343",
    saturated: "0.000015103895885563",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.04",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "tk-gems",
    icon: "0",
    pool_id: "5e35823f00b4e1ba970c809fbb43b24b3fbbc58cc04f342f70093083",
    db_ticker: "TGEM",
    db_name: "tk-gems",
    db_url: "https://twitter.com/tk_gems",
    roa: "4.11",
    roa_lifetime: "3.177",
    total_stake: "1691877549458",
    stake_x_deleg: "563959183152.67",
    saturated: "0.024611249236953",
    luck_lifetime: "97",
    blocks_epoch: "1",
    blocks_lifetime: "77",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakepool for kanayama",
    icon: "https://cdn.adapools.org/pool_logo/ef9123943836fd7d47f98de569e7a10ca3ba5b80ca2ab3a7b41ce8dd.png",
    pool_id: "ef9123943836fd7d47f98de569e7a10ca3ba5b80ca2ab3a7b41ce8dd",
    db_ticker: "KNY11",
    db_name: "stakepool for kanayama",
    db_url: "https://kanayama11.xyz/",
    roa: "3.71",
    roa_lifetime: "4.11",
    total_stake: "11474738812933",
    stake_x_deleg: "637485489607.39",
    saturated: "0.16691967864016",
    luck_lifetime: "100",
    blocks_epoch: "11",
    blocks_lifetime: "751",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kaze-Wa-Minamikara",
    icon: "0",
    pool_id: "c46c687b40950ea3d52554706f65a763954cce334a2e4e0d8f1176a4",
    db_ticker: "NAHA",
    db_name: "Kaze-Wa-Minamikara",
    db_url: "https://twitter.com/kazewa_minami",
    roa: "3.879",
    roa_lifetime: "3.698",
    total_stake: "16115361717215",
    stake_x_deleg: "619821604508.27",
    saturated: "0.23442546648432",
    luck_lifetime: "98",
    blocks_epoch: "9",
    blocks_lifetime: "265",
    tax_ratio: "0.045",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Berry Capital",
    icon: "0",
    pool_id: "d505cad035397e1e102baa61cc5107f9505bf6ad6b7ff81eb2fc031b",
    db_ticker: "SWEET",
    db_name: "Berry Capital",
    db_url: "https://twitter.com/Berry__Capital",
    roa: "5.345",
    roa_lifetime: "3.836",
    total_stake: "2991434393088",
    stake_x_deleg: "747858598272",
    saturated: "0.043515523595586",
    luck_lifetime: "116",
    blocks_epoch: "4",
    blocks_lifetime: "51",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Pool of India",
    icon: "https://cdn.adapools.org/pool_logo/9df5a0e7e565eae1bafaeeebdffc36b39badac8cd74b175e1ec6e1cb.png",
    pool_id: "9df5a0e7e565eae1bafaeeebdffc36b39badac8cd74b175e1ec6e1cb",
    db_ticker: "India",
    db_name: "The Pool of India",
    db_url: "https://storage.googleapis.com/india-pool/index.html",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1048303343",
    stake_x_deleg: "1048303343",
    saturated: "0.000015249362968833",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.045",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ATARASHIIMONO Stake Pool",
    icon: "0",
    pool_id: "0a37c1f76e2bbae2e2c683026de2650e92da9bf496face1e52cbcd46",
    db_ticker: "ATRMN",
    db_name: "ATARASHIIMONO Stake Pool",
    db_url: "https://twitter.com/Rd7hrujn2Xv5FS2",
    roa: "3.064",
    roa_lifetime: "3.514",
    total_stake: "6025104636192",
    stake_x_deleg: "753138079524",
    saturated: "0.087645439782298",
    luck_lifetime: "98",
    blocks_epoch: "4",
    blocks_lifetime: "111",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Art Bank",
    icon: "0",
    pool_id: "d19db3e7e7b4c7b3673e6f8ae92cbf46eb4c13897d28363e0fdc1f44",
    db_ticker: "TAB",
    db_name: "The Art Bank",
    db_url: "https://theartbank.io",
    roa: "3.237",
    roa_lifetime: "3.682",
    total_stake: "4265433039695",
    stake_x_deleg: "118484251102.64",
    saturated: "0.062048010316762",
    luck_lifetime: "107",
    blocks_epoch: "1",
    blocks_lifetime: "137",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IBEX2 ADA Pool",
    icon: "0",
    pool_id: "093281f3d46eaa6422b2b9e257d67059d303805adb166073ab656a3b",
    db_ticker: "IBEX2",
    db_name: "IBEX2 ADA Pool",
    db_url: "https://ada.ibexpool.com",
    roa: "5.083",
    roa_lifetime: "4.282",
    total_stake: "12004197949541",
    stake_x_deleg: "666899886085.61",
    saturated: "0.17462156627146",
    luck_lifetime: "100",
    blocks_epoch: "11",
    blocks_lifetime: "917",
    tax_ratio: "0.024",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BerryCapital",
    icon: "0",
    pool_id: "bf9fe7384d2f62efc04a329ae9a1e39f9990eb6841be8738bcf405d4",
    db_ticker: "SOUR",
    db_name: "BerryCapital",
    db_url: "https://twitter.com/Berry___Capital",
    roa: "1.238",
    roa_lifetime: "1.801",
    total_stake: "3031433055938",
    stake_x_deleg: "757858263984.5",
    saturated: "0.044097372477535",
    luck_lifetime: "66",
    blocks_epoch: "4",
    blocks_lifetime: "20",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UAE Stake Pool",
    icon: "0",
    pool_id: "ce85194e6ce144037eda7dec99136a64a3b6209026ae7fa8c624a925",
    db_ticker: "UAE",
    db_name: "UAE Stake Pool",
    db_url: "https://thecooltank.com/mining-pool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5009428097",
    stake_x_deleg: "5009428097",
    saturated: "0.000072870689412103",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "f7ae59a074c909f1d87389435934124ce5fecf15660839d23ad7fd81",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "AMRR Pool 1",
    icon: "0",
    pool_id: "6081c07758fa52e8651d4b08d83c56e8032c39f7e0c11ebcd83f439d",
    db_ticker: "AMRR1",
    db_name: "AMRR Pool 1",
    db_url: "https://amrr.investments",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2010436661",
    stake_x_deleg: "2010436661",
    saturated: "0.000029245235717461",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tangible Onyx Tesla",
    icon: "0",
    pool_id: "3a6ada5a5a9c726f33e5e35d1060498f4997831ce41087aecd976900",
    db_ticker: "TOT",
    db_name: "Tangible Onyx Tesla",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "06b79f3a9d8db8ebdda62113f3b09d7221820278e35c7d74309316cb",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "ac5ba151fae6889e25021acc723b0e34655828bf3328b6f1180e1d65",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "accd28c048ea968ffdd99c91d927f125574f3c88bdc968e9aabe7806",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "7d45120fc9e82f8b69d886057d80630b721e8f033cb8e3f26621d7bd",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Garlic Knot Pool",
    icon: "0",
    pool_id: "a922e197f601328388e177023dc9ddb8de46f1e1940fc811482c77c4",
    db_ticker: "GAR",
    db_name: "Garlic Knot Pool",
    db_url: "https://garlicknot.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "162110596",
    stake_x_deleg: "162110596",
    saturated: "0.000002358175556727",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BASHO",
    icon: "0",
    pool_id: "ec128b017b97390c7d06f28c11302e8c748b73f8bae0b818bf848bc0",
    db_ticker: "BASHO",
    db_name: "BASHO",
    db_url: "https://basho.cardanoada.biz",
    roa: "4.187",
    roa_lifetime: "3.768",
    total_stake: "29760212753201",
    stake_x_deleg: "708576494123.83",
    saturated: "0.43291313467007",
    luck_lifetime: "91",
    blocks_epoch: "20",
    blocks_lifetime: "1366",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardanode: 0.00% Fee &amp; 100% Rewards Guaranteed",
    icon: "https://cdn.adapools.org/pool_logo/0775f0139af22e1ebf2c3278598816946e750029b70eace3c5135c3e.png",
    pool_id: "0775f0139af22e1ebf2c3278598816946e750029b70eace3c5135c3e",
    db_ticker: "CNODE",
    db_name: "Cardanode: 0.00% Fee &amp; 100% Rewards Guaranteed",
    db_url: "https://www.cardanode.io",
    roa: "4.455",
    roa_lifetime: "4.504",
    total_stake: "67934686567275",
    stake_x_deleg: "12641363335.928",
    saturated: "0.98822607077984",
    luck_lifetime: "99",
    blocks_epoch: "41",
    blocks_lifetime: "3951",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "500000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ITVBPool",
    icon: "0",
    pool_id: "9390bd2c8d16935b3bdfeaae6301c203f67806511e492e3cf3dbfc69",
    db_ticker: "ITVB",
    db_name: "ITVBPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "4.668",
    total_stake: "9551618",
    stake_x_deleg: "2387904.5",
    saturated: "1.3894460109686e-7",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "816",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "6f5a984e5de0ae8c3054bd87cfbb2c0e4bbb2ba51c0cb5543d7609e1",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "111061670",
    stake_x_deleg: "55530835",
    saturated: "0.0000016155817197987",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "17c3e5b58bc2965396f311a199deb6f556baa1c143def86d2579e52d",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "103000000",
    stake_x_deleg: "51500000",
    saturated: "0.0000014983109576803",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "8b605f403472d21e78de53c323d63d32b1748f12551a73f625f9f029",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "103000000",
    stake_x_deleg: "51500000",
    saturated: "0.0000014983109576803",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "01822fd8c9cede0545f7904a9d2958d13b991c04c3891acdc4594fd3",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "103000000",
    stake_x_deleg: "51500000",
    saturated: "0.0000014983109576803",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "49866eaf9f565a45618482a4aa63a982d84824ef973ad1c7bea55414",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1003924403",
    stake_x_deleg: "501962201.5",
    saturated: "0.000014603795472792",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "88295d7c14fedc5084b84b9db3523cbd490ec88a3f0246a2933af06a",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0.85",
    roa_lifetime: "0.848",
    total_stake: "813249081356",
    stake_x_deleg: "271083027118.67",
    saturated: "0.011830097183681",
    luck_lifetime: "48",
    blocks_epoch: "1",
    blocks_lifetime: "3",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XWIN ADA STAKE POOL",
    icon: "0",
    pool_id: "eda2cbad9eefd992b932a58d4341c35a584a755159b9c0a09a9802de",
    db_ticker: "XWIN",
    db_name: "XWIN ADA STAKE POOL",
    db_url: "https://xwin.com.my",
    roa: "3.816",
    roa_lifetime: "3.622",
    total_stake: "8170542727913",
    stake_x_deleg: "907838080879.22",
    saturated: "0.11885450193618",
    luck_lifetime: "97",
    blocks_epoch: "7",
    blocks_lifetime: "220",
    tax_ratio: "0.02",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IBEX ADA Pool",
    icon: "0",
    pool_id: "d31f204c4a1701068d3a116b9c50662b60f365178336d6f7825ea547",
    db_ticker: "IBEX",
    db_name: "IBEX ADA Pool",
    db_url: "https://ada.ibexpool.com",
    roa: "3.689",
    roa_lifetime: "4.296",
    total_stake: "16003498644260",
    stake_x_deleg: "842289402329.47",
    saturated: "0.23279822698948",
    luck_lifetime: "100",
    blocks_epoch: "12",
    blocks_lifetime: "1048",
    tax_ratio: "0.024",
    tax_fix: "340000000",
    pledge: "10000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OWJUPool",
    icon: "0",
    pool_id: "4ae0fed71f173ce25da0754f80a1567320b1640d6add28d4264b8b24",
    db_ticker: "OWJU",
    db_name: "OWJUPool",
    db_url: "",
    roa: "2.876",
    roa_lifetime: "4.023",
    total_stake: "3190093466",
    stake_x_deleg: "1595046733",
    saturated: "0.000046405359185748",
    luck_lifetime: "96",
    blocks_epoch: "9",
    blocks_lifetime: "1798",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RFJIPool",
    icon: "0",
    pool_id: "eff96bfccda465d5be2c42e97ab4c6013bd08b29bd8a41feafcd4713",
    db_ticker: "RFJI",
    db_name: "RFJIPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "3.975",
    total_stake: "6169669",
    stake_x_deleg: "3084834.5",
    saturated: "8.9748375417091e-8",
    luck_lifetime: "90",
    blocks_epoch: "0",
    blocks_lifetime: "989",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 43",
    icon: "0",
    pool_id: "21ad7ca84128f182623df17e6cb29ca1e83889e0013f1004cf5beb73",
    db_ticker: "BNP",
    db_name: "Binance Staking - 43",
    db_url: "https://www.binance.com",
    roa: "3.617",
    roa_lifetime: "4.418",
    total_stake: "13854523153755",
    stake_x_deleg: "577271798073.12",
    saturated: "0.20153770732725",
    luck_lifetime: "103",
    blocks_epoch: "12",
    blocks_lifetime: "1407",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - 2",
    icon: "0",
    pool_id: "2b4cc7c209a3e3e5aaf05bd5d8af3dc2fafccdcb37a5a1980959af48",
    db_ticker: "IOG2",
    db_name: "Input Output Global (IOHK) - 2",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "507203829",
    stake_x_deleg: "507203829",
    saturated: "0.000007378146162797",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.07",
    tax_fix: "340000000",
    pledge: "5000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gold Staking Pool",
    icon: "0",
    pool_id: "df27b852b9ea0fcf9de2d95011ca5dc5c8ddbf26a299248a03c49c1c",
    db_ticker: "GOLD",
    db_name: "Gold Staking Pool",
    db_url: "",
    roa: "3.799",
    roa_lifetime: "3.754",
    total_stake: "16937303173611",
    stake_x_deleg: "1881922574845.7",
    saturated: "0.24638200911237",
    luck_lifetime: "105",
    blocks_epoch: "15",
    blocks_lifetime: "876",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OccamFi",
    icon: "0",
    pool_id: "7ff3e1bbe944565d5748dbcb20be80452c060228671750289dcf15c9",
    db_ticker: "pOCC2",
    db_name: "OccamFi",
    db_url: "https://occam-x.fi",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25312872446",
    stake_x_deleg: "816544272.45161",
    saturated: "0.00036821897239034",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.099",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "InfStones",
    icon: "0",
    pool_id: "65b0f6b38938cc3c5cfee6d3d2770847c773a4e7ca5e9d54c36f334e",
    db_ticker: "IFS1",
    db_name: "InfStones",
    db_url: "https://infstones.com/",
    roa: "3.103",
    roa_lifetime: "3.975",
    total_stake: "1009457258050",
    stake_x_deleg: "2900739247.2701",
    saturated: "0.014684280301419",
    luck_lifetime: "86",
    blocks_epoch: "1",
    blocks_lifetime: "676",
    tax_ratio: "0.1",
    tax_fix: "345000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Sagittarius Pool",
    icon: "0",
    pool_id: "fe6147876ad38293a09bf61bdaf9e64260ee5857212ccb91304feadc",
    db_ticker: "SGTRS",
    db_name: "Sagittarius Pool",
    db_url: "https://twitter.com/sagittarius_stc",
    roa: "5.695",
    roa_lifetime: "4.355",
    total_stake: "5088559407253",
    stake_x_deleg: "1272139851813.2",
    saturated: "0.074021789501886",
    luck_lifetime: "111",
    blocks_epoch: "6",
    blocks_lifetime: "320",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "b2817f8c6ecf0e9ce1b5b9ab79a95f63df31b623934d2d3783e9bdbf",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "3.704",
    roa_lifetime: "3.784",
    total_stake: "16825532167905",
    stake_x_deleg: "10228287032.161",
    saturated: "0.24475610889295",
    luck_lifetime: "107",
    blocks_epoch: "16",
    blocks_lifetime: "156",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "anetaBTC - first LISO Pool ",
    icon: "0",
    pool_id: "2065c73cf62e8dbcbd3d40ea4052a9af0c3c97b1f7622f7fb27b87ca",
    db_ticker: "NETA",
    db_name: "anetaBTC - first LISO Pool ",
    db_url: "https://www.anetabtc.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23347182630",
    stake_x_deleg: "2334718263",
    saturated: "0.00033962465597565",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.099",
    tax_fix: "340000000",
    pledge: "88000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "VSETPool",
    icon: "0",
    pool_id: "b1b2d76b11afa2fbc6b5f89f078b47a9defa00707975f3fd4ebe1df2",
    db_ticker: "VSET",
    db_name: "VSETPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2609445",
    stake_x_deleg: "2609445",
    saturated: "3.795883531033e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "b2d05bb3b93dbfc74c6a2dafbec7666f929948b6a7de49341fdf452c",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2618354",
    stake_x_deleg: "2618354",
    saturated: "3.8088431934815e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PASHA",
    icon: "0",
    pool_id: "ff9477b81985600c917596474775a4bdf9aa6a7d935deae017f89052",
    db_ticker: "PASHA",
    db_name: "PASHA",
    db_url: "http://orkastakepool.com/pasha",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1664173",
    stake_x_deleg: "1664173",
    saturated: "2.4208239236657e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MHGLPool",
    icon: "0",
    pool_id: "27ef7af15a7b854508385bf193acd73f7fb78b72ea6a99e8a413ca68",
    db_ticker: "MHGL",
    db_name: "MHGLPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RFTYPool",
    icon: "0",
    pool_id: "6c1966b54b8ba738ec7b5780fa7942fa851d7ece8e3eac26a27e1556",
    db_ticker: "RFTY",
    db_name: "RFTYPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "1.589",
    total_stake: "373760410",
    stake_x_deleg: "373760410",
    saturated: "0.0000054369836684473",
    luck_lifetime: "85",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ARDFPool",
    icon: "0",
    pool_id: "51897956cbe5c8f4751953c31ce85a1c60f5f27efee16397e6654947",
    db_ticker: "ARDF",
    db_name: "ARDFPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OUYGPool",
    icon: "0",
    pool_id: "37776026d64eeb9fb5873caedc6e9c140f66c43ef6b3e396e061c891",
    db_ticker: "OUYG",
    db_name: "OUYGPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WDHBPool",
    icon: "0",
    pool_id: "1f79d3dd8366a9d04512e59e3cd65c1342eaa261b641ef93e5ac8f86",
    db_ticker: "WDHB",
    db_name: "WDHBPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "COJRPool",
    icon: "0",
    pool_id: "54ee46b05aac43c6f692963d731037b1747a4cebfc119ccd2c5ad78b",
    db_ticker: "COJR",
    db_name: "COJRPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PKJNPool",
    icon: "0",
    pool_id: "c44a041de1e1e85d5f89a5afac6d3c304e411855b2418ae209178cdc",
    db_ticker: "PKJN",
    db_name: "PKJNPool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1434673",
    stake_x_deleg: "1434673",
    saturated: "2.0869769675612e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Huobi",
    icon: "0",
    pool_id: "2a999d0685a1dbddfae38e754d6380a6398151130a7a2f8065b0705d",
    db_ticker: "Huobi",
    db_name: "Huobi",
    db_url: "https://www.huobi.com",
    roa: "4.952",
    roa_lifetime: "3.954",
    total_stake: "5821945874704",
    stake_x_deleg: "1164389174940.8",
    saturated: "0.084690148534859",
    luck_lifetime: "102",
    blocks_epoch: "4",
    blocks_lifetime: "185",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "7184e00be7c503b803408fba57a51b0bbc2f28b7e27646a2311604fb",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "3.011",
    roa_lifetime: "3.379",
    total_stake: "2013443868744",
    stake_x_deleg: "671147956248",
    saturated: "0.029288980691391",
    luck_lifetime: "109",
    blocks_epoch: "1",
    blocks_lifetime: "26",
    tax_ratio: "0.069",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TAM Stake Pool",
    icon: "0",
    pool_id: "39a561d4c1861c687579748bf06342f558e2bc04c49af70c81c8332d",
    db_ticker: "TAM",
    db_name: "TAM Stake Pool",
    db_url: "https://twitter.com/TAM_Stake_Pool",
    roa: "3.587",
    roa_lifetime: "4.363",
    total_stake: "13900162258679",
    stake_x_deleg: "1544462473186.6",
    saturated: "0.20220160607488",
    luck_lifetime: "107",
    blocks_epoch: "10",
    blocks_lifetime: "814",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cronetree Stake Pool",
    icon: "0",
    pool_id: "c80ba3446dd81ed334867b9ce23f550aa421fd1a6863e1d38ebe6f39",
    db_ticker: "CRTR",
    db_name: "Cronetree Stake Pool",
    db_url: "https://myadapoolnamerocks.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "381264620",
    stake_x_deleg: "190632310",
    saturated: "0.0000055461452225418",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 45",
    icon: "0",
    pool_id: "279d3af9d898261a19860ebc05eed79104915f05ad9f6960b530c3a2",
    db_ticker: "BNP",
    db_name: "Binance Staking - 45",
    db_url: "https://www.binance.com",
    roa: "3.085",
    roa_lifetime: "4.351",
    total_stake: "34442037228227",
    stake_x_deleg: "984058206520.77",
    saturated: "0.50101826974645",
    luck_lifetime: "98",
    blocks_epoch: "29",
    blocks_lifetime: "4162",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Celsius Network 1",
    icon: "0",
    pool_id: "b684dbad975a48346edb835ffd9842ce6f5d49b1f12cb863a608c856",
    db_ticker: "CLS1",
    db_name: "Celsius Network 1",
    db_url: "https://celsius.network/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50600536386290",
    stake_x_deleg: "16866845462097",
    saturated: "0.73607124400076",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "35",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Staked",
    icon: "0",
    pool_id: "1c8e2b0ad16edffdce4bea70202427842fbecd07420f4c9032cdd59d",
    db_ticker: "STKD",
    db_name: "Staked",
    db_url: "https://staked.us",
    roa: "2.837",
    roa_lifetime: "3.939",
    total_stake: "10116481459016",
    stake_x_deleg: "337216048633.87",
    saturated: "0.14716150507974",
    luck_lifetime: "92",
    blocks_epoch: "16",
    blocks_lifetime: "3733",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Reserve System",
    icon: "https://cdn.adapools.org/pool_logo/7102ce043d15b9f9ff47b118142b978339c6dda4f204d403331e1fff.png",
    pool_id: "7102ce043d15b9f9ff47b118142b978339c6dda4f204d403331e1fff",
    db_ticker: "CRS",
    db_name: "Cardano Reserve System",
    db_url: "https://www.cardanoreserve.com/",
    roa: "4.05",
    roa_lifetime: "4.142",
    total_stake: "15079437262324",
    stake_x_deleg: "1884929657790.5",
    saturated: "0.21935617559022",
    luck_lifetime: "105",
    blocks_epoch: "11",
    blocks_lifetime: "130",
    tax_ratio: "0.01",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "System76 Cardano Pool",
    icon: "0",
    pool_id: "0ee128326da3f46009dfc7599363e7d2fafaeb2017aa613165f68e7e",
    db_ticker: "SYS76",
    db_name: "System76 Cardano Pool",
    db_url: "https://system76-server.encyclopedia.tw/crypto/cardano",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "200000000",
    stake_x_deleg: "200000000",
    saturated: "0.0000029093416653986",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CypherGrunts",
    icon: "0",
    pool_id: "fa2a42b641e8b01ef50a8bf7d720d57562cdfdbe361be18234f864cb",
    db_ticker: "GRUNT",
    db_name: "CypherGrunts",
    db_url: "https://cyphergrunts.com",
    roa: "0",
    roa_lifetime: "0.249",
    total_stake: "476394111",
    stake_x_deleg: "476394111",
    saturated: "0.0000069299661814141",
    luck_lifetime: "87",
    blocks_epoch: "0",
    blocks_lifetime: "7",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "894ff15d8bef5f740a2fa3552157834d9d6b2f5a232bed3819d756b5",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAY Pool",
    icon: "0",
    pool_id: "af5797205f66a7f564f68cda31396523fd0c8781fc8ace1ad158c429",
    db_ticker: "WAY",
    db_name: "WAY Pool",
    db_url: "https://skylightpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2001800619",
    stake_x_deleg: "2001800619",
    saturated: "0.000029119609733387",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "1500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite C",
    icon: "https://cdn.adapools.org/pool_logo/c5d7ddd302cb05652d785b3eb0c3075bbee4e84225fd69465617948b.png",
    pool_id: "c5d7ddd302cb05652d785b3eb0c3075bbee4e84225fd69465617948b",
    db_ticker: "NUFIC",
    db_name: "Nu.Fi &amp; AdaLite C",
    db_url: "https://nu.fi",
    roa: "3.975",
    roa_lifetime: "4.875",
    total_stake: "13121867123486",
    stake_x_deleg: "1874552446212.3",
    saturated: "0.19087997375091",
    luck_lifetime: "98",
    blocks_epoch: "10",
    blocks_lifetime: "1983",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Monkey Contracts",
    icon: "0",
    pool_id: "1ea4d9e25ae203c16197cd2d51d4e566ea6e38bebcb2d08d09729d83",
    db_ticker: "MONK",
    db_name: "Monkey Contracts",
    db_url: "https://monkeycontracts.com",
    roa: "0",
    roa_lifetime: "0.445",
    total_stake: "326266573708",
    stake_x_deleg: "27188881142.333",
    saturated: "0.0047461046845776",
    luck_lifetime: "17",
    blocks_epoch: "0",
    blocks_lifetime: "5",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GaiemmaeStakingPool",
    icon: "0",
    pool_id: "54e2ca1629ae284dec65686eb740820338166e597d4418c037f23d4c",
    db_ticker: "GAIM1",
    db_name: "GaiemmaeStakingPool",
    db_url: "http://gaien1-staking.xyz",
    roa: "3.2",
    roa_lifetime: "2.157",
    total_stake: "3078208238653",
    stake_x_deleg: "90535536430.971",
    saturated: "0.044777797417432",
    luck_lifetime: "77",
    blocks_epoch: "4",
    blocks_lifetime: "65",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO4 Pool",
    icon: "0",
    pool_id: "e455088c9164ae075f0300bc1a7234c30433556c6b88d4301a06cfe5",
    db_ticker: "LEO4",
    db_name: "LEO4 Pool",
    db_url: "https://staking-fm.site",
    roa: "4.002",
    roa_lifetime: "3.944",
    total_stake: "23893373939233",
    stake_x_deleg: "4778674787846.6",
    saturated: "0.3475699416418",
    luck_lifetime: "97",
    blocks_epoch: "15",
    blocks_lifetime: "5003",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gundam Stake",
    icon: "0",
    pool_id: "9423f5591c5eaa1189e07f4e13780b23798fa771baadc60ff44245b2",
    db_ticker: "Gunda",
    db_name: "Gundam Stake",
    db_url: "https://cross.technology",
    roa: "2.693",
    roa_lifetime: "1.531",
    total_stake: "4017813896639",
    stake_x_deleg: "2008906948319.5",
    saturated: "0.058445966866547",
    luck_lifetime: "46",
    blocks_epoch: "4",
    blocks_lifetime: "33",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Live_Music_Show_Stake_Pool",
    icon: "0",
    pool_id: "f479f294e0315d3e58d0156f0af92c8c3fcf0b04d87be45995d9401a",
    db_ticker: "MUSIC",
    db_name: "Live_Music_Show_Stake_Pool",
    db_url: "https://spo.livemusic.show",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "88910796344",
    stake_x_deleg: "8082799667.6364",
    saturated: "0.0012933594215368",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GAGA2",
    icon: "0",
    pool_id: "e98e298624ce6efe34635318bdd5d634bed732bb238989d3d1b6fc9e",
    db_ticker: "GAGA2",
    db_name: "GAGA2",
    db_url: "https://staking-fm.site",
    roa: "3.636",
    roa_lifetime: "4.397",
    total_stake: "53200636888896",
    stake_x_deleg: "4092356683761.2",
    saturated: "0.77389414763303",
    luck_lifetime: "98",
    blocks_epoch: "40",
    blocks_lifetime: "4825",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NOBI",
    icon: "https://cdn.adapools.org/pool_logo/a6fc7ec669c64333e6c411b50e9c99323d8043391aacbe2b49f7cb29.png",
    pool_id: "a6fc7ec669c64333e6c411b50e9c99323d8043391aacbe2b49f7cb29",
    db_ticker: "NOBI",
    db_name: "NOBI",
    db_url: "https://usenobi.com",
    roa: "3.488",
    roa_lifetime: "4.145",
    total_stake: "22552723037579",
    stake_x_deleg: "2050247548870.8",
    saturated: "0.32806788400712",
    luck_lifetime: "91",
    blocks_epoch: "18",
    blocks_lifetime: "2344",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "3000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "REX Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/4d214f833ecc4de71a18875dd93dad90aaa25716c241830db19991e7.png",
    pool_id: "4d214f833ecc4de71a18875dd93dad90aaa25716c241830db19991e7",
    db_ticker: "REX",
    db_name: "REX Stake Pool",
    db_url: "https://rexstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5922701064",
    stake_x_deleg: "2961350532",
    saturated: "0.000086155804885979",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Route.net",
    icon: "0",
    pool_id: "efe8c47b005a614d810da708433ca22dd903027756be3aa96553c940",
    db_ticker: "ROUTE",
    db_name: "Route.net",
    db_url: "https://route.net",
    roa: "0",
    roa_lifetime: "1.556",
    total_stake: "30682798462",
    stake_x_deleg: "15341399231",
    saturated: "0.00044633371988262",
    luck_lifetime: "76",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HashPayStakePool",
    icon: "0",
    pool_id: "e953b17cfb383e7b5a903e1064be64ae1fa62a95797de40614dcb937",
    db_ticker: "HPAY",
    db_name: "HashPayStakePool",
    db_url: "https://stakepool.hashpay.ch",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "132502367",
    stake_x_deleg: "66251183.5",
    saturated: "0.0000019274732853852",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Carda Mom Pool",
    icon: "0",
    pool_id: "d1033d20f0dd39bf6ec625dbcf48b90145f8d1c470293298e87f792c",
    db_ticker: "MOM",
    db_name: "Carda Mom Pool",
    db_url: "https://twitter.com/CardamomPool",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "161021654",
    stake_x_deleg: "161021654",
    saturated: "0.000002342335035068",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "60c3bcc7d72109047eb33f2c7f05f393139960f683853a10db426ad2",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7624514",
    stake_x_deleg: "7624514",
    saturated: "1.1091158129307e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PayPoolName",
    icon: "0",
    pool_id: "090b35f42bab1c9010a9c144db84c4bb34855e0ce44136c98057eed4",
    db_ticker: "PAY",
    db_name: "PayPoolName",
    db_url: "none",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "102067027",
    stake_x_deleg: "102067027",
    saturated: "0.0000014847392715723",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XxxPoolName",
    icon: "0",
    pool_id: "2f711ee4abc0b1bbbec7a6941f37c6798f6a20fa8e624285f3050438",
    db_ticker: "XXX",
    db_name: "XxxPoolName",
    db_url: "none",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "102070371",
    stake_x_deleg: "102070371",
    saturated: "0.000001484787915765",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DevilsPoolGround",
    icon: "0",
    pool_id: "b4e4e97099d2c00f425ecf1837095863b9b0d8b9d5ccd5be81372f9c",
    db_ticker: "666",
    db_name: "DevilsPoolGround",
    db_url: "none",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "102070371",
    stake_x_deleg: "102070371",
    saturated: "0.000001484787915765",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Monster Stake Pool",
    icon: "0",
    pool_id: "24bd62ae985cfaabb04e11614928385884390f1629b1977a4d9315f4",
    db_ticker: "MSP",
    db_name: "Monster Stake Pool",
    db_url: "https://monsterstakepool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.15",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "LEO7 Pool",
    icon: "0",
    pool_id: "3635fed04554336e1f58a8db3e30d26e229b9c2d5f67f419a4297069",
    db_ticker: "LEO7",
    db_name: "LEO7 Pool",
    db_url: "https://staking-fm.site",
    roa: "3.206",
    roa_lifetime: "3.791",
    total_stake: "27321194199615",
    stake_x_deleg: "5464238839923",
    saturated: "0.39743344316693",
    luck_lifetime: "98",
    blocks_epoch: "18",
    blocks_lifetime: "3771",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPP Stake Pool",
    icon: "0",
    pool_id: "2146dfb28bb438d70cd5f7532357c81b6ab25d55163094c9618ab8fc",
    db_ticker: "SPP",
    db_name: "SPP Stake Pool",
    db_url: "",
    roa: "2.958",
    roa_lifetime: "3.471",
    total_stake: "21562158418045",
    stake_x_deleg: "3080308345435",
    saturated: "0.31365842940772",
    luck_lifetime: "95",
    blocks_epoch: "15",
    blocks_lifetime: "518",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Cookies",
    icon: "0",
    pool_id: "ffed333bbe10b88c515b9b23f09917f744774c04f8cc6482e216cb95",
    db_ticker: "CCM",
    db_name: "Cardano Cookies",
    db_url: "https://cardanocookies.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "229374109",
    stake_x_deleg: "57343527.25",
    saturated: "0.0000033366382613869",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "50000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Thailand",
    icon: "0",
    pool_id: "7c30ca03866f2e3c615a70783bdbd58b668807276e2220dadddbe0c2",
    db_ticker: "CDNTH",
    db_name: "Cardano Thailand",
    db_url: "https://cardano-thailand.com",
    roa: "3.753",
    roa_lifetime: "3.62",
    total_stake: "8776627998946",
    stake_x_deleg: "516272235232.12",
    saturated: "0.12767104759519",
    luck_lifetime: "115",
    blocks_epoch: "7",
    blocks_lifetime: "193",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "4cdacde59095e9d7decba6f25d8d7fb5788e566724e0f6bad5912ed0",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.2",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ARRK POOL",
    icon: "https://cdn.adapools.org/pool_logo/3ed1b022f921fa2d37894e3592ac2271a00e25ef706df7c86743006e.png",
    pool_id: "3ed1b022f921fa2d37894e3592ac2271a00e25ef706df7c86743006e",
    db_ticker: "ARRK",
    db_name: "ARRK POOL",
    db_url: "https://arrk.live/",
    roa: "0",
    roa_lifetime: "3.765",
    total_stake: "90083469494",
    stake_x_deleg: "5004637194.1111",
    saturated: "0.0013104179558128",
    luck_lifetime: "92",
    blocks_epoch: "0",
    blocks_lifetime: "255",
    tax_ratio: "0",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Toadpool",
    icon: "0",
    pool_id: "288bdc78a8a9ed9b214645cd1ea65a00690b0cbd1d0af73efcbe0f33",
    db_ticker: "TOAD",
    db_name: "Toadpool",
    db_url: "https://toadpool.org",
    roa: "3.28",
    roa_lifetime: "2.893",
    total_stake: "3332438259526",
    stake_x_deleg: "666487651905.2",
    saturated: "0.048476007379037",
    luck_lifetime: "118",
    blocks_epoch: "2",
    blocks_lifetime: "159",
    tax_ratio: "0.2",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 39",
    icon: "0",
    pool_id: "2cf87fadec7130c7e41910492073ae4dbe65ca462c1fdc3d2a56d015",
    db_ticker: "BNP",
    db_name: "Binance Staking - 39",
    db_url: "https://www.binance.com",
    roa: "3.299",
    roa_lifetime: "4.16",
    total_stake: "64318647262380",
    stake_x_deleg: "3573258181243.3",
    saturated: "0.93562460171259",
    luck_lifetime: "95",
    blocks_epoch: "50",
    blocks_lifetime: "5919",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Harmonic",
    icon: "https://cdn.adapools.org/pool_logo/2a05c534817a0b97ce0c5a2354b6e35a067c52408fa70c77e0b5e378.png",
    pool_id: "2a05c534817a0b97ce0c5a2354b6e35a067c52408fa70c77e0b5e378",
    db_ticker: "ARMN",
    db_name: "Harmonic",
    db_url: "https://harmonicpool.on.fleek.co",
    roa: "3.341",
    roa_lifetime: "3.767",
    total_stake: "15125833321640",
    stake_x_deleg: "840324073424.44",
    saturated: "0.22003108553261",
    luck_lifetime: "94",
    blocks_epoch: "14",
    blocks_lifetime: "378",
    tax_ratio: "0.032",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Atomic Wallet",
    icon: "0",
    pool_id: "8f67312cb7927075292fcc986927c986ccdd630a3bae1b52dd4671a4",
    db_ticker: "AWP3",
    db_name: "Atomic Wallet",
    db_url: "https://atomicwallet.io",
    roa: "2.969",
    roa_lifetime: "3.757",
    total_stake: "52448269338872",
    stake_x_deleg: "1730737504.5826",
    saturated: "0.76294967632814",
    luck_lifetime: "86",
    blocks_epoch: "44",
    blocks_lifetime: "3393",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Atomic Wallet",
    icon: "0",
    pool_id: "a3ac6ca0694fc5825f831c8d0d97f202c4f298741ae60874730fafb8",
    db_ticker: "AWP",
    db_name: "Atomic Wallet",
    db_url: "https://atomicwallet.io",
    roa: "3.299",
    roa_lifetime: "4.354",
    total_stake: "34126175917376",
    stake_x_deleg: "1571910452.2053",
    saturated: "0.49642352738572",
    luck_lifetime: "97",
    blocks_epoch: "24",
    blocks_lifetime: "4563",
    tax_ratio: "0.05",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "spacecoins",
    icon: "https://cdn.adapools.org/pool_logo/782253118f1397ec130f4ab38d782b4ec1126ac74bd6f4b06d6b7a01.png",
    pool_id: "782253118f1397ec130f4ab38d782b4ec1126ac74bd6f4b06d6b7a01",
    db_ticker: "DIDIT",
    db_name: "spacecoins",
    db_url: "https://spacecoins.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25438323270",
    stake_x_deleg: "1695888218",
    saturated: "0.00037004386893645",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.25",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: " Ray of Light &amp; Hope for the deserving",
    icon: "0",
    pool_id: "4a57fbcf474c7f99415c894386d0118890e9ff9b1854f68f58002c67",
    db_ticker: "ARKA",
    db_name: " Ray of Light &amp; Hope for the deserving",
    db_url: "https://arka.techcallidus.com",
    roa: "0",
    roa_lifetime: "4.468",
    total_stake: "10955465889",
    stake_x_deleg: "730364392.6",
    saturated: "0.0001593659668736",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "65",
    tax_ratio: "0.25",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "spacecoins",
    icon: "https://cdn.adapools.org/pool_logo/c61e2032aae2c704a588257345a7f38f26395aa9d00b450d74d122d1.png",
    pool_id: "c61e2032aae2c704a588257345a7f38f26395aa9d00b450d74d122d1",
    db_ticker: "YOUR",
    db_name: "spacecoins",
    db_url: "https://spacecoins.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6277815797",
    stake_x_deleg: "523151316.41667",
    saturated: "0.000091321555329548",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.25",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UBX Pool 1",
    icon: "0",
    pool_id: "92a10197f76a6bf4e27f9c813a80b532d231e5b99da72603b2cd65fb",
    db_ticker: "UBX1",
    db_name: "UBX Pool 1",
    db_url: "https://cardano-staking-pool.cms.ubx.ph/",
    roa: "2.56",
    roa_lifetime: "2.959",
    total_stake: "8117814763881",
    stake_x_deleg: "279924647030.38",
    saturated: "0.11808748362273",
    luck_lifetime: "84",
    blocks_epoch: "9",
    blocks_lifetime: "108",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 36",
    icon: "0",
    pool_id: "3200ed8ab323773d4d1a64333a0d79b93dc8f3a45c37f88141e34317",
    db_ticker: "BNP",
    db_name: "Binance Staking - 36",
    db_url: "https://www.binance.com",
    roa: "3.724",
    roa_lifetime: "4.281",
    total_stake: "60551226243233",
    stake_x_deleg: "4657786634094.8",
    saturated: "0.88082102700207",
    luck_lifetime: "97",
    blocks_epoch: "41",
    blocks_lifetime: "6018",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Warm Vanilla Archimedes",
    icon: "0",
    pool_id: "0054fc7a4e34f9a1eef15a05fdfc9d323deeb9b72a4b5ec514247e66",
    db_ticker: "WVA",
    db_name: "Warm Vanilla Archimedes",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "59281768850491",
    stake_x_deleg: "29640884425246",
    saturated: "0.86235460057631",
    luck_lifetime: "0",
    blocks_epoch: "10",
    blocks_lifetime: "0",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 32",
    icon: "0",
    pool_id: "243499b53228f5953bb906c8af0fb996002dd78712e430aa7d116964",
    db_ticker: "BNP",
    db_name: "Binance Staking - 32",
    db_url: "https://www.binance.com",
    roa: "3.654",
    roa_lifetime: "4.31",
    total_stake: "64354864490411",
    stake_x_deleg: "5362905374200.9",
    saturated: "0.93615144316517",
    luck_lifetime: "98",
    blocks_epoch: "49",
    blocks_lifetime: "6122",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 1",
    icon: "0",
    pool_id: "bf7908ea0ef61c441e4dca0e756f335c33459bb8f2779cb8f6caf8eb",
    db_ticker: "BD1",
    db_name: "Blockdaemon  Stake Pool 1",
    db_url: "https://blockdaemon.com/",
    roa: "3.136",
    roa_lifetime: "3.915",
    total_stake: "35767753868395",
    stake_x_deleg: "5961292311399.2",
    saturated: "0.52030308303522",
    luck_lifetime: "94",
    blocks_epoch: "25",
    blocks_lifetime: "2047",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WHCZPool",
    icon: "0",
    pool_id: "00472a2a777ca2a0d180515e3ff1b848a87b72448097412d2c9289b3",
    db_ticker: "WHCZ",
    db_name: "WHCZPool",
    db_url: "",
    roa: "3.463",
    roa_lifetime: "4.135",
    total_stake: "32014723019456",
    stake_x_deleg: "5335787169909.3",
    saturated: "0.4657088379335",
    luck_lifetime: "97",
    blocks_epoch: "22",
    blocks_lifetime: "2742",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 38",
    icon: "0",
    pool_id: "639288b5e4bf267ece78e9a4941dd794f50ec54785f84c687b4ab086",
    db_ticker: "BNP",
    db_name: "Binance Staking - 38",
    db_url: "https://www.binance.com",
    roa: "3.461",
    roa_lifetime: "4.253",
    total_stake: "64320970266160",
    stake_x_deleg: "6432097026616",
    saturated: "0.93565839377102",
    luck_lifetime: "97",
    blocks_epoch: "61",
    blocks_lifetime: "6037",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Krypto Knight",
    icon: "0",
    pool_id: "fcc675f8266022c4a0f8a7f1e24b00896fd742b35f147304c2354442",
    db_ticker: "KKS",
    db_name: "Krypto Knight",
    db_url: "https://krypto-knight.us/",
    roa: "3.421",
    roa_lifetime: "4.017",
    total_stake: "61028718332923",
    stake_x_deleg: "6780968703658.1",
    saturated: "0.88776696515924",
    luck_lifetime: "95",
    blocks_epoch: "53",
    blocks_lifetime: "4664",
    tax_ratio: "0.03",
    tax_fix: "345000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HGK StakePool",
    icon: "0",
    pool_id: "0cbc37bbfc5161da696ae49bb5313f510def4ba6711de70c2beaf80d",
    db_ticker: "HGK",
    db_name: "HGK StakePool",
    db_url: "https://twitter.com/HGK000",
    roa: "2.233",
    roa_lifetime: "2.648",
    total_stake: "13947315112726",
    stake_x_deleg: "1992473587532.3",
    saturated: "0.20288752488949",
    luck_lifetime: "96",
    blocks_epoch: "10",
    blocks_lifetime: "266",
    tax_ratio: "0.3",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 34",
    icon: "0",
    pool_id: "7a990e7ee931f9d548a977d5cf2194f26f7e2f1158752e0bb94a073f",
    db_ticker: "BNP",
    db_name: "Binance Staking - 34",
    db_url: "https://www.binance.com",
    roa: "4.064",
    roa_lifetime: "4.265",
    total_stake: "64355784460663",
    stake_x_deleg: "7150642717851.4",
    saturated: "0.93616482570409",
    luck_lifetime: "97",
    blocks_epoch: "53",
    blocks_lifetime: "6071",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 40",
    icon: "0",
    pool_id: "9418abb33c7cf50778598371bc1f09fe5460d1dedf740faea665f4ac",
    db_ticker: "BNP",
    db_name: "Binance Staking - 40",
    db_url: "https://www.binance.com",
    roa: "3.794",
    roa_lifetime: "4.254",
    total_stake: "64336520067828",
    stake_x_deleg: "7148502229758.7",
    saturated: "0.93588459220043",
    luck_lifetime: "97",
    blocks_epoch: "48",
    blocks_lifetime: "6037",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 41",
    icon: "0",
    pool_id: "ffb3fe58689ae0d70d6fb939a842ee78ecfa10eab561274327e78b7b",
    db_ticker: "BNP",
    db_name: "Binance Staking - 41",
    db_url: "https://www.binance.com",
    roa: "3.837",
    roa_lifetime: "4.255",
    total_stake: "64362216279197",
    stake_x_deleg: "7151357364355.2",
    saturated: "0.93625838749232",
    luck_lifetime: "97",
    blocks_epoch: "54",
    blocks_lifetime: "6055",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 71",
    icon: "0",
    pool_id: "3d82913d4c8da690aab6af622651f7a659da40af87dcb4b3b98ce830",
    db_ticker: "BNP",
    db_name: "Binance Staking - 71",
    db_url: "https://www.binance.com",
    roa: "3.757",
    roa_lifetime: "4.266",
    total_stake: "64342395637659",
    stake_x_deleg: "7149155070851",
    saturated: "0.93597006240101",
    luck_lifetime: "99",
    blocks_epoch: "48",
    blocks_lifetime: "5533",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 21",
    icon: "0",
    pool_id: "4b5e02dce5f549a0c332d8b02f91c43a506311fe1cd4498ddcf46bd0",
    db_ticker: "BNP",
    db_name: "Binance Staking - 21",
    db_url: "https://www.binance.com",
    roa: "3.507",
    roa_lifetime: "4.114",
    total_stake: "64331301999713",
    stake_x_deleg: "7147922444412.6",
    saturated: "0.93580868648553",
    luck_lifetime: "94",
    blocks_epoch: "50",
    blocks_lifetime: "5831",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nodeasy #2",
    icon: "0",
    pool_id: "523484ee98a83c3139c66eeef334d67f96467241d34abb655baeade0",
    db_ticker: "NDE",
    db_name: "Nodeasy #2",
    db_url: "https://www.nodeasy.com",
    roa: "4.097",
    roa_lifetime: "4.3",
    total_stake: "62371633160678",
    stake_x_deleg: "7796454145084.8",
    saturated: "0.90730195546659",
    luck_lifetime: "97",
    blocks_epoch: "40",
    blocks_lifetime: "5021",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "2000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "YLGAPool",
    icon: "0",
    pool_id: "f17566678e30c1c75dd39e68829723a3c1aeff1d4f8924c6cdd18746",
    db_ticker: "YLGA",
    db_name: "YLGAPool",
    db_url: "",
    roa: "3.688",
    roa_lifetime: "4.212",
    total_stake: "32015307913434",
    stake_x_deleg: "6403061582686.8",
    saturated: "0.4657173462156",
    luck_lifetime: "99",
    blocks_epoch: "24",
    blocks_lifetime: "2779",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FOEQPool",
    icon: "0",
    pool_id: "55eaf38bf6eb1c6755d67175b59f93940f09abd6792d525c55089903",
    db_ticker: "FOEQ",
    db_name: "FOEQPool",
    db_url: "",
    roa: "4.024",
    roa_lifetime: "4.104",
    total_stake: "32021055679434",
    stake_x_deleg: "6404211135886.8",
    saturated: "0.46580095729113",
    luck_lifetime: "97",
    blocks_epoch: "33",
    blocks_lifetime: "2718",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 35",
    icon: "0",
    pool_id: "625ae8c8aaaead6ceebf262f380245cb61b4a3e96199b27eb9b01ad9",
    db_ticker: "BNP",
    db_name: "Binance Staking - 35",
    db_url: "https://www.binance.com",
    roa: "4.056",
    roa_lifetime: "4.318",
    total_stake: "64338634379629",
    stake_x_deleg: "8042329297453.6",
    saturated: "0.93591534847751",
    luck_lifetime: "99",
    blocks_epoch: "56",
    blocks_lifetime: "6144",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 73",
    icon: "0",
    pool_id: "e0ac9f1b68d0621a4e6723f369a50e81f27916acb7cd42bc03eaac93",
    db_ticker: "BNP",
    db_name: "Binance Staking - 73",
    db_url: "https://www.binance.com",
    roa: "3.699",
    roa_lifetime: "4.186",
    total_stake: "64356320980220",
    stake_x_deleg: "8044540122527.5",
    saturated: "0.9361726302976",
    luck_lifetime: "97",
    blocks_epoch: "44",
    blocks_lifetime: "5421",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 49",
    icon: "0",
    pool_id: "2c88e3a7f6279bda114f92dbbcd78b734f0ebff40ff2bdce647e9c53",
    db_ticker: "BNP",
    db_name: "Binance Staking - 49",
    db_url: "https://www.binance.com",
    roa: "3.927",
    roa_lifetime: "4.314",
    total_stake: "65508144763458",
    stake_x_deleg: "8188518095432.2",
    saturated: "0.95292787491646",
    luck_lifetime: "99",
    blocks_epoch: "54",
    blocks_lifetime: "5958",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NVE Tech StakePool",
    icon: "0",
    pool_id: "34812765ebf71de725a4431652e68e96382d64da1e0ca0bf5ee80c1d",
    db_ticker: "NVET",
    db_name: "NVE Tech StakePool",
    db_url: "https://twitter.com/nvetech",
    roa: "2.837",
    roa_lifetime: "2.669",
    total_stake: "19376336881964",
    stake_x_deleg: "3229389480327.3",
    saturated: "0.28186192106749",
    luck_lifetime: "95",
    blocks_epoch: "14",
    blocks_lifetime: "369",
    tax_ratio: "0.3",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Battle Borgz blockchain game stake pool.",
    icon: "https://cdn.adapools.org/pool_logo/3a391718111f3bcfab1769c9568988f7a75b94f80828d8a6a7c89121.png",
    pool_id: "3a391718111f3bcfab1769c9568988f7a75b94f80828d8a6a7c89121",
    db_ticker: "BORGZ",
    db_name: "Battle Borgz blockchain game stake pool.",
    db_url: "https://battleborgz.com/",
    roa: "0",
    roa_lifetime: "1.019",
    total_stake: "81163072431",
    stake_x_deleg: "1690897342.3125",
    saturated: "0.0011806555415764",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.5",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Flac Finance ISPO 50% pool A",
    icon: "0",
    pool_id: "641d6331ec4832382558482982c3e3a273ae174f5ed6641b06f7b2c1",
    db_ticker: "FF50A",
    db_name: "Flac Finance ISPO 50% pool A",
    db_url: "https://flac.finance",
    roa: "1.014",
    roa_lifetime: "1.207",
    total_stake: "176586697716",
    stake_x_deleg: "12613335551.143",
    saturated: "0.0025687551861015",
    luck_lifetime: "124",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.5",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ARTHPool",
    icon: "0",
    pool_id: "68ac3c1be0477698b5c825ce598cca359dbf6132e1761a572403b9fb",
    db_ticker: "ARTH",
    db_name: "ARTHPool",
    db_url: "",
    roa: "3.864",
    roa_lifetime: "4.142",
    total_stake: "32022313272414",
    stake_x_deleg: "8005578318103.5",
    saturated: "0.4658192511294",
    luck_lifetime: "98",
    blocks_epoch: "30",
    blocks_lifetime: "2753",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JWERPool",
    icon: "0",
    pool_id: "07cc67445f419f643158cfdf3d0a3dd12507b8e81b961a19d3e9d3b5",
    db_ticker: "JWER",
    db_name: "JWERPool",
    db_url: "",
    roa: "3.394",
    roa_lifetime: "4.054",
    total_stake: "32022710635097",
    stake_x_deleg: "8005677658774.2",
    saturated: "0.46582503144845",
    luck_lifetime: "95",
    blocks_epoch: "21",
    blocks_lifetime: "2760",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 37",
    icon: "0",
    pool_id: "0f581255fdb8e8e88d3676a3cbede33e1f2456171cbe39266b3db679",
    db_ticker: "BNP",
    db_name: "Binance Staking - 37",
    db_url: "https://www.binance.com",
    roa: "3.69",
    roa_lifetime: "4.294",
    total_stake: "64348780274818",
    stake_x_deleg: "9192682896402.6",
    saturated: "0.93606293785554",
    luck_lifetime: "98",
    blocks_epoch: "63",
    blocks_lifetime: "6130",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 53",
    icon: "0",
    pool_id: "beb5390bc047831874ad69f615a6f023ea121865c75ec389d6c0a392",
    db_ticker: "BNP",
    db_name: "Binance Staking - 53",
    db_url: "https://www.binance.com",
    roa: "3.678",
    roa_lifetime: "4.409",
    total_stake: "64336777277236",
    stake_x_deleg: "9190968182462.3",
    saturated: "0.93588833375066",
    luck_lifetime: "101",
    blocks_epoch: "66",
    blocks_lifetime: "6059",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 54",
    icon: "0",
    pool_id: "7e00129d906afbba06a6b279fcf6e4295986a1104b7f5c4046c653bc",
    db_ticker: "BNP",
    db_name: "Binance Staking - 54",
    db_url: "https://www.binance.com",
    roa: "3.404",
    roa_lifetime: "4.3",
    total_stake: "64353838501622",
    stake_x_deleg: "9193405500231.7",
    saturated: "0.93613651840551",
    luck_lifetime: "99",
    blocks_epoch: "55",
    blocks_lifetime: "5900",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 65",
    icon: "0",
    pool_id: "61d2598400211dcda6878511eb6d84719e5c502c7aa63d500f7a57ce",
    db_ticker: "BNP",
    db_name: "Binance Staking - 65",
    db_url: "https://www.binance.com",
    roa: "3.554",
    roa_lifetime: "4.283",
    total_stake: "64338664183211",
    stake_x_deleg: "9191237740458.7",
    saturated: "0.93591578202152",
    luck_lifetime: "100",
    blocks_epoch: "56",
    blocks_lifetime: "5551",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RYOMA",
    icon: "0",
    pool_id: "1596878503f749aa3f6c33583e1e98ca240ef984a1cb7cf1afa6a814",
    db_ticker: "RYOMA",
    db_name: "RYOMA",
    db_url: "https://github.com/ryomapool/ryoma",
    roa: "3.089",
    roa_lifetime: "1.002",
    total_stake: "21060967234000",
    stake_x_deleg: "5265241808500",
    saturated: "0.30636774743735",
    luck_lifetime: "98",
    blocks_epoch: "12",
    blocks_lifetime: "2058",
    tax_ratio: "0.24",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CTEC2 Stake Pool",
    icon: "0",
    pool_id: "55962d29056c55303f0db16442011ab0392f7e7b37bdaeae97a14f0e",
    db_ticker: "CTEC2",
    db_name: "CTEC2 Stake Pool",
    db_url: "https://www.crown-tech.co.jp/ctecstakepool/",
    roa: "1.773",
    roa_lifetime: "1.614",
    total_stake: "7626784555849",
    stake_x_deleg: "2542261518616.3",
    saturated: "0.11094461040675",
    luck_lifetime: "104",
    blocks_epoch: "4",
    blocks_lifetime: "444",
    tax_ratio: "0.5",
    tax_fix: "680000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 31",
    icon: "0",
    pool_id: "c1bef45a2abbb3a862cdd9142236dff38763cc32e91fc8f282823ee6",
    db_ticker: "BNP",
    db_name: "Binance Staking - 31",
    db_url: "https://www.binance.com",
    roa: "3.691",
    roa_lifetime: "4.194",
    total_stake: "64328971910711",
    stake_x_deleg: "10721495318452",
    saturated: "0.93577479136044",
    luck_lifetime: "96",
    blocks_epoch: "49",
    blocks_lifetime: "5989",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 56",
    icon: "0",
    pool_id: "a250eb51869c6b94cbc507d22e76ed0f35d31ccabdb1725cfbcddb75",
    db_ticker: "BNP",
    db_name: "Binance Staking - 56",
    db_url: "https://www.binance.com",
    roa: "3.622",
    roa_lifetime: "4.288",
    total_stake: "64318995243032",
    stake_x_deleg: "10719832540505",
    saturated: "0.93562966368564",
    luck_lifetime: "98",
    blocks_epoch: "51",
    blocks_lifetime: "5867",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 62",
    icon: "0",
    pool_id: "11c660b87cb43e4720b053895b3137b4c08f367dbd030127c9c6de9e",
    db_ticker: "BNP",
    db_name: "Binance Staking - 62",
    db_url: "https://www.binance.com",
    roa: "3.361",
    roa_lifetime: "4.135",
    total_stake: "64294578634370",
    stake_x_deleg: "10715763105728",
    saturated: "0.9352744824011",
    luck_lifetime: "96",
    blocks_epoch: "46",
    blocks_lifetime: "5355",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 47",
    icon: "0",
    pool_id: "82906c841da0f4010b2fa1a2c3726b5571b02eea6abfb48d7c6a4184",
    db_ticker: "BNP",
    db_name: "Binance Staking - 47",
    db_url: "https://www.binance.com",
    roa: "3.528",
    roa_lifetime: "4.314",
    total_stake: "64352486350389",
    stake_x_deleg: "10725414391732",
    saturated: "0.93611684905591",
    luck_lifetime: "99",
    blocks_epoch: "58",
    blocks_lifetime: "5931",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 50",
    icon: "0",
    pool_id: "96a46d5a796a30483492c3717f80b7c6ef87e9525af162ffacf74fdb",
    db_ticker: "BNP",
    db_name: "Binance Staking - 50",
    db_url: "https://www.binance.com",
    roa: "3.64",
    roa_lifetime: "4.257",
    total_stake: "64358848680177",
    stake_x_deleg: "10726474780030",
    saturated: "0.93620940001161",
    luck_lifetime: "98",
    blocks_epoch: "52",
    blocks_lifetime: "5823",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 55",
    icon: "0",
    pool_id: "b44181287c72d0c7b1bce6a57173cdf739ac77d030bdfc4606138dd2",
    db_ticker: "BNP",
    db_name: "Binance Staking - 55",
    db_url: "https://www.binance.com",
    roa: "3.859",
    roa_lifetime: "4.31",
    total_stake: "64353683491989",
    stake_x_deleg: "10725613915332",
    saturated: "0.93613426352559",
    luck_lifetime: "99",
    blocks_epoch: "56",
    blocks_lifetime: "5930",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dionysus Pool - 60% Managed / 40% Stake",
    icon: "https://cdn.adapools.org/pool_logo/a2073a8b7a2bf6969baa44028416bf7ae919d495d3cfed787f2c3009.png",
    pool_id: "a2073a8b7a2bf6969baa44028416bf7ae919d495d3cfed787f2c3009",
    db_ticker: "DIO1",
    db_name: "Dionysus Pool - 60% Managed / 40% Stake",
    db_url: "https://dionysus-pool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1000000",
    stake_x_deleg: "1000000",
    saturated: "1.4546708326993e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.6",
    tax_fix: "500000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite F",
    icon: "https://cdn.adapools.org/pool_logo/3862e102e1440b150ccafe5d16ac68cd78ce6e46afc4fb0977fcd6bd.png",
    pool_id: "3862e102e1440b150ccafe5d16ac68cd78ce6e46afc4fb0977fcd6bd",
    db_ticker: "NUFIF",
    db_name: "Nu.Fi &amp; AdaLite F",
    db_url: "https://nu.fi",
    roa: "3.755",
    roa_lifetime: "4.55",
    total_stake: "54962141477860",
    stake_x_deleg: "10992428295572",
    saturated: "0.79951824110535",
    luck_lifetime: "100",
    blocks_epoch: "44",
    blocks_lifetime: "4216",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "The Parallel Revolution",
    icon: "0",
    pool_id: "57f5b9353079d7441414e62c7dbaaa20cff50ec6b2b560fe580d42fe",
    db_ticker: "PLL",
    db_name: "The Parallel Revolution",
    db_url: "https://www.theparallelrevolution.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.03",
    tax_fix: "10000000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MOJWPool",
    icon: "0",
    pool_id: "dd47ad6a8fe21ca342e28a163cb74e69b13d519538de3d06ac26aad2",
    db_ticker: "MOJW",
    db_name: "MOJWPool",
    db_url: "",
    roa: "3.553",
    roa_lifetime: "4.055",
    total_stake: "32013390047156",
    stake_x_deleg: "10671130015719",
    saturated: "0.46568944757424",
    luck_lifetime: "96",
    blocks_epoch: "26",
    blocks_lifetime: "2685",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BVUYPool",
    icon: "0",
    pool_id: "c7e431518477a4156c2a8cd6523a6a73514e7fbbbf7eed363b9a22ad",
    db_ticker: "BVUY",
    db_name: "BVUYPool",
    db_url: "",
    roa: "3.96",
    roa_lifetime: "4.191",
    total_stake: "32016584414757",
    stake_x_deleg: "10672194804919",
    saturated: "0.46573591510802",
    luck_lifetime: "99",
    blocks_epoch: "30",
    blocks_lifetime: "2800",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ZDUHPool",
    icon: "0",
    pool_id: "35a38b78bf1f4b829a98d1ebf27d883b3f936eefcdfce2bd7a83c4c7",
    db_ticker: "ZDUH",
    db_name: "ZDUHPool",
    db_url: "",
    roa: "3.573",
    roa_lifetime: "4.267",
    total_stake: "32019117083661",
    stake_x_deleg: "10673039027887",
    saturated: "0.46577275710386",
    luck_lifetime: "100",
    blocks_epoch: "38",
    blocks_lifetime: "2821",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "JWVNPool",
    icon: "0",
    pool_id: "b91e740582b7166b2238c19dbb081de4cb86be0b27ead58401510c05",
    db_ticker: "JWVN",
    db_name: "JWVNPool",
    db_url: "",
    roa: "3.596",
    roa_lifetime: "4.033",
    total_stake: "32021337117149",
    stake_x_deleg: "10673779039050",
    saturated: "0.46580505128348",
    luck_lifetime: "95",
    blocks_epoch: "28",
    blocks_lifetime: "2754",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GOmetalaunch",
    icon: "0",
    pool_id: "aed3a9d4edde3e8454eb3e68b196263cf0a4c2cdf3ffb687f8734106",
    db_ticker: "URGO",
    db_name: "GOmetalaunch",
    db_url: "https://GOmetalaunch.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1250510327404",
    stake_x_deleg: "44661083121.571",
    saturated: "0.018190808992639",
    luck_lifetime: "88",
    blocks_epoch: "0",
    blocks_lifetime: "13",
    tax_ratio: "0.7",
    tax_fix: "340000000",
    pledge: "102000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 42",
    icon: "0",
    pool_id: "d4970177385f7c3c90f51af3dbc4a865221778b48f23e4dc60dd4a67",
    db_ticker: "BNP",
    db_name: "Binance Staking - 42",
    db_url: "https://www.binance.com",
    roa: "3.746",
    roa_lifetime: "4.17",
    total_stake: "64331819636426",
    stake_x_deleg: "12866363927285",
    saturated: "0.93581621639581",
    luck_lifetime: "96",
    blocks_epoch: "64",
    blocks_lifetime: "5981",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 30",
    icon: "0",
    pool_id: "cb9a5414581696cf9176787e99472790355a7de61d67a5643da95a86",
    db_ticker: "BNP",
    db_name: "Binance Staking - 30",
    db_url: "https://www.binance.com",
    roa: "3.607",
    roa_lifetime: "4.292",
    total_stake: "64369082682506",
    stake_x_deleg: "12873816536501",
    saturated: "0.93635827105851",
    luck_lifetime: "96",
    blocks_epoch: "69",
    blocks_lifetime: "6084",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 68",
    icon: "0",
    pool_id: "204f49517b4b9df91efb81b030a9b33738f4f0f43819fd2c39cb438b",
    db_ticker: "BNP",
    db_name: "Binance Staking - 68",
    db_url: "https://www.binance.com",
    roa: "3.288",
    roa_lifetime: "4.211",
    total_stake: "64314191684979",
    stake_x_deleg: "12862838336996",
    saturated: "0.93555978772771",
    luck_lifetime: "98",
    blocks_epoch: "55",
    blocks_lifetime: "5451",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "FKAIPool",
    icon: "0",
    pool_id: "dec7757e2ba0fcea7fd93a9999bf342d1974338fb91355ac68c13320",
    db_ticker: "FKAI",
    db_name: "FKAIPool",
    db_url: "",
    roa: "3.415",
    roa_lifetime: "2.755",
    total_stake: "44447297922936",
    stake_x_deleg: "11111824480734",
    saturated: "0.64656187880791",
    luck_lifetime: "97",
    blocks_epoch: "33",
    blocks_lifetime: "1794",
    tax_ratio: "0.1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Dionysus Pool - 70% Managed/ 30% Stake",
    icon: "https://cdn.adapools.org/pool_logo/455be4b1f2d7c6186eca6320c0c8065ddc70e33432ddac7845cbb2de.png",
    pool_id: "455be4b1f2d7c6186eca6320c0c8065ddc70e33432ddac7845cbb2de",
    db_ticker: "DIO2",
    db_name: "Dionysus Pool - 70% Managed/ 30% Stake",
    db_url: "https://dionysus-pool.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.7",
    tax_fix: "500000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano ADA SSS Pool",
    icon: "0",
    pool_id: "a07f6d16fc809cbd6c3dd8bb8e3fdc9e874e1cacba480a359b3f2252",
    db_ticker: "SSS",
    db_name: "Cardano ADA SSS Pool",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7968060804090",
    stake_x_deleg: "996007600511.25",
    saturated: "0.11590905644884",
    luck_lifetime: "98",
    blocks_epoch: "8",
    blocks_lifetime: "1540",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC-Asia Consulting",
    icon: "0",
    pool_id: "5c35c3c09f0ed0069b0cfe229bb475b2e8ed8a99462e78b16bd0dce7",
    db_ticker: "THCB",
    db_name: "THC-Asia Consulting",
    db_url: "https://stake.love",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9586173242745",
    stake_x_deleg: "599135827671.56",
    saturated: "0.13944726613424",
    luck_lifetime: "118",
    blocks_epoch: "13",
    blocks_lifetime: "161",
    tax_ratio: "0.99",
    tax_fix: "500000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "THC Cardano Stake World",
    icon: "0",
    pool_id: "ba90de47f28afe4bf8b3b999af16b31c233a631b962e47b9483477f4",
    db_ticker: "THCV",
    db_name: "THC Cardano Stake World",
    db_url: "https://thc-room335.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "8491702613902",
    stake_x_deleg: "653207893377.08",
    saturated: "0.123526321124",
    luck_lifetime: "90",
    blocks_epoch: "7",
    blocks_lifetime: "84",
    tax_ratio: "0.99",
    tax_fix: "500000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Aada Finance - Stake ADA earn AADA",
    icon: "https://cdn.adapools.org/pool_logo/c48ed34a5f24ca6dcbac194e45ed9f0b6ac720018eac038ffa9488bd.png",
    pool_id: "c48ed34a5f24ca6dcbac194e45ed9f0b6ac720018eac038ffa9488bd",
    db_ticker: "ISPO",
    db_name: "Aada Finance - Stake ADA earn AADA",
    db_url: "https://aada.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4230363109622",
    stake_x_deleg: "2207913940.3038",
    saturated: "0.061537858272942",
    luck_lifetime: "103",
    blocks_epoch: "3",
    blocks_lifetime: "826",
    tax_ratio: "0.99",
    tax_fix: "345000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Minswap",
    icon: "0",
    pool_id: "ec3308cedfbe904927d51f9a0d9cb93492da29e22e32900f85a15d8c",
    db_ticker: "MIN",
    db_name: "Minswap",
    db_url: "https://minswap.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "45788563851270",
    stake_x_deleg: "3270611703662.1",
    saturated: "0.66607288305632",
    luck_lifetime: "97",
    blocks_epoch: "30",
    blocks_lifetime: "757",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "30000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 33",
    icon: "0",
    pool_id: "b21ddee9370f41c3b2b71da34f8a57943ba59034a777b80aed503c2b",
    db_ticker: "BNP",
    db_name: "Binance Staking - 33",
    db_url: "https://www.binance.com",
    roa: "3.506",
    roa_lifetime: "4.331",
    total_stake: "64337991859553",
    stake_x_deleg: "16084497964888",
    saturated: "0.93590600192537",
    luck_lifetime: "99",
    blocks_epoch: "43",
    blocks_lifetime: "6164",
    tax_ratio: "0.06",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 52",
    icon: "0",
    pool_id: "662796197f741c9b9f0d9830530d01300ee5c63f549784ff2ac81c17",
    db_ticker: "BNP",
    db_name: "Binance Staking - 52",
    db_url: "https://www.binance.com",
    roa: "3.77",
    roa_lifetime: "4.301",
    total_stake: "64344321606492",
    stake_x_deleg: "16086080401623",
    saturated: "0.93599807890787",
    luck_lifetime: "99",
    blocks_epoch: "64",
    blocks_lifetime: "5891",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 57",
    icon: "0",
    pool_id: "a7784228ea544bc94455e76f527aaee53266929f0bb2896893a0444f",
    db_ticker: "BNP",
    db_name: "Binance Staking - 57",
    db_url: "https://www.binance.com",
    roa: "3.803",
    roa_lifetime: "4.272",
    total_stake: "64327479882427",
    stake_x_deleg: "16081869970607",
    saturated: "0.93575308726018",
    luck_lifetime: "98",
    blocks_epoch: "51",
    blocks_lifetime: "5850",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 58",
    icon: "0",
    pool_id: "3caaa49b3f45a914736c393c97c4bb8611ab7d5f66ecb4e214e93606",
    db_ticker: "BNP",
    db_name: "Binance Staking - 58",
    db_url: "https://www.binance.com",
    roa: "3.643",
    roa_lifetime: "4.289",
    total_stake: "64331505691623",
    stake_x_deleg: "16082876422906",
    saturated: "0.93581164953233",
    luck_lifetime: "99",
    blocks_epoch: "51",
    blocks_lifetime: "5886",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 70",
    icon: "0",
    pool_id: "caf8502f66536e95138fbf23c14abde1d13090d4aef52b9287f682e8",
    db_ticker: "BNP",
    db_name: "Binance Staking - 70",
    db_url: "https://www.binance.com",
    roa: "3.689",
    roa_lifetime: "4.256",
    total_stake: "64346129835195",
    stake_x_deleg: "16086532458799",
    saturated: "0.9360243826834",
    luck_lifetime: "99",
    blocks_epoch: "46",
    blocks_lifetime: "5518",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 72",
    icon: "0",
    pool_id: "936316c217c6f217640ed8d132cb0ff8133eef73e74e4c28002b9eb3",
    db_ticker: "BNP",
    db_name: "Binance Staking - 72",
    db_url: "https://www.binance.com",
    roa: "3.77",
    roa_lifetime: "4.258",
    total_stake: "64335811408314",
    stake_x_deleg: "16083952852078",
    saturated: "0.93587428353717",
    luck_lifetime: "99",
    blocks_epoch: "56",
    blocks_lifetime: "5517",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-6",
    icon: "0",
    pool_id: "aa3afa914dce3ccdf6e282daac537283bcc03d9a56dd8f80936114e8",
    db_ticker: "WAV6",
    db_name: "WAVE-6",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10110629831128",
    stake_x_deleg: "3370209943709.3",
    saturated: "0.14707638315561",
    luck_lifetime: "98",
    blocks_epoch: "15",
    blocks_lifetime: "1680",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "10000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Flach",
    icon: "0",
    pool_id: "89c9b0934094c11a4263a716b7f94d12ebf9882124bbce0438cd6f90",
    db_ticker: "FLACH",
    db_name: "Flach",
    db_url: "https://pool.pm",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2426338345251",
    stake_x_deleg: "485267669050.2",
    saturated: "0.035295236210965",
    luck_lifetime: "110",
    blocks_epoch: "4",
    blocks_lifetime: "248",
    tax_ratio: "1",
    tax_fix: "400000000",
    pledge: "50000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Genius X 2",
    icon: "https://cdn.adapools.org/pool_logo/6ec2284f8e312b5b122d85b97db828ff364d49e3c51c449bfc8889bf.png",
    pool_id: "6ec2284f8e312b5b122d85b97db828ff364d49e3c51c449bfc8889bf",
    db_ticker: "GENS2",
    db_name: "Genius X 2",
    db_url: "https://www.genius-x.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "47641734445883",
    stake_x_deleg: "14582716389.924",
    saturated: "0.69303041517632",
    luck_lifetime: "84",
    blocks_epoch: "35",
    blocks_lifetime: "1095",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Genius X 1",
    icon: "https://cdn.adapools.org/pool_logo/c485ab20bd3f105e59f3c50a0d3fbaf615a51f70a1c6d29d00a1fd27.png",
    pool_id: "c485ab20bd3f105e59f3c50a0d3fbaf615a51f70a1c6d29d00a1fd27",
    db_ticker: "GENS1",
    db_name: "Genius X 1",
    db_url: "https://www.genius-x.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "45016438662474",
    stake_x_deleg: "12684259978.156",
    saturated: "0.65484100314298",
    luck_lifetime: "90",
    blocks_epoch: "37",
    blocks_lifetime: "1802",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Genius X",
    icon: "https://cdn.adapools.org/pool_logo/c80d3b1f64feed0697bb97c97867a63ff1c33cb8b472bc33fdfa457e.png",
    pool_id: "c80d3b1f64feed0697bb97c97867a63ff1c33cb8b472bc33fdfa457e",
    db_ticker: "GENSX",
    db_name: "Genius X",
    db_url: "https://www.genius-x.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "45012204345934",
    stake_x_deleg: "13496912847.357",
    saturated: "0.65477940777531",
    luck_lifetime: "91",
    blocks_epoch: "34",
    blocks_lifetime: "1252",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Genius X 3",
    icon: "https://cdn.adapools.org/pool_logo/126f84b8f88fd0f65de921ef521f2aa637e5e465cea06042c103fe22.png",
    pool_id: "126f84b8f88fd0f65de921ef521f2aa637e5e465cea06042c103fe22",
    db_ticker: "GENS3",
    db_name: "Genius X 3",
    db_url: "https://www.genius-x.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "38469230679309",
    stake_x_deleg: "12687740989.218",
    saturated: "0.55960067825572",
    luck_lifetime: "91",
    blocks_epoch: "30",
    blocks_lifetime: "1635",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Iagon 1",
    icon: "https://cdn.adapools.org/pool_logo/a790a551d217bafb8548f2e92f20b56b63980d5a320af1cc65402328.png",
    pool_id: "a790a551d217bafb8548f2e92f20b56b63980d5a320af1cc65402328",
    db_ticker: "IAG1",
    db_name: "Iagon 1",
    db_url: "https://iagon.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5280286617780",
    stake_x_deleg: "10864787279.383",
    saturated: "0.07681078931177",
    luck_lifetime: "119",
    blocks_epoch: "9",
    blocks_lifetime: "51",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "d728b1de1e241b7564fdeb6cf09cc1a2a1817cf0517a4d117764606e",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12475349430209",
    stake_x_deleg: "4158449810069.7",
    saturated: "0.18147526943857",
    luck_lifetime: "111",
    blocks_epoch: "6",
    blocks_lifetime: "127",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "10000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HKZDPool",
    icon: "0",
    pool_id: "b7c07212231ead5c8bdc75842a5b912709b8e728d2153cc4254d6f31",
    db_ticker: "HKZD",
    db_name: "HKZDPool",
    db_url: "",
    roa: "3.596",
    roa_lifetime: "4.089",
    total_stake: "32018486914238",
    stake_x_deleg: "16009243457119",
    saturated: "0.46576359021306",
    luck_lifetime: "96",
    blocks_epoch: "23",
    blocks_lifetime: "2707",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "XJKGPool",
    icon: "0",
    pool_id: "585a5de1da253667d10c28d09b7f447b20d83810381b936d27641760",
    db_ticker: "XJKG",
    db_name: "XJKGPool",
    db_url: "",
    roa: "3.55",
    roa_lifetime: "4.086",
    total_stake: "32020398634115",
    stake_x_deleg: "16010199317058",
    saturated: "0.46579139944452",
    luck_lifetime: "96",
    blocks_epoch: "33",
    blocks_lifetime: "2702",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "DIPNPool",
    icon: "0",
    pool_id: "486d217a5ef6e9cb5e46a19900a56b1c1f4f1ec4374b744df5a1ef6a",
    db_ticker: "DIPN",
    db_name: "DIPNPool",
    db_url: "",
    roa: "4.202",
    roa_lifetime: "4.192",
    total_stake: "32021036773034",
    stake_x_deleg: "16010518386517",
    saturated: "0.46580068226524",
    luck_lifetime: "99",
    blocks_epoch: "27",
    blocks_lifetime: "2772",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OPSXPool",
    icon: "0",
    pool_id: "43bf2e2c7601bd6dfb38564fc2c48f5b1a773fdb4095dee412f52e50",
    db_ticker: "OPSX",
    db_name: "OPSXPool",
    db_url: "",
    roa: "3.552",
    roa_lifetime: "4.004",
    total_stake: "32024218075544",
    stake_x_deleg: "16012109037772",
    saturated: "0.46584695974496",
    luck_lifetime: "95",
    blocks_epoch: "28",
    blocks_lifetime: "2660",
    tax_ratio: "0.075",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Flac Finance ISPO 100% pool A",
    icon: "0",
    pool_id: "e4ac73ef83e1d5950a3fe84b3a4b51045f9b01d2db18121395b4ac94",
    db_ticker: "FFMXA",
    db_name: "Flac Finance ISPO 100% pool A",
    db_url: "https://flac.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4660594435872",
    stake_x_deleg: "9669283061.9751",
    saturated: "0.067796307889036",
    luck_lifetime: "114",
    blocks_epoch: "3",
    blocks_lifetime: "18",
    tax_ratio: "0.999",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GERO Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/e97191994b0ca417f71fea850389c844be1786e00778615adba32c5d.png",
    pool_id: "e97191994b0ca417f71fea850389c844be1786e00778615adba32c5d",
    db_ticker: "GERO",
    db_name: "GERO Stake Pool",
    db_url: "https://gerowallet.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1458893533909",
    stake_x_deleg: "8895692279.9329",
    saturated: "0.02122209871791",
    luck_lifetime: "105",
    blocks_epoch: "1",
    blocks_lifetime: "7",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IAMX - OWN YOUR IDENTITY Pool",
    icon: "https://cdn.adapools.org/pool_logo/31da45799477b63af0bef7cf60073c7f02d5682b84792304c50a361b.png",
    pool_id: "31da45799477b63af0bef7cf60073c7f02d5682b84792304c50a361b",
    db_ticker: "IAMX2",
    db_name: "IAMX - OWN YOUR IDENTITY Pool",
    db_url: "https://iamx.id/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "274618568808",
    stake_x_deleg: "8581830275.25",
    saturated: "0.0039947962216262",
    luck_lifetime: "15",
    blocks_epoch: "0",
    blocks_lifetime: "2",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "anetaBTC - LISO Pool 1",
    icon: "https://cdn.adapools.org/pool_logo/f5f50f91a3d0da560380ec4e1a612530261c2aaed74a51dfffc485f9.png",
    pool_id: "f5f50f91a3d0da560380ec4e1a612530261c2aaed74a51dfffc485f9",
    db_ticker: "NETA1",
    db_name: "anetaBTC - LISO Pool 1",
    db_url: "https://www.anetabtc.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "42301882644247",
    stake_x_deleg: "11966586320.862",
    saturated: "0.61535314850855",
    luck_lifetime: "93",
    blocks_epoch: "37",
    blocks_lifetime: "901",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "880000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "anetaBTC - LISO Pool 2",
    icon: "https://cdn.adapools.org/pool_logo/a5cc5b8b27e7f1076b3ec673f12839da911e170a2b819ae602b5e019.png",
    pool_id: "a5cc5b8b27e7f1076b3ec673f12839da911e170a2b819ae602b5e019",
    db_ticker: "NETA2",
    db_name: "anetaBTC - LISO Pool 2",
    db_url: "https://www.anetabtc.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "31590277324541",
    stake_x_deleg: "11571530155.51",
    saturated: "0.45953455020892",
    luck_lifetime: "89",
    blocks_epoch: "26",
    blocks_lifetime: "698",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "880000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardano Mixer Stake Pool",
    icon: "0",
    pool_id: "0ef395f417fc1b8a124784c279575708e05d8917363fa8b3172d1631",
    db_ticker: "CMIX",
    db_name: "Cardano Mixer Stake Pool",
    db_url: "https://cardmix.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6534787829467",
    stake_x_deleg: "8271883328.4392",
    saturated: "0.09505965253404",
    luck_lifetime: "98",
    blocks_epoch: "3",
    blocks_lifetime: "114",
    tax_ratio: "0.99",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BitcoinSuisse01",
    icon: "0",
    pool_id: "88cc73feada202ed17343e969c373c16cda4a8902d02094ff3dfc438",
    db_ticker: "btcs",
    db_name: "BitcoinSuisse01",
    db_url: "https://bitcoinsuisse.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "36606610045684",
    stake_x_deleg: "141338262724.65",
    saturated: "0.53250567917454",
    luck_lifetime: "96",
    blocks_epoch: "29",
    blocks_lifetime: "4184",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ravendex Pool",
    icon: "https://cdn.adapools.org/pool_logo/a2665c9ebfde02738b44fb7a86e7f957c0fb0079dfd2f9174cf624f9.png",
    pool_id: "a2665c9ebfde02738b44fb7a86e7f957c0fb0079dfd2f9174cf624f9",
    db_ticker: "RAVEP",
    db_name: "Ravendex Pool",
    db_url: "https://pool.ravendex.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1329167988306",
    stake_x_deleg: "3273812779.0788",
    saturated: "0.019335019043463",
    luck_lifetime: "97",
    blocks_epoch: "2",
    blocks_lifetime: "139",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IAMX - OWN YOUR IDENTITY Pool",
    icon: "https://cdn.adapools.org/pool_logo/45e0ce168917ce9a23fe6e3cc819cd1893d8c0aefe52095e954c5ba1.png",
    pool_id: "45e0ce168917ce9a23fe6e3cc819cd1893d8c0aefe52095e954c5ba1",
    db_ticker: "IAMX3",
    db_name: "IAMX - OWN YOUR IDENTITY Pool",
    db_url: "https://iamx.id/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "70851131204",
    stake_x_deleg: "2285520361.4194",
    saturated: "0.0010306507402621",
    luck_lifetime: "49",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GoKey Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/c3c27acc9633f75202050d99f475178cff31cfde6d059696cc2d97f9.png",
    pool_id: "c3c27acc9633f75202050d99f475178cff31cfde6d059696cc2d97f9",
    db_ticker: "GOKEY",
    db_name: "GoKey Stake Pool",
    db_url: "https://gokey.network",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "480419054232",
    stake_x_deleg: "4251496055.1504",
    saturated: "0.0069885158566427",
    luck_lifetime: "153",
    blocks_epoch: "0",
    blocks_lifetime: "3",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Waya Collective Stake Pool",
    icon: "https://cdn.adapools.org/pool_logo/5e14b70b4bcf267bd6ad96d8c695b99f7c39227b37316160f2f7a5f1.png",
    pool_id: "5e14b70b4bcf267bd6ad96d8c695b99f7c39227b37316160f2f7a5f1",
    db_ticker: "WYACO",
    db_name: "Waya Collective Stake Pool",
    db_url: "https://wayacollective.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1979553491567",
    stake_x_deleg: "7146402496.6318",
    saturated: "0.028795987259506",
    luck_lifetime: "147",
    blocks_epoch: "1",
    blocks_lifetime: "31",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "OccamFi",
    icon: "https://cdn.adapools.org/pool_logo/34f513d0f993d188557696d598f54944879c24cb60811aea840fce47.png",
    pool_id: "34f513d0f993d188557696d598f54944879c24cb60811aea840fce47",
    db_ticker: "pOCC",
    db_name: "OccamFi",
    db_url: "https://occam-x.fi",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "26135148888369",
    stake_x_deleg: "9189574152.0285",
    saturated: "0.38018038796164",
    luck_lifetime: "94",
    blocks_epoch: "17",
    blocks_lifetime: "1308",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IAMX - OWN YOUR IDENTITY Pool",
    icon: "https://cdn.adapools.org/pool_logo/6ac3bb93f42ccd80ac37b6ba7fd62c452af1ea538406c726a8c7fcdc.png",
    pool_id: "6ac3bb93f42ccd80ac37b6ba7fd62c452af1ea538406c726a8c7fcdc",
    db_ticker: "IAMX1",
    db_name: "IAMX - OWN YOUR IDENTITY Pool",
    db_url: "https://iamx.id/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65392138353",
    stake_x_deleg: "2109423817.8387",
    saturated: "0.00095124036349946",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UKRAINE POOL 1",
    icon: "0",
    pool_id: "4687c441061ffc4f827c7280cdf5f6e5b51762a46563daaf6e883bd7",
    db_ticker: "UP1",
    db_name: "UKRAINE POOL 1",
    db_url: "https://www.ukrainianpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "97622303551",
    stake_x_deleg: "9762230355.1",
    saturated: "0.0014200831759656",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lottery Pool",
    icon: "0",
    pool_id: "ff37a9645f9d5ab34192475136bf5ceb1c18166bb40a3b1637f4022b",
    db_ticker: "LOTTO",
    db_name: "Lottery Pool",
    db_url: "https://twitter.com/pjsstator",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "512755284",
    stake_x_deleg: "128188821",
    saturated: "0.0000074589015594725",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "345000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Cardax 1",
    icon: "0",
    pool_id: "1fbf1129cdae0a21f4b9f3d5bef58626fc489e9f09a2a3ef6a2a7f8f",
    db_ticker: "CDX1",
    db_name: "Cardax 1",
    db_url: "https://cardax.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "28657126415",
    stake_x_deleg: "9552375471.6667",
    saturated: "0.00041686685944877",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "UKRAINE POOL 2",
    icon: "0",
    pool_id: "a0b9cf234f8bdd04f53cd74ef0a74253b58cf2bde6f8a6ea792590bc",
    db_ticker: "UP2",
    db_name: "UKRAINE POOL 2",
    db_url: "https://www.ukrainianpool.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5553546056",
    stake_x_deleg: "1851182018.6667",
    saturated: "0.000080785814657154",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Mokonet Ada Pool",
    icon: "0",
    pool_id: "f49b1f9fbaaca0d6ee4d348ced2dfff14f6584cd06d55d7edee8f981",
    db_ticker: "MAP",
    db_name: "Mokonet Ada Pool",
    db_url: "https://adapool.mokonet.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "16525170251",
    stake_x_deleg: "5508390083.6667",
    saturated: "0.0002403868316952",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "345000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Teshita",
    icon: "0",
    pool_id: "822edf0f607d961d19a7204f5dbdf48bef7018c5afe1383b7d92d463",
    db_ticker: "TS1TA",
    db_name: "Teshita",
    db_url: "https://twitter.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2005379813721",
    stake_x_deleg: "222819979302.33",
    saturated: "0.029171675235039",
    luck_lifetime: "96",
    blocks_epoch: "4",
    blocks_lifetime: "25",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-11",
    icon: "0",
    pool_id: "2dc733ef313c5c5ead9b93b96918106b067e343b21bbc2d3576e2913",
    db_ticker: "WAV11",
    db_name: "WAVE-11",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68874606264768",
    stake_x_deleg: "34437303132384",
    saturated: "1.0018988084701",
    luck_lifetime: "98",
    blocks_epoch: "51",
    blocks_lifetime: "1103",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "68000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-10",
    icon: "0",
    pool_id: "13d3a7c2fbc1e210ce97ac1a3973bc4bc9d03f7d3906545a19782f7a",
    db_ticker: "WAV10",
    db_name: "WAVE-10",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68864661998842",
    stake_x_deleg: "34432330999421",
    saturated: "1.0017541521341",
    luck_lifetime: "89",
    blocks_epoch: "52",
    blocks_lifetime: "1120",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "68000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Stake BBQ Pool 1",
    icon: "0",
    pool_id: "48b14df69375d393b3728a01bf38a049c0b1eec04d973922cab835c3",
    db_ticker: "SBBQ1",
    db_name: "Stake BBQ Pool 1",
    db_url: "https://dltsecure.net",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "111594447051",
    stake_x_deleg: "13949305881.375",
    saturated: "0.001623331872163",
    luck_lifetime: "118",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "1",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "8BLOX",
    icon: "https://cdn.adapools.org/pool_logo/08b0c54264e2713931ba9de737e8bc9189d45ebf60983cb8dc7e3573.png",
    pool_id: "08b0c54264e2713931ba9de737e8bc9189d45ebf60983cb8dc7e3573",
    db_ticker: "8BLOX",
    db_name: "8BLOX",
    db_url: "https://metablx.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1515562627",
    stake_x_deleg: "757781313.5",
    saturated: "0.00002204644748626",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Red Bike",
    icon: "https://cdn.adapools.org/pool_logo/42850eb2d0c386213d60039c4b5888ecc996fc48063514cf20d4d82c.png",
    pool_id: "42850eb2d0c386213d60039c4b5888ecc996fc48063514cf20d4d82c",
    db_ticker: "RBA",
    db_name: "Red Bike",
    db_url: "https://redbike.asia",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2855342590",
    stake_x_deleg: "951780863.33333",
    saturated: "0.000041535835830371",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.999",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-2",
    icon: "0",
    pool_id: "7d89d3f11170f5874c5b781b80864be392edcd9c8715ccce18155a8f",
    db_ticker: "WAV2",
    db_name: "WAVE-2",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "69119122573411",
    stake_x_deleg: "69119122573411",
    saturated: "1.0054557158931",
    luck_lifetime: "91",
    blocks_epoch: "53",
    blocks_lifetime: "4949",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "65000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "3d5f6d2a147cf3c97aed0d7ea973e6a95abdf94255025bdf470c9809",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68196401050596",
    stake_x_deleg: "68196401050596",
    saturated: "0.99203315503366",
    luck_lifetime: "75",
    blocks_epoch: "65",
    blocks_lifetime: "2661",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "66000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "b405a62715301a625c2fd5eb3f5b16be9f1f3f0452f5b8b9286997c4",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68182481628528",
    stake_x_deleg: "68182481628528",
    saturated: "0.99183067326076",
    luck_lifetime: "74",
    blocks_epoch: "58",
    blocks_lifetime: "2628",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "66000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "ea5f1fb21658736dce6e8c63a516e9e5d645e104b9efbfbb395d7e68",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68159609956384",
    stake_x_deleg: "68159609956384",
    saturated: "0.99149796571713",
    luck_lifetime: "76",
    blocks_epoch: "47",
    blocks_lifetime: "2621",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "66000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "ad1b6687f605b3543f9315a5f966ea70fc5f35e7b2a4c3a943bef78d",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68062844070903",
    stake_x_deleg: "68062844070903",
    saturated: "0.99009034060503",
    luck_lifetime: "70",
    blocks_epoch: "61",
    blocks_lifetime: "2496",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "66000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "768a4abe40bf4ec986087a6f356984da85ac3b8388dfa6840e3cdcf7",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "67049758572675",
    stake_x_deleg: "67049758572675",
    saturated: "0.975353281352",
    luck_lifetime: "97",
    blocks_epoch: "48",
    blocks_lifetime: "4706",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "41b8741536cd484687a4c3adcdc07211cfce03ca58727212336acc5e",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66949538066203",
    stake_x_deleg: "66949538066203",
    saturated: "0.97389540287597",
    luck_lifetime: "98",
    blocks_epoch: "50",
    blocks_lifetime: "4592",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "f6541f3ca941d96d3c243eae01fea874d6be403b2dd2c6575245de6e",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66921124360857",
    stake_x_deleg: "66921124360857",
    saturated: "0.97348207699181",
    luck_lifetime: "94",
    blocks_epoch: "60",
    blocks_lifetime: "4538",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "b07dde7ad2e458a89177d4e10cd657309d9e36a79ef1edebb60b414f",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66906339452868",
    stake_x_deleg: "66906339452868",
    saturated: "0.97326700524766",
    luck_lifetime: "97",
    blocks_epoch: "59",
    blocks_lifetime: "4539",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "a3d7b1f62e5ee8cbb833f4db6e45b2c39f17eecc1d2a1bf90c407dc4",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66860063584335",
    stake_x_deleg: "66860063584335",
    saturated: "0.97259384368553",
    luck_lifetime: "96",
    blocks_epoch: "57",
    blocks_lifetime: "4480",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "8a8e0fa9953e6a02c73bf9c7471c08c20e4067cccb4afff6201cb6ef",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66849726715646",
    stake_x_deleg: "66849726715646",
    saturated: "0.97244347627169",
    luck_lifetime: "95",
    blocks_epoch: "53",
    blocks_lifetime: "4464",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "f619a10b7ccea6167c5f169634e834b57739c2db892185f2efa9f1eb",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66845329129040",
    stake_x_deleg: "66845329129040",
    saturated: "0.97237950586199",
    luck_lifetime: "97",
    blocks_epoch: "47",
    blocks_lifetime: "4479",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-1",
    icon: "0",
    pool_id: "e985b5698de1386439e62b5b7ab2cc9f814b91b4a1fef5970f38694d",
    db_ticker: "WAV1",
    db_name: "WAVE-1",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "68720393073039",
    stake_x_deleg: "68720393073039",
    saturated: "0.99965551414981",
    luck_lifetime: "98",
    blocks_epoch: "57",
    blocks_lifetime: "1620",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 6",
    icon: "0",
    pool_id: "737929797be1a93e3a61cda089e33368e3b91f3884b0d684462a32ec",
    db_ticker: "ETO6",
    db_name: "eToro Pool 6",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66775797188889",
    stake_x_deleg: "66775797188889",
    saturated: "0.97136804500921",
    luck_lifetime: "94",
    blocks_epoch: "48",
    blocks_lifetime: "4955",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NAPPY Pool",
    icon: "0",
    pool_id: "716ed335e4693c01acd957f1941dfb0c09ddba7d0119c531e49a255c",
    db_ticker: "NAPPY",
    db_name: "NAPPY Pool",
    db_url: "https://twitter.com/nappy66560609",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "675597122430",
    stake_x_deleg: "135119424486",
    saturated: "0.009827714286545",
    luck_lifetime: "92",
    blocks_epoch: "1",
    blocks_lifetime: "37",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ProjectSupportPoolJapan",
    icon: "0",
    pool_id: "a68e2b86e684e54da6027217da0bece7f9af20e9aca7a6c4d052d9d8",
    db_ticker: "PSPJ",
    db_name: "ProjectSupportPoolJapan",
    db_url: "https://ranket2.wordpress.com/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "651571171901",
    stake_x_deleg: "162892792975.25",
    saturated: "0.0094782157919209",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "7c5d59f69e113939aa1e19f081cc0884e2b85a55768a4347bf356191",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "206363708",
    stake_x_deleg: "68787902.666667",
    saturated: "0.0000030019126695528",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GADA Finance",
    icon: "https://cdn.adapools.org/pool_logo/b253e36605e45bd5151776eed3bfd5594feaef326824c24850cf1e68.png",
    pool_id: "b253e36605e45bd5151776eed3bfd5594feaef326824c24850cf1e68",
    db_ticker: "GFIN1",
    db_name: "GADA Finance",
    db_url: "https://www.gada.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "100352189523",
    stake_x_deleg: "5575121640.1667",
    saturated: "0.0014597940309662",
    luck_lifetime: "425",
    blocks_epoch: "0",
    blocks_lifetime: "1",
    tax_ratio: "0.9999",
    tax_fix: "500000000",
    pledge: "29500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SIMPL",
    icon: "0",
    pool_id: "78ce8b6855c8651d0ffc746dfb52cfe09386e1e37171c78b8433a9a0",
    db_ticker: "SIMPL",
    db_name: "SIMPL",
    db_url: "https://github.com/SIMPLPool/SPO",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2252433818551",
    stake_x_deleg: "204766710777.36",
    saturated: "0.032765497784316",
    luck_lifetime: "103",
    blocks_epoch: "1",
    blocks_lifetime: "252",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BitcoinSuisse03",
    icon: "0",
    pool_id: "c8a15d64b34aed5d3bca88915ceb53533a45ee1487c7b2398df24d42",
    db_ticker: "BTCS",
    db_name: "BitcoinSuisse03",
    db_url: "https://bitcoinsuisse.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "21627802848127",
    stake_x_deleg: "243009020765.47",
    saturated: "0.31461333978541",
    luck_lifetime: "108",
    blocks_epoch: "20",
    blocks_lifetime: "398",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private F",
    icon: "0",
    pool_id: "bc0aa0bf4b0128cf9f1ef99ed999c93e4d92aab478c052b2d1e5218b",
    db_ticker: "EMURF",
    db_name: "Emurgo Private F",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "11617275228",
    stake_x_deleg: "5808637614",
    saturated: "0.00016899311429612",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "3479",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WWW ADA POOL",
    icon: "0",
    pool_id: "1629f7b5f22b5e5d145e840379341e1a726bcee34b42ac9cf063e9c9",
    db_ticker: "WWW",
    db_name: "WWW ADA POOL",
    db_url: "https://twitter.com/nkr_1234",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "199027124015",
    stake_x_deleg: "99513562007.5",
    saturated: "0.0028951895222065",
    luck_lifetime: "139",
    blocks_epoch: "0",
    blocks_lifetime: "4",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Swinkels_pool",
    icon: "0",
    pool_id: "92214249302d9744840950a84077e634163f64e70105cfa493a1a95f",
    db_ticker: "Swink",
    db_name: "Swinkels_pool",
    db_url: "www.probablyAnInvalidURL.maja",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1113422061",
    stake_x_deleg: "556711030.5",
    saturated: "0.000016196625966206",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "647343600",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADA Stake Pool DK",
    icon: "0",
    pool_id: "f685439e01508839dfce172be07b3a5db88a87d16c836b4112daeb9d",
    db_ticker: "ADADK",
    db_name: "ADA Stake Pool DK",
    db_url: "https://ada-stakepool.dk",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1882013744",
    stake_x_deleg: "941006872",
    saturated: "0.00002737710500136",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1800000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private L",
    icon: "0",
    pool_id: "033a87edb0478f47318210a7b38aa7b0ccdb4c7de73ca605553eda26",
    db_ticker: "EMURL",
    db_name: "Emurgo Private L",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1505605983",
    stake_x_deleg: "752802991.5",
    saturated: "0.000021901611090077",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "a7fc9a31b48031790090d1a8446da881ce0a93536bb1e310bd7d08a9",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "103000000",
    stake_x_deleg: "51500000",
    saturated: "0.0000014983109576803",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "ADAO",
    icon: "0",
    pool_id: "6b97796c43b6e80aa880ec4d47cba4ce828a7a7e5d345c56bd9eae28",
    db_ticker: "ADAO",
    db_name: "ADAO",
    db_url: "https://summonplatform.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6370595570",
    stake_x_deleg: "3185297785",
    saturated: "0.000092671195626024",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "3000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kyoto pool 1",
    icon: "0",
    pool_id: "a8d2f897e5b42d9906a8f40931e53c922f9db5d6cccd4ad50472025c",
    db_ticker: "KYOTO",
    db_name: "Kyoto pool 1",
    db_url: "https://kyotoprotocol.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "195445945",
    stake_x_deleg: "97722972.5",
    saturated: "0.0000028430951556085",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "TAX Adjustment Stake Pool",
    icon: "0",
    pool_id: "974999446e4d0934ef7cf50f1345ca97a2dc65ab4fd13e89b912671b",
    db_ticker: "TAX",
    db_name: "TAX Adjustment Stake Pool",
    db_url: "https://no-tax.biz/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1280805063765",
    stake_x_deleg: "320201265941.25",
    saturated: "0.018631497686325",
    luck_lifetime: "108",
    blocks_epoch: "0",
    blocks_lifetime: "36",
    tax_ratio: "0.9999",
    tax_fix: "340000000",
    pledge: "750000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Proud Staking",
    icon: "0",
    pool_id: "9e20b3cbed16dddac0feaf226a83d0c824404672c1cbd5394fa6b6a2",
    db_ticker: "PROUD",
    db_name: "Proud Staking",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "54326509",
    stake_x_deleg: "54326509",
    saturated: "7.9027188084676e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "mtma",
    icon: "0",
    pool_id: "587c9118ee8fc6e771c9494de9fc03a8e937f5c103ec374fb764a27f",
    db_ticker: "mtma",
    db_name: "mtma",
    db_url: "http://www.ayame3.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5734388165209",
    stake_x_deleg: "337316950894.65",
    saturated: "0.083416472073056",
    luck_lifetime: "97",
    blocks_epoch: "6",
    blocks_lifetime: "295",
    tax_ratio: "0.999",
    tax_fix: "345000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PlanningT",
    icon: "0",
    pool_id: "d6323bbbf63cc1e1879918ea879d614641eeba7741856277f83cce69",
    db_ticker: "PLNGT",
    db_name: "PlanningT",
    db_url: "https://twitter.com/mac5_alpha",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5308405945056",
    stake_x_deleg: "482582358641.45",
    saturated: "0.077219832964005",
    luck_lifetime: "102",
    blocks_epoch: "7",
    blocks_lifetime: "70",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "GADA Finance",
    icon: "https://cdn.adapools.org/pool_logo/1aad61d60bebe5ec44fd2474f64d306fe71c68d31ea25705806bfb82.png",
    pool_id: "1aad61d60bebe5ec44fd2474f64d306fe71c68d31ea25705806bfb82",
    db_ticker: "GFIN2",
    db_name: "GADA Finance",
    db_url: "https://www.gada.finance",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "62414313846",
    stake_x_deleg: "31207156923",
    saturated: "0.00090792281894716",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "0.9999",
    tax_fix: "500000000",
    pledge: "30000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NHD StakePoolServer",
    icon: "0",
    pool_id: "3749cc41406e4a3fb7155fc1a3bc932618a11b857623b04868b61004",
    db_ticker: "NHD",
    db_name: "NHD StakePoolServer",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1431562686798",
    stake_x_deleg: "477187562266",
    saturated: "0.020824524856657",
    luck_lifetime: "93",
    blocks_epoch: "1",
    blocks_lifetime: "77",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SEXY",
    icon: "0",
    pool_id: "66e4abd18e4623a2a0acafc9ba78683d56785f4b74d50c7a981c42d9",
    db_ticker: "SEXY",
    db_name: "SEXY",
    db_url: "https://this.pool.is.not.in.use",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "24805635",
    stake_x_deleg: "24805635",
    saturated: "3.6084033721085e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 3",
    icon: "0",
    pool_id: "32fdb90d73865d16463fe1e092c7814a9f035f781a661d9d43ed7443",
    db_ticker: "ETO3",
    db_name: "eToro Pool 3",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "5194",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 20",
    icon: "0",
    pool_id: "ff25b9db61f779c606e3bfcd92997003965658e2ced070db2258e3af",
    db_ticker: "ETO20",
    db_name: "eToro Pool 20",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBowl3",
    icon: "0",
    pool_id: "3f795319d8e528a5c88641130860b4fbb0979d15289ff9d13efbf4f0",
    db_ticker: "STBL3",
    db_name: "StakeBowl3",
    db_url: "https://www.stakebowl.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1430897",
    stake_x_deleg: "1430897",
    saturated: "2.0814841304969e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CardanoValnode1",
    icon: "0",
    pool_id: "6352c3d00e4fba3005213be537635e446ecbb0376e7e9334a888ee40",
    db_ticker: "VLND1",
    db_name: "CardanoValnode1",
    db_url: "https://cardano.valnode.org",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "547448981",
    stake_x_deleg: "547448981",
    saturated: "0.0000079635806505165",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "25000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 4",
    icon: "0",
    pool_id: "83b8495a77cb960c6ef43c6520b3f8fbc25b96e35d3ea4c58abff48c",
    db_ticker: "ETO4",
    db_name: "eToro Pool 4",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "95",
    blocks_epoch: "0",
    blocks_lifetime: "4073",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private H",
    icon: "0",
    pool_id: "0544810c1e75cafe8e5364473349c7518ed0def7a198f348f9e92bc6",
    db_ticker: "EMURH",
    db_name: "Emurgo Private H",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502600000",
    stake_x_deleg: "1502600000",
    saturated: "0.00002185788393214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private I",
    icon: "0",
    pool_id: "2dd68004efab62c52b1fbb3ce999ee48aefe5c78e168495afd43cce4",
    db_ticker: "EMURI",
    db_name: "Emurgo Private I",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502600000",
    stake_x_deleg: "1502600000",
    saturated: "0.00002185788393214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private J",
    icon: "0",
    pool_id: "6b85c15dd478aa4a74fc0057c092b2e3e8b86ea4767e92e40240b4da",
    db_ticker: "EMURJ",
    db_name: "Emurgo Private J",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502600000",
    stake_x_deleg: "1502600000",
    saturated: "0.00002185788393214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private K",
    icon: "0",
    pool_id: "3da6b08e874c428851db118f590cc08ad8b51fbcdc0b28256410849e",
    db_ticker: "EMURK",
    db_name: "Emurgo Private K",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502600000",
    stake_x_deleg: "1502600000",
    saturated: "0.00002185788393214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private M",
    icon: "0",
    pool_id: "18f2fef01a1ba073827e8a6caec02af32f130a50a3b1254da50095b1",
    db_ticker: "EMURM",
    db_name: "Emurgo Private M",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1502600000",
    stake_x_deleg: "1502600000",
    saturated: "0.00002185788393214",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "c18f395d8e8d5d9012dfed12289e685c5a3ab5ff5b1f018240e9f881",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "389",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "44000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "hask",
    icon: "0",
    pool_id: "a52a2f1572fcaf1f31532a3a71c5582cc5bbb7c4d07e736a44f914a3",
    db_ticker: "HASK",
    db_name: "hask",
    db_url: "https://pools.zw3rk.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5075783",
    stake_x_deleg: "5075783",
    saturated: "7.383593483211e-8",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 21",
    icon: "0",
    pool_id: "770c0659e9f816d950fde8267d9dca05f3d705881df6098c9ee5b41c",
    db_ticker: "ETO21",
    db_name: "eToro Pool 21",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 19",
    icon: "0",
    pool_id: "95beb97c96a9eae0b477ca9f096d0041aee788c7f9b4bdfeaeb046ba",
    db_ticker: "ETO19",
    db_name: "eToro Pool 19",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-4",
    icon: "0",
    pool_id: "a906eb0f4c31aba088e24d1b9b91524bb9e0a12f64eb9d71ec2fb14c",
    db_ticker: "WAV4",
    db_name: "WAVE-4",
    db_url: "https://wavgp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "66",
    blocks_epoch: "0",
    blocks_lifetime: "565",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wave-8",
    icon: "0",
    pool_id: "ea00ab4ff7004cc9aafe7a32106afd1e55d5f1c6b5a63224219a78b6",
    db_ticker: "WAV8",
    db_name: "Wave-8",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "125177909",
    stake_x_deleg: "125177909",
    saturated: "0.0000018209265312059",
    luck_lifetime: "80",
    blocks_epoch: "0",
    blocks_lifetime: "21",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBowl2",
    icon: "0",
    pool_id: "d6921660bfcfdc70b598ace26c8270a5976b6c5ca87fdccca52a5c45",
    db_ticker: "STBL2",
    db_name: "StakeBowl2",
    db_url: "https://www.stakebowl.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1258444",
    stake_x_deleg: "1258444",
    saturated: "1.8306217813854e-8",
    luck_lifetime: "77",
    blocks_epoch: "0",
    blocks_lifetime: "132",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBowl3",
    icon: "0",
    pool_id: "715428fcce193098db697fa8d975497676fff092c549062ba9bea08a",
    db_ticker: "STBL3",
    db_name: "StakeBowl3",
    db_url: "https://www.stakebowl.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3455860",
    stake_x_deleg: "3455860",
    saturated: "5.0271387438922e-8",
    luck_lifetime: "93",
    blocks_epoch: "0",
    blocks_lifetime: "204",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 17",
    icon: "0",
    pool_id: "194783a7465fce936c6c1472b2e66f09d612f40f18a1f4e05d7035bf",
    db_ticker: "ETO17",
    db_name: "eToro Pool 17",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 16",
    icon: "0",
    pool_id: "7d3dcf02af6c7a2776c72bc1928d7125e94af1ab049123cedcb3db4e",
    db_ticker: "ETO16",
    db_name: "eToro Pool 16",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "NFT_Raffle_Pool",
    icon: "0",
    pool_id: "33cedfd1a47ba1cd10a60d28d1daa6c930894d9c581a0d9dd004e817",
    db_ticker: "RAFL",
    db_name: "NFT_Raffle_Pool",
    db_url: "http://nftraffle.club",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "537427641",
    stake_x_deleg: "537427641",
    saturated: "0.0000078178031404909",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "500000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-14",
    icon: "0",
    pool_id: "6c320c550c6261ee692ff2afda8c06438d5b1188fe5093039e2373b8",
    db_ticker: "WAV14",
    db_name: "WAVE-14",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "7800619",
    stake_x_deleg: "7800619",
    saturated: "1.13473329363e-7",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "10000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 15",
    icon: "0",
    pool_id: "dd8239a0e8ebabc159af3142193a84a290c5026ee65656ad9b69349b",
    db_ticker: "ETO15",
    db_name: "eToro Pool 15",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 18",
    icon: "0",
    pool_id: "e7599640136b50441663b4df54f8a0f9b463fbb52418d62cddc32c9e",
    db_ticker: "ETO18",
    db_name: "eToro Pool 18",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Equinox",
    icon: "0",
    pool_id: "d7875b556eec7e3008226b6b5bfebdbe0a39aea6f0e4b70b4f42d3e9",
    db_ticker: "EQX",
    db_name: "Equinox",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "500066807",
    stake_x_deleg: "500066807",
    saturated: "0.0000072743259854397",
    luck_lifetime: "0",
    blocks_epoch: "0",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 13",
    icon: "0",
    pool_id: "9924e7d74f73a8092c3cb64dc238cd157e2b934bdf88444fd5fcff8c",
    db_ticker: "ETO13",
    db_name: "eToro Pool 13",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "642856758470",
    stake_x_deleg: "642856758470",
    saturated: "0.0093514497614993",
    luck_lifetime: "78",
    blocks_epoch: "0",
    blocks_lifetime: "20",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "IAMX - OWN YOUR IDENTITY Pool",
    icon: "https://cdn.adapools.org/pool_logo/0c7173112ca61362d2ee05040973f2184968f2d4e769df86671c916b.png",
    pool_id: "0c7173112ca61362d2ee05040973f2184968f2d4e769df86671c916b",
    db_ticker: "IAMX",
    db_name: "IAMX - OWN YOUR IDENTITY Pool",
    db_url: "https://iamx.id/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23356263590595",
    stake_x_deleg: "15127113724.479",
    saturated: "0.33975675406075",
    luck_lifetime: "88",
    blocks_epoch: "14",
    blocks_lifetime: "467",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "20000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "35119f1ed0d4b0f91e704b88005ecae58dfb305e11bfb2f168b6aafa",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "109",
    blocks_epoch: "0",
    blocks_lifetime: "1151",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "1000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "223bb62965fb9f1b5f060e7d41c9b509ff39c757e1a2dce511b8f119",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "453",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "80cea8788c73a04d01e8cb33d70d1f59e10896578245e6bc35ed09ab",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "487",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "465facf0ff6365a976c671c5c74a2432c3bce6c791c6870130bcbcc4",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "496",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "28eb94ea6f359d10b04be39b5a936310ec4f907dfcd341f1b3c7af36",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "107",
    blocks_epoch: "0",
    blocks_lifetime: "640",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "5d048776c64ec06473dfd5136ad3918a47f00d8883053f373b691f99",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "744",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "0eed3810a405dfa4876de24ec16196107ff222523f05696308665cb0",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "103",
    blocks_epoch: "0",
    blocks_lifetime: "779",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "f2930e8988d0861b4bd83badf807df1ce84349971f2b99b5ac626643",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "738",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "80903e036a6fef6bd017f0d2b51cc22a2c6968624cf10e03898b371f",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "849",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "93e512ad4d4eec9fa66c1e60d81d9f4ddc8f500ee27a5a72949ba3ed",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "777",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "786cbd8d5a485835fbd2f6e9bc5c1f5bf72ded2c5f87c6372eb603d8",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "961",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "ba4d2838d574c29db7add69f7608d5b4e574c55c7211a72213004a73",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "105",
    blocks_epoch: "0",
    blocks_lifetime: "971",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "a9fb2641de1177743ab158188da46c52ba266f513da76022e0654be3",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "971",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "61500eea75ceebdf93d97296365d03eaef5d8e012ac98515ffd44f36",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "1019",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "5cba23f74af5636a2c4d69fe46be084abf862ecf2ca022e56cec0ea1",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "999",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "d574cff47d83db9fc97702d3f2b42d728d09f7c52fdf6e909c2b2b5c",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2925923",
    stake_x_deleg: "2925923",
    saturated: "4.256254846824e-8",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "1182",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "bf7cf0300e9ee6e0f7d9a7a842ad04357dd728a36585e8ee69aa8547",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1481480",
    stake_x_deleg: "1481480",
    saturated: "2.1550657452274e-8",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "1171",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "bd384c4b9e9d03c34d58b26c11769478d3a95aab70f594bef0d992bf",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3981480",
    stake_x_deleg: "3981480",
    saturated: "5.7917428269756e-8",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "2800",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "503fa739c7ef4fc84ca7e8bf617b4d55d665ecb0777ec2770af0fe0d",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1481480",
    stake_x_deleg: "1481480",
    saturated: "2.1550657452274e-8",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "2952",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "3ebd062968c015896c970220966736dc09415a1f6e8a2ba07fb7f9cb",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "102",
    blocks_epoch: "0",
    blocks_lifetime: "508",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "3baad1072772ec6516184faa0dc2e34d0e647b4fde0dca07d07f446e",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "97",
    blocks_epoch: "0",
    blocks_lifetime: "454",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "61fe899e9b4b24fbde1f4c84ae3e7c5d63dbdafc15d38dd3b934ad97",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "638",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "b05eeffac69bc4ba197bfb7ca814e366b320f12cfc4a0b2b57296608",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "104",
    blocks_epoch: "0",
    blocks_lifetime: "731",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "046139b9d5423a419eedd21a78902629d44bbbf723bf8cbbc4302ba9",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "106",
    blocks_epoch: "0",
    blocks_lifetime: "800",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "46179fe1365f666b813271cec904ac7f49cdb75f1c47989c32746cc6",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "724",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "b61cab024af033e9ae022a6ec2308c0a7c11e3eec5240ae1cf011b7f",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "929",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "214c95e2b803e0cc31e89357c84d9ff8d14650737b55541c75df9738",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "101",
    blocks_epoch: "0",
    blocks_lifetime: "932",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "c9d5e490f94b7d328bb86da7758d53a0c1b1929c0be9b0201095b7b1",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "0",
    stake_x_deleg: "false",
    saturated: "0",
    luck_lifetime: "98",
    blocks_epoch: "0",
    blocks_lifetime: "953",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "548091bafc62a537f09b76f8d537514068d1c9e74c93d1acad3203c5",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2925923",
    stake_x_deleg: "2925923",
    saturated: "4.256254846824e-8",
    luck_lifetime: "99",
    blocks_epoch: "0",
    blocks_lifetime: "1145",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "d6b4480e5dc5dbedfa703d188646c8c7c0191f7eae756911ceba20e2",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18025802643323",
    stake_x_deleg: "18025802643323",
    saturated: "0.26221609341236",
    luck_lifetime: "0",
    blocks_epoch: "5",
    blocks_lifetime: "31",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite D",
    icon: "https://cdn.adapools.org/pool_logo/a374a14d2fa809b5e999a474b5d085b10be2fc95e6e82da045d9e8f6.png",
    pool_id: "a374a14d2fa809b5e999a474b5d085b10be2fc95e6e82da045d9e8f6",
    db_ticker: "NUFID",
    db_name: "Nu.Fi &amp; AdaLite D",
    db_url: "https://nu.fi",
    roa: "3.658",
    roa_lifetime: "4.534",
    total_stake: "54716115262249",
    stake_x_deleg: "18238705087416",
    saturated: "0.79593936950607",
    luck_lifetime: "99",
    blocks_epoch: "49",
    blocks_lifetime: "4160",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite E",
    icon: "https://cdn.adapools.org/pool_logo/49f23c86547526bbd0c9320d3704542c84472f426b923a3e429392eb.png",
    pool_id: "49f23c86547526bbd0c9320d3704542c84472f426b923a3e429392eb",
    db_ticker: "NUFIE",
    db_name: "Nu.Fi &amp; AdaLite E",
    db_url: "https://nu.fi",
    roa: "4.116",
    roa_lifetime: "4.486",
    total_stake: "54912114205001",
    stake_x_deleg: "18304038068334",
    saturated: "0.79879050895868",
    luck_lifetime: "98",
    blocks_epoch: "32",
    blocks_lifetime: "4139",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite G",
    icon: "https://cdn.adapools.org/pool_logo/a2d42d873250321acca2d7cc7a5465f9060892bdbb645b4dedb28125.png",
    pool_id: "a2d42d873250321acca2d7cc7a5465f9060892bdbb645b4dedb28125",
    db_ticker: "NUFIG",
    db_name: "Nu.Fi &amp; AdaLite G",
    db_url: "https://nu.fi",
    roa: "3.271",
    roa_lifetime: "4.522",
    total_stake: "54943866718373",
    stake_x_deleg: "18314622239458",
    saturated: "0.79925240350935",
    luck_lifetime: "99",
    blocks_epoch: "50",
    blocks_lifetime: "4178",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "New One Stake Pool",
    icon: "0",
    pool_id: "e9a6817cf8fa67a0ce5d4d5d41bc155a30eb2d8f163869ce2abfca5a",
    db_ticker: "NOSP",
    db_name: "New One Stake Pool",
    db_url: "https://twitter.com/Rd7hrujn2Xv5FS2",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3836294132226",
    stake_x_deleg: "959073533056.5",
    saturated: "0.055805451798046",
    luck_lifetime: "85",
    blocks_epoch: "1",
    blocks_lifetime: "71",
    tax_ratio: "0.9999",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "HRDIB",
    icon: "0",
    pool_id: "fec57159acb5f5ee9ef2c64dfbf656b1d3eb094b4c6891cb8bd60790",
    db_ticker: "HRDIB",
    db_name: "HRDIB",
    db_url: "https://twitter.com/harudaibab",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2185973190431",
    stake_x_deleg: "1092986595215.5",
    saturated: "0.031798714411826",
    luck_lifetime: "105",
    blocks_epoch: "3",
    blocks_lifetime: "12",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "CREST-Pool",
    icon: "0",
    pool_id: "20044bf1900d8419427fdd4791b3fed11ee4623b6709851ab41c6558",
    db_ticker: "CREST",
    db_name: "CREST-Pool",
    db_url: "https://github.com/crest9999/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4503336236907",
    stake_x_deleg: "1125834059226.8",
    saturated: "0.065508718736664",
    luck_lifetime: "101",
    blocks_epoch: "7",
    blocks_lifetime: "327",
    tax_ratio: "0.999",
    tax_fix: "340000000",
    pledge: "300000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "lilystake",
    icon: "0",
    pool_id: "b2753f0f7c707063180d579d013e81b04086a33d2d6c9b798e2ce505",
    db_ticker: "LILY",
    db_name: "lilystake",
    db_url: "https://lilystake.xyz",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3588348299001",
    stake_x_deleg: "1196116099667",
    saturated: "0.052198656081229",
    luck_lifetime: "96",
    blocks_epoch: "0",
    blocks_lifetime: "105",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Diamond",
    icon: "0",
    pool_id: "fc5cd04719c2a32eb6cb560d13d58b437d49e3bc8c61b1856e91fd35",
    db_ticker: "PIVT",
    db_name: "Diamond",
    db_url: "https://twitter.com/Diamond25758632",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4550729026316",
    stake_x_deleg: "1516909675438.7",
    saturated: "0.066198127821",
    luck_lifetime: "99",
    blocks_epoch: "4",
    blocks_lifetime: "98",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 2",
    icon: "0",
    pool_id: "6feba2d1c9ff8b6f216259fc8824e8803202f882e33a07b6a235c5ac",
    db_ticker: "BD2",
    db_name: "Blockdaemon  Stake Pool 2",
    db_url: "https://blockdaemon.com/",
    roa: "3.409",
    roa_lifetime: "3.607",
    total_stake: "56257967126746",
    stake_x_deleg: "18752655708915",
    saturated: "0.81836823886233",
    luck_lifetime: "89",
    blocks_epoch: "47",
    blocks_lifetime: "1833",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Yuri-kun Pool",
    icon: "0",
    pool_id: "948e7da731887b371c16b5dbb70e44f2c94502d88461900ac0a52ef6",
    db_ticker: "YURI",
    db_name: "Yuri-kun Pool",
    db_url: "https://yuriyuriyuri.xyz/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "2945875649274",
    stake_x_deleg: "1472937824637",
    saturated: "0.04285279383758",
    luck_lifetime: "87",
    blocks_epoch: "7",
    blocks_lifetime: "13",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "neighborlypool",
    icon: "0",
    pool_id: "675ed63f7d0d1a0bf647093111a1f47bda3041fba437836f3a322f3c",
    db_ticker: "NEIGH",
    db_name: "neighborlypool",
    db_url: "https://neighborlypool.xyz",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3009010508362",
    stake_x_deleg: "1504505254181",
    saturated: "0.043771198217999",
    luck_lifetime: "87",
    blocks_epoch: "1",
    blocks_lifetime: "81",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "stakepool for kanayama",
    icon: "0",
    pool_id: "922bd702ed15695e13f6bb8d09a2e923de41473716c4ad8340a1990e",
    db_ticker: "KNY12",
    db_name: "stakepool for kanayama",
    db_url: "https://kanayama12.xyz",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "3533800876156",
    stake_x_deleg: "1766900438078",
    saturated: "0.051405170631114",
    luck_lifetime: "101",
    blocks_epoch: "6",
    blocks_lifetime: "584",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 3",
    icon: "0",
    pool_id: "4a5571be1cb000df524350ef170f73cc0c0404155350dd0f71e4d130",
    db_ticker: "BD3",
    db_name: "Blockdaemon  Stake Pool 3",
    db_url: "https://blockdaemon.com/",
    roa: "3.871",
    roa_lifetime: "3.675",
    total_stake: "57387037369907",
    stake_x_deleg: "19129012456636",
    saturated: "0.83479249437028",
    luck_lifetime: "93",
    blocks_epoch: "49",
    blocks_lifetime: "726",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Nu.Fi &amp; AdaLite H",
    icon: "https://cdn.adapools.org/pool_logo/266a21992f779be68c1476fab8e9bf1015e7b4c428ee8c3b899f65dd.png",
    pool_id: "266a21992f779be68c1476fab8e9bf1015e7b4c428ee8c3b899f65dd",
    db_ticker: "NUFIH",
    db_name: "Nu.Fi &amp; AdaLite H",
    db_url: "https://nu.fi",
    roa: "3.353",
    roa_lifetime: "4.032",
    total_stake: "63520669361160",
    stake_x_deleg: "21173556453720",
    saturated: "0.92401664993216",
    luck_lifetime: "97",
    blocks_epoch: "52",
    blocks_lifetime: "4703",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "PONTA Pool",
    icon: "0",
    pool_id: "bd87416177933138a533c62b205a98598c91da3ebfb8d6826c336b8e",
    db_ticker: "PONTA",
    db_name: "PONTA Pool",
    db_url: "https://twitter.com/LumpAHV8Lx1LGtU",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23964936039984",
    stake_x_deleg: "1997078003332",
    saturated: "0.34861093464869",
    luck_lifetime: "96",
    blocks_epoch: "19",
    blocks_lifetime: "841",
    tax_ratio: "0.9999",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Blockdaemon  Stake Pool 6",
    icon: "0",
    pool_id: "da482311081902f95d08bb05d878126904a1e133dd06901e05e652ce",
    db_ticker: "BD6",
    db_name: "Blockdaemon  Stake Pool 6",
    db_url: "https://blockdaemon.com/",
    roa: "1.694",
    roa_lifetime: "3.383",
    total_stake: "60415516275154",
    stake_x_deleg: "20138505425051",
    saturated: "0.87884689367936",
    luck_lifetime: "100",
    blocks_epoch: "47",
    blocks_lifetime: "186",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "200000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Grandiose Charcoal Nobel",
    icon: "0",
    pool_id: "4594f67c08f0007d1fac243169624b710375cb834bbe81f6b4d79f32",
    db_ticker: "GCN",
    db_name: "Grandiose Charcoal Nobel",
    db_url: "",
    roa: "3.672",
    roa_lifetime: "3.804",
    total_stake: "63888351916906",
    stake_x_deleg: "21296117305635",
    saturated: "0.92936522082752",
    luck_lifetime: "98",
    blocks_epoch: "50",
    blocks_lifetime: "916",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Optimistic Olive Hypatia",
    icon: "0",
    pool_id: "37e0b7c9c58753b14707dd34267ebbd50917e9b80d4f76da07e0e1b8",
    db_ticker: "OOH",
    db_name: "Optimistic Olive Hypatia",
    db_url: "",
    roa: "3.609",
    roa_lifetime: "3.447",
    total_stake: "64000130498310",
    stake_x_deleg: "21333376832770",
    saturated: "0.9309912312484",
    luck_lifetime: "89",
    blocks_epoch: "47",
    blocks_lifetime: "851",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wacky Gold Maxwell",
    icon: "0",
    pool_id: "365a5a9c59aa3971d96511a5bce24c4598849d0d3c2d61217dc0b488",
    db_ticker: "WGM",
    db_name: "Wacky Gold Maxwell",
    db_url: "",
    roa: "3.669",
    roa_lifetime: "3.63",
    total_stake: "63792987903339",
    stake_x_deleg: "21264329301113",
    saturated: "0.92797798833727",
    luck_lifetime: "94",
    blocks_epoch: "43",
    blocks_lifetime: "884",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Genius Yield 4",
    icon: "https://cdn.adapools.org/pool_logo/40efc97d08315ff9be5898f24af5b8b120669b43027662c3499dd785.png",
    pool_id: "40efc97d08315ff9be5898f24af5b8b120669b43027662c3499dd785",
    db_ticker: "GENS4",
    db_name: "Genius Yield 4",
    db_url: "https://www.geniusyield.co",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "1879985927404",
    stake_x_deleg: "3224675690.2298",
    saturated: "0.027347606944797",
    luck_lifetime: "18",
    blocks_epoch: "0",
    blocks_lifetime: "65",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "10000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 48",
    icon: "0",
    pool_id: "2352f8c7d0b9f0bf5b342c135d5b25c910491deec1440bd9d27799a6",
    db_ticker: "BNP",
    db_name: "Binance Staking - 48",
    db_url: "https://www.binance.com",
    roa: "3.452",
    roa_lifetime: "4.261",
    total_stake: "64329725408766",
    stake_x_deleg: "21443241802922",
    saturated: "0.93578575227687",
    luck_lifetime: "98",
    blocks_epoch: "52",
    blocks_lifetime: "5848",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 51",
    icon: "0",
    pool_id: "40ff71258d2c73da9e2a5ee3abb9be256e745e7f74c31eb8d4be5299",
    db_ticker: "BNP",
    db_name: "Binance Staking - 51",
    db_url: "https://www.binance.com",
    roa: "3.413",
    roa_lifetime: "4.189",
    total_stake: "64314354537957",
    stake_x_deleg: "21438118179319",
    saturated: "0.93556215670248",
    luck_lifetime: "96",
    blocks_epoch: "55",
    blocks_lifetime: "5746",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 59",
    icon: "0",
    pool_id: "72583cf41012446cd1ca839c10049ecd65d31664b11e63d286041d61",
    db_ticker: "BNP",
    db_name: "Binance Staking - 59",
    db_url: "https://www.binance.com",
    roa: "3.623",
    roa_lifetime: "4.326",
    total_stake: "64327699021968",
    stake_x_deleg: "21442566340656",
    saturated: "0.93575627501916",
    luck_lifetime: "100",
    blocks_epoch: "45",
    blocks_lifetime: "5951",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 60",
    icon: "0",
    pool_id: "f99151a5fd003b8bb92ba2af02ed6efec2923b520189cf89e1c264aa",
    db_ticker: "BNP",
    db_name: "Binance Staking - 60",
    db_url: "https://www.binance.com",
    roa: "3.976",
    roa_lifetime: "4.207",
    total_stake: "64341137424078",
    stake_x_deleg: "21447045808026",
    saturated: "0.93595175953504",
    luck_lifetime: "98",
    blocks_epoch: "43",
    blocks_lifetime: "5445",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 64",
    icon: "0",
    pool_id: "cf2b0acb72eecb56a05ba6b79922d4b47fedfb93b168a2eb1e5fa260",
    db_ticker: "BNP",
    db_name: "Binance Staking - 64",
    db_url: "https://www.binance.com",
    roa: "3.701",
    roa_lifetime: "4.173",
    total_stake: "64326743673746",
    stake_x_deleg: "21442247891249",
    saturated: "0.93574237784723",
    luck_lifetime: "97",
    blocks_epoch: "52",
    blocks_lifetime: "5397",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 67",
    icon: "0",
    pool_id: "533a710c19bdddc144ab19900d37a81488088259981a5d4885031ce3",
    db_ticker: "BNP",
    db_name: "Binance Staking - 67",
    db_url: "https://www.binance.com",
    roa: "3.927",
    roa_lifetime: "4.215",
    total_stake: "64347136617841",
    stake_x_deleg: "21449045539280",
    saturated: "0.9360390280569",
    luck_lifetime: "98",
    blocks_epoch: "50",
    blocks_lifetime: "5474",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 63",
    icon: "0",
    pool_id: "c51e4e6cb25065ad0740249c66eafa11e186120602aff1cdca53110a",
    db_ticker: "BNP",
    db_name: "Binance Staking - 63",
    db_url: "https://www.binance.com",
    roa: "3.954",
    roa_lifetime: "4.168",
    total_stake: "64349165954089",
    stake_x_deleg: "21449721984696",
    saturated: "0.9360685482194",
    luck_lifetime: "97",
    blocks_epoch: "41",
    blocks_lifetime: "5413",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "MORH",
    icon: "0",
    pool_id: "87dc693d71a7a9f33c69728d1179a04ab520fe14d682d711acfd9f24",
    db_ticker: "MORH",
    db_name: "MORH",
    db_url: "https://myadapoolnamerocks.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "18262155710570",
    stake_x_deleg: "9131077855285",
    saturated: "0.26565425254379",
    luck_lifetime: "96",
    blocks_epoch: "18",
    blocks_lifetime: "773",
    tax_ratio: "0.99",
    tax_fix: "340000000",
    pledge: "200000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "168b1fb18159fc3b126f9690924742fcfae2ecad94e13d76a8244ea5",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "4743162511629",
    stake_x_deleg: "4743162511629",
    saturated: "0.068997401604195",
    luck_lifetime: "140",
    blocks_epoch: "40",
    blocks_lifetime: "1929",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "9caffdb0feeb61d9a57484b8c153a50a09977b5b303af7171ed2ba9a",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25094606377056",
    stake_x_deleg: "5018921275411.2",
    saturated: "0.36504391954773",
    luck_lifetime: "92",
    blocks_epoch: "29",
    blocks_lifetime: "372",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "27ca1ffda65d1ef326c9e6a717210c98b10a0e17910c0ab6d9eeb6fb",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46469415558968",
    stake_x_deleg: "23234707779484",
    saturated: "0.67597703426214",
    luck_lifetime: "0",
    blocks_epoch: "29",
    blocks_lifetime: "43",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-12",
    icon: "0",
    pool_id: "f4a10d6f82ca3a874ff2e556fd9a163fa99e686fe387eda7bb4a8d86",
    db_ticker: "WAV12",
    db_name: "WAVE-12",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "48410505860660",
    stake_x_deleg: "12102626465165",
    saturated: "0.70421350871721",
    luck_lifetime: "99",
    blocks_epoch: "39",
    blocks_lifetime: "808",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "45000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Wave-9",
    icon: "0",
    pool_id: "8d40e1d5d817183a0ca2613f7d998a6ac3962162adaf3d5176af7cbb",
    db_ticker: "WAV9",
    db_name: "Wave-9",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "6631168844096",
    stake_x_deleg: "6631168844096",
    saturated: "0.096461679042108",
    luck_lifetime: "103",
    blocks_epoch: "18",
    blocks_lifetime: "3055",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "RANMA pool",
    icon: "0",
    pool_id: "032cd96779710e1bdc766de1896277896be4d3b9aa86d4caccd22c8b",
    db_ticker: "RANMA",
    db_name: "RANMA pool",
    db_url: "https://twitter.com/RANMA83054020",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "22333560176423",
    stake_x_deleg: "7444520058807.7",
    saturated: "0.32487978578977",
    luck_lifetime: "106",
    blocks_epoch: "16",
    blocks_lifetime: "441",
    tax_ratio: "0.9999",
    tax_fix: "340000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 14",
    icon: "0",
    pool_id: "f92e7cf95462d73b0e9927d09e87c1dcf761d9ee14c364fce0376e40",
    db_ticker: "ETO14",
    db_name: "eToro Pool 14",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "9528519844972",
    stake_x_deleg: "9528519844972",
    saturated: "0.13860859897277",
    luck_lifetime: "102",
    blocks_epoch: "13",
    blocks_lifetime: "272",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tall Zaffre Darwin",
    icon: "0",
    pool_id: "8c289ee3b1b591a95c18ffce66957d1f07115afdf939c2a9b441fc22",
    db_ticker: "TZD",
    db_name: "Tall Zaffre Darwin",
    db_url: "",
    roa: "3.695",
    roa_lifetime: "3.808",
    total_stake: "54286876498029",
    stake_x_deleg: "27143438249014",
    saturated: "0.78969535840032",
    luck_lifetime: "99",
    blocks_epoch: "29",
    blocks_lifetime: "788",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBowl5",
    icon: "0",
    pool_id: "315787d387683291b48e285069f95010fc1507246f8c80423f51e1ed",
    db_ticker: "STBL5",
    db_name: "StakeBowl5",
    db_url: "https://www.stakebowl.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "40191072755328",
    stake_x_deleg: "10047768188832",
    saturated: "0.58464781272071",
    luck_lifetime: "92",
    blocks_epoch: "27",
    blocks_lifetime: "1818",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ubiquitous Teal Aslan",
    icon: "0",
    pool_id: "837a3cf8c96e97bed8e8be6deb390144b46171e3c843b75575d0f788",
    db_ticker: "UTA",
    db_name: "Ubiquitous Teal Aslan",
    db_url: "",
    roa: "3.121",
    roa_lifetime: "3.512",
    total_stake: "57161977019623",
    stake_x_deleg: "28580988509812",
    saturated: "0.83151860709873",
    luck_lifetime: "91",
    blocks_epoch: "31",
    blocks_lifetime: "774",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "QQQ2",
    icon: "0",
    pool_id: "8c92ae1a56f6158fea69ffd931b5ba832b9c80a2638ca8f22e1a4beb",
    db_ticker: "QQQ2",
    db_name: "QQQ2",
    db_url: "https://github.com/desayuno11",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "5683739403466",
    stake_x_deleg: "1136747880693.2",
    saturated: "0.082679699308857",
    luck_lifetime: "99",
    blocks_epoch: "4",
    blocks_lifetime: "616",
    tax_ratio: "0.9999",
    tax_fix: "9999000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Thoughtful Iris Albattani",
    icon: "0",
    pool_id: "a5b36528fbbb2709e1b29fbdc3816399ef2bd1c916efa416aa325f1a",
    db_ticker: "TIA",
    db_name: "Thoughtful Iris Albattani",
    db_url: "",
    roa: "3.865",
    roa_lifetime: "3.887",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "100",
    blocks_epoch: "46",
    blocks_lifetime: "930",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Curly Viridian Moore",
    icon: "0",
    pool_id: "2885a48f88db36756708fd2f7a30117962dad843add43a313b5ebfdf",
    db_ticker: "CVM",
    db_name: "Curly Viridian Moore",
    db_url: "",
    roa: "3.693",
    roa_lifetime: "3.6",
    total_stake: "63095348116466",
    stake_x_deleg: "31547674058233",
    saturated: "0.91782962584032",
    luck_lifetime: "93",
    blocks_epoch: "46",
    blocks_lifetime: "863",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fervent Vermilion Galileo",
    icon: "0",
    pool_id: "661d04fdb7aa2c2476bbc6827d11ec22ec04ecb953495ade0f7b2352",
    db_ticker: "FVG",
    db_name: "Fervent Vermilion Galileo",
    db_url: "",
    roa: "3.459",
    roa_lifetime: "3.527",
    total_stake: "63588573258040",
    stake_x_deleg: "31794286629020",
    saturated: "0.92500442811433",
    luck_lifetime: "91",
    blocks_epoch: "55",
    blocks_lifetime: "869",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Admiring Red Hertz",
    icon: "0",
    pool_id: "a9807616077f17a66dc12c562bfc8dbc1a0b9ad1c96591fd56ffafb5",
    db_ticker: "ARH",
    db_name: "Admiring Red Hertz",
    db_url: "",
    roa: "3.577",
    roa_lifetime: "3.556",
    total_stake: "64124472114448",
    stake_x_deleg: "32062236057224",
    saturated: "0.93279999247127",
    luck_lifetime: "93",
    blocks_epoch: "52",
    blocks_lifetime: "283",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Thin Blue Galileo",
    icon: "0",
    pool_id: "cb40e0cff388865448b04c364ff941ccf576bb2c7f8c804bdc153769",
    db_ticker: "TBG",
    db_name: "Thin Blue Galileo",
    db_url: "",
    roa: "0.345",
    roa_lifetime: "2.071",
    total_stake: "65639215950654",
    stake_x_deleg: "32819607975327",
    saturated: "0.95483452924667",
    luck_lifetime: "65",
    blocks_epoch: "49",
    blocks_lifetime: "24",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Futuristic Cadet Swartz",
    icon: "0",
    pool_id: "b900dfcf894c473620bf379f8c63aa300d8c8842a94a758758bdcd33",
    db_ticker: "FCS",
    db_name: "Futuristic Cadet Swartz",
    db_url: "",
    roa: "3.519",
    roa_lifetime: "3.565",
    total_stake: "63514284283671",
    stake_x_deleg: "31757142141836",
    saturated: "0.92392376807228",
    luck_lifetime: "92",
    blocks_epoch: "27",
    blocks_lifetime: "850",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tidy Lilac Kepler",
    icon: "0",
    pool_id: "fe59e220d8036bd7533bf393e26abde56ec7d49fa632e57bf696d134",
    db_ticker: "TLK",
    db_name: "Tidy Lilac Kepler",
    db_url: "",
    roa: "3.423",
    roa_lifetime: "3.713",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "96",
    blocks_epoch: "45",
    blocks_lifetime: "659",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Responsible Rose Coanda",
    icon: "0",
    pool_id: "b619d366a0acc2e5a8e0f8e373d6eb926a01811f05a381aaa7a8564c",
    db_ticker: "RRC",
    db_name: "Responsible Rose Coanda",
    db_url: "",
    roa: "3.665",
    roa_lifetime: "3.687",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "95",
    blocks_epoch: "37",
    blocks_lifetime: "890",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Abundant Cadet Newton",
    icon: "0",
    pool_id: "cb3e1428aa4d240ca528edfd992d6509b47335849a91f0d70e5b0ffd",
    db_ticker: "ACN",
    db_name: "Abundant Cadet Newton",
    db_url: "",
    roa: "3.184",
    roa_lifetime: "3.547",
    total_stake: "63946011640857",
    stake_x_deleg: "31973005820428",
    saturated: "0.93020398001405",
    luck_lifetime: "91",
    blocks_epoch: "38",
    blocks_lifetime: "873",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fancy Mango Dirac",
    icon: "0",
    pool_id: "9ca4378a7ee308d910efd7a611a1df2181314c25723ff4cfa855664c",
    db_ticker: "FMD",
    db_name: "Fancy Mango Dirac",
    db_url: "",
    roa: "3.323",
    roa_lifetime: "3.55",
    total_stake: "63908576423703",
    stake_x_deleg: "31954288211852",
    saturated: "0.92965942082895",
    luck_lifetime: "91",
    blocks_epoch: "37",
    blocks_lifetime: "861",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Lucky Azure Gauss",
    icon: "0",
    pool_id: "483b0d11a79c52d088f93e8a0930999c1bb17f2b04b26c7167d9bb0e",
    db_ticker: "LAG",
    db_name: "Lucky Azure Gauss",
    db_url: "",
    roa: "3.773",
    roa_lifetime: "3.6",
    total_stake: "63954971687995",
    stake_x_deleg: "31977485843998",
    saturated: "0.93033431920636",
    luck_lifetime: "93",
    blocks_epoch: "41",
    blocks_lifetime: "876",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Relaxed Mustard Hopper",
    icon: "0",
    pool_id: "f747045bf6584b0e92d4b0bbdb399f8c9c4c082ff239c3288d7f0280",
    db_ticker: "RMH",
    db_name: "Relaxed Mustard Hopper",
    db_url: "",
    roa: "3.784",
    roa_lifetime: "3.741",
    total_stake: "63791199997756",
    stake_x_deleg: "31895599998878",
    saturated: "0.92795198019623",
    luck_lifetime: "96",
    blocks_epoch: "52",
    blocks_lifetime: "914",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Tangible Opal Wozniak",
    icon: "0",
    pool_id: "65f3f975b5d6e8fabff2789e9d48bad257e3affedc2a396aa458c7eb",
    db_ticker: "TOW",
    db_name: "Tangible Opal Wozniak",
    db_url: "",
    roa: "3.436",
    roa_lifetime: "3.557",
    total_stake: "63778716513086",
    stake_x_deleg: "31889358256543",
    saturated: "0.92777038658583",
    luck_lifetime: "92",
    blocks_epoch: "30",
    blocks_lifetime: "855",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Gleaming Cyan Davinci",
    icon: "0",
    pool_id: "3a203ec1a8bae5098be953c3759e9fae42eea3d5714293500c6b3013",
    db_ticker: "GCD",
    db_name: "Gleaming Cyan Davinci",
    db_url: "",
    roa: "3.851",
    roa_lifetime: "3.831",
    total_stake: "62866730784204",
    stake_x_deleg: "31433365392102",
    saturated: "0.91450399618941",
    luck_lifetime: "99",
    blocks_epoch: "42",
    blocks_lifetime: "913",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Fancy Burgundy Archimedes",
    icon: "0",
    pool_id: "d66b1218d56cb0c9c3937207b3ae61bbff21ae212ae791722062f0e1",
    db_ticker: "FBA",
    db_name: "Fancy Burgundy Archimedes",
    db_url: "",
    roa: "3.844",
    roa_lifetime: "3.786",
    total_stake: "63538909148182",
    stake_x_deleg: "31769454574091",
    saturated: "0.92428197879391",
    luck_lifetime: "98",
    blocks_epoch: "39",
    blocks_lifetime: "911",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Upbeat Beige Euler",
    icon: "0",
    pool_id: "92bda83eb105d569b23cb6fa1d407dd42de0d2d7e6562718b7ac5c02",
    db_ticker: "UBE",
    db_name: "Upbeat Beige Euler",
    db_url: "",
    roa: "3.667",
    roa_lifetime: "3.531",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "91",
    blocks_epoch: "37",
    blocks_lifetime: "866",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Amazing Vanilla Morse",
    icon: "0",
    pool_id: "16f6e9a767dbb9400d3dbe9f7374eef7c706bda870592a7dabd47a75",
    db_ticker: "AVM",
    db_name: "Amazing Vanilla Morse",
    db_url: "",
    roa: "3.661",
    roa_lifetime: "3.508",
    total_stake: "63934224861583",
    stake_x_deleg: "31967112430792",
    saturated: "0.93003252117383",
    luck_lifetime: "90",
    blocks_epoch: "34",
    blocks_lifetime: "857",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Periodic Sinopia Hopper",
    icon: "0",
    pool_id: "49c24284e61ad58c6e59e865c74e9b0a0a36654eb2091ad846e1c4ec",
    db_ticker: "PSH",
    db_name: "Periodic Sinopia Hopper",
    db_url: "",
    roa: "3.685",
    roa_lifetime: "3.605",
    total_stake: "62864604092200",
    stake_x_deleg: "31432302046100",
    saturated: "0.91447305982112",
    luck_lifetime: "93",
    blocks_epoch: "44",
    blocks_lifetime: "868",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Keen Lemon Fermi",
    icon: "0",
    pool_id: "37303e39c681371e158fdd7268f4546d581114dc3fbcc87cbc6856f0",
    db_ticker: "KLF",
    db_name: "Keen Lemon Fermi",
    db_url: "",
    roa: "3.788",
    roa_lifetime: "3.617",
    total_stake: "62948980035033",
    stake_x_deleg: "31474490017516",
    saturated: "0.91570045205133",
    luck_lifetime: "93",
    blocks_epoch: "64",
    blocks_lifetime: "872",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Kind Orange Heisenberg",
    icon: "0",
    pool_id: "4fd4f6b6c164e0bf9b514f7facc6dcae87ed4eca857605da92990d35",
    db_ticker: "KOH",
    db_name: "Kind Orange Heisenberg",
    db_url: "",
    roa: "3.87",
    roa_lifetime: "3.743",
    total_stake: "61999391442925",
    stake_x_deleg: "30999695721462",
    saturated: "0.9018870637713",
    luck_lifetime: "96",
    blocks_epoch: "42",
    blocks_lifetime: "885",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Elegant Gold Heisenberg",
    icon: "0",
    pool_id: "061f4c7b5de2c1554690029e2e559272ff4dbcd3aecc02d91aceb5c7",
    db_ticker: "EGH",
    db_name: "Elegant Gold Heisenberg",
    db_url: "",
    roa: "3.619",
    roa_lifetime: "3.603",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "93",
    blocks_epoch: "38",
    blocks_lifetime: "837",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Rampant Violet Hawking",
    icon: "0",
    pool_id: "0322ccfd1f68a8a288057d64428123bb6efed9d6b5c9fe2864db7507",
    db_ticker: "RVH",
    db_name: "Rampant Violet Hawking",
    db_url: "",
    roa: "3.413",
    roa_lifetime: "3.633",
    total_stake: "64000000000000",
    stake_x_deleg: "32000000000000",
    saturated: "0.93098933292755",
    luck_lifetime: "95",
    blocks_epoch: "35",
    blocks_lifetime: "289",
    tax_ratio: "0.05",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "QQQ",
    icon: "0",
    pool_id: "571b0be3f126255004d9828f37f11026551b9ca391c4aed3b05d6a75",
    db_ticker: "QQQ",
    db_name: "QQQ",
    db_url: "https://github.com/desayuno11",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "28500248880581",
    stake_x_deleg: "1357154708599.1",
    saturated: "0.41458480771252",
    luck_lifetime: "96",
    blocks_epoch: "28",
    blocks_lifetime: "2254",
    tax_ratio: "0.9999",
    tax_fix: "9999000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 46",
    icon: "0",
    pool_id: "9b9890b811d67ba9e69810581e4fc119ca0c2354adb7cd6ff564b0a3",
    db_ticker: "BNP",
    db_name: "Binance Staking - 46",
    db_url: "https://www.binance.com",
    roa: "3.79",
    roa_lifetime: "4.231",
    total_stake: "64339575833571",
    stake_x_deleg: "32169787916786",
    saturated: "0.9359290435334",
    luck_lifetime: "97",
    blocks_epoch: "60",
    blocks_lifetime: "5803",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 61",
    icon: "0",
    pool_id: "d2b32bb469f6e8bcd6e1e0ba818716d317e3f26d0a93f317b08fdced",
    db_ticker: "BNP",
    db_name: "Binance Staking - 61",
    db_url: "https://www.binance.com",
    roa: "3.858",
    roa_lifetime: "4.219",
    total_stake: "64337806293731",
    stake_x_deleg: "32168903146866",
    saturated: "0.93590330255348",
    luck_lifetime: "98",
    blocks_epoch: "47",
    blocks_lifetime: "5456",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 66",
    icon: "0",
    pool_id: "c1d314a78a43f3d5baa9c2e4105015384211416f220af4a24a8ea3ee",
    db_ticker: "BNP",
    db_name: "Binance Staking - 66",
    db_url: "https://www.binance.com",
    roa: "3.507",
    roa_lifetime: "4.268",
    total_stake: "64321162949953",
    stake_x_deleg: "32160581474976",
    saturated: "0.93566119668595",
    luck_lifetime: "99",
    blocks_epoch: "56",
    blocks_lifetime: "5518",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Binance Staking - 69",
    icon: "0",
    pool_id: "e645c034522f32522bf1c64a7f46128c562880abc26abe5f65f25a84",
    db_ticker: "BNP",
    db_name: "Binance Staking - 69",
    db_url: "https://www.binance.com",
    roa: "3.667",
    roa_lifetime: "4.165",
    total_stake: "64319103911123",
    stake_x_deleg: "32159551955562",
    saturated: "0.93563124444866",
    luck_lifetime: "97",
    blocks_epoch: "50",
    blocks_lifetime: "5391",
    tax_ratio: "0.06",
    tax_fix: "345000000",
    pledge: "2000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WizOz",
    icon: "0",
    pool_id: "6622bb2a073669662fed0eff7b22539af53b7ff9610901659d0a1e07",
    db_ticker: "WizOz",
    db_name: "WizOz",
    db_url: "https://git.io/JU5Hg",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "23890473139556",
    stake_x_deleg: "3412924734222.3",
    saturated: "0.34752774455498",
    luck_lifetime: "100",
    blocks_epoch: "17",
    blocks_lifetime: "2130",
    tax_ratio: "0.995",
    tax_fix: "9000000000",
    pledge: "5000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private A",
    icon: "0",
    pool_id: "eb7d911eb4c0ca066badadab42d80d5b59a5ce996389523a26edb64a",
    db_ticker: "EMURA",
    db_name: "Emurgo Private A",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50142334528876",
    stake_x_deleg: "12535583632219",
    saturated: "0.72940591522607",
    luck_lifetime: "98",
    blocks_epoch: "37",
    blocks_lifetime: "4754",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "BitcoinSuisse02",
    icon: "0",
    pool_id: "8e6f0aa0582af4b90e830926059027ee1759240bbd2b7e137d8721f9",
    db_ticker: "BTCS",
    db_name: "BitcoinSuisse02",
    db_url: "https://bitcoinsuisse.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25150216181112",
    stake_x_deleg: "12575108090556",
    saturated: "0.36585285914746",
    luck_lifetime: "95",
    blocks_epoch: "16",
    blocks_lifetime: "944",
    tax_ratio: "1",
    tax_fix: "345000000",
    pledge: "100000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private G",
    icon: "0",
    pool_id: "875acc163537a9629bfafcf64703077ef91b63f395c5402ed65fa67b",
    db_ticker: "EMURG",
    db_name: "Emurgo Private G",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "27188119281901",
    stake_x_deleg: "13594059640950",
    saturated: "0.39549764115331",
    luck_lifetime: "96",
    blocks_epoch: "15",
    blocks_lifetime: "2119",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "StakeBowl4",
    icon: "0",
    pool_id: "ec826010110c7b8123a6e635afb96815c7d08b9a6f2c57b44f70a8b7",
    db_ticker: "STBL4",
    db_name: "StakeBowl4",
    db_url: "https://www.stakebowl.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "60105035582637",
    stake_x_deleg: "15026258895659",
    saturated: "0.87433042160416",
    luck_lifetime: "93",
    blocks_epoch: "44",
    blocks_lifetime: "2641",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private E",
    icon: "0",
    pool_id: "1634c08e606f0f68bed8d88ba0ab87980994a5872ce68afdd0064e40",
    db_ticker: "EMURE",
    db_name: "Emurgo Private E",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50378541830443",
    stake_x_deleg: "16792847276814",
    saturated: "0.73284195394667",
    luck_lifetime: "97",
    blocks_epoch: "49",
    blocks_lifetime: "3971",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 5",
    icon: "0",
    pool_id: "689a60c22ae02ece9405e0cd5fefdb7ceffc0a52c61c42d9955a9b1b",
    db_ticker: "ETO5",
    db_name: "eToro Pool 5",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "61471314564151",
    stake_x_deleg: "20490438188050",
    saturated: "0.89420528344154",
    luck_lifetime: "97",
    blocks_epoch: "35",
    blocks_lifetime: "5432",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 7",
    icon: "0",
    pool_id: "b83ec9c4136a2d3cd15cbe691afa7f2437bce3d1240ac6f2c5d0e159",
    db_ticker: "ETO7",
    db_name: "eToro Pool 7",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "19934868158462",
    stake_x_deleg: "19934868158462",
    saturated: "0.28998671263821",
    luck_lifetime: "96",
    blocks_epoch: "13",
    blocks_lifetime: "3984",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private B",
    icon: "0",
    pool_id: "d854986d64cb440f9554576eee79542ddd86afa49008f9223da2549e",
    db_ticker: "EMURB",
    db_name: "Emurgo Private B",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50123577011225",
    stake_x_deleg: "25061788505612",
    saturated: "0.72913305508786",
    luck_lifetime: "96",
    blocks_epoch: "36",
    blocks_lifetime: "4643",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private C",
    icon: "0",
    pool_id: "45822f964089d86a48f9630e0187f26b4dbba9013ac7b1e04c443c82",
    db_ticker: "EMURC",
    db_name: "Emurgo Private C",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50136366288217",
    stake_x_deleg: "25068183144108",
    saturated: "0.72931909696998",
    luck_lifetime: "93",
    blocks_epoch: "39",
    blocks_lifetime: "4538",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Emurgo Private D",
    icon: "0",
    pool_id: "a1a13e86037a489911c0983887b675b859e7f499b88e91d8a01a05d8",
    db_ticker: "EMURD",
    db_name: "Emurgo Private D",
    db_url: "https://emurgo.io/",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "50145329840225",
    stake_x_deleg: "25072664920112",
    saturated: "0.72944948714661",
    luck_lifetime: "98",
    blocks_epoch: "33",
    blocks_lifetime: "4768",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "1000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 12",
    icon: "0",
    pool_id: "4b11b32e3a39fb417f5dddd9d058abdb2d1fdcd64360bee8928e05c2",
    db_ticker: "ETO12",
    db_name: "eToro Pool 12",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "25906653464983",
    stake_x_deleg: "25906653464983",
    saturated: "0.37685653168359",
    luck_lifetime: "97",
    blocks_epoch: "21",
    blocks_lifetime: "835",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "3407812f0f5b698329dd4db9b7748e7aa6078b5e5f74c0af318385e4",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "43281573026358",
    stake_x_deleg: "43281573026358",
    saturated: "0.62960441874788",
    luck_lifetime: "0",
    blocks_epoch: "27",
    blocks_lifetime: "45",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "f747208eb5e3b703b271ff373b4dc4ca643c026071e480689be320d9",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46407215063021",
    stake_x_deleg: "46407215063021",
    saturated: "0.6750722217898",
    luck_lifetime: "0",
    blocks_epoch: "25",
    blocks_lifetime: "35",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 3",
    icon: "0",
    pool_id: "2bf5a031b46b34c07937a769ff6f82b6a78a25c71022eaaf1f20eec9",
    db_ticker: "ETO3",
    db_name: "eToro Pool 3",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65000004590510",
    stake_x_deleg: "32500002295255",
    saturated: "0.94553610803136",
    luck_lifetime: "96",
    blocks_epoch: "48",
    blocks_lifetime: "7014",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 1",
    icon: "0",
    pool_id: "a3e71398e80739522e907c35b8f652064e5268e62e9b78a9603f6b98",
    db_ticker: "ETO1",
    db_name: "eToro Pool 1",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65000962107973",
    stake_x_deleg: "32500481053986",
    saturated: "0.94555003675861",
    luck_lifetime: "125",
    blocks_epoch: "65",
    blocks_lifetime: "11102",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 8",
    icon: "0",
    pool_id: "842dd6ff41539eb5a47a7915082458d8e7d592ae5e8eca23e26c7346",
    db_ticker: "ETO8",
    db_name: "eToro Pool 8",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "63100159569180",
    stake_x_deleg: "31550079784590",
    saturated: "0.91789961663958",
    luck_lifetime: "95",
    blocks_epoch: "48",
    blocks_lifetime: "4996",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "6334904a31086c287d916db04eefac1a82db84cc3c73aaf9a2a7d662",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "46841071436249",
    stake_x_deleg: "46841071436249",
    saturated: "0.68138340390696",
    luck_lifetime: "0",
    blocks_epoch: "28",
    blocks_lifetime: "40",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "WAVE-13",
    icon: "0",
    pool_id: "7c7b15fdb0fb983c32541a47dc89201f7d0b6b0f9cbae8099a5ac32b",
    db_ticker: "WAV13",
    db_name: "WAVE-13",
    db_url: "https://wavegp.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "45100007300619",
    stake_x_deleg: "45100007300619",
    saturated: "0.65605665174736",
    luck_lifetime: "0",
    blocks_epoch: "36",
    blocks_lifetime: "0",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "45000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "N/A",
    icon: "0",
    pool_id: "012abf0f0a65192fcba3e5e305fe13478f0cd58f271873b0f20ddb10",
    db_ticker: "N/A",
    db_name: "N/A",
    db_url: "N/A",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "57300563156362",
    stake_x_deleg: "57300563156362",
    saturated: "0.83353457920804",
    luck_lifetime: "0",
    blocks_epoch: "28",
    blocks_lifetime: "46",
    tax_ratio: "0.03",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "29db67496a0725cd1b3714e755c17b1f8c156d9be3d51237c0b2253f",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65448966289267",
    stake_x_deleg: "65448966289267",
    saturated: "0.95206702291316",
    luck_lifetime: "89",
    blocks_epoch: "46",
    blocks_lifetime: "2965",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "27814ffdea37e4b3ac8e412b8b2dfaba1f7105beaf2cb892b4ac6c14",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65576947903517",
    stake_x_deleg: "65576947903517",
    saturated: "0.95392873412688",
    luck_lifetime: "93",
    blocks_epoch: "44",
    blocks_lifetime: "3109",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "9e734b6c2263c0917bfc550e9c949f41afa3fe000377243bd29df399",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "64328594406327",
    stake_x_deleg: "64328594406327",
    saturated: "0.93576929991427",
    luck_lifetime: "98",
    blocks_epoch: "55",
    blocks_lifetime: "6110",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "a532904ca60e13e88437b58e7c6ff66b8d5e7ec8d3f4b9e4be7820ec",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "64328594406327",
    stake_x_deleg: "64328594406327",
    saturated: "0.93576929991427",
    luck_lifetime: "97",
    blocks_epoch: "53",
    blocks_lifetime: "6059",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Input Output Global (IOHK) - Private",
    icon: "0",
    pool_id: "4045672633587d62688c258870681cab49c0d09a6542614e9a271678",
    db_ticker: "IOGP",
    db_name: "Input Output Global (IOHK) - Private",
    db_url: "https://iohk.io",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "64328594406327",
    stake_x_deleg: "64328594406327",
    saturated: "0.93576929991427",
    luck_lifetime: "98",
    blocks_epoch: "48",
    blocks_lifetime: "6117",
    tax_ratio: "1",
    tax_fix: "500000000",
    pledge: "64000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "f51e13866bf3427d1a1e97ec3c6e22e662d3a030ac26812e111c94cf",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66424570665358",
    stake_x_deleg: "66424570665358",
    saturated: "0.9662588552147",
    luck_lifetime: "96",
    blocks_epoch: "53",
    blocks_lifetime: "4534",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "",
    icon: "0",
    pool_id: "4ea4b108b5a3cedd1abec6175e6f97a61520f64d234e555d231a440e",
    db_ticker: "",
    db_name: "",
    db_url: "",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "66632028043020",
    stake_x_deleg: "66632028043020",
    saturated: "0.96927667717783",
    luck_lifetime: "93",
    blocks_epoch: "57",
    blocks_lifetime: "4303",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "63000000000000",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 9",
    icon: "0",
    pool_id: "d679d78bbfefe7e03e05e7a42f9088075af0f15f501a631c67e64037",
    db_ticker: "ETO9",
    db_name: "eToro Pool 9",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "62826992887476",
    stake_x_deleg: "62826992887476",
    saturated: "0.91392594059618",
    luck_lifetime: "96",
    blocks_epoch: "44",
    blocks_lifetime: "5462",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 11",
    icon: "0",
    pool_id: "90ac71b92a4c29f7a9bbde83ba2e6e2c17b69e41db204a55e7a338ae",
    db_ticker: "ETO11",
    db_name: "eToro Pool 11",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "65000026557959",
    stake_x_deleg: "65000026557959",
    saturated: "0.94553642758543",
    luck_lifetime: "98",
    blocks_epoch: "55",
    blocks_lifetime: "3928",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "eToro Pool 10",
    icon: "0",
    pool_id: "2fd4fd5fbfe773a7105bf2e415c51f9943ded365855396e70ae3ca90",
    db_ticker: "ETO10",
    db_name: "eToro Pool 10",
    db_url: "https://etoro.com",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "64997328536408",
    stake_x_deleg: "64997328536408",
    saturated: "0.94549718025287",
    luck_lifetime: "96",
    blocks_epoch: "61",
    blocks_lifetime: "3884",
    tax_ratio: "1",
    tax_fix: "340000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "Ragdoll",
    icon: "0",
    pool_id: "1b94521f14db543dd493b0c170ecb0ef56d7f5e611098d1b8dcfd167",
    db_ticker: "RGDL",
    db_name: "Ragdoll",
    db_url: "https://github.com/rgdl12345/rgdl12345",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "10009790188580",
    stake_x_deleg: "1000979018858",
    saturated: "0.14560949828767",
    luck_lifetime: "97",
    blocks_epoch: "7",
    blocks_lifetime: "592",
    tax_ratio: "0.9999",
    tax_fix: "999999000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
  {
    name: "SPH",
    icon: "0",
    pool_id: "105a6816701a47f2fd43239a9340606640913012811485543bb05968",
    db_ticker: "SPH",
    db_name: "SPH",
    db_url: "https://github.com/stakepoolhub",
    roa: "0",
    roa_lifetime: "0",
    total_stake: "12759201434171",
    stake_x_deleg: "4253067144723.7",
    saturated: "0.18560438174824",
    luck_lifetime: "104",
    blocks_epoch: "14",
    blocks_lifetime: "961",
    tax_ratio: "0.999999",
    tax_fix: "999999000000",
    pledge: "0",
    handles: {
      tw: null,
      tg: null,
      fb: null,
      yt: null,
      tc: null,
      di: null,
      gh: null,
    },
  },
];

export const mockPools = take(snapshotPoolList, snapshotPoolList.length);
