
import { defineComponent, ref, watch } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  inheritAttrs: false,
  props: {
    labelEnd: { type: Object },
    isFocusedModel: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    /**
     * If true - will allow other input like Cyrillic, Arabic, etc
     */
    allowNonLatin: {
      type: Boolean,
      default: false,
    },
    validateFirst: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["on-custom-blur-handler", "update:isFocusedModel", "get-input-ref"],
  setup(props, { emit }) {
    const isFocused = useVModel(props, "isFocusedModel");
    // We need this internally in case no props is passed for [isFocusedModel]
    // [useVModel] does not automatically create a reactive variable if there is no props
    const isFocusedInternal = ref();

    const focusHandler = () => {
      isFocused.value = true;
      isFocusedInternal.value = true;
    };

    const inputRef = ref();
    if (props.autofocus) {
      setTimeout(() => inputRef.value?.focus(), 500);
    }

    /**
     * Used to exposed internal input ref directly
     */
    watch(inputRef, (ref) => {
      emit("get-input-ref", ref);
    });

    const blurHandler = () => {
      isFocused.value = false;
      isFocusedInternal.value = false;
      emit("on-custom-blur-handler");
    };

    return {
      focusHandler,
      blurHandler,
      inputRef,
      isFocused,
      isFocusedInternal,
    };
  },
  components: {
    FormItem,
  },
});
