
import { useInternalID } from "@/shared/composables/useInternalID";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {},
  setup() {
    const { t } = useI18n();

    const {
      internalId,
      internalIds,
      internalIdsLoading,
      generateSecretKey,
      generateSecretKeyLoading,
    } = useInternalID();

    return {
      t,
      internalId,
      internalIds,
      internalIdsLoading,
      generateSecretKey,
      generateSecretKeyLoading,
    };
  },
});
