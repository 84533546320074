// API docs @see https://github.com/yiminghe/async-validator
import { Rules } from "async-validator";

export const makeRequiredRule = (
  errorMessage: string,
  type: "string" | "array" | "number" | "boolean" | "object" = "string"
): {
  type: "string" | "array" | "number" | "boolean" | "object";
  message: string;
  required: boolean;
} => {
  return {
    type,
    required: true,
    message: errorMessage,
  };
};

/** Regular function for specified checking of regex string */
export const isRegexMatch = (
  text: string | undefined,
  regexStr: string
): boolean => {
  const regexObject = new RegExp(regexStr);
  let isValid = false;

  if (regexObject.test(text ?? "")) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const makeRegexRule = (
  errorMessage: string,
  regexString: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = new RegExp(regexString);
        if (regexObject.test(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};
