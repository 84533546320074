import gql from "graphql-tag";

export const generateSecretKeyGql = gql`
  mutation GenerateSecretKey {
    generateSecretKey {
      ... on UserSecretKey {
        id
        created
        modified
        user {
          id
          username
          name
          avatarUrl
        }
        secretKey
        status
        poolVotes {
          id
          created
          status
          delegation {
            id
            fromStakingKey {
              id
              created
              modified
              user {
                id
                username
                name
                avatarUrl
              }
              stakingKey
              delegations {
                id
              }
            }
            startTx {
              id
              amount
              txid
              stakingKey
              type
              epoch {
                id
                created
                number
              }
            }
            endTx {
              id
              amount
              txid
              stakingKey
              type
              epoch {
                id
                created
                number
              }
            }
          }
          epoch {
            id
            created
            number
          }
          pool {
            id
            address
            status
          }
          secretKey {
            id
            created
            modified
            user {
              id
              username
              name
              avatarUrl
            }
            secretKey
            status
            poolVotes {
              id
              created
              status
              delegation {
                id
              }
              epoch {
                id
                created
                number
              }
              pool {
                id
                address
                status
              }
              secretKey {
                id
                created
                modified
                secretKey
                status
              }
            }
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
